import React, { useEffect, useState, useRef } from "react";
import Button2 from "@mui/material/Button";

import GPTAPI from '../../../http/gpt';
import Constants from "../../../resource/Constants";
import { CircularProgress } from "@mui/material";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

const TAG = 'GPTMainGenerator.js';

const GPTMainGenerator = (props) => {
    const { planSetup } = props;
    const { namesDict, clsStd } = planSetup;
    const { chapter_name, topic_name, topic_sub_name, subject_sub_name, subject_name, board_name } = namesDict;
    
    const CRIT = Constants.Application.LESSONPLAN_CRITERIA;
    let criter = localStorage.getItem(CRIT, '');
    if (!criter)
        criter = '';

    const [criter2, setCriter2] = useState(criter);

    const [loading, setLoading] = React.useState(false);

    console.log(TAG, 'planSetup ', planSetup);

    const handleGPTMainGenerate = async () => {
        setLoading(true);

        // re-used body
        let body = null;

        // read the object plansetup and use the keys as criteria

        let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
        if (sPlanSetup) {
            const keys = Object.keys(sPlanSetup);

            console.log(TAG, 'handleGPTMainGenerate keys', keys);

            const { aim, action, analysis, application, assessment, lessonPlanSetup } = sPlanSetup;

            // update criteria, now from the lesson plan description
            const {plan_description} = lessonPlanSetup;

            criter = plan_description;
            criter = criter2;

            // props.handleUpdateGPTText('Generating.. please wait..'); // clear first
            // at one show every text is updated

            let gptText = '';

            // outcome
            if (aim) {
                aim.isChecked = true;
                aim.isSelected = true;
                aim.checked = true;
                aim.hasResource = true;
                aim.hasKeyword = true;
                aim.summary = 'Generating'; // before update
                aim.learning_outcome = 'Generating';
                aim.description = 'Generating';

                // update the object
                sPlanSetup.aim = aim;

                // update
                // aim summary
                prompt = 'one sentence summary of outcome';
                gptText = await genGPT(prompt);
                aim.summary = gptText;

                // update the object
                sPlanSetup.aim = aim;

                // aim description
                prompt = 'description for learning outcome';
                gptText = await genGPT(prompt);
                aim.description = gptText;
                aim.learning_outcome = gptText;

                sPlanSetup.aim = aim;


                // add two resources
                let resourceList = [
                    {
                        title: "",
                        description: "",
                        url: "",
                    },
                    {
                        title: "",
                        description: "",
                        url: "",
                    },
                ]

                prompt = 'a single resource we will need for outcome';
                gptText = await genGPT(prompt);
                resourceList[0].title = gptText;
                resourceList[0].description = gptText;

                gptText = await genGPT(prompt);
                resourceList[1].title = gptText;
                resourceList[1].description = gptText;

                aim.resourceList = resourceList;

                // add two keywords
                let keywordList = [
                    {
                        title: "",
                        name: "",
                        description: "",
                    },
                    {
                        title: "",
                        name: "",
                        description: "",
                    },
                ]

                prompt = 'a single unique keyword and it\'s description separated by colon, that we will need for outcome';
                gptText = await genGPT(prompt);
                let keywArr = gptText.split(':');
                console.log(TAG, 'handleGPTMainGenerate: keywArr ', keywArr);
                keywordList[0].title = keywArr[0].replace(/^\n+/, '');
                keywordList[0].name = keywArr[0].replace(/^\n+/, '');
                keywordList[0].description = keywArr[1];

                gptText = await genGPT(prompt);
                let keywArr2 = gptText.split(':');
                keywordList[1].title = keywArr2[0].replace(/^\n+/, '');
                keywordList[1].name = keywArr2[0].replace(/^\n+/, '');
                keywordList[1].description = keywArr2[1]; // only two keywords

                aim.keywordList = keywordList;
            }

            // learning path
            if (action) {

                action.isChecked = true;
                action.summary = 'Generating';
                action.engage = 'Generating';
                action.explore = 'Generating';

                // aim
                prompt = 'Teacher Summary for learning path';
                gptText = await genGPT(prompt);
                action.summary = gptText;

                // engage
                prompt = 'engage';
                gptText = await genGPT(prompt);
                action.engage = gptText;

                // explore
                prompt = 'prerequisite';
                gptText = await genGPT(prompt);
                action.explore = gptText;


                // explain steps
                let steps = [
                    {
                        title: "",
                        hasImages: false,
                        hasVideos: false,
                        hasDocs: false,
                        hasTips: true,
                        images: [],
                        videos: [],
                        docs: [],
                        tips: [
                            {
                                title: "",
                                description: "",
                            },
                        ],
                    },

                ];

                action.steps = steps;
                prompt = 'a single unique step for explain';
                gptText = await genGPT(prompt);
                action.steps[0].title = gptText;

                prompt = 'a single unique teacher tip for explain';
                gptText = await genGPT(prompt);
                action.steps[0].tips[0].title = gptText;
                action.steps[0].tips[0].description = gptText;
            }


            // participate            
            if (analysis) {

                prompt = 'one sentence summary for participate';
                gptText = await genGPT(prompt);
                analysis.isChecked = true;
                analysis.summary = gptText;

                // elaborate
                // prompt = 'few sentences under only elaborate part of participation';
                prompt = 'specifically "elaborate" in participate and excluding: "engage, explain, explore, evaluate", under the participate ';
                gptText = await genGPT(prompt);
                analysis.elaborate = gptText;

                let parts = [
                    {
                        heading: "Activity",
                        headingExp: "Activity",
                        headingCode: "ACTV",
                        question: "",
                        hasExp: true,
                        explanation: [
                            {
                                name: "",
                                type: "",
                                description: "",
                            },

                        ],
                        isSelected: true,
                    },
                    {
                        heading: "Generalized",
                        headingExp: "Generalized",
                        headingCode: "GENERAL",
                        question: "",
                        hasExp: true,
                        explanation: [
                            {
                                text: "",
                            },

                        ],
                        isSelected: true,
                    },
                    {
                        heading: "Higher-Order Thinking",
                        headingExp: "Higher-Order Thinking",
                        headingCode: "HOT",
                        question: "",
                        hasExp: true,
                        explanation: [
                            {
                                text: "",
                            }
                        ],
                        isSelected: true,
                    },
                ]

                analysis.parts = parts;
                // activity question => parts[0]
                prompt = 'a single large sentence activity';
                gptText = await genGPT2(prompt);
                analysis.parts[0].question = gptText;

                // activity explanation 1
                prompt = 'many sentences of explanation for activity: ' + analysis.parts[0].question;
                gptText = await genGPT2(prompt);
                analysis.parts[0].explanation[0].text = gptText;
                analysis.parts[0].explanation[0].description = gptText;

                // generalized question => parts[1]
                prompt = 'a single question for generalized';
                gptText = await genGPT2(prompt);
                analysis.parts[1].question = gptText;

                // generalized explanation
                prompt = 'explanation of generalized for question: ' + analysis.parts[1].question;
                gptText = await genGPT2(prompt);
                analysis.parts[1].explanation[0].text = gptText;
                analysis.parts[1].explanation[0].description = gptText;

                // hot question => parts[2]
                prompt = 'a single question for Higher-Order Thinking';
                gptText = await genGPT2(prompt);
                analysis.parts[2].question = gptText;

                // hot explanation
                prompt = 'explanation of Higher-Order Thinking for question: ' + analysis.parts[2].question;
                gptText = await genGPT2(prompt);
                analysis.parts[2].explanation[0].text = gptText;
                analysis.parts[2].explanation[0].description = gptText;
            }

            // evaluate
            if (application) {

                let classwork = {
                    description: "",
                    hasImage: false, // workbook can be image or document
                    hasDocument: false,
                    hasWorkBook: true, // just text
                    hasAssm: false,
                    hasQandA: false,
                    hasDiffTips: true,
                    hasTips: true,
                    teacherDiffTips: {
                        help_description: "",
                        extra_challenge: "",
                    },
                    imageURL: "",
                    documentURL: "",
                    workBookURL: "",
                    assessment: {
                        classroom_id: 0,
                        staff_id: 0,
                        staff_lesson_id: 0,
                        subject_id: 0,
                        subject_sub_id: 0,
                        chapter_id: 0,
                        quiz_title: "abcd",
                        scheduled_date: "2020-12-20",
                        quiz_ended: 0,
                        question_text: "",
                        question_time: "00:00", //+$(event.target.closest('div')).find('input').val(),//$("#timerbool").val(),
                        question_url: "",
                        question_topic_text: "",
                        question_pts: 1,
                        question_type: 201,
                        questions: [
                            {
                                question_text: "",
                                options: [
                                    {
                                        option_index: 0,
                                        option_text: "",
                                        option_url: "",
                                        option_correct: 1,
                                    },
                                    {
                                        option_index: 1,
                                        option_text: "",
                                        option_url: "",
                                        option_correct: 0,
                                    },
                                    {
                                        option_index: 2,
                                        option_text: "",
                                        option_url: "",
                                        option_correct: 0,
                                    },
                                    {
                                        option_index: 3,
                                        option_text: "",
                                        option_url: "",
                                        option_correct: 0,
                                    },
                                ],
                            },
                        ],
                    },
                    questionTips: [
                        {
                            type: "instruct",
                            name: "",
                            description: "",
                            assetUrl: "",
                        },
                        {
                            type: "instruct",
                            name: "",
                            description: "",
                            assetUrl: "",
                        },
                    ],
                    qandAText: "",
                    questionPaperUrl: "",
                    answerSheetUrl: "",
                }

                let morePractice = {
                    description: "",
                    hasSteps: true,
                    mins: 0,
                    steps: [],
                }

                let homework = {
                    description: "",
                    hasQandA: false,
                    hasAssm: false,
                    hasImages: false,
                    hasQuestionTips: false,
                    qandAText: "",
                    questionPaperUrl: "",
                    answerSheetUrl: "",
                    assessment: {
                        questions: [],
                    },
                    images: [],
                    questionTips: [],
                }

                // evaluate summary
                prompt = 'one sentence summary for evaluate';
                gptText = await genGPT(prompt);
                application.summary = gptText;
                application.isChecked = true;
                
                // classwork
                // workbook text
                prompt = 'workbook summary for classwork';
                gptText = await genGPT(prompt);
                application.classwork.description = gptText;

                // teacher diff tips
                prompt = 'more help in teacher differentiation tips';
                gptText = await genGPT(prompt);
                application.classwork.teacherDiffTips.help_description = gptText;

                // extra challenge
                prompt = 'extra challenge in teacher differentiation tips';
                gptText = await genGPT(prompt);
                application.classwork.teacherDiffTips.extra_challenge = gptText;

                // question tips
                

                // more practice
                prompt = 'more practice description';
                gptText = await genGPT(prompt);
                application.morePractice.description = gptText;

                // more practice step
                let morePracStep = {
                    title: "",
                    hasImages: false,
                    hasVideos: false,
                    hasDocs: false,
                    hasTips: false,
                    images: [],
                    videos: [],
                    docs: [],
                    tips: [],
                };

                prompt = 'a single more practice step';
                gptText = await genGPT(prompt);
                morePracStep.title = gptText;
                application.morePractice.steps[0] = morePracStep;

                // homework
                prompt = ''

                // application = JSON.parse(JSON.stringify(applicationObj));

            }

            // reflection
            if (assessment) {
                assessment.isChecked = true;

                prompt = 'one sentence teacher summary for reflection';
                gptText = await genGPT(prompt);
                assessment.summary = gptText;

            }

            // update the object
            props.onUpdatePlanSetup(sPlanSetup);
            console.log(TAG, 'handleGPTMainGenerate: state update called..');

        }


        setLoading(false);
    }

    const genGPT = async (prompt) => {
        // update criteria
        criter = criter2; // from state
        // attach the learning outcome..
        let newPrompt = `without mentioning grade, standard and subject again, give me only '${prompt}' part of a lesson plan for the learning outcome '${criter}', in brief`
        console.log(TAG, 'genGPT newPrompt ', newPrompt);
        let gptText = 'Generating.. please wait..';

        const body = {
            board: namesDict.board_name,
            subject: namesDict.subject_sub_name,
            grade: clsStd,
            chapter: chapter_name,
            topic: topic_name,
            sub_topic: topic_sub_name,
            pedagogy_text: newPrompt,
            process: true
        }

        console.log(TAG, 'genGPT body ', JSON.stringify(body));

        const resp = await GPTAPI.generate(body);

        if (resp && resp.data && resp.data.choices) {
            if (resp.data.choices.length && resp.data.choices[0].text)
                gptText = resp.data.choices[0].text;
        }

        console.log(TAG, 'genGPT resp ', JSON.stringify(resp));
        console.log(TAG, 'genGPT gptText ', gptText);
        
        // props.handleUpdateGPTText(gptText);
        return gptText;

    }

    const genGPT2 = async (prompt) => {
        // update criteria
        criter = criter2; // from state
        // attach the learning outcome..
        let newPrompt = `without mentioning grade, standard and subject again, give me only '${prompt}' part of a lesson plan , in brief`
        console.log(TAG, 'genGPT newPrompt ', newPrompt);
        let gptText = 'Generating.. please wait..';

        const body = {
            board: namesDict.board_name,
            subject: namesDict.subject_sub_name,
            grade: clsStd,
            chapter: chapter_name,
            topic: topic_name,
            sub_topic: topic_sub_name,
            pedagogy_text: newPrompt,
            process: true
        }

        console.log(TAG, 'genGPT body ', JSON.stringify(body));

        const resp = await GPTAPI.generate(body);

        if (resp && resp.data && resp.data.choices) {
            if (resp.data.choices.length && resp.data.choices[0].text)
                gptText = resp.data.choices[0].text;
        }

        console.log(TAG, 'genGPT resp ', JSON.stringify(resp));
        console.log(TAG, 'genGPT gptText ', gptText);
        
        // props.handleUpdateGPTText(gptText);
        return gptText;

    }

    const updateCriter = () => {
        let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
        const { aim, action, analysis, application, assessment, lessonPlanSetup } = sPlanSetup;

        // update criteria, now from the lesson plan description
        const {plan_description} = lessonPlanSetup;
        
        if (plan_description) {
            // setCriter2(plan_description);
        }
    }

    useEffect(() => {
        updateCriter();
        
        return {

        }
    }, [])

    return (
        (loading) ?
            <><CircularProgress /></>
            :
            <Button2
                variant="contained"
                color="success"
                size="small"
                disabled={loading}
                onClick={handleGPTMainGenerate}
                >
                <AutoFixHighIcon />
                &nbsp;&nbsp;
                <>
                {(loading) ? 'Generating' : 'Generate'}
                </>
            </Button2>
    )

}

export default GPTMainGenerator;
