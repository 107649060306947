import { CURRICULUMMAP_LIST_REQUEST, CURRICULUMMAP_LIST_SUCCESS, 
    CURRICULUMMAP_LIST_FAIL,
CURRICULUMMAP_ADD_REQUEST,
CURRICULUMMAP_ADD_SUCCESS,
CURRICULUMMAP_ADD_FAILURE, 
CURRICULUMMAPFILTER_LIST_REQUEST,
CURRICULUMMAPFILTER_LIST_SUCCESS,
CURRICULUMMAPFILTER_LIST_FAIL} from "../constants/CurriculumMapConstants";

function CurriculumMapReducer(state = {CurriculumMap:[]},action) {
    switch(action.type){
        case CURRICULUMMAP_LIST_REQUEST:
            return {loadingCur:true}
        case CURRICULUMMAP_LIST_SUCCESS:
            console.log(`called curriculum add success with data ${JSON.stringify(action.payload)}`)
            
            return {loadingCur:false,CurriculumMap:action.payload};
        case CURRICULUMMAP_LIST_FAIL:
            return {loadingCur:false,error:action.payload}
        case CURRICULUMMAP_ADD_REQUEST:
            return {loadingCur:true}
        case CURRICULUMMAP_ADD_SUCCESS:
            return {loadingCur: false, CurriculumMap: action.payload}
        case CURRICULUMMAP_ADD_FAILURE:
            return {loadingCur: false, error: action.payload}
        default:
            return state;
    }

}


function CurriculumFilterMapReducer(state ={CurriculumMapFilter:[]},action) {

    switch (action.type) {
        case CURRICULUMMAPFILTER_LIST_REQUEST:
            return { loadingCurFil: true, CurriculumMapFilter: [],errorCurFil:false }
        case CURRICULUMMAPFILTER_LIST_SUCCESS:
            return {loadingCurFil:false,CurriculumMapFilter:action.payload,errorCurFil: false};
        case CURRICULUMMAPFILTER_LIST_FAIL:
            return {loadingCurFil:false,CurriculumMapFilter:[], errorCurFil:action.payload}
        default:
            return state;
    }

}
export {CurriculumMapReducer,CurriculumFilterMapReducer}