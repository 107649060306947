import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from "react-router-dom";

import List2 from "@mui/material/List";
import ListItem2 from "@mui/material/ListItem";
import ListItemButton2 from "@mui/material/ListItemButton";
import ListItemIcon2 from "@mui/material/ListItemIcon";
import ListItemText2 from "@mui/material/ListItemText";
import ListItemAvatar2 from "@mui/material/ListItemAvatar";
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import EditIcon2 from "@mui/icons-material/Edit";
import DeleteIcon2 from "@mui/icons-material/Delete";

import Alert2 from "@mui/material/Alert";
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Typography,
    TextField,
    Chip,
} from "@material-ui/core";
import Typography2 from "@mui/material/Typography";
import Divider2 from "@mui/material/Divider";
import LessonPlanSetupAPI from "../../../http/lessonplansetup";

const TAG = 'RelatedLessons.js';

const RelatedLesson2 = (props) => {
    const {idx} = props;
    return (
        <>
            <ListItem2 alignItems="flex-start"

                style={{
                    cursor: 'pointer',
                }}
            >
                <ListItemAvatar2>
                    <Avatar>{idx}</Avatar>
                </ListItemAvatar2>
                <ListItemText2
                    primary={
                    <>
                        <Chip label="keyword" />,
                        <Typography2 variant='caption'>keyword1</Typography2>,
                        <Typography2 variant='caption'>keyword2</Typography2>
                    </>
                    }
                    // secondary={
                    //     <React.Fragment>
                    //         <Typography2
                    //             sx={{ display: 'inline' }}
                    //             component="span"
                    //             variant="body2"
                    //             color="text.primary"
                    //         >
                    //             {'secondary'}
                    //         </Typography2>

                    //     </React.Fragment>
                    // }
                />
                <ListItemIcon>
                    <EditIcon2 />
                    <DeleteIcon2 />
                </ListItemIcon>
            </ListItem2>
            <Divider2 variant="inset" component="li" />
        </>
    )
}

function RelatedLesson(props) {
    const { lsn, idx } = props;


    // get params from URLSearchParams object
    const params = new URLSearchParams(window.location.search);
    const instId5 = params.get('inst_id');
    const boardId5 = params.get('board_id');
    const clsStd5 = params.get('class_std');
    const subId5 = params.get('sub_id');
    const chapId5 = params.get('chap_id');
    const mapId5 = params.get('map_id');
    const unitId5 = params.get('unit_id');
    const lsnId5 = params.get('lesson_id');

    console.log(TAG, 'instId5', instId5);
    console.log(TAG, 'boardId5', boardId5);
    console.log(TAG, 'chapId5', chapId5);

    console.log(TAG, 'Related Lesson', JSON.stringify(lsn));
    const { name, plan_description, topic_id, lesson_plan_setup_id, cover_image_url } = lsn;

    const history = useHistory();
    return (
        <>
            <ListItem2 alignItems="flex-start"
                onClick={() => {
                    // take him to that page
                    const URL = `/publisher-ebook/curriculum-map/set-up/${lsn.lesson_plan_setup_id}/?map_id=${mapId5}&inst_id=${instId5}&board_id=${boardId5}&class_std=${clsStd5}&sub_id=${subId5}&unit_id=${unitId5}&lesson_id=${lsn.lesson_plan_setup_id}&chap_id=${chapId5}`
                    //      history.push(URL)
                    window.location.assign(URL);
                }}
                style={{
                    cursor: 'pointer',
                }}
            >
                <ListItemAvatar2>
                    <Avatar>{(idx + 1)}</Avatar>
                </ListItemAvatar2>
                <ListItemText2
                    primary={name}
                    secondary={
                        <React.Fragment>
                            <Typography2
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                {plan_description}
                            </Typography2>
                            &nbsp; - Lesson Plan #{(idx + 1)}
                        </React.Fragment>
                    }
                />
            </ListItem2>
            <Divider2 variant="inset" component="li" />
        </>
    )
}

function RelatedLessonsList(props) {
    const { relatedLessons } = props;

    // if (!relatedLessons || !relatedLessons.length) {
    //     return (
    //         <>
    //             <Alert2 severity="secondary">No related keywords found!</Alert2>
    //         </>
    //     )
    // }

    return (
        <List2 sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <RelatedLesson2 idx={1} />
            <RelatedLesson2 idx={2} />
            <RelatedLesson2 idx={3} />

        </List2>
    );
}


const RelatedLessons = (props) => {
    const { planSetup } = props;
    const { lessonPlanSetup } = planSetup;
    const [relatedLessons, setRelatedLessons] = useState([]);

    async function _getRelatedLessons() {
        const blockId = lessonPlanSetup.lesson_id;
        const resp = await LessonPlanSetupAPI.getPlans(blockId);

        console.log(TAG, "RelatedLessons response ", JSON.stringify(resp));

        const data = (resp.data && resp.data.data) ? resp.data.data : null;
        setRelatedLessons(data);

    }

    useEffect(() => {

        // _getRelatedLessons()
        return () => {

        }
    }, [])

    return (
        <>
            <Typography2
                variant="h6">Selected Keywords</Typography2>
            <RelatedLessonsList
                relatedLessons={relatedLessons} />
        </>
    )
}

export default RelatedLessons;
