import {
  FILTERED_LIST_REQUEST,
  FILTERED_LIST_SUCCESS,
  FILTERED_LIST_FAIL,
  CURRICULUMMAPFILTER_LIST_REQUEST,
  CURRICULUMMAPFILTER_LIST_SUCCESS,
  CURRICULUMMAPFILTER_LIST_FAIL,
} from "../constants/CurriculumMapConstants";

function filteredMapReducer(state = { filteredMap: null }, action) {
  switch (action.type) {
    case FILTERED_LIST_REQUEST:
      return { loadingCur: true };
    case FILTERED_LIST_SUCCESS:
      console.log(
        `called filtered list success with data ${JSON.stringify(
          action.payload
        )}`
      );
      return { loadingCur: false, filteredMap: action.payload };

    case FILTERED_LIST_FAIL:
      return { loadingCur: false, error: action.payload };
    case CURRICULUMMAPFILTER_LIST_FAIL:
      return { loadingCur: false, error: action.payload };
    case CURRICULUMMAPFILTER_LIST_REQUEST:
      return { loadingCur: true };
    case CURRICULUMMAPFILTER_LIST_SUCCESS:
      console.log(
        `called filtered list success with data ${JSON.stringify(
          action.payload
        )}`
      );
      return { loadingCur: false, filteredMap: action.payload };
    default:
      return state;
  }
}

export { filteredMapReducer };
