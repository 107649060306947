import "./App.css";
import {
  Route,
  Link,
  BrowserRouter as Router,
  Switch,
  Redirect,
  BrowserRouter,
} from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";

import MapDashboard from "./pages/curriculummap/MapDashboard";

import Login from "./pages/user/Login";
import DoLogin from "./pages/user/DoLogin";
import Logout from "./pages/user/Logout";
import { useHistory } from "react-router-dom";

// import UploadEbookScreen from './pages/ebook/UploadContent';
import UnitList from "./pages/curriculummap/UnitList";
import CurriculumSetup from "./pages/curriculummap/CurriculumSetup";
import CurriculumSetupLearn from "./pages/curriculummap/CurriculumSetupLearn";
import MapList from "./pages/curriculummap/MapList";

import { useState } from "react";
import MainPage from "./pages/user/HomePage";
import { Helmet } from "react-helmet";
import GlobalStyles from "./GlobalStyles";
import theme from "./theme";

import ProfilePage from "./pages/user/ProfilePage";
import Cookie from "js-cookie";

import SignUp from "./pages/user/SignUp";
import ThankyouSignUp from "./pages/user/ThankyouSignUp";
import ForgotPassw from "./pages/user/ForgotPassw";
import ChangePassw from "./pages/user/ChangePassw";
import Terms from "./pages/curriculummap/slots/Settings/TermsConditions";

import HelpSupportPub from "./pages/curriculummap/slots/Settings/HelpSupportPub";
import TermsConditionsPub from "./pages/curriculummap/slots/Settings/TermsConditions";
import AccountSwitch from "./pages/user/slots/SwitchAccount";
import AddPlanPage from "./pages/curriculummap/AddPlan";
import SettingsEbook from "./pages/curriculummap/SettingsEbook";

import ActivityPage from "./pages/contentmaker/slots/AdminControls/ActivityPage";
import ActivityPagePub from "./pages/curriculummap/slots/AdminControls/ActivityPagePub";
import MyLessons from "./pages/curriculummap/MyLessons";

function App() {
  const [sideStatus, setSideStatus] = useState(false);
  const [sideProvList, setSideProvList] = useState(null);

  const updateSideStatus = (status) => {
    setSideStatus(status);
  };

  const updateSideProvList = (pubData) => {
    setSideProvList(pubData);
  };

  // const roles = Cookie.get('kgtopg.guruculum.user.roles')
  // const CONTENTUPLOAD = (roles && roles.includes("CONTENTUPLOAD"))
  // const QUALITYASSURANCE = (roles && roles.includes("QUALITYASSURANCE"))
  // const SUPERADMIN = (roles && roles.includes("SUPERADMIN"))
  // const CONTENTMAP = (roles && roles.includes("CONTENTMAP"))
  // const CURRICULUMMAP = (roles && roles.includes("CURRICULUMMAP"))
  // const EBOOKUPLOAD = (roles && roles.includes("EBOOKUPLOAD"))
  // const TRAINER = (roles && roles.includes("TRAINER"))

  // console.log("role status",
  //  CONTENTUPLOAD,
  //   QUALITYASSURANCE,
  //   SUPERADMIN,
  //   CONTENTMAP,
  //   CURRICULUMMAP,
  //   EBOOKUPLOAD,
  //   TRAINER
  //   )

  const routing = (
    <BrowserRouter>
      <Helmet>
        <title> Guruculum.in | KGtoPG Partner</title>
      </Helmet>

      <Switch>
        <Route exact={true} path="/" component={Login} />
        <Route exact={true} path="/home" component={MainPage} />
        <Route exact={true} path="/profile" component={ProfilePage} />

        <Route
          exact={true}
          path="/publisher-ebook/curriculum-map"
          component={MapList}
        />
        <Route exact={true} path="/curriculum-map" component={MapDashboard} />
        <Route
          exact={true}
          path="/publisher-ebook/curriculum-map/set-units/:id"
          component={UnitList}
        />
        <Route
          exact={true}
          path="/publisher-ebook/curriculum-map/set-up/:id"
          component={CurriculumSetup}
        />
        <Route
          exact={true}
          path="/publisher-ebook/curriculum-map/set-up-learn/:id"
          component={CurriculumSetupLearn}
        />
        <Route
          exact={true}
          path="/publisher-ebook/settings/help"
          component={HelpSupportPub}
        />
        <Route
          exact={true}
          path="/publisher-ebook/settings/terms-conditions"
          component={TermsConditionsPub}
        />
        <Route
          exact={true}
          path="/publisher-ebook/settings"
          component={SettingsEbook}
        />
        <Route
          exact={true}
          path="/publisher-ebook/admin-activity"
          component={ActivityPagePub}
        />

        <Route
          exact={true}
          path="/publisher-ebook/my-lessons"
          component={MyLessons}
        />

        <Route exact={true} path="/terms" component={Terms} />

        <Route exact path="/logout" component={Logout} />
        {/* external access */}
        <Route exact={true} path="/user/do-login" component={DoLogin} />
        <Route exact={true} path="/do-login" component={DoLogin} />
        <Route exact={true} path="/user/forgot-passw" component={ForgotPassw} />
        <Route exact={true} path="/user/change-passw" component={ChangePassw} />
        <Route exact={true} path="/user/register" component={SignUp} />
        <Route exact={true} path="/user/thank-you" component={ThankyouSignUp} />
        <Route
          exact={true}
          path="/user/switch-account"
          component={AccountSwitch}
        />
      </Switch>
    </BrowserRouter>
  );

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>
  );
}

export default App;
