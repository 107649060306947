import React, { useEffect, useState } from "react";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import "../../css/sidebar.css"
import { Link } from 'react-router-dom'
import { ExitToApp, ExitToAppOutlined, LaptopWindows, SettingsOutlined } from '@material-ui/icons';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { CircularProgress, Divider, Input } from '@material-ui/core';
import AddBoxSharpIcon from '@material-ui/icons/AddBoxSharp';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import image from "../../assets/bus.png"
import FilterResults from 'react-filter-search';
import SearchIcon from '@material-ui/icons/Search';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import CropLandscapeOutlinedIcon from '@material-ui/icons/CropLandscapeOutlined';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import ImportContactsOutlinedIcon from '@material-ui/icons/ImportContactsOutlined';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector } from "react-redux";
import { listEbookContentGet } from "../../redux/actions/ebookContentAction"
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import PUBLISHERAPI from '../../http/publisher'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import Cookie from 'js-cookie';
import "../../css/sidebar.css"
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { Alert, AlertTitle } from '@material-ui/lab';
import Constants from "../../resource/Constants";

const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
    { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
    { title: 'The Good, the Bad and the Ugly', year: 1966 },
    { title: 'Fight Club', year: 1999 },
    { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
    { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
    { title: 'Forrest Gump', year: 1994 },
    { title: 'Inception', year: 2010 },
    { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
    { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { title: 'Goodfellas', year: 1990 },
    { title: 'The Matrix', year: 1999 },
    { title: 'Seven Samurai', year: 1954 },
    { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
    { title: 'City of God', year: 2002 },
    { title: 'Se7en', year: 1995 },
    { title: 'The Silence of the Lambs', year: 1991 },
    { title: "It's a Wonderful Life", year: 1946 },
    { title: 'Life Is Beautiful', year: 1997 },
    { title: 'The Usual Suspects', year: 1995 },
    { title: 'Léon: The Professional', year: 1994 },
    { title: 'Spirited Away', year: 2001 },
    { title: 'Saving Private Ryan', year: 1998 },
    { title: 'Once Upon a Time in the West', year: 1968 },
    { title: 'American History X', year: 1998 },
    { title: 'Interstellar', year: 2014 },
    { title: 'Casablanca', year: 1942 },
    { title: 'City Lights', year: 1931 },
    { title: 'Psycho', year: 1960 },
    { title: 'The Green Mile', year: 1999 },
    { title: 'The Intouchables', year: 2011 },
    { title: 'Modern Times', year: 1936 },
    { title: 'Raiders of the Lost Ark', year: 1981 },
    { title: 'Rear Window', year: 1954 },
    { title: 'The Pianist', year: 2002 },
    { title: 'The Departed', year: 2006 },
    { title: 'Terminator 2: Judgment Day', year: 1991 },
    { title: 'Back to the Future', year: 1985 },
    { title: 'Whiplash', year: 2014 },
    { title: 'Gladiator', year: 2000 },
    { title: 'Memento', year: 2000 },
    { title: 'The Prestige', year: 2006 },
    { title: 'The Lion King', year: 1994 },
    { title: 'Apocalypse Now', year: 1979 },
    { title: 'Alien', year: 1979 },
    { title: 'Sunset Boulevard', year: 1950 },
    { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964 },
    { title: 'The Great Dictator', year: 1940 },
    { title: 'Cinema Paradiso', year: 1988 },
    { title: 'The Lives of Others', year: 2006 },
    { title: 'Grave of the Fireflies', year: 1988 },
    { title: 'Paths of Glory', year: 1957 },
    { title: 'Django Unchained', year: 2012 },
    { title: 'The Shining', year: 1980 },
    { title: 'WALL·E', year: 2008 },
    { title: 'American Beauty', year: 1999 },
    { title: 'The Dark Knight Rises', year: 2012 },
    { title: 'Princess Mononoke', year: 1997 },
    { title: 'Aliens', year: 1986 },
    { title: 'Oldboy', year: 2003 },
    { title: 'Once Upon a Time in America', year: 1984 },
    { title: 'Witness for the Prosecution', year: 1957 },
    { title: 'Das Boot', year: 1981 },
    { title: 'Citizen Kane', year: 1941 },
    { title: 'North by Northwest', year: 1959 },
    { title: 'Vertigo', year: 1958 },
    { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
    { title: 'Reservoir Dogs', year: 1992 },
    { title: 'Braveheart', year: 1995 },
    { title: 'M', year: 1931 },
    { title: 'Requiem for a Dream', year: 2000 },
    { title: 'Amélie', year: 2001 },
    { title: 'A Clockwork Orange', year: 1971 },
    { title: 'Like Stars on Earth', year: 2007 },
    { title: 'Taxi Driver', year: 1976 },
    { title: 'Lawrence of Arabia', year: 1962 },
    { title: 'Double Indemnity', year: 1944 },
    { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
    { title: 'Amadeus', year: 1984 },
    { title: 'To Kill a Mockingbird', year: 1962 },
    { title: 'Toy Story 3', year: 2010 },
    { title: 'Logan', year: 2017 },
    { title: 'Full Metal Jacket', year: 1987 },
    { title: 'Dangal', year: 2016 },
    { title: 'The Sting', year: 1973 },
    { title: '2001: A Space Odyssey', year: 1968 },
    { title: "Singin' in the Rain", year: 1952 },
    { title: 'Toy Story', year: 1995 },
    { title: 'Bicycle Thieves', year: 1948 },
    { title: 'The Kid', year: 1921 },
    { title: 'Inglourious Basterds', year: 2009 },
    { title: 'Snatch', year: 2000 },
    { title: '3 Idiots', year: 2009 },
    { title: 'Monty Python and the Holy Grail', year: 1975 },
];



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '150ch',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
}));

const useStyles1 = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

const useStyles3 = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    customWidth: {
        maxWidth: 500,
    },
    noMaxWidth: {
        maxWidth: 'none',
    },
}));

const longText = `
Click on the Thumbnail card to show the play  button on ebook 
`;



const PublisherListCard = (props) => {
    const classes = useStyles1();
    const [checked, setChecked] = React.useState([1]);
    const [showcontentCard, setShowContentCard] = useState(false)
    const [provId, setProvId] = useState("")
    const [provDetails, setProvDetails] = useState("")

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const prov = props.contentData;

    const SendProvDataToList = (provData) => {
        props.providerData(provData)
    }


    const UpdateProvDetails = (status, id, provData) => {
        SendProvDataToList(provData)
        setProvDetails(provData)
        setShowContentCard(status)
        setProvId(id)
    }


    const sendContDatatoParent = (contData) => {
        props.getContentDatafromPubList(contData)
    }


    const ShowContentCard = (status) => {
        setShowContentCard(status)
        props.showProvName(status)
    }

    const ProviderList = (props) => {


        return (
            <List dense className={classes.root}>
                {props.contentData && props.contentData.length ?
                    props.contentData.map((value) => {
                        console.log("conetnt Data at Publisherlist card  ", value)

                        return (

                            !showcontentCard ?
                                <div >

                                    <ListItem key={value} button onClick={() => { props.showCard(true, value.id, value) }}>
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={value.prov_name_text}
                                                src={value.prov_cover_url}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText id={value.id} primary={value.prov_name_text} />
                                        <ListItemSecondaryAction>
                                            <ChevronRightOutlinedIcon />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Divider
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent:
                                                    "center",
                                                alignSelf: "center"
                                            }} />
                                    </div>
                                </div>
                                :
                                <></>




                        );
                    })
                    :

                    <Alert severity="warning">No content providers found!</Alert>
                }
            </List>
        )

    }

    return (
        <div style={{ maxHeight: "100vh" }}>
            {
                !showcontentCard ?
                    <ProviderList contentData={prov} showCard={(status, id, provData) => UpdateProvDetails(status, id, provData)} />
                    :
                    <DisplayCard
                        contentData={prov}
                        id={provId}
                        showProvList={(status) => { ShowContentCard(status) }}
                        getCardData={(contentDetails) => { sendContDatatoParent(contentDetails) }}
                    />

            }
        </div>
    );
}




const DisplayCard = (props) => {
    const classes = useStyles3();
    const [title, setTitle] = useState("")
    const [searchResults, setSearchResults] = useState([])


    let provDetails = props.contentData
    let id = props.id

    console.log("props at displayCard ", id, provDetails)

    const handleTitleChange = (event) => {
        console.log(`new title ${event}`)
        const { name, value } = event.target
        setTitle(value)
    }


    useEffect(() => {
        let temp = [];

        provDetails.map((details, index) => {
            details.id == id && details.contents && details.contents.length != 0 ?
                details.contents.map((list, index) => {
                    temp.push(list)
                })
                :
                <></>
        })


        // if(temp && temp.length > 0) {
        const results = (title) ? temp.filter(name => name.content_name.toLowerCase().includes(title.toLowerCase())) : []
        console.log("results", results)
        setSearchResults(results)
        // }

        console.log("search Resust at state", searchResults)
    }, [title])

    return (
        <div>
            <div >
                <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', marginTop: 10, width: '100%' }}>

                    <TextField
                        id="outlined-basic"
                        label="Search Content"
                        variant="outlined"
                        defaultValue={title}
                        onChange={handleTitleChange}

                        style={{
                            width: 250,
                            fontFamily: "Poppins, Helvetica, sans-serif",
                            display: "inline-block",
                            lineHeight: "140%"


                        }}

                    />


                    <ClearOutlinedIcon onClick={() => { props.showProvList(false) }} style={{ cursor: "pointer" }} />
                </div>

                <div style={{ display: "flex", justifyContent: "left", marginLeft: 20 }}>
                    <Typography>
                        <span
                            style={{
                                fontSize: 12,
                                color: "#464E5F",
                                marginLeft: 5,
                                fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                        >
                            Search by <span
                                style={{
                                    color: "#464E5F",
                                    fontWeight: "600",
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                    fontSize: 12
                                }}>
                                Content Name
                            </span>
                        </span>
                    </Typography>

                </div>
            </div>



            {/* 

            <div style={{ display: "flex", justifyContent: "flex-end", margin: 10 }}>
                <ClearOutlinedIcon onClick={() => { props.showProvList(false) }} style={{ cursor: "pointer" }} />
            </div> */}

            <div className="contentScroll" >
                {
                    // provDetails.map((details, index) => {
                    //     console.log("details at display card =============", details, id)
                    //     return (

                    //         details.id == id && details.contents && details.contents.length != 0 ?

                    //             details.contents.map((list, index) => {
                    //                 return (
                    //                     <Card style={{ width: 270, height: 150, margin: 10, justifyContent: "center", }} key={index} onClick={() => { props.getCardData(list) }}>
                    //                         <CardMedia
                    //                             component="img"
                    //                             alt={list.content_name}
                    //                             height="100"
                    //                             image={list.image_cover_url}
                    //                             title={list.content_name}
                    //                         />

                    //                         <CardContent>
                    //                             <Typography
                    //                                 gutterBottom
                    //                                 variant="h5"
                    //                                 component="h2"
                    //                                 style={{
                    //                                     fontFamily: "Poppins, Helvetica, sans-serif",
                    //                                     fontSize: 14,
                    //                                 }}
                    //                             >
                    //                                 {list.content_name}
                    //                             </Typography>
                    //                         </CardContent>
                    //                     </Card>

                    //                 )
                    //             })



                    //             :
                    //             <></>
                    //     )
                    // })

                    (searchResults && searchResults.length) ?
                    searchResults.map((list, index) => {
                        return (
                            <Tooltip title={longText} placement="bottom">
                                <Card style={{ width: 270, height: 150, margin: 10, justifyContent: "center", }} key={index} onClick={() => { props.getCardData(list) }}>
                                    <CardMedia
                                        component="img"
                                        alt={list.content_name}
                                        height="100"
                                        image={list.image_cover_url}
                                        title={list.content_name}
                                    />

                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="h2"
                                            style={{
                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                fontSize: 14,
                                            }}
                                        >
                                            {list.content_name}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Tooltip>
                        )
                    })
                    :
                    <div style={{
                        marginTop: 20
                    }}>
                        <Alert severity="info">
                            <AlertTitle>No queries made</AlertTitle>
                            Please use the search field to filter appropriate content — <strong>use the right keyword!</strong>
                        </Alert>
                    </div>

                }
            </div>

            <div className="contnetScroll">
                {


                }

            </div>


        </div>
    )
}

const useStyles2 = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));


function CheckboxListSecondary(props) {
    const classes = useStyles2();
    const [checked, setChecked] = React.useState([]);
    const { contentData } = props;

    console.log("checked list ", checked)

    const handleSelectProv = () => {

    }

    const handleToggle = (e, value) => () => {
        console.log("values at checkedlist", value)
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
            props.getSelectedProv(value)
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);


    };

    const data = props && props.ContentProviders ? props.ContentProviders : ""
    let contentIds = []
    if (contentData && contentData.length) {
        contentData.map(cont => {
            contentIds.push(cont.id)
        })
    }

    console.log("providers list at check box 2222222222222222222222", props.ContentProviders)
    console.log(`contentData at check box ${JSON.stringify(contentData)}`)
    console.log(`contentIds ${JSON.stringify(contentIds)}`)
    return (
        <List dense className={classes.root} style={{ width: 350 }}>
            {data.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value}`;
                let selected = false

                if (contentIds.indexOf(value.id) > -1)
                    selected = true
                return (
                    <ListItem key={value} button
                        selected={selected}
                        onClick={(e) => { props.savePubContProv(e, value.id) }}
                    >
                        <ListItemAvatar>
                            <Avatar
                                alt={value.prov_name_text}
                                src={value.prov_cover_url}
                            />
                        </ListItemAvatar>
                        <ListItemText id={labelId} primary={value.prov_name_text} />
                        <ListItemSecondaryAction>
                            {/* <Checkbox */}
                            <Radio
                                edge="end"
                                // onChange={(e) => handleToggle(e, value)}
                                onChange={(e) => {
                                    props.savePubContProv(e, value.id)
                                }}
                                // checked={checked.indexOf(value) !== -1}
                                checked={selected}
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                            {/* <DeleteOutlinedIcon color="secondary" /> */}
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            })}
        </List>
    );
}






function PublisherListSideBar(props) {


    const [data, setData] = useState([]);
    const [value, setValue] = useState("");
    const [search, setSearch] = useState("")
    const [showContent, setShownContent] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [book_id, setBook_id] = useState("")
    const [contData, setContData] = useState("")
    const [title, setTitle] = useState("")
    const [pubId, setPubId] = useState(2)
    const teachPrev = (props.teachPrev != null) ? props.teachPrev : false
    const bookId2 = (props.bookId != null) ? props.bookId : 0
    const [prov, setProvider] = useState("")
    const [provStatus, setProvStatus] = useState("")

    const [selProv, setSelProv] = useState("")

    const savePubContProv = async (e, contProvId) => {
        // const pubId = 5;
        const { name, value, checked } = e.target
        console.log(`checkeddd ${checked}`)
        console.log("savePubContent id at publisherListBar", contProvId)
        // only if checked
        if (checked) {

            console.log(`now subscribing ${contProvId}..`)
            const body = {
                cont_prov_id: contProvId,
                pub_id: pubId,
            }
            await PUBLISHERAPI.savePubContProv(body);
        } else {
            // remove content provider
            console.log(`now unsubscribing ${contProvId}`)
            const body = {
                id: contProvId,
                pub_id: pubId,
            }

            await DeleteProv(body);

        }
    }

    const DeleteProv = async (provDet) => {
        const query = new URLSearchParams(window.location.search)
        console.log("-------------------------", query.get('book_id'))

        const book_id = query.get('book_id')
        const response = await PUBLISHERAPI.DelContentProvider(provDet);

        if (response) {
            getEbookContent(book_id)
        }

    }

    const handleProvChange = (event) => {
        setSelProv(event.target.value);
    };

    const UpdateProvData = (provDat) => {
        setProvider(provDat)
    }

    const UpdateProvStatus = (status) => {
        setProvStatus(!status)
    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleChange = event => {
        const { value } = event.target;
        this.setState({ value });
    };


    const onTextChange = e => {
        setSearch(e.target.value)
    };

    const handleTitleChange = (event) => {
        console.log(`new title ${event}`)
        const { name, value } = event.target
        setTitle(value)
    }

    /* 
        useEffect(() => {
            
            return <></>
        }, [])
     */

    const sendContDatatoDashboard = (list, status) => {
        console.log("content Data=========", list)
        props.sendDatatoReaderScreen(list, status)
    }



    // const filteredData = top100Films.filter(content => {
    //     return content.title.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    // })




    const dispatch = useDispatch()


    const EbookContentGet = useSelector(state => state.ebookContent)
    const { loadingEbook, EbookContent, errorEbook } = EbookContentGet



    console.log("EBook Content provider details at publisher list side bar", EbookContent)

    const [providers, setProviders] = useState(null)
    const [bookId, setBookId] = useState(0)

    let contentData = ""

    {
        loadingEbook && EbookContentGet != null ?
            <></>
            :
            EbookContent.data ?

                contentData = EbookContent.data
                :
                console.log("NO DATA AT EBOOK")

    }



    let filteredData = ""





    // {
    //     contentData ?
    //         filteredData = contentData.filter(content => {
    //             return content.content_name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    //         })

    //         :
    //         console.log("no data at ebook")
    // }


    console.log("content Data at ebbok =====================================", filteredData)

    const getEbookContent = (book_id) => {
        dispatch(listEbookContentGet(book_id))
    }


    const getContentProviders = async () => {

        console.log("getContentProviders Function is calling...................")
        const response = await PUBLISHERAPI.getContentProviders();
        console.log("content providers ++++++++++++++++++++", response.data)
        setProviders(response.data)
    }



    useEffect(() => {
        const userId = Cookie.get('kgtopg.guruculum.user.id')
        setPubId(userId)

        getContentProviders();
        const query = new URLSearchParams(window.location.search)
        setBook_id(query.get('book_id'))
        console.log("-------------------------", query.get('book_id'))
        getEbookContent(query.get('book_id'))

        return () => {
            // console.log("windows path", window.location.href, window.location.pathname)
            // 
        }
    }, [])


    const AccordianCard = (props) => {

        return (
            <div>

                <Accordion >
                    <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className="dashboard" style={{ display: "flex", justifyContent: "center" }}>
                            <PersonOutlineOutlinedIcon />
                            <Typography style={{
                                fontSize: 15, margin: 3
                            }}>{props.contentname}</Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>

                        <div
                            style={{
                                height: 50,
                                width: 270,
                                backgroundColor: "#fff",
                                border: "1px solid #e0e0e0",
                                borderRadius: 10,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                            <SearchIcon />
                            <Input
                                label="search content"
                                icon="search"
                                onChange={(e) => onTextChange(e)}
                                style={{ outlineColor: "#fff" }}
                                placeholder="Search Content"
                            />
                        </div>
                    </AccordionDetails>


                    <div style={{ height: "75vh", overflowY: "scroll", }}>

                        {/* {filteredData ?
                            filteredData.length != 0 ?
                                filteredData.map((list, index) => {
                                    return (
                                        <div onClick={() => { props.buttonstatus(list, true) }} >
                                            <DisplayCard list={list} key={index} />
                                        </div>
                                    )
                                })
                                :

                                <Card style={{ justifyContent: "center", display: "flex", width: "90%", margin: 10 }}>
                                    <Typography style={{ fontSize: 14, fontFamily: "Poppins, Helvetica, sans-serif" }} color="textSecondary" gutterBottom>
                                        No Options
                            </Typography>
                                </Card>


                            :
                            <Card style={{ justifyContent: "center", display: "flex" }}>
                                <Typography style={{ fontSize: 14, fontFamily: "Poppins, Helvetica, sans-serif" }} color="textSecondary" gutterBottom>
                                    No Options
                        </Typography>
                            </Card>

                        } */}


                    </div>
                </Accordion>



            </div>
        )
    }


    const sendDatatoReader = (list, status) => {
        props.sendDatatoReaderScreen(list, status)
    }

    const ContentProviders = (props) => {
        console.log("contentProviders at contentProviders", props.contentData)

        return (
            <></>
        )


        // return providers.data.map(prov => (
        //     <PublisherListCard prov={prov} />
        // ))
    }


    // const ContentProvidersSide = () => {

    //     if (!contentData) {
    //         return (

    //             <></>
    //         )
    //     }


    //     return contentData.provider_details.map(prov => (
    //         <AccordianCard contentname={prov.prov_name_text} />
    //     ))
    // }


    const DeleteDialog = (props) => {
        const { provd } = props

        const [open, setOpen] = React.useState(false);

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };


        const DeleteProv = async (provDet) => {
            const query = new URLSearchParams(window.location.search)
            console.log("-------------------------", query.get('book_id'))

            const book_id = query.get('book_id')
            const response = await PUBLISHERAPI.DelContentProvider(provDet);

            if (response) {
                getEbookContent(book_id)
            }

        }




        return (
            <div>

                <div onClick={handleClickOpen}>
                    <Tooltip title="Delete Provider" >
                        <DeleteOutlinedIcon color="secondary" />
                    </Tooltip>
                </div>

                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Confirm Delete</DialogTitle>
                    <DialogContent>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center"
                        }}
                        >
                            <CloseOutlinedIcon color="secondary" style={{ fontSize: 60 }} />
                        </div>

                        <DialogContentText>
                            Are you Sure,Do you want to remove {provd.prov_name_text} from List ?
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => { DeleteProv(provd) }} color="primary">
                            Yes,Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }


    return (
        <div >



            <div style={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                {
                    // !prov || provStatus ?
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <ListSubheader
                                style={{
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                    fontSize: 14,
                                    color: "#000",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                Content Providers
                            </ListSubheader>

                            <Button variant="outlined" color="primary" onClick={handleClickOpen} >
                                <AddIcon style={{ fontFamily: "Poppins, Helvetica, sans-serif", }} />
                            </Button>

                        </div>

                        // :
                        // <div>
                        //     <ListItem key={prov.id} button >
                        //         <ListItemAvatar>
                        //             <Avatar
                        //                 alt={prov.prov_name_text}
                        //                 src={prov.prov_cover_url}
                        //             />
                        //         </ListItemAvatar>
                        //         <ListItemText id={prov.id} primary={prov.prov_name_text} />
                        //         <ListItemSecondaryAction>
                        //             <DeleteDialog provd={prov} />
                        //         </ListItemSecondaryAction>
                        //     </ListItem>
                        // </div>
                }

                <Divider />
                <div>

                    {
                        contentData && contentData.provider_details ?
                            <PublisherListCard
                                showProvName={(status) => { UpdateProvStatus(status) }}
                                providerData={(provider) => { UpdateProvData(provider) }}
                                contentData={contentData.provider_details}
                                getContentDatafromPubList={(contData) => { sendContDatatoDashboard(contData, true) }}
                            />
                            :
                            <div style={{
                                display: 'flex',
                                alignSelf: 'center',
                                justifyContent: 'center',
                                marginTop: 20
                            }}>
                                {/* <CircularProgress /> */}
                                <Alert severity="warning">No content providers found!</Alert>
                            </div>

                    }

                </div>

                <div>
                    {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                        Open form dialog
                    </Button> */}
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">

                        <DialogTitle id="form-dialog-title">Content Provider List</DialogTitle>

                        <DialogContent>
                            <DialogContentText>
                                Please select Content Provider
                            </DialogContentText>

                            <Card>
                                <CheckboxListSecondary
                                    savePubContProv={async (e, provId) => {
                                        await new Promise(async (resolve, reject) => {
                                            await savePubContProv(e, provId)
                                            resolve() // wait over
                                        })

                                        // fetch all content again
                                        dispatch(listEbookContentGet(book_id))
                                    }}
                                    ContentProviders={providers}
                                    // already mapped ..
                                    contentData={contentData.provider_details}
                                    getSelectedProv={(list) => {
                                        console.log("selected providers list", list)
                                    }} />


                            </Card>


                            {/* 
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Email Address"
                                type="email"
                                fullWidth
                            /> */}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Done
                            </Button>
                            {/* <Button onClick={handleClose} color="primary">
                                ADD
                            </Button> */}
                        </DialogActions>
                    </Dialog>
                </div>
            </div>

            <div style={{
                marginTop: 20
            }}>
                {
                    // teachPrev ?
                    <div style={{ display: "flex", justifyContent: "center", backgroundColor: "#fff" }}>
                        {/* <Link to={"/publisher-ebook/teach-preview/?book-id=" + bookId2}> */}
                        <a href={Constants.Application.PUSTAK_URL + "/books/" + bookId2}>
                            <Button color="primary">Teach Preview</Button>
                        </a>
                        {/* </Link> */}
                    </div>
                    // :
                    // <></>
                }
            </div>


        </div>
    )
}

export default PublisherListSideBar;