
export const DASHBOARDHEADERDETAILS_LIST_REQUEST = 'DASHBOARDHEADERDETAILS_LIST_REQUEST'
export const DASHBOARDHEADERDETAILS_LIST_SUCCESS = ' DASHBOARDHEADERDETAILS_LIST_SUCCESS'
export const DASHBOARDHEADERDETAILS_LIST_FAIL = 'DASHBOARDHEADERDETAILS_LIST_FAIL'

export const DASHBOARDCONTHEADERDETAILS_LIST_REQUEST = 'DASHBOARDCONTHEADERDETAILS_LIST_REQUEST'
export const DASHBOARDCONTHEADERDETAILS_LIST_SUCCESS = ' DASHBOARDCONTHEADERDETAILS_LIST_SUCCESS'
export const DASHBOARDCONTHEADERDETAILS_LIST_FAIL = 'DASHBOARDCONTHEADERDETAILS_LIST_FAIL'

export const DASHBOARDSCHOOLSDETAILS_LIST_REQUEST = 'DASHBOARDSCHOOLSDETAILS_LIST_REQUEST'
export const DASHBOARDSCHOOLSDETAILS_LIST_SUCCESS = ' DASHBOARDSCHOOLSDETAILS_LIST_SUCCESS'
export const DASHBOARDSCHOOLSDETAILS_LIST_FAIL = 'DASHBOARDSCHOOLSDETAILS_LIST_FAIL'

export const DASHBOARDSCHOOLSCONTDETAILS_LIST_REQUEST = 'DASHBOARDSCHOOLSCONTDETAILS_LIST_REQUEST'
export const DASHBOARDSCHOOLSCONTDETAILS_LIST_SUCCESS = ' DASHBOARDSCHOOLSCONTDETAILS_LIST_SUCCESS'
export const DASHBOARDSCHOOLSCONTDETAILS_LIST_FAIL = 'DASHBOARDSCHOOLSCONTDETAILS_LIST_FAIL'

export const DASHBOARDPUBLISHERDETAILS_LIST_REQUEST = 'DASHBOARDPUBLISHERDETAILS_LIST_REQUEST'
export const DASHBOARDPUBLISHERDETAILS_LIST_SUCCESS = ' DASHBOARDPUBLISHERDETAILS_LIST_SUCCESS'
export const DASHBOARDPUBLISHERDETAILS_LIST_FAIL = 'DASHBOARDPUBLISHERDETAILS_LIST_FAIL'

export const DASHBOARDCONTPROVDETAILS_LIST_REQUEST = 'DASHBOARDCONTPROVDETAILS_LIST_REQUEST'
export const DASHBOARDCONTPROVDETAILS_LIST_SUCCESS = ' DASHBOARDCONTPROVDETAILS_LIST_SUCCESS'
export const DASHBOARDCONTPROVDETAILS_LIST_FAIL = 'DASHBOARDCONTPROVDETAILS_LIST_FAIL'

export const DASHBOARDPUBGRAPHS_LIST_REQUEST = 'DASHBOARDPUBGRAPHS_LIST_REQUEST'
export const DASHBOARDPUBGRAPHS_LIST_SUCCESS = ' DASHBOARDPUBGRAPHS_LIST_SUCCESS'
export const DASHBOARDPUBGRAPHS_LIST_FAIL = 'DASHBOARDPUBGRAPHS_LIST_FAIL'

export const DASHBOARDCONTGRAPHS_LIST_REQUEST = 'DASHBOARDCONTGRAPHS_LIST_REQUEST'
export const DASHBOARDCONTGRAPHS_LIST_SUCCESS = ' DASHBOARDCONTGRAPHS_LIST_SUCCESS'
export const DASHBOARDCONTGRAPHS_LIST_FAIL = 'DASHBOARDCONTGRAPHS_LIST_FAIL'

export const DASHBOARDPUBDEVICES_LIST_REQUEST = 'DASHBOARDPUBDEVICES_LIST_REQUEST'
export const DASHBOARDPUBDEVICES_LIST_SUCCESS = ' DASHBOARDPUBDEVICES_LIST_SUCCESS'
export const DASHBOARDPUBDEVICES_LIST_FAIL = 'DASHBOARDPUBDEVICES_LIST_FAIL'

export const DASHBOARDCONTDEVICES_LIST_REQUEST = 'DASHBOARDCONTDEVICES_LIST_REQUEST'
export const DASHBOARDPCONTDEVICES_LIST_SUCCESS = 'DASHBOARDPCONTDEVICES_LIST_SUCCESS'
export const DASHBOARDCONTDEVICES_LIST_FAIL = 'DASHBOARDCONTDEVICES_LIST_FAIL'
