import { INSTDETAILS_LIST_FAIL, INSTDETAILS_LIST_REQUEST, INSTDETAILS_LIST_SUCCESS } from "../constants/instituteDetailsConstants";


function instDetailsReducer(state = { instDetails: [] }, action) {
    switch (action.type) {
        case INSTDETAILS_LIST_REQUEST:
            return { loadingInst: true }
        case INSTDETAILS_LIST_SUCCESS:
            return { loadingInst: false, instDetails: action.payload };
        case INSTDETAILS_LIST_FAIL:
            return { loadingInst: false, error: action.payload }
        default:
            return state;
    }

}

export { instDetailsReducer }