import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
} from "@material-ui/core";

import ChipInput from "material-ui-chip-input";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Search as SearchIcon } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import AddCurriculumDialog from "./slots/AddCurriculumDialog";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import MapAPI from "../../http/curriculummap";
import { useDispatch, useSelector } from "react-redux";
import { listCurriculumAllGet } from "../../redux/actions/GetAllFilesAction";
import {
  filterMaps,
  listCurriculuFilterGet,
  listCurriculumGet,
  postNewCurriculum,
} from "../../redux/actions/curriculumMapAction";
import { CURRICULUMMAP_ADD_SUCCESS } from "../../redux/constants/CurriculumMapConstants";
import { listBoardGet } from "../../redux/actions/boardDetailsAction";
import { listInstGet } from "../../redux/actions/InstituteDetailsAction";
import { listSubGet } from "../../redux/actions/subjectsAction";
import { listSubSubGet } from "../../redux/actions/subSubjectsAction";
import { listChpGet } from "../../redux/actions/chapterAction";
import { listStdGet } from "../../redux/actions/standardAction";
import Cookie from "js-cookie";
import Constants from "../../resource/Constants";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TAG = "MapListToolbar.js";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const PubDD = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <div>
      {/* <FormControl variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-age-native-simple">Select type</InputLabel>
        <Select
          native
          value={state.age}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'filled-age-native-simple',
          }}
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          <option aria-label="None" value="" />
          <option value={10} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Published or in Draft</option>
          <option value={20} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Draft</option>
          <option value={30} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Thirty</option>
        </Select>
      </FormControl> */}

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">
          Institute Type
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={state.age}
          onChange={handleChange}
          label="Institute Type"
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          <option
            aria-label="None"
            value=""
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          />
          <option
            value={10}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            Published or in Draft
          </option>
          <option
            value={20}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            Draft
          </option>
          <option
            value={30}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            Thirty
          </option>
        </Select>
      </FormControl>
    </div>
  );
};

const ActDD = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <div>
      {/* <FormControl variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-age-native-simple">Activity</InputLabel>
        <Select
          native
          value={state.age}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'filled-age-native-simple',
          }}
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          <option aria-label="None" value="" />
          <option value={10} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Any Activity</option>
          <option value={20} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>All Activities</option>
          <option value={30} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Thirty</option>
        </Select>
      </FormControl> */}

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Board</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={state.age}
          onChange={handleChange}
          label="Activity"
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          <option
            aria-label="None"
            value=""
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          />
          <option
            value={10}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            Any Activity
          </option>
          <option
            value={20}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            All Activities
          </option>
          <option
            value={30}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            Thirty
          </option>
        </Select>
      </FormControl>
    </div>
  );
};

const SubDD = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <div>
      {/* <FormControl variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-age-native-simple">Activity</InputLabel>
        <Select
          native
          value={state.age}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'filled-age-native-simple',
          }}
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          <option aria-label="None" value="" />
          <option value={10} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Any Activity</option>
          <option value={20} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>All Activities</option>
          <option value={30} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Thirty</option>
        </Select>
      </FormControl> */}

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Subject</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={state.age}
          onChange={handleChange}
          label="Activity"
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          <option
            aria-label="None"
            value=""
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          />
          <option
            value={10}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            Any Activity
          </option>
          <option
            value={20}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            All Activities
          </option>
          <option
            value={30}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            Thirty
          </option>
        </Select>
      </FormControl>
    </div>
  );

  return (
    <div>
      {/* <FormControl variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-age-native-simple">Activity</InputLabel>
        <Select
          native
          value={state.age}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'filled-age-native-simple',
          }}
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          <option aria-label="None" value="" />
          <option value={10} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Any Activity</option>
          <option value={20} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>All Activities</option>
          <option value={30} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Thirty</option>
        </Select>
      </FormControl> */}

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">
          Sub Subject
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={state.age}
          onChange={handleChange}
          label="Activity"
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          <option
            aria-label="None"
            value=""
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          />
          <option
            value={10}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            Any Activity
          </option>
          <option
            value={20}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            All Activities
          </option>
          <option
            value={30}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            Thirty
          </option>
        </Select>
      </FormControl>
    </div>
  );
};

const StdDD = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <div>
      {/* <FormControl variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-age-native-simple">Activity</InputLabel>
        <Select
          native
          value={state.age}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'filled-age-native-simple',
          }}
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          <option aria-label="None" value="" />
          <option value={10} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Any Activity</option>
          <option value={20} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>All Activities</option>
          <option value={30} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Thirty</option>
        </Select>
      </FormControl> */}

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Standard</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={state.age}
          onChange={handleChange}
          label="Activity"
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          <option
            aria-label="None"
            value=""
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          />
          <option
            value={10}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            Any Activity
          </option>
          <option
            value={20}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            All Activities
          </option>
          <option
            value={30}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            Thirty
          </option>
        </Select>
      </FormControl>
    </div>
  );
};

const MapListToolbar = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const [title, setTitle] = React.useState("");

  const [tags, setTags] = React.useState("");

  const [instType, setInstType] = useState("");
  const [board, setBoard] = useState("");
  const [clrStd, setClrStd] = useState("");
  const [sub, setSub] = useState("");
  const [subSub, setSubSub] = useState("");

  const [instType2, setInstType2] = useState("");
  const [boardType2, setBoardType2] = useState("");
  const [subjectType2, setSubjectType2] = useState("");
  const [subjectSubType2, setSubjectSubType2] = useState("");
  const [standardType2, setStandardType2] = useState("");
  const [pubId, setPubId] = useState(0);
  const [unitCount, setUnitCount] = useState(2);
  const [termNum, setTermNum] = useState(1);

  const boardDetailsGet = useSelector((state) => state.boardDetails);
  const { loading, boardDetails, error } = boardDetailsGet;

  const instDetailsGet = useSelector((state) => state.instDetails);
  const { loadingInst, instDetails, errorInst } = instDetailsGet;

  const subDetailsGet = useSelector((state) => state.subDetails);
  const { loadingSub, subDetails, errorSub } = subDetailsGet;

  const subSubDetailsGet = useSelector((state) => state.subSubDetails);
  const { loadingsubSub, subSubDetails, errorsubSub } = subSubDetailsGet;

  const stdDetailsGet = useSelector((state) => state.stdDetails);
  const { loadingStd, stdDetails, errorStd } = stdDetailsGet;

  const curriculumDetailsGet = useSelector((state) => state.curriculumMap);
  const { loadingCur, CurriculumMap, errorCur } = curriculumDetailsGet;


  // all results first time

  const curriculumAllGet = useSelector((state) => state.allCurriculumGet);
  const { loadingAllCurr, allCurrGet, errorAllBooks } = curriculumAllGet;


  // const mapList = useSelector((state) => state.curriculumMap.CurriculumMap);
  // const mapList = useSelector((state) => CurriculumMap);
  const maps = useSelector((state) => CurriculumMap);
  
  const filteredMaps1 = useSelector((state) => state.filteredMap.filteredMap);
  const allMaps = allCurrGet;

  console.log(TAG, 'maps', maps);
  let mapList = maps && maps.data ? maps.data : [];

  mapList = allMaps;

  // filter if applied..
  if (filteredMaps1 != null) {
    // && filteredMaps.length) {
    mapList = filteredMaps1;
  }

  // const instDetails = useSelector(state => state.instDetails.instDetails)
  // const boardDetails = useSelector(state => state.boardDetails.boardDetails)
  // const subDetails = useSelector(state => state.subDetails.subDetails)
  // const stdDetails = useSelector(state => state.stdDetails.stdDetails)

  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSuccessSnack, setOpenSuccessSnack] = React.useState(false);
  const [searchTxt, setSearchTxt] = React.useState("");
  const [yourChips, setYourChips] = useState([]);

  function setPublisher() {
    const userId = Cookie.get("kgtopg.guruculum.user.id");
    setPubId(userId);

    console.log(`pubId is ${userId}`);
  }

  const handleChipChange = (chips) => {
    console.log("chip change called ", chips);
    setYourChips(chips);
  };

  const handleAddChip = (chip) => {
    console.log("onAdd chip called");
    let chips = yourChips;
    chips.push(chip);
    setYourChips(chips);
  };

  const handleDeleteChip = (chip, index) => {
    console.log("onDelete chip called");

    // let chips = yourChips.filter(item => item != chip)
    let chips = yourChips.filter((item, idx) => idx !== index);
    setYourChips(chips);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleSearchChanged = (event) => {
    const { name, value } = event.target;

    setSearchTxt(value);
    console.log(TAG, `searching for map title ${value}`);
    console.log(TAG, `map list `, JSON.stringify(mapList));
    // now filter maps from curriculum maps

    let filteredMaps = [];
    // console.log(`filtered maps to redux original ${JSON.stringify(mapList)}`)
    if (mapList && mapList.length) {
      mapList.map((map, index) => {
        const title = map.map_title_text.toLowerCase();
        const val = value.toLowerCase();

        console.log(TAG, `includes check title ${title}`);
        console.log(TAG, `includes check val ${val}`);
        if (title.includes(val)) {
          filteredMaps.push(map);
        }
      });
    }

    console.log(`filtered maps to redux ${JSON.stringify(filteredMaps)}`);

    // update filteredMaps
    if(filteredMaps && filteredMaps.length)
      dispatch(filterMaps(filteredMaps));
    
    if(!value) {
      // call all by default
      // dispatch(listCurriculumAllGet());

      dispatch(filterMaps(null));
    }
  };

  const handleInstTypeChange = (event) => {
    const { name, value } = event.target;

    setInstType(value);
    dispatch(listBoardGet(value));
  };

  const handleBoardChange = (event) => {
    const { name, value } = event.target;

    setBoard(value);
    dispatch(listSubGet(value));
    dispatch(listStdGet(instType, value));
  };

  const handleClrStdChange = (event) => {
    const { name, value } = event.target;

    setClrStd(value);
  };

  const handleSubChange = (event) => {
    const { name, value } = event.target;
    console.log("handlesub ", value);

    setSub(value);
    dispatch(listSubSubGet(value));
  };

  const handleSubSubChange = (event) => {
    const { name, value } = event.target;
    console.log("handlesubsub ", event.target);

    console.log("value", value);
    setSubSub(value);

    // dispatch(listCurriculuFilterGet(instType2, boardType2, standardType2, value))
    // call sub subjects
  };

  const handleTagsChange = (event) => {
    const { name, value } = event.target;

    setTags(value);
  };

  const handleClick = () => {
    setOpen2(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  const handleTitleChange = (event) => {
    const { name, value } = event.target;
    setTitle(value);
  };

  // const mapList = props.mapList;
  const currMap = props.currMap;

  const dispatch = useDispatch();

  const handleCreateMap = async () => {
    const userId = Cookie.get("kgtopg.guruculum.user.id");

    if (
      !title ||
      title.length < 10 ||
      !instType ||
      !board ||
      !clrStd ||
      !sub 
      // || !unitCount
    ) {
      // alert('Please enter title..');
      setOpenSnack(true);
      return;
    }

    const tags2 = yourChips.join(",");

    const body = {
      institute_type_id: instType,
      institute_board_id: board,
      classroom_std: clrStd,
      subject_id: sub,
      // sub_subject_id: subSub,
      sub_subject_id: 0,
      // subject_sub_name: subSub,
      unit_count: unitCount,
      map_title_text: title,
      publisher_id: userId,
      map_tags: tags2,
      term_number: termNum,
    };

    const resp = await MapAPI.postSaveMap(body);
    console.log(`post save response ${JSON.stringify(resp)}`);
    const mapId = resp.sub_subject_id;

    // now call dispatch action to save to store and refresh

    const newObj = {
      map_id: mapId,
      publisher_id: userId,
      subject_id: sub,
      sub_subject_id: subSub,
      // subject_sub_name: subSub,
      draft_updated_date: "2021-01-01",
      is_published: true,
      institute_type_id: instType,
      institute_board_id: board,
      classroom_std: clrStd,
      unit_count: unitCount,
      map_title_text: title,
      map_tags: tags,
      term_number: termNum,
      published_date: "2021-01-01",
      Total_lesson_count: 15,
      units: [],
    };

    // mapList.push(newObj);
    const newData = {
      newObj: newObj,
      mapList: mapList,
    };

    console.log(`newData ${JSON.stringify(newData)}`);
    // call middleware thunk
    // await new Promise((resolve, reject) => {
    //   dispatch(postNewCurriculum(newData))
    //   resolve()
    // })

    // fetch all
    // await new Promise((resolve, reject) => {
    //   dispatch(listCurriculumGet())
    //   resolve()
    // })

    // close dialog
    handleClose();

    // show snackbar
    setOpenSuccessSnack(true);

    // clear all state
    // setSub("");
    // setBoard("");
    // setClrStd("");
    setTitle("");
    // setUnitCount("");
    // setInstType("");
    setTags("");

    // reload from parent
    props.onReload(true); // reload all
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleCloseSuccessSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccessSnack(false);
  };

  const handleUnitChange = (event) => {
    const { name, value } = event.target;

    setUnitCount(value);
  };

  const handleTermChange = (event) => {
    const { name, value } = event.target;

    setTermNum(value);
  };

  const handleBoardType2Change = (event) => {
    console.log("called handleBoardChange");
    console.log(`new board  ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    console.log(`board selected value ${value}`);

    setBoardType2(value);
    dispatch(listStdGet(instType, value));
    dispatch(listSubGet(value));

    dispatch(
      listCurriculuFilterGet(instType2, value, standardType2, subjectType2)
    );
  };

  const handleSubType2Change = (event) => {
    console.log("called handleSubChange");
    console.log(`new subject ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setSubjectType2(value);
    dispatch(listSubSubGet(value));

    dispatch(
      listCurriculuFilterGet(instType2, boardType2, standardType2, value)
    );
  };

  // changing sub subject
  const handleSubSubType2Change = (event) => {
    console.log("called handleSubSubChange");
    console.log(`new sub subject ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setSubjectSubType2(value);

    // get maps
    dispatch(
      listCurriculuFilterGet(instType2, boardType2, standardType2, value)
    );
  };

  const handleInstType2Change = (event) => {
    console.log("called handleInstChange");
    console.log(`new institute type ${event}`);
    const name = event.target.name;
    const value = event.target.value;
    // setState({
    // ...state,
    // [name]: event.target.value,
    // });
    dispatch(listBoardGet(value));
    setInstType2(value);
    dispatch(
      listCurriculuFilterGet(value, boardType2, standardType2, subjectType2)
    );
  };

  const handleStdType2Change = (event) => {
    console.log("called handleStdChange");
    console.log(`new standard type ${event}`);
    const name = event.target.name;
    const value = event.target.value;
    // setState({
    // ...state,
    // [name]: event.target.value,
    // });

    setStandardType2(value);
    dispatch(
      listCurriculuFilterGet(instType2, boardType2, value, subjectType2)
    );
  };

  async function getData() {
    await new Promise((resolve, reject) => {
      dispatch(listInstGet());

      resolve();
    });
  }

  useEffect(() => {
    // set up logged in publisher
    setPublisher();
    // get boards, subjects and standards
    getData();

    return <></>;
  }, []);

  return (
    <Box {...props}>
      <div
        style={{
          display: "flex",
          marginTop: 10,
          fontFamily: "Poppins, Helvetica, sans-serif",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <LibraryBooksIcon /> */}
          <Typography
            className={classes.title}
            id="tableTitle"
            component="div"
            variant="h2"
            style={{
              marginTop: 10,
            }}
          >
            Curriculum Index
          </Typography>
        </div>

        {/* SEARCH BAR */}
        <Paper>
          <div style={{ padding: 0, minWidth: 400 }}>
            <TextField
              InputProps={{
                style: {
                  // padding: 0
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon
                        style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                      />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              placeholder="Search for curriculum"
              variant="outlined"
              style={{
                fontFamily: "Poppins, Helvetica, sans-serif",
                width: "100%",
                padding: 0,
              }}
              onChange={handleSearchChanged}
            />
          </div>
        </Paper>

        <div>
          <Box display="flex" justifyContent="flex-end">
            {/* <div>
              <Button style={{ fontFamily: "Poppins, Helvetica, sans-serif" }} onClick={handleClick}>
                Import
              </Button>
              <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
                <Alert onClose={handleClose2} severity="info">
                  This feature is coming soon!
                </Alert>
              </Snackbar>
            </div>

            <div>
              <Button style={{ fontFamily: "Poppins, Helvetica, sans-serif" }} onClick={handleClick}>
                Export
              </Button>
              <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
                <Alert onClose={handleClose2} severity="info">
                  This feature is coming soon!
                </Alert>
              </Snackbar>
            </div> */}

            <Button
              color="primary"
              variant="contained"
              onClick={handleClickOpen}
              style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
            >
              Add curriculum
            </Button>
          </Box>
        </div>
      </div>
      <div>
        {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <Typography variant="h3">Create New Curriculum </Typography>
            <Typography variant="caption">
              New Curriculum lets you setup a new curriculum unit sets with time
              and pacing.
            </Typography>
          </DialogTitle>
          <DialogContent
            style={{
              overflow: "auto",
              overflowX: "initial",
              scrollbarWidth: "thin",
            }}
          >
            {/* <DialogContentText style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>
            
            </DialogContentText> */}
            <TextField
              required
              autoFocus
              margin="dense"
              id="name"
              label="Curriculum Name"
              type="text"
              fullWidth
              defaultValue={title}
              onChange={handleTitleChange}
              helperText="Please enter at least 10 characters eg: (Maths 10th CBSE)"
              style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
            />

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <FormControl
                variant="filled"
                style={{ width: "48%", marginTop: 10, marginBottom: 10 }}
                required
              >
                <InputLabel
                  htmlFor="filled-age-native-simple"
                  style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                >
                  Institute Type
                </InputLabel>
                <Select
                  native
                  value={instType}
                  onChange={handleInstTypeChange}
                  inputProps={{
                    name: "age",
                    id: "filled-age-native-simple",
                  }}
                  style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                >
                  <option
                    key={-1}
                    value={-1}
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Select
                  </option>

                  {instDetails && instDetails.data ? (
                    instDetails.data.map((type, index) => {
                      console.log(`single inst type ${JSON.stringify(type)}`);
                      return (
                        <option
                          key={index}
                          value={type.institute_type_id}
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          {type.institute_type_name}
                        </option>
                      );
                    })
                  ) : (
                    <option key={-2} value={-2}>
                      No Types
                    </option>
                  )}
                </Select>
              </FormControl>

              {/* institute board */}
              <FormControl
                variant="filled"
                style={{
                  width: "50%",
                  marginTop: 10,
                  marginRight: 5,
                  marginBottom: 10,
                }}
                required
              >
                <InputLabel
                  htmlFor="filled-age-native-simple"
                  style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                >
                  Board of Education
                </InputLabel>
                <Select
                  native
                  value={board}
                  onChange={handleBoardChange}
                  inputProps={{
                    name: "age",
                    id: "filled-age-native-simple",
                  }}
                  style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                >
                  <option
                    key={-1}
                    value={-1}
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Select
                  </option>
                  {boardDetails && boardDetails.data ? (
                    boardDetails.data.map((brd, index) => {
                      return (
                        <option
                          key={index}
                          value={brd.institute_board_id}
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          {brd.institute_board_name}
                        </option>
                      );
                    })

                    
                  ) : (
                    <option key={-2} value={-2}
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                    >
                      No Boards
                    </option>
                  )}

                  
                  <option value={Constants.Application.CUSTOM_BOARD_ID}
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                  >Integrated Curriculum</option>
                  
                </Select>
              </FormControl>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <FormControl
                variant="filled"
                style={{ width: "48%", marginTop: 10, marginBottom: 10 }}
                required
              >
                <InputLabel
                  htmlFor="filled-age-native-simple"
                  style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                >
                  Class
                </InputLabel>
                <Select
                  native
                  value={clrStd}
                  onChange={handleClrStdChange}
                  inputProps={{
                    name: "age",
                    id: "filled-age-native-simple",
                  }}
                  style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                >
                  <option
                    key={-1}
                    value={-1}
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Select
                  </option>
                  {stdDetails && stdDetails.data && stdDetails.data ? (
                    stdDetails.data.map((sb, index) => {
                      return (
                        <option
                          key={index}
                          value={sb.id}
                          style={{ fontSize: 13, color: "#464E5F" }}
                        >
                          {sb.standard}
                        </option>
                      );
                    })
                  ) : (
                    <option key={-2} value={-2}>
                      No Standards
                    </option>
                  )}
                </Select>
              </FormControl>

              {/* subjects */}
              <FormControl
                variant="filled"
                style={{
                  width: "50%",
                  marginTop: 10,
                  marginBottom: 10,
                  marginRight: 5,
                }}
                required
              >
                <InputLabel
                  htmlFor="filled-age-native-simple"
                  style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                >
                  Subject
                </InputLabel>
                <Select
                  native
                  value={sub}
                  onChange={handleSubChange}
                  inputProps={{
                    name: "age",
                    id: "filled-age-native-simple",
                  }}
                  style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                >
                  <option
                    key={-1}
                    value={-1}
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Select
                  </option>
                  {subDetails && subDetails.data && subDetails.data.subjects ? (
                    subDetails.data.subjects.map((sb, index) => {
                      return (
                        <option
                          key={index}
                          value={sb.subject_id}
                          style={{ fontSize: 13, color: "#464E5F" }}
                        >
                          {sb.subject_name}
                        </option>
                      );
                    })
                  ) : (
                    <option key={-2} value={-2}>
                      No Subjects
                    </option>
                  )}
                </Select>
              </FormControl>
            </div>

            {/* subject and sub subject */}
            {/* <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row'
              }}> */}

            {/* 
              <div style={{
                width: '70%'
              }}>

              </div>

              <div
                style={{
                  marginTop: 10,
                  marginLeft: 10,
                  width: '25%'
                }}>
                <TextField
                  autoFocus
                  required
                  defaultValue={unitCount}
                  onChange={handleUnitChange}
                  // margin="dense"
                  id="tags"
                  label="Unit Count"
                  type="number"
                  fullWidth
                  variant="outlined"
                  size='large'
                  style={{ width: '100%', fontFamily: "Poppins, Helvetica, sans-serif" }}
                />
              </div> */}

            {/* 
              <FormControl variant="filled" style={{ width: '48%', marginTop: 10, marginBottom: 10 }} required>
                <InputLabel htmlFor="filled-age-native-simple" style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Sub Subject</InputLabel>
                <Select
                  native
                  value={subSub}
                  onChange={handleSubSubChange}
                  inputProps={{
                    name: 'age',
                    id: 'filled-age-native-simple',
                  }}
                  style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                >

                  <option key={-1} value={-1} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Select</option>
                  {subSubDetails && subSubDetails.data && subSubDetails.data ?
                    subSubDetails.data.map((ssb, index) => {
                      console.log("the data of ssb are", ssb);
                      return (
                        <option key={index} value={ssb.subject_sub_id} style={{ fontSize: 13, color: "#464E5F" }}>{ssb.subject_sub_name}</option>
                      )
                    })
                    :
                    <option key={-2} value={-2}>No Sub Subjects</option>
                  }

                </Select>
              </FormControl> */}
            {/* 

            </div>

            <div
              style={{
                width: '100%'
              }}> */}
            {/* <TextField
                autoFocus
                required
                defaultValue={termNum}
                onChange={handleTermChange}
                margin="dense"
                id="tags"
                label="Term Num"
                type="number"
                fullWidth
                style={{
                  width: '50%', fontFamily: "Poppins, Helvetica, sans-serif",
                  marginRight: 5
                }}
              /> */}

            {/* </div> */}

            {/* 
            <TextField
              autoFocus
              required
              defaultValue={tags}
              onChange={handleTagsChange}
              margin="dense"
              id="tags"
              label="Tags"
              type="text"
              fullWidth
              style={{ width: '100%', fontFamily: "Poppins, Helvetica, sans-serif" }}
            /> */}
            {/* 
            <div style={{
              marginTop: 10
            }}>
              <ChipInput
                defaultValue={yourChips}
                onChange={(chips) => handleChipChange(chips)}
                fullWidth={true}
                fullWidthInput={true}
                placeholder={`Add tags`}
                multiline={true}
              /> */}
            {/* <ChipInput
                value={yourChips}
                onAdd={(chip) => handleAddChip(chip)}
                onDelete={(chip, index) => handleDeleteChip(chip, index)}
              /> */}
            {/* </div> */}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleCreateMap}
              color="primary"
              style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
            >
              Create
            </Button>
            <Snackbar
              open={openSnack}
              autoHideDuration={6000}
              onClose={handleCloseSnack}
            >
              <Alert onClose={handleCloseSnack} severity="warning">
                Please fill in mandatory details!
              </Alert>
            </Snackbar>

            <Snackbar
              open={openSuccessSnack}
              autoHideDuration={6000}
              onClose={handleCloseSuccessSnack}
            >
              <Alert onClose={handleCloseSuccessSnack} severity="success">
                Curriculum saved successfully..
              </Alert>
            </Snackbar>
          </DialogActions>
        </Dialog>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "flex-start",
          padding: 0,
          marginTop: 20,
          marginBottom: 10,
          overflow: "hidden",
          overflowX: "hidden",
          scrollbarWidth: "thin",
          background: "none",
        }}
      >
        <div
          style={{
            width: "25%",
            paddingRight: 5,
          }}
        >
          {instDetails ? (
            <div>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  Institute Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={instType2}
                  onChange={handleInstType2Change}
                  label="Institute Type"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  {loadingInst && instDetailsGet ? (
                    console.log("loading trueeee")
                  ) : instDetails &&
                    instDetails.data &&
                    instDetails.data.length != 0 ? (
                    instDetails.data.map((list, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={list.institute_type_id}
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          {list.institute_type_name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      No Institutes
                    </MenuItem>
                  )}

                </Select>
                {/* 
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12
                    }}>
                    Institute
    </span>
                </span> */}
              </FormControl>
            </div>
          ) : (
            <div style={{ display: "flex" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  Institute Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={instType2}
                  onChange={handleInstType2Change}
                  label="Institute Type"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    No Institutes
                  </MenuItem>
                </Select>
                {/* 
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12
                    }}>
                    Institute
</span>
                </span> */}
              </FormControl>
            </div>
          )}
        </div>

        <div
          style={{
            width: "25%",
            paddingRight: 5,
          }}
        >
          {boardDetailsGet &&
          boardDetails &&
          boardDetails.length != 0 &&
          boardDetails.data ? (
            <div style={{ display: "flex" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  Board
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={boardType2}
                  onChange={handleBoardType2Change}
                  label="Board"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  {
                    loading && boardDetailsGet ? (
                      console.log("loading trueeee")
                    ) : boardDetails && boardDetails.length != 0 ? (
                      boardDetails.data.map((list, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={list.institute_board_id}
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            {list.institute_board_name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Please Select Institute Type
                      </MenuItem>
                    )

                    // boardDetailsMock.map((list, index) => {
                    //     return (
                    //         <MenuItem key={index} value={index} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_board_name}</MenuItem>
                    //     )
                    // })
                    
                    
                  }

                  
                  <MenuItem
                    value={Constants.Application.CUSTOM_BOARD_ID}
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Own board
                  </MenuItem>
                </Select>
                {/* <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12
                    }}>
                    Board
                  </span>
                </span> */}
              </FormControl>
            </div>
          ) : (
            <div style={{ display: "flex" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  Board
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={boardType2}
                  onChange={handleBoardType2Change}
                  label="Board"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    No Boards{" "}
                  </MenuItem>
                </Select>
                {/* <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12
                    }}>
                    Board
                </span>
                </span> */}
                  <MenuItem
                    value={Constants.Application.CUSTOM_BOARD_ID}
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Own board
                  </MenuItem>
              </FormControl>
            </div>
          )}
        </div>

        <div
          style={{
            width: "25%",
            paddingRight: 5,
          }}
        >
          {/* standards */}

          {stdDetailsGet ? (
            <div style={{ display: "flex" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  Standard
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={standardType2}
                  onChange={handleStdType2Change}
                  label="Standard"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  {
                    loadingStd && stdDetailsGet ? (
                      console.log("loading trueeee")
                    ) : stdDetails.length != 0 ? (
                      stdDetails.data.map((list, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={list.id}
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            {list.standard}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Please Select Institute Type
                      </MenuItem>
                    )

                    // standardsMockData.map((list, index) => {
                    //     return (
                    //         <MenuItem key={index} value={index} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.stand_name}</MenuItem>
                    //     )
                    // })
                  }
                </Select>
                {/* <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12
                    }}>
                    Standard
                    </span>
                </span> */}
              </FormControl>
            </div>
          ) : (
            <div style={{ display: "flex" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  Standard
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={standardType2}
                  onChange={handleStdType2Change}
                  label="Standard"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    No Standards
                  </MenuItem>
                </Select>
                {/* <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12
                    }}>
                    Standard
        </span>
                </span> */}
              </FormControl>
            </div>
          )}
        </div>

        <div
          style={{
            width: "25%",
            paddingRight: 5,
          }}
        >
          {/* subjects */}

          {subDetails ? (
            <div style={{ display: "flex" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  Subject
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={subjectType2}
                  onChange={handleSubType2Change}
                  label="Subject"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  {loadingSub && subDetails ? (
                    <MenuItem
                      key={0}
                      value="Please Select Board"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      {" "}
                      Please Select Board{" "}
                    </MenuItem>
                  ) : subDetails &&
                    subDetails.data &&
                    subDetails.data.subjects.length != 0 ? (
                    subDetails.data.subjects.map((list, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={list.subject_id}
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          {list.subject_name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please Select Institute Type
                    </MenuItem>
                  )}
                </Select>
                {/* <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12
                    }}>
                    Subject
                    </span>
                </span> */}
              </FormControl>
            </div>
          ) : (
            <div style={{ display: "flex" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  Subject
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={subjectType2}
                  onChange={handleSubType2Change}
                  label="Subject"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    No Subjects{" "}
                  </MenuItem>
                </Select>
                {/* <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12
                    }}>
                    Subject
                  </span>
                </span> */}
              </FormControl>
            </div>
          )}
        </div>

        {/*
        <div style={{
          width: '20%',
          paddingRight: 10
        }}>
         
          {subSubDetails ?

            <div style={{ display: "flex", }}>
              <FormControl variant="outlined" className={classes.formControl}>

                <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", }}>Sub Subject</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={subjectSubType2}
                  onChange={handleSubSubType2Change}
                  label="Sub Subject"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >


                  {loadingsubSub && subSubDetails ?

                    <MenuItem key={0} value="Please Select Board" style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}> Please Select Board  </MenuItem>

                    :
                    (subSubDetails && subSubDetails.data && subSubDetails.data.length != 0) ?

                      subSubDetails.data.map((list, index) => {
                        return (
                          <MenuItem key={index} value={list.subject_sub_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.subject_sub_name}</MenuItem>

                        )
                      })
                      :

                      <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Please Select Institute Type</MenuItem>

                  }

                </Select>
                
              </FormControl>
            </div>

            :
            <div style={{ display: "flex", }}>
              <FormControl variant="outlined" className={classes.formControl}>

                <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", }}>Sub Subject</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={subjectType2}
                  onChange={handleSubType2Change}
                  label="Sub Subject"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Subjects </MenuItem>
                </Select>
                
              </FormControl>
            </div>

          }
        </div>
        */}
      </div>

      {/* <div style={{ display: "flex", width:"100%"}}>
        <Card>
          <CardContent>
            <div>
              <TextField

                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon style={{ fontFamily: "Poppins, Helvetica, sans-serif" }} />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}

                placeholder="Search for curriculum map"
                variant="outlined"
                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
              />
            </div>

            <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
              <PubDD />
              <div style={{ width: 10 }} />
              <ActDD />
            </div>
          </CardContent>
        </Card>
      </div> */}
    </Box>
  );
};

export default MapListToolbar;
