import axios from "axios";
import Constants from "../../resource/Constants";
import { ADMIN_ROLES_REQUEST, ADMIN_ROLES_SUCCESS, ADMIN_ROLES_FAIL } from "../constants/adminRolesConstants";

const updateAdminRoles = (data) => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_ROLES_REQUEST })

        console.log("update admin roles redux", data)
        dispatch({ type: ADMIN_ROLES_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: ADMIN_ROLES_FAIL, payload: error.message })

    }
}

export { updateAdminRoles }
