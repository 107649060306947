/**
 * axios main configuration
 */

import axios from 'axios';
import Constants from '../resource/Constants';
// import UserSession from '../resource/UserSession';

export default axios.create({
    baseURL: Constants.Application.PLATFORM_END_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        // 'Authorization': 'Bearer' + UserSession.authtoken,

    }
})
