/**
 * @project kgtopg - schooler - partner
 * publisher data services
 */
import API from "./http";
import axios from "axios";
import qs from "querystring";
import Constants from "../resource/Constants";
import Cookie from 'js-cookie';

const TAG = 'platform.js';
const PLATFORMAPI = {
    async getChapTopics(chpId) {
        
        console.log(TAG, "getChapTopics api is getting called")
        try {
            const URL = Constants.Application.PLATFORM_END_URL + '/chapter-topic/?chapterid=' + chpId;
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            console.log(TAG, "getChapTopics api url", URL);

            const options = {
                method: "GET",
                url: URL,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log(TAG, "getChapTopics api response", response)
            if (response) {
                return response.data;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

}

export default PLATFORMAPI;