
/**
 * quiz planner
 * drawer
 */

import React, { Component, useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import PropTypes from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import LessonPlanAPI from '../../../http/lessonplanquiz';
import Constants from '../../../resource/Constants';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid2 from '@mui/material/Grid'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import AddBoxIcon from '@material-ui/icons/AddBox';

import MenuBookIcon from '@material-ui/icons/MenuBook';
import Autocomplete from "@material-ui/lab/Autocomplete";

import InputLabel2 from '@mui/material/InputLabel';
import MenuItem2 from '@mui/material/MenuItem';
import FormControl2 from '@mui/material/FormControl';
import Select2 from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';

import Alert2 from '@mui/material/Alert';
import Stack2 from '@mui/material/Stack';

import Dialog2 from '@mui/material/Dialog';
import DialogActions2 from '@mui/material/DialogActions';
import DialogContent2 from '@mui/material/DialogContent';
import DialogContentText2 from '@mui/material/DialogContentText';
import DialogTitle2 from '@mui/material/DialogTitle';

import Button2 from '@mui/material/Button';

import {
    Avatar,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Typography,
    TextField,
} from "@material-ui/core";
import styled from 'styled-components';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const options = [
    'None',
    'Atria',
    'Callisto',
    'Dione',
    'Ganymede',
    'Hangouts Call',
    'Luna',
    'Oberon',
    'Phobos',
    'Pyxis',
    'Sedna',
    'Titania',
    'Triton',
    'Umbriel',
];

const itemData = [
    {
        img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
        title: 'Breakfast',
    },
    {
        img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
        title: 'Burger',
    },
    {
        img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
        title: 'Camera',
    },
    {
        img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
        title: 'Coffee',
    },
    /*
    {
        img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
        title: 'Hats',
    },
    {
        img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
        title: 'Honey',
    },
    {
        img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
        title: 'Basketball',
    },
    {
        img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
        title: 'Fern',
    },
    {
        img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
        title: 'Mushrooms',
    },
    {
        img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
        title: 'Tomato basil',
    },
    {
        img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
        title: 'Sea star',
    },
    {
        img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
        title: 'Bike',
    },
    */
];

const useStyles3 = makeStyles({
    list: {
        width: 250,
        minWidth: 250
    },
    fullList: {
        width: 'auto',
    },
    fullWidth: {
        width: '100%',
        minWidth: '100%'
    }
});


const useStyles2 = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const useStyles4 = makeStyles((theme) => ({
    root: {
        width: '100%',
        // maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
}));

function ConfirmationDialogRaw(props) {
    const { onClose, value: valueProp, open, ...other } = props;
    const [value, setValue] = React.useState(valueProp);
    const radioGroupRef = React.useRef(null);

    React.useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(value);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">Phone Ringtone</DialogTitle>
            <DialogContent dividers>
                <RadioGroup
                    ref={radioGroupRef}
                    aria-label="ringtone"
                    name="ringtone"
                    value={value}
                    onChange={handleChange}
                >
                    {options.map((option) => (
                        <FormControlLabel value={option} key={option} control={<Radio />} label={option} />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleOk} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialogRaw.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        width: '80%',
        maxHeight: 435,
    },
}));

function ConfirmationDialog() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('Dione');

    const handleClickListItem = () => {
        setOpen(true);
    };

    const handleClose = (newValue) => {
        setOpen(false);

        if (newValue) {
            setValue(newValue);
        }
    };

    return (
        <div className={classes.root}>
            <List component="div" role="list">
                <ListItem button divider disabled role="listitem">
                    <ListItemText primary="Interruptions" />
                </ListItem>
                <ListItem
                    button
                    divider
                    aria-haspopup="true"
                    aria-controls="ringtone-menu"
                    aria-label="phone ringtone"
                    onClick={handleClickListItem}
                    role="listitem"
                >
                    <ListItemText primary="Phone ringtone" secondary={value} />
                </ListItem>
                <ListItem button divider disabled role="listitem">
                    <ListItemText primary="Default notification ringtone" secondary="Tethys" />
                </ListItem>
                <ConfirmationDialogRaw
                    classes={{
                        paper: classes.paper,
                    }}
                    id="ringtone-menu"
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    value={value}
                />
            </List>
        </div>
    );
}

function AlignItemsList(props) {
    const classes = useStyles4();
    const quiz = props.quiz;

    console.log('quiz in alignitemslist ', JSON.stringify(quiz))

    const onDeleteQuiz = (id) => {
        props.onDeleteQuiz(id);
    }

    return (
        <List className={classes.root}>
            {(quiz && quiz.length) ?
                quiz.map((qstn, index) => {
                    // const qst = JSON.parse(qstStr)
                    const qst = qstn.question
                    console.log('qst in alignitemslist ', qst)

                    let qType = 'Multiple Choice';
                    console.log(`qtype from qst ${qst.quiz_question_type_id}`)
                    console.log(`qtype static print ${Constants.Application.QUIZ.ID_QTYPE_TRUE_FALSE}`)
                    switch (qst.quiz_question_type_id) {
                        case Constants.Application.QUIZ.ID_QTYPE_MULTIPLE_CHOICE:
                            qType = 'Multiple Choice';
                            break;
                        case Constants.Application.QUIZ.ID_QTYPE_MULTIPLE_SELECT:
                            qType = 'Multiple Select';
                            break;
                        case Constants.Application.QUIZ.ID_QTYPE_TRUE_FALSE:
                            qType = 'True Or False';
                            console.log('qtype ', qType)
                            break;
                        case Constants.Application.QUIZ.ID_QTYPE_TEXT_TYPE:
                            qType = 'Short Answer';
                            break;
                        default:
                            qType = 'None'
                    }

                    console.log('qtype in alignitemslist ', qType)

                    return (
                        <div key={index}>
                            <ListItem alignItems="flex-start"
                            >
                                <ListItemAvatar>
                                    <Avatar alt={qType} src="/static/images/avatar/1.jpg" />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={qType}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"

                                            >
                                                {qst.question_text}
                                            </Typography>
                                            {` — Time ${qst.question_time}`}
                                        </React.Fragment>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}>
                                        <IconButton edge="end" aria-label="delete"

                                            onClick={() => { console.log('click question'); props.onOpenQuiz(qst.quiz_id, qst.question_id, qst, qst.quiz_question_type_id) }}
                                        >
                                            <EditIcon
                                                style={{
                                                    color: 'gray'
                                                }} />
                                        </IconButton>

                                        <IconButton edge="end" aria-label="delete"

                                            onClick={() => {
                                                onDeleteQuiz(qst.quiz_id)
                                            }}>
                                            <DeleteIcon
                                                style={{
                                                    color: 'red'
                                                }} />
                                        </IconButton>
                                    </div>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </div>
                    )
                })
                :
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt="No Quiz" src="/static/images/avatar/1.jpg" />
                    </ListItemAvatar>
                    <ListItemText
                        primary="No Quiz Created Yet!"
                        secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    No questions created
                                </Typography>
                                {" — Start creating questions for quiz"}
                            </React.Fragment>
                        }
                    />
                </ListItem>
            }


            {/* dummy */}
            {/* 
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Summer BBQ"
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                to Scott, Alex, Jennifer
                </Typography>
                            {" — Wish I could come, but I'm out of town this…"}
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Oui Oui"
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                Sandra Adams
                </Typography>
                            {' — Do you have Paris recommendations? Have you ever…'}
                        </React.Fragment>
                    }
                />
            </ListItem>
         */}
        </List>
    );
}

/* 
function getUnpubLessons() {

    // get your unpublished quiz
    const url = '<%= Constants.API_URL %>/quiz/get-unpub-quiz/<%= sLsn.getStaff_lesson_id() %>';

    $.ajax({
        url: url,
        data: 'sid=' + Math.random(),
        async: true,
        method: 'GET',
        success: function (response) {

            // success
            console.log('get unpublished lessons response ' + JSON.stringify(response));

            $('#qlist').html('loading..');
            let qHTML = '';
            let qId = 0;
            let type = 201;
            let typeStr = '';
            let qNum = 1;
            let lbl;
            let bglight = 'bg-light-success'
            if (response && response.length > 0) {

                //					qHTML += '<div class="list-group">';
                //					qHTML += '<div class="list list-hover">';
                qHTML += '<div class="card-body pt-0">';
                response.map(qz => {
                    type = qz.quiz_question_type_id;
                    switch (type) {
                        case parseInt('<%= Constants.ID_QTYPE_MULTIPLE_CHOICE %>'):
                            typeStr = 'Multiple choice';
                            lbl = 'label-danger';
                            bglight = 'bg-light-info';
                            break;
                        case parseInt('<%= Constants.ID_QTYPE_MULTIPLE_SELECT %>'):
                            typeStr = 'Multiple select';
                            lbl = 'label-success';
                            bglight = 'bg-light-warning';
                            break;
                        case parseInt('<%= Constants.ID_QTYPE_TRUE_FALSE %>'):
                            typeStr = 'True Or False';
                            lbl = 'label-info';
                            bglight = 'bg-light-danger';
                            break;
                        case parseInt('<%= Constants.ID_QTYPE_PICTURE_CHOICE %>'):
                            typeStr = 'Picture Choice';
                            lbl = 'label-warning';
                            bglight = 'bg-light-success';
                            break;
                        case parseInt('<%= Constants.ID_QTYPE_TEXT_TYPE %>'):
                            typeStr = 'Short Answer';
                            lbl = 'label-danger';
                            bglight = 'bg-light-info';
                            break;
                        case parseInt('<%= Constants.ID_QTYPE_MATCH_FLW %>'):
                            typeStr = 'Match The Following';
                            lbl = 'label-danger';
                            bglight = 'bg-light-info';
                            break;
                    }

                    if (((qNum % 2) == 0)) {

                    } else if (((qNum % 3) == 0)) {

                    } else if (((qNum % 1) == 0)) {

                    } else {

                    }

                    qHTML += '<div  onmouseover="onMouseHoverd(this)" onmouseout="onMouseOutd(this)" class="d-flex justify-content-between text-left align-items-center mb-9 ' + bglight + ' rounded p-5">' +

                        '<div class="symbol symbol-40 symbol-light mr-4">' +
                        '<span class="symbol-label bg-hover-white">' +
                        '<span class="label ' + lbl + '">' + qNum + '</span>' +
                        '</span>' +
                        '</div>' +

                        '<div class="d-flex flex-column flex-grow-1 mr-2">' +
                        '<div class="d-flex justify-content-start">' +
                        '<span class="text-muted font-weight-bold mr-5">' + (typeStr.toUpperCase()) + '</span>' +

                        '<span><i class="fa fa-clock-o" style="color: #808080bf;"></i>&nbsp;<small style="font-size:1em;">' + qz.question_time + '</small></span>' +
                        '</div>' +
                        '<a href="#" class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">' + qz.question_text + '</a>' +

                        '</div>' +
                        '<div class="qst-close-btn" style="display: none">' +
                        //								      '<div class="dot" style="font-size: 1.5em; font-weight: bold; background: #CCCCCC; width: 30px; height: 30px; border-radius: 50%;">' + 
                        '<span onclick="onDelQuizClicked(\'' + qz.quiz_id + '\', this.parentNode.parentNode)" style="cursor: pointer">' +
                        '<i class="fa fa-times-circle fa-2x text-danger"></i>' +
                        '</span>' +
                        //								      '</div>' + 
                        '</div>' +
                        //'<span class="font-weight-bolder text-warning py-1 font-size-lg">&nbsp;</span>'+

                        '</div>'

                    ++qNum;
                });
                qHTML += '</div>';


                $('#qlist').html(qHTML);
            } else {
                let none = '<div class="p-4">' +
                    '<div class="alert alert-success mb-5 p-5" role="alert" id="noresults">' +
                    '<h4 class="alert-heading">No quiz created yet!</h4>' +
                    '<p>No quiz has been planned recently. Please use the button above to prepare your questions</p>' +
                    '<div class="border-bottom border-white opacity-20 mb-5"></div>' +
                    '<p class="mb-0">You can close this window after setting up the quiz questions.</p>' +
                    '</div>' +
                    '</div>';
                $('#qlist').html(none);
            }
            
        },
        error: function (error) {
            // error page
            console.log('get unpublished lessons error ' + JSON.stringify(error));
        }
    });

}


    function submitOpt() {	
        //debugger
        isinputCheck = false;
        quizQuestion = true;
        let dateStr;
        let today = new Date();
        dateStr = today.getYear() + '-' + today.getMonth() + '-' + today.getDate();
        console.log('datestr ' + dateStr);
        console.log('submitting multiple option quiz');
        if(Validate()) {
        	
            let timeroptMin = document.getElementById("timeroptm");
            let timeroptSec = document.getElementById("timeropts");
            let timerOpt = timeroptMin.value +":"+ timeroptSec.value;
            //let timerOpt = document.getElementById('timeropt');
            console.log('timer opt ' + timerOpt);
            console.log('timer opt value ' + timerOpt);
            let formData = {
                    "classroom_id": '<%= sLsn.getClassroom_id() %>',
                    "staff_id": '<%= sLsn.getStaff_id() %>',
                    "staff_lesson_id": '<%= sLsn.getStaff_lesson_id() %>',
                    "subject_id": '<%= sLsn.getSubject_id() %>',
                    "chapter_id": '<%= sLsn.getChapter_id() %>',
                    "quiz_title": "abcd",
                    "scheduled_date": "2020-07-02",
                    "quiz_ended": 0,
                    "question_type": '<%= Constants.ID_QTYPE_MULTIPLE_CHOICE %>',
                    "question_text":$("#qstOpt").text(),
                    "question_time":'00:'+timerOpt, //'00:'+$(event.target.closest('div')).find('input').val(), // timerOpt.value,
                    "question_url": '',
                    "question_topic_text": "",
                    "question_pts": 1,
                    "options": [
                        {
                            "option_index": 0,
                            "option_text": $("#opt1").val(),
                            "option_url": '',
                            "option_correct": 1
                        },
                        {
                            "option_index": 1,
                            "option_text": $("#opt2").val(),
                            "option_url": '',
                            "option_correct": 0
                        },
                        {
                            "option_index": 2,
                            "option_text": $("#opt3").val(),
                            "option_url": '',
                            "option_correct": 0
                        },
                        {
                            "option_index": 3,
                            "option_text": $("#opt4").val(),
                            "option_url": '',
                            "option_correct": 0
                        }
                    ]
                }

            console.log('multiple choice form data ' + JSON.stringify(formData));

            $.ajax({
                type: 'post',
                url: '<%= Constants.API_URL %>/quiz/add-quiz',
                data: formData,
                success: function(data, textStatus, jqXHR) {
                    // The form was successfully submitted
//	                console.log('submitted successfully!');
                    swal("Your question has been submitted..");
                    console.log(data.insertId);
                    quesnId = data.insertId;
                    $('#quiz-div').hide();
                    $('#quiz-end').show();
                    //replace qbox
                    
                    // call unpublished
                    getUnpubLessons();
                    $drawerQst.toggleClass('quiz-drawer-open');
                    
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    // The submit action failed
                    alert('Failed to add quiz to database, try again.');
                }
            });
        }
        $('.customTextarea').text('');
    }
	
	

function submitChk() {
        console.log('submitting multiple select question')
        isinputCheck = true;
        quizQuestion = true;
        if(Validate()) {

            let timerMin = document.getElementById('timerchkm');
            let timerSec = document.getElementById('timerchks');
//			let timerChk = document.getElementById('timerchk');
//			console.log('timer check value ' + timerChk.value);

            let timerChk = timerMin.value + ":" + timerSec.value;
            let formData = {
                    "classroom_id": '<%= sLsn.getClassroom_id() %>',
                    "staff_id": '<%= sLsn.getStaff_id() %>',
                    "staff_lesson_id": '<%= sLsn.getStaff_lesson_id() %>',
                    "subject_id": '<%= sLsn.getSubject_id() %>',
                    "chapter_id": '<%= sLsn.getChapter_id() %>',
                    "quiz_title": "abcd",
                    "scheduled_date": "2020-12-20",
                    "quiz_ended": 0,
                    "question_type": <%= Constants.ID_QTYPE_MULTIPLE_SELECT %>,
                    "question_text": $("#qstChk").text(),
                    //"question_time": '00:'+$(event.target.closest('div')).find('input').val(),//$("#timerchk").val(),
                    "question_time": '00:'+timerChk,
                    "question_url": '',
                    "question_topic_text": "",
                    "question_pts": 1,
                    "options": [
                        {
                            "option_index": 0,
                            "option_text": $("#chktxt1").val(),
                            "option_url": '',
                            "option_correct": $("#chk1").is(":checked")?1:0
                        },
                        {
                            "option_index": 1,
                            "option_text": $("#chktxt2").val(),
                            "option_url": '',
                            "option_correct": $("#chk2").is(":checked")?1:0
                        },
                        {
                            "option_index": 2,
                            "option_text": $("#chktxt3").val(),
                            "option_url": '',
                            "option_correct": $("#chk3").is(":checked")?1:0
                        },
                        {
                            "option_index": 3,
                            "option_text": $("#chktxt4").val(),
                            "option_url": '',
                            "option_correct": $("#chk4").is(":checked")?1:0
                        }
                    ]
                }
    	
            $.ajax({ 
                type: 'post',
                url: '<%= Constants.API_URL %>/quiz/add-quiz',
                data: formData,
                success: function(data, textStatus, jqXHR) {
                    // The form was successfully submitted
//	                console.log('submitted successfully!');
                    swal("Your question has been submitted..");
                    console.log('submitted successfully!');
                    console.log(data.insertId);
                    console.log(data);
                    quesnId = data.insertId;
                    $('#quiz-div').hide();
                    $('#quiz-end').show();
                    

                    // call unpublished
                    getUnpubLessons();
                    $drawerQst.toggleClass('quiz-drawer-open');
                    
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    // The submit action failed
                    alert('Failed to add quiz to database, try again.');
                }
            });
        }
        $('.customTextarea').text('');
    }
	
    function submitBool() {
        isinputCheck = false;
        quizQuestion = true;
              
console.log('submitting boolean question');
if (Validate()) {

    if (tfOp > -1) {
        let timerboolMin = document.getElementById("timerboolm");
        let timerboolSec = document.getElementById("timerbools");
        let timerBool = timerboolMin.value + ':' + timerboolSec.value;
        //let timerBool = document.getElementById('timerbool');
        console.log('boolean question time ' + timerBool);
        let formData = {
            "classroom_id": '<%= sLsn.getClassroom_id() %>',
            "staff_id": '<%= sLsn.getStaff_id() %>',
            "staff_lesson_id": '<%= sLsn.getStaff_lesson_id() %>',
            "subject_id": '<%= sLsn.getSubject_id() %>',
            "chapter_id": '<%= sLsn.getChapter_id() %>',
            "quiz_title": "abcd",
            "scheduled_date": "2020-12-20",
            "quiz_ended": 0,
            "question_type": <%= Constants.ID_QTYPE_TRUE_FALSE %>,
            "question_text": $("#qstBool").text(),
            "question_time": '00:' + timerBool, //+$(event.target.closest('div')).find('input').val(),//$("#timerbool").val(),
            "question_url": $("#picurl6").val(),
            "question_topic_text": "",
            "question_pts": 1,
            "options": [
                {
                    "option_index": 0,
                    "option_text": (tfOp == 1) ? "True" : "False",//$("#toggle").is(":checked")?"True":"False",
                    "option_url": '',
                    "option_correct": (tfOp == 1) ? 1 : 0,//$("#toggle").is(":checked")?1:0
                }
                

            ]
        }

        console.log('true false form data ' + JSON.stringify(formData));

        $.ajax({
            type: 'POST',
            url: '<%= Constants.API_URL %>/quiz/add-quiz',
            data: formData,
            success: function (data, textStatus, jqXHR) {
                // The form was successfully submitted
                swal("Your question has been submitted..");
                console.log('submitted successfully!');
                console.log(data.insertId);
                console.log(data);
                quesnId = data.insertId;
                $('#quiz-div').hide();
                $('#quiz-end').show();

                // call unpublished
                getUnpubLessons();
                $drawerQst.toggleClass('quiz-drawer-open');

                // reset option
                tfOp = -1;

            },
            error: function (jqXHR, textStatus, errorThrown) {
                // The submit action failed
                swal('Failed to add quiz to database, try again.');
            }
        });
    } else {
        swal('Please select either True (or) False')
    }
}
$('.customTextarea').text('');
              
          }

function submitPOpt() {
    isinputCheck = false;
    quizQuestion = true;
    let dateStr;
    let today = new Date();

    dateStr = today.getYear() + '-' + today.getMonth() + '-' + today.getDate();
    console.log('datestr ' + dateStr);
    console.log('submitting picture option quiz');
    if (Validate()) {
        let timerpoptMin = document.getElementById('timerpoptm');
        let timerpoptSec = document.getElementById('timerpopts');
        let timerPopt = timerpoptMin.value + ":" + timerpoptSec.value;
        let formData = {
            "classroom_id": '<%= sLsn.getClassroom_id() %>',
            "staff_id": '<%= sLsn.getStaff_id() %>',
            "staff_lesson_id": '<%= sLsn.getStaff_lesson_id() %>',
            "subject_id": '<%= sLsn.getSubject_id() %>',
            "chapter_id": '<%= sLsn.getChapter_id() %>',
            "quiz_title": "abcd",
            "scheduled_date": "2020-07-02",
            "quiz_ended": 0,
            "question_type": '<%= Constants.ID_QTYPE_PICTURE_CHOICE %>',
            "question_text": $("#qstPOpt").text(),
            "question_time": '00:' + timerPopt, //+$(event.target.closest('div')).find('input').val(), //$("#timerpopt").val(),
            "question_url": $("#picurl1").val(),
            "question_topic_text": "",
            "question_pts": 1,
            "options": [
                {
                    "option_index": 0,
                    "option_text": $("#popt1").val(),
                    "option_url": $("#picurl2").val(),
                    "option_correct": 1
                },
                {
                    "option_index": 1,
                    "option_text": $("#popt2").val(),
                    "option_url": $("#picurl3").val(),
                    "option_correct": 0
                },
                {
                    "option_index": 2,
                    "option_text": $("#popt3").val(),
                    "option_url": $("#picurl4").val(),
                    "option_correct": 0
                },
                {
                    "option_index": 3,
                    "option_text": $("#popt4").val(),
                    "option_url": $("#picurl5").val(),
                    "option_correct": 0
                },
            ]
        }

        $.ajax({
            type: 'post',
            url: '<%= Constants.API_URL %>/quiz/add-quiz',
            data: formData,
            success: function (data, textStatus, jqXHR) {
                // The form was successfully submitted
                //	                console.log('submitted successfully!');
                Swal.fire("Your question has been submitted..");
                console.log(data.insertId);
                quesnId = data.insertId;
                $('#quiz-div').hide();
                $('#quiz-end').show();
                //replace qbox

                // call unpublished
                getUnpubLessons();
                $drawerQst.toggleClass('quiz-drawer-open');

            },
            error: function (jqXHR, textStatus, errorThrown) {
                // The submit action failed
                Swal('Failed to save quiz, try again.');
            }
        });
    }
    $('.customTextarea').text('');
}

function submitText() {
    isinputCheck = false;
    quizQuestion = true;
    
    console.log('submitting text question');
    let timerTextM = document.getElementById('timertextm');
    let timerTextS = document.getElementById('timertexts');
    let timerText = timerTextM.value + ":" + timerTextS.value;
    if (Validate()) {

        let formData = {
            "classroom_id": '<%= sLsn.getClassroom_id() %>',
            "staff_id": '<%= sLsn.getStaff_id() %>',
            "staff_lesson_id": '<%= sLsn.getStaff_lesson_id() %>',
            "subject_id": '<%= sLsn.getSubject_id() %>',
            "chapter_id": '<%= sLsn.getChapter_id() %>',
            "quiz_title": "abcd",
            "scheduled_date": "2020-12-20",
            "quiz_ended": 0,
            "question_type": <%= Constants.ID_QTYPE_TEXT_TYPE %>,
            "question_text": $("#qstTxt").text(),
            "question_time": '00:' + timerText,//$(event.target.closest('div')).find('input').val(), //$("#timertxt").val(),
            "question_url": '',
            "question_topic_text": "",
            "question_pts": 1,
            "options": [
                {
                    "option_index": 0,
                    "option_text": $("#ansTxt").val(),
                    "option_url": "",
                    "option_correct": 1,//$("#toggle").is(":checked")?1:0
                }
                
            ]
        }

        console.log('form data to be sent ' + JSON.stringify(formData));

        $.ajax({
            type: 'POST',
            url: '<%= Constants.API_URL %>/quiz/add-quiz',
            data: formData,
            success: function (data, textStatus, jqXHR) {
                // The form was successfully submitted
                swal("Your question has been submitted..");
                console.log('submitted successfully!');
                console.log(data.insertId);
                console.log(data);
                quesnId = data.insertId;
                $('#quiz-div').hide();
                $('#quiz-end').show();

                // call unpublished
                getUnpubLessons();
                $drawerQst.toggleClass('quiz-drawer-open');

                // reset option
                tfOp = -1;

            },
            error: function (jqXHR, textStatus, errorThrown) {
                // The submit action failed
                swal('Failed to add quiz to database, try again.');
            }
        });

    }
    $('.customTextarea').text('');
}
*/

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}))

function FormRow() {
    return (
        <React.Fragment>
            <Grid2 item xs={4}>
                Item
            </Grid2>
            <Grid2 item xs={4}>
                Item
            </Grid2>
            <Grid2 item xs={4}>
                Item
            </Grid2>
        </React.Fragment>

    )
}

const MediaPlannerDrawer = (props) => {
    const [quiz, setQuiz] = useState(null)
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0)
    const [progress, setProgress] = useState(0);
    const [progressBook, setProgressBook] = useState(0)
    const [currImg, setCurrImg] = useState(null)
    const [ImageDownloadUrls, setImageDownloadUrls] = useState((currImg) ? currImg : "")
    const [itemData2, setItemData2] = useState([]) // use itemData for dummy
    const [ovrMedia, setOvrMedia] = useState([])
    const [actvMedia, setActvMedia] = useState([])
    const [conMedia, setConMedia] = useState([])
    const [quizMedia, setQuizMedia] = useState([])

    const [updatedKey, setUpdatedKey] = useState(1)
    const [stage, setStage] = React.useState('OVERVIEW');
    const [open, setOpen] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [actvId, setActvId] = React.useState(0)
    const [subType, setSubType] = React.useState('OVERVIEW')
    const [progressOpen, setProgressOpen] = React.useState(false);

    const handleProgressClick = () => {
        setProgressOpen(true);
    };

    const handleProgressClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setProgressOpen(false);
    };

    const handleDialogClose = () => {
        setDialogOpen(false)
    }

    const handleChange = (event) => {
        setStage(event.target.value);
    };

    const onUpdateList = () => {
        getUnpubQuiz(props.lsnPlanId)
    }

    const onDeleteQuiz = async (id) => {

        await LessonPlanAPI.delQuiz(id)
        // update
        getUnpubQuiz(props.lsnPlanId)
    }

    const getUnpubQuiz = async (lsnPlanId) => {
        const resp = await LessonPlanAPI.getUnpubQuiz(lsnPlanId)

        console.log(`unpub quiz response ${JSON.stringify(resp)}`)
        setQuiz(resp.data)
    }

    function StandardImageList(props) {
        const { itemData3, type } = props;
        console.log('rendering standardimagelist size ' + itemData3.length)

        console.log('itemData3 ', itemData3)
        return (
            <div style={{
                padding: 20
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <h3>{props.title}</h3>


                    <div>
                        <div style={{ display: "flex", justifyContent: "flex-start", margin: 20, width: "100%" }}>
                            <label style={{ fontFamily: "Poppins, Helvetica, sans-serif", cursor: 'pointer' }}>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        cursor: 'pointer'
                                    }}>
                                    <AddPhotoAlternateIcon />
                                    <FileUploader
                                        // onClick={() => setStage(type)}
                                        hidden
                                        storageRef={firebase.storage().ref('lessonplanmedia')}
                                        onUploadStart={handleUploadStart}
                                        onUploadError={handleUploadError}
                                        onUploadSuccess={(filename) => handleUploadImageSuccess(filename, type)}
                                        onProgress={handleProgressImage}
                                    />

                                </div>
                            </label>
                            {/* 
                            <div style={{ display: "flex", justifyContent: "flex-end", marginLeft: 20 }}>
                                {progress ?

                                    <CircularProgress />
                                    :
                                    <></>
                                }
                            </div> */}
                        </div>
                    </div>

                </div>

                {itemData3.length ?
                    <ImageList sx={{ width: 500, }} cols={3} rowHeight={100}>
                        {itemData3.map((item) => (

                            <div
                            style={{
                                position: 'relative'
                            }}>
                                <ImageListItem key={item.img}>
                                    {(item.type && item.type.includes('video')) &&


                                        <img src={`https://cdn-icons-png.flaticon.com/512/109/109197.png?w=164&h=164&fit=crop&auto=format`}

                                            alt={item.title}
                                            loading="lazy"
                                            // title="Click to delete"
                                            onClick={() => props.handleDelete(item.id)}
                                        />

                                    }

                                    {(item.type && item.type.includes('image')) &&



                                        <img
                                            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                            alt={item.title}
                                            loading="lazy"
                                            // title="Click to delete"
                                            onClick={() => props.handleDelete(item.id)}
                                        />

                                    }
                                    {(item.type && item.type.includes('document')) &&

                                        <img src={`https://www.computerhope.com/jargon/d/doc.png?w=164&h=164&fit=crop&auto=format`} alt={item.title} loading="lazy"
                                            // title="Click to delete"
                                            onClick={() => props.handleDelete(item.id)} />


                                    }

                                    {(!item.type) &&

                                        <img src={`https://emojipedia-us.s3.amazonaws.com/source/skype/289/question-mark_2753.png?w=164&h=164&fit=crop&auto=format`} alt={item.title} loading="lazy"
                                            // title="Click to delete"
                                            onClick={() => props.handleDelete(item.id)} />

                                    }

                                </ImageListItem>
                                <div
                                style={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 10,
                                    cursor: 'pointer'
                                }}>
                                    <ClearSharpIcon color="secondary" onClick={() => props.handleDelete(item.id)} />
                                </div>
                            </div>
                        ))}
                    </ImageList>
                    :
                    <>
                        <Alert severity="warning">No media added to this category - add now!</Alert>
                    </>
                }
            </div>
        );
    }

    function AlertDialog() {

        return (

            <Dialog2
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle2 id="alert-dialog-title">
                    {"Delete Media?"}
                </DialogTitle2>
                <DialogContent2>
                    <DialogContentText2 id="alert-dialog-description">
                        Do you want to delete the media?
                    </DialogContentText2>
                </DialogContent2>
                <DialogActions2>
                    <Button2 onClick={handleDialogClose}>CANCEL</Button2>
                    <Button2 onClick={onDeleteMedia} autoFocus>
                        YES, DELETE
                    </Button2>
                </DialogActions2>
            </Dialog2>
        );
    }

    const getMedia = async (lsnPlanId) => {
        const resp = await LessonPlanAPI.getMedia(lsnPlanId)

        console.log(`media response ${JSON.stringify(resp)}`)

        let ovrMedia2 = []
        let actvMedia2 = []
        let conMedia2 = []
        let quizMedia2 = []
        // separate by stage
        if (resp && resp.data && resp.data.data) {
            resp.data.data.map((media) => {
                const path2 = media.path
                const obj = {
                    id: media.lesson_plan_media_id,
                    title: media.name,
                    img: path2,
                    type: media.type
                }
                switch (media.stage) {
                    case 'OVERVIEW':
                        ovrMedia2.push(obj)
                        break;
                    case 'ACTIVITY':
                        actvMedia2.push(obj)
                        break;
                    case 'CONCLUSION':
                        conMedia2.push(obj)
                        break;

                    case 'ASSESSMENT':
                        quizMedia2.push(obj)
                        break;
                }

            })

            // update state
            setOvrMedia(ovrMedia2)
            setActvMedia(actvMedia2)
            setConMedia(conMedia2)
            setQuizMedia(quizMedia2)
        }

    }

    const handleUploadStart = () => {
        // console.log('type ', type)
        setIsUploading(true)
        setProgress(0)
        setProgressOpen(true)
        // setStage(type)
    }

    const handleProgressImage = progress => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }
    const handleProgressBook = progress => {
        setProgressBook((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }

    const handleUploadError = error => {
        setIsUploading(false)
    }

    const handleDelete = (id) => {
        // show confirm dialog
        setDialogOpen(true)
        console.log('actvId ', id)
        setActvId(id)

        // call on delete
    }

    const onDeleteMedia = async () => {

        // wait until it's set
        setTimeout(async function () {
            const resp = await (LessonPlanAPI.delMedia(actvId))
            console.log('delete media response ' + JSON.stringify(resp));
            setDialogOpen(false)

            // reload
            onReload()
        }, 1000) // 1 sec later

    }

    const handleUploadImageSuccess = async (filename, stage2) => {
        const downloadUrl = await firebase
            .storage()
            .ref("lessonplanmedia")
            .child(filename)
            .getDownloadURL()
        let newImgs = itemData2
        let newUpdatedKey = updatedKey
        ++newUpdatedKey

        console.log('newimgs before ', newImgs.length)
        console.log("image download url", downloadUrl)

        // get type
        let type = 'image'; // default
        let lastIdx = downloadUrl.lastIndexOf('.')
        type = downloadUrl.substring(lastIdx, (lastIdx + 4))
        console.log('type after substring ', type)

        // close progress snackbar
        setProgressOpen(false)

        switch (type) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                type = 'image';
                break;
            case 'mp4':
            case 'mov':
                type = 'video';
                break;
            case 'pdf':
            case 'xls':
                type = 'document';
                break;
        }

        if (type.includes('jpg')
            || type.includes('jpeg')
            || type.includes('gif')
            || type.includes('png')) {
            type = 'image';
        } else if (type.includes('mp4')
            || type.includes('mov')) {
            type = 'video';
        } else if (type.includes('doc')
            || type.includes('xls')
            || type.includes('pdf')) {
            type = 'document';
        }

        setImageDownloadUrls(downloadUrl)

        let ovrMedia2 = ovrMedia;
        let actvMedia2 = actvMedia;
        let conMedia2 = conMedia;
        let quizMedia2 = quizMedia;

        if (downloadUrl) {
            setProgress(0)

            // add to list
            const newImg =
            {
                img: downloadUrl,
                title: 'newimage',
                type: type
            }

            newImgs.push(newImg)
            console.log('newImgs after ', newImgs.length)
            switch (stage2) {
                case 'OVERVIEW':
                    ovrMedia2.push(newImg)
                    setOvrMedia(ovrMedia2)
                    break;
                case 'ACTIVITY':
                    actvMedia2.push(newImg);
                    setActvMedia(actvMedia2)
                    break;
                case 'CONCLUSION':
                    conMedia2.push(newImg);
                    setConMedia(conMedia2)
                    break;
                case 'ASSESSMENT':
                    quizMedia2.push(newImg);
                    setQuizMedia(quizMedia2)
                    break;

            }
            // setItemData2(newImgs)
            setUpdatedKey(newUpdatedKey)

            // update to database
            const body = {
                name: 'Image ' + newUpdatedKey,
                path: downloadUrl,
                type: type, // media type
                stage: stage2, // plan stage => overview etc..
                status: 1,
                lesson_plan_id: props.lsnPlanId
            }

            const resp = await LessonPlanAPI.saveMedia(body)
            console.log('after saving media ', JSON.stringify(resp));
            setOpen(true)
        }
    }

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const onReload = () => {
        getMedia(props.lsnPlanId)
    }

    useEffect(() => {

        getUnpubQuiz(props.lsnPlanId)
        getMedia(props.lsnPlanId)
        return <></>
    }, [])

    // return (
    //     (
    //         <Box sx={{ flexGrow: 1 }}>
    //             <Grid2 container spacing={1}>
    //                 <Grid2 container item spacing={3}>
    //                     <FormRow />
    //                 </Grid2>
    //                 <Grid2 container item spacing={3}>
    //                     <FormRow />
    //                 </Grid2>
    //                 <Grid2 container item spacing={3}>
    //                     <FormRow />
    //                 </Grid2>
    //             </Grid2>
    //         </Box>
    //     )
    // )

    // build media by stage

    return (
        <>
            {/* <p>Rendered</p> */}
            <StandardImageList
                handleDelete={(id) => handleDelete(id)}
                title="Overview" type="OVERVIEW" itemData3={ovrMedia} key={updatedKey} onDelete={(id) => onDeleteMedia(id)} />
            <StandardImageList
                handleDelete={(id) => handleDelete(id)}
                title="Activity" type="ACTIVITY" itemData3={actvMedia} key={(updatedKey + 1)} onDelete={(id) => onDeleteMedia(id)} />
            <StandardImageList
                handleDelete={(id) => handleDelete(id)}
                title="Conclusion" type="CONCLUSION" itemData3={conMedia} key={(updatedKey + 2)} onDelete={(id) => onDeleteMedia(id)} />
            <StandardImageList
                handleDelete={(id) => handleDelete(id)}
                title="Assessment" type="ASSESSMENT" itemData3={quizMedia} key={(updatedKey + 3)} onDelete={(id) => onDeleteMedia(id)} />


            {/*
            <div style={{ display: "flex", justifyContent: "flex-start", margin: 20, width: "100%" }}>
                <label style={{ fontFamily: "Poppins, Helvetica, sans-serif", cursor: 'pointer' }}>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            cursor: 'pointer'
                        }}>
                        <AddBoxIcon style={{
                            color: 'steelblue'
                        }} />
                        <Typography
                            variant='body1'
                            style={{
                                marginLeft: 10,
                                color: "#464E5F",
                            }}>
                            Add Media
                        </Typography>
                        <FileUploader
                            hidden

                            storageRef={firebase.storage().ref('lessonplanmedia')}
                            onUploadStart={handleUploadStart}
                            onUploadError={handleUploadError}
                            onUploadSuccess={handleUploadImageSuccess}
                            onProgress={handleProgressImage}
                        />

                    </div>
                </label>

                <div style={{ display: "flex", justifyContent: "flex-end", marginLeft: 20 }}>
                    {progress ?

                        <CircularProgress />
                        :
                        <></>
                    }
                </div>
            </div>

            <div style={{ margin: 10, marginLeft: 20, marginRight: 20 }}>
                <Box sx={{ minWidth: 120 }}>
                    <FormControl2 fullWidth>
                        <InputLabel2 id="demo-simple-select-label">Stage</InputLabel2>
                        <Select2
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={stage}
                            label="Age"
                            mode="contained"
                            onChange={handleChange}
                        >
                            <MenuItem2 value={`OVERVIEW`}>OVERVIEW</MenuItem2>
                            <MenuItem2 value={`ACTIVITY`}>ACTIVITY</MenuItem2>
                            <MenuItem2 value={`CONCLUSION`}>CONCLUSION</MenuItem2>
                            <MenuItem2 value={`ASSESSMENT`}>ASSESSMENT</MenuItem2>
                        </Select2>
                    </FormControl2>
                </Box>
            </div>
            */}

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Media saved successfully!
                </Alert>
            </Snackbar>


            <Snackbar open={progressOpen} autoHideDuration={6000} onClose={handleProgressClose}>
                <Alert onClose={handleProgressClose} severity="warning" sx={{ width: '100%' }}>
                    Loading..
                </Alert>
            </Snackbar>

            <AlertDialog />
        </>
    )
}

export default MediaPlannerDrawer;