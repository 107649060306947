/**
 * setup action creators for current map id
 */

export const CURRICULUMMAPID_CURR_REQUEST = 'CURRICULUM_CURRID_REQUEST';
export const CURRICULUMMAPID_CURR_SUCCESS = 'CURRICULUM_CURRID_SUCCESS';
export const CURRICULUMMAPID_CURR_FAILURE = 'CURRICULUM_CURRID_FAILURE';

// action creators
export const getCurrMapId = () => ({ // already returns
    type: CURRICULUMMAPID_CURR_REQUEST
})

export const getCurrMapIdSuccess = (data) => ({
    type: CURRICULUMMAPID_CURR_SUCCESS,
    payload: data
})

export const getCurrMapIdFailure = () => ({
    type: CURRICULUMMAPID_CURR_FAILURE
})

// async thunk action to update
export function updateCurrMapId(data) {
    return async (dispatch) => {
        dispatch(getCurrMapId());

        try {
            // update data
            dispatch(getCurrMapIdSuccess(data))

            // cleanup
        } catch (error) {
            dispatch(getCurrMapIdFailure())
        }

    }
}