import { SUBSUBDETAILS_LIST_FAIL, SUBSUBDETAILS_LIST_REQUEST, SUBSUBDETAILS_LIST_SUCCESS } from "../constants/subSubjectConstants";


function subSubDetailsReducer(state = { subSubDetails: [] }, action) {
    switch (action.type) {
        case SUBSUBDETAILS_LIST_REQUEST:
            return { loadingsubSub: true }
        case SUBSUBDETAILS_LIST_SUCCESS:
            return { loadingsubSub: false, subSubDetails: action.payload };
        case SUBSUBDETAILS_LIST_FAIL:
            return { loadingsubSub: false, error: action.payload }
        default:
            return state;
    }

}

export { subSubDetailsReducer }