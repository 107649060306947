import { STDDETAILS_LIST_FAIL, STDDETAILS_LIST_REQUEST, STDDETAILS_LIST_SUCCESS } from "../constants/standardConstants";


function stdDetailsReducer(state = { stdDetails: [] }, action) {
    switch (action.type) {
        case STDDETAILS_LIST_REQUEST:
            return { loadingStd: true }
        case STDDETAILS_LIST_SUCCESS:
            return { loadingStd: false, stdDetails: action.payload };
        case STDDETAILS_LIST_FAIL:
            return { loadingStd: false, error: action.payload }
        default:
            return state;
    }

}

export { stdDetailsReducer }