/**
 * @project kgtopg - schooler - partner
 * curriculum map data services
 */
import API from "./http";
import axios from "axios";
import qs from "querystring";
import Constants from "../resource/Constants";

const TAG = 'curriculummap.js';

export default {

  async postSaveMap(data) {
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
      };

      let url = Constants.Application.PARTNER_END_URL;
      // url += "/curriculum-map/";
      url += "/save-map/";

      console.log("postSaveMap api url", url);

      const options = {
        method: "POST",
        data: data,
        url,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': 'Bearer ' + UserSession.authtoken,
        },
      };

      let response = await axios(options);

      console.log("postSaveMap api response", response);

      if (response) return response;
      else return false;
    } catch (ex) {
      return false;
    }
  },
  async postSaveLessonPlanSetupCustom(data) {
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
      };

      let url = Constants.Application.PARTNER_END_URL;
      // url += "/curriculum-map/";
      url += "/lesson-plan-setup-customcrud/";

      console.log("postSaveLessonPlanSetupCustom api url", url);

      const options = {
        method: "POST",
        data: data,
        url,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': 'Bearer ' + UserSession.authtoken,
        },
      };

      let response = await axios(options);

      console.log("postSaveLessonPlanSetupCustom api response", response);

      if (response) return response;
      else return false;
    } catch (ex) {
      return false;
    }
  },
  async postUpdateLessonPlanSetupCustom(data) {
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
      };

      let url = Constants.Application.PARTNER_END_URL;
      url += `/lesson-plan-setup-customcrud/`;

      console.log("postUpdateLessonPlanSetupCustom api url", url);

      const options = {
        method: "PUT",
        data: data,
        url,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': 'Bearer ' + UserSession.authtoken,
        },
      };

      let response = await axios(options);

      console.log("postUpdateLessonPlanSetupCustom api response", response);

      if (response) return response;
      else return false;
    } catch (ex) {
      return false;
    }
  },
  async postUpdateMap(data, mapId) {
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
      };

      let url = Constants.Application.PARTNER_END_URL;
      url += `/curriculum-map/${mapId}`;

      console.log("postUpdateMap api url", url);

      const options = {
        method: "PUT",
        data: data,
        url,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': 'Bearer ' + UserSession.authtoken,
        },
      };

      let response = await axios(options);

      console.log("postUpdateMap api response", response);

      if (response) return response;
      else return false;
    } catch (ex) {
      return false;
    }
  },
  
  
  async deleteMap(mapId) {
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
      };

      let url = Constants.Application.PARTNER_END_URL;
      url += `/curriculum-map/${mapId}`;

      console.log("deleteMap api url", url);

      const options = {
        method: "DELETE",
        url,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': 'Bearer ' + UserSession.authtoken,
        },
      };

      let response = await axios(options);

      console.log("deleteMap api response", response);

      if (response) return response;
      else return false;
    } catch (ex) {
      return false;
    }
  },


  async deleteTopic(topicName, lsnId) {
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
      };

      let url = Constants.Application.PARTNER_END_URL;
      url += `/delete-topic-based-on-lessonid-in-lcustom/`;

      const data = {
        lesson_id: lsnId,
        topic_name: topicName
      }

      console.log(TAG, "deleteTopic api url", url);

      const options = {
        method: "DELETE",
        data: data,
        url,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': 'Bearer ' + UserSession.authtoken,
        },
      };

      let response = await axios(options);

      console.log(TAG, "deleteTopic api response", response);

      if (response) return response;
      else return false;
    } catch (ex) {
      return false;
    }
  },



  async deleteLessonPlanCustom(planId) {
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
      };

      let url = Constants.Application.PARTNER_END_URL;
      url += `/lesson-plan-setup-customcrud/`;
      const data = {
        lesson_plan_setup_custom_id: planId
      }

      console.log(TAG, "deleteLesson api url", url);

      const options = {
        method: "DELETE",
        data: data,
        url,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': 'Bearer ' + UserSession.authtoken,
        },
      };

      let response = await axios(options);

      console.log(TAG, "deleteLesson api response", response);

      if (response) return response;
      else return false;
    } catch (ex) {
      return false;
    }
  },

  async deleteLesson(lsnId) {
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
      };

      let url = Constants.Application.PARTNER_END_URL;
      url += `/update-lesson-status/`;

      const body = {
        lesson_id: lsnId
      }

      console.log(TAG, "deleteLesson api url", url);

      const options = {
        method: "PUT",
        data: body,
        url,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': 'Bearer ' + UserSession.authtoken,
        },
      };

      let response = await axios(options);

      console.log(TAG, "deleteLesson api response", response);

      if (response) return response;
      else return false;
    } catch (ex) {
      return false;
    }
  },

  async getPredefinedLessonPlans(pubId, subId, clsStd) {
    // we wait
    try {
      let url = Constants.Application.PARTNER_END_URL;
      url += `/get-pre-defined-lesson-plans/?publisher_id=${pubId}&subject_id=${subId}&classroom_std=${clsStd}`;

      console.log(`getPredefinedLessonPlans url ${url}`);

      let response = await axios.get(url);
      // console.log(`upcoming response ${JSON.stringify(response)}`)
      // let response = await API.get(`/schooler/teacher-upcoming-classes-service?staffid=${staffId}`);

      // .then(response => {
      console.log(`getPredefinedLessonPlans response ${JSON.stringify(response)}`)

      // let result = response.data.data
      // let message = response.data.message

      if (response && response.data) return response.data;
      else return false;
    } catch (ex) {
      // console.log(ex);
      // console.log(`exception ${ex.message}`)
      return false;
    }
  },

  async getMaps(pubId) {
    // console.log("date",stuId,jsDate)
    // we wait
    try {
      let url = Constants.Application.PARTNER_END_URL;
      url += `/curriculum-map/get-pub-maps/${pubId}/`;

      console.log(`curriculum maps by publisher url ${url}`);

      let response = await axios.get(url);
      // console.log(`upcoming response ${JSON.stringify(response)}`)
      // let response = await API.get(`/schooler/teacher-upcoming-classes-service?staffid=${staffId}`);

      // .then(response => {
      // console.log(`upcoming classes response ${JSON.stringify(response)}`)

      // let result = response.data.data
      // let message = response.data.message

      if (response) return response;
      else return false;
    } catch (ex) {
      // console.log(ex);
      // console.log(`exception ${ex.message}`)
      return false;
    }
  },
  async getMaps2(pubId, brdId, subId, clsStd) {
    // console.log("date",stuId,jsDate)
    // we wait
    try {
      let url = Constants.Application.PARTNER_END_URL;
      url += `/map-activity/?pub_id=${pubId}`;
      url += `&board_id=${brdId}`;
      url += `&subject_id=${subId}`;
      url += `&classroom_std=${clsStd}`;

      console.log(`curriculum maps by publisher url ${url}`);

      let response = await axios.get(url);
      // console.log(`upcoming response ${JSON.stringify(response)}`)
      // let response = await API.get(`/schooler/teacher-upcoming-classes-service?staffid=${staffId}`);

      // .then(response => {
      // console.log(`upcoming classes response ${JSON.stringify(response)}`)

      // let result = response.data.data
      // let message = response.data.message

      if (response) return response;
      else return false;
    } catch (ex) {
      // console.log(ex);
      // console.log(`exception ${ex.message}`)
      return false;
    }
  },
  async getMapDetails(mapId) {
    // console.log("date",stuId,jsDate)
    // we wait
    try {
      let url = Constants.Application.PARTNER_END_URL;
      url += `/curriculum-map/${mapId}`;

      console.log(`curriculum map details view url ${url}`);

      let response = await axios.get(url);
      console.log(
        `curriculum map details response ${JSON.stringify(response)}`
      );
      // let response = await API.get(`/schooler/teacher-upcoming-classes-service?staffid=${staffId}`);

      // .then(response => {
      // console.log(`upcoming classes response ${JSON.stringify(response)}`)

      // let result = response.data.data
      // let message = response.data.message

      if (response) return response.data;
      else return false;
    } catch (ex) {
      // console.log(ex);
      // console.log(`exception ${ex.message}`)
      return false;
    }
  },

  async getOwnBoardMapDetails(mapId) {


    // console.log("date",stuId,jsDate)
    // we wait
    try {
      let url = Constants.Application.PARTNER_END_URL;
      url += `/get-lesson-plans-based-on-map/?map_id=${mapId}`;

      console.log(`getOwnBoardMapDetails(): curriculum map details view url ${url}`);

      let response = await axios.get(url);
      console.log(
        `getOwnBoardMapDetails(): curriculum map details response ${JSON.stringify(response)}`
      );
      // let response = await API.get(`/schooler/teacher-upcoming-classes-service?staffid=${staffId}`);

      // .then(response => {
      // console.log(`upcoming classes response ${JSON.stringify(response)}`)

      // let result = response.data.data
      // let message = response.data.message

      if (response) return response.data;
      else return false;
    } catch (ex) {
      // console.log(ex);
      // console.log(`exception ${ex.message}`)
      return false;
    }
  },
  async getContByContProvTopic(provId, topicId) {
    // we wait
    try {
      let url = Constants.Application.PARTNER_END_URL;
      url += `/get-content-by-contprov-topic/?contprovid=${provId}&topicid=${topicId}`;

      console.log(`getContByContProvTopic(): content details view url ${url}`);

      let response = await axios.get(url);
      console.log(`getContByContProvTopic(): content details response ${JSON.stringify(response)}`);
      // let response = await API.get(`/schooler/teacher-upcoming-classes-service?staffid=${staffId}`);

      // .then(response => {
      // console.log(`upcoming classes response ${JSON.stringify(response)}`)

      // let result = response.data.data
      // let message = response.data.message

      if (response) return response.data;
      else return false;
    } catch (ex) {
      // console.log(ex);
      // console.log(`exception ${ex.message}`)
      return false;
    }
  },
  async getWizenozeVideos(id) {
    let id2 = 8804;
    const usrId = 701;
    const usrType = "teacher";
    const url = `https://api.wizenoze.com/v4/curriculum/node/query/${id2}/results?userUUID=${usrId}&sessionUUID=${usrId}&userType=${usrType}&resultSize=5`;

    try {
      console.log(`content details view url ${url}`);

      let response = await axios.get(url, {
        headers: {
          Authorization: `7ba2ad78-efc3-4c36-a846-ca8f5e186a94`,
        },
        referrer: "https://pustak.co",
        //referrerPolicy: "no-referrer-when-downgrade", // no-referrer, origin, same-origin...
        referrerPolicy: "origin",
        //mode: "cors", // same-origin, no-cors
        //credentials: "same-origin", // omit, include
        cache: "no-store", // no-store, reload, no-cache, force-cache, or only-if-cached
        //redirect: "follow", // manual, error
        //integrity: "", // a hash, like "sha256-abcdef1234567890"
        keepalive: false, // true
        //signal: undefined, // AbortController to abort request
        window: window, // null
      });
      console.log(`wizenoze videos response ${JSON.stringify(response)}`);
      // let response = await API.get(`/schooler/teacher-upcoming-classes-service?staffid=${staffId}`);

      // .then(response => {
      // console.log(`upcoming classes response ${JSON.stringify(response)}`)

      // let result = response.data.data
      // let message = response.data.message

      if (response) return response.data;
      else return false;
    } catch (ex) {
      // console.log(ex);
      // console.log(`exception ${ex.message}`)
      return false;
    }
  },
};
