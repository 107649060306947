import { UNITLESSONS_LIST_REQUEST, UNITLESSONS_LIST_SUCCESS, UNITLESSONS_LIST_FAILURE,
UNITLESSONS_REQUEST, UNITLESSONS_SUCCESS, UNITLESSONS_FAILURE } from "../constants/unitLessonsConstants";

function UnitLessonsReducer(state = { unitLessons: [] }, action) {
    switch (action.type) {
        case UNITLESSONS_LIST_REQUEST:
            return { ...state, loading: true }
        case UNITLESSONS_LIST_SUCCESS:
            return { ...state, loading: false, unitLessons: action.payload };
        case UNITLESSONS_LIST_FAILURE:
            return { ...state, loading: false, errorMap: action.payload }
        case UNITLESSONS_REQUEST:
            return { ...state, loading: true }
        case UNITLESSONS_SUCCESS:
            return { ...state, loading: false, unitLessons: action.payload }
        case UNITLESSONS_FAILURE:
            return { ...state, loading: false, errorMap: action.payload }
        default:
            return state;
    }
}

export { UnitLessonsReducer }