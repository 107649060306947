/**
 *Publisher books screen
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import text1 from "../../assets/text1.jpg";
import textbooks from "../../assets/textbooks.jpg";
import Wallpaper from "../../assets/Wallpaper.png"
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import UploadEbookScreen from "./UploadEbookScreen"
import API from '../../http/http'
import PARTNERAPI from '../../http/httppartner'
import PUBLISHERAPI from '../../http/publisher'
import { useDispatch, useSelector } from 'react-redux';
import { listSearchEbookGet } from '../../redux/actions/searchEbookAction';
import { listBoardGet } from '../../redux/actions/boardDetailsAction';
import { listInstGet } from '../../redux/actions/InstituteDetailsAction';
import { listSubGet } from '../../redux/actions/subjectsAction';
import { listChpGet } from '../../redux/actions/chapterAction';
import bookCover from "../../assets/book-na-1.jpg"
import { listStdGet } from '../../redux/actions/standardAction';
import { Alert, AlertTitle } from '@material-ui/lab';
import Menu from '@material-ui/core/Menu';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Cookie from 'js-cookie';
import { listEbooksGet } from '../../redux/actions/GetAllFilesAction';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { listTrashBinGet, listTrashBinPost } from '../../redux/actions/TrashBinAction';

function createData(Title, Board, Subject, Chapter, Topic, Type) {
  return { Title, Board, Subject, Chapter, Topic, Type };
}



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const rows = [
  createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  // createData('Spherical Mirrors', 'ICSE', "Physics", "Mirrors", 'Reflection', "Video"),
  // createData(' Chemical Reactions and Equations', 'CBSE', "Chemistry", "Chemical Reactions", 'Reactions', "Pdf"),
  // createData('Acids, Bases and Salts', 'CBSE', "Chemistry", "Acids, Bases and Salts", 'Acids', "Video"),
  // createData('Metals and Non-metals', 'CBSE', "Chemistry", "Metals and Non-metals", 'Metals', "Video"),
  // createData('Resource and Development', 'CBSE', "Geography", "Resouces and Development", 'Resources', "Video"),
  // createData('Footprints without Feet', 'SSC', "English", "Footprints without Feet", 'Chapter1', "Video"),
  // createData(' Polynomials', 'ICSE', "Maths", " Polynomials", 'quadratic polynomials', "Video"),
  // createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  // createData('Minerals and Energy Resources', 'CBSE', "Geography", "Minerals and Energy Resources", 'Minerals', "Video"),
  // createData('Pairs of Linear Equations in Two Variables', 'CBSE', "Maths", "Pairs of Linear Equations in Two Variables", 'Linear Equations', "Video"),
  // createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  // createData('Footprints without Feet', 'ICSE', "English", "Footprints without Feet", 'Chapter1', "Video"),
  // createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  // createData('Agriculture', 'CBSE', "Geography", "Agriculture", 'Agriculture', "Video"),
  // createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  // createData('Metals and Non-metals', 'ICSE', "Chemistry", "Metals and Non-metals", 'Metals', "Video"),
  // createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  // createData('Refraction Of Light', 'CBSE', "Physics", "Refraction of Light", 'Reflection', "Video"),
  // createData('Pairs of Linear Equations in Two Variables', 'ICSE', "Maths", "Pairs of Linear Equations in Two Variables", 'Linear Equations', "Video"),
  // createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  // createData('Resource and Development', 'ICSE', "Geography", "Resouces and Development", 'Resources', "Video"),
  // createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  // createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  // createData('Pairs of Linear Equations in Two Variables', 'ICSE', "Maths", "Pairs of Linear Equations in Two Variables", 'Linear Equations', "Video"),
  // createData('Water Resources', 'CBSE', "Geography", "Water Resources", 'Water', "Image"),
  // createData('Resource and Development', 'SSC', "Geography", "Resouces and Development", 'Resources', "Video"),
  // createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  // createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  // createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  // createData('Forest and Wildlife Resources', 'CBSE', "Geography", "Forest and Wildlife Resources", 'Forest and Wildlife', "Video"),
  // createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  // createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  // createData('Metals and Non-metals', 'SSC', "Chemistry", "Metals and Non-metals", 'Metals', "Video"),
  // createData('Footprints without Feet', 'SSC', "English", "Footprints without Feet", 'Chapter1', "Video"),

];





const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
  { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: 'Life Is Beautiful', year: 1997 },
  { title: 'The Usual Suspects', year: 1995 },
  { title: 'Léon: The Professional', year: 1994 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Saving Private Ryan', year: 1998 },
  { title: 'Once Upon a Time in the West', year: 1968 },
  { title: 'American History X', year: 1998 },
  { title: 'Interstellar', year: 2014 },
  { title: 'Casablanca', year: 1942 },
  { title: 'City Lights', year: 1931 },
  { title: 'Psycho', year: 1960 },
  { title: 'The Green Mile', year: 1999 },
  { title: 'The Intouchables', year: 2011 },
  { title: 'Modern Times', year: 1936 },
  { title: 'Raiders of the Lost Ark', year: 1981 },
  { title: 'Rear Window', year: 1954 },
  { title: 'The Pianist', year: 2002 },
  { title: 'The Departed', year: 2006 },
  { title: 'Terminator 2: Judgment Day', year: 1991 },
  { title: 'Back to the Future', year: 1985 },
  { title: 'Whiplash', year: 2014 },
  { title: 'Gladiator', year: 2000 },
  { title: 'Memento', year: 2000 },
  { title: 'The Prestige', year: 2006 },
  { title: 'The Lion King', year: 1994 },
  { title: 'Apocalypse Now', year: 1979 },
  { title: 'Alien', year: 1979 },
  { title: 'Sunset Boulevard', year: 1950 },
  { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964 },
  { title: 'The Great Dictator', year: 1940 },
  { title: 'Cinema Paradiso', year: 1988 },
  { title: 'The Lives of Others', year: 2006 },
  { title: 'Grave of the Fireflies', year: 1988 },
  { title: 'Paths of Glory', year: 1957 },
  { title: 'Django Unchained', year: 2012 },
  { title: 'The Shining', year: 1980 },
  { title: 'WALL·E', year: 2008 },
  { title: 'American Beauty', year: 1999 },
  { title: 'The Dark Knight Rises', year: 2012 },
  { title: 'Princess Mononoke', year: 1997 },
  { title: 'Aliens', year: 1986 },
  { title: 'Oldboy', year: 2003 },
  { title: 'Once Upon a Time in America', year: 1984 },
  { title: 'Witness for the Prosecution', year: 1957 },
  { title: 'Das Boot', year: 1981 },
  { title: 'Citizen Kane', year: 1941 },
  { title: 'North by Northwest', year: 1959 },
  { title: 'Vertigo', year: 1958 },
  { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
  { title: 'Reservoir Dogs', year: 1992 },
  { title: 'Braveheart', year: 1995 },
  { title: 'M', year: 1931 },
  { title: 'Requiem for a Dream', year: 2000 },
  { title: 'Amélie', year: 2001 },
  { title: 'A Clockwork Orange', year: 1971 },
  { title: 'Like Stars on Earth', year: 2007 },
  { title: 'Taxi Driver', year: 1976 },
  { title: 'Lawrence of Arabia', year: 1962 },
  { title: 'Double Indemnity', year: 1944 },
  { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
  { title: 'Amadeus', year: 1984 },
  { title: 'To Kill a Mockingbird', year: 1962 },
  { title: 'Toy Story 3', year: 2010 },
  { title: 'Logan', year: 2017 },
  { title: 'Full Metal Jacket', year: 1987 },
  { title: 'Dangal', year: 2016 },
  { title: 'The Sting', year: 1973 },
  { title: '2001: A Space Odyssey', year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: 'Toy Story', year: 1995 },
  { title: 'Bicycle Thieves', year: 1948 },
  { title: 'The Kid', year: 1921 },
  { title: 'Inglourious Basterds', year: 2009 },
  { title: 'Snatch', year: 2000 },
  { title: '3 Idiots', year: 2009 },
  { title: 'Monty Python and the Holy Grail', year: 1975 },
];




const boardDetailsMock = [
  { institute_board_id: 10001, institute_board_name: "CBSE", institute_board_f_desc: "cbse board", institute_board_status: 1, created_date: "2020-05-27" },
  { institute_board_id: 10002, institute_board_name: "Andhra Pradesh State Board", institute_board_f_desc: "AP state board", institute_board_status: 1, created_date: "2020-05-27" },
  { institute_board_id: 10003, institute_board_name: "ICSE", institute_board_f_desc: "ICSE board", institute_board_status: 1, created_date: "2020-05-27" },
  { institute_board_id: 10004, institute_board_name: "Bihar State Board", institute_board_f_desc: "Bihar state board", institute_board_status: 1, created_date: "2020-05-27" },
  { institute_board_id: 10005, institute_board_name: "Cambridge International", institute_board_f_desc: "Cambridge International board", institute_board_status: 1, created_date: "2020-05-27" },
  { institute_board_id: 10006, institute_board_name: "Chhattisgarh State Board", institute_board_f_desc: "Chhattisgarh state board", institute_board_status: 1, created_date: "2020-05-30" },
  { institute_board_id: 10007, institute_board_name: "Gujarat State Board", institute_board_f_desc: "Gujarat state board", institute_board_status: 1, created_date: "2020-05-30" },
  { institute_board_id: 10008, institute_board_name: "International Baccalaureate Board", institute_board_f_desc: "International Baccalaureate board", institute_board_status: 1, created_date: "2020-05-30" },
  { institute_board_id: 10009, institute_board_name: "Jharkhand State Board", institute_board_f_desc: "Jharkhand state board", institute_board_status: 1, created_date: "2020-05-30" },
  { institute_board_id: 10010, institute_board_name: "J&K State Board", institute_board_f_desc: "J&K state board", institute_board_status: 1, created_date: "2020-05-30" },
  { institute_board_id: 10011, institute_board_name: "Karnataka State Board", institute_board_f_desc: "Karnataka state board", institute_board_status: 1, created_date: "2020-05-30" },
  { institute_board_id: 10012, institute_board_name: "Kerala State Board", institute_board_f_desc: "Kerala state board", institute_board_status: 1, created_date: "2020-05-30" },
  { institute_board_id: 10013, institute_board_name: "Madhya Pradesh State Board", institute_board_f_desc: "Madhya Pradesh state board", institute_board_status: 1, created_date: "2020-05-30" },
  { institute_board_id: 10014, institute_board_name: "Maharashtra State Board", institute_board_f_desc: "Maharashtra state board", institute_board_status: 1, created_date: "2020-05-30" },
  { institute_board_id: 10015, institute_board_name: "Punjab State Board", institute_board_f_desc: "Punjab state board", institute_board_status: 1, created_date: "2020-05-30" },
  { institute_board_id: 10016, institute_board_name: "Rajasthan State Board", institute_board_f_desc: "Rajasthan state board", institute_board_status: 1, created_date: "2020-05-30" },
  { institute_board_id: 10017, institute_board_name: "Tamil Nadu State Board", institute_board_f_desc: "Tamil Nadu state board", institute_board_status: 1, created_date: "2020-05-30" },
  { institute_board_id: 10018, institute_board_name: "Telangana State Board", institute_board_f_desc: "Telangana state board", institute_board_status: 1, created_date: "2020-05-30" },
  { institute_board_id: 10019, institute_board_name: "Uttarakhand State Board", institute_board_f_desc: "Uttarakhan state board", institute_board_status: 1, created_date: "2020-05-30" },
  { institute_board_id: 10020, institute_board_name: "Uttar Pradesh State Board", institute_board_f_desc: "Uttar Pradesh state board", institute_board_status: 1, created_date: "2020-05-30" },
  { institute_board_id: 10021, institute_board_name: "West Bengal State Board", institute_board_f_desc: "West Bengal state board", institute_board_status: 1, created_date: "2020-05-30" },

]

const instituteMockData = [
  { inst_id: 1, inst_type: "School" },
  { inst_id: 2, inst_type: "College" },
  { inst_id: 3, inst_type: "University" },
  { inst_id: 4, inst_type: "Institute" },
]

const standardsMockData = [
  { stand_id: 1, stand_name: 1 },
  { stand_id: 2, stand_name: 2 },
  { stand_id: 3, stand_name: 3 },
  { stand_id: 4, stand_name: 4 },
  { stand_id: 5, stand_name: 5 },
  { stand_id: 6, stand_name: 6 },
  { stand_id: 7, stand_name: 7 },
  { stand_id: 8, stand_name: 8 },
  { stand_id: 8, stand_name: 9 },
  { stand_id: 9, stand_name: 9 },
  { stand_id: 10, stand_name: 10 },
  { stand_id: 11, stand_name: 11 },
  { stand_id: 12, stand_name: 12 },
]




const subjectsMockData = [
  { subj_id: 1, subj_name: "English" },
  { subj_id: 2, subj_name: "Maths" },
  { subj_id: 3, subj_name: "Social" },
  { subj_id: 4, subj_name: "Physics" },
  { subj_id: 5, subj_name: "Chemistry" },
  { subj_id: 6, subj_name: "Biology" },

]

const chaptersMockData = [
  { chap_id: 1, chap_name: "A Letter to God." },
  { chap_id: 2, chap_name: "Polynomials" },
  { chap_id: 3, chap_name: "Federalism" },
  { chap_id: 4, chap_name: " Light-Reflection and Refraction. " },
  { chap_id: 5, chap_name: " Acids, Bases and Salts" },
  { chap_id: 6, chap_name: "Life Processes" },

]

const TopicsMockData = [
  { topic_id: 1, topic_name: "How Do Organisms Reproduce" },
  { topic_id: 2, topic_name: "Our Environment" },
  { topic_id: 3, topic_name: " Sources Of Energy." },
  { topic_id: 4, topic_name: " Coordinate Geometry. " },
  { topic_id: 5, topic_name: " Acids, Bases and Salts" },
  { topic_id: 6, topic_name: "Metals and Non Metals." },


]


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


const headCells = [
  { id: 'Title', numeric: false, disablePadding: true, label: 'Title' },
  { id: 'Board', numeric: false, disablePadding: true, label: 'Board' },
  { id: 'Subject', numeric: false, disablePadding: true, label: 'Subject' },
  { id: 'Chapter', numeric: false, disablePadding: true, label: "Chapter" },
  { id: 'Topic', numeric: false, disablePadding: true, label: 'Topic' },
  { id: "Type", numeric: false, disablePadding: true, label: "Type" },
  // { id: "image", numeric: false, disablePadding: false, label: "image(if)" }
];






function EnhancedTableHead(props) {

  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const [openRightSidebar, setOpenRightSidebar] = useState(false)
  const [state, setState] = React.useState({ right: false, });
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };


  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif", color: "#B5B5C3" }}
          >

            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}

            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ?
      {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      :
      {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { pubId, instType, boardType, standardType, numSelected, currBook } = props;

  const [state, setState] = React.useState({
    right: props.toggleDrawer2,
  })
  const [drawerOpen, setDrawerOpen] = useState(props.toggleDrawer2)
  console.log(`drawerOpen ${drawerOpen}`)
  console.log(`props.toggleDrawer2 ${props.toggleDrawer2}`)

  const dispatch = useDispatch()

  useEffect(() => {

    return (
      <></>
    )
  }, [])

  const toggleDrawer = (anchor, open) => (event) => {

    console.log(`anchor is ${anchor}, open is ${open}`)
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  }

  const toggleDrawer2 = (open) => {

    console.log(`open is ${open}`)
    setDrawerOpen(open)
    props.setToggleDrawer2(open)
    props.setCurrBook(null)
  }

  console.log(`anchor1 right`)
  console.log(`state[anchor1] ${state['right']}`)

  return (
    < Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })
      }
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (


        <Typography
          className={classes.title} id="tableTitle" component="div"
          variant="h2"
          style={{
            marginTop: 10,
            marginBottom: 10
          }}>
          Academics Books
        </Typography>
      )}

      {
        numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="New Content">
            <></>
          </Tooltip>
        )
      }


      {
        /* ['right'].map((anchor) => {
          console.log(`anchor ${anchor}`)
          console.log(`state[anchor] ${state[anchor]}`)
          return (
 */
        <React.Fragment>

          <Button
            onClick={() => toggleDrawer2(true)}
            variant="contained"
            color="primary"
            className={classes.primary}
            style={{ width: "20%", backgroundColor: "#3699FF", textTransform: "none" }}
          >
            Add Book

          </Button>
          <Drawer anchor={'right'} open={props.toggleDrawer2} onClose={() => toggleDrawer2(false)}>
            <UploadEbookScreen
              key="uploadebook"
              onReloadEbooks={() => { dispatch(listSearchEbookGet(instType, boardType, standardType, pubId)) }}
              onCloseDrawer={(anchorType, status) => { toggleDrawer2(status) }}
              anchorType={'right'}
              currBook={currBook}
            />
          </Drawer>
        </React.Fragment>
        /* )
      }) */
      }

    </Toolbar >

  );
};






EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

  },
  root1: {
    maxWidth: 250,
    minHeight: 400
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(3),
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  formControl1: {
    margin: theme.spacing(1),
    minWidth: 250,
    minHeight: 30,

  },
  primary: {
    width: "25%",
    height: 50,
  }
}));


function MovetoTrashDialog(props) {
  const { pubBookData, bookId } = props
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false);


  console.log("pubBooksData at Movetotrash dialog", pubBookData, bookId)
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const moveTrash = (id) => {
    console.log("book id is ", id)
    const status = 0
    dispatch(listTrashBinPost(id, status))

  }

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        Move to Trash
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Move to Trash"}</DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <DeleteOutlinedIcon color="secondary" style={{ fontSize: 60 }} />
          </div>
          <DialogContentText>
            Are you sure, Do you want to move <strong>{pubBookData.ebook_name_text}</strong> to trash bin
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => { moveTrash(bookId) }} color="secondary" autoFocus>
            Yes,Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}







export default function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(2);
  const [title, setTitle] = useState("")

  const [age, setAge] = React.useState('');
  const [instType, setInstType] = useState("")
  const [boardType, setBoardType] = useState("")
  const [subjectType, setSubjectType] = useState("")
  const [standardType, setStandardType] = useState("")
  const [filtersResOpen, setFiltersResOpen] = useState(false)
  const [pubId, setPubId] = useState(2)



  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const dispatch = useDispatch()

  function setPublisher() {
    const userId = Cookie.get('kgtopg.guruculum.user.id')
    setPubId(userId)

    console.log(`pubId is ${userId}`)
  }


  const handleTitleChange = (event) => {
    console.log(`new title ${event}`)
    const { name, value } = event.target
    setTitle(value)

  }




  const getSearchResults = useSelector(state => state.searchEbook)
  const { loadingSearch, searchEbookData, errorSearchEbook } = getSearchResults

  const getAllEbooks = useSelector(state => state.allBooksGet)
  const { loadingAllBooks, allBooksGet, errorAllBooks } = getAllEbooks

  {
    loadingSearch && searchEbookData ?

      console.log("no data ")
      :
      console.log("search results", loadingSearch, searchEbookData)

  }


  {
    loadingAllBooks && getAllEbooks ?

      console.log("no data ")
      :
      console.log("default results in  mybooksscreen", allBooksGet)

  }






  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 2));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);


  const DeleteDialogSlide = (props) => {
    const { bookId, pubData, pubId, instType, boardType, standardType } = props
    const [open, setOpen] = React.useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);


    console.log("pubData at delete confiramtion", pubData, bookId)
    const handleClickOpen = () => {
      setOpen(true);
    };


    const handleCloseDialog = () => {
      setOpen(false);
    };



    const handleCloseSnack = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpenSnack(false);
    };

    const dispatch = useDispatch()

    const handleDeleteBook = async (bookId) => {
      console.log("book id  for deleting is ", bookId)
      const response = await PUBLISHERAPI.DeleteSingleEbook(bookId)
      handleCloseDialog()
      setOpenSnack(true);
      dispatch(listSearchEbookGet(instType, boardType, standardType, pubId))

      // console.log("response for delete ",response)


    }

    return (
      <div>
        <Button color="secondary" onClick={handleClickOpen}>
          Delete
        </Button>

        <Dialog
          open={open}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are you Sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ display: "flex", justifyContent: "center", alignSelf: "center" }}>
                <CancelOutlinedIcon color="secondary" style={{ fontSize: 60, }} />
              </div>
              Are you sure? Do you really want to delete this book?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary" style={{ textTransform: "none" }}>
              Cancel
            </Button>
            <Button onClick={() => handleDeleteBook(bookId)} color="secondary" style={{ textTransform: "none" }}>
              Yes,Delete it
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnack}>
          <Alert onClose={handleCloseSnack} severity="success">
            Book  is deleted successfully!
          </Alert>
        </Snackbar>
      </div>
    );
  }

  const BookMenu = (props) => {
    const { pubData, book_id, ind, updateSideStatus, pubId, instType, boardType, standardType } = props
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>

        <IconButton
          style={{
            padding: 0,
            margin: 0
          }}
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Link to={"/publisher-ebook/map-content?book_id=" + pubData.id} style={{ textDecoration: "none", color: "grey" }} key={ind} onClick={() => updateSideStatus(true, pubData)}>
            <MenuItem >
              <Button color="primary">
                View Book
              </Button>
            </MenuItem>
          </Link>

          {/* EDIT BOOK */}
          <MenuItem >
            <Button color="primary"
              onClick={() => props.toggleDrawer(pubData)}>
              Edit Book
            </Button>
          </MenuItem>

          <MenuItem >
            <MovetoTrashDialog pubBookData={pubData} bookId={book_id} />
          </MenuItem>

          {/* <MenuItem onClick={handleClose}>
            <DeleteDialogSlide
              bookId={book_id}
              pubData={pubData}
              pubId={pubId}
              instType={instType}
              boardType={boardType}
              standardType={standardType}
            />
          </MenuItem> */}


        </Menu>
      </div>
    );
  }






  const BooksCard = (props) => {
    console.log("props", props)
    let bookData = props.pubData
    let pubId = props.pubId
    let instType = props.instType
    let boardType = props.boardType
    let standardType = props.standardType

    console.log("pubData at booksCard", bookData)
    const updateSideBarStatus = (status, bookData) => {
      props.updateSidebar(status, bookData)
    }

    return (
      props ?
        <div style={{ margin: 10, height: 400 }}>
          <Card className={classes.root1}>

            <CardActionArea
              style={{
                height: 360
              }}>
              {
                bookData.ebook_image_url ?
                  <Link to={"/publisher-ebook/map-content?book_id=" + bookData.id} style={{ textDecoration: "none", }} key={props.ind} onClick={() => props.updateSidebar(true, bookData)}>
                    <CardMedia
                      component="img"
                      alt={bookData.ebook_name_text}
                      // height="200"
                      image={bookData.ebook_image_url ? bookData.ebook_image_url : bookCover}
                      title={bookData.ebook_name_text}
                      style={{ resize: "block", objectFit: "cover", height: 360, minWidth: 250 }}
                    />
                  </Link>
                  :
                  <CardMedia
                    component="img"
                    alt={bookData.ebook_name_text}
                    // width="150"
                    // height="200"
                    image={bookCover}
                    title={bookData.ebook_name_text}
                    // style={{ resize: "block", objectFit: "fill" }}
                    style={{ resize: "block", objectFit: "cover", height: 360, minWidth: 250 }}
                  />
              }

              {/* <CardContent style={{ padding: 0, alignSelf: 'flex-end' }}> */}
              {/* <CardContent> */}
                {/* <div style={{ display: "flex", flexDirection: "row", padding: 10, justifyContent: 'space-between', alignItems: "flex-end", }}> */}

                {/* 
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 16,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}>
                    {bookData.ebook_name_text}
                  </Typography> */}

                {/* <Typography gutterBottom variant="h5" component="h2">
                  {bookData.ebook_name_text}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Standard {bookData.classroom_std}
                </Typography> */}

                {/* </div> */}

                {/* <div >
                  <MenuOption pubData={bookData} book_id={bookData.id} ind={props.ind} updateSideStatus={(status, pubData) => { updateSideBarStatus(status, pubData) }} />
                </div> */}


              {/* </CardContent> */}
            </CardActionArea>

            <CardActions
              style={{
                justifyContent: 'space-between',
                alignItems: 'flex-end'
              }}>
              {/* <Button size="small" color="primary">
                Share
              </Button> */}
              <Typography gutterBottom variant="h5" component="h2"
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }}>
                {bookData.ebook_name_text}
              </Typography>
              <BookMenu
                pubId={pubId}
                instType={instType}
                boardType={boardType}
                standardType={standardType}
                pubData={bookData}
                book_id={bookData.id}
                ind={props.ind}
                updateSideStatus={(status, pubData) => { updateSideBarStatus(status, pubData) }}
                toggleDrawer={(bookData) => props.toggleDrawer(bookData)}
              />

            </CardActions>
          </Card>
        </div>

        :
        <div style={{ margin: 10 }}>
          <Card className={classes.root1}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="No Title"
                height="200"
                image={text1}
                title="No Title"
                style={{ resize: "block" }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 16 }}>
                  No Book
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>


        </div>
    )
  }

  const [state, setState] = React.useState({
    right: false,
  });

  const [ebookData, setEbookData] = useState(null)

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  }


  const getPublisherBooks = async () => {

    const response = await PUBLISHERAPI.GetPublisherEbooks()

    let respData = response.data.data
    setEbookData(respData)

    // console.log("response get all books",response)

  }




  const boardDetailsGet = useSelector(state => state.boardDetails)
  const { loading, boardDetails, error } = boardDetailsGet

  const instDetailsGet = useSelector(state => state.instDetails)
  const { loadingInst, instDetails, errorInst } = instDetailsGet

  const subDetailsGet = useSelector(state => state.subDetails)
  const { loadingSub, subDetails, errorSub } = subDetailsGet
  const [toggleDrawer2, setToggleDrawer2] = useState(false)
  const [currBook, setCurrBook] = useState(null)

  console.log("subject details at my books screen", loadingSub, subDetails)
  console.log(`table toggledrawer2 ${toggleDrawer2}`);

  const handleInstChange = (event) => {
    console.log('called handleInstChange')
    console.log(`new institute type ${event}`)
    const name = event.target.name;
    const value = event.target.value;
    const pub_id = pubId

    setInstType(value)
    dispatch(listBoardGet(value))
    dispatch(listStdGet(value, boardType))
    dispatch(listSearchEbookGet(value, boardType, standardType, pub_id))

  }




  const handleBoardChange = (event) => {
    console.log('called handleBoardChange')
    console.log(`new board  ${event}`)

    const name = event.target.name;
    const value = event.target.value;
    console.log(`board selected value ${value}`)
    const pub_id = pubId
    setBoardType(value)
    dispatch(listStdGet(instType, value))
    dispatch(listSearchEbookGet(instType, value, standardType, pub_id))

  }


  const handleSubChange = (event) => {
    console.log('called handleSubChange')
    console.log(`new subject ${event}`)

    const name = event.target.name;
    const value = event.target.value;
    setSubjectType(value)




  }


  const handleStdChange = (event) => {
    console.log('called handleStdChange')
    console.log(`new standard ${event}`)

    const name = event.target.name;
    const value = event.target.value;
    setStandardType(value)
    //dispatch(listSubGet(instType))
    const pub_id = pubId
    if (value) {
      console.log("handlestdChange ==============================", instType, boardType, value, pub_id)
      dispatch(listSearchEbookGet(instType, boardType, value, pub_id))
    }
    setFiltersResOpen(true)
  }


  async function getData() {
    await new Promise((resolve, reject) => {
      dispatch(listInstGet())

      resolve()
    })

    dispatch(listChpGet())

  }


  useEffect(() => {
    // getPublisherBooks()
    // dispatch(listSearchEbookGet(""))
    dispatch(listEbooksGet())
    getData()

    setPublisher()
    return (() => {
      //
    })
  }, [])


  const stdDetailsGet = useSelector(state => state.stdDetails)
  const { loadingStd, stdDetails, errorStd } = stdDetailsGet;


  return (
    <div className={classes.root}
      style={{

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: '100%'
      }}>


      <div>
        <EnhancedTableToolbar
          pubId={pubId}
          instType={instType}
          boardType={boardType}
          standardType={standardType} numSelected={selected.length}
          toggleDrawer2={toggleDrawer2}
          setToggleDrawer2={(bool) => setToggleDrawer2(bool)}
          currBook={currBook}
          setCurrBook={(currBook) => setCurrBook(currBook)}
        />

        {/* <TableContainer> */}
        <div>
          <Divider light style={{ width: "100%", marginBottom: 20 }} />

          <Paper evaluation={2}>
            <div style={{ width: '100%', display: "flex", alignItems: "center", justifyContent: "start", marginBottom: 20, overflow: "auto", overflowX: "initial", scrollbarWidth: "thin" }}>


              {/* <div > */}




              {/* <div style={{ display: "flex", justifyContent: "center" ,marginTop:10}}>

                              <TextField 
                              id="outlined-basic" 
                              label="Search by Name" 
                              variant="outlined"
                              defaultValue={title}
                              onChange={handleTitleChange}
                        
                                  style={{
                                      width:250,
                                      fontFamily: "Poppins, Helvetica, sans-serif",
                                      display:"inline-block",
                                      lineHeight:"140%"

                        
                                  }}
                                  
                              />
                          </div>

                          <div style={{ display: "flex", justifyContent: "Left" }}>
                          <Typography>
                                  <span
                                      style={{
                                          fontSize: 12,
                                          color: "#464E5F",
                                          marginLeft: 5,
                                          fontFamily: "Poppins, Helvetica, sans-serif",
                                      }}
                                  >
                                      Search by  <span
                                          style={{
                                              color: "#464E5F",
                                              fontWeight: "600",
                                              fontFamily: "Poppins, Helvetica, sans-serif",
                                              fontSize: 12
                                          }}>
                                        Name
                              </span>
                                  </span>
                              </Typography>

                              </div>

                      </div>

  */}










              {instDetailsGet ?

                <div style={{ display: "flex" }}>
                  <FormControl variant="outlined" className={classes.formControl1}>

                    <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F" }}>Institute Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={instType}
                      onChange={handleInstChange}
                      label="Institute Type"
                      style={{ fontSize: 13, color: "#464E5F" }}
                    >
                      {loadingInst && instDetailsGet ?
                        console.log("loading trueeee")
                        :
                        (instDetails && instDetails.data && instDetails.data.length != 0) ?

                          instDetails.data.map((list, index) => {
                            return (
                              <MenuItem key={index} value={list.institute_type_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_type_name}</MenuItem>


                            )
                          })
                          :

                          <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Institutes</MenuItem>

                      }

                    </Select>

                    <span
                      style={{
                        fontSize: 12,
                        color: "#464E5F",
                        marginLeft: 5,
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please select <span
                        style={{
                          color: "#464E5F",
                          fontWeight: "600",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                          fontSize: 12
                        }}>
                        Institute
                      </span>
                    </span>
                  </FormControl>
                </div>

                :

                <div style={{ display: "flex" }}>
                  <FormControl variant="outlined" className={classes.formControl1}>

                    <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F" }}>Institute Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={instType}
                      onChange={handleInstChange}
                      label="Institute Type"
                      style={{ fontSize: 13, color: "#464E5F" }}
                    >

                      <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Institutes</MenuItem>


                    </Select>

                    <span
                      style={{
                        fontSize: 12,
                        color: "#464E5F",
                        marginLeft: 5,
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please select <span
                        style={{
                          color: "#464E5F",
                          fontWeight: "600",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                          fontSize: 12
                        }}>
                        Institute
                      </span>
                    </span>
                  </FormControl>
                </div>

              }

              {boardDetailsGet && boardDetails && boardDetails.length != 0 && boardDetails.data ?

                <div style={{ display: "flex", }}>
                  <FormControl variant="outlined" className={classes.formControl1}>

                    <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", }}>Board</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={boardType}
                      onChange={handleBoardChange}
                      label="Board"
                      style={{ fontSize: 13, color: "#464E5F" }}
                    >
                      {(loading && boardDetailsGet) ?
                        console.log("loading trueeee")
                        :
                        (boardDetails && boardDetails.length != 0) ?

                          boardDetails.data.map((list, index) => {
                            return (
                              <MenuItem key={index} value={list.institute_board_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_board_name}</MenuItem>

                            )
                          })
                          :
                          <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Please Select Institute Type</MenuItem>

                        // boardDetailsMock.map((list, index) => {
                        //     return (
                        //         <MenuItem key={index} value={index} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_board_name}</MenuItem>
                        //     )
                        // })

                      }


                    </Select>
                    <span
                      style={{
                        fontSize: 12,
                        color: "#464E5F",
                        marginLeft: 5,
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please select <span
                        style={{
                          color: "#464E5F",
                          fontWeight: "600",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                          fontSize: 12
                        }}>
                        Board
                      </span>
                    </span>
                  </FormControl>
                </div>
                :
                <div style={{ display: "flex", }}>
                  <FormControl variant="outlined" className={classes.formControl1}>

                    <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", }}>Board</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={boardType}
                      onChange={handleBoardChange}
                      label="Board"
                      style={{ fontSize: 13, color: "#464E5F" }}
                    >

                      <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Boards </MenuItem>

                    </Select>
                    <span
                      style={{
                        fontSize: 12,
                        color: "#464E5F",
                        marginLeft: 5,
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please select <span
                        style={{
                          color: "#464E5F",
                          fontWeight: "600",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                          fontSize: 12
                        }}>
                        Board
                      </span>
                    </span>
                  </FormControl>
                </div>

              }



              {stdDetailsGet ?
                <div style={{ display: "flex", }}>
                  <FormControl variant="outlined" className={classes.formControl1}>

                    <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", }}>Standard</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={standardType}
                      onChange={handleStdChange}
                      label="Standard"
                      style={{ fontSize: 13, color: "#464E5F" }}
                    >


                      {loadingStd && stdDetailsGet ?
                        console.log("loading trueeee")
                        :
                        stdDetails.length != 0 ?

                          stdDetails.data.map((list, index) => {
                            return (
                              <MenuItem key={index} value={list.id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.standard}</MenuItem>

                            )
                          })
                          :
                          <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Please Select Institute Type</MenuItem>

                        // standardsMockData.map((list, index) => {
                        //     return (
                        //         <MenuItem key={index} value={index} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.stand_name}</MenuItem>
                        //     )
                        // })
                      }

                    </Select>
                    <span
                      style={{
                        fontSize: 12,
                        color: "#464E5F",
                        marginLeft: 5,
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please select <span
                        style={{
                          color: "#464E5F",
                          fontWeight: "600",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                          fontSize: 12
                        }}>
                        Standard
                      </span>
                    </span>
                  </FormControl>
                </div>

                :
                <div style={{ display: "flex", }}>
                  <FormControl variant="outlined" className={classes.formControl1}>

                    <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", }}>Standard</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={standardType}
                      onChange={handleStdChange}
                      label="Standard"
                      style={{ fontSize: 13, color: "#464E5F" }}
                    >



                      <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Standards</MenuItem>



                    </Select>
                    <span
                      style={{
                        fontSize: 12,
                        color: "#464E5F",
                        marginLeft: 5,
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please select <span
                        style={{
                          color: "#464E5F",
                          fontWeight: "600",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                          fontSize: 12
                        }}>
                        Standard
                      </span>
                    </span>
                  </FormControl>
                </div>

              }





            </div>

          </Paper>


          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start" }}>


            {
              !filtersResOpen
                ?
                loadingAllBooks && allBooksGet ?

                  console.log("FilterData at rendering", searchEbookData)
                  :

                  allBooksGet && allBooksGet.data ?
                    allBooksGet && allBooksGet.data.length > 0 ?
                      allBooksGet.data.map((pubData, index) => {
                        console.log("pubDataa at mybooks", pubData)
                        return (
                          // <Link to={"/publisher-ebook/map-content?book_id="+pubData.id }   style={{ textDecoration: "none", }} key={index} onClick={() => props.sidebarStatus(true,pubData)}  >
                          <BooksCard
                            pubData={pubData}
                            updateSidebar={(status, bookData) => props.sidebarStatus(status, bookData)}
                            ind={index}
                            pubId={pubId}
                            instType={instType}
                            boardType={boardType}
                            standardType={standardType}
                            toggleDrawer={(bookData) => { setToggleDrawer2(true); setCurrBook(bookData) }} // reload full parent to affect child vars
                          />

                          // </Link>
                        )
                      })
                      :

                      <Paper
                        style={{
                          width: "100%",
                          height: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 20,
                          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",


                        }}>
                        <Alert
                          style={{
                            width: '100%',
                          }}
                          severity="error"
                        >
                          <AlertTitle>No Books found!</AlertTitle>
                          No Books are available under this selection  — <strong>try changing your selection!</strong>
                        </Alert>
                      </Paper>

                    :
                    <Paper
                      style={{
                        width: "100%",
                        height: "50%",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        marginTop: 20,
                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",


                      }}>

                      <Alert
                        style={{
                          width: '100%',

                        }} severity="warning">
                        <AlertTitle>No fields are selected!</AlertTitle>
                        You have not selected any fields — <strong>Please use the fields above to show books</strong>
                      </Alert>
                    </Paper>

                :
                loadingSearch && searchEbookData ?

                  console.log("searchEbookData at rendering", searchEbookData)
                  :

                  searchEbookData && searchEbookData.data ?
                    searchEbookData && searchEbookData.data.length > 0 ?
                      searchEbookData.data.map((pubData, index) => {
                        console.log("pubDataa at mybooks", pubData)
                        return (
                          // <Link to={"/publisher-ebook/map-content?book_id="+pubData.id }   style={{ textDecoration: "none", }} key={index} onClick={() => props.sidebarStatus(true,pubData)}  >
                          <BooksCard
                            pubData={pubData}
                            updateSidebar={(status, bookData) => props.sidebarStatus(status, bookData)}
                            ind={index}
                            pubId={pubId}
                            instType={instType}
                            boardType={boardType}
                            standardType={standardType}
                            toggleDrawer={(bookData) => { setToggleDrawer2(true); setCurrBook(bookData) }} // reload full parent to affect child vars
                          />
                          // </Link>
                        )
                      })
                      :

                      <Paper
                        style={{
                          width: "100%",
                          height: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 20,
                          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",


                        }}>
                        <Alert
                          style={{
                            width: '100%',
                          }}
                          severity="error"
                        >
                          <AlertTitle>No Books found!</AlertTitle>
                          No Books are available under this selection  — <strong>try changing your selection!</strong>
                        </Alert>
                      </Paper>

                    :
                    <Paper
                      style={{
                        width: "100%",
                        height: "50%",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        marginTop: 20,
                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",


                      }}>

                      <Alert
                        style={{
                          width: '100%',

                        }} severity="warning">
                        <AlertTitle>No fields are selected!</AlertTitle>
                        You have not selected any fields — <strong>Please use the fields above to show books</strong>
                      </Alert>
                    </Paper>

            }

          </div>
        </div>
        {/* </TableContainer> */}
      </div>
    </div >
  );
}