import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography
} from '@material-ui/core';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';

import { Search as SearchIcon } from 'react-feather';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@mui/material/MenuItem';

import NativeSelect from '@material-ui/core/NativeSelect';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Switch from "@mui/material/Switch";
import Typography2 from "@mui/material/Typography";

import { useDispatch } from "react-redux";
import { listBoardGet } from "../../../redux/actions/boardDetailsAction";
import Constants from '../../../resource/Constants';
import { useLocation } from 'react-router-dom';
import Cookie from "js-cookie";

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MapAPI from "../../../http/curriculummap";
import { updateCurrMap } from '../../../redux/actions/currMapActions';

const TAG = 'UnitListToolbar.js';
const swLabel = { inputProps: { 'aria-label': 'Switch demo' } };

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  shape: {
    backgroundColor: theme.palette.primary.main,
    width: 20,
    height: 20,
  },
  shape2: {
    backgroundColor: theme.palette.secondary.light,
    width: 20,
    height: 20,
  },
  shapeCircle: {
    borderRadius: '50%',
  },
}));

const PubDD = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <div>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-age-native-simple">Select type</InputLabel>
        <Select
          native
          value={state.age}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'filled-age-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          <option value={10}>Published or in Draft</option>
          <option value={20}>Draft</option>
          <option value={30}>Thirty</option>
        </Select>
      </FormControl>
    </div>
  )
}

const ActDD = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <div>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-age-native-simple">Activity</InputLabel>
        <Select
          native
          value={state.age}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'filled-age-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          <option value={10}>Any Activity</option>
          <option value={20}>All Activities</option>
          <option value={30}>Thirty</option>
        </Select>
      </FormControl>
    </div>
  )
}

function BasicBoardSelect(props) {
  const [board, setBoard] = React.useState(-1);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Access individual query parameters
  const instTypeId5 = queryParams.get('inst_id');
  const instBoardId5 = parseInt(queryParams.get('board_id'));
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const boardDetailsGet = useSelector((state) => state.boardDetails);
  const { loading, boardDetails, error } = boardDetailsGet;
  const currMap = useSelector(state => state.currMap.currMap)

  const handleBoardChange = (event) => {
    
    // show snackbar
    setOpen(true);

    let board2 = event.target.value;
    setBoard(board2);
    console.log(TAG, 'handleBoardChange value', board2);

    // call the api to update the map
    props.setBoard(board2);
    currMap.institute_board_id = board2;
    dispatch(updateCurrMap(currMap));

    setTimeout(function() {
      props.handleUpdateMap(false, board2);
    }, 2000);
    
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      {/* <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select Board</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={board}
          label="Choose Board"
          onChange={handleBoardChange}
        >
          <MenuItem value={10}>CBSE</MenuItem>
          <MenuItem value={20}>TSCERT</MenuItem>
          <MenuItem value={30}>SSC</MenuItem>
        </Select>
      </FormControl> */}


      {/* institute board */}
      <FormControl

        style={{

        }}
        required
        fullWidth
      >
        {/* <InputLabel
          htmlFor="filled-age-native-simple"
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          Board of Education
        </InputLabel> */}
        <Select

          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={instBoardId5}
          label="Choose Board"
          onChange={handleBoardChange}
        >
          <MenuItem
            key={-1}
            value={-1}
            style={{
              fontSize: 13,
              color: "#464E5F",
              fontFamily: "Poppins, Helvetica, sans-serif",
            }}
          >
            Select Board
          </MenuItem>
          {boardDetails && boardDetails.data ? (
            boardDetails.data.map((brd, index) => {
              return (
                <MenuItem
                  key={index}
                  value={brd.institute_board_id}
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  {brd.institute_board_name}
                </MenuItem>
              );
            })


          ) : (
            <MenuItem key={-2} value={-2}
              style={{
                fontSize: 13,
                color: "#464E5F",
                fontFamily: "Poppins, Helvetica, sans-serif",
              }}
            >
              No Boards
            </MenuItem>
          )}


          <MenuItem value={Constants.Application.CUSTOM_BOARD_ID}
            style={{
              fontSize: 13,
              color: "#464E5F",
              fontFamily: "Poppins, Helvetica, sans-serif",
            }}
          >Own board</MenuItem>

        </Select>
      </FormControl>

      {/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Please wait for the changes to get affected.."
        // action={action}
      />
    </Box>
  );
}

const MapListToolbar = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Access individual query parameters
  const instTypeId5 = queryParams.get('inst_id');
  const instBoardId5 = parseInt(queryParams.get('board_id'));

  const [open, setOpen] = React.useState(false);
  const rectangle = <div className={classes.shape} />;
  const circle = <div className={clsx(classes.shape, classes.shapeCircle)} />;
  const [open2, setOpen2] = React.useState(false);

  const currMap = useSelector(state => state.currMap.currMap)
  const mapId = (currMap != null) ? currMap.id : 0
  const title = (currMap != null) ? currMap.map_title_text : '';
  const instTypeId = (currMap != null) ? currMap.institute_type_id : 0;
  const instBoardId = (currMap != null) ? currMap.institute_board_id : 0;
  const [isIntegrated, setIsIntegrated] = useState((instBoardId5 === Constants.Application.CUSTOM_BOARD_ID));
  const [board, setBoard] = useState(instBoardId);
  const [snackOpen, setSnackOpen] = useState(false);

  let isIntegrated2 = (instBoardId5 == Constants.Application.CUSTOM_BOARD_ID);
  console.log(TAG, 'MapListToolbar isIntegrated2 ', isIntegrated2);

  console.log(TAG, 'MapListToolbar currMap ', currMap);
  const handleClick2 = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleSnackClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleClick = () => {
    setOpen2(true);
  };

  const handleClose2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen2(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangeIntegrated = (event) => {
    console.log(TAG, 'handleChangeIsIntegrated value ', event.target.value);
    setIsIntegrated(event.target.checked); // it's boolean

    if (event.target.checked) {

      setSnackOpen(true);

      setTimeout(function() {

        // set board to custom and save
        setBoard(Constants.Application.CUSTOM_BOARD_ID);
        handleUpdateMap(true);
      }, 2000);

    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const _getBoards = () => {
    dispatch(listBoardGet(instTypeId)); // school type boards only for now
  }

  const _checkIsIntegrated = () => {

    let instBoardId = (currMap != null) ? currMap.institute_board_id : 0;
    let isIntegrated3 = (instBoardId5 === Constants.Application.CUSTOM_BOARD_ID);

    console.log(TAG, '_checkIsIntegrated instBoardId ', instBoardId);
    console.log(TAG, '_checkIsIntegrated isIntegrated3 ', isIntegrated3);
    setIsIntegrated(isIntegrated3);
  }

  const handleUpdateMap = async (isCustom, board3) => {
    const userId = Cookie.get("kgtopg.guruculum.user.id");
    const mapId = currMap.map_id ? currMap.map_id : currMap.id;

    const title = currMap.map_title_text;
    const instType = currMap.institute_type_id;

    console.log(TAG, `handleUpdateMap current map ${JSON.stringify(currMap)}`);
    console.log(TAG, `handleUpdateMap mapId ${mapId}`);
    console.log(TAG, `handleUpdateMap mapTitle ${title}`);

    // let tags2 = yourChips.join(",");
    let tags2 = '';

    /*
    if (!title || title.length < 10 || !mapId) {
      // alert('Please enter title..');
      setOpenSnack(true);
      return;
    }
    */
    let board2 = (isCustom) ? Constants.Application.CUSTOM_BOARD_ID : board3;

    const body = {
      institute_type_id: instType,
      institute_board_id: board2,
      classroom_std: currMap.classroom_std,
      subject_id: currMap.subject_id,
      sub_subject_id: currMap.sub_subject_id,
      unit_count: currMap.unit_count,
      // publisher_id: 1,
      map_title_text: title,
      map_tags: currMap.map_tags,
      term_number: currMap.term_number,
    };

    console.log(TAG, "handleUpdateMap update map body ", JSON.stringify(body));
    const resp = await MapAPI.postUpdateMap(body, mapId);
    console.log(
      TAG, `handleUpdateMap response from update map api response ${JSON.stringify(resp)}`
    );

    handleClose(); // close dialog

    //    alert('Map updated successfully..')

    // update map in redux
    currMap.institute_board_id = board2;
    dispatch(updateCurrMap(currMap));
    
    let currURL = window.location.href;
    const newURL = replaceUrlKeyValue(currURL, 'board_id', board2);
    window.location.assign(newURL);
  };

  function replaceUrlKeyValue(url, key, value) {
    // Create a regular expression pattern for the key=value string
    const pattern = new RegExp(`${key}=[^&]*`);
  
    // Replace the key=value string with the new key=value pair
    const replacedUrl = url.replace(pattern, `${key}=${value}`);
  
    return replacedUrl;
  }


  useEffect(() => {

    // get boards
    _getBoards();
    // check is integrated
    _checkIsIntegrated();
    return {

    }
  }, () => { })

  return (
    <Box {...props}>

      <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%' }}>
          {/* <LibraryBooksIcon /> */}

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}>
            <Typography
              color="textPrimary"
              // display="inline"
              // sx={{ pl: 1 }}
              variant="h2"
              style={{
                // fontSize: 18,
                marginLeft: 0
              }}
            >
              {title} - Lesson Plan Setter
            </Typography>
            <Typography
              variant="caption"
              style={{
                fontSize: 11,
                color: 'gray'
              }}>
              {`LAST UPDATED ON ${(currMap) ? moment(currMap.created_date) : 'n/a'}`}
            </Typography>
            {/* <Chip label={`DRAFT UPDATED ${(currMap) ? moment(currMap.created_date) : 'n/a'}`} /> */}
          </div>

          {/* curriculum type */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}>
            {/* if not integrated */}
            {(!isIntegrated) ?
              <>
                <BasicBoardSelect
                  handleUpdateMap={handleUpdateMap}
                  setBoard={(val) => setBoard(val)}

                />
                <div style={{ margin: 10 }}></div>
              </>
              :
              <></>
            }
{/* 
            <>
              <Typography2
                variant="h6">Integrated Curriculum</Typography2>
              <Switch {...swLabel}
                checked={isIntegrated}
                onChange={handleChangeIntegrated}
              />
            </> */}

          </div>
          <Snackbar
            open={snackOpen}
            autoHideDuration={6000}
            onClose={handleSnackClose}
            message="Please wait for the changes to get affected.."
            // action={action}
          />
        </div>

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          style={{
            display: 'none'
          }}
        >
          <Button sx={{ mx: 2 }} style={{ marginRight: 20 }} onClick={handleClick}>
            Export
          </Button>
          <Button
            color="primary"
            variant="contained"
            // onClick={handleClickOpen}
            onClick={handleClick}
          >
            PUBLISH
          </Button>
          <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
            <Alert onClose={handleClose2} severity="info">
              This feature is coming soon!
            </Alert>
          </Snackbar>
        </Box>
      </div>

      <div>
        {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Create New Curriculum Map</DialogTitle>
          <DialogContent>
            <DialogContentText>
              New Curriculum Map lets you setup a new curriculum unit sets with time and pacing.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Curriculum Map Name"
              type="text"
              fullWidth
            />

            <FormControl variant="filled" style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
              <InputLabel htmlFor="filled-age-native-simple">Template</InputLabel>
              <Select
                native
                value={state.age}
                onChange={handleChange}
                inputProps={{
                  name: 'age',
                  id: 'filled-age-native-simple',
                }}
              >
                <option aria-label="None" value="" />
                <option value={10}>KGtoPG - Default UBD Template</option>
                <option value={20}>Twenty</option>
                <option value={30}>Thirty</option>
              </Select>
            </FormControl>

            <TextField
              autoFocus
              margin="dense"
              id="tags"
              label="Tags"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleClose} color="primary">
              Subscribe
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {/* 

      <Box sx={{ mt: 3 }} style={{ marginTop: 20 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: '100%' }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search for curriculum map"
                variant="outlined"
              />
            </Box>

            <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
              <PubDD />
              <div style={{ width: 10 }} />
              <ActDD />
            </div>
          </CardContent>
        </Card>
      </Box>
    
     */}
    </Box>
  )
};

export default MapListToolbar;
