import React, { useEffect, useState, useRef } from "react";
import { Box, Container } from "@material-ui/core";
import JoditEditor from "jodit-react";

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import IconButton2 from "@mui/material/IconButton";
import DoneIcon2 from "@mui/icons-material/Done";
import Drawer2 from "@mui/material/Drawer";

import Card2 from "@mui/material/Card";
import CardActions2 from "@mui/material/CardActions";
import CardContent2 from "@mui/material/CardContent";
import Typography2 from "@mui/material/Typography";
import CardActionArea2 from "@mui/material/CardActionArea";
import Alert2 from "@mui/material/Alert";
import ListItem2 from "@mui/material/ListItem";
import ListItemButton2 from "@mui/material/ListItemButton";
import ListItemIcon2 from "@mui/material/ListItemIcon";
import ListItemText2 from "@mui/material/ListItemText";
import Checkbox2 from "@mui/material/Checkbox";
import ExpandLess2 from "@mui/icons-material/ExpandLess";
import Collapse2 from "@mui/material/Collapse";
import ExpandMore2 from "@mui/icons-material/ExpandMore";
import List2 from "@mui/material/List";
import Button2 from "@mui/material/Button";
import Divider2 from "@mui/material/Divider";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import QuizPlannerDrawer from "./QuizPlannerDrawer";
import MediaPlannerDrawer from "./MediaPlannerDrawer";
import ClearIcon from "@mui/icons-material/Clear";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip2 from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

import Select2 from "@mui/material/Select";
import MenuItem2 from "@mui/material/MenuItem";
import InputLabel2 from "@mui/material/InputLabel";
import NativeSelect2 from "@mui/material/NativeSelect";
import FormControl2 from "@mui/material/FormControl";

import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Constants from "../../../resource/Constants";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/dist/ProfilePicture.css";
import GPTGenerator from "./GPTGenerator";

function BriefField(props) {
  const { name, planSetup } = props;
  const [content, setContent] = useState("");

  const editor = useRef(null);
  const onContentChanged = (newContent) => {
    setContent(newContent);

    handleChange2(newContent);
  };

  const handleChange = (event) => {
    const { name2, value } = event.target;

    console.log("AssessmentList.js BriefField handleChange name is " + name);
    console.log("AssessmentList.js BriefField handleChange name2 " + name2);
    console.log("AssessmentList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    /*
    switch (name.toLowerCase()) {
      case "aim":
        newPlanSetup.aim.summary = value;

        break;
      case "action":
        newPlanSetup.action.summary = value;

        break;
      case "analysis":
        newPlanSetup.analysis.summary = value;

        break;
      case "application":
        newPlanSetup.application.summary = value;

        break;
      case "assessment":
        newPlanSetup.assessment.summary = value;

        break;
    }
    */

    newPlanSetup.assessment.summary = value;
    props.handleUpdatePlanSetup(newPlanSetup);
  };
  const handleChange2 = (value) => {
    console.log("AssessmentList.js BriefField handleChange name is " + name);

    console.log("AssessmentList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    /*
    switch (name.toLowerCase()) {
      case "aim":
        newPlanSetup.aim.summary = value;

        break;
      case "action":
        newPlanSetup.action.summary = value;

        break;
      case "analysis":
        newPlanSetup.analysis.summary = value;

        break;
      case "application":
        newPlanSetup.application.summary = value;

        break;
      case "assessment":
        newPlanSetup.assessment.summary = value;

        break;
    }
    */

    newPlanSetup.assessment.summary = value;
    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const val = planSetup.assessment.summary;

  return (
    <div sx={{ minWidth: "100%" }}>
      <>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SUBHEADING value={`Teacher Summary for ${name}`} />

            <GPTGenerator
            handleUpdateGPTText={(text) => handleChange2(text)}
            prompt={`one sentence teacher summary for ${name}`}
            planSetup={planSetup} 
          />
          </div>

          <Box
            sx={{
              // width: 500,
              maxWidth: "100%",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              // defaultValue={val}
              value={val}
              fullWidth
              label="Enter summary"
              placeholder="Enter the text here"
              id="fullWidth"
              variant="outlined"
              onChange={handleChange}
              inputProps={{
                maxLength: 500,
              }}
            />

            {/* <JoditEditor
                            ref={editor}
                            value={val}
                            tabIndex={1}
                            onChange={onContentChanged}
                        /> */}
          </Box>
        </div>
      </>
    </div>
  );
}

const TEXTBOX3 = (props) => {
  const { num, desc, planSetup } = props;
  const [content, setContent] = useState("");
  const editor = useRef(null);
  const onContentChanged = (newContent) => {
    setContent(newContent);

    handleChange2(newContent);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("AssessmentList.js BriefField handleChange name " + name);
    console.log("AssessmentList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    switch (num) {
      case 1:
        newPlanSetup.assessment.approachingText = value;
        break;
      case 2:
        newPlanSetup.assessment.meetingText = value;
        break;
      case 3:
        newPlanSetup.assessment.exceedingText = value;
        break;
    }

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const handleChange2 = (value) => {
    console.log("AssessmentList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    switch (num) {
      case 1:
        newPlanSetup.assessment.approachingText = value;
        break;
      case 2:
        newPlanSetup.assessment.meetingText = value;
        break;
      case 3:
        newPlanSetup.assessment.exceedingText = value;
        break;
    }

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  let text = "";
  switch (num) {
    case 1:
      text = planSetup.assessment.approachingText;
      break;
    case 2:
      text = planSetup.assessment.meetingText;
      break;
    case 3:
      text = planSetup.assessment.exceedingText;
      break;
  }

  return (
    <>
      <div sx={{ minWidth: "100%" }}>
        <>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <SUBHEADING value={desc} />
              {/* 
                            <ClearIcon color="warning"
                                style={{
                                    cursor: 'pointer'
                                }} /> */}
            </div>

            <Box
              sx={{
                // width: 500,
                maxWidth: "100%",
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {/* <TextField
                                defaultValue={text}
                                fullWidth label="Enter the text"
                                placeholder="Enter the text here" id="fullWidth"
                                variant="outlined"
                                onChange={handleChange}
                            /> */}
              <JoditEditor
                ref={editor}
                value={text}
                tabIndex={1}
                onChange={onContentChanged}
              />
            </Box>
          </div>
        </>
      </div>
    </>
  );
};

const SUBHEADING = (props) => {
  const { value } = props;

  return (
    <>
      <Typography variant="subtitle1">{value}</Typography>
    </>
  );
};

const ASSESSMENTLIST = (props) => {
  const { planSetup, planId, subId, subSubId, chapId, unitId } = props;

  const { assessment } = planSetup;
  return (
    <Card2 sx={{ minWidth: "100%" }}>
      <CardContent2>
        <div
          style={{
            margin: 10,
          }}
        ></div>
        <BriefField
          name="Reflection"
          planSetup={planSetup}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />
        <div
          style={{
            margin: 10,
          }}
        ></div>

        {/* <WorkbookItem desc="Check Worksheet" /> */}
        <div
          style={{
            margin: 10,
          }}
        ></div>

        {/*

        {assessment.hasApproachingText ? (
          <>
            <TEXTBOX3
              num={1}
              desc="Enter Approaching Text"
              planSetup={planSetup}
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
            />

            <div
              style={{
                margin: 20,
              }}
            ></div>
          </>
        ) : (
          <></>
        )}

        {assessment.hasMeetingText ? (
          <>
            <TEXTBOX3
              num={2}
              desc="Enter Meeting Text"
              planSetup={planSetup}
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
            />

            <div
              style={{
                margin: 20,
              }}
            ></div>
          </>
        ) : (
          <></>
        )}

        {assessment.hasExceedingText ? (
          <>
            <TEXTBOX3
              num={3}
              desc="Enter Exceeding Text"
              planSetup={planSetup}
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
            />

            <div
              style={{
                margin: 20,
              }}
            ></div>
          </>
        ) : (
          <></>
        )}
          */}


        {/* 
            {assessment.hasAssm ?
                <Card2 sx={{ minWidth: '100%' }}>
                    <CardContent2>
                        <QuizPlannerDrawer
                            chapId={chapId}
                            planSetup={planSetup}
                            type="assm"
                            name="Reflection" 
                            lsnPlanId={planId} 
                            subId={subId} 
                            subSubId={subSubId} 
                            noTime={false}
                            />
                    </CardContent2>
                </Card2>
                :
                <></>
            } */}
      </CardContent2>
    </Card2>
  );
};

export default ASSESSMENTLIST;
