/**
 * application constants
 *
 */

const Constants = {
  Application: {
    PLATFORM_END_URL: "https://admin.kgtopg.com",
    // PLATFORM_END_URL: "https://admin-test.kgtopg.com",
    // PLATFORM_END_URL: "http://192.168.29.224/platform",
    PARTNER_END_URL: "https://partner.kgtopg.com",
    // PARTNER_END_URL: "https://api5.kgtopg.com",
    // PARTNER_END_URL: "http://192.168.29.224/partner",
    NODEJS_END_URL: "https://api.kgtopg.com",
    // NODEJS_END_URL: "https://api3.kgtopg.com",
    // NODEJS_END_URL: "http://192.168.29.224/nodeserver",
    // NODEJS_END_URL: 'http://localhost:4000',
    // NODEJS_END_URL: 'http://localhost:4000',
    // QBANK_END_URL: "http://192.168.29.224/qbank",
    QBANK_END_URL: "https://qbank.co.in",
    // QBANK_END_URL: "https://chat.qbank.co.in",
    PUSTAK_URL: "https://pustak.co",
    // PUSTAK_URL: "https://demo.pustak.co",
    // PUSTAK_URL: "http://192.168.29.224/pustak",
    PUSTAK_BOOKS_URL: "https://pustak.co/static/books",
    // PUSTAK_BOOKS_URL: "https://demo.pustak.co/static/books",
    // PUSTAK_BOOKS_URL: "http://192.168.29.224/pustak/static/books",
    // PLATFORM_END_URL: 'http://192.168.10.117:8002',
    // PARTNER_END_URL: 'http://192.168.10.117:8004',
    SCHOOLER_URL: 'https://schooler.co.in',
    // GPT_PEDAGOGY_URL: 'http://192.168.29.251:8000/api',
    // GPT_PEDAGOGY_URL: 'http://192.168.29.224/examer/api',
    GPT_PEDAGOGY_URL: 'https://live.kgtopg.com/api',
    // SCHOOLER_URL: 'http://192.168.29.224:8080/my-webapp',
    // SCHOOLER_URL: 'https://test.schooler.co.in',
    TEMP_END_URL: "http://192.168.10.117:8003",
    TEMP_END_URL1: "http://192.168.10.117:8001",
    FIREBASE: {
      API_KEY: "AIzaSyBTCkmhlLRvtmztSh9yIl_rgfXvieYg3eY",
      AUTH_DOMAIN: "kgtopg-profiles.firebaseapp.com",
      // DATABASE_URL: 'https://<DATABASE_NAME>.firebaseio.com',
      STORAGE_BUCKET: "kgtopg-profiles.appspot.com",
    },
    ROLE_CONT_PROV: "CONTENT_PROVIDER",
    ROLE_EBOOK_PUB: "EBOOK_PROVIDER",
    ROLE_CONT_EBOOK_PUB: "CONTENT_PROVIDER AND PUBLISHER",
    QUIZ: {
      ID_QTYPE_MULTIPLE_CHOICE: 201,
      ID_QTYPE_MULTIPLE_SELECT: 202,
      ID_QTYPE_TRUE_FALSE: 203,
      ID_QTYPE_TEXT_TYPE: 204,
      ID_QTYPE_PICTURE_CHOICE: 205,
      ID_QTYPE_MATCH_FLW: 206,
    },
    NAME_AIM: "aim",
    NAME_ACTION: "action",
    NAME_ANALYSIS: "analysis",
    NAME_APPLICATION: "application",
    NAME_ASSESSMENT: "assessment",
    CURRICULUM_PLAN_SETUP: {
      lessonPlanId: 0,
      lessonPlanSetup: {
        id: 0,
        topic_id: 0,
        name: "",
        cover_image_url:
          "https://firebasestorage.googleapis.com/v0/b/kgtopg-profiles.appspot.com/o/files%2Fmm8HqrM6Oa.jpg?alt=media&token=a7cb13ad-fdcf-41ad-ada6-e92728f030dd",
      },
      aim: {
        isChecked: false,
        hasResource: true,
        hasKeyword: true,
        learning_outcome: "",
        summary: "Aim summary",
        description: "",
        mins: 0,
        participantCount: 0,
        resourceList: [
          {
            title: "",
            description: "",
            url: "",
          },
          {
            title: "",
            description: "",
            url: "",
          },
        ],
        keywordList: [
          {
            title: "",
            description: "",
          },
          {
            title: "",
            description: "",
          },
        ],
      },
      action: {
        isChecked: false,
        summary: "Action summary",
        hasSteps: true,
        hasTeacherTips: false,
        hasKeyword: false,
        mins: 0,
        participantCount: 0,
        engage: "",
        explore: "",
        explain: "",
        steps: [
          {
            title: "",
            hasImages: true,
            hasVideos: false,
            hasDocs: false,
            hasTips: true,
            images: [
              {
                url: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e?w=164&h=164&fit=crop&auto=format",
              },
              {
                url: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
              },
              {
                url: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
              },
            ],
            videos: [],
            docs: [],
            tips: [
              {
                title: "",
                description: "",
              },
            ],
          },
          {
            title: "",
            hasImages: false,
            hasVideos: false,
            hasDocs: false,
            hasTips: false,
            images: [],
            videos: [],
            docs: [],
            tips: [],
          },
        ],
        teacherTips: [],
        keywords: [],
      },
      analysis: {
        isChecked: false,
        summary: "Analysis summary",
        hasParts: true,
        hasAssm: true,
        mins: 10,
        participantCount: 3,
        elaborate: "",
        hasActivity: false,
        hasGeneralized: false,
        hasHOT: false,
        parts: [
          {
            heading: "Activity",
            headingExp: "Activity",
            headingCode: "ACTV",
            question: "",
            hasExp: true,
            explanation: [
              {
                name: "",
                type: "",
                description: "",
              },
              {
                name: "",
                type: "",
                description: "",
                text: "",
              },
            ],
            isSelected: false,
          },
          {
            heading: "Generalized",
            headingExp: "Generalized",
            headingCode: "GENERAL",
            question: "",
            hasExp: true,
            explanation: [
              {
                text: "",
              },
              {
                text: "",
              },
            ],
            isSelected: false,
          },
          {
            heading: "Higher-Order Thinking",
            headingExp: "Higher-Order Thinking",
            headingCode: "HOT",
            question: "",
            hasExp: true,
            explanation: [
              {
                text: "",
              },
              {
                text: "",
              },
            ],
            isSelected: false,
          },
        ],
      },
      application: {
        isChecked: false,
        summary: "Application summary",
        hasClasswork: true,
        hasMorePractice: true,
        hasHomework: true,
        mins: 0,
        participantCount: 1,
        classwork: {
          description: "",
          hasImage: true, // workbook can be image or document
          hasDocument: true,
          hasWorkBook: true,
          hasAssm: true,
          hasQandA: true,
          hasDiffTips: true,
          hasTips: true,
          teacherDiffTips: {
            help_description: "",
            extra_challenge: "",
          },
          imageURL: "",
          documentURL: "",
          workBookURL: "",
          assessment: {
            classroom_id: 0,
            staff_id: 0,
            staff_lesson_id: 0,
            subject_id: 0,
            subject_sub_id: 0,
            chapter_id: 0,
            quiz_title: "abcd",
            scheduled_date: "2020-12-20",
            quiz_ended: 0,
            question_text: "",
            question_time: "00:00", //+$(event.target.closest('div')).find('input').val(),//$("#timerbool").val(),
            question_url: "",
            question_topic_text: "",
            question_pts: 1,
            question_type: 201,
            questions: [
              {
                question_text: "",
                options: [
                  {
                    option_index: 0,
                    option_text: "",
                    option_url: "",
                    option_correct: 1,
                  },
                  {
                    option_index: 1,
                    option_text: "",
                    option_url: "",
                    option_correct: 0,
                  },
                  {
                    option_index: 2,
                    option_text: "",
                    option_url: "",
                    option_correct: 0,
                  },
                  {
                    option_index: 3,
                    option_text: "",
                    option_url: "",
                    option_correct: 0,
                  },
                ],
              },
            ],
          },
          questionTips: [
            {
              type: "instruct",
              name: "",
              description: "",
              assetUrl: "",
            },
            {
              type: "instruct",
              name: "",
              description: "",
              assetUrl: "",
            },
          ],
          qandAText: "",
          questionPaperUrl: "",
          answerSheetUrl: "",
        },
        morePractice: {
          description: "",
          hasSteps: true,
          mins: 0,
          steps: [
            {
              title: "",
              hasImages: true,
              hasVideos: false,
              hasDocs: false,
              hasTips: true,
              images: [
                {
                  url: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e?w=164&h=164&fit=crop&auto=format",
                },
                {
                  url: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
                },
                {
                  url: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
                },
              ],
              videos: [],
              docs: [],
              tips: [
                {
                  title: "",
                  description: "",
                  assetUrl: "",
                },
              ],
            },
            {
              title: "",
              hasImages: false,
              hasVideos: false,
              hasDocs: false,
              hasTips: false,
              images: [],
              videos: [],
              docs: [],
              tips: [],
            },
          ],
        },
        homework: {
          description: "",
          hasQandA: true,
          hasAssm: true,
          hasImages: true,
          hasQuestionTips: true,
          qandAText: "",
          questionPaperUrl: "",
          answerSheetUrl: "",
          assessment: {
            questions: [],
          },
          images: [
            {
              img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
              url: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
              title: "Coffee",
            },
            {
              img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
              url: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
              title: "Hats",
            },

            {
              img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
              url: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
              title: "Honey",
            },
          ],
          questionTips: [
            {
              type: "instruct",
              name: "",
              description: "",
              assetUrl: "",
            },
            {
              type: "instruct",
              name: "",
              description: "",
              assetUrl: "",
            },
          ],
        },
      },
      assessment: {
        isChecked: true,
        summary: "Assessment summary",
        hasApproachingText: true,
        hasMeetingText: true,
        hasExceedingText: true,
        hasAssm: true,
        approachingText: "",
        meetingText: "",
        exceedingText: "",
        mins: 0,
        participantCount: 0,
        assessment: {
          classroom_id: 0,
          staff_id: 0,
          staff_lesson_id: 0,
          subject_id: 0,
          subject_sub_id: 0,
          chapter_id: 0,
          quiz_title: "abcd",
          scheduled_date: "2020-12-20",
          quiz_ended: 0,
          question_text: "",
          question_time: "00:00", //+$(event.target.closest('div')).find('input').val(),//$("#timerbool").val(),
          question_url: "",
          question_topic_text: "",
          question_pts: 1,
          question_type: 201,
          questions: [
            {
              question_text: "",
              options: [
                {
                  option_index: 0,
                  option_text: "",
                  option_url: "",
                  option_correct: 1,
                },
                {
                  option_index: 1,
                  option_text: "",
                  option_url: "",
                  option_correct: 0,
                },
                {
                  option_index: 2,
                  option_text: "",
                  option_url: "",
                  option_correct: 0,
                },
                {
                  option_index: 3,
                  option_text: "",
                  option_url: "",
                  option_correct: 0,
                },
              ],
            },
          ],
        },
      },
    },
    CUSTOM_BOARD_ID: 999999,
    SCHOOLER_PUB_ID: 73,
    LESSONPLAN_CRITERIA: "kgtopg.guruculum.lessonplan.learningoutcomecriteria",
    COLOR_PRIMARY: "#5664D2",
    COLOR_SECONDARY: "#EEE6FF",
    COLOR_INFO: "#9C27B0",
    COLOR_LIGHT: "#EEEEEE",
    COLOR_PRIMARY_LIGHT: "#EEE6FF",
  },
};

export default Constants;
