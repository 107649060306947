import { USERAUTH_SIGNIN_REQUEST, USERAUTH_SIGNIN_SUCCESS, USERAUTH_SIGNIN_FAIL } from "../constants/userAuthConstants";

function userSigninReducer(state = { userInfo: [] }, action) {
    switch (action.type) {
        case USERAUTH_SIGNIN_REQUEST:
            return { loadingAuth: true };
        case USERAUTH_SIGNIN_SUCCESS:
            return { loadingAuth: false, userInfo: action.payload };
        case USERAUTH_SIGNIN_FAIL:
            return { loadingAuth: false, errorAuth: action.payload };
        default: return state;
    }
}


export { userSigninReducer }
