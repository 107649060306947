import React, { useEffect, useState } from "react";

import Box2 from "@mui/material/Box";

import List2 from "@mui/material/List";
import ListSubheader2 from "@mui/material/ListSubheader";
import ListItem2 from "@mui/material/ListItem";
import ListItemButton2 from "@mui/material/ListItemButton";
import ListItemIcon2 from "@mui/material/ListItemIcon";
import ListItemText2 from "@mui/material/ListItemText";
import { FixedSizeList2 } from "react-window";
import IconButton2 from "@mui/material/IconButton";
import DoneIcon2 from "@mui/icons-material/Done";

import TemporaryDrawer2 from "./LessonPlanDrawer";

// left bar
function AimResourceList(props) {
  const { planSetup } = props;
  const [open, setOpen] = React.useState(true);

  // verify resources and keywords
  let resourceCount = planSetup.aim.resourceList.length;
  let keywCount = planSetup.aim.keywordList.length;

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List2
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {resourceCount ? (
        <ListItem2
          secondaryAction={
            <IconButton2 edge="end" aria-label="delete">
              <DoneIcon2 />
            </IconButton2>
          }
        >
          <ListItemText2
            primary="Resources"
            secondary={"Count: " + resourceCount}
          />
        </ListItem2>
      ) : (
        <>
          <>
            <ListItem2>
              <ListItemText2
                secondary="No resources added yet!"
                // secondary={'Count: ' + stepCount}
              />
            </ListItem2>
          </>
        </>
      )}

      {keywCount ? (
        <ListItem2
          secondaryAction={
            <IconButton2 edge="end" aria-label="delete">
              <DoneIcon2 />
            </IconButton2>
          }
        >
          <ListItemText2 primary="Keyword" secondary={"Count: " + keywCount} />
        </ListItem2>
      ) : (
        <>
          <>
            <ListItem2>
              <ListItemText2
                secondary="No keywords added yet!"
                // secondary={'Count: ' + stepCount}
              />
            </ListItem2>
          </>
        </>
      )}
    </List2>
  );
}

function ActionResourceList(props) {
  const { planSetup } = props;
  const [open, setOpen] = React.useState(true);

  // verify resources and keywords
  let stepCount = planSetup.action.steps.length;
  //  let keywCount = planSetup.aim.keywordList.length;

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List2
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {planSetup.action.steps && planSetup.action.steps.length ? (
        planSetup.action.steps.map((step, idx) => {
          const imgCount = step.images.length;
          const vidCount = step.videos.length;
          const docCount = step.docs.length;
          const tipsCount = step.tips.length;
          return (
            <ListItem2
              secondaryAction={
                <IconButton2 edge="end" aria-label="delete"></IconButton2>
              }
            >
              <ListItemText2
                primary={"Step " + (idx + 1)}
                secondary={
                  "Images: " +
                  imgCount +
                  ", Videos: " +
                  vidCount +
                  ", Documents: " +
                  docCount +
                  ", Tips: " +
                  tipsCount
                }
              />
            </ListItem2>
          );
        })
      ) : (
        <>
          <ListItem2>
            <ListItemText2
              primary="No steps added yet!"
              // secondary={'Count: ' + stepCount}
            />
          </ListItem2>
        </>
      )}
    </List2>
  );
}

function AnalysisResourceList(props) {
  const { planSetup } = props;
  const [open, setOpen] = React.useState(true);

  let partCount = planSetup.analysis.parts.length;

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List2
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {planSetup.analysis.parts && planSetup.analysis.parts.length ? (
        planSetup.analysis.parts.map((part, idx) => {

          // negate assessment
          if(part.heading === 'assessment_assm')
            return <></>;
          
          const expCount = part.explanation.length;

          return part.isSelected ? (
            <ListItem2
              secondaryAction={
                <IconButton2 edge="end" aria-label="delete"></IconButton2>
              }
            >
              <ListItemText2
                primary={part.heading}
                secondary={"Explanation: " + expCount}
              />
            </ListItem2>
          ) : (
            <></>
          );
        })
      ) : (
        <>
          <ListItem2>
            <ListItemText2
              primary="No parts added yet!"
              // secondary={'Count: ' + stepCount}
            />
          </ListItem2>
        </>
      )}
    </List2>
  );
}

function ApplicationResourceList(props) {
  const { planSetup } = props;
  const [open, setOpen] = React.useState(true);

  const {
    hasClasswork,
    hasMorePractice,
    hasHomework,
    classwork,
    morePractice,
    homework,
  } = planSetup.application;

  const { hasWorkBook } = classwork;
  const { hasQandA } = homework;
  const classworkQandA = classwork.hasQandA;
  const classworkAssm = classwork.hasAssm;
  const homeworkAssm = homework.hasAssm;

  let workBookStr = hasWorkBook
    ? "workbook" +
      (classworkAssm ? " assessment " + (classworkQandA ? " q&a " : "") : "")
    : "";
  let homeworkStr = hasQandA
    ? " q&a " + (homeworkAssm ? " assessment " : "")
    : "";

  workBookStr = hasWorkBook ? "workbook" : "";
  workBookStr = workBookStr.concat(classworkAssm ? " assessment " : "");
  workBookStr = workBookStr.concat(classworkQandA ? " q&a " : "");

  homeworkStr = hasQandA ? " q&a " : "";
  homeworkStr = homeworkStr.concat(homeworkAssm ? " assessment " : "");
  //    homeworkStr = homeworkStr.concat()
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List2
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItem2
        secondaryAction={
          <IconButton2 edge="end" aria-label="delete">
            {hasClasswork && <DoneIcon2 />}
          </IconButton2>
        }
      >
        <ListItemText2 primary="Classwork" secondary={workBookStr} />
      </ListItem2>

      <ListItem2
        secondaryAction={
          <IconButton2 edge="end" aria-label="delete">
            {hasMorePractice && <DoneIcon2 />}
          </IconButton2>
        }
      >
        <ListItemText2
          primary="More Practice"
          // secondary={'analysis part'}
        />
      </ListItem2>

      <ListItem2
        secondaryAction={
          <IconButton2 edge="end" aria-label="delete">
            {hasHomework && <DoneIcon2 />}
          </IconButton2>
        }
      >
        <ListItemText2 primary="Homework" secondary={homeworkStr} />
      </ListItem2>
    </List2>
  );
}

function AssessmentResourceList(props) {
  const { planSetup } = props;
  const [open, setOpen] = React.useState(true);

  const {
    isChecked,
    hasApproachingText,
    hasMeetingText,
    hasExceedingText,
    hasAssm,
  } = planSetup.assessment;

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List2
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItem2
        secondaryAction={
          <IconButton2 edge="end" aria-label="delete">
            {hasApproachingText && <DoneIcon2 />}
          </IconButton2>
        }
      >
        <ListItemText2
          primary="Approaching"
          // secondary={'approaching info'}
        />
      </ListItem2>

      <ListItem2
        secondaryAction={
          <IconButton2 edge="end" aria-label="delete">
            {hasMeetingText && <DoneIcon2 />}
          </IconButton2>
        }
      >
        <ListItemText2
          primary="Meeting"
          // secondary={'meeting info'}
        />
      </ListItem2>

      <ListItem2
        secondaryAction={
          <IconButton2 edge="end" aria-label="delete">
            {hasExceedingText && <DoneIcon2 />}
          </IconButton2>
        }
      >
        <ListItemText2
          primary="Exceeding"
          // secondary={'exceeding info'}
        />
      </ListItem2>

      {/* 
            <ListItem2
                secondaryAction={
                    <IconButton2 edge="end" aria-label="delete">
                        {hasAssm &&
                            <DoneIcon2 />
                        }
                    </IconButton2>
                }
            >
                <ListItemText2
                    primary="Quiz"
                    secondary={'Quiz assessment'}
                />
            </ListItem2> */}

      {/* 
            <ListItem2
                secondaryAction={
                    <IconButton2 edge="end" aria-label="delete">
                        <DoneIcon2 />
                    </IconButton2>
                }
            >
                <ListItemText2
                    primary="Take Test"
                    secondary={'Assessment during the class'}
                />
            </ListItem2> */}
    </List2>
  );
}

const CHECKBOXMENUITEM = (props) => {
  const { name, type, checked, planSetup } = props;
  return (
    <>
      <TemporaryDrawer2
        name={name}
        type={type}
        checked={checked}
        planSetup={planSetup}
        handleToggleResourceCheck={(name, type, checked) =>
          props.handleToggleResourceCheck(name, type, checked)
        }
        onChangeAccordionHeaderStatus={(type, checked) =>
          props.onChangeAccordionHeaderStatus(type, checked)
        }
        onUpdateRightItemSubChecked={(type, name, sub, isChecked2) =>
          props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)
        }
        onUpdatePlanSetup={(newPlanSetup) =>
          props.onUpdatePlanSetup(newPlanSetup)
        }
        scrollToSection={props.scrollToSection}
      />
      {/* <Typography>{name}</Typography> */}
    </>
  );
};

function ACCORDIONHEADER(props) {
  const { name, type, checkedList, planSetup } = props;

  console.log("CurriculumSetup.js ACCORDIONHEADER name " + name);
  console.log("CurriculumSetup.js ACCORDIONHEADER type " + type);
  console.log(
    "CurriculumSetup.js ACCORDIONHEADER checkList " +
      JSON.stringify(checkedList)
  );

  let name2 = name.toLowerCase();
  // apply checked
  let checked = false;
  checkedList.map((itm3, idx3) => {
    const name3 = itm3.name;
    const checked3 = itm3.checked;
    if (type === name3) {
      console.log(
        "CurriculumSetup.js ACCORDIONHEADER match for type " +
          type +
          " and name " +
          name3 +
          " checked is " +
          checked3
      );
      checked = checked3;
    }
  });

  console.log(
    "CurriculumSetup.js ACCORDIONHEADER checked for name " +
      name +
      " is: " +
      checked
  );

  return (
    <>
      <div
        style={{
          width: "99%",
        }}
      >
        {/* <Typography>AIM</Typography> */}
        <CHECKBOXMENUITEM
          name={name}
          type={type}
          checked={checked}
          planSetup={planSetup}
          handleToggleResourceCheck={(name, type, checked) => {
            props.handleToggleResourceCheck(name, type, checked)
          }}
          onChangeAccordionHeaderStatus={(type, checked) =>
            props.onChangeAccordionHeaderStatus(type, checked)
          }
          onUpdateRightItemSubChecked={(type, name, sub, isChecked2) =>
            props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)
          }
          onUpdatePlanSetup={(newPlanSetup) =>
            props.onUpdatePlanSetup(newPlanSetup)
          }
          scrollToSection={props.scrollToSection}
        />
      </div>
    </>
  );
}

// left accordions
function ActivityAccordions(props) {
  const { planSetup } = props;

  console.log(
    "CurriculumSetup.js ActivityAccordions checkedList ",
    props.checkedList
  );
  console.log(
    "CurriculumSetup.js ActivityAccordions planSetup " +
      JSON.stringify(planSetup)
  );
  return (
    <div
      style={{
        height: "100vh",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingLeft: 0,
        }}
      >
        <ACCORDIONHEADER
          name="OUTCOME"
          type="aim"
          checkedList={props.checkedList}
          onChangeAccordionHeaderStatus={(name, checked) =>
            props.onChangeAccordionHeaderStatus(name, checked)
          }
          planSetup={planSetup}
          handleToggleResourceCheck={(name, type, checked) =>
            props.handleToggleResourceCheck(name, type, checked)
          }
          onUpdateRightItemSubChecked={(type, name, sub, isChecked2) =>
            props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)
          }
          onUpdatePlanSetup={(newPlanSetup) =>
            props.onUpdatePlanSetup(newPlanSetup)
          }
          scrollToSection={props.scrollToAim}
        />
      </div>
      <AimResourceList planSetup={planSetup} />

      <div style={{ margin: 10 }}></div>

      {/* ACTION menu */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingLeft: 0,
        }}
      >
        <ACCORDIONHEADER
          name="LEARNING PATH"
          type="action"
          checkedList={props.checkedList}
          onChangeAccordionHeaderStatus={(name, checked) =>
            props.onChangeAccordionHeaderStatus(name, checked)
          }
          planSetup={planSetup}
          handleToggleResourceCheck={(name, type, checked) =>
            props.handleToggleResourceCheck(name, type, checked)
          }
          onUpdateRightItemSubChecked={(type, name, sub, isChecked2) =>
            props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)
          }
          onUpdatePlanSetup={(newPlanSetup) =>
            props.onUpdatePlanSetup(newPlanSetup)
          }
          scrollToSection={props.scrollToAction}
        />
      </div>

      <ActionResourceList planSetup={planSetup} />

      <div style={{ margin: 10 }}></div>

      {/* ANALYSIS menu */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingLeft: 0,
        }}
      >
        <ACCORDIONHEADER
          name="PARTICIPATE"
          type="analysis"
          checkedList={props.checkedList}
          onChangeAccordionHeaderStatus={(name, checked) =>
            props.onChangeAccordionHeaderStatus(name, checked)
          }
          planSetup={planSetup}
          handleToggleResourceCheck={(name, type, checked) =>
            props.handleToggleResourceCheck(name, type, checked)
          }
          onUpdateRightItemSubChecked={(type, name, sub, isChecked2) =>
            props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)
          }
          onUpdatePlanSetup={(newPlanSetup) =>
            props.onUpdatePlanSetup(newPlanSetup)
          }
          scrollToSection={props.scrollToAnalysis}
        />
      </div>

      <AnalysisResourceList planSetup={planSetup} />

      <div style={{ margin: 10 }}></div>

      {/* APPLICATION menu */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingLeft: 0,
        }}
      >
        <ACCORDIONHEADER
          name="EVALUATE"
          type="application"
          checkedList={props.checkedList}
          onChangeAccordionHeaderStatus={(name, checked) =>
            props.onChangeAccordionHeaderStatus(name, checked)
          }
          planSetup={planSetup}
          handleToggleResourceCheck={(name, type, checked) =>
            props.handleToggleResourceCheck(name, type, checked)
          }
          onUpdateRightItemSubChecked={(type, name, sub, isChecked2) =>
            props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)
          }
          onUpdatePlanSetup={(newPlanSetup) =>
            props.onUpdatePlanSetup(newPlanSetup)
          }
          scrollToSection={props.scrollToApplication}
        />
      </div>

      <ApplicationResourceList planSetup={planSetup} />

      <div style={{ margin: 10 }}></div>

      {/* ASSESSMENT menu */}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingLeft: 0,
        }}
      >
        <ACCORDIONHEADER
          name="REFLECTION"
          type="assessment"
          checkedList={props.checkedList}
          onChangeAccordionHeaderStatus={(name, checked) => {
            props.onChangeAccordionHeaderStatus(name, checked)
          }}
          planSetup={planSetup}
          handleToggleResourceCheck={(name, type, checked) =>
            props.handleToggleResourceCheck(name, type, checked)
          }
          onUpdateRightItemSubChecked={(type, name, sub, isChecked2) =>
            props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)
          }
          onUpdatePlanSetup={(newPlanSetup) =>
            props.onUpdatePlanSetup(newPlanSetup)
          }
          scrollToSection={props.scrollToAssessment}
        />
      </div>

      {/* <AssessmentResourceList planSetup={planSetup} /> */}
    </div>
  );
}

function ActivityLeftBar(props) {
  const { planSetup } = props;

  return (
    <div>
      <ActivityAccordions
        checkedList={props.checkedList}
        onChangeAccordionHeaderStatus={(name, checked) =>
          props.onChangeAccordionHeaderStatus(name, checked)
        }
        planSetup={planSetup}
        handleToggleResourceCheck={(name, type, checked) =>
          props.handleToggleResourceCheck(name, type, checked)
        }
        onUpdateRightItemSubChecked={(type, name, sub, isChecked2) =>
          props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)
        }
        onUpdatePlanSetup={(newPlanSetup) =>
          props.onUpdatePlanSetup(newPlanSetup)
        }
        scrollToAim={props.scrollToAim}
        scrollToAction={props.scrollToAction}
        scrollToAnalysis={props.scrollToAnalysis}
        scrollToApplication={props.scrollToApplication}
        scrollToAssessment={props.scrollToAssessment}
      />
    </div>
  );
}

export default ActivityLeftBar;
