/**
 * @project kgtopg - schooler - partner
 * curriculum map data services
 */
import API from "./http";
import axios from "axios";
import qs from "querystring";
import Constants from "../resource/Constants";

const TAG = 'gpt.js';

export default {
  async generate(data) {
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
      };

      let url = Constants.Application.GPT_PEDAGOGY_URL;
      // url += "/curriculum-map/";
      url += "/generate-pedagogy/";

      console.log(TAG, "generate api url", url);

      const options = {
        method: "POST",
        data: data,
        url,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': 'Bearer ' + UserSession.authtoken,
        },
      };

      let response = await axios(options);

      console.log(TAG, "generate api response", response);

      if (response) return response.data;
      else return false;
    } catch (ex) {
      return false;
    }
  },

  async getPlanSetupDetails(planId) {
    // console.log("date",stuId,jsDate)
    // we wait

    /*
    try {
      let url = Constants.Application.PARTNER_END_URL;
      url += `/get-lesson-plan-setup/?lesson_plan_setup_id=${planId}`;

      console.log(`getPlanSetupDetails details view url ${url}`);

      let response = await axios.get(url);
      console.log(
        `getPlanSetupDetails details response ${JSON.stringify(response)}`
      );
      // let response = await API.get(`/schooler/teacher-upcoming-classes-service?staffid=${staffId}`);

      // .then(response => {
      // console.log(`upcoming classes response ${JSON.stringify(response)}`)

      // let result = response.data.data
      // let message = response.data.message

      if (response) return response.data;
      else return false;
    } catch (ex) {
      // console.log(ex);
      // console.log(`exception ${ex.message}`)
      return false;
    }
    */
  },
};
