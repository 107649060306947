import React, { useEffect, useState } from "react";
import { useLocation, useParams, useHistory, Link } from "react-router-dom";

import { emphasize, withStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Chip from "@material-ui/core/Chip";
import Chip2 from "@mui/material/Chip";
import VisibilityIcon2 from '@mui/icons-material/Visibility';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Modal2 from '@mui/material/Modal';

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import DateRangeIcon from "@material-ui/icons/DateRange";
// import HTML5Backend from "react-dnd-html5-backend";
// import { DragDropContext } from "react-dnd";
import BigCalendar from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import { DateRangePickerCalendar, START_DATE } from "react-nice-dates";
import HomeIcon from "@material-ui/icons/Home";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Link2 from "@mui/material/Link";
import TextField2 from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import EditIcon2 from "@mui/icons-material/Edit";

import List2 from "@mui/material/List";
import ListItem2 from "@mui/material/ListItem";
import Divider2 from "@mui/material/Divider";
import ListItemText2 from "@mui/material/ListItemText";
import ListItemIcon2 from "@mui/material/ListItemIcon";
import ListItemAvatar2 from "@mui/material/ListItemAvatar";
import Avatar2 from "@mui/material/Avatar";
import Typography2 from "@mui/material/Typography";

import CardActionArea2 from "@mui/material/CardActionArea";

import Breadcrumbs2 from "@mui/material/Breadcrumbs";
import Switch from "@mui/material/Switch";
import UNITAPI from "../../http/curriculummapunit";
// import Alert3 from '@mui/material/Alert';

// iconv
// import Iconv from 'iconv';
// import Buffer from 'buffer';
// import assert from 'assert';
// var Buffer = require('buffer').Buffer;
// var Iconv  = require('iconv').Iconv;
// var assert = require('assert');
import store from "../../store";

import { updateCurrLessonPlan } from "../../redux/actions/currPlanActions";
import "react-nice-dates/build/style.css";

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Box, Container } from "@material-ui/core";
// import UnitListResults from "./slots/UnitListResults";
import UnitListToolbar from "./slots/UnitListToolbar";
import customers from "../../__mocks__/customers";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { Edit } from "react-feather";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import MapAPI from "../../http/curriculummap";
import MapUnitAPI from "../../http/curriculummapunit";
import { useDispatch, useSelector } from "react-redux";
import { listUnitMapGet, postNewUnit } from "../../redux/actions/unitMapAction";
import { UnitMapReducer } from "../../redux/reducers/unitMapReducer";
import { updateCurrMap } from "../../redux/actions/currMapActions";
import { updateCurrUnit } from "../../redux/actions/currUnitActions";
import { updateCurrLesson } from "../../redux/actions/currLessonActions";
import { updateCurrMapId } from "../../redux/actions/currMapIdActions";

import { getUnitLessons } from "../../redux/actions/unitLessonsActions";
import {
  getLessonPlans,
  postNewLessonPlan,
} from "../../redux/actions/lessonPlansActions";
import Constants from "../../resource/Constants";
import ImportContactsOutlinedIcon from "@material-ui/icons/ImportContactsOutlined";
import LeftCard from "./slots/LeftCard";
import { Alert, AlertTitle } from "@material-ui/lab";
import Slider from "@material-ui/core/Slider";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ColorLensOutlinedIcon from "@material-ui/icons/ColorLensOutlined";
import ColorPicker from "./slots/ColorPicker";

import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import QuizPlannerDrawer from "./slots/QuizPlannerDrawer";
import MediaPlannerDrawer from "./slots/MediaPlannerDrawer";
import {
  listChpGet,
  listChpGet2,
  listChpGet3,
} from "../../redux/actions/chapterAction";
import { listTopGet } from "../../redux/actions/topicAction";
import { truncate } from "lodash";

import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import { SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core";

import Box2 from "@mui/material/Box";
import ListItemButton2 from "@mui/material/ListItemButton";

import { FixedSizeList2 } from "react-window";

import Card2 from "@mui/material/Card";
import CardActions2 from "@mui/material/CardActions";
import CardContent2 from "@mui/material/CardContent";
import CardMedia2 from "@mui/material/CardMedia";
import Button2 from "@mui/material/Button";
import ButtonGroup2 from '@mui/material/ButtonGroup';

/* accordion elements from mui */
import Accordion2 from "@mui/material/Accordion";
import AccordionDetails2 from "@mui/material/AccordionDetails";
import AccordionSummary2 from "@mui/material/AccordionSummary";
import InboxIcon2 from "@mui/icons-material/Inbox";
import DraftsIcon2 from "@mui/icons-material/Drafts";

import ExpandMoreIcon2 from "@mui/icons-material/ExpandMore";

import { styled } from "@mui/material/styles";
import Grid2 from "@mui/material/Grid";
import Paper2 from "@mui/material/Paper";

import AddBoxIcon2 from "@mui/icons-material/AddBox";

import Dialog2 from "@mui/material/Dialog";
import DialogActions2 from "@mui/material/DialogActions";
import DialogContent2 from "@mui/material/DialogContent";
import DialogContentText2 from "@mui/material/DialogContentText";
import DialogTitle2 from "@mui/material/DialogTitle";

import InputLabel2 from "@mui/material/InputLabel";
import MenuItem2 from "@mui/material/MenuItem";
import FormControl2 from "@mui/material/FormControl";
import Select2 from "@mui/material/Select";

import Alert2 from "@mui/material/Alert";
import IconButton2 from "@mui/material/IconButton";
import CloseIcon2 from "@mui/icons-material/Close";

import Radio2 from "@mui/material/Radio";
import RadioGroup2 from "@mui/material/RadioGroup";
import FormControlLabel2 from "@mui/material/FormControlLabel";

import FormLabel2 from "@mui/material/FormLabel";

import LessonPlanSetupAPI from "../../http/lessonplansetup";

// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import {
  filterMaps,
  listCurriculuFilterGet,
  listCurriculumGet,
  postNewCurriculum,
} from "../../redux/actions/curriculumMapAction";
import { listSubSubGet } from "../../redux/actions/subSubjectsAction";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Cookie from "js-cookie";
import UserSession from "../../resource/UserSession";
// import MultiStep from 'react-multistep';
// import MasterForm from "./slots/MasterForm";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import Drawer2 from "@mui/material/Drawer";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


// add gpt plan dialog
import LessonPlanAddGPTCard from './slots/AddLessonPlanGPT';
import { listSubTopGet, listChapSubTopGet } from "../../redux/actions/SubtopicAction";
import CurriculumMapper from "./slots/CurriculumMapper";
import AddUnitDialog from "./slots/AddUnitDialog";
import EditUnitDialog from "./slots/EditUnitDialog";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const swLabel = { inputProps: { 'aria-label': 'Switch demo' } };

const TAG = "UnitList.js";
const userId3 = parseInt(Cookie.get('kgtopg.guruculum.user.id'));

const iconv = require("iconv-lite");
const Item = styled(Paper2)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const style3 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const drawerWidth2 = 440;

/* circle item */
const shapeStyles = { bgcolor: "primary.main", width: 40, height: 40 };
const shapeCircleStyles = { borderRadius: "50%" };
const rectangle = <Box component="span" sx={shapeStyles} />;
const circle = (
  <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles }}>
    <span>1</span>
  </Box>
);

const StepOne = () => {
  return (
    <>
      <span>Step One</span>
    </>
  );
};

const StepTwo = () => {
  return (
    <>
      <span>Step Two</span>
    </>
  );
};

const StepThree = () => {
  return (
    <>
      <span>Step Three</span>
    </>
  );
};

const StepFour = () => {
  return (
    <>
      <span>Step Four</span>
    </>
  );
};

const steps = [
  { name: "StepOne", component: <StepOne /> },
  { name: "StepTwo", component: <StepTwo /> },
  { name: "StepThree", component: <StepThree /> },
  { name: "StepFour", component: <StepFour /> },
];

function Alert3(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const localizer = momentLocalizer(moment);

const myEventsList = [
  {
    id: 0,
    title: "Unit 1: Foundations of number",
    allDay: true,
    start: new Date(2021, 3, 0),
    end: new Date(2021, 3, 1),
  },
  {
    id: 1,
    title: "Unit 2: Additions and Subtractions",
    start: new Date(2021, 3, 7),
    end: new Date(2021, 3, 10),
  },

  {
    id: 2,
    title: "Unit 3: DTS STARTS",
    start: new Date(2021, 4, 13, 0, 0, 0),
    end: new Date(2021, 4, 20, 0, 0, 0),
  },

  {
    id: 3,
    title: "Unit 4: DTS ENDS",
    start: new Date(2020, 10, 6, 0, 0, 0),
    end: new Date(2020, 10, 13, 0, 0, 0),
  },

  {
    id: 4,
    title: "Unit 5: Some Event",
    start: new Date(2021, 3, 9, 0, 0, 0),
    end: new Date(2021, 3, 9, 0, 0, 0),
  },
  {
    id: 5,
    title: "Unit 6: Conference",
    start: new Date(2021, 3, 11),
    end: new Date(2021, 3, 13),
    desc: "Big conference for important people",
  },
  {
    id: 6,
    title: "Unit 7: Meeting",
    start: new Date(2021, 3, 12, 10, 30, 0, 0),
    end: new Date(2021, 3, 12, 12, 30, 0, 0),
    desc: "Pre-meeting meeting, to prepare for the meeting",
  },
  {
    id: 7,
    title: "Unit 8: Lunch",
    start: new Date(2021, 3, 12, 12, 0, 0, 0),
    end: new Date(2021, 3, 12, 13, 0, 0, 0),
    desc: "Power lunch",
  },
  {
    id: 8,
    title: "Unit 9: Meeting",
    start: new Date(2021, 3, 12, 14, 0, 0, 0),
    end: new Date(2021, 3, 12, 15, 0, 0, 0),
  },
  {
    id: 9,
    title: "Unit 10: Happy Hour",
    start: new Date(2021, 3, 12, 17, 0, 0, 0),
    end: new Date(2021, 3, 12, 17, 30, 0, 0),
    desc: "Most important meal of the day",
  },
  {
    id: 10,
    title: "Unit 11: Dinner",
    start: new Date(2021, 3, 12, 20, 0, 0, 0),
    end: new Date(2021, 3, 12, 21, 0, 0, 0),
  },
  {
    id: 11,
    title: "Unit 12: Birthday Party",
    start: new Date(2021, 3, 13, 7, 0, 0),
    end: new Date(2021, 3, 13, 10, 30, 0),
  },
  {
    id: 12,
    title: "Unit 13: Late Night Event",
    start: new Date(2021, 3, 17, 19, 30, 0),
    end: new Date(2021, 3, 18, 2, 0, 0),
  },
  {
    id: 13,
    title: "Unit 14: Multi-day Event",
    start: new Date(2021, 3, 20, 19, 30, 0),
    end: new Date(2021, 3, 22, 2, 0, 0),
  },
  {
    id: 14,
    title: "Unit 15: Today",
    start: new Date(new Date().setHours(new Date().getHours() - 3)),
    end: new Date(new Date().setHours(new Date().getHours() + 3)),
  },
];

const drawerWidth = 250;

const useStyles1 = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbar1: {
    paddingRight: 24,
    height: 40,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbarIcon2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbarIcon1: {
    top: "45vh",
    bottom: 0,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    // marginLeft:70,
    backgroundColor: "#1976d2",
    color: "#ffffff",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: "blue",
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    fontSize: 14,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: "#ffffff",
    scrollbarColor: "green",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    display: "flex",
    justifyContent: "center",
  },
  container: {
    marginTop: 20,
    paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(4),
    justifyContent: "center",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  large: {
    width: "100%",
    height: 50,
    borderRadius: 0,
  },
}));

const useStyles2 = makeStyles((theme) => ({
  root: {
    display: "flex",
    // justifyContent: 'center',
    flexWrap: "wrap",
    "& > *": {
      // margin: theme.spacing(0.5),
    },
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  shape: {
    backgroundColor: theme.palette.primary.main,
    width: 20,
    height: 20,
  },
  shape2: {
    backgroundColor: theme.palette.secondary.dark,
    width: 20,
    height: 20,
  },
  shape3: {
    backgroundColor: theme.palette.primary.main,
    width: 5,
    height: 20,
  },
  shape4: {
    backgroundColor: theme.palette.secondary.dark,
    width: 5,
    height: 20,
  },
  shapeCircle: {
    borderRadius: "50%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const useStyles3 = makeStyles({
  list: {
    width: drawerWidth2,
  },
  fullList: {
    width: "auto",
  },
});

const useStyles4 = makeStyles({
  header: {
    // backgroundImage: `url(${background})`,
    // height: '100vh',
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  content: {
    backgroundColor: "rgba(211, 211, 211, 0.4)",
    // color: 'white'
  },
});

const config = {
  apiKey: Constants.Application.FIREBASE.API_KEY,
  authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
  //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
  storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET,
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}

let lessonsFirstTimeLoad = false;
let unitsFirstTimeLoad = false;

/* NEW LESSONS UI */

function BasicCard(props) {
  const { selected, title, lessons, days, index, id, unit, lessonsData, cover } = props;
  const classes4 = useStyles4();
  const unitLessons = useSelector((state) => state.unitLessons.unitLessons);
  const dispatch = useDispatch();

  let cover2 =
    "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/fa/6926005ea411e490ff8d4c5d4ff426/chemistry_logo.png?auto=format%2Ccompress&dpr=1";
  if (cover) cover2 = cover;

  console.log("UnitList.js BasicCard: cover " + cover);
  console.log("UnitList.js BasicCard: cover2 " + cover2);

  const onEditClicked = (e) => {
    props.handleClickOpen(e, unit);
    dispatch(updateCurrUnit(unit));
    // make main page edit flag true
    props.setIsEdit(true);
  };

  const currMap = useSelector((state) => state.currMap.currMap);
  let bgColor = "#FFCCF9";
  let color = '#333333';
  const subjectId = currMap.subject_id;

  let days2 = "-";
  let lessons2 = "-";
  switch (subjectId) {
    case 128:
      bgColor = "#97A2FF";
      break;
    case 108:
      bgColor = "#ACE7FF";
      break;
    case 112:
      bgColor = "#FFFFD1";
      break;
    case 114:
      bgColor = "#FFC9DE";
      break;
    case 155:
      bgColor = "#DCD3FF";
      break;
    case 175:
      bgColor = "#C4FAF8";
      break;
  }

  bgColor = '#F5F5F5';

  if (selected) {
    bgColor = '#EEE6FF';
    color = '#F5F5F5';
  }

  return (
    <Box2
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      style={{
        marginBottom: 5,
      }}
    >
      <CardActionArea2>
        <Card2
          sx={{ minWidth: 275 }}
          onClick={() => {
            console.log("UnitList.js BasicCard: onClick called in Card2");
            props.handleChange(id, title);

            props.onUpdateDefPanel("");
          }}
          className={classes4.header}
          style={{
            // backgroundImage: 'url("' + cover2 + '")',
            // backgroundPosition: 'center',
            // backgroundSize: 'cover'
            backgroundColor: bgColor,
          }}
        >
          <CardContent2 className={classes4.content}>
            <Typography2 sx={{ fontSize: 14 }} color="text.white" gutterBottom>
              UNIT - {index}
            </Typography2>
            <Typography2 variant="body1" component="div">
              {title}
            </Typography2>
            {/* <Typography2 sx={{ mb: 1.5 }} color="text.secondary">
          
        </Typography2> */}
            <Typography2 variant="caption">
              {/* {days2} Days - {lessons2} Lessons */}
              {/* {lessons2} Lessons */}
            </Typography2>

            {/* edit icon */}
            <div
              style={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
            >
              {/* <Button size="small" variant="contained" color="gray" onClick={handleClickOpen} startIcon={<EditIcon />}>
                Edit Unit
              </Button> */}

              <EditIcon2 onClick={(e) => onEditClicked(e)} />
            </div>
          </CardContent2>
          {/* <CardActions2>
        <Button2 size="small"> More</Button2>
      </CardActions2> */}
        </Card2>
      </CardActionArea2>
    </Box2>
  );
}

function LessonPlans(props) {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const { currMap, instId, boardId, clsStd, subId, lsn, plans } = props;
  const [openComm, setOpenComm] = React.useState(false);

  // const currLesson = useSelector(state => state.currLesson.currLesson)
  console.log("UnitList.js LessonPlans: currLesson ", JSON.stringify(lsn));

  const topDetailsGet = useSelector((state) => state.topDetails);
  const SubtopDetailsGet = useSelector((state) => state.subTopDetails);
  const currLsnPlan = useSelector((state) => state.currPlan);

  const lsnPlanName =
    currLsnPlan && currLsnPlan.currPlan ? currLsnPlan.currPlan.name : "";
  const lsnPlanDesc =
    currLsnPlan && currLsnPlan.currPlan
      ? currLsnPlan.currPlan.plan_description
      : "";
  const tpcId =
    currLsnPlan && currLsnPlan.currPlan ? currLsnPlan.currPlan.topic_id : 0;

  const [lsnPlanName2, setLsnPlanName2] = useState(lsnPlanName);
  const [lsnPlanDesc2, setLsnPlanDesc2] = useState(lsnPlanDesc);
  const [tpcId2, setTpcId2] = useState(tpcId);
  const [currLsnPlan2, setCurrLsnPlan2] = useState(currLsnPlan);
  console.log(
    "UnitList.js LessonPlans: currLsnPlan " + JSON.stringify(currLsnPlan)
  );
  console.log(
    "UnitList.js LessonPlans: currLsnPlan2 " + JSON.stringify(currLsnPlan2)
  );

  let id2 = currLsnPlan2 ? currLsnPlan2.lesson_plan_setup_id : 0;
  const name2 = currLsnPlan2 ? currLsnPlan2.name : "";
  const desc2 = currLsnPlan2 ? currLsnPlan2.plan_description : "";
  const topic2 = currLsnPlan2 ? currLsnPlan2.topic_id : 0;
  const subTopic2 = currLsnPlan2 ? currLsnPlan2.sub_topic_id : 0;
  const cover3 = currLsnPlan2 ? currLsnPlan2.cover_image_url : "";

  const [id, setId] = useState(
    currLsnPlan2 ? currLsnPlan2.lesson_plan_setup_id : 0
  );
  const [name, setName] = useState(currLsnPlan2 ? currLsnPlan2.name : "");
  const [desc, setDesc] = useState(
    currLsnPlan2 ? currLsnPlan2.plan_description : ""
  );
  const [topic, setTopic] = useState(currLsnPlan2 ? currLsnPlan2.topic_id : 0);
  const [subTopic, setSubTopic] = useState(currLsnPlan2 ? currLsnPlan2.sub_topic_id : 0);
  const [chapter, setChapter] = useState(10);

  const [planType, setPlanType] = useState(currLsnPlan2.is_learning);
  const [dur, setDur] = useState("");
  const [err, setErr] = useState(
    "Please verify the form details before submitting..!"
  );
  const [mediaType, setMediaType] = useState("self");
  const [videos, setVideos] = useState(null);
  const cover_image =
    currLsnPlan && currLsnPlan.currPlan
      ? currLsnPlan.currPlan.cover_image
      : "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/fa/6926005ea411e490ff8d4c5d4ff426/chemistry_logo.png?auto=format%2Ccompress&dpr=1";

  console.log("UnitList.js LessonPlans widget plans " + JSON.stringify(plans));
  const plans2 =
    plans && plans.data && plans.data.data ? plans.data.data : null;

  const handleMediaTypeChanged = (val) => {
    setMediaType(val);
  };

  const dispatch = useDispatch();
  const history = useHistory();

  // ONLY STORE DATA WHETHER API CALLED OR NOT
  const { loadingTop, TopDetails, errorTop } = topDetailsGet;
  const { loadingSubtop, SubtopDetails, errorSubtop } = SubtopDetailsGet;
  console.log(`topicdetails ${JSON.stringify(TopDetails)}`);
  console.log(`subtopicdetails ${JSON.stringify(SubtopDetails)}`);

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const handleSuccessSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    const chpId = lsn.chapter_id;

    dispatch(listTopGet(chpId));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleCloseComm = () => {
    setOpenComm(false);
  };

  const handleChangeChapter = (event) => {
    setChapter(event.target.value);
  };

  const handleChangeDur = (event) => {
    let val = event.target.value;
    console.log("UnitList.js AddPlanFormDialog handleChangeDur val ", val);
    setDur(val);
  };

  const handleChangeTopic = (event) => {
    setTopic(event.target.value);
    let currLsnPlan3 = currLsnPlan2;
    const tpcId = parseInt(event.target.value);
    currLsnPlan3.topic_id = tpcId;
    dispatch(updateCurrLessonPlan(currLsnPlan3));

    // get sub topics
    dispatch(listSubTopGet(tpcId));
    // state
    setCurrLsnPlan2(currLsnPlan3);
  };

  const handleChangeSubTopic = (event) => {
    setSubTopic(event.target.value);
    let currLsnPlan3 = currLsnPlan2;
    currLsnPlan3.sub_topic_id = event.target.value;
    dispatch(updateCurrLessonPlan(currLsnPlan3));
    // state
    setCurrLsnPlan2(currLsnPlan3);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);

    let currLsnPlan3 = currLsnPlan2;
    currLsnPlan3.name = event.target.value;
    dispatch(updateCurrLessonPlan(currLsnPlan3));
    // state
    setCurrLsnPlan2(currLsnPlan3);
  };

  const handleChangeDesc = (event) => {
    setDesc(event.target.value);
    let currLsnPlan3 = currLsnPlan2;
    currLsnPlan3.plan_description = event.target.value;
    dispatch(updateCurrLessonPlan(currLsnPlan3));
    // state
    setCurrLsnPlan2(currLsnPlan3);
  };


  const onPlanTypeChanged = (e) => {
    const val = e.target.value;
    console.log(TAG, 'LessonPlans onPlanTypeChanged:', val);
    setPlanType(val);
  }


  const handleSave = async () => {
    const lessonId = lsn.id;

    let dur2 = 0;
    if (dur) {
      console.log("UnitList.js handleSave: dur is " + dur);

      // dur is date
      // let min = dur.getMinutes();
      // let sec = dur.getSeconds();

      //dur2 = parseInt(dur.split(':')[0])
      dur2 = dur; //+':'+sec;
    }

    dur2 = 0;

    const body = {
      lesson_plan_setup_id: id2,
      lesson_id: lessonId,
      duration_min: dur2,
      topic_id: topic2,
      sub_topic_id: subTopic2,
      name: name2,
      plan_description: desc2,
      multimedia_self: mediaType === "self" ? 1 : 0,
      is_learning: planType,
      cover_image_url: cover3,
      created_user_id: UserSession.userId,
      modified_user_id: UserSession.userId,
    };

    console.log(
      "UnitList.js handleLessonPlanSetupSave body ",
      JSON.stringify(body)
    );

    if (!id2 || !lessonId || !topic2 || !name2) {
      // || !dur) {

      setSnackOpen(true);
      return;
    }

    // call save api
    const resp = await LessonPlanSetupAPI.putUpdatePlanSetup(body);
    console.log(
      "UnitList.js AddPlanFormDialog handleSave: response " +
      JSON.stringify(resp)
    );

    if (resp && resp.data && resp.data.msg && resp.data.msg === "updated") {
      // close dialogs
      handleClose();
      handleClose2();

      // let planId = 0;
      // const planSetup = resp.data.plan;
      // planId = planSetup.lesson_plan_setup_id;

      // just open snackbar
      setSuccessSnackOpen(true);
    } else if (
      resp &&
      resp.data &&
      resp.data.msg &&
      resp.data.msg === "exists"
    ) {
      setErr("A lesson plan already exists with same name!");
      setSnackOpen(true);
    } else {
      setErr("There was a problem saving lesson plan!");
      setSnackOpen(true);
    }
  };

  function ControlledRadioButtonsGroup(props) {
    const [value, setValue] = React.useState("guruculum");

    const handleChange = async (event) => {
      const val = event.target.value;
      setValue(val);

      // see if we opened guruculum option, then close the second modal
      // if (val !== 'self')
      // setOpen2(false)

      // props.handleMediaTypeChanged(event.target.value)
      setMediaType(val);

      // load the videos
      /*
      if (val == 'guruculum') {
        const resp = await MapAPI.getWizenozeVideos();
        console.log('UnitList.js ControlledRadioButtonsGroup handleChange: response ' + JSON.stringify(resp))

        let videos = [];
        props.setVideos(videos);
      }
      */
    };

    const onLoadContentByProv = async () => {
      let provId = 87;
      const resp = await MapAPI.getContByContProvTopic(provId, topic);
      console.log(
        "UnitList.js ControlledRadioButtonsGroup onLoadContentByProv: " +
        JSON.stringify(resp)
      );
    };

    return (
      <FormControl2>
        <FormLabel2 id="demo-controlled-radio-buttons-group">
          Type of media
        </FormLabel2>
        <RadioGroup2
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={mediaType}
          onChange={handleChange}
        >
          <FormControlLabel2
            value="guruculum"
            control={
              <Radio2
                onClick={() => {
                  // onLoadContentByProv()
                }}
              />
            }
            label="Third Party Library"
          />
          <FormControlLabel2
            value="self"
            control={<Radio2 />}
            label="Own Multimedia"
          />
        </RadioGroup2>
      </FormControl2>
    );
  }

  const handleOpenEditLessonPlan = (plan) => {
    console.log(
      "UnitList.js handleOpenEditLessonPlan: plan " + JSON.stringify(plan)
    );
    setCurrLsnPlan2(plan);
    setOpen(true);
  };

  function LessonComments() {

    const comments = (currLsnPlan && currLsnPlan.currPlan) ? currLsnPlan.currPlan.publisher_comments : '';

    console.log(TAG, 'LessonComments currLsnPlan: ' + JSON.stringify(currLsnPlan));
    console.log(TAG, 'LessonComments comments: ', comments);
    return (
      <div>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
          Open alert dialog
        </Button> */}
        <Dialog
          open={openComm}
          onClose={handleCloseComm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={'md'}
        >
          <DialogTitle id="alert-dialog-title">
            {"Comments by the publisher"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {comments}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseComm}>OK</Button>
            {/* <Button onClick={handleClose} autoFocus>
              Agree
            </Button> */}
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  useEffect(() => {
    console.log("UnitList.js LessonPlans useEffect(): called..");
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {plans2 && plans2.length ? (
          <>
            {plans2.map((plan, idx) => {
              return (
                <Grid item xs={6} md={4}>
                  <LessonPlanCard
                    lsn={lsn}
                    plan={plan}
                    handleOpenEditLessonPlan={() =>
                      handleOpenEditLessonPlan(plan)
                    }
                    handleUpdateLessonPlan={(plan) =>
                      handleOpenEditLessonPlan(plan)
                    }

                    handleUpdateTopic={(topicId) => {
                      console.log(TAG, 'handleUpdateTopic called with topicId ', topicId);
                      setTopic(topicId);
                      // fetch subtopics
                      dispatch(listSubTopGet(topicId));
                    }}

                    handleUpdateSubTopic={(subTopicId) => {
                      console.log(TAG, 'handleUpdateSubTopic called with subTopicId ', subTopicId);
                      setSubTopic(subTopicId);
                    }}

                    setOpenComm={(bool) => setOpenComm(bool)}
                  />
                </Grid>
              );
            })}

            {/* EDIT PLAN DIALOG */}
            <Dialog
              open={open}
              onClose={handleClose}
              fullWidth={true}
              maxWidth={"sm"}
            >
              <DialogTitle>Edit plan </DialogTitle>
              <DialogContent>
                <DialogContentText>Edit plan</DialogContentText>

                <>
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Plan Name"
                        value={name2}
                        defaultValue={name2}
                        placeholder="Enter here"
                        type="text"
                        fullWidth
                        variant="outlined"
                        style={{
                          width: "99%",
                        }}
                        size="large"
                        onChange={handleChangeName}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <TextField
                        autoFocus
                        margin="dense"
                        id="desc"
                        label="Learning outcome"
                        placeholder="Enter here"
                        value={desc2}
                        defaultValue={desc2}
                        type="text"
                        fullWidth
                        variant="outlined"
                        style={{
                          width: "99%",
                        }}
                        size="large"
                        onChange={handleChangeDesc}
                      />
                    </div>

                    <div
                      style={{
                        margin: 20,
                      }}
                    ></div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "99%",
                        }}
                      >
                        <FormControl2 fullWidth>
                          <InputLabel2 id="demo-simple-select-label">
                            Topic
                          </InputLabel2>
                          <Select2
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={topic2}
                            defaultValue={topic2}
                            // value={tpcId}
                            // onChange={handleTpcChange}
                            label="Topic"
                            size="small"
                            onChange={handleChangeTopic}
                          >
                            <MenuItem2 value={0}>Select Topic</MenuItem2>

                            {loadingTop && topDetailsGet ? (
                              console.log("loading trueeee")
                            ) : TopDetails.data &&
                              TopDetails.data.length != 0 ? (
                              TopDetails.data.map((list, index) => {
                                let selected = false;

                                if (topic == list.topic_id) selected = true;

                                return (
                                  <MenuItem2
                                    selected={selected}
                                    key={index}
                                    value={list.topic_id}
                                    style={{
                                      fontSize: 13,
                                      color: "#464E5F",
                                      fontFamily:
                                        "Poppins, Helvetica, sans-serif",
                                    }}
                                  >
                                    {list.topic_title}
                                  </MenuItem2>
                                );
                              })
                            ) : (
                              <MenuItem2
                                style={{
                                  fontSize: 13,
                                  color: "#464E5F",
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                              >
                                Chapter not selected
                              </MenuItem2>
                            )}
                          </Select2>
                        </FormControl2>
                      </div>
                    </div>




                    <div
                      style={{
                        margin: 20,
                      }}
                    ></div>


                    {/* SUB TOPIC */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "99%",
                        }}
                      >
                        <FormControl2 fullWidth>
                          <InputLabel2 id="demo-simple-select-label">
                            Sub Topic
                          </InputLabel2>
                          <Select2
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={subTopic}
                            // value={tpcId}
                            // onChange={handleTpcChange}
                            label="Sub Topic"
                            size="small"
                            onChange={handleChangeSubTopic}
                          >
                            <MenuItem2 value={0}>Select Sub Topic</MenuItem2>

                            {loadingSubtop && SubtopDetailsGet ? (
                              console.log("loading trueeee")
                            ) : SubtopDetails.data && SubtopDetails.data.sub_topics && SubtopDetails.data.sub_topics.length != 0 ? (
                              SubtopDetails.data.sub_topics.map((list, index) => {
                                let selected = false;

                                if (subTopic == list.topic_sub_id) selected = true;

                                return (
                                  <MenuItem2
                                    selected={selected}
                                    key={index}
                                    value={list.topic_sub_id}
                                    style={{
                                      fontSize: 13,
                                      color: "#464E5F",
                                      fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                  >
                                    {list.topic_sub_name}
                                  </MenuItem2>
                                );
                              })
                            ) : (
                              <MenuItem2
                                style={{
                                  fontSize: 13,
                                  color: "#464E5F",
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                              >
                                Topic not selected
                              </MenuItem2>
                            )}
                          </Select2>
                        </FormControl2>
                      </div>
                    </div>



                    {/* plan type */}
                    <div
                      style={{
                        marginTop: 10
                      }}>


                      {/* Choose teaching or learning */}

                      <FormControl2>
                        <FormLabel2 id="demo-row-radio-buttons-group-label">Is this for Teacher or Student?</FormLabel2>
                        <RadioGroup2
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={planType}
                          onChange={(e) => {
                            onPlanTypeChanged(e);
                          }}
                        >
                          <FormControlLabel2 value={0} control={<Radio2 />} label="Teaching (For Teacher)" />
                          {/* <FormControlLabel2 value={1} control={<Radio2 />} label="Learning (For Student)" /> */}
                          {/* <FormControlLabel2 value="other" control={<Radio2 />} label="Other" />
                    <FormControlLabel2
                      value="disabled"
                      disabled
                      control={<Radio2 />}
                      label="other"
                    /> */}
                        </RadioGroup2>
                      </FormControl2>
                    </div>
                  </>
                </>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                {/* <Button onClick={() => { onNextClicked() }}>NEXT</Button> */}
                <Button onClick={() => handleSave(true)}>SAVE</Button>
              </DialogActions>
            </Dialog>

            {/* choose provider */}
            <Dialog
              open={open2}
              onClose={handleClose2}
              fullWidth={true}
              maxWidth={"sm"}
            >
              <DialogTitle>Choose the media type </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Choose what type of media is available to teacher
                </DialogContentText>

                <>
                  <ControlledRadioButtonsGroup
                    handleMediaTypeChanged={(type) =>
                      handleMediaTypeChanged(type)
                    }
                    setVideos={(videos) => setVideos(videos)}
                  />
                </>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    handleClose2();
                    setOpen(true);
                    setMediaType("self");
                  }}
                >
                  Cancel
                </Button>
                <Button onClick={() => handleSave(true)}>SAVE</Button>
              </DialogActions>
            </Dialog>

            {/* <MediaDrawer /> */}

            {/* message snack */}
            <Snackbar
              open={snackOpen}
              autoHideDuration={6000}
              onClose={handleSnackClose}
            >
              <Alert
                onClose={handleSnackClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {err}
              </Alert>
            </Snackbar>
            <Snackbar
              open={successSnackOpen}
              autoHideDuration={6000}
              onClose={handleSuccessSnackClose}
            >
              <Alert
                onClose={handleSuccessSnackClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                Plan Setup details updated successfully.
              </Alert>
            </Snackbar>
          </>
        ) : (
          <>
            <div
              style={{
                width: "100%",
                margin: 10,
              }}
            >
              <Alert severity="warning">No plans setup yet! — add now!</Alert>
            </div>
          </>
        )}

        <Grid item xs={6} md={4}>
          <LessonPlanAddCard lsn={lsn} />
        </Grid>
        {/* 
        <Grid item xs={6} md={4}>
          <LessonPlanAddGPTCard lsn={lsn} />
        </Grid> */}
      </Grid>

      <LessonComments />
    </Box>
  );
}

function LessonPlans1() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <LessonPlanCard />
      <div style={{ marginRight: 5 }}>
        <Chip label="20 min" />
      </div>
      <LessonPlanCard />
      <LessonPlanCard />
    </div>
  );
}

function AddPlanFormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const { currMap, instId, boardId, clsStd, subId, lsn, unitId5, lsnId5, chpId5 } = props;
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [topic, setTopic] = useState(0);
  const [subTopic, setSubTopic] = useState(0);
  const [chapter, setChapter] = useState(0);
  const [dur, setDur] = useState("");
  const [err, setErr] = useState(
    "Please verify the form details before submitting..!"
  );
  const [mediaType, setMediaType] = useState("self");
  const [planType, setPlanType] = useState(0); // teach by default
  const [videos, setVideos] = useState(null);
  const cover_image =
    // "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/fa/6926005ea411e490ff8d4c5d4ff426/chemistry_logo.png?auto=format%2Ccompress&dpr=1";
    "https://media.istockphoto.com/id/1248593714/vector/flat-vector-illustration-teamwork-on-finding-new-ideas-little-people-launch-a-mechanism.jpg?s=612x612&w=is&k=20&c=k5GIFPNQBK-nk_PZteruzfSFXkP0MTe9njdUJ08uoWk=";

  const handleMediaTypeChanged = (val) => {
    setMediaType(val);
  };

  const dispatch = useDispatch();
  const history = useHistory();

  // const currLesson = useSelector(state => state.currLesson.currLesson)
  // console.log("UnitList.js handleSave: currLesson ", JSON.stringify(lsn));

  const topDetailsGet = useSelector((state) => state.topDetails);
  const SubtopDetailsGet = useSelector((state) => state.subTopDetails);
  const { loadingTop, TopDetails, errorTop } = topDetailsGet;
  const { loadingSubtop, SubtopDetails, errorSubtop } = SubtopDetailsGet;
  console.log(`topicdetails ${JSON.stringify(TopDetails)}`);
  console.log(`subtopicdetails ${JSON.stringify(SubtopDetails)}`);

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const handleSuccessSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackOpen(false);
  };

  const onPlanTypeChanged = (e) => {
    const val = e.target.value;
    console.log(TAG, 'onPlanTypeChanged', val);
    setPlanType(val);
  }

  const handleClickOpen = () => {

    console.log(TAG, 'AddPlanFormDialog handleClickOpen() called..')
    setOpen(true);
    const chpId = lsn.chapter_id;

    dispatch(listTopGet(chpId));
    // dispatch(listChapSubTopGet(chpId));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleChangeChapter = (event) => {
    setChapter(event.target.value);
  };

  const handleChangeDur = (event) => {
    let val = event.target.value;
    console.log("UnitList.js AddPlanFormDialog handleChangeDur val ", val);
    setDur(val);
  };

  const handleChangeTopic = (event) => {
    let tpcId = parseInt(event.target.value);
    setTopic(tpcId);

    dispatch(listSubTopGet(tpcId));
  };

  const handleChangeSubTopic = (event) => {
    setSubTopic(event.target.value);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeDesc = (event) => {
    setDesc(event.target.value);
  };

  const handleSave = async () => {
    const lessonId = lsn.id;

    let dur2 = 0;
    if (dur) {
      console.log("UnitList.js handleSave: dur is " + dur);

      // dur is date
      // let min = dur.getMinutes();
      // let sec = dur.getSeconds();

      //dur2 = parseInt(dur.split(':')[0])
      dur2 = dur; //+':'+sec;
    }

    dur2 = 0;

    const body = {
      lesson_id: lessonId,
      duration_min: dur2,
      topic_id: topic,
      sub_topic_id: subTopic,
      name: name,
      plan_description: desc,
      multimedia_self: mediaType === "self" ? 1 : 0,
      is_learning: planType, // teach or learn
      cover_image_url: cover_image,
      created_user_id: userId3,
      modified_user_id: userId3
    };

    console.log(
      "UnitList.js handleLessonPlanSetupSave body ",
      JSON.stringify(body)
    );

    if (!lessonId || !topic || !name) {
      // || !dur) {

      setSnackOpen(true);
      return;
    }

    // call save api
    const resp = await LessonPlanSetupAPI.postSavePlanSetup(body);
    console.log(
      "UnitList.js AddPlanFormDialog handleSave: response " +
      JSON.stringify(resp)
    );

    if (resp && resp.data && resp.data.msg && resp.data.msg === "saved") {
      // close dialogs
      handleClose();
      handleClose2();

      let planId = 0;
      const planSetup = resp.data.plan;
      planId = planSetup.lesson_plan_setup_id;

      // just open snackbar
      setSuccessSnackOpen(true);
      setTimeout(function () {
        // take him to next page
        // window.location.assign(`/publisher-ebook/curriculum-map/set-up/${planId}/?map_id=1&inst_id=${instId}&board_id=${boardId}&class_std=${clsStd}&sub_id=${subId}`)

        if (planType) { // is-learn => true
          history.push(
            `/publisher-ebook/curriculum-map/set-up-learn/${planId}/?map_id=${currMap.id}&inst_id=${instId}&board_id=${boardId}&class_std=${clsStd}&sub_id=${subId}&unit_ud=${unitId5}&lesson_id=${lsnId5}&chap_id=${chpId5}`
          );
        } else {
          history.push(
            `/publisher-ebook/curriculum-map/set-up/${planId}/?map_id=${currMap.id}&inst_id=${instId}&board_id=${boardId}&class_std=${clsStd}&sub_id=${subId}&unit_ud=${unitId5}&lesson_id=${lsnId5}&chap_id=${chpId5}`
          );
        }
      }, 1000);
    } else if (
      resp &&
      resp.data &&
      resp.data.msg &&
      resp.data.msg === "exists"
    ) {
      setErr("A lesson plan already exists with same name!");
      setSnackOpen(true);
    } else {
      setErr("There was a problem saving lesson plan!");
      setSnackOpen(true);
    }
  };

  function ControlledRadioButtonsGroup(props) {
    const [value, setValue] = React.useState("guruculum");

    const handleChange = async (event) => {
      const val = event.target.value;
      setValue(val);

      // see if we opened guruculum option, then close the second modal
      // if (val !== 'self')
      // setOpen2(false)

      // props.handleMediaTypeChanged(event.target.value)
      setMediaType(val);

      // load the videos
      /*
      if (val == 'guruculum') {
        const resp = await MapAPI.getWizenozeVideos();
        console.log('UnitList.js ControlledRadioButtonsGroup handleChange: response ' + JSON.stringify(resp))

        let videos = [];
        props.setVideos(videos);
      }
      */
    };

    return (
      <FormControl2>
        <FormLabel2 id="demo-controlled-radio-buttons-group">
          Type of media
        </FormLabel2>
        <RadioGroup2
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={mediaType}
          onChange={handleChange}
        >
          <FormControlLabel2
            value="guruculum"
            control={
              <Radio2
                onClick={() => {
                  // onLoadContentByProv()
                }}
              />
            }
            label="Third Party Library"
          />
          <FormControlLabel2
            value="self"
            control={<Radio2 />}
            label="Own Multimedia"
          />
        </RadioGroup2>
      </FormControl2>
    );
  }

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}

      {/* <Link2 href={`/publisher-ebook/curriculum-map/set-up/${(currMap) ? currMap.id : 0}/?map_id=${(currMap) ? currMap.id : 0}&inst_id=${instId}&board_id=${boardId}&class_std=${clsStd}&sub_id=${subId}`}> */}
      <Card2
        sx={{ maxWidth: 345, textAlign: "center" }}
        onClick={() => {
          // window.alert('yeah!!')
          //navigation.navigate()
          handleClickOpen();
        }}
      >
        <CardActionArea2>
          <CardMedia2
            component="img"
            height="140"
            image="https://cdn.pixabay.com/photo/2018/11/13/21/44/instagram-3814061_1280.png"
            alt="green iguana"
          />

          <CardContent2>
            <Typography2 gutterBottom variant="h5" component="div">
              Add Plan
            </Typography2>

            <Typography2 variant="body2" color="text.secondary">
              Add a new Plan
            </Typography2>
          </CardContent2>
        </CardActionArea2>
      </Card2>
      {/* </Link2> */}


      {/* ADD PLAN DIALOG */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>Add a new plan </DialogTitle>
        <DialogContent>
          <DialogContentText>Add a new plan</DialogContentText>

          <>
            {/* <MultiStep activeStep={1} showNavigation={true} steps={steps} /> */}
            {/* <MasterForm /> */}

            {/* <ProgressForm /> */}

            {/* <AddPlanForm /> */}
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Plan Name"
                  value={name}
                  placeholder="Enter here"
                  type="text"
                  fullWidth
                  variant="outlined"
                  style={{
                    width: "99%",
                  }}
                  size="large"
                  onChange={handleChangeName}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  autoFocus
                  margin="dense"
                  id="desc"
                  label="Learning outcome"
                  placeholder="Enter here"
                  value={desc}
                  type="text"
                  fullWidth
                  variant="outlined"
                  style={{
                    width: "99%",
                  }}
                  size="large"
                  onChange={handleChangeDesc}
                />
              </div>

              <div
                style={{
                  margin: 20,
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "99%",
                  }}
                >
                  <FormControl2 fullWidth>
                    <InputLabel2 id="demo-simple-select-label">
                      Topic
                    </InputLabel2>
                    <Select2
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={topic}
                      // value={tpcId}
                      // onChange={handleTpcChange}
                      label="Topic"
                      size="small"
                      onChange={handleChangeTopic}
                    >
                      <MenuItem2 value={0}>Select Topic</MenuItem2>

                      {loadingTop && topDetailsGet ? (
                        console.log("loading trueeee")
                      ) : TopDetails.data && TopDetails.data.length != 0 ? (
                        TopDetails.data.map((list, index) => {
                          let selected = false;

                          if (topic == list.topic_id) selected = true;

                          return (
                            <MenuItem2
                              selected={selected}
                              key={index}
                              value={list.topic_id}
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              {list.topic_title}
                            </MenuItem2>
                          );
                        })
                      ) : (
                        <MenuItem2
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          Chapter not selected
                        </MenuItem2>
                      )}
                    </Select2>
                  </FormControl2>
                </div>

                {/* time pick */}
                {/* <div
                  style={{
                    width: '30%'
                  }}>

                  <TextField
                    autoFocus
                    margin="dense"
                    id="time"
                    value={dur}
                    label="Plan Duration"
                    placeholder="Enter minutes"
                    type="number"
                    fullWidth
                    variant="outlined"
                    style={{
                      width: '99%'
                    }}
                    size="large"
                    onChange={handleChangeDur}
                  />

                </div> */}
              </div>





              <div
                style={{
                  margin: 20,
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "99%",
                  }}
                >
                  <FormControl2 fullWidth>
                    <InputLabel2 id="demo-simple-select-label">
                      Sub Topic
                    </InputLabel2>
                    <Select2
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={subTopic}
                      // value={tpcId}
                      // onChange={handleTpcChange}
                      label="Sub Topic"
                      size="small"
                      onChange={handleChangeSubTopic}
                    >
                      <MenuItem2 value={0}>Select Sub Topic</MenuItem2>

                      {loadingSubtop && SubtopDetailsGet ? (
                        console.log("loading trueeee")
                      ) : SubtopDetails.data && SubtopDetails.data.sub_topics && SubtopDetails.data.sub_topics.length != 0 ? (
                        SubtopDetails.data.sub_topics.map((list, index) => {
                          let selected = false;

                          if (subTopic == list.topic_sub_id) selected = true;

                          return (
                            <MenuItem2
                              selected={selected}
                              key={index}
                              value={list.topic_sub_id}
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              {list.topic_sub_name}
                            </MenuItem2>
                          );
                        })
                      ) : (
                        <MenuItem2
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          Topic not selected
                        </MenuItem2>
                      )}
                    </Select2>
                  </FormControl2>
                </div>


                {/* time pick */}
                {/* <div
                  style={{
                    width: '30%'
                  }}>

                  <TextField
                    autoFocus
                    margin="dense"
                    id="time"
                    value={dur}
                    label="Plan Duration"
                    placeholder="Enter minutes"
                    type="number"
                    fullWidth
                    variant="outlined"
                    style={{
                      width: '99%'
                    }}
                    size="large"
                    onChange={handleChangeDur}
                  />

                </div> */}
              </div>



              <div
                style={{
                  marginTop: 10
                }}>


                {/* Choose teaching or learning */}

                <FormControl2>
                  <FormLabel2 id="demo-row-radio-buttons-group-label">Is this for Teacher or Student?</FormLabel2>
                  <RadioGroup2
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={planType}
                    onChange={(e) => {
                      onPlanTypeChanged(e);
                    }}
                  >
                    <FormControlLabel2 value={0} control={<Radio2 />} label="Teaching (For Teacher)" />
                    {/* <FormControlLabel2 value={1} control={<Radio2 />} label="Learning (For Student)" /> */}
                    {/* <FormControlLabel2 value="other" control={<Radio2 />} label="Other" />
                    <FormControlLabel2
                      value="disabled"
                      disabled
                      control={<Radio2 />}
                      label="other"
                    /> */}
                  </RadioGroup2>
                </FormControl2>
              </div>
            </>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* <Button onClick={() => { onNextClicked() }}>NEXT</Button> */}
          <Button onClick={() => handleSave(true)}>SAVE</Button>
        </DialogActions>
      </Dialog>

      {/* choose provider */}
      <Dialog
        open={open2}
        onClose={handleClose2}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>Choose the media type </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose what type of media is available to teacher
          </DialogContentText>

          <>
            <ControlledRadioButtonsGroup
              handleMediaTypeChanged={(type) => handleMediaTypeChanged(type)}
              setVideos={(videos) => setVideos(videos)}
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose2();
              setOpen(true);
              setMediaType("self");
            }}
          >
            Cancel
          </Button>
          <Button onClick={() => handleSave(true)}>SAVE</Button>
        </DialogActions>
      </Dialog>

      {/* <MediaDrawer /> */}

      {/* message snack */}
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {err}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackOpen}
        autoHideDuration={6000}
        onClose={handleSuccessSnackClose}
      >
        <Alert
          onClose={handleSuccessSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Plan Setup saved successfully. Please wait until we redirect you to
          the creator page.
        </Alert>
      </Snackbar>
    </div>
  );
}

function LessonPlanAddCard(props) {
  const { lsn } = props;
  const queryParams = new URLSearchParams(useLocation().search);
  const params = useParams();

  console.log(
    `UnitList.js LessonPlanAddCard queryParams ${JSON.stringify(queryParams)}`
  );
  console.log(`UnitList.js LessonPlanAddCard params ${JSON.stringify(params)}`);
  const currMap = useSelector((state) => state.currMap.currMap);
  const currUnit = useSelector((state) => state.currUnit.currUnit);
  const currLesson = useSelector((state) => state.currLesson.currLesson);

  let instId = 0;
  let boardId = 0;
  let clsStd = 0;
  let subId = 0;
  let unitId5 = 0;
  let lsnId5 = 0;
  let chpId5 = 0;

  console.log(
    "UnitList.js LessonPlanAddCard currMap " + JSON.stringify(currMap)
  );

  if (!currMap) {
    return <span>Loading..</span>;
  }

  instId = currMap.institute_type_id;
  boardId = currMap.institute_board_id;
  clsStd = currMap.classroom_std;
  subId = currMap.subject_id;
  unitId5 = currUnit.id;
  lsnId5 = currLesson.id;
  chpId5 = currLesson.chapter_id;

  return (
    <AddPlanFormDialog
      currMap={currMap}
      instId={instId}
      boardId={boardId}
      clsStd={clsStd}
      subId={subId}
      lsn={lsn}
      unitId5={unitId5}
      lsnId5={lsnId5}
      chpId5={chpId5}
    />
  );
}

function ReviewPlanDialog(props) {
  const [open, setOpen] = React.useState(props.openReviewDialog);
  const { planSetupId, planName, planStatus, planType } = props;
  const [previewUrl, setPreviewUrl] = React.useState(props.previewUrl);

  const handleClickOpen = () => {
    setOpen(true);
    props.setOpenReviewDialog(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.setOpenReviewDialog(false);
  };

  console.log(TAG, 'ReviewPlanDialog previewUrl: ' + previewUrl);
  return (
    <div>

      <Button2
        size="small"
        key="two"
        className="tagbutton"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          let previewUrl2 = '';
          if(planType) {
            // learn preview
            previewUrl2 = 'http://127.0.0.1:5501/conceptdesigner.html';
          } else {
            previewUrl2 = Constants.Application.SCHOOLER_URL + '/schooler/teacher-classroom-preview?restreq=true&id=0&subid=0&staffid=0&datetime=' + new Date().toISOString() + '&planid=' + planSetupId + '#';
            // teach
          }
          
          console.log(TAG, 'ReviewPlanDialog previewUrl2 ', previewUrl2);
          setPreviewUrl(previewUrl2);
          props.setPreviewUrl(previewUrl2);
          props.setOpenReviewDialog(true);
        }} mode="contained"
        variant="contained"
        color="primary"
      >Preview</Button2>

      <Dialog
        fullScreen
        open={props.openReviewDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}>
          <Toolbar
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClose();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {planName}
            </Typography>

          </Toolbar>
        </AppBar>

        <iframe id="" src={previewUrl} width="100%" height="100%" ></iframe>
      </Dialog>
    </div>
  );
}

function LessonPlanCard(props) {
  const { lsn, plan } = props;
  const [previewUrl, setPreviewUrl] = useState('');
  const [openReviewModal, setOpenReviewModal] = React.useState(false);
  const [openReviewDialog, setOpenReviewDialog] = React.useState(false);
  const currMap = useSelector((state) => state.currMap.currMap);
  const currUnit = useSelector((state) => state.currUnit.currUnit);
  const currLesson = useSelector((state) => state.currLesson.currLesson);

  const dispatch = useDispatch();
  const history = useHistory();

  const unitId = currUnit.id;
  const lsnId = currLesson.id;
  const chapterId = currLesson.chapter_id;
  const createdUserId = plan.created_user_id;
  const planSetupId = plan.lesson_plan_setup_id;
  const planName = plan.name;
  const planStatus = plan.is_approved;
  const planType = plan.is_learning;

  const buttons = [
    <Button2 variant="contained" mode="contained" key="one">One</Button2>,
    <Button2 variant="contained" mode="contained" key="two">Two</Button2>,
    <Button2 variant="contained" mode="contained" key="three">Three</Button2>,
  ];

  const BUTTONGROUP = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '& > *': {
            m: 1,
          },
        }}
      >
        <ButtonGroup2 size="small" aria-label="small button group">
          <>
            {/* {buttons} */}
            {plan.is_approved === 0 ?
              <Button2 key="one"
                variant="contained"
                mode="contained"
                size="small"
                color="warning"
                className="tagbutton"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >PENDING</Button2>
              : <></>
            }

            {plan.is_approved === -1 ?
              <Button2 key="one"
                variant="contained"
                mode="contained"
                size="small"
                className="tagbutton"
                onClick={(e) => {
                  e.stopPropagation();

                  // update current plan to reflect on the dialog
                  handleUpdateLessonPlan();
                  // wait 1 second
                  setTimeout(function () {
                    props.setOpenComm(true);
                  }, 500);
                }}
                color="error"
              >REWORK</Button2>
              : <></>
            }

            <ReviewPlanDialog
              key="two"
              previewUrl={previewUrl}
              setPreviewUrl={setPreviewUrl}
              openReviewDialog={openReviewDialog}
              setOpenReviewDialog={setOpenReviewDialog}
              planSetupId={planSetupId}
              planName={planName}
              planStatus={planStatus}
              planType={planType}
            />

            <Button2 key="three"
              variant="contained"
              mode="contained"
              size="small"
              color="secondary"
              className="tagbutton"
              onClick={(e) => {
                e.stopPropagation();
                // alert('stopped propagation..')

                // update current plan to reflect on the dialog
                handleUpdateLessonPlan();
                // wait 1 second
                setTimeout(function () {
                  props.handleOpenEditLessonPlan(plan);
                }, 500);
              }}
            >EDIT</Button2>

          </>
        </ButtonGroup2>
      </Box>
    )
  }

  const handleUpdateLessonPlan = async () => {
    // fetch the topics by the lsn chapter-id
    const chpId = lsn.chapter_id;

    await dispatch(listTopGet(chpId));
    await dispatch(updateCurrLessonPlan(plan));

    props.handleUpdateSubTopic(plan.sub_topic_id);
    props.handleUpdateTopic(plan.topic_id);
  };

  const onRedirectToPlanClicked = () => {
    const planType = plan.is_learning;
    let url = '';
    if(!planType) { // teaching
      url = `/publisher-ebook/curriculum-map/set-up/${plan.lesson_plan_setup_id}/?map_id=${currMap.id}&inst_id=${instId}&board_id=${boardId}&class_std=${clsStd}&sub_id=${subId}&unit_id=${unitId}&lesson_id=${lsnId}&chap_id=${chapterId}&is_approved=${planStatus}`;
    } else {
      url = `/publisher-ebook/curriculum-map/set-up-learn/${plan.lesson_plan_setup_id}/?map_id=${currMap.id}&inst_id=${instId}&board_id=${boardId}&class_std=${clsStd}&sub_id=${subId}&unit_id=${unitId}&lesson_id=${lsnId}&chap_id=${chapterId}&is_approved=${planStatus}`;
    }
    
    // window.location.assign(url)
    history.push(url);
  };

  let planImg =
    "https://static.wikia.nocookie.net/onepiecefanon/images/2/24/NoImageAvailable.jpg/revision/latest?cb=20180606064203";

  let instId = 0;
  let boardId = 0;
  let clsStd = 0;
  let subId = 0;
  console.log(
    "UnitList.js LessonPlanAddCard currMap " + JSON.stringify(currMap)
  );
  console.log("UnitList.js LessonPlanAddCard plan " + JSON.stringify(plan));

  if (!currMap) {
    return <span>Loading..</span>;
  }

  instId = currMap.institute_type_id;
  boardId = currMap.institute_board_id;
  clsStd = currMap.classroom_std;
  subId = currMap.subject_id;

  if (plan.cover_image_url) planImg = plan.cover_image_url;

  let showReview = true;
  if (createdUserId === userId3)
    showReview = false;


  // show for everyone
  showReview = true;

  return (
    // <Link2 href={`/publisher-ebook/curriculum-map/set-up/${plan.lesson_plan_setup_id}/?map_id=${currMap.id}&inst_id=${instId}&board_id=${boardId}&class_std=${clsStd}&sub_id=${subId}&unit_id=${unitId}&lesson_id=${lsnId}&chap_id=${chapterId}`}>
    // <Link to={`/publisher-ebook/curriculum-map/set-up/${plan.lesson_plan_setup_id}/?map_id=${currMap.id}&inst_id=${instId}&board_id=${boardId}&class_std=${clsStd}&sub_id=${subId}&unit_id=${unitId}&lesson_id=${lsnId}&chap_id=${chapterId}`}>
    <Card2 sx={{ maxWidth: 345 }}>
      <CardActionArea2 onClick={onRedirectToPlanClicked}>
        <CardMedia2
          component="img"
          height="140"
          image={planImg}
          alt={plan.name}
        />
        <CardContent2>
          <Typography2 gutterBottom variant="h5" component="div">
            {plan.name}
          </Typography2>

          <Typography2 variant="body2" color="text.secondary">
            {plan.plan_description}
          </Typography2>

          <div
            style={{
              position: "absolute",
              bottom: 5,
              right: 5,
              zIndex: 100
            }}
          >
            {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {plan.is_approved === 1 ? (
                <Chip label="APPROVED" color="primary" />
              ) : (
                <></>
              )} */}



            {/* <div
                style={{
                  padding: 10,
                  background: "rgba(0, 0, 0, 0.1)",
                  color: "#778899",
                  borderTopLeftRadius: 5,
                  marginLeft: 10,
                }}
              >
                <span>{plan.duration_min} min</span>
              </div> */}
            {/* </div> */}
          </div>

          <div
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              display: 'flex',
              flexDirection: "row",
            }}
          >


            {/* 
            {plan.is_approved === -1 ? (
              <div
              style={{
                marginRight: 10
              }}>
                <Chip label="REWORK" color="secondary" 
                onClick={(e) => {
                  e.stopPropagation();
                  
                  // update current plan to reflect on the dialog
                  handleUpdateLessonPlan();
                  // wait 1 second
                  setTimeout(function () {
                    props.setOpenComm(true);
                  }, 500);
                }}
                />
              </div>
            ) : (
              <></>
            )} */}




            {/* {((showReview) && (plan.is_approved === 0)) ? ( */}
            {/* <div
                style={{
                  marginRight: 10
                }}> */}
            {/*                   
                  <ReviewPlanModal
                  openReviewModal={openReviewModal}
                  setOpenReviewModal={setOpenReviewModal}
                  planSetupId={planSetupId}
                  /> */}

            {/* <ReviewPlanDialog
                  openReviewDialog={openReviewDialog}
                  setOpenReviewDialog={setOpenReviewDialog}
                  planSetupId={planSetupId}
                  planName={planName}
                  planStatus={planStatus}
                  />
                </div> */}
            {/* )
                :
                <></>
              } */}

            {/* <EditIcon2
              onClick={(e) => {
                e.stopPropagation();
                // alert('stopped propagation..')

                // update current plan to reflect on the dialog
                handleUpdateLessonPlan();
                // wait 1 second
                setTimeout(function () {
                  props.handleOpenEditLessonPlan(plan);
                }, 500);
              }}
            /> */}

            <BUTTONGROUP />
          </div>
        </CardContent2>
      </CardActionArea2>
    </Card2>
    // </Link>
    // </Link2>
  );
}

function UnitSetup(props) {
  const { units, lessonsData, handleChange } = props;
  const [queryParams, setQueryParams] = useState("");
  const dispatch = useDispatch();

  const currUnit = useSelector((state) => state.currUnit.currUnit);

  console.log(TAG, 'currUnit is', currUnit);

  console.log(
    "UnitList.js UnitSetup lessonsData " + JSON.stringify(lessonsData)
  );

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const instId = query.get("inst_id");
    const board_id = query.get("board_id");
    const clsStd = query.get("class_std");
    const subId = query.get("sub_id");

    const queryParams1 = new URLSearchParams(window.location.search);
    setQueryParams(queryParams1);

    console.log("query params at unit list", instId, board_id, clsStd, subId);

    // dispatch(listChpGet2(subId, board_id, clsStd));

    // clear the lessons first
    dispatch(getUnitLessons(0));
    // clear current unit
    dispatch(updateCurrUnit(null));

    return () => {
      //
    };
  }, []);

  if (!units) {
    return (
      <div>
        <p>No Units!</p>
      </div>
    );
  }

  return (
    <>
      {units && units.data && units.data.Units && units.data.Units.length ? (
        units.data.Units.map((unit, index) => {
          const isSel = (currUnit && unit.id === currUnit.id);
          const {
            id,
            unit_title_text,
            lesson_count,
            cover_image,
            days_count,
            unit_number,
          } = unit;

          return (
            <BasicCard
              title={unit_title_text}
              key={index}
              cover={cover_image}
              // index={(index + 1)}
              index={unit_number}
              lessons={lesson_count}
              days={days_count}
              selected={isSel}
              lessonsData={lessonsData}
              id={id}
              unit={unit}
              handleChange={(id2, unit_title_text2) =>
                handleChange(id2, unit_title_text2)
              }
              defPanel={props.defPanel}
              onUpdateDefPanel={(panelx) => props.onUpdateDefPanel(panelx)}
              handleClickOpen={(e, unit) => props.handleClickOpen(e, unit)}
              setIsEdit={(flag) => props.setIsEdit(flag)}
            />
          );
        })
      ) : (
        <p>No Units</p>
      )}
    </>
  );
}

const LESSONSETUP = (props) => {
  const { lsn, idx, panel, defPanel, expanded } = props;
  const currLesson = lsn;
  const lessSubSubject = currLesson ? currLesson.subject_sub_id : 0;

  const [plans, setPlans] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(true);
  const [openDialog, setOpenDialog] = useState(null);
  const [subjectSubType2, setSubjectSubType2] = useState(lessSubSubject);

  const dispatch = useDispatch();
  // const currLesson = useSelector(state => state.currLesson.currLesson);
  const currUnit = useSelector((state) => state.currUnit.currUnit);
  const currMap = useSelector((state) => state.currMap.currMap);

  const subSubDetailsGet = useSelector((state) => state.subSubDetails);
  const { loadingsubSub, subSubDetails, errorsubSub } = subSubDetailsGet;

  const getLsnPlans = async () => {
    const blockId = lsn.id;
    const plans1 = await LessonPlanSetupAPI.getPlans(blockId);

    console.log("UnitList.js LESSONSETUP plans ", JSON.stringify(plans));
    setPlans(plans1);
    setLoading(false);
  };

  let unitNumEVar = currUnit != null ? currUnit.unit_number : 0;
  let unitTitleEVar = currUnit != null ? currUnit.unit_title_text : "";
  let unitLessonsEVar = currUnit != null ? currUnit.lesson_count : "";
  let unitDaysEVar = currUnit != null ? currUnit.days_count : "";
  let unitColorEVar = currUnit != null ? currUnit.unit_color : "";
  let unitTagsEVar = currUnit != null ? currUnit.unit_tags : "";
  let unitIdEVar = currUnit != null ? currUnit.id : 0;

  const lessNameE = currLesson ? currLesson.name_text : "";
  const lessDescE = currLesson ? currLesson.name_less : "";
  const lessDaysE = 0; //currLesson ? currLesson.days_count : "";
  const lessMinsE = currLesson ? currLesson.minutes_num : 0;
  const lessChapE = currLesson ? currLesson.chapter_id : 0;

  console.log(TAG, 'lessNameE', lessNameE);

  const [unitNumE, setUnitNumE] = React.useState(unitNumEVar);
  const [unitTitleE, setUnitTitleE] = React.useState(unitTitleEVar);
  const [unitLessonsE, setUnitLessonsE] = React.useState(unitLessonsEVar);
  const [unitDaysE, setUnitDaysE] = React.useState(unitDaysEVar);
  const [unitColorE, setUnitColorE] = React.useState(unitColorEVar);
  const [unitTagsE, setUnitTagsE] = React.useState(unitTagsEVar);
  const [unitIdE, setUnitIdE] = React.useState(unitIdEVar);
  const [checked, setChecked] = React.useState(false);
  const [lessNameE2, setLessNameE2] = useState(lessNameE);
  const [lessMinsE2, setLessMinsE2] = useState(lessMinsE);

  const [lessonSnackEOpen, setLessonSnackEOpen] = useState(false);
  const [showLessonDelete, setShowLessonDelete] = useState(false);

  const handleShowLessonDelete = (bool) => {
    setShowLessonDelete(bool);
  };

  console.log(`unitnume ${unitNumE}`);
  console.log(`unitnume var ${unitNumEVar}`);
  console.log(`unittitlee ${unitTitleEVar}`);

  const classes = useStyles2();
  const handleUnitIdChange = (event) => {
    const { name, value } = event.target;
    setUnitIdE(value);
  };

  const handleOpenEdit = () => {
    setSubjectSubType2(lessSubSubject); // update with current chapter
    setOpenDialog(true);
  };

  const handleCloseEdit = () => {
    setOpenDialog(false);
  };

  const handleLessonSnackEClose = () => {
    setLessonSnackEOpen(false);
  };

  /* EDIT UNIT FIELDS FUNCTIONS */

  const handleUnitNumEChange = (event) => {
    const { name, value } = event.target;
    setUnitNumE(value);
    unitNumEVar = value;

    // update store
    currUnit.unit_number = value;
    dispatch(updateCurrUnit(currUnit));
  };

  const handleUnitTitleEChange = (event) => {
    const { name, value } = event.target;
    setUnitTitleE(value);
    unitTitleEVar = value;

    // update store
    currUnit.unit_title_text = value;
    dispatch(updateCurrUnit(currUnit));
  };

  const handleUnitLessonsEChange = (event) => {
    const { name, value } = event.target;
    setUnitLessonsE(value);
    unitLessonsEVar = value;

    // update store
    currUnit.lesson_count = value;
    dispatch(updateCurrUnit(currUnit));
  };

  const handleUnitDaysEChange = (event) => {
    const { name, value } = event.target;

    setUnitDaysE(value);
    unitDaysEVar = value;

    // update store
    currUnit.days_count = value;
    dispatch(updateCurrUnit(currUnit));
  };

  const handleUnitTagsEChange = (event) => {
    const { name, value } = event.target;

    setUnitTagsE(value);
    unitTagsEVar = value;

    // update store
    currUnit.unit_tags = value;
    dispatch(updateCurrUnit(currUnit));
  };

  const handleLessNameEChange = (event) => {
    const { name, value } = event.target;

    // update currlesson
    let currLessonE = currLesson;
    currLessonE.name_text = value;

    dispatch(updateCurrLesson(currLessonE));
  };

  const handleLessDaysEChange = (event) => {
    const { name, value } = event.target;

    // update currlesson
    let currLessonE = currLesson;
    currLessonE.minutes_num = value;

    dispatch(updateCurrLesson(currLessonE));
  };

  const handleLessTitleEChange = (event) => {
    const { name, value } = event.target;

    let currLessonE = currLesson;
    currLessonE.name_text = value;
    currLessonE.name_less = value;
    setLessNameE2(value); // update state

    dispatch(updateCurrLesson(currLessonE));
  };

  const handleLessChapEChange = (event) => {
    const { name, value } = event.target;

    console.log(`chapter change ${value}`);

    let currLessonE = currLesson;
    currLessonE.chapter_id = value;
    
    // set the name of the lesson as chapter
    // let titleVal = value;
    // currLessonE.name_text = titleVal;
    // currLessonE.name_less = titleVal;
    // setLessNameE2(titleVal); // update state

    dispatch(updateCurrLesson(currLessonE));
  };

  const handleEditLesson = async () => {
    const currLessonE = currLesson;
    const dataObj = {
      map_unit: currUnit.id,
      name_text: currLessonE.name_text,
      name_less: currLessonE.name_text,
      minutes_num: currLessonE.minutes_num,
      chapter_id: currLessonE.chapter_id,
      subject_sub_id: currLessonE.subject_sub_id,
    };

    console.log(`post edit lesson body params ${JSON.stringify(dataObj)}`);

    const resp = await MapUnitAPI.postUpdateLesson(currLesson.id, dataObj);
    console.log("resp at post add new lesson", resp);
    handleCloseEdit();

    // show snackbar
    setLessonSnackEOpen(true);

    // load lessons
    dispatch(getUnitLessons(currUnit.id));
  };

  const handleDeleteLesson = async () => {
    const resp = await MapUnitAPI.postDeleteLesson(currLesson.id);
    console.log("resp at post delete new lesson", resp);

    // close dialog
    setShowLessonDelete(false);

    // close edit dialog
    handleCloseEdit();

    // load lessons
    dispatch(getUnitLessons(currUnit.id));
  };

  const loadSubSubs = () => {
    // const currMap2 = useSelector((state) => state.currMap.currMap);
    // const state2 = store.getState()
    // const currMap2 = state2.currMap.currMap;
    console.log(
      "UnitList.js LESSONSETUP loadSubSubs: currMap " + JSON.stringify(currMap)
    );
    let value = 0;

    if (currMap) value = currMap.subject_id;

    if (value != 0) dispatch(listSubSubGet(value));
  };

  // changing sub subject
  const handleSubSubType2Change = (event) => {
    console.log(
      "UnitList.js LESSONSETUP handleSubSubType2Change: called handleSubSubChange"
    );
    console.log(
      `UnitList.js LESSONSETUP handleSubSubType2Change: new sub subject ${JSON.stringify(
        event.target
      )}`
    );

    const name = event.target.name;
    const value = event.target.value;
    setSubjectSubType2(value);

    // update store
    let currLessonE = currLesson;
    currLessonE.subject_sub_id = value;

    dispatch(updateCurrLesson(currLessonE));

    // get maps
    // dispatch(listCurriculuFilterGet(instType2, boardType2, standardType2, value))

    // new vars from map
    let instType3 = currMap.institute_type_id;
    let boardType3 = currMap.institute_board_id;
    let stdType3 = currMap.classroom_std;
    let subType3 = currMap.subject_id;
    let subType4 = value;

    console.log(
      "UnitList.js LESSONSETUP handleSubSubType2Change: instType3 " + instType3
    );
    console.log(
      "UnitList.js LESSONSETUP handleSubSubType2Change: boardType3 " +
      boardType3
    );
    console.log(
      "UnitList.js LESSONSETUP handleSubSubType2Change: stdType3 " + stdType3
    );
    // get chapters
    dispatch(listChpGet3(value, boardType3, stdType3));


    // clear the name of the lesson / chapter
    let titleVal = '';
    currLessonE.name_text = titleVal;
    currLessonE.name_less = titleVal;
    setLessNameE2(titleVal); // update state

    dispatch(updateCurrLesson(currLessonE));
  };

  useEffect(() => {
    getLsnPlans();
  }, []);

  console.log("UnitList.js LESSONSETUP lsn " + JSON.stringify(lsn));
  console.log("UnitList.js LESSONSETUP panel " + panel);
  console.log("UnitList.js LESSONSETUP defPanel " + defPanel);
  console.log("UnitList.js LESSONSETUP expanded " + expanded);

  let isExp2 = false;

  const chpDetailsGet = useSelector((state) => state.chpDetails);
  const { loadingChap, chapDetails, errorChp } = chpDetailsGet;

  console.log(
    "UnitList.js LESSONSETUP: chapDetails " + JSON.stringify(chapDetails)
  );

  // what is expanded...?
  if (expanded && defPanel && panel) {
    if (expanded === panel) isExp2 = true;
  }

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  /** DELETE LESSON DIALOG */
  function DelLessonDialog() {
    const [open, setOpen] = React.useState(showLessonDelete);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div>
        <Button variant="" onClick={handleClickOpen}>
          Delete
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete chapter?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you really want to delete the chapter? You cannot revert this!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleDeleteLesson} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        OK
      </Button>
      {/* <IconButton2
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon2 fontSize="small" />
      </IconButton2> */}
    </React.Fragment>
  );

  console.log(TAG, "subjectSubType2 " + subjectSubType2);

  return (
    <Accordion2
      expanded={isExp2}
      onChange={(event, isExpanded) => {
        console.log(TAG, "LESSONSETUP accordion2 onchange called ...");
        props.handleChange(panel, event, isExpanded, idx);
        console.log(
          "UnitList.js LESSONSETUP Accordion2 onChange() panel " + panel
        );
        console.log(
          "UnitList.js LESSONSETUP Accordion2 onChange() event " + event
        );
        console.log(
          "UnitList.js LESSONSETUP Accordion2 onChange() isExpanded " +
          isExpanded
        );

        // now call the topics as we're now inside a lesson => chapter
        // const chpId = lsn.chapter_id;

        // dispatch(listTopGet(chpId));

        // load this lesson's plans
        getLsnPlans();

        props.onUpdateDefPanel(panel);
        setOpen(true);
        setLoading(true);
        props.loadSubSubs();
      }}
    >
      <AccordionSummary2
        expandIcon={<ExpandMoreIcon2 />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          backgroundColor: Constants.Application.COLOR_PRIMARY_LIGHT,
        }}
      >
        {/* <Typography2 sx={{ width: "33%", flexShrink: 0 }}>
          CHAPTER - {idx + 1}
        </Typography2> */}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '95%'
          }}>
          <Typography2 sx={{ color: "text.dark" }}>
            {lsn.name_text}
            {/* - {lsn.minutes_num} days */}
          </Typography2>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}>
            <Typography2
              variant="caption">
              Approved
            </Typography2>
            <div style={{ width: 10 }}></div>
            <Typography2
              variant="body2">
              {lsn.approved_count} / {lsn.plan_count}
            </Typography2>
          </div>
        </div>
      </AccordionSummary2>
      <AccordionDetails2>
        {/* 10 Lessons - 5 days */}
        <div
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">Lesson Plans</Typography>

          {currLesson && (
            <>
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                <Button
                  size="small"
                  variant="contained"
                  color="gray"
                  onClick={handleOpenEdit}
                  startIcon={<EditIcon2 />}
                >
                  Edit Chapter
                </Button>

                {/* <Chip
                    label="Edit Lesson"
                    clickable
                    color="secondary"
                    onClick={handleOpenEdit}
                  /> */}
              </div>

              {/* EDIT LESSON UNIT DIALOG */}
              <Dialog
                fullWidth
                open={openDialog}
                onClose={handleCloseEdit}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  <Typography variant="h4">Edit Chapter</Typography>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Edit the chapter fields to your suitability.
                  </DialogContentText>

                  {/* select sub subject */}
                  <Grid
                    container
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                  >
                    <Grid container item lg={8} md={8} sm={12} xs={12}>
                      <div
                        style={{
                          width: "20%",
                          paddingRight: 10,
                        }}
                      >
                        {subSubDetails ? (
                          <div style={{ display: "flex" }}>
                            <EditIcon style={{ height: 16 }} />
                            <FormControl className={classes.formControl}>
                              <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                              >
                                Sub Subject
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={subjectSubType2}
                                onChange={handleSubSubType2Change}
                                label="Sub Subject"
                                style={{ fontSize: 13, color: "#464E5F" }}
                              >
                                {loadingsubSub && subSubDetails ? (
                                  <MenuItem
                                    key={0}
                                    value="Please Select Board"
                                    style={{
                                      fontSize: 13,
                                      color: "#464E5F",
                                      fontFamily:
                                        "Poppins, Helvetica, sans-serif",
                                    }}
                                  >
                                    {" "}
                                    Please Select Sub Subject{" "}
                                  </MenuItem>
                                ) : subSubDetails &&
                                  subSubDetails.data &&
                                  subSubDetails.data.length != 0 ? (
                                  subSubDetails.data.map((list, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={list.subject_sub_id}
                                        style={{
                                          fontSize: 13,
                                          color: "#464E5F",
                                          fontFamily:
                                            "Poppins, Helvetica, sans-serif",
                                        }}
                                      >
                                        {list.subject_sub_name}
                                      </MenuItem>
                                    );
                                  })
                                ) : (
                                  <MenuItem
                                    style={{
                                      fontSize: 13,
                                      color: "#464E5F",
                                      fontFamily:
                                        "Poppins, Helvetica, sans-serif",
                                    }}
                                  >
                                    No data found!
                                  </MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        ) : (
                          <div style={{ display: "flex" }}>
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                              >
                                Sub Subject
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={subjectSubType2}
                                onChange={handleSubSubType2Change}
                                label="Sub Subject"
                                style={{ fontSize: 13, color: "#464E5F" }}
                              >
                                <MenuItem
                                  style={{
                                    fontSize: 13,
                                    color: "#464E5F",
                                    fontFamily:
                                      "Poppins, Helvetica, sans-serif",
                                  }}
                                >
                                  No data found!{" "}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid container item lg={4} md={4} sm={12} xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          flexDirection: "row",
                          // width: "20%",
                          // paddingRight: 10
                        }}
                      >
                        <EditIcon style={{ height: 16 }} />

                        <TextField
                          autoFocus
                          // defaultValue={unitDaysEVar}
                          defaultValue={lessMinsE2}
                          onChange={handleLessDaysEChange}
                          margin="dense"
                          id="name"
                          label="Days"
                          type="number"
                          fullWidth
                          style={{
                            marginLeft: 10,
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        flexDirection: "row",
                        // width: "80%",
                      }}
                    >
                      <EditIcon style={{ height: 16 }} />
                      <FormControl
                        required
                        className={classes.formControl}
                        style={{ overflowY: "initial", scrollbarWidth: "thin" }}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Chapter
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-required-label"
                          id="demo-simple-select-required"
                          defaultValue={lessChapE}
                          onChange={handleLessChapEChange}
                          className={classes.selectEmpty}
                        >
                          {loadingChap && chpDetailsGet ? (
                            console.log("loading trueeee")
                          ) : chapDetails.data &&
                            chapDetails.data.length != 0 ? (
                            chapDetails.data.map((list, index) => {
                              let selected = false;
                              if (list.chapter_id == lessChapE) selected = true;

                              return (
                                <MenuItem
                                  selected={selected}
                                  key={index}
                                  value={list.chapter_id}
                                  style={{
                                    fontSize: 13,
                                    color: "#464E5F",
                                    fontFamily:
                                      "Poppins, Helvetica, sans-serif",
                                  }}
                                  onClick={() => {
                                    const value = list.chapter_title;
                                    // setChapterName(list.chapter_title);
                                    console.log(
                                      "UnitList.js selected chapter " +
                                      value
                                    );
                                    // update the currlesson object
                                    // set the name of the lesson as chapter
                                    let titleVal = value;
                                    let currLessonE = currLesson;
                                    currLessonE.name_text = titleVal;
                                    currLessonE.name_less = titleVal;
                                    // setLessNameE2(titleVal); // update state

                                    dispatch(updateCurrLesson(currLessonE));
                                    setLessNameE2(value);
                                    
                                  }}
                                >
                                  {list.chapter_title}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MenuItem
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              No Chapters for the subject..
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <EditIcon style={{ height: 16 }} />

                    <TextField
                      autoFocus
                      value={lessNameE2}
                      // value={lessNameE2}
                      onChange={handleLessTitleEChange}
                      margin="dense"
                      id="name"
                      label="Title"
                      type="text"
                      fullWidth
                      style={{
                        marginLeft: 10,
                      }}
                    />
                  </div>

                  {/*
                  <div
                    style={{
                      display: "none",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      flexDirection: "row",
                      // width: "80%",
                    }}
                  >
                    <EditIcon style={{ height: 16 }} />

                    <TextField
                      autoFocus
                      defaultValue={lessNameE}
                      onChange={handleLessTitleEChange}
                      margin="dense"
                      id="name"
                      label="Lesson Name"
                      type="text"
                      fullWidth
                      style={{
                        marginLeft: 10,
                      }}
                    />
                  </div>
                    */}
                </DialogContent>
                <DialogActions>
                  <DelLessonDialog />

                  <Button onClick={handleCloseEdit} color="primary">
                    Cancel
                  </Button>

                  <Button color="primary" onClick={handleEditLesson}>
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}

          {/* <LessonsList /> */}
        </div>

        {!loading ? (
          <>
            <LessonPlans lsn={lsn} plans={plans} />
          </>
        ) : (
          <>
            <div
              style={{
                width: "100%",
                margin: 10,
              }}
            >
              <Alert severity="info">Loading..</Alert>
            </div>
          </>
        )}

        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Loading lesson plans.."
          action={action}
        />

        <Snackbar
          open={lessonSnackEOpen}
          autoHideDuration={6000}
          onClose={handleLessonSnackEClose}
        >
          <Alert onClose={handleLessonSnackEClose} severity="success">
            You have successfully updated chapter details!
          </Alert>
        </Snackbar>
      </AccordionDetails2>
    </Accordion2>
  );
};

const LESSONSSETUP = (props) => {
  const { unitLessons, expandedArr, expanded, isExpanded } = props;

  if (unitLessons && unitLessons.length) {
    return unitLessons.map((lsn, idx) => {
      return (
        <>
          <LESSONSETUP
            lsn={lsn}
            idx={idx}
            key={idx}
            handleChange={(pnl, event, isExpanded, idx) => {
              console.log(
                "UnitList.js LESSONSSETUP handleChange callback pnl " + pnl
              );
              props.handleChange(pnl, event, isExpanded, idx);
            }}
            panel={`panel${idx + 1}`}
            expandedArr={expandedArr}
            expanded={expanded} // which panel
            defPanel={props.defPanel}
            onUpdateDefPanel={(panelx) => props.onUpdateDefPanel(panelx)}
            loadSubSubs={props.loadSubSubs}

          />

        </>
      );
    });
  } else {
    return (
      <>
        <Alert2 severity="info">No lessons created yet!</Alert2>
      </>
    );
  }
};

/* NEW ACCORDIONS */
function LessonsSetup(props) {
  const [expanded, setExpanded] = React.useState(""); // default first
  const [expandedArr, setExpandedArr] = React.useState([]);
  const unitLessons = useSelector((state) => state.unitLessons.unitLessons);
  const [unitLessons2, setUnitLessons2] = useState(null);
  const dispatch = useDispatch();


  const currMap = useSelector((state) => state.currMap.currMap);
  const currUnit = useSelector((state) => state.currUnit.currUnit);
  const currLesson = useSelector((state) => state.currLesson.currLesson);

  let boardId = currMap.institute_board_id;
  let clsStd = currMap.classroom_std;
  let subId = currMap.subject_id;
  let subSubId = (currLesson) ? currLesson.subject_sub_id : 0;

  console.log(
    "UnitList.js LessonsSetup unitLessons " + JSON.stringify(unitLessons)
  );

  const handleChange1 = (panel) => (event, isExpanded) => {
    console.log("UnitList.js LessonSetup handleChange panel " + panel);
    console.log(
      "UnitList.js LessonSetup handleChange isExpanded " + isExpanded
    );
    setExpanded(isExpanded ? panel : false);
  };

  const handleChange = (panel, event, isExpanded, idx) => {
    console.log("UnitList.js LessonSetup handleChange panel " + panel);
    console.log(
      "UnitList.js LessonSetup handleChange isExpanded " + isExpanded
    );
    setExpanded(isExpanded ? panel : false);

    // load the lesson plans for this block
    let lesson = unitLessons[idx];
    console.log(
      "UnitList.js LessonSetup handleChange lesson " + JSON.stringify(lesson)
    );

    dispatch(updateCurrLesson(lesson));
    subSubId = lesson.subject_sub_id;

    // load chapters under sub-subject
    console.log(TAG, 'handleChange subSubId ', subSubId);
    console.log(TAG, 'handleChange currLesson ', currLesson);

    // get chapters under sub-subject
    dispatch(listChpGet3(subSubId, boardId, clsStd));

  };

  const handleChange2 = (idx, bool) => {
    let newExpandedArr = expandedArr;
    expandedArr.map((item, idx2) => {
      newExpandedArr[idx2] = item; // update
    });

    setExpandedArr(newExpandedArr);
  };

  useEffect(() => {
    console.log(TAG, "FIRST TIME LOADING... LESSONS SETUP");
    // default setup expanded arr
    if (unitLessons && unitLessons.length) {
      unitLessons.map((lsn, idx) => {
        expandedArr[idx] = "";

        // also update the current lesson to the first one
        if (idx == 0) {
          // dispatch(updateCurrLesson(lsn));
        }
      });
    }

    // update global flag
    if (!lessonsFirstTimeLoad) {
      setUnitLessons2(unitLessons);
      lessonsFirstTimeLoad = true;
    }
  }, []);

  {/* verify if board is own or official */ }
  if (parseInt(boardId) == Constants.Application.CUSTOM_BOARD_ID) {
    return (
      <CurriculumMapper
        currMap={currMap}
        currUnit={currUnit}
        currLesson={currLesson}
      />
    )
  }

  {/* regular board, check if chapters are already mapped */ }
  if (!unitLessons || !unitLessons.length) {
    return (
      <>
        <Alert2 severity="info">No Chapters found!</Alert2>
      </>
    );
  }

  console.log(TAG, 'LessonsSetup boardId ', boardId);

  return (
    <div>
      <LESSONSSETUP
        unitLessons={unitLessons}
        expanded={expanded}
        expandedArr={expandedArr}
        handleChange={(pnl, event, isExpanded, idx) =>
          handleChange(pnl, event, isExpanded, idx)
        }
        handleChange2={(idx, bool) => handleChange2(idx, bool)}
        defPanel={props.defPanel}
        onUpdateDefPanel={(panelx) => props.onUpdateDefPanel(panelx)}
        loadSubSubs={props.loadSubSubs}
      />

      {/*

      <Accordion2 expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary2
          expandIcon={<ExpandMoreIcon2 />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            backgroundColor: "#eee6ff"
          }}
        >

          <Typography2 sx={{ width: '33%', flexShrink: 0 }}>
            BLOCK - I
          </Typography2>
          <Typography2 sx={{ color: 'text.secondary' }}>Lesson 1 Title - 5 days</Typography2>
        </AccordionSummary2>
        <AccordionDetails2>

          
          <div style={{
            paddingTop: 10,
            paddingBottom: 10
          }}>
            <Typography
              variant="h5">Lesson Plans</Typography>
            
          </div>

          <LessonPlans />


        </AccordionDetails2>
      </Accordion2>
      <Accordion2 expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary2
          expandIcon={<ExpandMoreIcon2 />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          sx={{
            backgroundColor: "#eee6ff"
          }}
        >
          <Typography2 sx={{ width: '33%', flexShrink: 0 }}>
            BLOCK - II
          </Typography2>
          <Typography2 sx={{ color: 'text.secondary' }}>
            Lesson 2 Title
          </Typography2>
        </AccordionSummary2>
        <AccordionDetails2>
          <Typography2>
            Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus,
            varius pulvinar diam eros in elit. Pellentesque convallis laoreet
            laoreet.
          </Typography2>
        </AccordionDetails2>
      </Accordion2>
      <Accordion2 expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary2
          expandIcon={<ExpandMoreIcon2 />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
          sx={{
            backgroundColor: "#eee6ff"
          }}
        >
          <Typography2 sx={{ width: '33%', flexShrink: 0 }}>
            BLOCK - III
          </Typography2>
          <Typography2 sx={{ color: 'text.secondary' }}>
            Lesson 3 Title
          </Typography2>
        </AccordionSummary2>
        <AccordionDetails2>
          <Typography2>
            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
            amet egestas eros, vitae egestas augue. Duis vel est augue.
          </Typography2>
        </AccordionDetails2>
      </Accordion2>
      <Accordion2 expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary2
          expandIcon={<ExpandMoreIcon2 />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
          sx={{
            backgroundColor: "#eee6ff"
          }}
        >
          <Typography2 sx={{ width: '33%', flexShrink: 0 }}>
            BLOCK - IV
          </Typography2>
        </AccordionSummary2>
        <AccordionDetails2>
          <Typography2>
            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
            amet egestas eros, vitae egestas augue. Duis vel est augue.
          </Typography2>
        </AccordionDetails2>
      </Accordion2>
        */}
    </div>
  );
}

function PreviewImageDialog(props) {
  const { imageUrl } = props;

  // Create new plugin instance
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          CurrentScale,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          // ZoomIn,
          // ZoomOut,
        } = slots;
        return (
          <div
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            {/* <div style={{ padding: '0px 2px' }}>
                        <ZoomOut>
                            {(props) => (
                                <button
                                    style={{
                                        backgroundColor: '#357edd',
                                        border: 'none',
                                        borderRadius: '4px',
                                        color: '#ffffff',
                                        cursor: 'pointer',
                                        padding: '8px',
                                    }}
                                    onClick={props.onClick}
                                >
                                    Zoom out
                                </button>
                            )}
                        </ZoomOut>
                    </div>
                    <div style={{ padding: '0px 2px' }}>
                        <CurrentScale>{(props) => <span>{`${Math.round(props.scale * 100)}%`}</span>}</CurrentScale>
                    </div>
                    <div style={{ padding: '0px 2px' }}>
                        <ZoomIn>
                            {(props) => (
                                <button
                                    style={{
                                        backgroundColor: '#357edd',
                                        border: 'none',
                                        borderRadius: '4px',
                                        color: '#ffffff',
                                        cursor: 'pointer',
                                        padding: '8px',
                                    }}
                                    onClick={props.onClick}
                                >
                                    Zoom in
                                </button>
                            )}
                        </ZoomIn>
                    </div> */}
            <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
              <GoToPreviousPage>
                {(props) => (
                  <button
                    style={{
                      backgroundColor: props.isDisabled ? "#96ccff" : "#357edd",
                      border: "none",
                      borderRadius: "4px",
                      color: "#ffffff",
                      cursor: props.isDisabled ? "not-allowed" : "pointer",
                      padding: "8px",
                    }}
                    disabled={props.isDisabled}
                    onClick={props.onClick}
                  >
                    Previous page
                  </button>
                )}
              </GoToPreviousPage>
            </div>
            <div style={{ padding: "0px 2px", width: "4rem" }}>
              <CurrentPageInput />
            </div>
            <div style={{ padding: "0px 2px" }}>
              / <NumberOfPages />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <GoToNextPage>
                {(props) => (
                  <button
                    style={{
                      backgroundColor: props.isDisabled ? "#96ccff" : "#357edd",
                      border: "none",
                      borderRadius: "4px",
                      color: "#ffffff",
                      cursor: props.isDisabled ? "not-allowed" : "pointer",
                      padding: "8px",
                    }}
                    disabled={props.isDisabled}
                    onClick={props.onClick}
                  >
                    Next page
                  </button>
                )}
              </GoToNextPage>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const renderPage: RenderPage = (props: RenderPageProps) => (
    <div
      style={{
        width: "50%",
        height: "50%",
      }}
    >
      {props.canvasLayer.children}
      {props.width}
      {/* <div
            style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '50%',
            }}
        >
            <div
                style={{
                    color: 'rgba(0, 0, 0, 0.2)',
                    fontSize: `${8 * props.scale}rem`,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    transform: 'rotate(-45deg)',
                    userSelect: 'none',
                }}
            >
                Draft
            </div>
        </div> */}
      {props.annotationLayer.children}
      {props.textLayer.children}
    </div>
  );

  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
      // defaultTabs[0], // Bookmarks tab
      defaultTabs[0], // Thumbnails tab
    ],
  });

  const [open, setOpen] = React.useState(false);
  const [ImageDownloadUrls, setImageDownloadUrls] = useState(
    imageUrl ? imageUrl : " "
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ marginLeft: 5 }}>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        style={{ borderRadius: 50 }}
      >
        <ImageOutlinedIcon />
      </Button>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title">{"Guide Preview"}</DialogTitle>
          <DialogContent style={{ scrollbarWidth: "thin" }}>
            <div style={{ height: "750px", width: "100%" }}>
              {imageUrl ? (
                <Viewer
                  fileUrl={imageUrl}
                  plugins={[
                    // Register plugins
                    defaultLayoutPluginInstance,
                  ]}
                  // height={'100%'}
                  defaultScale={SpecialZoomLevel.PageFit}
                />
              ) : (
                // <img
                //   src={imageUrl}
                //   alt="preview Image"
                //   width="100px"
                //   style={{
                //     alignSelf: "center",
                //     display: "flex",
                //     width: "100%",
                //     width: "100%",
                //   }}
                // />
                <Alert severity="warning">No preview image available!</Alert>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

function TemporaryDrawer(props) {
  const classes = useStyles3();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const anc = "right";

  return (
    <div
      style={{
        marginLeft: 10,
      }}
    >
      {/* {['left', 'right', 'top', 'bottom'].map((anchor) => ( */}
      {/* {['right'].map((anchor) => ( */}
      <React.Fragment key={anc}>
        <Button
          size="small"
          onClick={toggleDrawer(anc, true)}
          variant="outlined"
          color="primary"
        >
          QUIZ
        </Button>
        {/* <Chip
          label="Quiz"
          clickable
          color="primary"
          onClick={toggleDrawer(anc, true)}
        /> */}
        <Drawer
          anchor={anc}
          open={state[anc]}
          onClose={toggleDrawer(anc, false)}
        >
          {/* {list(anc)} */}
          <QuizPlannerDrawer
            lsnPlanId={props.lsnPlanId}
            subId={props.subId}
            subSubId={props.subSubId}
          />
        </Drawer>
      </React.Fragment>
      {/* ))} */}
    </div>
  );
}

function TemporaryDrawer1(props) {
  const classes = useStyles3();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const anc = "right";

  return (
    <div
      style={{
        marginLeft: 10,
      }}
    >
      {/* {['left', 'right', 'top', 'bottom'].map((anchor) => ( */}
      {/* {['right'].map((anchor) => ( */}
      <React.Fragment key={anc}>
        <Button
          size="small"
          onClick={toggleDrawer(anc, true)}
          variant="outlined"
          color="primary"
        >
          MEDIA
        </Button>
        {/* <Chip
          label="Quiz"
          clickable
          color="primary"
          onClick={toggleDrawer(anc, true)}
        /> */}
        <Drawer
          anchor={anc}
          open={state[anc]}
          onClose={toggleDrawer(anc, false)}
        >
          {/* {list(anc)} */}
          <MediaPlannerDrawer
            lsnPlanId={props.lsnPlanId}
            subId={props.subId}
            subSubId={props.subSubId}
          />
        </Drawer>
      </React.Fragment>
      {/* ))} */}
    </div>
  );
}

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

function UnitBreadcrumbs() {
  const currMap = useSelector((state) => state.currMap.currMap);
  const currUnit = useSelector((state) => state.currUnit.currUnit);
  const unitTitle = currUnit != null ? currUnit.unit_title_text : "";
  const title = currMap != null ? currMap.map_title_text : "";

  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs2 aria-label="breadcrumb">
        {/* <Link2 underline="hover" color="inherit" href="/">
          CBSE
        </Link2>
        <Link2
          underline="hover"
          color="inherit"
          href="/material-ui/getting-started/installation/"
        >
          Maths
        </Link2> */}
        <Link2
          underline="hover"
          color="inherit"
          href="javascript:void(0)"
          onClick={() => {
            window.history.back();
          }}
        >
          {title}
        </Link2>
        <Typography2 color="text.primary">{unitTitle}</Typography2>
      </Breadcrumbs2>
    </div>
  );
}

const CustomizedBreadcrumbs = (props) => {
  const { unitNumber, unitName, lessonName } = props;
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <StyledBreadcrumb
        component="a"
        href="#"
        label={`${unitNumber} ${unitName}`}
        icon={<HomeIcon fontSize="small" />}
        onClick={handleClick}
      />
      <StyledBreadcrumb
        component="a"
        href="#"
        label={lessonName}
        onClick={handleClick}
      />
      <StyledBreadcrumb
        label="Lesson Plans"
        deleteIcon={<ExpandMoreIcon />}
        onClick={handleClick}
        onDelete={handleClick}
      />
    </Breadcrumbs>
  );
};

const MyCalendar = (props) => {
  return (
    <div>
      <Calendar
        localizer={localizer}
        events={myEventsList}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        eventPropGetter={(event, start, end, isSelected) => {
          console.log("event id " + event.id);
          const id = parseInt(event.id);
          let backgroundColor = "#FF9AA2";
          if ((id + 1) % 2 == 0) {
            backgroundColor = "#FFDAC1"; //'#FFB7B2'
          } else if ((id + 1) % 5 == 0) {
            backgroundColor = "#B5EAD7";
          }
          var style = {
            backgroundColor: backgroundColor,
            borderRadius: "0px",
            opacity: 0.8,
            color: "black",
            border: "0px",
            display: "block",
          };
          return {
            style: style,
          };
        }}
      />
    </div>
  );
};
// const useStyles = makeStyles({
//   root: {
//     flexGrow: 1,
//   },
// });

function MyDateRangePickerCalendar() {
  const today = new Date();
  let last = today;
  last.setDate(today.getDate() + 12);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(last);
  const [focus, setFocus] = useState(START_DATE);
  const handleFocusChange = (newFocus) => {
    setFocus(newFocus || START_DATE);
  };
  return (
    <div>
      {/*       
      <p>Selected start date: {startDate ? format(startDate, 'dd MMM yyyy', { locale: enGB }) : 'none'}.</p>
      <p>Selected end date: {endDate ? format(endDate, 'dd MMM yyyy', { locale: enGB }) : 'none'}.</p>
      <p>Currently selecting: {focus}.</p> */}

      <DateRangePickerCalendar
        startDate={startDate}
        endDate={endDate}
        focus={focus}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        onFocusChange={handleFocusChange}
        locale={enGB}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // justifyContent: 'center',
    flexWrap: "wrap",
    "& > *": {
      // margin: theme.spacing(0.5),
    },
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  shape: {
    backgroundColor: theme.palette.primary.main,
    width: 20,
    height: 20,
  },
  shape2: {
    backgroundColor: theme.palette.secondary.dark,
    width: 20,
    height: 20,
  },
  shape3: {
    backgroundColor: theme.palette.primary.main,
    width: 5,
    height: 20,
  },
  shape4: {
    backgroundColor: theme.palette.secondary.dark,
    width: 5,
    height: 20,
  },
  shapeCircle: {
    borderRadius: "50%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        width: "100%",
      }}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

const MainTabs = (props) => {
  const [openlesDialog, setOpenLesDialog] = useState(false);
  //add Lesson values
  const [lessName, setlessName] = useState("");
  const [lessDesc, setLessDesc] = useState("");
  const [lessMins, setLessMins] = useState(0);
  //Edit Lesson values
  const [lessChap, setLessChap] = useState("");
  const [lessTop, setLessTop] = useState("");

  const [openDialog, setOpenDialog] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(null);
  const [openSnack, setOpenSnack] = React.useState(false);

  const [lessonSnackOpen, setLessonSnackOpen] = useState(false);
  const currMap = useSelector((state) => state.currMap.currMap);
  const currLesson = useSelector((state) => state.currLesson.currLesson);
  const currUnit = useSelector((state) => state.currUnit.currUnit);

  const [instType2, setInstType2] = useState("");
  const [boardType2, setBoardType2] = useState("");
  const [standardType2, setStandardType2] = useState("");
  const [subjectType2, setSubjectType2] = useState("");
  const [subjectSubType2, setSubjectSubType2] = useState("");

  const subSubDetailsGet = useSelector((state) => state.subSubDetails);
  const { loadingsubSub, subSubDetails, errorsubSub } = subSubDetailsGet;

  const lessNameE = currLesson ? currLesson.name_text : "";
  const lessDescE = currLesson ? currLesson.name_less : "";
  const lessDaysE = 0; //currLesson ? currLesson.days_count : "";
  const lessChapE = currLesson ? currLesson.chapter_id : 0;
  const [currUnitName, setCurrUnitName] = useState((currUnit) ? (currUnit.unit_title_text) : "Select Unit");
  const [selUnit, setSelUnit] = useState(null);
  const unitMapDetailsGet = useSelector((state) => state.unitMap);
  const { loadingUnit, UnitMap, errorMap } = unitMapDetailsGet;

  const { currMap2 } = props;

  const loadSubSubs = () => {
    // const currMap2 = useSelector((state) => state.currMap.currMap);
    // const state2 = store.getState()
    // const currMap2 = state2.currMap.currMap;
    console.log(
      "MainTabs.js MainTabs loadSubSubs: currMap " + JSON.stringify(currMap2)
    );
    let value = 0;

    if (currMap2) value = currMap2.subject_id;

    if (value != 0) dispatch(listSubSubGet(value));
  };

  const units = unitMapDetailsGet.UnitMap;

  useEffect(() => {
    loadSubSubs();
  }, []);

  const defDate = new Date();
  defDate.setMinutes(1);
  defDate.setSeconds(0);

  const dispatch = useDispatch();
  const classes = useStyles();

  const chpDetailsGet = useSelector((state) => state.chpDetails);
  const { loadingChap, chapDetails, errorChp } = chpDetailsGet;

  const handleOpenEdit = () => {
    setOpenDialog(true);
  };

  const handleCloseEdit = () => {
    setOpenDialog(false);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleSubType2Change = (event) => {
    console.log("called handleSubChange");
    console.log(`new subject ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setSubjectType2(value);
    dispatch(listSubSubGet(value));

    // new vars from map
    let instType3 = currMap.institute_type_id;
    let boardType3 = currMap.institute_board_id;
    let stdType3 = currMap.classroom_std;

    // dispatch(listCurriculuFilterGet(instType, boardType2, standardType2, value))
  };

  // changing sub subject
  const handleSubSubType2Change = (event) => {
    console.log("called handleSubSubChange");
    console.log(`new sub subject ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setSubjectSubType2(value);

    // get maps
    // dispatch(listCurriculuFilterGet(instType2, boardType2, standardType2, value))

    // new vars from map
    let instType3 = currMap.institute_type_id;
    let boardType3 = currMap.institute_board_id;
    let stdType3 = currMap.classroom_std;
    let subType3 = currMap.subject_id;
    let subSubType3 = value;

    // get chapters
    dispatch(listChpGet3(subSubType3, boardType3, stdType3));
  };

  const handleTopic = (event) => {
    const { name, value } = event.target;

    if (value) {
      setLessTop(value);
      dispatch(listSubTopGet(value));
    }
  }

  const handleChapter = (event) => {
    const { name, value } = event.target;

    if (value) {
      setLessChap(value);
      dispatch(listTopGet(value));
      // fetch all subtopics also
      // dispatch(listChapSubTopGet(value));
    }
  };

  const handleLessonSnackClose = () => {
    setLessonSnackOpen(false);
  };

  const handleAddLessonClose = () => {
    setOpenLesDialog(false);
  };

  const handleLessTitleChange = (event) => {
    const { name, value } = event.target;
    setlessName(value);
  };

  const handleLesDescChange = (event) => {
    const { name, value } = event.target;
    setLessDesc(value);
  };

  const handleLesMinsChange = (event) => {
    const { name, value } = event.target;
    setLessMins(value);
  };

  const handleLessNameEChange = (event) => {
    const { name, value } = event.target;

    // update currlesson
    let currLessonE = currLesson;
    currLessonE.name_text = value;

    dispatch(updateCurrLesson(currLessonE));
  };

  const handleLessDaysEChange = (event) => {
    const { name, value } = event.target;

    // update currlesson
    let currLessonE = currLesson;
    currLessonE.days_count = value;

    dispatch(updateCurrLesson(currLessonE));
  };

  const handleLessTitleEChange = (event) => {
    const { name, value } = event.target;

    let currLessonE = currLesson;
    currLessonE.name_less = value;

    dispatch(updateCurrLesson(currLessonE));
  };

  const handleLessChapEChange = (event) => {
    const { name, value } = event.target;

    console.log(`chapter change ${value}`);

    let currLessonE = currLesson;
    currLessonE.chapter_id = value;

    dispatch(updateCurrLesson(currLessonE));
  };

  const handleAddLessonOpen = () => {
    // reset - clear
    setSubjectSubType2('')
    setLessChap('');
    setlessName('');

    // open dialog
    setOpenLesDialog(true);
    loadSubSubs();
  };

  const postAddNewLesson = async () => {
    const unitId = currUnit ? currUnit.id : 0;

    if (unitId > 0) {
      const dataObj = {
        map_unit: unitId,
        name_text: lessName,
        name_less: lessName,
        minutes_num: lessMins,
        // minutes_num: 0,
        subject_sub_id: subjectSubType2,
        sub_subject_id: subjectSubType2,
        chapter_id: lessChap,
      };

      console.log(
        "UnitList.js MainTabs postAddNewLesson: dataObj " +
        JSON.stringify(dataObj)
      );

      const resp = await MapUnitAPI.postAddLesson(dataObj);
      console.log(
        "UnitList.js MainTabs postAddNewLesson: resp at post add new lesson",
        resp
      );

      handleAddLessonClose();

      // show snackbar
      setLessonSnackOpen(true);

      // load lessons
      dispatch(getUnitLessons(currUnit.id));
    }
  };

  const handleEditLesson = async () => {
    const dataObj = {
      map_unit: currUnit.id,
      name_text: currLesson.name_text,
      name_less: currLesson.name_less,
      minutes_num: currLesson.minutes_num,
      chapter_id: currLesson.chapter_id,
    };

    console.log(`post edit lesson body params ${JSON.stringify(dataObj)}`);

    const resp = await MapUnitAPI.postUpdateLesson(currLesson.id, dataObj);
    console.log("resp at post add new lesson", resp);
    handleCloseEdit();

    // load lessons
    dispatch(getUnitLessons(currUnit.id));
  };


  const handleChange = async (event, child, value) => {
    if (child && child.props && event && event.target) {
      // get the index by checking the name
      let index = -1;

      // console.log(`change unit got event ${JSON.stringify(event.target)}`)
      // console.log(`change unit got index ${JSON.stringify(child.props)}`)

      for (let i = 0; i < UnitMap.data.Units.length; i++) {
        const unt = UnitMap.data.Units[i];
        if (unt.unit_title_text == event.target.value) {
          index = i;
          break;
        }
      }

      if (index > -1) {
        setCurrUnitName(event.target.value);
        console.log(`we got index ${index}`);

        await new Promise((resolve, reject) => {
          // get current unit by index
          setSelUnit(UnitMap.data.Units[index]);
          resolve();
        });

        const currUnit = UnitMap.data.Units[index];
        const currUnitId = currUnit.id;
        // also update current unit
        await new Promise((resolve, reject) => {
          dispatch(updateCurrUnit(currUnit));
          console.log(`new currUnit id ${currUnitId}`);
          resolve();
        });

        // load new lessons
        dispatch(getUnitLessons(currUnitId));

        // take first lesson

        // load lesson plans
      }
    }
  };


  console.log("UnitList.js MainTabs currUnit " + JSON.stringify(currUnit));

  if (!currUnit) {
    // return <p>Please select a unit!</p>;
  }

  let currUnitName2 = (currUnit) ? currUnit.unit_title_text : 'Select Unit';

  return (
    <>
      <div
        style={{
          display: "flex",
          // flexDirection: 'column',
          justifyContent: "space-between",
        }}
      >

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <h2>
            {(currUnit) ? 'UNIT - ' + currUnit.unit_number : ''}{" "}
            <span style={{ color: "grey" }}>{(currUnit) ? currUnit.unit_title_text : ''}</span>
          </h2>

          <div style={{ width: 20 }}></div>

          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Choose Unit</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // defaultValue={currUnitName}
                value={currUnitName2}
                onChange={(event, index, value) => handleChange(event, index, value)}
                label="Units"
              >
                <MenuItem value="Select Unit">
                  <em>Select Unit</em>
                </MenuItem>

                {/*               
    <MenuItem value={20}>Twenty</MenuItem>
    <MenuItem value={30}>Thirty</MenuItem> */}

                {units && units.data && units.data.Units && units.data.Units.length ? (
                  units.data.Units.map((unit, index) => {
                    return (
                      <MenuItem value={unit.unit_title_text}>
                        {unit.unit_title_text}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem value={-1}>No Units</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>

          {/* <Button2 mode="contained" size="small">Add Lesson</Button2> */}

          {currUnit != null ?
            <EditUnitDialog />
            :
            <></>
          }

          <div style={{ width: 20 }}></div>
          <AddUnitDialog />

          {currMap && currUnit && currMap.institute_board_id !== Constants.Application.CUSTOM_BOARD_ID ?
            <>
              <div style={{ width: 20 }}></div>
              <Button
                variant="contained"
                mode="contained"
                color="primary"
                style={{
                  // alignSelf: "flex-end",
                  // display: "flex",
                  // marginBottom: 20,
                  // marginLeft: 10,
                  // justifyContent: "flex-end",
                }}
                size="small"
                onClick={handleAddLessonOpen}
                startIcon={<AddIcon />}
              >
                Add Chapter
              </Button>
            </>
            :
            <></>
          }


          {currUnit != null ?
            <>
              {/* add Lesson DIALOG */}
              <Dialog
                fullWidth
                open={openlesDialog}
                onClose={handleAddLessonClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  Add Chapter for UNIT - {currUnit.unit_title_text}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Chapter is a list of lesson plans to be taught
                  </DialogContentText>

                  {/* select sub subject */}

                  <Grid
                    container
                    item
                    md={12}
                    lg={12}
                    sm={12}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                  >
                    <Grid container item md={8} lg={8} xs={12} sm={12}>
                      {" "}
                      <div
                        style={{
                          width: "20%",
                          paddingRight: 10,
                        }}
                      >
                        {subSubDetails ? (
                          <div style={{ display: "flex" }}>
                            <EditIcon style={{ height: 16 }} />
                            <FormControl className={classes.formControl}>
                              <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                              >
                                Sub Subject
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={subjectSubType2}
                                onChange={handleSubSubType2Change}
                                label="Sub Subject"
                                style={{ fontSize: 13, color: "#464E5F" }}
                              >
                                {loadingsubSub && subSubDetails ? (
                                  <MenuItem
                                    key={0}
                                    value="Please Select Board"
                                    style={{
                                      fontSize: 13,
                                      color: "#464E5F",
                                      fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                  >
                                    {" "}
                                    Please Select Sub Subject{" "}
                                  </MenuItem>
                                ) : subSubDetails &&
                                  subSubDetails.data &&
                                  subSubDetails.data.length != 0 ? (
                                  subSubDetails.data.map((list, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={list.subject_sub_id}
                                        style={{
                                          fontSize: 13,
                                          color: "#464E5F",
                                          fontFamily:
                                            "Poppins, Helvetica, sans-serif",
                                        }}
                                      >
                                        {list.subject_sub_name}
                                      </MenuItem>
                                    );
                                  })
                                ) : (
                                  <MenuItem
                                    style={{
                                      fontSize: 13,
                                      color: "#464E5F",
                                      fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                  >
                                    No data found!
                                  </MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        ) : (
                          <div style={{ display: "flex" }}>
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                              >
                                Sub Subject
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={subjectSubType2}
                                onChange={handleSubSubType2Change}
                                label="Sub Subject"
                                style={{ fontSize: 13, color: "#464E5F" }}
                              >
                                <MenuItem
                                  style={{
                                    fontSize: 13,
                                    color: "#464E5F",
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                  }}
                                >
                                  No data found!{" "}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid container item md={4} lg={4} xs={12} sm={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          flexDirection: "row",
                          // width: "20%",
                          // paddingRight: 10
                        }}
                      >
                        <EditIcon style={{ height: 16 }} />

                        <TextField
                          autoFocus
                          // defaultValue={unitTitleEVar}
                          onChange={handleLesMinsChange}
                          margin="dense"
                          id="name"
                          label="Days"
                          type="number"
                          fullWidth
                          style={{
                            marginLeft: 10,
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <EditIcon style={{ height: 16 }} />
                    <FormControl
                      required
                      className={classes.formControl}
                      style={{ overflowY: "initial", scrollbarWidth: "thin" }}
                    >
                      <InputLabel id="demo-simple-select-required-label">
                        Chapter
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        value={lessChap}
                        onChange={handleChapter}
                        className={classes.selectEmpty}
                      >
                        {loadingChap && chpDetailsGet ? (
                          console.log("loading trueeee")
                        ) : chapDetails.data && chapDetails.data.length != 0 ? (
                          chapDetails.data.map((list, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={list.chapter_id}
                                style={{
                                  fontSize: 13,
                                  color: "#464E5F",
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                                onClick={() => {
                                  // setChapterName(list.chapter_title);
                                  console.log(
                                    "UnitList.js selected chapter " +
                                    list.chapter_title
                                  );
                                  setlessName(list.chapter_title);
                                }}
                              >
                                {list.chapter_title}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            No Chapters for this subject..
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <EditIcon style={{ height: 16 }} />

                    <TextField
                      autoFocus
                      // defaultValue={unitTitleEVar}
                      value={lessName}
                      onChange={handleLessTitleChange}
                      margin="dense"
                      id="name"
                      label="Title"
                      type="text"
                      fullWidth
                      style={{
                        marginLeft: 10,
                      }}
                    />
                  </div>

                  <div
                    style={{
                      display: "none",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <EditIcon style={{ height: 16 }} />

                    <TextField
                      autoFocus
                      // defaultValue={unitTitleEVar}
                      onChange={handleLesDescChange}
                      margin="dense"
                      id="name"
                      label="Description"
                      type="text"
                      fullWidth
                      style={{
                        marginLeft: 10,
                      }}
                    />
                  </div>

                  {/* <div
              style={{
                // display: "",
                justifyContent: "space-evenly",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <EditIcon style={{ height: 16 }} />

              <TextField
                autoFocus
                // defaultValue={unitTitleEVar}
                onChange={handleLesMinsChange}
                margin="dense"
                id="name"
                label="Days"
                type="number"
                fullWidth
                style={{
                  marginLeft: 10,
                }}
              />
            </div> */}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAddLessonClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={postAddNewLesson} color="primary">
                    Save
                  </Button>
                  {/* <Button onClick={handleClose} color="warning">
            Delete
          </Button> */}
                </DialogActions>
              </Dialog>

              <Snackbar
                open={lessonSnackOpen}
                autoHideDuration={6000}
                onClose={handleLessonSnackClose}
              >
                <Alert onClose={handleLessonSnackClose} severity="success">
                  You have successfully added a chapter!
                </Alert>
              </Snackbar>
            </>
            :
            <></>
          }
        </div>
      </div>

      <div style={{ margin: 10 }}></div>
      {currUnit != null ?
        <>
          <UnitBreadcrumbs />
          <div style={{ marginTop: 10 }}></div>

          <Divider2 />

          <div style={{ marginTop: 10 }}></div>
          <LessonsSetup
            defPanel={props.defPanel}
            onUpdateDefPanel={(panelx) => props.onUpdateDefPanel(panelx)}
            loadSubSubs={loadSubSubs}
          />
        </>
        :
        <>
          <Alert2 severity="warning">No Unit selected — choose one from above!</Alert2>
        </>
      }
    </>
  );
};

const UnitLeftCard = ({
  lessonData,
  getLesData,
  UnitMap,
  unitLessons,
  setUnitData,
  defPanel,
  onUpdateDefPanel,
  product,
  params,
  ...rest
}) => {
  const unitMapDetailsGet = useSelector((state) => state.unitMap);

  const units = unitMapDetailsGet.UnitMap;
  const currUnit = useSelector((state) => state.currUnit.currUnit);

  console.log(`got unit lessons in left card ${JSON.stringify(unitLessons)}`);

  const defImg =
    "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/fa/6926005ea411e490ff8d4c5d4ff426/chemistry_logo.png?auto=format%2Ccompress&dpr=1";
  const classes = useStyles();
  const [age, setAge] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openAddUnit, setOpenAddUnit] = useState(false);
  const [addUnitNumber, setAddUnitNumber] = useState(null);
  const [addUnitTitle, setAddUnitTitle] = useState(null);
  const [addUnitLess, setAddUnitLess] = useState(null);
  const [addunitDays, setAddUnitDays] = useState(null);
  const [addUnitTags, setAddUnitTags] = useState(null);
  const [addUnitColor, setAddUnitColor] = useState("#000");
  const [currUnitName, setCurrUnitName] = useState("Select Unit");
  const [selUnit, setSelUnit] = useState(null);
  const [unitSnackOpen, setUnitSnackOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [ImageDownloadUrls, setImageDownloadUrls] = useState(
    isEdit
      ? currUnit.cover_image
      : "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/fa/6926005ea411e490ff8d4c5d4ff426/chemistry_logo.png?auto=format%2Ccompress&dpr=1"
  );
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [openSnack, setOpenSnack] = React.useState(false);

  const [showUnitDelete, setShowUnitDelete] = useState(false);

  const handleShowUnitDelete = (bool) => {
    setShowUnitDelete(bool);
  };

  console.log(
    "UnitList.js UnitLeftCard: ImageDownloadUrls " + ImageDownloadUrls
  );
  console.log("UnitList.js UnitLeftCard: currUnit " + JSON.stringify(currUnit));

  const handleUnitSnackClose = () => {
    setUnitSnackOpen(false);
  };

  const handleUploadImageSuccess = async (filename) => {
    const downloadUrl = await firebase
      .storage()
      .ref("ebook")
      .child(filename)
      .getDownloadURL();

    console.log("image download url", downloadUrl);
    setImageDownloadUrls(downloadUrl);
    if (downloadUrl) {
      setProgress(0);
    }
  };

  const handleUploadStart = () => {
    setIsUploading(true);
    setProgress(0);
  };

  const handleProgressImage = (progress) => {
    setProgress((prevProgress) =>
      prevProgress >= 100 ? 0 : prevProgress + 10
    );
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
  };

  const handleChange = async (event, child, value) => {
    if (child && child.props && event && event.target) {
      // get the index by checking the name
      let index = -1;

      // console.log(`change unit got event ${JSON.stringify(event.target)}`)
      // console.log(`change unit got index ${JSON.stringify(child.props)}`)

      for (let i = 0; i < UnitMap.data.Units.length; i++) {
        const unt = UnitMap.data.Units[i];
        if (unt.unit_title_text == event.target.value) {
          index = i;
          break;
        }
      }

      if (index > -1) {
        setCurrUnitName(event.target.value);
        console.log(`we got index ${index}`);

        await new Promise((resolve, reject) => {
          // get current unit by index
          setSelUnit(UnitMap.data.Units[index]);
          resolve();
        });

        const currUnit = UnitMap.data.Units[index];
        const currUnitId = currUnit.id;
        // also update current unit
        await new Promise((resolve, reject) => {
          dispatch(updateCurrUnit(currUnit));
          console.log(`new currUnit id ${currUnitId}`);
          resolve();
        });

        // load new lessons
        dispatch(getUnitLessons(currUnitId));

        // take first lesson

        // load lesson plans
      }
    }
  };

  const handleChange2 = async (id, value) => {
    console.log("UnitList.js handleChange2: id and value ", id, value);
    if (value) {
      // get the index by checking the name
      let index = -1;

      // console.log(`change unit got event ${JSON.stringify(event.target)}`)
      // console.log(`change unit got index ${JSON.stringify(child.props)}`)

      for (let i = 0; i < UnitMap.data.Units.length; i++) {
        const unt = UnitMap.data.Units[i];
        if (unt.unit_title_text == value) {
          index = i;
          break;
        }
      }

      if (index > -1) {
        setCurrUnitName(value);
        console.log(`UnitList.js handleChange2: we got index ${index}`);

        await new Promise((resolve, reject) => {
          // get current unit by index
          setSelUnit(UnitMap.data.Units[index]);
          resolve();
        });

        const currUnit = UnitMap.data.Units[index];
        const currUnitId = currUnit.id;

        console.log(
          "UnitList.js handleChange2: currUnit " + JSON.stringify(currUnit)
        );
        // also update current unit
        await new Promise((resolve, reject) => {
          dispatch(updateCurrUnit(currUnit));
          console.log(`new currUnit id ${currUnitId}`);
          resolve();
        });

        // load new lessons
        dispatch(getUnitLessons(currUnitId));

        // take first lesson

        // load lesson plans
      }
    }
  };

  const currMap = useSelector((state) => state.currMap.currMap);
  const currMapId = useSelector((state) => state.currMapId.currMapId);
  // const mapId = currMapId != null ? currMapId : 0;
  const mapId = params.id;

  const dispatch = useDispatch();
  console.log(`lessons data 2 ${JSON.stringify(lessonData)}`);

  const sendUnitData = (units) => {
    setUnitData(units);
  };

  // const unitMapDetailsGet = useSelector(state => state.unitMap)
  // const { loadingUnit, UnitMap, errorMap } = unitMapDetailsGet

  // console.log("unit details", UnitMap)

  // useEffect(() => {
  //   dispatch(listUnitMapGet(1))
  //   return () => {
  //     //
  //   }

  // }, [])

  const updateLesData = (lesData) => {
    getLesData(lesData);
  };

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleAddunitOpen = () => {
    setOpenAddUnit(true);
    // make edit false
    setIsEdit(false);
    setImageDownloadUrls(""); // reset
  };

  const handleAddunitClose = () => {
    setOpenAddUnit(false);
  };

  const handleUnitNumChange = (event) => {
    const { value } = event.target;
    setAddUnitNumber(value);
  };

  const handleUnitTitle = (event) => {
    const { value } = event.target;
    setAddUnitTitle(value);
  };

  const handleUnitLess = (event) => {
    const { value } = event.target;
    setAddUnitLess(value);
  };

  const handleUnitDays = (event) => {
    const { value } = event.target;
    setAddUnitDays(value);
  };

  const handleUnitTags = (event) => {
    const { value } = event.target;
    setAddUnitTags(value);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  // delete unit dialog
  function DelUnitDialog() {
    const [open, setOpen] = React.useState(showUnitDelete);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
          Open alert dialog
        </Button> */}
        <Button onClick={handleClickOpen} color="warning">
          Delete
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Use Google's location service?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this unit? It cannot be reverted!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleDelete} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  // UPDATE CURRENT UNIT
  const handleUpdateUnit = async () => {
    if (
      // !currUnit.id ||
      !currUnit.unit_number ||
      !currUnit.unit_title_text
      // || !currUnit.lesson_count
      // || !currUnit.days_count
    ) {
      setOpenSnack(true);
      return;
    }

    let imgUrl = "";
    if (ImageDownloadUrls) imgUrl = ImageDownloadUrls;

    imgUrl =
      "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/fa/6926005ea411e490ff8d4c5d4ff426/chemistry_logo.png?auto=format%2Ccompress&dpr=1";

    const body = {
      curriculum_map_id: mapId,
      unit_title_text: currUnit.unit_title_text,
      unit_number: currUnit.unit_number,
      lesson_count: 0,
      days_count: 0,
      unit_color: 0,
      unit_tags: "#tag",
      cover_image: imgUrl,
    };

    console.log(`handleUpdateUnit called..  with body ${JSON.stringify(body)}`);

    const resp = await MapUnitAPI.postUpdateMapUnit(body, currUnit.id);
    console.log(`curriculum map unit update response ${JSON.stringify(resp)}`);
    console.log(`going to call units for map ${mapId}`);

    handleClose();

    // now refresh map units
    dispatch(listUnitMapGet(mapId));
  };

  const handleAddUnitSave = async () => {
    const body = {
      curriculum_map_id: mapId,
      unit_title_text: addUnitTitle,
      unit_number: addUnitNumber,
      cover_image: ImageDownloadUrls,
      lesson_count: 0,
      days_count: 0,
      unit_color: "#FFFFFF",
      unit_tags: "#tag",
    };

    console.log(`add unit body params ${JSON.stringify(body)}`);

    const resp = await MapUnitAPI.postSaveMapUnit(body);

    console.log("response at handleunitsave ==================", resp);
    handleAddunitClose(); // close dialog

    // open snackbar
    setUnitSnackOpen(true);

    // load all units
    dispatch(listUnitMapGet(mapId));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    if (!currUnit.id) {
      setOpenSnack(true);
      return;
    }

    // call delete api for unit
    const unitId = currUnit.id;

    const resp = await UNITAPI.postDeleteMapUnit(unitId);

    // close dialog
    setShowUnitDelete(false);

    // close edit unit dialog
    handleClose();

    // reload page

    // load all units
    dispatch(listUnitMapGet(mapId));
  };

  const unitOptions = (
    <Select
      labelId="demo-simple-select-outlined-label"
      id="demo-simple-select-outlined"
      value={currUnitName}
      onChange={(event, index, value) => handleChange(event, index, value)}
      label="Units"
    >
      <MenuItem value="Select Unit">
        <em>Select Unit</em>
      </MenuItem>

      {/*               
    <MenuItem value={20}>Twenty</MenuItem>
    <MenuItem value={30}>Thirty</MenuItem> */}

      {units && units.data && units.data.Units && units.data.Units.length ? (
        units.data.Units.map((unit, index) => {
          return (
            <MenuItem value={unit.unit_title_text}>
              {unit.unit_title_text}
            </MenuItem>
          );
        })
      ) : (
        <MenuItem value={-1}>No Units</MenuItem>
      )}
    </Select>
  );

  let mapTitle = currMap ? currMap.map_title_text : "";

  let unitNumEVar = currUnit != null ? currUnit.unit_number : 0;
  let unitTitleEVar = currUnit != null ? currUnit.unit_title_text : "";
  let unitLessonsEVar = currUnit != null ? currUnit.lesson_count : "";
  let unitDaysEVar = currUnit != null ? currUnit.days_count : "";
  let unitColorEVar = currUnit != null ? currUnit.unit_color : "";
  let unitTagsEVar = currUnit != null ? currUnit.unit_tags : "";
  let unitIdEVar = currUnit != null ? currUnit.id : 0;

  const [unitNumE, setUnitNumE] = React.useState(unitNumEVar);
  const [unitTitleE, setUnitTitleE] = React.useState(unitTitleEVar);
  const [unitLessonsE, setUnitLessonsE] = React.useState(unitLessonsEVar);
  const [unitDaysE, setUnitDaysE] = React.useState(unitDaysEVar);
  const [unitColorE, setUnitColorE] = React.useState(unitColorEVar);
  const [unitTagsE, setUnitTagsE] = React.useState(unitTagsEVar);
  const [unitIdE, setUnitIdE] = React.useState(unitIdEVar);
  const [checked, setChecked] = React.useState(false);

  console.log(`unitnume ${unitNumE}`);
  console.log(`unitnume var ${unitNumEVar}`);
  console.log(`unittitlee ${unitTitleEVar}`);

  /* EDIT UNIT FIELDS FUNCTIONS */

  const handleUnitNumEChange = (event) => {
    const { name, value } = event.target;
    setUnitNumE(value);
    unitNumEVar = value;

    // update store
    currUnit.unit_number = value;
    dispatch(updateCurrUnit(currUnit));
  };

  const handleUnitTitleEChange = (event) => {
    const { name, value } = event.target;
    setUnitTitleE(value);
    unitTitleEVar = value;

    // update store
    currUnit.unit_title_text = value;
    dispatch(updateCurrUnit(currUnit));
  };

  const handleUnitLessonsEChange = (event) => {
    const { name, value } = event.target;
    setUnitLessonsE(value);
    unitLessonsEVar = value;

    // update store
    currUnit.lesson_count = value;
    dispatch(updateCurrUnit(currUnit));
  };

  const handleUnitDaysEChange = (event) => {
    const { name, value } = event.target;

    setUnitDaysE(value);
    unitDaysEVar = value;

    // update store
    currUnit.days_count = value;
    dispatch(updateCurrUnit(currUnit));
  };

  const handleUnitTagsEChange = (event) => {
    const { name, value } = event.target;

    setUnitTagsE(value);
    unitTagsEVar = value;

    // update store
    currUnit.unit_tags = value;
    dispatch(updateCurrUnit(currUnit));
  };

  const handleClickOpen = (e, unit) => {
    e.stopPropagation();
    setOpen(true);

    // update image
    setImageDownloadUrls(unit.cover_image);
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
      style={{
        width: "100%",
      }}
      {...rest}
    >
      <CardContent style={{}}>
        <div style={{}}>
          {/* <FormControl variant="filled" className={classes.formControl}
            style={{
              width: '100%'
            }}>
            <InputLabel id="demo-simple-select-filled-label">UNIT CONTENT</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={age}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>

           */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <Chip label="COURSE SUMMARY" style={{ marginTop: 10 }} /> */}
            <Typography align="center" color="textPrimary" variant="body1">
              UNITS INDEX
            </Typography>

            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleAddunitOpen}
              startIcon={<AddIcon />}
            >
              Add Unit
            </Button>
            {/* <Chip
              label="ADD UNIT"
              style={{ marginTop: 10 }}
              onClick={handleAddunitOpen}
              clickable
              color="primary"
            /> */}
          </div>

          {/* UNITS IN THE SELECT BOX */}
          {/* <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">
              Units
            </InputLabel>

            {unitOptions}
          </FormControl> */}

          <div
            style={{
              marginTop: 10,
            }}
          ></div>
          {/* UNITS IN THE ACCORDION */}
          <UnitSetup
            units={units}
            lessonsData={unitLessons}
            sendUnit={(units) => {
              sendUnitData(units);
            }}
            UnitMap={UnitMap}
            lessonData={(lesData) => {
              updateLesData(lesData);
            }}
            handleChange={(id, val) => handleChange2(id, val)}
            defPanel={defPanel}
            onUpdateDefPanel={(panelx) => onUpdateDefPanel(panelx)}
            handleClickOpen={(e, unit) => handleClickOpen(e, unit)}
            setIsEdit={(flag) => setIsEdit(flag)}
          />
        </div>

        {/* 
        <Typography
          align="center"
          color="textPrimary"
          variant="body1"
        >
          Description
        </Typography>
         */}

        {/* ADD UNIT DIALOG AT LEFT CARD*/}
        <Dialog
          open={openAddUnit}
          onClose={handleAddunitClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            Add Unit for {mapTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please define the unit number in digits &amp; enter relevant title
            </DialogContentText>

            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <EditIcon style={{ height: 16 }} />

              <TextField
                autoFocus
                // defaultValue={unitNum}
                onChange={handleUnitNumChange}
                margin="dense"
                id="name"
                label="Unit Number"
                placeholder="Enter a number"
                type="number"
                InputProps={{ inputProps: { min: 1, max: 20 } }}
                min="1"
                max="20"
                style={{
                  marginLeft: 10,
                  width: "20%",
                }}
              />

              <TextField
                autoFocus
                // defaultValue={unitTitle}
                onChange={handleUnitTitle}
                margin="dense"
                id="name"
                label="Title"
                placeholder="Enter relevant title"
                type="text"
                fullWidth
                style={{
                  marginLeft: 10,
                }}
              />
            </div>

            <div
              style={{
                display: "none",
                flexDirection: "row",

                marginTop: 10,
              }}
            >
              <DateRangeIcon style={{ height: 16 }} />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 10,
                }}
              >
                <Typography variant="body2">Pacing</Typography>

                <Typography variant="duration">SUGGESTED DURATION</Typography>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div>
                    <TextField
                      autoFocus
                      // defaultValue={unitLessons}
                      onChange={handleUnitLess}
                      margin="dense"
                      id="lessons"
                      label="Lessons"
                      type="number"
                    />
                  </div>

                  <div style={{ marginLeft: 20 }}>
                    <TextField
                      autoFocus
                      // defaultValue={unitDays}
                      onChange={handleUnitDays}
                      margin="dense"
                      id="days"
                      label="Periods / Days"
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "none",
              }}
            >
              <TextField
                autoFocus
                // defaultValue={unitTags}
                onChange={handleUnitTags}
                margin="dense"
                id="tags"
                label="Tags"
                type="text"
                fullWidth
              />
            </div>

            <div
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                display: "none",
                marginTop: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: 0,
                  marginLeft: 10,
                  marginRight: 20,
                  width: "100%",
                }}
              >
                <label
                  style={{
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      cursor: "pointer",
                    }}
                  >
                    <AddBoxIcon2
                      style={{
                        color: "steelblue",
                      }}
                    />
                    <Typography2
                      variant="body1"
                      style={{
                        marginLeft: 10,
                        color: "#464E5F",
                      }}
                    >
                      Add Cover Image
                    </Typography2>
                    <FileUploader
                      hidden
                      accept="image/*"
                      storageRef={firebase.storage().ref("ebook")}
                      onUploadStart={handleUploadStart}
                      onUploadError={handleUploadError}
                      onUploadSuccess={handleUploadImageSuccess}
                      onProgress={handleProgressImage}
                    />
                  </div>
                </label>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginLeft: 20,
                  }}
                >
                  {progress ? <CircularProgress /> : <></>}
                </div>
              </div>

              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: 20,
                }}
              >
                {ImageDownloadUrls && (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        top: 5,
                        right: 5,
                        cursor: "pointer",
                        zIndex: 3,
                      }}
                    >
                      <label>
                        <EditIcon />
                        <FileUploader
                          hidden
                          accept="image/*"
                          storageRef={firebase.storage().ref("ebook")}
                          onUploadStart={handleUploadStart}
                          onUploadError={handleUploadError}
                          onUploadSuccess={handleUploadImageSuccess}
                          onProgress={handleProgressImage}
                        />
                      </label>
                    </div>
                    <img
                      src={ImageDownloadUrls}
                      alt="preview Image"
                      style={{
                        alignSelf: "flex-start",
                        display: "flex",
                        width: "100%",
                      }}
                    />
                    {/* {ImageDownloadUrls} */}
                  </>
                )}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddunitClose} color="primary">
              Cancel
            </Button>

            <Button onClick={handleAddUnitSave} color="primary">
              Save
            </Button>

            {/* <Button color="warning">Delete</Button> */}
          </DialogActions>
        </Dialog>

        <Snackbar
          open={unitSnackOpen}
          autoHideDuration={6000}
          onClose={handleUnitSnackClose}
        >
          <Alert onClose={handleUnitSnackClose} severity="success">
            You have successfully added a Unit!
          </Alert>
        </Snackbar>

        {/* EDIT UNIT DIALOG */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            {unitTitleEVar} [Edit]
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please define the unit number in digits &amp; enter relevant title
            </DialogContentText>

            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <EditIcon style={{ height: 16 }} />

              <TextField
                autoFocus
                defaultValue={unitNumEVar}
                onChange={handleUnitNumEChange}
                margin="dense"
                id="name"
                label="Number"
                placeholder="Enter a number"
                InputProps={{ inputProps: { min: 0, max: 10 } }}
                type="text"
                style={{
                  marginLeft: 10,
                }}
              />

              <TextField
                autoFocus
                defaultValue={unitTitleEVar}
                onChange={handleUnitTitleEChange}
                margin="dense"
                id="name"
                label="Title"
                placeholder="Enter a title"
                type="text"
                fullWidth
                style={{
                  marginLeft: 10,
                }}
              />
            </div>

            <div
              style={{
                display: "none",
                flexDirection: "row",

                marginTop: 10,
              }}
            >
              <DateRangeIcon style={{ height: 16 }} />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 10,
                }}
              >
                <Typography variant="body2">Pacing</Typography>

                <Typography variant="duration">SUGGESTED DURATION</Typography>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div>
                    <TextField
                      autoFocus
                      defaultValue={unitLessonsEVar}
                      onChange={handleUnitLessonsEChange}
                      margin="dense"
                      id="lessons"
                      label="Lessons"
                      type="number"
                    />
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    <TextField
                      autoFocus
                      defaultValue={unitDaysEVar}
                      onChange={handleUnitDaysEChange}
                      margin="dense"
                      id="days"
                      label="Periods / Days"
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "none",
              }}
            >
              <TextField
                autoFocus
                defaultValue={unitTagsEVar}
                onChange={handleUnitTagsEChange}
                margin="dense"
                id="tags"
                label="Tags"
                type="text"
                fullWidth
              />
            </div>

            {/* image */}

            <div
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                display: "none",
                marginTop: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: 0,
                  marginLeft: 10,
                  marginRight: 20,
                  width: "100%",
                }}
              >
                <label
                  style={{
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      cursor: "pointer",
                    }}
                  >
                    <AddBoxIcon2
                      style={{
                        color: "steelblue",
                      }}
                    />
                    <Typography2
                      variant="body1"
                      style={{
                        marginLeft: 10,
                        color: "#464E5F",
                      }}
                    >
                      Update Cover Image
                    </Typography2>
                    <FileUploader
                      hidden
                      accept="image/*"
                      storageRef={firebase.storage().ref("ebook")}
                      onUploadStart={handleUploadStart}
                      onUploadError={handleUploadError}
                      onUploadSuccess={handleUploadImageSuccess}
                      onProgress={handleProgressImage}
                    />
                  </div>
                </label>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginLeft: 20,
                  }}
                >
                  {progress ? <CircularProgress /> : <></>}
                </div>
              </div>

              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: 20,
                }}
              >
                {ImageDownloadUrls && (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        top: 5,
                        right: 5,
                        cursor: "pointer",
                        zIndex: 3,
                      }}
                    >
                      <label>
                        <EditIcon />
                        <FileUploader
                          hidden
                          accept="image/*"
                          storageRef={firebase.storage().ref("ebook")}
                          onUploadStart={handleUploadStart}
                          onUploadError={handleUploadError}
                          onUploadSuccess={handleUploadImageSuccess}
                          onProgress={handleProgressImage}
                        />
                      </label>
                    </div>
                    <img
                      src={ImageDownloadUrls}
                      alt="preview Image"
                      style={{
                        alignSelf: "flex-start",
                        display: "flex",
                        width: "100%",
                      }}
                    />
                    {/* {ImageDownloadUrls} */}
                  </>
                )}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <DelUnitDialog />
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleUpdateUnit} color="primary">
              Save
            </Button>
            {/* <Button onClick={handleClose} color="warning">
                  Delete
                </Button> */}
          </DialogActions>
        </Dialog>

        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity="warning">
            Please enter mandatory details!
          </Alert>
        </Snackbar>
      </CardContent>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />

      {/* <CenteredTabs /> */}

      {/*       
      <div
        style={{
          width: "100%",
        }}
      >
        <UnitplanBox
          lessonsData={unitLessons}
          sendUnit={(units) => {
            sendUnitData(units);
          }}
          UnitMap={UnitMap}
          lessonData={(lesData) => {
            updateLesData(lesData);
          }}
        />
      </div> */}
    </Card>
  );
};

const UnitList = (props) => {
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });

  const classes = useStyles1();
  const [unitData, setUnitData] = useState(null);
  const [lessonData, setlessonData] = useState(null);
  const currMap = useSelector((state) => state.currMap.currMap);
  const currUnit = useSelector((state) => state.currUnit.currUnit);

  const unitLessons = useSelector((state) => state.unitLessons.unitLessons);
  const lessonPlans = useSelector((state) => state.lessonPlans.lessonPlans);
  //  const mapId = (currMap != null) ? currMap.map_id : 0

  const dispatch = useDispatch();

  const unitMapDetailsGet = useSelector((state) => state.unitMap);
  const { loadingUnit, UnitMap, errorMap } = unitMapDetailsGet;
  const [currMap2, setCurrMap2] = useState(currMap);
  const [firstTime, setFirstTime] = useState(false);

  // default open accordion panel
  const [defPanel, setDefPanel] = useState("");

  const mapId = props.match.params.id;
  const userId = Cookie.get("kgtopg.guruculum.user.id");

  console.log("unit details", UnitMap);
  console.log(`lessons data ${JSON.stringify(unitLessons)}`);

  useEffect(() => {
    console.log("map id at unit List ", mapId);
    if (userId == 0) {
      window.scrollTo(0, 0);
      console.log("Settings.js Settings: useEffect unlisten completed");

      window.location.assign("/logout");
    }
    getCurrMap(mapId);
    // set current unit null first time
    dispatch(updateCurrUnit(null));

    // get lessons
    return () => {
      //
    };
  }, []);

  const getCurrMap = async (mapId) => {
    const currMap = await MapAPI.getMapDetails(mapId);

    await new Promise((resolve, reject) => {
      dispatch(updateCurrMap(currMap));
      setCurrMap2(currMap);
      console.log(`got currmap ${JSON.stringify(currMap)}`);
      resolve();
    });

    await new Promise((resolve, reject) => {
      dispatch(updateCurrMapId(mapId));
      resolve();
    });

    getUnitAndLessons();
  };

  const getUnitAndLessons = async () => {
    await new Promise((resolve, reject) => {
      dispatch(listUnitMapGet(mapId));
      resolve();
    });

    // update current unit to 1
    if (UnitMap && UnitMap.length > 0) {
      console.log(`trying to get lessons`);
      const currUnit = UnitMap[0];
      await new Promise((resolve, reject) => {
        dispatch(updateCurrUnit(currUnit));
        resolve();
      });

      // getUnitLessonsData()
    }

    console.log(`getunitandlessons listunitmapget()`);
  };

  /*

  const getUnitLessonsData = () => {
    const unitId = currUnit.id;
    console.log(`unitid lessons call ${unitId}`);

    const lessons = unitLessons;
    // const lessons = await MapUnitAPI.getUnitLessons(unitId)
    dispatch(getUnitLessons(unitId));

    if (
      lessons &&
      lessons.data &&
      lessons.data.data &&
      lessons.data.data.length
    ) {
      // set current lesson
      dispatch(updateCurrLesson(lessons.data.data[0]));
    }

    setlessonData(lessons);
    setFirstTime(true);
  };



  // set first unit as default current
  if (
    !firstTime &&
    UnitMap &&
    UnitMap.data &&
    UnitMap.data.Units &&
    UnitMap.data.Units.length
  ) {
    dispatch(updateCurrUnit(UnitMap.data.Units[0]));
  }

  */
  console.log(`firstTime ${firstTime}`);

  // also get first unit lessons
  if (!firstTime && currUnit) {
    console.log(`curr unit id ${currUnit.id}`);
    // getUnitLessonsData(currUnit.id);
  }

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const updateData = (data) => {
    setUnitData(data);
  };

  const updateLesDatatoState = (lesData) => {
    setlessonData(lesData);
  };

  const onUpdateDefPanel = (panelx) => {
    console.log("UnitList.js UnitList onUpdateDefPanel: panel " + panelx);
    setDefPanel(panelx);
  };

  return (
    <div className={classes.root}>
      <LeftCard open={false} />

      <main
        className={classes.content}
        style={{
          width: "100%",
        }}
      >
        <Container
          className={classes.container}
          style={{
            maxWidth: "100%",
            width: "100%",
          }}
        >
          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              maxWidth: "100%",
            }}
          >
            <Helmet>
              <title>Curriculum Maps | Guruculum</title>
            </Helmet>
            <Box
              sx={{
                backgroundColor: "background.default",
                minHeight: "100%",
                py: 3,
              }}
              style={{
                width: "100%",
                minWidth: "100%",
              }}
            >
              <Container
                style={{
                  maxWidth: "100%",
                }}
              >
                <UnitListToolbar />

                <Box
                  sx={{ pt: 3, mt: 5 }}
                  style={{
                    marginTop: 20,
                    paddingBottom: 20,
                    width: "100%",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    {loadingUnit && unitMapDetailsGet && UnitMap ? (
                      <></>
                    ) : (
                      <></>

                      // <UnitLeftCard
                      //   style={{ width: "29%" }}
                      //   setUnitData={(data) => {
                      //     updateData(data);
                      //   }}
                      //   UnitMap={UnitMap}
                      //   unitLessons={unitLessons}
                      //   getLesData={(lesData) => {
                      //     updateLesDatatoState(lesData);
                      //   }}
                      //   lessonData={lessonData}
                      //   params={props.match.params}
                      //   defPanel={defPanel}
                      //   onUpdateDefPanel={(panelx) => onUpdateDefPanel(panelx)}
                      //   onUpdateMap={(map) => setCurrMap2(map)}
                      // />

                    )}
                    <Card
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: "99%",
                      }}
                      style={{
                        width: "99%",
                        marginLeft: 10,
                      }}
                    >
                      <CardContent
                        style={{
                          width: "100%",
                        }}
                      >
                        <MainTabs
                          updatedUnitData={unitData}
                          UnitMap={UnitMap}
                          lessonData={lessonData}
                          defPanel={defPanel}
                          currMap={currMap}
                          currMap2={currMap2}
                          onUpdateDefPanel={(panelx) =>
                            onUpdateDefPanel(panelx)
                          }
                        />
                      </CardContent>
                    </Card>
                  </div>
                </Box>
              </Container>
            </Box>
          </Grid>
        </Container>
      </main>
    </div>
  );
};

export default UnitList;
