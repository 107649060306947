import { ADMIN_ROLES_REQUEST,  ADMIN_ROLES_SUCCESS,  ADMIN_ROLES_FAIL } from "../constants/adminRolesConstants";


function adminRolesReducer(state = { adminRoles: [] }, action) {
    switch (action.type) {
        case ADMIN_ROLES_REQUEST:
            return { loadingRoles: true, adminRoles: [], error: false }
        case ADMIN_ROLES_SUCCESS:
            return { loadingRoles: false, adminRoles: action.payload, error: false }
        case ADMIN_ROLES_FAIL:
            return { loadingRoles: false, adminRoles: [], error: action.payload }
        default:
            return state;
    }

}

export { adminRolesReducer }
