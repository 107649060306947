import React, { useEffect, useState } from "react";
import { useLocation, useParams, useHistory, Link } from "react-router-dom";

import { emphasize, withStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Chip from "@material-ui/core/Chip";
import Chip2 from "@mui/material/Chip";
import VisibilityIcon2 from '@mui/icons-material/Visibility';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Modal2 from '@mui/material/Modal';

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import DateRangeIcon from "@material-ui/icons/DateRange";
// import HTML5Backend from "react-dnd-html5-backend";
// import { DragDropContext } from "react-dnd";
import BigCalendar from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import { DateRangePickerCalendar, START_DATE } from "react-nice-dates";
import HomeIcon from "@material-ui/icons/Home";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Link2 from "@mui/material/Link";
import TextField2 from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import EditIcon2 from "@mui/icons-material/Edit";

import List2 from "@mui/material/List";
import ListItem2 from "@mui/material/ListItem";
import Divider2 from "@mui/material/Divider";
import ListItemText2 from "@mui/material/ListItemText";
import ListItemIcon2 from "@mui/material/ListItemIcon";
import ListItemAvatar2 from "@mui/material/ListItemAvatar";
import Avatar2 from "@mui/material/Avatar";
import Typography2 from "@mui/material/Typography";

import CardActionArea2 from "@mui/material/CardActionArea";

import Breadcrumbs2 from "@mui/material/Breadcrumbs";
import Switch from "@mui/material/Switch";
import UNITAPI from "../../../http/curriculummapunit";
// import Alert3 from '@mui/material/Alert';

// iconv
// import Iconv from 'iconv';
// import Buffer from 'buffer';
// import assert from 'assert';
// var Buffer = require('buffer').Buffer;
// var Iconv  = require('iconv').Iconv;
// var assert = require('assert');
import store from "../../../store";

import { updateCurrLessonPlan } from "../../../redux/actions/currPlanActions";
import "react-nice-dates/build/style.css";

import {
    Avatar,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Typography,
    TextField,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Box, Container } from "@material-ui/core";
// import UnitListResults from "../slots/UnitListResults";
import UnitListToolbar from "../slots/UnitListToolbar";
import customers from "../../../__mocks__/customers";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { Edit } from "react-feather";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import MapAPI from "../../../http/curriculummap";
import MapUnitAPI from "../../../http/curriculummapunit";
import { useDispatch, useSelector } from "react-redux";
import { listUnitMapGet, postNewUnit } from "../../../redux/actions/unitMapAction";
import { UnitMapReducer } from "../../../redux/reducers/unitMapReducer";
import { updateCurrMap } from "../../../redux/actions/currMapActions";
import { updateCurrUnit } from "../../../redux/actions/currUnitActions";
import { updateCurrLesson } from "../../../redux/actions/currLessonActions";
import { updateCurrMapId } from "../../../redux/actions/currMapIdActions";

import { getUnitLessons } from "../../../redux/actions/unitLessonsActions";
import {
    getLessonPlans,
    postNewLessonPlan,
} from "../../../redux/actions/lessonPlansActions";
import Constants from "../../../resource/Constants";
import ImportContactsOutlinedIcon from "@material-ui/icons/ImportContactsOutlined";
import LeftCard from "../slots/LeftCard";
import { Alert, AlertTitle } from "@material-ui/lab";
import Slider from "@material-ui/core/Slider";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ColorLensOutlinedIcon from "@material-ui/icons/ColorLensOutlined";
import ColorPicker from "../slots/ColorPicker";

import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import QuizPlannerDrawer from "../slots/QuizPlannerDrawer";
import MediaPlannerDrawer from "../slots/MediaPlannerDrawer";
import {
    listChpGet,
    listChpGet2,
    listChpGet3,
} from "../../../redux/actions/chapterAction";
import { listTopGet } from "../../../redux/actions/topicAction";
import { truncate } from "lodash";

import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import { SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core";

import Box2 from "@mui/material/Box";
import ListItemButton2 from "@mui/material/ListItemButton";

import { FixedSizeList2 } from "react-window";

import Card2 from "@mui/material/Card";
import CardActions2 from "@mui/material/CardActions";
import CardContent2 from "@mui/material/CardContent";
import CardMedia2 from "@mui/material/CardMedia";
import Button2 from "@mui/material/Button";
import ButtonGroup2 from '@mui/material/ButtonGroup';

/* accordion elements from mui */
import Accordion2 from "@mui/material/Accordion";
import AccordionDetails2 from "@mui/material/AccordionDetails";
import AccordionSummary2 from "@mui/material/AccordionSummary";
import InboxIcon2 from "@mui/icons-material/Inbox";
import DraftsIcon2 from "@mui/icons-material/Drafts";

import ExpandMoreIcon2 from "@mui/icons-material/ExpandMore";

import { styled } from "@mui/material/styles";
import Grid2 from "@mui/material/Grid";
import Paper2 from "@mui/material/Paper";

import AddBoxIcon2 from "@mui/icons-material/AddBox";

import Dialog2 from "@mui/material/Dialog";
import DialogActions2 from "@mui/material/DialogActions";
import DialogContent2 from "@mui/material/DialogContent";
import DialogContentText2 from "@mui/material/DialogContentText";
import DialogTitle2 from "@mui/material/DialogTitle";

import InputLabel2 from "@mui/material/InputLabel";
import MenuItem2 from "@mui/material/MenuItem";
import FormControl2 from "@mui/material/FormControl";
import Select2 from "@mui/material/Select";

import Alert2 from "@mui/material/Alert";
import IconButton2 from "@mui/material/IconButton";
import CloseIcon2 from "@mui/icons-material/Close";

import Radio2 from "@mui/material/Radio";
import RadioGroup2 from "@mui/material/RadioGroup";
import FormControlLabel2 from "@mui/material/FormControlLabel";

import FormLabel2 from "@mui/material/FormLabel";

import LessonPlanSetupAPI from "../../../http/lessonplansetup";

// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import {
    filterMaps,
    listCurriculuFilterGet,
    listCurriculumGet,
    postNewCurriculum,
} from "../../../redux/actions/curriculumMapAction";
import { listSubSubGet } from "../../../redux/actions/subSubjectsAction";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Cookie from "js-cookie";
import UserSession from "../../../resource/UserSession";
// import MultiStep from 'react-multistep';
// import MasterForm from "../slots/MasterForm";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import Drawer2 from "@mui/material/Drawer";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


// add gpt plan dialog
import LessonPlanAddGPTCard from "../slots/AddLessonPlanGPT";
import { listSubTopGet, listChapSubTopGet } from "../../../redux/actions/SubtopicAction";
import CurriculumMapper from "../slots/CurriculumMapper";

const AddUnitDialog = () => {

    const unitMapDetailsGet = useSelector((state) => state.unitMap);
    const { loadingUnit, UnitMap, errorMap } = unitMapDetailsGet;

    const [open, setOpen] = React.useState(false);
    const [openAdd, setOpenAdd] = React.useState(false);
    const [openAddUnit, setOpenAddUnit] = useState(false);
    const [addUnitNumber, setAddUnitNumber] = useState(null);
    const [addUnitTitle, setAddUnitTitle] = useState(null);
    const [addUnitLess, setAddUnitLess] = useState(null);
    const [addunitDays, setAddUnitDays] = useState(null);
    const [addUnitTags, setAddUnitTags] = useState(null);
    const [addUnitColor, setAddUnitColor] = useState("#000");
    const [currUnitName, setCurrUnitName] = useState("Select Unit");
    const [selUnit, setSelUnit] = useState(null);
    const [unitSnackOpen, setUnitSnackOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [ImageDownloadUrls, setImageDownloadUrls] = useState(
        isEdit
            ? currUnit.cover_image
            : "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/fa/6926005ea411e490ff8d4c5d4ff426/chemistry_logo.png?auto=format%2Ccompress&dpr=1"
    );
    const [progress, setProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [openSnack, setOpenSnack] = React.useState(false);

    const [showUnitDelete, setShowUnitDelete] = useState(false);
    const currUnit = useSelector((state) => state.currUnit.currUnit);

    const handleShowUnitDelete = (bool) => {
        setShowUnitDelete(bool);
    };

    console.log(
        "UnitList.js UnitLeftCard: ImageDownloadUrls " + ImageDownloadUrls
    );
    console.log("UnitList.js UnitLeftCard: currUnit " + JSON.stringify(currUnit));

    const handleUnitSnackClose = () => {
        setUnitSnackOpen(false);
    };

    const handleUploadImageSuccess = async (filename) => {
        const downloadUrl = await firebase
            .storage()
            .ref("ebook")
            .child(filename)
            .getDownloadURL();

        console.log("image download url", downloadUrl);
        setImageDownloadUrls(downloadUrl);
        if (downloadUrl) {
            setProgress(0);
        }
    };

    const handleUploadStart = () => {
        setIsUploading(true);
        setProgress(0);
    };

    const handleProgressImage = (progress) => {
        setProgress((prevProgress) =>
            prevProgress >= 100 ? 0 : prevProgress + 10
        );
    };

    const handleUploadError = (error) => {
        setIsUploading(false);
    };

    const handleChange = async (event, child, value) => {
        if (child && child.props && event && event.target) {
            // get the index by checking the name
            let index = -1;

            // console.log(`change unit got event ${JSON.stringify(event.target)}`)
            // console.log(`change unit got index ${JSON.stringify(child.props)}`)

            for (let i = 0; i < UnitMap.data.Units.length; i++) {
                const unt = UnitMap.data.Units[i];
                if (unt.unit_title_text == event.target.value) {
                    index = i;
                    break;
                }
            }

            if (index > -1) {
                setCurrUnitName(event.target.value);
                console.log(`we got index ${index}`);

                await new Promise((resolve, reject) => {
                    // get current unit by index
                    setSelUnit(UnitMap.data.Units[index]);
                    resolve();
                });

                const currUnit = UnitMap.data.Units[index];
                const currUnitId = currUnit.id;
                // also update current unit
                await new Promise((resolve, reject) => {
                    dispatch(updateCurrUnit(currUnit));
                    console.log(`new currUnit id ${currUnitId}`);
                    resolve();
                });

                // load new lessons
                dispatch(getUnitLessons(currUnitId));

                // take first lesson

                // load lesson plans
            }
        }
    };

    const handleChange2 = async (id, value) => {
        console.log("UnitList.js handleChange2: id and value ", id, value);
        if (value) {
            // get the index by checking the name
            let index = -1;

            // console.log(`change unit got event ${JSON.stringify(event.target)}`)
            // console.log(`change unit got index ${JSON.stringify(child.props)}`)

            for (let i = 0; i < UnitMap.data.Units.length; i++) {
                const unt = UnitMap.data.Units[i];
                if (unt.unit_title_text == value) {
                    index = i;
                    break;
                }
            }

            if (index > -1) {
                setCurrUnitName(value);
                console.log(`UnitList.js handleChange2: we got index ${index}`);

                await new Promise((resolve, reject) => {
                    // get current unit by index
                    setSelUnit(UnitMap.data.Units[index]);
                    resolve();
                });

                const currUnit = UnitMap.data.Units[index];
                const currUnitId = currUnit.id;

                console.log(
                    "UnitList.js handleChange2: currUnit " + JSON.stringify(currUnit)
                );
                // also update current unit
                await new Promise((resolve, reject) => {
                    dispatch(updateCurrUnit(currUnit));
                    console.log(`new currUnit id ${currUnitId}`);
                    resolve();
                });

                // load new lessons
                dispatch(getUnitLessons(currUnitId));

                // take first lesson

                // load lesson plans
            }
        }
    };

    const currMap = useSelector((state) => state.currMap.currMap);
    const currMapId = useSelector((state) => state.currMapId.currMapId);
    // const mapId = currMapId != null ? currMapId : 0;

    const { id } = useParams();
    const mapId = id;

    const dispatch = useDispatch();
    // console.log(`lessons data 2 ${JSON.stringify(lessonData)}`);

    /*
    const sendUnitData = (units) => {
        setUnitData(units);
    };
    */

    // const unitMapDetailsGet = useSelector(state => state.unitMap)
    // const { loadingUnit, UnitMap, errorMap } = unitMapDetailsGet

    // console.log("unit details", UnitMap)

    // useEffect(() => {
    //   dispatch(listUnitMapGet(1))
    //   return () => {
    //     //
    //   }

    // }, [])

    /*
    const updateLesData = (lesData) => {
        getLesData(lesData);
    };
    */

    const handleClickOpenAdd = () => {
        setOpenAdd(true);
    };

    const handleAddunitOpen = () => {
        setOpenAddUnit(true);
        // make edit false
        setIsEdit(false);
        setImageDownloadUrls(""); // reset
    };

    const handleAddunitClose = () => {
        setOpenAddUnit(false);
    };

    const handleUnitNumChange = (event) => {
        const { value } = event.target;
        setAddUnitNumber(value);
    };

    const handleUnitTitle = (event) => {
        const { value } = event.target;
        setAddUnitTitle(value);
    };

    const handleUnitLess = (event) => {
        const { value } = event.target;
        setAddUnitLess(value);
    };

    const handleUnitDays = (event) => {
        const { value } = event.target;
        setAddUnitDays(value);
    };

    const handleUnitTags = (event) => {
        const { value } = event.target;
        setAddUnitTags(value);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnack(false);
    };

    // delete unit dialog
    function DelUnitDialog() {
        const [open, setOpen] = React.useState(showUnitDelete);

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        return (
            <div>
                {/* <Button variant="outlined" onClick={handleClickOpen}>
          Open alert dialog
        </Button> */}
                <Button onClick={handleClickOpen} color="warning">
                    Delete
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Use Google's location service?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this unit? It cannot be reverted!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleDelete} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    // UPDATE CURRENT UNIT
    const handleUpdateUnit = async () => {
        if (
            // !currUnit.id ||
            !currUnit.unit_number ||
            !currUnit.unit_title_text
            // || !currUnit.lesson_count
            // || !currUnit.days_count
        ) {
            setOpenSnack(true);
            return;
        }

        let imgUrl = "";
        if (ImageDownloadUrls) imgUrl = ImageDownloadUrls;

        imgUrl =
            "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/fa/6926005ea411e490ff8d4c5d4ff426/chemistry_logo.png?auto=format%2Ccompress&dpr=1";

        const body = {
            curriculum_map_id: mapId,
            unit_title_text: currUnit.unit_title_text,
            unit_number: currUnit.unit_number,
            lesson_count: 0,
            days_count: 0,
            unit_color: 0,
            unit_tags: "#tag",
            cover_image: imgUrl,
        };

        console.log(`handleUpdateUnit called..  with body ${JSON.stringify(body)}`);

        const resp = await MapUnitAPI.postUpdateMapUnit(body, currUnit.id);
        console.log(`curriculum map unit update response ${JSON.stringify(resp)}`);
        console.log(`going to call units for map ${mapId}`);

        handleClose();

        // now refresh map units
        dispatch(listUnitMapGet(mapId));
    };

    const handleAddUnitSave = async () => {
        const body = {
            curriculum_map_id: mapId,
            unit_title_text: addUnitTitle,
            unit_number: addUnitNumber,
            cover_image: ImageDownloadUrls,
            lesson_count: 0,
            days_count: 0,
            unit_color: "#FFFFFF",
            unit_tags: "#tag",
        };

        console.log(`add unit body params ${JSON.stringify(body)}`);

        const resp = await MapUnitAPI.postSaveMapUnit(body);

        console.log("response at handleunitsave ==================", resp);
        handleAddunitClose(); // close dialog

        // open snackbar
        setUnitSnackOpen(true);

        // load all units
        dispatch(listUnitMapGet(mapId));
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        if (!currUnit.id) {
            setOpenSnack(true);
            return;
        }

        // call delete api for unit
        const unitId = currUnit.id;

        const resp = await UNITAPI.postDeleteMapUnit(unitId);

        // close dialog
        setShowUnitDelete(false);

        // close edit unit dialog
        handleClose();

        // reload page

        // load all units
        dispatch(listUnitMapGet(mapId));
    };

    /*
    const unitOptions = (
        <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={currUnitName}
            onChange={(event, index, value) => handleChange(event, index, value)}
            label="Units"
        >
            <MenuItem value="Select Unit">
                <em>Select Unit</em>
            </MenuItem>

            {units && units.data && units.data.Units && units.data.Units.length ? (
                units.data.Units.map((unit, index) => {
                    return (
                        <MenuItem value={unit.unit_title_text}>
                            {unit.unit_title_text}
                        </MenuItem>
                    );
                })
            ) : (
                <MenuItem value={-1}>No Units</MenuItem>
            )}
        </Select>
    );
    */

    let mapTitle = currMap ? currMap.map_title_text : "";

    let unitNumEVar = currUnit != null ? currUnit.unit_number : 0;
    let unitTitleEVar = currUnit != null ? currUnit.unit_title_text : "";
    let unitLessonsEVar = currUnit != null ? currUnit.lesson_count : "";
    let unitDaysEVar = currUnit != null ? currUnit.days_count : "";
    let unitColorEVar = currUnit != null ? currUnit.unit_color : "";
    let unitTagsEVar = currUnit != null ? currUnit.unit_tags : "";
    let unitIdEVar = currUnit != null ? currUnit.id : 0;

    const [unitNumE, setUnitNumE] = React.useState(unitNumEVar);
    const [unitTitleE, setUnitTitleE] = React.useState(unitTitleEVar);
    const [unitLessonsE, setUnitLessonsE] = React.useState(unitLessonsEVar);
    const [unitDaysE, setUnitDaysE] = React.useState(unitDaysEVar);
    const [unitColorE, setUnitColorE] = React.useState(unitColorEVar);
    const [unitTagsE, setUnitTagsE] = React.useState(unitTagsEVar);
    const [unitIdE, setUnitIdE] = React.useState(unitIdEVar);
    const [checked, setChecked] = React.useState(false);

    console.log(`unitnume ${unitNumE}`);
    console.log(`unitnume var ${unitNumEVar}`);
    console.log(`unittitlee ${unitTitleEVar}`);

    /* EDIT UNIT FIELDS FUNCTIONS */

    const handleUnitNumEChange = (event) => {
        const { name, value } = event.target;
        setUnitNumE(value);
        unitNumEVar = value;

        // update store
        currUnit.unit_number = value;
        dispatch(updateCurrUnit(currUnit));
    };

    const handleUnitTitleEChange = (event) => {
        const { name, value } = event.target;
        setUnitTitleE(value);
        unitTitleEVar = value;

        // update store
        currUnit.unit_title_text = value;
        dispatch(updateCurrUnit(currUnit));
    };

    const handleUnitLessonsEChange = (event) => {
        const { name, value } = event.target;
        setUnitLessonsE(value);
        unitLessonsEVar = value;

        // update store
        currUnit.lesson_count = value;
        dispatch(updateCurrUnit(currUnit));
    };

    const handleUnitDaysEChange = (event) => {
        const { name, value } = event.target;

        setUnitDaysE(value);
        unitDaysEVar = value;

        // update store
        currUnit.days_count = value;
        dispatch(updateCurrUnit(currUnit));
    };

    const handleUnitTagsEChange = (event) => {
        const { name, value } = event.target;

        setUnitTagsE(value);
        unitTagsEVar = value;

        // update store
        currUnit.unit_tags = value;
        dispatch(updateCurrUnit(currUnit));
    };

    const handleClickOpen = (e, unit) => {
        e.stopPropagation();
        setOpen(true);

        // update image
        setImageDownloadUrls(unit.cover_image);
    };


    return (
        <>


            <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                    handleAddunitOpen();
                }}
                startIcon={<AddIcon />}
            >
                Add Unit
            </Button>


            {/* ADD UNIT DIALOG AT LEFT CARD*/}
            <Dialog
                open={openAddUnit}
                onClose={handleAddunitClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle id="form-dialog-title">
                    Add Unit for {mapTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please define the unit number in digits &amp; enter relevant title
                    </DialogContentText>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            flexDirection: "row",
                        }}
                    >
                        <EditIcon style={{ height: 16 }} />

                        <TextField
                            autoFocus
                            // defaultValue={unitNum}
                            onChange={handleUnitNumChange}
                            margin="dense"
                            id="name"
                            label="Unit Number"
                            placeholder="Enter a number"
                            type="number"
                            InputProps={{ inputProps: { min: 1, max: 20 } }}
                            min="1"
                            max="20"
                            style={{
                                marginLeft: 10,
                                width: "20%",
                            }}
                        />

                        <TextField
                            autoFocus
                            // defaultValue={unitTitle}
                            onChange={handleUnitTitle}
                            margin="dense"
                            id="name"
                            label="Title"
                            placeholder="Enter relevant title"
                            type="text"
                            fullWidth
                            style={{
                                marginLeft: 10,
                            }}
                        />
                    </div>

                    <div
                        style={{
                            display: "none",
                            flexDirection: "row",

                            marginTop: 10,
                        }}
                    >
                        <DateRangeIcon style={{ height: 16 }} />

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: 10,
                            }}
                        >
                            <Typography variant="body2">Pacing</Typography>

                            <Typography variant="duration">SUGGESTED DURATION</Typography>

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <div>
                                    <TextField
                                        autoFocus
                                        // defaultValue={unitLessons}
                                        onChange={handleUnitLess}
                                        margin="dense"
                                        id="lessons"
                                        label="Lessons"
                                        type="number"
                                    />
                                </div>

                                <div style={{ marginLeft: 20 }}>
                                    <TextField
                                        autoFocus
                                        // defaultValue={unitDays}
                                        onChange={handleUnitDays}
                                        margin="dense"
                                        id="days"
                                        label="Periods / Days"
                                        type="number"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            display: "none",
                        }}
                    >
                        <TextField
                            autoFocus
                            // defaultValue={unitTags}
                            onChange={handleUnitTags}
                            margin="dense"
                            id="tags"
                            label="Tags"
                            type="text"
                            fullWidth
                        />
                    </div>

                    <div
                        style={{
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            display: "none",
                            marginTop: 20,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                marginTop: 0,
                                marginLeft: 10,
                                marginRight: 20,
                                width: "100%",
                            }}
                        >
                            <label
                                style={{
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                    cursor: "pointer",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        cursor: "pointer",
                                    }}
                                >
                                    <AddBoxIcon2
                                        style={{
                                            color: "steelblue",
                                        }}
                                    />
                                    <Typography2
                                        variant="body1"
                                        style={{
                                            marginLeft: 10,
                                            color: "#464E5F",
                                        }}
                                    >
                                        Add Cover Image
                                    </Typography2>
                                    <FileUploader
                                        hidden
                                        accept="image/*"
                                        storageRef={firebase.storage().ref("ebook")}
                                        onUploadStart={handleUploadStart}
                                        onUploadError={handleUploadError}
                                        onUploadSuccess={handleUploadImageSuccess}
                                        onProgress={handleProgressImage}
                                    />
                                </div>
                            </label>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginLeft: 20,
                                }}
                            >
                                {progress ? <CircularProgress /> : <></>}
                            </div>
                        </div>

                        <div
                            style={{
                                position: "relative",
                                display: "flex",
                                justifyContent: "flex-start",
                                paddingLeft: 20,
                            }}
                        >
                            {ImageDownloadUrls && (
                                <>
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: 5,
                                            right: 5,
                                            cursor: "pointer",
                                            zIndex: 3,
                                        }}
                                    >
                                        <label>
                                            <EditIcon />
                                            <FileUploader
                                                hidden
                                                accept="image/*"
                                                storageRef={firebase.storage().ref("ebook")}
                                                onUploadStart={handleUploadStart}
                                                onUploadError={handleUploadError}
                                                onUploadSuccess={handleUploadImageSuccess}
                                                onProgress={handleProgressImage}
                                            />
                                        </label>
                                    </div>
                                    <img
                                        src={ImageDownloadUrls}
                                        alt="preview Image"
                                        style={{
                                            alignSelf: "flex-start",
                                            display: "flex",
                                            width: "100%",
                                        }}
                                    />
                                    {/* {ImageDownloadUrls} */}
                                </>
                            )}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddunitClose} color="primary">
                        Cancel
                    </Button>

                    <Button onClick={handleAddUnitSave} color="primary">
                        Save
                    </Button>

                    {/* <Button color="warning">Delete</Button> */}
                </DialogActions>
            </Dialog>

            <Snackbar
                open={unitSnackOpen}
                autoHideDuration={6000}
                onClose={handleUnitSnackClose}
            >
                <Alert onClose={handleUnitSnackClose} severity="success">
                    You have successfully added a Unit!
                </Alert>
            </Snackbar>

        </>
    )
}

export default AddUnitDialog;
