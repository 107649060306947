import axios from "axios";
import Constants from "../../resource/Constants";
import Cookie from 'js-cookie';

import {
  PROFILEDETAILSPUB_LIST_REQUEST,
  PROFILEDETAILSPUB_LIST_SUCCESS,
  PROFILEDETAILSPUB_LIST_FAIL,
  PROFILEDETAILSCONT_LIST_REQUEST,
  PROFILEDETAILSCONT_LIST_SUCCESS,
  PROFILEDETAILSCONT_LIST_FAIL,
} from "../constants/ProfileConstant";

const listProfilUpdatePub = (body) => async (dispatch) => {

  console.log("body at axios at ebookpublisher", body)
  try {
    dispatch({ type: PROFILEDETAILSPUB_LIST_REQUEST });
    const ProvId = Cookie.get('kgtopg.guruculum.user.id')
    let url = Constants.Application.PARTNER_END_URL + `/publisher/${ProvId}`

    const options = {
      method: "PUT",
      data: body,
      url,
    }
    const { data } = await axios(options)
    console.log("Profile Details ======================", data);

    // if (data) {
    //   let userId = 0
    //   let userName = ""
    //   let userEmail = ""
    //   let userPhoto = ""
    //   let userPhone = ""
    //   let userBankAccName = ""
    //   let userCompanyId = ""
    //   let userBankAccNum = ""
    //   let userBankAccIFSC = ""
    //   let userBankAccBranch = ""
    //   let userBankName = ""
    //   let pubUserId = 0

    //   userId = data.id
    //   userName = data.pub_name_text
    //   userEmail = data.pub_email_text
    //   userPhoto = data.pub_logo
    //   userPhone = data.phone_number
    //   userCompanyId = data.company_id
    //   userBankAccName = data.bank_account_name
    //   userBankAccNum = data.bank_account_number
    //   userBankAccIFSC = data.banck_account_IFSC
    //   userBankAccBranch = data.bank_branch
    //   userBankName = data.bank_name
    //   pubUserId = data.user_id

    //   Cookie.set('kgtopg.guruculum.user.id', userId);
    //   Cookie.set('kgtopg.guruculum.user.name', userName);
    //   Cookie.set('kgtopg.guruculum.user.email', userEmail);
    //   Cookie.set('kgtopg.guruculum.user.photo', userPhoto);
    //   Cookie.set('kgtopg.guruculum.user.phone', userPhone);
    //   Cookie.set('kgtopg.guruculum.user.companyid', userCompanyId);
    //   Cookie.set('kgtopg.guruculum.user.accname', userBankAccName);
    //   Cookie.set('kgtopg.guruculum.user.accnum', userBankAccNum);
    //   Cookie.set('kgtopg.guruculum.user.accifsc', userBankAccIFSC);
    //   Cookie.set('kgtopg.guruculum.user.bankbranch', userBankAccBranch);
    //   Cookie.set('kgtopg.guruculum.user.bankname', userBankName);
    //   Cookie.set('kgtopg.guruculum.user.pubuserid', pubUserId);


    // }
    dispatch({ type: PROFILEDETAILSPUB_LIST_SUCCESS, payload: data });



  } catch (error) {
    dispatch({ type: PROFILEDETAILSPUB_LIST_FAIL, payload: error.message });
  }
};

const listProfilUpdateCont = (body) => async (dispatch) => {
  try {
    dispatch({ type: PROFILEDETAILSCONT_LIST_REQUEST });
    const ProvId = Cookie.get('kgtopg.guruculum.user.id')
    let url = Constants.Application.PARTNER_END_URL + `/content-prov/${ProvId}`

    const options = {
      method: "PUT",
      data: body,
      url,
    }

    const { data } = await axios(options)
    console.log("Profile Detailscont ======================", data);
    // if (data) {
    //   let userId = 0
    //   let userName = ""
    //   let userEmail = ""
    //   let userPhoto = ""
    //   let userPhone = ""
    //   let userCompanyId = ""
    //   let contuserid = ""
    //   let userBankAccName = ""
    //   let userBankAccNum = ""
    //   let userBankAccIFSC = ""
    //   let userBankAccBranch = ""
    //   let userBankName = ""


    //   userId = data.id
    //   userName = data.pub_name_text
    //   userEmail = data.pub_email_text
    //   userPhoto = data.pub_logo
    //   userPhone = data.phone_number
    //   userCompanyId = data.company_id
    //   contuserid = data.user_id
    //   userBankAccName = data.bank_account_name
    //   userBankAccNum = data.bank_account_number
    //   userBankAccIFSC = data.banck_account_IFSC
    //   userBankAccBranch = data.bank_branch
    //   userBankName = data.bank_name


    //   Cookie.set('kgtopg.guruculum.user.id', userId);
    //   Cookie.set('kgtopg.guruculum.user.name', userName);
    //   Cookie.set('kgtopg.guruculum.user.email', userEmail);
    //   Cookie.set('kgtopg.guruculum.user.photo', userPhoto);
    //   Cookie.set('kgtopg.guruculum.user.phone', userPhone);
    //   Cookie.set('kgtopg.guruculum.user.companyid', userCompanyId);
    //   Cookie.set('kgtopg.guruculum.user.contuserid', contuserid);
    //   Cookie.set('kgtopg.guruculum.user.accname', userBankAccName);
    //   Cookie.set('kgtopg.guruculum.user.accnum', userBankAccNum);
    //   Cookie.set('kgtopg.guruculum.user.accifsc', userBankAccIFSC);
    //   Cookie.set('kgtopg.guruculum.user.bankbranch', userBankAccBranch);
    //   Cookie.set('kgtopg.guruculum.user.bankname', userBankName);


    // }
    dispatch({ type: PROFILEDETAILSCONT_LIST_SUCCESS, payload: data });


  } catch (error) {
    dispatch({ type: PROFILEDETAILSCONT_LIST_FAIL, payload: error.message });
  }
};



export { listProfilUpdatePub, listProfilUpdateCont };
