import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Divider, Grid, Toolbar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { ArrowBack, CloudDoneOutlined, FilterTiltShiftRounded, Refresh } from '@material-ui/icons';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { useDispatch, useSelector } from 'react-redux';
import { listBoardGet } from '../../redux/actions/boardDetailsAction';
import { boardDetailsReducer } from '../../redux/reducers/boardDetailsReducer';
import { listInstGet } from '../../redux/actions/InstituteDetailsAction';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import WifiIcon from '@material-ui/icons/Wifi';
import BluetoothIcon from '@material-ui/icons/Bluetooth';
import Cookie from 'js-cookie';

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    formControl: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 250,
        minHeight: 30,

    },
    formControl1: {
        margin: theme.spacing(1),
        minWidth: 400,
        minHeight: 30,

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    toolbar: {
        marginRight: 24,
        justifyContent: "space-between"
    },
    button: {
        margin: theme.spacing(1),
        justifyContent: "flex-end",
        alignSelf: "center",
    },
    button1: {
        margin: theme.spacing(1),
        justifyContent: "flex-end",
        justifyItems: "flex-end",
        justifySelf: "flex-end",
        alignSelf: "flex-end",
        alignItems: "flex-end",
        alignContent: "flex-end",
    },
}));


export default function Settings() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [age, setAge] = React.useState('');
    const [showInputField, SetShowInputField] = useState(false)
    const [intialValue1, setInitialValue1] = useState(0)
    const [selectBoardDetails, setSelectedBoardDetails] = useState("")
    const [newfieldCount, setnewfieldCount] = useState(4);
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
    });

    const newFieldArr = [];

    const dispatch = useDispatch();


    const handleChangeSwitch = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const AddnewField = () => {
        let intialValue = newfieldCount;
        let count = parseInt(intialValue + 1)
        setnewfieldCount(count)
        console.log("count", newfieldCount)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const BootstrapInput = withStyles((theme) => ({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            height: 30,
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            fontSize: 16,
            width: 300,
            padding: '10px 26px 10px 12px',
            placeholder: "Title",
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#80bdff',

                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
        },
    }))(InputBase);




    const showInput = () => {
        SetShowInputField(true)

    }

    const boardDetailsGet = useSelector(state => state.boardDetails)
    const { loading, boardDetails, error } = boardDetailsGet

    const instDetailsGet = useSelector(state => state.instDetails)
    const { loadingInst, instDetails, errorInst } = instDetailsGet

    console.log("board Details", boardDetails ? boardDetails.data : "not yet fetched")
    console.log("instDetails ", instDetails ? instDetailsGet : "not fetched inst details")
    const userId = Cookie.get('kgtopg.guruculum.user.id')

    useEffect(() => {
        if(userId == 0) {
            window.scrollTo(0, 0);
            console.log('Settings.js Settings: useEffect unlisten completed')
    
            window.location.assign('/logout')
    
        }
        
        dispatch(listBoardGet(), listInstGet())

        return () => {
            // 

        }
    }, [])

    const deleteField = (i) => {
        console.log("i",i)
        newFieldArr.splice(i, 1)
        
        console.log("newFieldArr",newFieldArr)
    }


    const FieldOptions = (props) => {
        console.log("props at field options", props)
        let i = props.numb
        return (

            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                alignSelf: "center"
            }}>
                <FormControl className={classes.margin}>
                    <BootstrapInput id="demo-customized-textbox" />
                    <Typography>
                        <span
                            style={{
                                fontSize: 12,
                                color: "#464E5F",
                                marginLeft: 5,
                                fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                        >
                            Please enter <span
                                style={{
                                    color: "#464E5F",
                                    fontWeight: "600",
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                    fontSize: 12
                                }}>
                                Label
                    </span>
                        </span>
                    </Typography>
                </FormControl>
                {/* <span style={{ display: "flex", marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                    :
                </span>
 */}

                <FormControl variant="outlined" className={classes.formControl} style={{ alignSelf: "center" }} >
                    <InputLabel id="demo-simple-select-outlined-label"
                        style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}
                    >
                        Field Types
                </InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={age}
                        onChange={handleChange}
                        label="Age"
                        size="small"
                        style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}

                    >
                        <MenuItem value="0" style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}><em>None</em></MenuItem>
                        <MenuItem value="1" style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Input</MenuItem>
                        <MenuItem value="2" style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Dropdown</MenuItem>
                        <MenuItem value="3" style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>switch</MenuItem>

                    </Select>
                    <span
                        style={{
                            fontSize: 12,
                            color: "#464E5F",
                            marginLeft: 5,
                            fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                    >
                        Please select <span
                            style={{
                                color: "#464E5F",
                                fontWeight: "600",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                                fontSize: 12
                            }}>
                            type of Field
                        </span>
                    </span>
                </FormControl>
                <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                    <IconButton color="primary" aria-label="add to shopping cart" style={{ fontSize: 14, marginTop: -10 }}>
                        <EditOutlinedIcon />
                    </IconButton>

                    <IconButton color="secondary" aria-label="add an alarm" style={{ fontSize: 14, marginTop: -10 }} onClick={() => { deleteField(i) }} >
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>

                </div>
            </div>

        )
    }


    let FIELD_OPTIONS = [
        <FieldOptions />,
        <FieldOptions />,
        <FieldOptions />,
        <FieldOptions />

    ]

    const MasterFieldOptions = () => {
        const count = newfieldCount
        const items = []
        // _.times(newfieldCount, () => {
        for (let i = 0; i < newfieldCount; i++) {
            newFieldArr.push(<FieldOptions numb={i} />)
        }


        return newFieldArr;
    }




    return (
        <div>


            <Toolbar className={classes.toolbar}>

                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ fontSize: 16, fontFamily: "Poppins, Helvetica, sans-serif", }}>
                    Settings
                </Typography>

                {/*
                <div style={{ flexDirection: "row", }}>

                    <Link to="/" style={{ textDecoration: "none" }}>
                        <Button
                            variant="contained"
                            color="default"
                            size="small"
                            className={classes.button1}
                            startIcon={<ArrowBack style={{ fontFamily: "Poppins, Helvetica, sans-serif", color: " #80808F" }} />}
                            style={{ fontFamily: "Poppins, Helvetica, sans-serif", color: " #80808F", backgroundColor: "#F3F6F9", }}
                        >
                            Back
                     </Button>
                    </Link>

                    <Button
                        variant="contained"
                        color="default"
                        className={classes.button}
                        startIcon={<Refresh style={{ fontFamily: "Poppins, Helvetica, sans-serif", color: " #80808F" }} />}
                        size="small"
                        style={{ fontFamily: "Poppins, Helvetica, sans-serif", color: " #80808F", backgroundColor: "#F3F6F9", }}

                    >
                        Reset
                    </Button>


                </div>
                */}


            </Toolbar>
            <Divider style={{ marginBottom: 10 }} />


            {/* <AppBar position="static" color="default" style={{ marginTop: "20" }}> */}
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs example"
            >
                <Tab label="General"  {...a11yProps(0)} style={{ fontSize: 12, }} />
                <Tab label="Fields" {...a11yProps(1)} style={{ fontSize: 12 }} />
            </Tabs>

            {/* </AppBar> */}



            <TabPanel value={value} index={0}>
            
            
                {/* <List subheader={<ListSubheader>Settings</ListSubheader>} className={classes.root}>
                    <ListItem>
                        <ListItemIcon>
                        <WifiIcon />
                        </ListItemIcon>
                        <ListItemText id="switch-list-label-wifi" primary="Wi-Fi" />
                        <ListItemSecondaryAction>
                        <Switch
                            edge="end"
                            onChange={handleToggle('wifi')}
                            checked={checked.indexOf('wifi') !== -1}
                            inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                        />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List> */}

                

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 10 }}>
                    <span style={{ fontFamily: "Poppins, Helvetica, sans-serif", color: " #80808F", backgroundColor: "#F3F6F9", fontSize: 14 }}>Notifications:</span>
                    <Switch
                        checked={state.checkedA}
                        onChange={handleChangeSwitch}
                        name="checkedA"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />

                </div>

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 10 }}>
                    <span style={{ fontFamily: "Poppins, Helvetica, sans-serif", color: " #80808F", backgroundColor: "#F3F6F9", fontSize: 14 }}>Custom Fields:</span>
                    <Switch
                        checked={state.checkedA}
                        onChange={handleChangeSwitch}
                        name="checkedA"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                </div>
                <Divider style={{ marginTop: 20 }} />

                <div style={{ display: "flex", justifyContent: "center", margin: 10 }}>
                    <Button variant="contained" color="primary" style={{ fontFamily: "Poppins, Helvetica, sans-serif", textTransform: "none" }}>
                        Submit
                    </Button>
                </div>



            </TabPanel>

            <TabPanel value={value} index={1}>



                <MasterFieldOptions />
                {/* <FieldOptions />
                <FieldOptions />
                <FieldOptions />
                <FieldOptions /> */}

                <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
                    <Button variant="outlined" color="primary" onClick={() => { AddnewField() }}>
                        Add New Field
                    </Button>
                </div>



            </TabPanel>

        </div>
    );
}