/**
 * @project kgtopg - schooler - publisher
 * http api calls for
 * authentication
 */

 import API from './http';
 import axios from 'axios';
 import qs from 'querystring';
 import Constants from '../resource/Constants';
 import Cookie from 'js-cookie';
 import UserSession from '../resource/UserSession';
 //  import UserSession from '../resource/UserSession';
 
 export default {
 
 
 
     async doLoginUser(body) {
 
         const headers = {
             "Content-Type": "application/x-www-form-urlencoded",
             Accept: "*/*",
         };
 
         let url = Constants.Application.PLATFORM_END_URL;
         url += "/user-signin/";
 
         // we wait
         try {
 
             console.log("login authentication api url", url)
 
             const options = {
                 method: "POST",
                 data: body,
                 url,
                 headers: {
                     "Content-Type": "application/json",
                     Accept: "application/json",
                     // 'Authorization': 'Bearer ' + UserSession.authtoken,
                 },
 
             };
 
 
             console.log("options==========", options)
             let response = await axios(options);
 
             console.log("doLoginUser Auth api response=====", response)
 
             if (response) {
                 const token = response.data.token
                 Cookie.set('kgtopg.guruculum.user.token', token);
                 console.log("cookies data token+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.token'))
 
             }
 
 
 
             if (response) return response;
             else return false;
         }
         catch (ex) {
             // console.log(ex);
             // console.log(`exception ${ex.message}`)
             return false
         }
 
     },
 
 
     async doLoginToken(body) {
 
         const headers = {
             "Content-Type": "application/x-www-form-urlencoded",
             Accept: "*/*",
         };
 
         let url = Constants.Application.PARTNER_END_URL;
         url += "/token-valid/";
 
         // we wait
         try {
 
             console.log("token login api url", url)
 
             const options = {
                 method: "POST",
                 data: body,
                 url,
                 headers: {
                     "Content-Type": "application/json",
                     Accept: "application/json",
                     // 'Authorization': 'Bearer ' + token,
                 },
 
             };
 
             const response = await axios(options);
 
             console.log("doLoginToken api response ========================", response.data.data, response.data.role)
 
 
 
 
             if (response) {
                 const data = response.data
                 const role = data.role
                 let userId = 0
                 let userName = ""
                 let userEmail = ""
                 let userPhoto = ""
                 let userPhone = ""
                 let userCompanyId = ""
                 let userBankAccName = ""
                 let userBankAccNum = ""
                 let userBankAccIFSC = ""
                 let userBankAccBranch = ""
                 let userBankName = ""
                 let pubUserId = 0
                 let userLoggedIn = false
                 let userRoles = ""
                 // let SUPERADMIN = ""
                 // let CONTENTUPLOAD = ""
                 // let QUALITYASSURANCE = ""
                 // let CONTENTMAP = ""
                 // let CURRICULUMMAP = ""
                 // let TRAINER = ""
                 // let EBOOKUPLOAD = ""
 
 
                 if (role == Constants.Application.ROLE_CONT_PROV) {
                     userId = data.data.content_prov_details.id
                     userName = data.data.content_prov_details.prov_name_text
                     userEmail = data.data.content_prov_details.prov_email_text
                     userPhoto = data.data.content_prov_details.prov_cover_url
                     userPhone = data.data.content_prov_details.phone_number
                     userLoggedIn = true
                     userCompanyId = data.data.content_prov_details.company_id
                     userBankAccName = data.data.content_prov_details.bank_account_name
                     userBankAccNum = data.data.content_prov_details.bank_account_number
                     userBankAccIFSC = data.data.content_prov_details.banck_account_IFSC
                     userBankAccBranch = data.data.content_prov_details.bank_branch
                     userBankName = data.data.content_prov_details.bank_name
                     userRoles = data.data.content_prov_details.Roles
                     // SUPERADMIN = userRoles.includes("SUPERADMIN")
                     // CONTENTUPLOAD = userRoles.includes("CONTENTUPLOAD")
                     // QUALITYASSURANCE = userRoles.includes("QUALITYASSURANCE")
                     // CONTENTMAP = userRoles.includes("CONTENTMAP")
                     // CURRICULUMMAP = userRoles.includes("CURRICULUMMAP")
                     // TRAINER = userRoles.includes("TRAINER")
                     // EBOOKUPLOAD = userRoles.includes("EBOOKUPLOAD")
 
                 }
 
 
 
                 else if (role == Constants.Application.ROLE_EBOOK_PUB) {
                     userId = data.data.ebook_prov_details.id
                     userName = data.data.ebook_prov_details.pub_name_text
                     userEmail = data.data.ebook_prov_details.pub_email_text
                     userPhoto = data.data.ebook_prov_details.pub_logo
                     userPhone = data.data.ebook_prov_details.phone_number
                     userLoggedIn = true
                     userCompanyId = data.data.ebook_prov_details.company_id
                     userBankAccName = data.data.ebook_prov_details.bank_account_name
                     userBankAccNum = data.data.ebook_prov_details.bank_account_number
                     userBankAccIFSC = data.data.ebook_prov_details.banck_account_IFSC
                     userBankAccBranch = data.data.ebook_prov_details.bank_branch
                     userBankName = data.data.ebook_prov_details.bank_name
                     pubUserId = data.data.ebook_prov_details.user_id
                     userRoles = data.data.ebook_prov_details.Roles
                     // SUPERADMIN = data.data.ebook_prov_details.Roles.includes("SUPERADMIN")
                     // CONTENTUPLOAD = data.data.ebook_prov_details.Roles.includes("CONTENTUPLOAD")
                     // QUALITYASSURANCE = data.data.ebook_prov_details.Roles.includes("QUALITYASSURANCE")
                     // CONTENTMAP = data.data.ebook_prov_details.Roles.includes("CONTENTMAP")
                     // CURRICULUMMAP = data.data.ebook_prov_details.Roles.includes("CURRICULUMMAP")
                     // TRAINER = data.data.ebook_prov_details.Roles.includes("TRAINER")
                     // EBOOKUPLOAD = data.data.ebook_prov_details.Roles.includes("EBOOKUPLOAD")
 
                 }
 
                 // update in session
                 UserSession.userId = userId
                 UserSession.userName = userName
                 UserSession.userEmail = userEmail
                 UserSession.userPhoto = userPhoto
                 UserSession.userLoggedIn = true
 
                 Cookie.set('kgtopg.guruculum.user.id', userId, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.name', userName, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.email', userEmail, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.photo', userPhoto, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.phone', userPhone, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.role', role, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.login', userLoggedIn, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.companyid', userCompanyId, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.accname', userBankAccName, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.accnum', userBankAccNum, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.accifsc', userBankAccIFSC, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.bankbranch', userBankAccBranch, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.bankname', userBankName, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.pubuserid', pubUserId, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.Roles', userRoles, { expires: 90 })
                 // Cookie.set('kgtopg.guruculum.user.SUPERADMIN', SUPERADMIN);
                 // Cookie.set('kgtopg.guruculum.user.CONTENTUPLOAD', CONTENTUPLOAD);
                 // Cookie.set('kgtopg.guruculum.user.QUALITYASSURANCE', QUALITYASSURANCE);
                 // Cookie.set('kgtopg.guruculum.user.CONTENTMAP', CONTENTMAP);
                 // Cookie.set('kgtopg.guruculum.user.EBOOKUPLOAD', EBOOKUPLOAD);
                 // Cookie.set('kgtopg.guruculum.user.CURRICULUMMAP', CURRICULUMMAP);
                 // Cookie.set('kgtopg.guruculum.user.TRAINER', TRAINER);
 
 
 
                 console.log("cookies data id+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.id'))
                 console.log("cookies data name+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.name'))
                 console.log("cookies data email+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.email'))
                 console.log("cookies data photo+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.photo'))
                 console.log("cookies data phone+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.phone'))
                 console.log("cookies data role+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.role'))
                 console.log("cookies data login+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.login'))
                 console.log("cookies data login+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.accname'))
                 console.log("cookies data login+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.accnum'))
                 console.log("cookies data login+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.accifsc'))
                 console.log("cookies data login+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.bankbranch'))
                 console.log("cookies data login+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.bankname'))
                 console.log("cookies data login+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.pubuserid'))
                 console.log("cookies data  Roles at login+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.Roles'))
 
                 // console.log("cookies data SUPERADMIN+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.SUPERADMIN'))
                 // console.log("cookies data CONTENTUPLOAD+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.CONTENTUPLOAD'))
                 // console.log("cookies data QUALITYASSURANCE+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.QUALITYASSURANCE'))
                 // console.log("cookies data CONTENTMAP+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.CONTENTMAP'))
                 // console.log("cookies data EBOOKUPLOAD+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.EBOOKUPLOAD'))
                 // console.log("cookies data CURRICULUMMAP+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.CURRICULUMMAP'))
                 // console.log("cookies data TRAINER+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.TRAINER'))
 
 
                 return response;
 
             }
 
 
             else return false;
         }
         catch (ex) {
             console.log(ex);
             console.log(`exception ${ex.message}`)
             return false
         }
 
     },
 
     async doSwitchContLogin(body) {
         let url = Constants.Application.PARTNER_END_URL;
         url += "/verifying-details/";
 
         const token = Cookie.get('kgtopg.guruculum.user.token')
 
         try {
             const options = {
                 method: "POST",
                 data: body,
                 url,
                 headers: {
                     "Content-Type": "application/json",
                     Accept: "application/json",
                     "Authorization": "Bearer " + token,
                 },
             }
 
             const response = await axios(options);
 
             console.log("doSwitchContLogin api response ========================", response.data.data, response.data.role)
 
             if (response) {
                 const data = response.data
                 const role = data.role
                 let userId = 0
                 let userName = ""
                 let userEmail = ""
                 let userPhoto = ""
                 let userPhone = ""
                 let userCompanyId = ""
                 let userBankAccName = ""
                 let userBankAccNum = ""
                 let userBankAccIFSC = ""
                 let userBankAccBranch = ""
                 let userBankName = ""
                 let contUserId = 0
                 let pubUserId = 0
                 let userLoggedIn = false
                 let userRoles = ""
 
 
                 if (role == Constants.Application.ROLE_CONT_EBOOK_PUB) {
                     console.log("response in switch cont provider", response.data.data)
                     userId = data.data.Provider_details.id
                     userName = data.data.Provider_details.prov_name_text
                     userEmail = data.data.Provider_details.prov_email_text
                     userPhoto = data.data.Provider_details.prov_cover_url
                     userPhone = data.data.Provider_details.phone_number
                     userLoggedIn = true
                     userCompanyId = data.data.Provider_details.company_id
                     userBankAccName = data.data.Provider_details.bank_account_name
                     userBankAccNum = data.data.Provider_details.bank_account_number
                     userBankAccIFSC = data.data.Provider_details.banck_account_IFSC
                     userBankAccBranch = data.data.Provider_details.bank_branch
                     userBankName = data.data.Provider_details.bank_name
                     userRoles = data.data.Provider_details.Roles
                     contUserId = data.data.Provider_details.user_id
                 }
 
                 UserSession.userId = userId
                 UserSession.userName = userName
                 UserSession.userEmail = userEmail
                 UserSession.userPhoto = userPhoto
                 UserSession.userLoggedIn = true
 
                 Cookie.set('kgtopg.guruculum.user.id', userId, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.name', userName, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.email', userEmail, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.photo', userPhoto, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.phone', userPhone, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.role', role, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.login', userLoggedIn, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.companyid', userCompanyId, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.accname', userBankAccName, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.accnum', userBankAccNum, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.accifsc', userBankAccIFSC, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.bankbranch', userBankAccBranch, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.bankname', userBankName, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.contuserid', contUserId, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.pubuserid', pubUserId, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.Roles', userRoles, { expires: 90 })
 
                 return response;
 
             }
 
             else return false;
 
         } catch (ex) {
             console.log(ex);
             console.log(`exception ${ex.message}`)
             return false
         }
 
     },
 
 
     async doSwitchPublishLogin(body) {
         let url = Constants.Application.PARTNER_END_URL;
         url += "/verifying-details/";
 
         const token = Cookie.get('kgtopg.guruculum.user.token')
 
         try {
             const options = {
                 method: "POST",
                 data: body,
                 url,
                 headers: {
                     "Content-Type": "application/json",
                     Accept: "application/json",
                     "Authorization": "Bearer " + token,
                 },
             }
 
             const response = await axios(options);
 
             console.log("doSwitchPublishLogin api response ========================", response.data.data, response.data.role)
 
             if (response) {
                 const data = response.data
                 const role = data.role
                 let userId = 0
                 let userName = ""
                 let userEmail = ""
                 let userPhoto = ""
                 let userPhone = ""
                 let userCompanyId = ""
                 let userBankAccName = ""
                 let userBankAccNum = ""
                 let userBankAccIFSC = ""
                 let userBankAccBranch = ""
                 let userBankName = ""
                 let contUserId = 0
                 let pubUserId = 0
                 let userLoggedIn = false
                 let userRoles = ""
 
 
                 if (role == Constants.Application.ROLE_CONT_EBOOK_PUB) {
                     console.log("response in switch publisher", response.data.data)
                     userId = data.data.publisher_details.id
                     userName = data.data.publisher_details.pub_name_text
                     userEmail = data.data.publisher_details.pub_email_text
                     userPhoto = data.data.publisher_details.pub_logo
                     userPhone = data.data.publisher_details.phone_number
                     userLoggedIn = true
                     userCompanyId = data.data.publisher_details.company_id
                     userBankAccName = data.data.publisher_details.bank_account_name
                     userBankAccNum = data.data.publisher_details.bank_account_number
                     userBankAccIFSC = data.data.publisher_details.banck_account_IFSC
                     userBankAccBranch = data.data.publisher_details.bank_branch
                     userBankName = data.data.publisher_details.bank_name
                     pubUserId = data.data.publisher_details.user_id
                     userRoles = data.data.publisher_details.Roles
                 }
 
                 UserSession.userId = userId
                 UserSession.userName = userName
                 UserSession.userEmail = userEmail
                 UserSession.userPhoto = userPhoto
                 UserSession.userLoggedIn = true
 
                 Cookie.set('kgtopg.guruculum.user.id', userId, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.name', userName, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.email', userEmail, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.photo', userPhoto, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.phone', userPhone, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.role', role, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.login', userLoggedIn, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.companyid', userCompanyId, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.accname', userBankAccName, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.accnum', userBankAccNum, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.accifsc', userBankAccIFSC, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.bankbranch', userBankAccBranch, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.bankname', userBankName, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.contuserid', contUserId, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.pubuserid', pubUserId, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.Roles', userRoles, { expires: 90 })
 
                 return response;
 
             }
 
             else return false;
 
         } catch (ex) {
             console.log(ex);
             console.log(`exception ${ex.message}`)
             return false
         }
 
     },
 
 
 
     async doLogin(body) {
 
 
         let url = Constants.Application.PARTNER_END_URL;
         url += "/user-sign/";
 
         const token = Cookie.get('kgtopg.guruculum.user.token')
         // we wait
         try {
 
             console.log("login api url", url)
 
             const options = {
                 method: "POST",
                 data: body,
                 url,
                 headers: {
                     "Content-Type": "application/json",
                     Accept: "application/json",
                     'Authorization': 'Bearer ' + token,
                 },
 
             };
 
             const response = await axios(options);
 
             console.log("doLogin api response ========================", response.data.data, response.data.role)
 
 
 
 
             if (response) {
                 const data = response.data
                 const role = data.role
                 let userId = 0
                 let qBankId = 0;
                 let userName = ""
                 let userEmail = ""
                 let userPhoto = ""
                 let userPhone = ""
                 let userCompanyId = ""
                 let userBankAccName = ""
                 let userBankAccNum = ""
                 let userBankAccIFSC = ""
                 let userBankAccBranch = ""
                 let userBankName = ""
                 let contUserId = 0
                 let pubUserId = 0
                 let userLoggedIn = false
                 let userRoles = ""
 
 
                 // cont prov session
                 let userId2 = 0
                 let contUserName = ""
                 let contUserEmail = ""
                 let contUserPhoto = ""
                 let contUserPhone = ""
                 let contUserCompanyId = ""
                 let contUserBankAccName = ""
                 let contUserBankAccNum = ""
                 let contUserBankAccIFSC = ""
                 let contUserBankAccBranch = ""
                 let contUserBankName = ""
                 
 
 
                 // let SUPERADMIN = ""
                 // let CONTENTUPLOAD = ""
                 // let QUALITYASSURANCE = ""
                 // let CONTENTMAP = ""
                 // let CURRICULUMMAP = ""
                 // let TRAINER = ""
                 // let EBOOKUPLOAD = ""
 
                 const fullData = data.data;
                 //variables for both content provider and ebook publisher
 
                 if (role == Constants.Application.ROLE_CONT_EBOOK_PUB) {
                     console.log("response in both", response.data.data)
 
                     userId = data.data.publisher_details.id
                     qBankId = data.data.qbank_id;
                     userName = data.data.publisher_details.pub_name_text
                     userEmail = data.data.publisher_details.pub_email_text
                     userPhoto = data.data.publisher_details.pub_logo
                     userPhone = data.data.publisher_details.phone_number
                     userLoggedIn = true
                     userCompanyId = data.data.publisher_details.company_id
                     userBankAccName = data.data.publisher_details.bank_account_name
                     userBankAccNum = data.data.publisher_details.bank_account_number
                     userBankAccIFSC = data.data.publisher_details.banck_account_IFSC
                     userBankAccBranch = data.data.publisher_details.bank_branch
                     userBankName = data.data.publisher_details.bank_name
                     pubUserId = data.data.publisher_details.user_id
                     userRoles = data.data.publisher_details.Roles
                     contUserId = data.data.Provider_details.user_id
 
 
                     userId2 = fullData.Provider_details.id;
                     contUserName = fullData.Provider_details.prov_name_text
                     contUserEmail = fullData.Provider_details.prov_email_text
                     contUserPhoto = fullData.Provider_details.prov_cover_url
                     contUserPhone = fullData.Provider_details.phone_number
                     contUserCompanyId = fullData.Provider_details.company_id
                     contUserBankAccName = fullData.Provider_details.bank_account_name
                     contUserBankAccNum = fullData.Provider_details.bank_account_number
                     contUserBankAccIFSC = fullData.Provider_details.banck_account_IFSC
                     contUserBankAccBranch = fullData.Provider_details.bank_branch
                     contUserBankName = fullData.Provider_details.bank_name
 
 
                 }
 
 
                 if (role == Constants.Application.ROLE_CONT_PROV) {
                     userId = userId2 = data.data.content_prov_details.id
                     qBankId = data.data.qbank_id;
                     userName = contUserName =data.data.content_prov_details.prov_name_text
                     userEmail = contUserEmail = data.data.content_prov_details.prov_email_text
                     userPhoto = contUserPhoto = data.data.content_prov_details.prov_cover_url
                     userPhone = contUserPhone = data.data.content_prov_details.phone_number
                     userLoggedIn = true
                     userCompanyId = contUserCompanyId = data.data.content_prov_details.company_id
                     userBankAccName = contUserBankAccName = data.data.content_prov_details.bank_account_name
                     userBankAccNum = contUserBankAccNum = data.data.content_prov_details.bank_account_number
                     userBankAccIFSC = contUserBankAccIFSC = data.data.content_prov_details.banck_account_IFSC
                     userBankAccBranch = contUserBankAccBranch = data.data.content_prov_details.bank_branch
                     userBankName = contUserBankName = data.data.content_prov_details.bank_name
                     userRoles = data.data.content_prov_details.Roles
                     contUserId = data.data.content_prov_details.user_id
                     // SUPERADMIN = userRoles.includes("SUPERADMIN")
                     // CONTENTUPLOAD = userRoles.includes("CONTENTUPLOAD")
                     // QUALITYASSURANCE = userRoles.includes("QUALITYASSURANCE")
                     // CONTENTMAP = userRoles.includes("CONTENTMAP")
                     // CURRICULUMMAP = userRoles.includes("CURRICULUMMAP")
                     // TRAINER = userRoles.includes("TRAINER")
                     // EBOOKUPLOAD = userRoles.includes("EBOOKUPLOAD")
 
                 }
 
 
 
                 else if (role == Constants.Application.ROLE_EBOOK_PUB) {
                     userId = data.data.ebook_prov_details.id
                     qBankId = data.data.qbank_id;
                     userName = data.data.ebook_prov_details.pub_name_text
                     userEmail = data.data.ebook_prov_details.pub_email_text
                     userPhoto = data.data.ebook_prov_details.pub_logo
                     userPhone = data.data.ebook_prov_details.phone_number
                     userLoggedIn = true
                     userCompanyId = data.data.ebook_prov_details.company_id
                     userBankAccName = data.data.ebook_prov_details.bank_account_name
                     userBankAccNum = data.data.ebook_prov_details.bank_account_number
                     userBankAccIFSC = data.data.ebook_prov_details.banck_account_IFSC
                     userBankAccBranch = data.data.ebook_prov_details.bank_branch
                     userBankName = data.data.ebook_prov_details.bank_name
                     pubUserId = data.data.ebook_prov_details.user_id
                     userRoles = data.data.ebook_prov_details.Roles
                     // SUPERADMIN = data.data.ebook_prov_details.Roles.includes("SUPERADMIN")
                     // CONTENTUPLOAD = data.data.ebook_prov_details.Roles.includes("CONTENTUPLOAD")
                     // QUALITYASSURANCE = data.data.ebook_prov_details.Roles.includes("QUALITYASSURANCE")
                     // CONTENTMAP = data.data.ebook_prov_details.Roles.includes("CONTENTMAP")
                     // CURRICULUMMAP = data.data.ebook_prov_details.Roles.includes("CURRICULUMMAP")
                     // TRAINER = data.data.ebook_prov_details.Roles.includes("TRAINER")
                     // EBOOKUPLOAD = data.data.ebook_prov_details.Roles.includes("EBOOKUPLOAD")
 
                 }
 
 
                 // update in session
                 UserSession.userId = userId
                 UserSession.userName = userName
                 UserSession.userEmail = userEmail
                 UserSession.userPhoto = userPhoto
                 UserSession.userLoggedIn = true
 
                 Cookie.set('kgtopg.guruculum.user.id', userId, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.qbank.id', qBankId, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.name', userName, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.email', userEmail, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.photo', userPhoto, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.phone', userPhone, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.role', role, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.login', userLoggedIn, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.companyid', userCompanyId, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.accname', userBankAccName, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.accnum', userBankAccNum, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.accifsc', userBankAccIFSC, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.bankbranch', userBankAccBranch, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.bankname', userBankName, { expires: 90 });
 
 
                 Cookie.set('kgtopg.guruculum.cont.id', userId2, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.cont.name', contUserName, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.cont.email', contUserEmail, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.cont.photo', contUserPhoto, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.cont.phone', contUserPhone, { expires: 90 });
                 
                 Cookie.set('kgtopg.guruculum.cont.companyid', contUserCompanyId, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.cont.accname', contUserBankAccName, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.cont.accnum', contUserBankAccNum, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.cont.accifsc', contUserBankAccIFSC, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.cont.bankbranch', contUserBankAccBranch, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.cont.bankname', contUserBankName, { expires: 90 });
 
 
                 Cookie.set('kgtopg.guruculum.user.contuserid', contUserId, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.pubuserid', pubUserId, { expires: 90 });
                 Cookie.set('kgtopg.guruculum.user.Roles', userRoles, { expires: 90 })
                 // Cookie.set('kgtopg.guruculum.user.SUPERADMIN', SUPERADMIN);
                 // Cookie.set('kgtopg.guruculum.user.CONTENTUPLOAD', CONTENTUPLOAD);
                 // Cookie.set('kgtopg.guruculum.user.QUALITYASSURANCE', QUALITYASSURANCE);
                 // Cookie.set('kgtopg.guruculum.user.CONTENTMAP', CONTENTMAP);
                 // Cookie.set('kgtopg.guruculum.user.EBOOKUPLOAD', EBOOKUPLOAD);
                 // Cookie.set('kgtopg.guruculum.user.CURRICULUMMAP', CURRICULUMMAP);
                 // Cookie.set('kgtopg.guruculum.user.TRAINER', TRAINER);
 
 
 
                 console.log("cookies data id+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.id'))
                 console.log("cookies data qbankid+++++++++++++++++", Cookie.get('kgtopg.guruculum.qbank.id'))
                 console.log("cookies data name+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.name'))
                 console.log("cookies data email+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.email'))
                 console.log("cookies data photo+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.photo'))
                 console.log("cookies data phone+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.phone'))
                 console.log("cookies data role+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.role'))
                 console.log("cookies data login+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.login'))
                 console.log("cookies data login+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.accname'))
                 console.log("cookies data login+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.accnum'))
                 console.log("cookies data login+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.accifsc'))
                 console.log("cookies data login+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.bankbranch'))
                 console.log("cookies data login+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.bankname'))
                 console.log("cookies data login+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.pubuserid'))
                 console.log("cookies data  Roles at login+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.Roles'))
 
                 // console.log("cookies data SUPERADMIN+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.SUPERADMIN'))
                 // console.log("cookies data CONTENTUPLOAD+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.CONTENTUPLOAD'))
                 // console.log("cookies data QUALITYASSURANCE+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.QUALITYASSURANCE'))
                 // console.log("cookies data CONTENTMAP+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.CONTENTMAP'))
                 // console.log("cookies data EBOOKUPLOAD+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.EBOOKUPLOAD'))
                 // console.log("cookies data CURRICULUMMAP+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.CURRICULUMMAP'))
                 // console.log("cookies data TRAINER+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.TRAINER'))
 
                 // both content provider and book publisher cookies 
 
 
 
                 return response;
 
             }
 
 
             else return false;
         }
         catch (ex) {
             console.log(ex);
             console.log(`exception ${ex.message}`)
             return false
         }
 
     },
 
 
     async doAuth(body) {
 
 
         const headers = {
             "Content-Type": "application/x-www-form-urlencoded",
             Accept: "*/*",
         };
 
         let url = Constants.Application.PARTNER_END_URL;
         url += "/user-sign/";
 
         // we wait
         try {
 
             console.log("login authentication api url", url)
 
             const options = {
                 method: "POST",
                 data: body,
                 url,
                 headers: {
                     "Content-Type": "application/json",
                     Accept: "application/json",
                     // 'Authorization': 'Bearer ' + UserSession.authtoken,
                 },
 
             };
 
 
             console.log("options==========", options)
             let response = await axios(options);
 
             console.log("doLogin Auth api response", response)
 
 
             if (response) return response;
             else return false;
         }
         catch (ex) {
             // console.log(ex);
             // console.log(`exception ${ex.message}`)
             return false
         }
 
     },
 
 
     async doRegisterContProv(body) {
 
 
         const headers = {
             "Content-Type": "application/x-www-form-urlencoded",
             Accept: "*/*",
         };
 
         let url = Constants.Application.PLATFORM_END_URL;
         url += "/save-prov-user/";
 
         // we wait
         try {
 
             console.log("login authentication api url", url)
 
             const options = {
                 method: "POST",
                 data: body,
                 url,
                 headers: {
                     "Content-Type": "application/json",
                     Accept: "application/json",
                     // 'Authorization': 'Bearer ' + UserSession.authtoken,
                 },
 
             };
 
 
             console.log("options==========", options)
             let response = await axios(options);
 
             console.log("doLogin Auth api response", response)
 
 
             if (response) return response;
             else return false;
         }
         catch (ex) {
             // console.log(ex);
             // console.log(`exception ${ex.message}`)
             return false
         }
 
     },
 
 
     async doRegisterBookPub(body) {
 
 
         const headers = {
             "Content-Type": "application/x-www-form-urlencoded",
             Accept: "*/*",
         };
 
         let url = Constants.Application.PLATFORM_END_URL;
         url += "/save-pub-user/";
 
         // we wait
         try {
 
             console.log("login authentication api url", url)
 
             const options = {
                 method: "POST",
                 data: body,
                 url,
                 headers: {
                     "Content-Type": "application/json",
                     Accept: "application/json",
                     // 'Authorization': 'Bearer ' + UserSession.authtoken,
                 },
 
             };
 
 
             console.log("options==========", options)
             let response = await axios(options);
 
             console.log("doLogin Auth api response", response)
 
 
             if (response) return response;
             else return false;
         }
         catch (ex) {
             // console.log(ex);
             // console.log(`exception ${ex.message}`)
             return false
         }
 
     },
     async doRegisterBoth(body) {
 
 
         const headers = {
             "Content-Type": "application/x-www-form-urlencoded",
             Accept: "*/*",
         };
 
         let url = Constants.Application.PLATFORM_END_URL;
         url += "/save-both-users/";
 
         // we wait
         try {
 
             console.log("login authentication api url", url)
 
             const options = {
                 method: "POST",
                 data: body,
                 url,
                 headers: {
                     "Content-Type": "application/json",
                     Accept: "application/json",
                     // 'Authorization': 'Bearer ' + UserSession.authtoken,
                 },
 
             };
 
 
             console.log("options==========", options)
             let response = await axios(options);
 
             console.log("doLogin Auth api response", response)
 
 
             if (response) return response;
             else return false;
         }
         catch (ex) {
             // console.log(ex);
             // console.log(`exception ${ex.message}`)
             return false
         }
 
     },
 
     async userChangePswrd(body) {
 
         let url = Constants.Application.PLATFORM_END_URL;
         url += "/update-password/";
 
         // we wait
         try {
 
             console.log("user Change password api url", url)
 
             const options = {
                 method: "POST",
                 data: body,
                 url,
                 headers: {
                     "Content-Type": "application/json",
                     Accept: "application/json",
                     // 'Authorization': 'Bearer ' + UserSession.authtoken,
                 },
 
             };
 
 
             let response = await axios(options);
             console.log("change password api response", response)
 
 
             if (response) return response;
             else return false;
         }
         catch (ex) {
             // console.log(ex);
             // console.log(`exception ${ex.message}`)
             return false
         }
 
     },
 
 
     async usrForgotPswrd(body) {
 
         let url = Constants.Application.PLATFORM_END_URL;
         url += "/forgot-password/";
 
         // we wait
         try {
 
             console.log("user Change password api url", url)
 
             const options = {
                 method: "POST",
                 data: body,
                 url,
                 headers: {
                     "Content-Type": "application/json",
                     Accept: "application/json",
                     // 'Authorization': 'Bearer ' + UserSession.authtoken,
                 },
 
             };
 
 
             let response = await axios(options);
             console.log("doLogin Auth api response", response)
 
 
             if (response) return response;
             else return false;
         }
         catch (ex) {
             // console.log(ex);
             // console.log(`exception ${ex.message}`)
             return false
         }
 
     },
 
 
 
 
 }