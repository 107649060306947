import { COMPANYDETCONTGET_LIST_FAIL, COMPANYDETCONTGET_LIST_REQUEST, COMPANYDETCONTGET_LIST_SUCCESS, COMPANYDETCONTPOST_LIST_FAIL, COMPANYDETCONTPOST_LIST_SUCCESS, COMPANYDETPCONTPOST_LIST_REQUEST, COMPANYDETPPUBPOST_LIST_REQUEST, COMPANYDETPUBGET_LIST_FAIL, COMPANYDETPUBGET_LIST_REQUEST, COMPANYDETPUBGET_LIST_SUCCESS, COMPANYDETPUBPOST_LIST_FAIL, COMPANYDETPUBPOST_LIST_SUCCESS } from "../constants/CompanyProfile";

function companyDetPubGetReducer(state = { CompPubGet: [] }, action) {

    switch (action.type) {
        case COMPANYDETPUBGET_LIST_REQUEST:
            return { loadingCompPubGet: true }
        case COMPANYDETPUBGET_LIST_SUCCESS:
            return { loadingCompPubGet: false, CompPubGet: action.payload };
        case COMPANYDETPUBGET_LIST_FAIL:
            return { loadingCompPubGet: false, errorCompPubGet: action.payload }
        default:
            return state;
    }

}


function companyDetContGetReducer(state = { CompContGet: [] }, action) {

    switch (action.type) {
        case COMPANYDETCONTGET_LIST_REQUEST:
            return { loadingCompContGet: true }
        case COMPANYDETCONTGET_LIST_SUCCESS:
            return { loadingCompContGet: false, CompContGet: action.payload };
        case COMPANYDETCONTGET_LIST_FAIL:
            return { loadingCompContGet: false, errorCompContGet: action.payload }
        default:
            return state;
    }

}

function companyDetPubPostreducer(state = { CompPubPost: [] }, action) {

    switch (action.type) {
        case COMPANYDETPPUBPOST_LIST_REQUEST:
            return { loadingCompPubPost: true }
        case COMPANYDETPUBPOST_LIST_SUCCESS:
            return { loadingCompPubPost: false, CompPubPost: action.payload };
        case COMPANYDETPUBPOST_LIST_FAIL:
            return { loadingCompPubPost: false, errorCompPubPost: action.payload }
        default:
            return state;
    }

}

function companyDevContPostreducer(state = { CompContPost: [] }, action) {

    switch (action.type) {
        case COMPANYDETPCONTPOST_LIST_REQUEST:
            return { loadingAllSrcEvents: true }
        case COMPANYDETCONTPOST_LIST_SUCCESS:
            return { loadingAllSrcEvents: false, allEventsSrcGetData: action.payload };
        case COMPANYDETCONTPOST_LIST_FAIL:
            return { loadingAllSrcEvents: false, errorAllSrcEvents: action.payload }
        default:
            return state;
    }

}




export {
    companyDetPubGetReducer,
    companyDetContGetReducer,
    companyDetPubPostreducer,
    companyDevContPostreducer,
}