import React from "react";
import moment from "moment";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Cookie from "js-cookie";
import { useEffect, useState } from "react";
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import Constants from "../../../resource/Constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  listProfilUpdateCont,
  listProfilUpdatePub,
} from "../../../redux/actions/ProfileAction";
import { useDispatch } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function Alert2(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TAG = "AccountProfile.js";

const user = {
  avatar: "/static/images/avatars/avatar_6.png",
  city: "Los Angeles",
  country: "USA",
  jobTitle: "Senior Developer",
  name: "Katarina Smith",
  timezone: "GTM-7",
};

const config = {
  apiKey: Constants.Application.FIREBASE.API_KEY,
  authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
  //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
  storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET,
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}

function AccountProfile(props) {
  const [crop, setCrop] = useState({ aspect: 16 / 9 });

  const userId = Cookie.get("kgtopg.guruculum.user.id");
  const userName = Cookie.get("kgtopg.guruculum.user.name");
  const userEmail = Cookie.get("kgtopg.guruculum.user.email");
  const userPhoto = Cookie.get("kgtopg.guruculum.user.photo");
  const userPhone = Cookie.get("kgtopg.guruculum.user.phone");
  const userRole = Cookie.get("kgtopg.guruculum.user.role");
  const userLogin = Cookie.get("kgtopg.guruculum.user.login");
  const userCompanyId = Cookie.get("kgtopg.guruculum.user.companyid");
  const accname = Cookie.get("kgtopg.guruculum.user.accname");
  const accnum = Cookie.get("kgtopg.guruculum.user.accnum");
  const accifsc = Cookie.get("kgtopg.guruculum.user.accifsc");
  const bankbranch = Cookie.get("kgtopg.guruculum.user.bankbranch");
  const bankname = Cookie.get("kgtopg.guruculum.user.bankname");
  const contuserid = Cookie.get("kgtopg.guruculum.user.contuserid");
  const pubuserid = Cookie.get("kgtopg.guruculum.user.pubuserid");

  const [snackOpen, setSnackOpen] = React.useState(false);

  const handleSnackClick = () => {
    setOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const history = useHistory();
  const dispatch = useDispatch();
  // console.log("props at AccountProfile", props);

  const [open, setOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [ImageDownloadUrls, setImageDownloadUrls] = useState("");

  const [Id, setId] = useState(userId);
  const [firstName, setFirstName] = useState(userName);
  const [email, setEmail] = useState(userEmail);
  const [phone, setPhone] = useState(userPhone);
  const [photo, setPhoto] = useState(userPhoto);
  const [Role, setRole] = useState(userRole);
  const [login, setLogin] = useState(userLogin);
  const [companyId, setCompanyId] = useState(userCompanyId);
  const [accName, setAccName] = useState(accname);
  const [accNum, setAccNum] = useState(accnum);
  const [accBank, setAccBank] = useState(bankname);
  const [accIfsc, setAccIfsc] = useState(accifsc);
  const [accBranch, setAccBranch] = useState(bankbranch);
  const [contId, setContId] = useState(contuserid);
  const [pubId, setPubId] = useState(pubuserid);

  const role = Cookie.get("kgtopg.guruculum.user.role");
  // const pubuserid = props.pubuserid

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUploadStart = () => {

    // verify phone number and name
    if(!firstName || !phone) {
      setSnackOpen(true);
      setIsUploading(false);
      setProgress(0);
    } else {
      setIsUploading(true);

      console.log(`progress at ${0}`);
      setProgress(0);
    }
  };

  const handleUploadError = (error) => {
    console.error(`upload error ${JSON.stringify(error)}`);

    setSnackOpen(true);
    setIsUploading(false);
    setProgress(0);
  };

  const handleProgress = (progress) => {
    console.log(`progress at ${progress}`);
    setProgress(progress);
  };

  const handleUploadSingleSuccess = async (filename) => {
    if(!firstName || !phone) {
      
      setSnackOpen(true);
      setIsUploading(false);
      setProgress(0);
      return;
    }

    const downloadURL = await firebase
      .storage()
      .ref("ebook")
      .child(filename)
      .getDownloadURL();

    console.log("download url is =====", downloadURL);
    console.log(TAG, "role is ", role);

    if (downloadURL) {
      setProgress(0);
    }

    setImageDownloadUrls(downloadURL);

    // not necessary
    let accName2 = "1";
    let accNum2 = "1";
    let accIfsc2 = "1";
    let accBranch2 = "1";
    let accBank2 = "1";
    if (downloadURL) {
      let userId = 0;
      let userName = "";
      let userEmail = "";
      let userPhoto = "";
      let userPhone = "";
      let userCompanyId = "";
      let userBankAccName = "";
      let userBankAccNum = "";
      let userBankAccIFSC = "";
      let userBankAccBranch = "";
      let userBankName = "";
      let pubUserId = 0;
      let contuserid = 0;
      let userLoggedIn = false;
      let userRoles = "";

      if (
        role == "EBOOK_PROVIDER" ||
        role === "CONTENT_PROVIDER AND PUBLISHER"
      ) {
        userId = Id;
        userName = firstName;
        userEmail = email;
        userPhoto = downloadURL;
        userPhone = phone;
        userLoggedIn = true;
        userCompanyId = companyId;
        userBankAccName = accName2;
        userBankAccNum = accNum2;
        userBankAccIFSC = accIfsc2;
        userBankAccBranch = accBranch2;
        userBankName = accBank2;
        userRoles = Role;

        const body = {
          user_id: userId,
          pub_name_text: firstName,
          pub_email_text: email,
          pub_desc: "some",
          pub_logo: downloadURL,
          company_id: companyId,
          bank_account_name: accName2,
          bank_account_number: accNum2,
          banck_account_IFSC: accIfsc2,
          bank_branch: accBranch2,
          bank_name: accBank2,
          phone_number: phone,
        };
        Cookie.set("kgtopg.guruculum.user.id", userId);
        Cookie.set("kgtopg.guruculum.user.name", userName);
        Cookie.set("kgtopg.guruculum.user.email", userEmail);
        Cookie.set("kgtopg.guruculum.user.photo", userPhoto);
        Cookie.set("kgtopg.guruculum.user.phone", userPhone);
        Cookie.set("kgtopg.guruculum.user.companyid", userCompanyId);
        Cookie.set("kgtopg.guruculum.user.accname", userBankAccName);
        Cookie.set("kgtopg.guruculum.user.accnum", userBankAccNum);
        Cookie.set("kgtopg.guruculum.user.accifsc", userBankAccIFSC);
        Cookie.set("kgtopg.guruculum.user.bankbranch", userBankAccBranch);
        Cookie.set("kgtopg.guruculum.user.bankname", userBankName);

        dispatch(listProfilUpdatePub(body));
      }

      if (role == "CONTENT_PROVIDER") {
        userId = Id;
        userName = firstName;
        userEmail = email;
        userPhoto = downloadURL;
        userPhone = phone;
        userLoggedIn = true;
        userCompanyId = companyId;
        contuserid = contId;
        userBankAccName = accName2;
        userBankAccNum = accNum2;
        userBankAccIFSC = accIfsc2;
        userBankAccBranch = accBranch2;
        userBankName = accBank2;
        userRoles = Role;

        const body = {
          prov_name_text: firstName,
          prov_email_text: email,
          prov_cover_url: downloadURL,
          company_id: companyId,
          bank_account_name: accName2,
          bank_account_number: accNum2,
          banck_account_IFSC: accIfsc2,
          bank_branch: accBranch2,
          bank_name: accBank2,
          phone_number: phone,
          user_id: contId,
        };

        Cookie.set("kgtopg.guruculum.user.id", userId);
        Cookie.set("kgtopg.guruculum.user.name", userName);
        Cookie.set("kgtopg.guruculum.user.email", userEmail);
        Cookie.set("kgtopg.guruculum.user.photo", userPhoto);
        Cookie.set("kgtopg.guruculum.user.phone", userPhone);
        Cookie.set("kgtopg.guruculum.user.companyid", userCompanyId);
        Cookie.set("kgtopg.guruculum.user.accname", userBankAccName);
        Cookie.set("kgtopg.guruculum.user.accnum", userBankAccNum);
        Cookie.set("kgtopg.guruculum.user.accifsc", userBankAccIFSC);
        Cookie.set("kgtopg.guruculum.user.bankbranch", userBankAccBranch);
        Cookie.set("kgtopg.guruculum.user.bankname", userBankName);

        console.log("download url in if condition at content provider", body);

        dispatch(listProfilUpdateCont(body));
      }

      // Cookie.set('kgtopg.guruculum.user.id', userId);
      // Cookie.set('kgtopg.guruculum.user.name', userName);
      // Cookie.set('kgtopg.guruculum.user.email', userEmail);
      // Cookie.set('kgtopg.guruculum.user.photo', userPhoto);
      // Cookie.set('kgtopg.guruculum.user.phone', userPhone);
      // Cookie.set('kgtopg.guruculum.user.accname', userBankAccName);
      // Cookie.set('kgtopg.guruculum.user.accnum', userBankAccNum);
      // Cookie.set('kgtopg.guruculum.user.accifsc', userBankAccIFSC);
      // Cookie.set('kgtopg.guruculum.user.bankbranch', userBankAccBranch);
      // Cookie.set('kgtopg.guruculum.user.bankname', userBankName);
      // Cookie.set('kgtopg.guruculum.user.pubuserid', pubUserId);

      // Cookie.set('kgtopg.guruculum.user.Roles', userRoles)
    }
    // let userPhoto = downloadURL

    // Cookie.set('kgtopg.guruculum.user.photo', userPhoto);
  };

  const LogoutUser = (props) => {
    const role = "";
    let userId = 0;
    let userName = "";
    let userEmail = "";
    let userPhoto = "";
    let userPhone = "";
    let userLoggedIn = false;
    let pubUserId = 0;
    let userRoles = "";

    //company details
    let companyName = "";
    let companyEmail = "";
    let companyWebsite = "";
    let companyLogo = "";
    let companyAddress = "";
    let companyPan = "";
    let companyGst = "";
    let companyPhone = "";
    let companyBankAccName = "";
    let companyBankAccNum = "";
    let companyBankAccIFSC = "";
    let companyBankAccBranch = "";
    let companyBankName = "";
    // let SUPERADMIN = ""
    // let CONTENTUPLOAD = ""
    // let QUALITYASSURANCE = ""
    // let CONTENTMAP = ""
    // let CURRICULUMMAP = ""
    // let TRAINER = ""
    // let EBOOKUPLOAD =""

    Cookie.set("kgtopg.guruculum.user.id", userId);
    Cookie.set("kgtopg.guruculum.user.name", userName);
    Cookie.set("kgtopg.guruculum.user.email", userEmail);
    Cookie.set("kgtopg.guruculum.user.photo", userPhoto);
    Cookie.set("kgtopg.guruculum.user.phone", userPhone);
    Cookie.set("kgtopg.guruculum.user.role", role);
    Cookie.set("kgtopg.guruculum.user.login", userLoggedIn);
    Cookie.set("kgtopg.guruculum.user.pubuserid", pubUserId);
    Cookie.set("kgtopg.guruculum.user.Roles", userRoles);

    Cookie.set("kgtopg.guruculum.company.name", companyName);
    Cookie.set("kgtopg.guruculum.company.email", companyEmail);
    Cookie.set("kgtopg.guruculum.company.logo", companyLogo);
    Cookie.set("kgtopg.guruculum.company.phone", companyPhone);
    Cookie.set("kgtopg.guruculum.company.website", companyWebsite);
    Cookie.set("kgtopg.guruculum.company.address", companyAddress);
    Cookie.set("kgtopg.guruculum.company.pan", companyPan);
    Cookie.set("kgtopg.guruculum.company.gst", companyGst);
    Cookie.set("kgtopg.guruculum.company.accname", companyBankAccName);
    Cookie.set("kgtopg.guruculum.company.accnum", companyBankAccNum);
    Cookie.set("kgtopg.guruculum.company.accifsc", companyBankAccIFSC);
    Cookie.set("kgtopg.guruculum.company.bankbranch", companyBankAccBranch);
    Cookie.set("kgtopg.guruculum.company.bankname", companyBankName);

    // Cookie.set('kgtopg.guruculum.user.SUPERADMIN', SUPERADMIN);
    // Cookie.set('kgtopg.guruculum.user.CONTENTUPLOAD', CONTENTUPLOAD);
    // Cookie.set('kgtopg.guruculum.user.QUALITYASSURANCE', QUALITYASSURANCE);
    // Cookie.set('kgtopg.guruculum.user.CONTENTMAP', CONTENTMAP);
    // Cookie.set('kgtopg.guruculum.user.EBOOKUPLOAD', EBOOKUPLOAD);
    // Cookie.set('kgtopg.guruculum.user.CURRICULUMMAP', CURRICULUMMAP);
    // Cookie.set('kgtopg.guruculum.user.TRAINER', TRAINER);

    console.log(
      "cookies data id+++++++++++++++++",
      Cookie.get("kgtopg.guruculum.user.id")
    );
    console.log(
      "cookies data name+++++++++++++++++",
      Cookie.get("kgtopg.guruculum.user.name")
    );
    console.log(
      "cookies data email+++++++++++++++++",
      Cookie.get("kgtopg.guruculum.user.email")
    );
    console.log(
      "cookies data photo+++++++++++++++++",
      Cookie.get("kgtopg.guruculum.user.photo")
    );
    console.log(
      "cookies data role+++++++++++++++++",
      Cookie.get("kgtopg.guruculum.user.role")
    );
    console.log(
      "cookies data login+++++++++++++++++",
      Cookie.get("kgtopg.guruculum.user.login")
    );

    console.log(
      "cookies data Roles at logout+++++++++++++++++",
      Cookie.get("kgtopg.guruculum.user.Roles")
    );
    // console.log("cookies data SUPERADMIN+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.SUPERADMIN'))
    // console.log("cookies data CONTENTUPLOAD+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.CONTENTUPLOAD'))
    // console.log("cookies data QUALITYASSURANCE+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.QUALITYASSURANCE'))
    // console.log("cookies data CONTENTMAP+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.CONTENTMAP'))
    // console.log("cookies data EBOOKUPLOAD+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.EBOOKUPLOAD'))
    // console.log("cookies data CURRICULUMMAP+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.CURRICULUMMAP'))
    // console.log("cookies data TRAINER+++++++++++++++++", Cookie.get('kgtopg.guruculum.user.TRAINER'))

    history.push("/");
  };

  return (
    <div data-tut="profile-step">
      <Card {...props}>
        <CardContent>
          <Box
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {props.userPhoto ? (
              // <ReactCrop src={(ImageDownloadUrls) ? ImageDownloadUrls : props.userPhoto}
              //   crop={crop} onChange={newCrop => setCrop(newCrop)} />

              <img
                src={ImageDownloadUrls ? ImageDownloadUrls : props.userPhoto}
                style={{
                  height: 225,
                  width: 225,
                  borderRadius: 10,
                  objectFit: "contain",
                }}
              />
            ) : (
              <Avatar
                src={props.userPhoto}
                style={{
                  height: 225,
                  width: 225,
                  borderRadius: 10,
                  objectFit: "contain",
                }}
              />
            )}

            <Typography
              color="textPrimary"
              gutterBottom
              variant="h3"
              style={{ marginTop: 10 }}
            >
              {props.userName}
            </Typography>

            {/* <Typography
              color="textSecondary"
              variant="body1"
            >
              {`${user.city} ${user.country}`}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
            >
              {`${moment().format('hh:mm A')} ${user.timezone}`}
            </Typography> */}
          </Box>
        </CardContent>
        <Divider />
        <CardActions style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginLeft: 20,
            }}
          >
            {progress ? (
              <CircularProgress />
            ) : (
              <label
                style={{
                  fontFamily: "Poppins, Helvetica, sans-serif",
                  color: "steelblue",
                  borderRadius: 4,
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                UPLOAD PICTURE
                <FileUploader
                  hidden
                  // accept="image/*"
                  accept="image/x-png,image/gif,image/jpeg"
                  storageRef={firebase.storage().ref("ebook")}
                  onUploadStart={handleUploadStart}
                  onUploadError={handleUploadError}
                  onUploadSuccess={handleUploadSingleSuccess}
                  onProgress={handleProgress}
                />
                <Snackbar
                  open={snackOpen}
                  autoHideDuration={6000}
                  onClose={handleSnackClose}
                >
                  <Alert2 onClose={handleSnackClose} severity="error">
                    There was an error! Please see to it your profile was already saved!
                  </Alert2>
                </Snackbar>
              </label>
            )}
          </div>
        </CardActions>
      </Card>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: 15,
        }}
        data-tut="logout-step"
      >
        <Button
          variant="contained"
          color="primary"
          style={{ textTransform: "none" }}
          onClick={handleClickOpen}
          fullWidth
        >
          Logout
        </Button>
      </div>

      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Logout"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure,do you want to logout ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button
              onClick={(props) => {
                LogoutUser(props);
              }}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default AccountProfile;
