import axios from "axios";
import Constants from "../../resource/Constants";
import { INSTDETAILS_LIST_FAIL, INSTDETAILS_LIST_REQUEST, INSTDETAILS_LIST_SUCCESS } from "../constants/instituteDetailsConstants";




const listInstGet = () => async (dispatch) => {
    try {
        dispatch({ type: INSTDETAILS_LIST_REQUEST })

        const { data } = await axios.get(Constants.Application.PLATFORM_END_URL+"/institute-type/")
        console.log("data in list inst get", data)
        dispatch({ type: INSTDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: INSTDETAILS_LIST_FAIL, payload: error.message })

    }
}


export { listInstGet }