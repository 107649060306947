import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Chip from "@material-ui/core/Chip";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import { Route, Link, BrowserRouter, Switch, Redirect } from "react-router-dom";
import PublisherAPI from "../../http/publisher";

import {
  Avatar,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Box, Container } from "@material-ui/core";
import MapListResults from "./MapListResults";
import MapListToolbar from "./MapListToolbar";
import customers from "../../__mocks__/customers";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { Edit } from "react-feather";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import moment from "moment";

/* accordion elements from mui */
import Accordion2 from "@mui/material/Accordion";
import AccordionDetails2 from "@mui/material/AccordionDetails";
import AccordionSummary2 from "@mui/material/AccordionSummary";
import Typography2 from "@mui/material/Typography";
import ExpandMoreIcon2 from "@mui/icons-material/ExpandMore";

import { useDispatch, useSelector } from "react-redux";
import { listUnitMapGet } from "../../redux/actions/unitMapAction";
import { UnitMapReducer } from "../../redux/reducers/unitMapReducer";
import {
  listCurriculumGet,
  filterMaps,
} from "../../redux/actions/curriculumMapAction";
import { CurriculumMapReducer } from "../../redux/reducers/CurriculumMapReducer";
import { updateCurrUnit } from "../../redux/actions/currUnitActions";
import Cookie from "js-cookie";
import LeftCard from "./slots/LeftCard";
import { updateCurrMap } from "../../redux/actions/currMapActions";
import { Alert, AlertTitle } from "@material-ui/lab";
import { getUnitLessons } from "../../redux/actions/unitLessonsActions";
import Copyright from "../user/slots/Copyright";
import { listChpGet } from "../../redux/actions/chapterAction";
import { listCurriculumAllGet } from "../../redux/actions/GetAllFilesAction";
import AdminControls2 from "./slots/AdminControls/AdminControlsPage2";
// const useStyles = makeStyles({

//   root: {
//     flexGrow: 1,
//   },
// });

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  shape: {
    backgroundColor: theme.palette.primary.main,
    width: 20,
    height: 20,
  },
  shape2: {
    backgroundColor: theme.palette.secondary.dark,
    width: 20,
    height: 20,
  },
  shapeCircle: {
    borderRadius: "50%",
  },
}));

const drawerWidth = 250;

const useStyles1 = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100%",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbar1: {
    paddingRight: 24,
    height: 40,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbarIcon2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbarIcon1: {
    top: "45vh",
    bottom: 0,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    // marginLeft:70,
    backgroundColor: "#1976d2",
    color: "#ffffff",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: "blue",
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    fontSize: 14,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: "#ffffff",
    scrollbarColor: "green",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    minHeight: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  container: {
    paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  large: {
    width: "80%",
    height: 50,
    borderRadius: 0,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography> {children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

const OverviewBox = (props) => {
  const classes = useStyles();

  console.log("props at overviewbox", props);

  let count = 0;

  if (props.lessonCount > 0) {
    count = props.lessonCount;
  }

  return (
    // <div className={classes.root} style={{
    //   width: '100%'
    // }}>
    //   <Grid container spacing={3}>

    //     <Grid item xs={6}>
    //       <Paper className={classes.paper}>xs=6</Paper>
    //     </Grid>
    //     <Grid item xs={6}>
    //       <Paper className={classes.paper}>xs=6</Paper>
    //     </Grid>

    //   </Grid>

    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        {/* <LibraryBooksIcon style={{ fontFamily: "Poppins, Helvetica, sans-serif" }} />&nbsp; */}
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="body2"
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          UNITS OVERVIEW
        </Typography>
      </div>
      <div style={{}}>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="body2"
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          LESSONS {count}
        </Typography>
      </div>
    </div>

    // </div>
  );
};

const StandardsBox = () => {
  const classes = useStyles();

  return (
    // <div className={classes.root} style={{
    //   width: '100%'
    // }}>
    //   <Grid container spacing={3}>

    //     <Grid item xs={6}>
    //       <Paper className={classes.paper}>xs=6</Paper>
    //     </Grid>
    //     <Grid item xs={6}>
    //       <Paper className={classes.paper}>xs=6</Paper>
    //     </Grid>

    //   </Grid>

    <>
      <Typography
        // align="center"
        color="textPrimary"
        gutterBottom
        variant="body2"
        style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
      >
        CURRICULUM STANDARDS
      </Typography>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <CheckCircleIcon
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          />
          &nbsp;
          <Typography
            // align="center"
            color="textPrimary"
            gutterBottom
            variant="body2"
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            Missouri State Standards - Grade 1 - Mathematics
          </Typography>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <CheckCircleIcon />
          &nbsp;
          <Typography
            // align="center"
            color="textPrimary"
            gutterBottom
            variant="body2"
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            HSD Student Friendly Standards - 1st Grade - Maths
          </Typography>
        </div>
      </div>
    </>

    // </div>
  );
};

const UnitplanBox = (props) => {
  console.log("props at unitplan box", props.customerData);

  const classes = useStyles();
  const Rectangle = (props) => {
    return (
      <div style={{ backgroundColor: props.color, width: 20, height: 20 }} />
    );
  };
  const rectangle2 = <div className={classes.shape2} />;
  const circle = <div className={clsx(classes.shape, classes.shapeCircle)} />;

  return (
    // <div className={classes.root} style={{
    //   width: '100%'
    // }}>
    //   <Grid container spacing={3}>

    //     <Grid item xs={6}>
    //       <Paper className={classes.paper}>xs=6</Paper>
    //     </Grid>
    //     <Grid item xs={6}>
    //       <Paper className={classes.paper}>xs=6</Paper>
    //     </Grid>

    //   </Grid>
    <div>
      {props && props.customerData ? (
        props.customerData ? (
          props.customerData.units.map((list, index) => {
            console.log("unit color ", list.unit_color);
            return (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  marginBottom: 2,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  {/* <Rectangle color={list.unit_color} /> */}
                  <Typography variant="body2">{index + 1}</Typography>
                  <Typography
                    // align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="body2"
                    style={{
                      marginLeft: 10,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    {list.unit_title_text}
                  </Typography>
                </div>
                <div style={{}}>
                  <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="caption"
                    style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                  >
                    <Chip label={list.lesson_count} size="small" />
                  </Typography>
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )
      ) : (
        console.log("no daata unitmap box")
      )}

      {/* second unit */}
      {/* <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
      }}>
        <div style={{
          display: 'flex'
        }}>
          {rectangle2}
          <Typography
            // align="center"
            color="textPrimary"
            gutterBottom
            variant="body2"
            style={{
              marginLeft: 10,
              fontFamily: "Poppins, Helvetica, sans-serif"
            }}
          >
            UNIT 2: DIVISION
        </Typography>

        </div>
        <div style={{

        }}>

          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="caption"
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}

          >
            (12 lessons)
        </Typography>
        </div>
      </div>

    </div> */}
    </div>
  );
};

const SimpleTabs = (props) => {
  {
    props && props.customerData
      ? console.log("props at simpletabs", props.customerData)
      : console.log("no data");
  }

  {
    props && props.curriculumData
      ? console.log("props at simpletabs curriculum", props.curriculumData)
      : console.log("no data");
  }

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const rectangle = <div className={classes.shape} />;
  const circle = <div className={clsx(classes.shape, classes.shapeCircle)} />;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log("curriculum data at maplist", props.curriculumData);

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab
            label="Overview"
            {...a11yProps(0)}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          />
          <Tab
            label="History"
            {...a11yProps(1)}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        {props && props.customerData ? (
          <OverviewBox lessonCount={props.customerData.Total_lesson_count} />
        ) : props &&
          props.curriculumData &&
          props.curriculumData.data &&
          props.curriculumData.data.length ? (
          <OverviewBox
            lessonCount={props.curriculumData.data[0].Total_lesson_count}
          />
        ) : (
          <OverviewBox lessonCount="0" />
        )}

        <div
          style={{
            height: 20,
          }}
        />

        {props && props.customerData ? (
          <UnitplanBox customerData={props.customerData} />
        ) : props && props.curriculumData.data ? (
          <UnitplanBox customerData={props.curriculumData.data[0]} />
        ) : (
          <UnitplanBox />
        )}

        <div
          style={{
            height: 40,
          }}
        />

        {/* <StandardsBox /> */}
      </TabPanel>

      <TabPanel value={value} index={1}>
        Coming soon..
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        Item Three
      </TabPanel> */}
    </div>
  );
};

const CenteredTabs = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [customerData, setCustomerData] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Item One" />
        <Tab label="Item Two" />
      </Tabs>
    </Paper>
  );
};

const RightCard = ({
  AllCurriculumData,
  CurriculumData,
  editData,
  customers,
  customerData,
  product,
  ...rest
}) => {
  console.log("CirruculumData at right card", CurriculumData);
  console.log("CustomerData at right card", customerData);

  const dispatch = useDispatch();

  const unitMapDetailsGet = useSelector((state) => state.unitMap);
  const { loadingUnit, UnitMap, errorMap } = unitMapDetailsGet;
  const currMap = useSelector((state) => state.currMap.currMap);

  let currMapId = 0;
  if (currMap && currMap.map_id) currMapId = currMap.map_id;

  if (currMap && currMap.id) currMapId = currMap.id;

  console.log("currMap", currMap);
  console.log(`currMapId ${currMapId}`);
  console.log("loadingunit", loadingUnit, UnitMap);
  console.log(`currmap in rightcard ${JSON.stringify(currMap)}`);

  /*
  const getUnitDetails = () => {


    CurriculumData ?

      dispatch(listUnitMapGet())
      :
      <></>

  }
  */

  const curriculumDetailsGet = useSelector((state) => state.curriculumMap);
  const { loadingCur, CurriculumMap, errorCur } = curriculumDetailsGet;

  const curriculumFilDetailsGet = useSelector(
    (state) => state.curriculumMapFil
  );
  const { loadingCurFil, CurriculumMapFilter, errorCurFil } =
    curriculumFilDetailsGet;

  const curriculumAllGet = useSelector((state) => state.allCurriculumGet);
  const { loadingAllCurr, allCurrGet, errorAllBooks } = curriculumAllGet;

  {
    loadingAllCurr && curriculumAllGet
      ? console.log("data is loading ")
      : console.log("all curriculum data at maplistResults"); //, allCurrGet);
  }

  const maps = useSelector(
    (state) => state.curriculumMapFil.CurriculumMapFilter
  );

  {
    loadingCurFil && curriculumFilDetailsGet
      ? console.log("loadingCurFil is loading")
      : console.log(
          "curriculum map at right card fetched fil",
          CurriculumMapFilter
        );
  }

  console.log("curriculum map at right card fetched", CurriculumMap);

  useEffect(() => {
    const userId = Cookie.get("kgtopg.guruculum.user.id");
    console.log("CAME TO THE MAPLIST PAGE..........");

    // dispatch(listCurriculumAllGet())

    // getUnitDetails()
    // dispatch(listCurriculumGet(userId))

    return () => {
      //
    };
  }, []);

  console.log("customer gsdrg", customerData);
  console.log(`curriculum map filter `, CurriculumMapFilter);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        // height: '100%'
      }}
      {...rest}
    >
      <CardContent style={{ display: "none" }}>
        <div style={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pb: 3,
            }}
          >
            <LibraryBooksIcon />
          </Box>

          {customerData ? (
            customerData.map_title_text ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                <Typography
                  align="center"
                  color="textPrimary"
                  gutterBottom
                  variant="body1"
                  style={{
                    marginLeft: 10,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  {customerData.map_title_text}
                </Typography>
                <Chip
                  label={"DRAFT UPDATED ON " + customerData.draft_updated_date}
                />
              </div>
            ) : (
              console.log(
                "curriculum data at first time loading.....................",
                CurriculumMap
              )
            )
          ) : loadingCur && curriculumDetailsGet ? (
            <></>
          ) : // CurriculumMap.data && CurriculumMap.data.length && CurriculumMap.length != 0 ?
          CurriculumMapFilter &&
            CurriculumMapFilter != undefined &&
            CurriculumMapFilter.length ? ( //data && CurriculumMapFilter.data.length && CurriculumMapFilter.data.length != 0 ?
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontFamily: "Poppins, Helvetica, sans-serif",
              }}
            >
              <Typography
                align="center"
                color="textPrimary"
                gutterBottom
                variant="body1"
                style={{
                  marginLeft: 10,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                {CurriculumMapFilter[0].map_title_text}
              </Typography>
              <Chip
                label={
                  "DRAFT UPDATED ON " +
                  CurriculumMapFilter[0].draft_updated_date
                }
              />
            </div>
          ) : allCurrGet &&
            allCurrGet.data &&
            allCurrGet.data.length &&
            allCurrGet.length != 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontFamily: "Poppins, Helvetica, sans-serif",
              }}
            >
              <Typography
                align="center"
                color="textPrimary"
                gutterBottom
                variant="body1"
                style={{
                  marginLeft: 10,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                {allCurrGet.data[0].map_title_text}
              </Typography>
              <Chip
                label={
                  "DRAFT UPDATED ON " + allCurrGet.data[0].draft_updated_date
                }
              />
            </div>
          ) : (
            <Alert severity="warning">
              <AlertTitle>No Maps</AlertTitle>
              No Map — <strong>select one from left</strong>
            </Alert>
          )}
        </div>
        {/* 
        <Typography
          align="center"
          color="textPrimary"
          variant="body1"
        >
          Description
        </Typography>
         */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: 20,
            width: "100%",
          }}
        >
          <Link
            to={`/publisher-ebook/curriculum-map/set-units/${currMapId}/?inst_id=${
              currMap ? currMap.institute_type_id : 0
            }&board_id=${currMap ? currMap.institute_board_id : 0}&class_std=${
              currMap ? currMap.classroom_std : 0
            }&sub_id=${currMap ? currMap.subject_id : 0}`}
            style={{ textDecoration: "none", color: "#000" }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <EditIcon
                style={{
                  height: 16,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              />

              <Typography
                align="center"
                color="textPrimary"
                variant="body2"
                style={{
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                onClick={() => {
                  editData(customerData);
                  // clear current unit
                  dispatch(updateCurrUnit(null));
                  // clear lessons
                  dispatch(getUnitLessons(0));
                }}
              >
                Edit Draft
              </Typography>
            </Box>
          </Link>

          <Link
            to={`/publisher-ebook/curriculum-map/set-units/?map_id=${
              currMap ? currMap.map_id : 0
            }`}
            style={{ textDecoration: "none", color: "#000" }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <VisibilityIcon style={{ height: 16 }} />
              <Typography
                align="center"
                color="textPrimary"
                variant="body2"
                style={{
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                View
              </Typography>
            </Box>
          </Link>

          {/* 
          <Box
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <ViewCarouselIcon style={{ height: 16 }} />
            <Typography
              align="center"
              color="textPrimary"
              variant="body2"
              style={{
                fontFamily: "Poppins, Helvetica, sans-serif"
              }}
            >
              Compare
            </Typography>
          </Box>
 */}
        </div>
      </CardContent>

      <Box sx={{ flexGrow: 1 }} />
      <Divider />

      {/* <CenteredTabs /> */}

      <div
        style={{
          width: "100%",
        }}
      >
        {/* {loadingCur && curriculumDetailsGet ? */}
        {loadingCur && CurriculumMapFilter ? (
          <></>
        ) : // <SimpleTabs loadingUnit={loadingUnit} UnitMap={UnitMap} />
        CurriculumMapFilter && CurriculumMapFilter.length ? (
          <SimpleTabs
            customerData={customerData}
            curriculumData={{ data: CurriculumMapFilter }}
          />
        ) : allCurrGet &&
          allCurrGet.data &&
          allCurrGet.data.length &&
          allCurrGet.length != 0 ? (
          <SimpleTabs customerData={customerData} curriculumData={allCurrGet} />
        ) : (
          <Alert severity="warning">
            <AlertTitle>No Units</AlertTitle>
            No Units — <strong>select one from left for units</strong>
          </Alert>
        )}
      </div>
    </Card>
  );
};

function MyLessons() {
  const classes1 = useStyles1();

  const [customerData, setCustomerData] = useState("");
  const [CurriculumData, setCurriculumData] = useState(null);

  const curriculumAllGet = useSelector((state) => state.allCurriculumGet);
  const { loadingAllCurr, allCurrGet, errorAllBooks } = curriculumAllGet;

  {
    loadingAllCurr && curriculumAllGet
      ? console.log("data is loading ")
      : console.log("all curriculum data at maplistResults"); //, allCurrGet);
  }

  const mapList = useSelector((state) => state.curriculumMap.CurriculumMap);
  const allList = useSelector((state) => state.allCurriculumGet.allCurrGet);
  // console.log(`now updated display maplist ${JSON.stringify(mapList)}`);
  // console.log(`now updated display alllist ${JSON.stringify(allList)}`);
  const currMap = useSelector((state) => state.currMap.currMap);

  const dispatch = useDispatch();

  const updateCurrData = (row, CurrData) => {
    console.log("props at maplist", row);
    console.log("CurrData", CurrData);
    setCustomerData(row);
    setCurriculumData(CurrData);
  };

  useEffect(() => {
    dispatch(listCurriculumAllGet());

    console.log(`now updated called useeffect in maplist main page`);

    return <></>;
  }, []);

  if (!currMap && mapList && mapList.data && mapList.data[0]) {
    // set current map
    dispatch(updateCurrMap(mapList.data[0]));
    console.log(`now updated current map..`);
  }

  const onReload = (all) => {
    const userId = Cookie.get("kgtopg.guruculum.user.id");
    // update everywhere
    dispatch(listCurriculumGet(userId));
    if (all) dispatch(listCurriculumAllGet());
  };

  return (
    <div className={classes1.root}>
      <LeftCard />
      <main className={classes1.content}>
        <Helmet>
          <title> Guruculum | My Lessons</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: "background.default",
            minHeight: "100%",
            py: 3,
          }}
        >
          <Container maxWidth={true}>
            <AdminControls2 />
          </Container>
        </Box>

        <div
          style={{
            margin: 20,
          }}
        >
          <Copyright />
        </div>
      </main>
    </div>
  );
}

export default MyLessons;
