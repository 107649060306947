import { SUBTOPDETAILS_LIST_REQUEST, SUBTOPDETAILS_LIST_SUCCESS, SUBTOPDETAILS_LIST_FAIL } from "../constants/SubtopicConstants";

function SubtopDetailsReducer(state = { SubtopDetails: [] }, action) {
    switch (action.type) {
        case SUBTOPDETAILS_LIST_REQUEST:
            return { loadingSubtop: true }
        case SUBTOPDETAILS_LIST_SUCCESS:
            return { loadingSubtop: false, SubtopDetails: action.payload };
        case SUBTOPDETAILS_LIST_FAIL:
            return { loadingSubtop: false, errorSubtop: action.payload }
        default:
            return state;
    }

}

export { SubtopDetailsReducer }
