import axios from "axios";
import Constants from "../../resource/Constants";
import { UNITMAP_LIST_REQUEST, UNITMAP_LIST_SUCCESS, UNITMAP_LIST_FAIL,
UNITADD_REQUEST, UNITADD_SUCCESS, UNITADD_FAILURE } from "../constants/unitMapConstants";



const listUnitMapGet = (id) => async (dispatch) => {
    try {

        console.log("id at actions", id)


        dispatch({ type: UNITMAP_LIST_REQUEST })

        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/unit-map/?map_id=${id}`)
        console.log("data in list units  get ===========", data)
        dispatch({ type: UNITMAP_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: UNITMAP_LIST_FAIL, payload: error.message })

    }
}


const postNewUnit = (data) => async (dispatch) => {

    dispatch({ type: UNITADD_REQUEST })

    try {
        // data variable is the new curriculum-map object to be added to previous list

        // attach new object to the old list and send new list as payload
        const { newObj, unitList } = data;

        console.log(`unitlist is ${JSON.stringify(unitList)}`)
        let newData = unitList;
        newData.data.Units.push(newObj);

        console.log('now adding new map to unit list')
        dispatch({ type: UNITADD_SUCCESS, payload: newData })

    } catch (error) {
        dispatch({ type: UNITADD_FAILURE, payload: error.message })
    }
}


export { listUnitMapGet, postNewUnit }
