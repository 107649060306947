import { LESSONPLANS_LIST_REQUEST, LESSONPLANS_LIST_SUCCESS, LESSONPLANS_LIST_FAILURE,
LESSONPLANS_REQUEST, LESSONPLANS_SUCCESS, LESSONPLANS_FAILURE } from "../constants/lessonPlansConstants";

function LessonPlansReducer(state = { lessonPlans: [] }, action) {
    switch (action.type) {
        case LESSONPLANS_LIST_REQUEST:
            return { ...state, loading: true }
        case LESSONPLANS_LIST_SUCCESS:
            return { ...state, loading: false, lessonPlans: action.payload };
        case LESSONPLANS_LIST_FAILURE:
            return { ...state, loading: false, errorMap: action.payload }
        case LESSONPLANS_REQUEST:
            return { ...state, loading: true }
        case LESSONPLANS_SUCCESS:
            return { ...state, loading: false, lessonPlans: action.payload }
        case LESSONPLANS_FAILURE:
            return { ...state, loading: false, errorMap: action.payload }
        default:
            return state;
    }
}

export { LessonPlansReducer }