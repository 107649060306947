
export const COMPANYDETPUBGET_LIST_REQUEST = 'COMPANYDETPUBGET_LIST_REQUEST'
export const COMPANYDETPUBGET_LIST_SUCCESS = 'COMPANYDETPUBGET_LIST_SUCCESS'
export const COMPANYDETPUBGET_LIST_FAIL = 'COMPANYDETPUBGET_LIST_FAIL'


export const COMPANYDETCONTGET_LIST_REQUEST = 'COMPANYDETCONTGET_LIST_REQUEST'
export const COMPANYDETCONTGET_LIST_SUCCESS = 'COMPANYDETCONTGET_LIST_SUCCESS'
export const COMPANYDETCONTGET_LIST_FAIL = 'COMPANYDETCONTGET_LIST_FAIL'



export const COMPANYDETPPUBPOST_LIST_REQUEST = 'COMPANYDETPPUBPOST_LIST_REQUEST'
export const COMPANYDETPUBPOST_LIST_SUCCESS = 'COMPANYDETPUBPOST_LIST_SUCCESS'
export const COMPANYDETPUBPOST_LIST_FAIL = 'COMPANYDETPUBPOST_LIST_FAIL'


export const COMPANYDETPCONTPOST_LIST_REQUEST = 'COMPANYDETPCONTPOST_LIST_REQUEST'
export const COMPANYDETCONTPOST_LIST_SUCCESS = 'COMPANYDETCONTPOST_LIST_SUCCESS'
export const COMPANYDETCONTPOST_LIST_FAIL = 'COMPANYDETCONTPOST_LIST_FAIL'