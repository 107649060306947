import React, { useEffect, useState } from "react";
import { useLocation, useParams, useHistory, Link } from "react-router-dom";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// dialog

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import CURRICULUMAPI from '../../../http/curriculummap';
import UNITAPI from '../../../http/curriculummapunit';
import Constants from "../../../resource/Constants";

const TAG = 'CurriculumMapper.js';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
];

// mock data
const CHAPTERS_MOCK = [
    {
        name_text: 'Chapter own name',
        name_less: 'Chapter mapped name'
    },
    {
        name_text: 'Chapter own name2',
        name_less: 'Chapter mapped name2'
    },
    {
        name_text: 'Chapter own name2',
        name_less: 'Chapter mapped name2'
    }
]

const LESSON_PLANS_MOCK = [
    {
        topic_own_name: 'Topic own name',
        topic_mapped_name: 'Topic mapped name',
        subTopics: [
            {
                sub_topic_own_name: 'Sub topic own name',
                lesson_plan_name: 'Lesson Plan name'
            },
            {
                sub_topic_own_name: 'Sub topic own name',
                lesson_plan_name: 'Lesson Plan name'
            }
        ]
    },
    {
        topic_own_name: 'Topic own name2',
        topic_mapped_name: 'Topic mapped name2',
        subTopics: [
            {
                sub_topic_own_name: 'Sub topic own name',
                lesson_plan_name: 'Lesson Plan name'
            },
            {
                sub_topic_own_name: 'Sub topic own name',
                lesson_plan_name: 'Lesson Plan name'
            }
        ]
    },
    {
        topic_own_name: 'Topic own name3',
        topic_mapped_name: 'Topic mapped name3',
        subTopics: [
            {
                sub_topic_own_name: 'Sub topic own name',
                lesson_plan_name: 'Lesson Plan name'
            },
            {
                sub_topic_own_name: 'Sub topic own name',
                lesson_plan_name: 'Lesson Plan name'
            }
        ]
    }
]

const LESSONPLANMAPPER = (props) => {
    const { chpIdx, LP, idx, idx2, idx6, chaptersData, preLessons } = props;
    const [preLessons2, setPreLessons2] = useState([]);

    const [planName, setPlanName] = useState(LP.name);
    const [autoValue, setAutoValue] = useState(LP.lesson_plan_setup_obj.name);
    const handleChange = (event) => {
        const { name, value } = event.target;

        let sChaptersData = JSON.parse(JSON.stringify(chaptersData));
        console.log(TAG, 'LESSONPLANMAPPER sChaptersData', sChaptersData);
        console.log(TAG, 'LESSONPLANMAPPER chpIdx', chpIdx);
        console.log(TAG, 'LESSONPLANMAPPER idx', idx);
        console.log(TAG, 'LESSONPLANMAPPER name value ', value);
        sChaptersData[chpIdx].lesson_plan_custom_data[idx6][idx2].name = value;
        sChaptersData[chpIdx].lesson_plan_custom_data[idx6][idx2].plan_description = value;
        props.handleUpdateChaptersData(sChaptersData);
        setPlanName(value);
    }

    const _setPreLessons = () => {
        console.log(TAG, '_setPreLessons preLessons ', preLessons);
        if (preLessons) {
            let preLessons3 = [];
            preLessons.map((lsn, idx) => {
                let obj = { id: lsn.lesson_plan_setup_id, label: lsn.name, year: 1994 }
                preLessons3.push(obj);
            })

            setPreLessons2(preLessons3)
        }
    }

    const handleSelect = (event, value2) => {
        const { name, value } = event;
        console.log(TAG, 'LESSONPLANMAPPER handleSelect value ', value);
        console.log(TAG, 'LESSONPLANMAPPER handleSelect value2 ', value2);

        let id4 = 0;

        if (value2) {
            id4 = value2.id;
            // update the id in the object
            let sChaptersData = JSON.parse(JSON.stringify(chaptersData));
            sChaptersData[chpIdx].lesson_plan_custom_data[idx6][idx2].lesson_plan_setup_id = id4;
            props.handleUpdateChaptersData(sChaptersData);
            setAutoValue(value2.label);
        }
    }

    useEffect(() => {

        _setPreLessons()
        return {

        }
    }, [])

    return (
        <>

            {/* sub-topics */}
            <div className=""
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginTop: 3
                }}>
                <div
                    style={{
                        marginRight: 5
                    }}>
                    <CheckIcon
                        variant="primary"
                        color="primary"
                    // fontSize="large"
                    />

                </div>

                <div
                    style={{
                        width: '45%'
                    }}>
                    <TextField
                        label="Sub topic name"
                        id="filled-size-normal"
                        defaultValue=""
                        value={planName}
                        placeholder="Please enter sub-topic name as per your text book"
                        variant="filled"
                        style={{
                            width: '100%'
                        }}
                        onChange={handleChange}
                    />
                </div>
                <div
                    style={{
                        width: '45%'
                    }}>
                    {(preLessons2 && preLessons2.length) ?
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={preLessons2}
                            sx={{}}
                            defaultValue={LP.lesson_plan_setup_obj.name}
                            value={autoValue}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label={autoValue}
                                    value={autoValue}
                                // defaultValue={LP.lesson_plan_setup_obj.name}
                                />}
                            onChange={handleSelect}
                        />
                        :
                        <>Loading options..</>
                    }
                </div>
                <div
                    style={{
                        width: '5%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginLeft: 10
                    }}>

                    <ClearIcon
                        variant="secondary"
                        color="secondary"
                        style={{
                            cursor: 'pointer',
                            marginLeft: 10,
                            color: 'Red'
                        }}
                        onClick={() => {
                            props.handleRemoveMapper(idx)
                        }}
                    />
                </div>
            </div>


            <div style={{ margin: 10 }}></div>
        </>
    )
}

{/* each topic and it's plans */ }
const LESSONPLANBOX = (props) => {
    const { chpIdx, lessonPlanData, chaptersData, preLessons } = props;

    console.log(TAG, 'LESSONPLANBOX lessonPlanData', (lessonPlanData));
    const chpId = lessonPlanData.id;

    const handleChange = (event, idx6) => {
        const { name, value } = event.target;

        let sChaptersData = JSON.parse(JSON.stringify(chaptersData));
        console.log(TAG, 'LESSONPLANMAPPER sChaptersData', sChaptersData);
        console.log(TAG, 'LESSONPLANMAPPER chpIdx', chpIdx);
        console.log(TAG, 'LESSONPLANMAPPER idx', idx6);
        console.log(TAG, 'LESSONPLANMAPPER name value ', value);
        let topicArr = sChaptersData[chpIdx].lesson_plan_custom_data[idx6];

        // update all sub topics names under this topic
        if (topicArr && topicArr.length) {
            topicArr.map((tpc, idx2) => {
                sChaptersData[chpIdx].lesson_plan_custom_data[idx6][idx2].topic_name = value;
            })
        }

        props.handleUpdateChaptersData(sChaptersData);
    }

    return (
        (lessonPlanData.lesson_plan_custom_data.map((topPlan, idx6) => {
            // group by topic
            // const { subTopics } = topPlan;

            let firstSubTopic = topPlan[0];
            if(!firstSubTopic) {
                firstSubTopic = {topic_name:''}
            }

            return (
                <div
                    key={idx6}>
                    <div className=""
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}>

                        <div
                            style={{
                                width: '95%'
                            }}>
                            <TextField
                                label="Topic name"
                                id="filled-size-normal"
                                defaultValue=""
                                value={firstSubTopic.topic_name}
                                // value={topPlan.topic_own_name}
                                placeholder="Please enter topic name as per your text book"
                                variant="filled"
                                style={{
                                    width: '100%'
                                }}
                                onChange={(event) => handleChange(event, idx6)}
                            />
                        </div>
                        {/*
                <div
                    style={{
                        width: '45%'
                    }}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={top100Films}
                        sx={{}}
                        renderInput={(params) => <TextField {...params} label="Topic" />}
                    />
                </div>
                */}

                        <div
                            style={{
                                width: '5%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginLeft: 10
                            }}>
                            {/* <CheckIcon
                        variant="primary"
                        color="primary"
                    // fontSize="large"
                    /> */}

                            <ClearIcon
                                variant="secondary"
                                color="secondary"
                                style={{
                                    cursor: 'pointer',
                                    marginLeft: 10,
                                    color: 'DarkOrange'
                                }}
                                onClick={() => {
                                    props.handleRemoveTopicMapper(idx6, firstSubTopic.topic_name, chpId)
                                }}
                            />
                        </div>
                    </div>

                    {topPlan.map((LP, idx2) => {
                        console.log(TAG, 'groupPlan ', JSON.stringify(LP));
                        const planId = LP.lesson_plan_setup_custom_id;

                        return (
                            <>

                                <LESSONPLANMAPPER
                                    key={idx2}
                                    LP={LP}
                                    chpIdx={chpIdx}
                                    chaptersData={chaptersData}
                                    idx={idx2}
                                    idx2={idx2}
                                    idx6={idx6}
                                    handleRemoveMapper={(idx) => props.handleRemoveMapper(idx6, idx, planId)}
                                    handleUpdateChaptersData={(chapters) => props.handleUpdateChaptersData(chapters)}
                                    preLessons={preLessons}
                                />
                            </>
                        )
                    })
                    }

                    {/*                     
                    {subTopics.map((LP, idx2) => {
                        
                    })
                    } */}



                    {/* show option to add one more sub-topic / lesson-plan */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            marginBottom: 10
                        }}>
                        <Button
                            variant="outlined"
                            onClick={() => props.handleAddLessonMapper(idx6)}
                        >+ Add Plan</Button>
                    </div>

                </div>
            )
        }))

    )
}

const ChapterDetails = (props) => {
    const { chaptersData, chp, idx, isSaved, preLessons } = props;

    console.log(TAG, 'ChapterDetails chp ', chp);
    const [lessonPlanData, setLessonPlanData] = useState(chp);
    console.log(TAG, 'ChapterDetails lessonPlanData ', lessonPlanData);
    const [chapName, setChapName] = useState(chp.name_text);
    const [chapMins, setChapMins] = useState(chp.minutes_num);

    const currUnit = useSelector((state) => state.currUnit.currUnit);
    const { id, curriculum_map_id } = currUnit;

    // new topic
    const handleAddTopicMapper = () => {
        // add a new object
        let sLessonPlanData = JSON.parse(JSON.stringify(lessonPlanData)); // the chapter

        /*
        const newMapper2 = {
            topic_own_name: 'Topic own name3',
            topic_mapped_name: 'Topic mapped name3',
            subTopics: [
                {
                    sub_topic_own_name: 'Sub topic own name3',
                    lesson_plan_name: 'Lesson Plan name3'
                },
                {
                    sub_topic_own_name: 'Sub topic own name3',
                    lesson_plan_name: 'Lesson Plan name3'
                },
                {
                    sub_topic_own_name: 'Sub topic own name3',
                    lesson_plan_name: 'Lesson Plan name3'
                }
            ]

        }
        */

        /* this is a map box with topic and sub-topic fresh */
        const newMapper = {
            lesson_plan_setup_custom_id: 0,
            topic_id: 0,
            sub_topic_id: 0,
            name: "",
            cover_image_url: "",
            created_user_id: 0,
            created_date: "",
            modified_user_id: 0,
            timestamp: "",
            duration_min: 0,
            plan_description: "",
            multimedia_self: 0,
            is_approved: 0,
            publisher_comments: "",
            topic_name: "",
            lesson_plan_setup_id: 17,
            lesson_id: 243,
            lesson_plan_setup_obj: {

            }
        }

        // topic-wise fresh list (adding a topic head)
        sLessonPlanData.lesson_plan_custom_data.push([newMapper]); // sub-topic list
        // setLessonPlanData(sLessonPlanData); // only update state

        // update main object
        let sChaptersData = JSON.parse(JSON.stringify(chaptersData));

        console.log(TAG, 'ChapterDetails sChaptersData ', sChaptersData);
        console.log(TAG, 'ChapterDetails idx ', idx);

        sChaptersData[idx].lesson_plan_custom_data.push([newMapper]);

        console.log(TAG, 'sChaptersData[idx] ', sChaptersData[idx]);
        props.handleUpdateChaptersData(sChaptersData);
    }

    // sub-topics
    const handleAddLessonMapper = (idx2) => { // idx of lesson plan, not chapter
        // add a new object
        let sLessonPlanData = JSON.parse(JSON.stringify(chp));
        // update main object
        let sChaptersData = JSON.parse(JSON.stringify(chaptersData));

        console.log(TAG, 'handleAddLessonMapper chp', chp);
        const topObj = sChaptersData[idx].lesson_plan_custom_data[idx2]; // which topic
        console.log(TAG, 'handleAddLessonMapper topObj', topObj);
        const subObj = {
            lesson_plan_setup_custom_id: 0,
            topic_id: 0,
            sub_topic_id: 0,
            name: "",
            cover_image_url: "",
            created_user_id: 0,
            created_date: "",
            modified_user_id: 0,
            timestamp: "",
            duration_min: 0,
            plan_description: "",
            multimedia_self: 0,
            is_approved: 0,
            publisher_comments: "",
            topic_name: "",
            lesson_plan_setup_id: 17, // default # remove after
            lesson_id: 0,
            lesson_plan_setup_obj: {

            }
        }

        topObj.push(subObj)

        console.log(TAG, 'handleAddLessonMapper topObj after', topObj);
        sChaptersData[idx].lesson_plan_custom_data[idx2] = topObj;
        props.handleUpdateChaptersData(sChaptersData); // main object
        // setLessonPlanData(sLessonPlanData);
    }

    // remove this topic under the chapter
    const handleRemoveTopicMapper = (idx2, topicName, chpId) => {
        // remove the element in the index idx

        console.log(TAG, 'handleRemoveTopicMapper idx ', idx2)
        // let sLessonPlanData = JSON.parse(JSON.stringify(lessonPlanData));

        if (window.confirm("Are you sure you want to remove this topic and lesson plans under it? It cannot be reverted!")) {
            // update main object
            let sChaptersData = JSON.parse(JSON.stringify(chaptersData));
            let sLessonPlanData = sChaptersData[idx];

            // sLessonPlanData = sLessonPlanData.filter((map, idx2) => parseInt(idx2) != parseInt(idx));
            sChaptersData[idx].lesson_plan_custom_data.splice(idx2, 1); // which element index, how many to remove
            props.handleUpdateChaptersData(sChaptersData);

            // setLessonPlanData(sLessonPlanData);
            // remove from back-end
            CURRICULUMAPI.deleteTopic(topicName, chpId);
        }

    }

    // remove this sub-topic under the topic with idx6 and the sub-topic itself with idx - idx
    const handleRemoveMapper = (idx6, idx2, planId) => {
        // remove the element in the index idx

        console.log(TAG, 'handleRemoveMapper idx6 ', idx6);
        console.log(TAG, 'handleRemoveMapper idx2 ', idx2);


        if (window.confirm("Are you sure you want to delete this plan? It cannot be reverted!")) {
            // let sLessonPlanData = JSON.parse(JSON.stringify(chp));
            let sChaptersData = JSON.parse(JSON.stringify(chaptersData));
            let sLessonPlanData = sChaptersData[idx];
            const topObj = sLessonPlanData.lesson_plan_custom_data[idx6];

            console.log(TAG, 'handleRemoveMapper() topObj ', JSON.stringify(topObj));
            console.log(TAG, 'handleRemoveMapper() topObj subTopics ', topObj.subTopics);
            // topObj.subTopics = topObj.subTopics.filter((map, idx3) => parseInt(idx3) != parseInt(idx));
            // topObj.subTopics.splice(idx, 1);
            topObj.splice(idx2, 1)
            sChaptersData[idx].lesson_plan_custom_data[idx6] = topObj; // update the object

            props.handleUpdateChaptersData(sChaptersData);
            // setLessonPlanData(sLessonPlanData);

            // remove from the server
            CURRICULUMAPI.deleteLessonPlanCustom(planId);
        }

    }

    const handleChangeChapName = (event) => {
        const { name, value } = event.target;

        setChapName(value);

        // update
        let sChaptersData = JSON.parse(JSON.stringify(chaptersData));
        sChaptersData[idx].name_text = value;
        sChaptersData[idx].name_less = value;

        props.handleUpdateChaptersData(sChaptersData);
    }

    const handleChangeChapMins = (event) => {
        const { name, value } = event.target;

        setChapMins(value);

        // update
        let sChaptersData = JSON.parse(JSON.stringify(chaptersData));
        sChaptersData[idx].minutes_num = value;

        props.handleUpdateChaptersData(sChaptersData);
    }

    const handleSaveChapClicked = async () => {

        /* call the lesson save api */
        const body = {
            map_unit: id,
            name_text: chapName,
            name_less: chapName,
            minutes_num: 0,
            chapter_id: 0,
            subject_sub_id: 0
        }

        props.handleSaveChapClicked(body);
    }

    // console.log(TAG, 'ChapterDetails lessonPlanData ', lessonPlanData);

    let chapName2 = chaptersData[idx].name_text;
    let chapMins2 = chaptersData[idx].minutes_num;

    return (
        <Box
            component="form"
            sx={{
                // '& .MuiTextField-root': { m: 1, width: '25ch1' },
            }}
            style={{
                width: '100%'
            }}
            noValidate
            autoComplete="off"
        >
            <Typography variant="h5" gutterBottom>
                Map chapter
            </Typography>
            <div className=""
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                }}>

                <div
                    style={{
                        width: '80%'
                    }}>
                    <TextField
                        label="Chapter name"
                        id="filled-size-normal"
                        defaultValue=""
                        value={chapName2}
                        placeholder="Please enter chapter name as per your text book"
                        variant="filled"
                        style={{
                            width: '100%'
                        }}
                        onChange={handleChangeChapName}
                    />
                </div>

                {/*
                <div
                    style={{
                        width: '45%'
                    }}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={top100Films}
                        value={chp.chapter_mapped_name}
                        sx={{}}
                        renderInput={(params) => <TextField {...params} label="Chapter" />}
                    />
                </div>
                */}
                <div
                    style={{
                        width: '10%',
                        marginLeft: 10
                    }}>
                    <TextField
                        label="No. of days"
                        id="filled-size-normal"
                        defaultValue=""
                        value={chapMins2}
                        placeholder="Please enter no of days to be taught"
                        variant="filled"
                        style={{
                            width: '100%'
                        }}
                        onChange={handleChangeChapMins}
                    />

                    {/* <CheckIcon
                        variant="primary"
                        color="primary"
                    // fontSize="large"
                    /> */}



                    {/* save button */}
                    {/* <Button
                    // disabled={isSaved}
                    disabled={false}
                    variant="contained"
                    onClick={handleSaveChapClicked}
                    >Save</Button> */}
                </div>
            </div>

            <div style={{ margin: 20 }}></div>

            {/* show only when chapter is saved */}
            {/* {isSaved ? */}
            {true ?
                <>
                    {/* topic */}
                    <Typography variant="h5" gutterBottom>
                        Map Lesson Plans
                    </Typography>
                    <LESSONPLANBOX
                        key={idx}
                        chpIdx={idx}
                        lessonPlanData={chaptersData[idx]}
                        handleRemoveTopicMapper={(idx, topicName, chpId) => handleRemoveTopicMapper(idx, topicName, chpId)}
                        handleAddLessonMapper={(idx) => handleAddLessonMapper(idx)} // for which topic idx
                        // handleAddTopicMapper={handleAddTopicMapper}
                        handleRemoveMapper={(idx6, idx, planId) => handleRemoveMapper(idx6, idx, planId)}
                        chaptersData={chaptersData}
                        handleUpdateChaptersData={(chapters) => props.handleUpdateChaptersData(chapters)}
                        preLessons={preLessons}
                    />

                    {/* add a new lesson plan box */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignSelf: 'center',
                            marginTop: 10
                        }}>
                        <Button
                            variant="outlined"
                            onClick={handleAddTopicMapper}
                            color="secondary"
                        >Add Topic</Button>

                    </div>
                </>
                :
                <></>
            }
        </Box>

    )
}

const CHAPTERS_BOX = (props) => {
    const { chaptersData, expanded, isSaved, preLessons } = props;

    console.log(TAG, 'CHAPTERS_BOX new chaptersData', chaptersData);

    // filter current unit chapters only
    let chaptersDataGrouped = chaptersData.reduce((chp2, obj) => {
        const key = obj.map_unit_id;
        if (!chp2[key]) {
            chp2[key] = [];

        }

        chp2[key].push(obj);
        return chp2;
    }, {});

    const currUnit = useSelector((state) => state.currUnit.currUnit);

    // console.log(TAG, 'CHAPTERS_BOX currUnit', currUnit);
    let chaptersData2 = null;

    if (chaptersDataGrouped) {
        console.log(TAG, 'CHAPTERS_BOX chaptersDataGrouped', chaptersDataGrouped);
        console.log(TAG, 'CHAPTERS_BOX currUnit.id', currUnit.id);
        chaptersData2 = chaptersDataGrouped[currUnit.id];

        console.log(TAG, 'CHAPTERS_BOX chaptersData2', chaptersData2);
    } else {
        return <></>;
    }

    if (chaptersData2 === undefined || chaptersData2 === null) {
        return <></>;
    }
    /*
    let return2 = (
        <>
            <Accordion expanded={expanded === 'panel1'} onChange={props.handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>Chapter #1</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ChapterDetails />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={props.handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>Chapter #2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ChapterDetails />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={props.handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography>Chapter #3</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ChapterDetails />
                </AccordionDetails>
            </Accordion>
        </>
    )
    */
    console.log(TAG, 're-rendering chapters....');

    return chaptersData2.map((chp, idx) => {
        console.log(TAG, 'CHAPTERS_BOX chp ', chp);
        let pnlName = `panel${(idx + 1)}`
        return (
            <Accordion
                idx={idx}
                key={idx}
                expanded={expanded === pnlName} onChange={props.handleChange(pnlName)}>
                <AccordionSummary aria-controls={`${pnlName}d-content`} id={`${pnlName}d-header`}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>
                        <Typography>Chapter #{(idx + 1)} ({chp.name_text})</Typography>

                        {/* delete icon */}
                        <ClearIcon
                            onClick={() => {
                                props.onHandleChapterDelete(idx, chp.id);
                            }}
                        />
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <ChapterDetails
                        chaptersData={chaptersData2}
                        idx={idx}
                        chp={chp}
                        handleSaveChapClicked={(body) => props.handleSaveChapClicked(body)}
                        isSaved={isSaved}
                        handleUpdateChaptersData={(chaptersData3) => props.handleUpdateChaptersData(chaptersData3)}
                        preLessons={preLessons}
                    />
                </AccordionDetails>
            </Accordion>
        )
    })

}

function SimpleAccordion(props) {
    const [expanded, setExpanded] = React.useState('panel1');
    const [chaptersData, setChaptersData] = useState([]);
    const [chapName, setChapName] = useState('');
    const [isSaved, setIsSaved] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const { preLessons } = props;

    function ProgressSnackBar() {

        const handleClick = () => {
            setOpen(true);
        };

        const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }

            setOpen(false);
        };

        const action = (
            <React.Fragment>
                {/* <Button color="secondary" size="small" onClick={handleClose}>
                    UNDO
                </Button> */}
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
        );

        return (
            <div>
                {/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
                <Snackbar
                    open={open}
                    autoHideDuration={10000}
                    onClose={handleClose}
                    message="Saving in progress.. Please wait!"
                    action={action}

                />
            </div>
        );
    }

    function SuccessSnackBar() {

        const handleClick = () => {
            setOpen2(true);
        };

        const handleClose2 = (event: React.SyntheticEvent | Event, reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }

            setOpen2(false);
        };

        const action2 = (
            <React.Fragment>
                {/* <Button color="secondary" size="small" onClick={handleClose2}>
                    UNDO
                </Button> */}
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose2}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
        );

        return (
            <div>
                {/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
                <Snackbar
                    open={open2}
                    autoHideDuration={1000}
                    onClose={handleClose2}
                    message="Curriculum saved successfully!"
                    action={action2}
                />
            </div>
        );
    }

    const { currMap, currLesson } = props;

    const currUnit = useSelector((state) => state.currUnit.currUnit);

    console.log(TAG, 'SimpleAccordion currUnit ', JSON.stringify(currUnit));
    console.log(TAG, 'SimpleAccordion currMap ', JSON.stringify(currMap));
    console.log(TAG, 'SimpleAccordion currLesson ', JSON.stringify(currLesson));

    const handleChange =
        (panel) => (event, newExpanded) => {
            setExpanded(newExpanded ? panel : false);
        };

    const handleAddNewChapter = () => {
        // add a new object

        let newChpObj = {
            id: 0, // lesson id
            map_unit_id: currUnit.id,
            name_text: "",
            name_less: "",
            minutes_num: 0,
            chapter_id: 0, // it's id
            subject_sub_id: currMap.sub_subject_id,
            created_date: "2023-03-31",
            timestamp: "2023-03-31T10:59:54.431147Z",
            lesson_plan_custom_data: [

            ]
        }

        // add to object
        let sChaptersData = JSON.parse(JSON.stringify(chaptersData));
        sChaptersData.push(newChpObj);

        setChaptersData(sChaptersData);
    }

    const onHandleChapterDelete = (idx, lsnId) => {

        if (window.confirm("Are you sure you want to delete the chapter? It cannot be reverted!")) {
            // copy
            let sChaptersData = JSON.parse(JSON.stringify(chaptersData));

            console.log(TAG, 'onHandleChapterDelete idx ', idx);
            // let newChaptersData = sChaptersData.filter((chp, idx2) => parseInt(idx2) !== parseInt(idx));
            sChaptersData.splice(idx, 1);

            // call API
            CURRICULUMAPI.deleteLesson(lsnId);
            setChaptersData(sChaptersData);
        }

    }

    const handleSaveChapClicked = async (body) => {
        if (!currUnit)
            return false;

        const { id, curriculum_map_id } = currUnit;

        const resp = await UNITAPI.postAddLesson(body);
        console.log(TAG, 'handleSaveChapClicked() resp ', resp);

        setIsSaved(true);
    }

    const _fetchMappedCurriculum = async () => {

        const mapId = currMap.id;
        // const mapId = 167; // ### DELETE AFTER TESTING
        const resp = await CURRICULUMAPI.getOwnBoardMapDetails(mapId);
        console.log(TAG, '_fetchMappedCurriculum() resp ', JSON.stringify(resp));

        const chaps5 = resp.data;
        setChaptersData(chaps5);
    }

    const handleSavePrivateCurr = async () => {
        // get the full object
        let fullObj = chaptersData;
        console.log(TAG, 'handleSavePrivateCurr: fullObj ', fullObj);
        console.log(TAG, 'handleSavePrivateCurr: called..');

        setOpen(true);

        if (fullObj && fullObj.length) {

            // break into lessons / chapters
            // const promises = fullObj.map(async (lsn, idx1) => {
            for(let i=0; i<fullObj.length; i++) {
                let idx1 = i;
                const lsn = fullObj[i];
                // each chapter / lesson
                const { chapter_id, map_unit_id, id, name_less, name_text, subject_sub_id, minutes_num } = lsn;

                let isUpdated = false;
                let resp3 = null;
                // save new if fresh
                if (id === 0 || id === '0') {
                    const body = {
                        map_unit: currUnit.id,
                        map_unit_id: currUnit.id,
                        name_text: name_text,
                        name_less: name_text,
                        lesson_count: minutes_num,
                        chapter_id: chapter_id,
                        subject_sub_id: subject_sub_id,
                        minutes_num: minutes_num,

                    }

                    console.log(TAG, 'handleSavePrivateCurr: add body ', body);

                    // save each chapter / lesson
                    resp3 = await UNITAPI.postAddLesson(body);
                    console.log(TAG, 'handleSavePrivateCurr: added new lesson')
                } else {
                    const body2 = {
                        map_unit: currUnit.id,
                        map_unit_id: currUnit.id,
                        name_text: name_text,
                        name_less: name_text,
                        minutes_num: minutes_num,
                        chapter_id: chapter_id,
                        subject_sub_id: subject_sub_id
                    };
                    console.log(TAG, 'handleSavePrivateCurr: update body ', body2);

                    // save each chapter / lesson
                    resp3 = await UNITAPI.postUpdateLesson(id, body2);
                    isUpdated = true;
                    console.log(TAG, 'handleSavePrivateCurr: updated lesson')
                }

                console.log(TAG, 'handleSavePrivateCurr: resp3 ', JSON.stringify(resp3));
                // get the saved lesson id
                if (resp3) {

                    let lsnId3 = 0;

                    if (!isUpdated)
                        lsnId3 = resp3.data.id;
                    else
                        lsnId3 = resp3.data.id;

                    console.log(TAG, 'handleSavePrivateCurr: lsnId3 is ', lsnId3);
                    lsn.lesson_id = lsnId3;
                    // each lesson -> save sub-topics
                    let commonTopic = '-';

                    let array2 = [1];
                    const promises2 = array2.map(async (element) => {


                    })

                    // const promises3 = lsn.lesson_plan_custom_data.map(async (subTopList) => {
                    for(let j=0; j<lsn.lesson_plan_custom_data.length; j++) {
                        const subTopList = lsn.lesson_plan_custom_data[j];
                        console.log(TAG, 'handleSavePrivateCurr: lessonplancustomdata subTopList', subTopList);
                        let resp5 = null;
                        if (subTopList && subTopList.length) {

                            // const promises4 = subTopList.map(async (subTop, idx2) => {
                            for(let k=0; k<subTopList.length; k++) {
                                let idx2 = k;
                                const subTop = subTopList[k];

                                const { lesson_id, lesson_plan_setup_custom_id, lesson_plan_setup_id, sub_topic_id, topic_id, topic_name, lesson_plan_setup_obj, name, plan_description } = subTop;
                                // const { name, plan_description } = lesson_plan_setup_obj;

                                // topic_name should be same for all elements, so first one is taken to group together
                                if (idx2 === 0) {
                                    commonTopic = topic_name;
                                }
                                let resp4 = null;
                                isUpdated = false;

                                if (lesson_plan_setup_custom_id === 0 || lesson_plan_setup_custom_id === '0') {
                                    // new object
                                    console.log(TAG, 'handleSavePrivateCurr: add lesson plan custom');

                                    const body2 = {
                                        topic_id: topic_id,
                                        sub_topic_id: sub_topic_id,
                                        name: name,
                                        cover_image_url: "",
                                        created_user_id: 0,
                                        modified_user_id: 0,
                                        duration_min: 0,
                                        plan_description: plan_description,
                                        multimedia_self: 0,
                                        is_approved: 0,
                                        publisher_comments: "",
                                        topic_name: commonTopic,
                                        lesson_plan_setup_id: lesson_plan_setup_id,
                                        lesson_id: lsnId3
                                    }

                                    console.log(TAG, 'handleSavePrivateCurr: add lesson plan custom body2', body2);

                                    // save each lesson plan custom
                                    resp4 = await CURRICULUMAPI.postSaveLessonPlanSetupCustom(body2);

                                } else {
                                    isUpdated = true;
                                    console.log(TAG, 'handleSavePrivateCurr: update lesson plan custom');

                                    const body3 = {
                                        lesson_plan_setup_custom_id: lesson_plan_setup_custom_id,
                                        topic_id: topic_id,
                                        sub_topic_id: sub_topic_id,
                                        name: name,
                                        cover_image_url: "",
                                        created_user_id: 0,
                                        modified_user_id: 0,
                                        duration_min: 0,
                                        plan_description: plan_description,
                                        multimedia_self: 0,
                                        is_approved: 0,
                                        publisher_comments: "",
                                        topic_name: topic_name,
                                        lesson_plan_setup_id: lesson_plan_setup_id,
                                        lesson_id: lsnId3
                                    }

                                    console.log(TAG, 'handleSavePrivateCurr: update lesson plan custom body3', body3);

                                    // save each lesson plan custom
                                    resp4 = await CURRICULUMAPI.postUpdateLessonPlanSetupCustom(body3);

                                }

                                console.log(TAG, 'handleSavePrivateCurr: resp4 ', JSON.stringify(resp4));

                                // return resp4;
                            }
                            // })

                            // resp5 = await Promise.all(promises4);
                        }

                        // return resp5;
                    }
                    // })

                }

                // return resp3;

            }
            // })

            // const results = await Promise.all(promises);
        }

        console.log(TAG, 'handleSavePrivateCurr: save priv curriculum completed early..');
        setOpen2(true);
    }

    useEffect(() => {

        // fetch the mapped curriculum
        _fetchMappedCurriculum();
        return () => {

        }
    }, [])



    if (!chaptersData || !chaptersData.length) {
        return <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 10
                }}>
                <Button
                    variant="outlined"
                    onClick={handleAddNewChapter}
                    color="secondary">
                    Add Chapter
                </Button>

                {/* 
                <Button
                    variant="contained"
                    onClick={handleSavePrivateCurr}
                    disabled
                    color="primary">Save</Button> */}
            </div>
        </>
    }

    return (
        <div>
            <CHAPTERS_BOX
                chaptersData={chaptersData}
                expanded={expanded}
                handleChange={(panel) => handleChange(panel)}
                onHandleChapterDelete={(idx, lsnId) => onHandleChapterDelete(idx, lsnId)}
                handleSaveChapClicked={(body) => handleSaveChapClicked(body)}
                isSaved={isSaved}
                handleUpdateChaptersData={(chaptersData2) => {
                    console.log(TAG, 'SimpleAccordion handleUpdateChaptersData callback called with data', chaptersData2);
                    setChaptersData(chaptersData2)
                }}
                preLessons={preLessons}
            />

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 10
                }}>
                <Button
                    variant="outlined"
                    onClick={handleAddNewChapter}
                    color="secondary">
                    Next Chapter
                </Button>


                <Button
                    variant="contained"
                    onClick={handleSavePrivateCurr}
                    color="primary">Save</Button>
            </div>

            <ProgressSnackBar />
            <SuccessSnackBar />
        </div>
    );
}

const CurriculumMapper = (props) => {
    const { currMap, currUnit, currLesson } = props;
    const [preLessons, setPreLessons] = useState(null);

    console.log(TAG, 'CurriculumMapper currMap ', JSON.stringify(currMap));
    console.log(TAG, 'CurriculumMapper currUnit ', JSON.stringify(currUnit));
    console.log(TAG, 'CurriculumMapper currLesson ', JSON.stringify(currLesson));

    async function _preloadLessonPlans() {
        const { id, subject_id, classroom_std } = currMap;

        const pubId5 = Constants.Application.SCHOOLER_PUB_ID;
        const resp = await CURRICULUMAPI.getPredefinedLessonPlans(pubId5, subject_id, classroom_std);

        if (resp) {
            const data = resp.data;

            setPreLessons(data);
        }
    }

    useEffect(() => {

        // load pre-defined lessonplans to map
        _preloadLessonPlans();
        return {

        }
    }, [])

    return (
        <>


            <SimpleAccordion
                currMap={currMap}
                currUnit={currUnit}
                currLesson={currLesson}
                preLessons={preLessons}
            />
        </>
    )
}

export default CurriculumMapper;
