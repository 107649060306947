/**
 * reducer for current curriculum map
 */

import * as actions from '../actions/currMapActions'

export const initialState = {
    currMap: null,
    loading: false,
    hasErrors: false
}

export default function currMapReducer(state = initialState, action) {
    switch(action.type) {
        case actions.CURRICULUMMAP_CURR_REQUEST:
            return { ...state, loading: true }
        case actions.CURRICULUMMAP_CURR_SUCCESS:
            console.log(`currmap in reducer success with payload ${JSON.stringify(action.payload)}`)
            return { currMap: action.payload, loading: false, hasErrors: false }
        case actions.CURRICULUMMAP_CURR_FAILURE:
            return { ...state, loading: false, hasErrors: true }
        default:
            return state;
    }
}