/**
 * @project kgtopg - schooler - partner
 * publisher data services
 */
import API from "./http";
import axios from "axios";
import qs from "querystring";
import Constants from "../resource/Constants";
import Cookie from 'js-cookie';

const TAG = 'publisher.js';
export default {
    async postSaveContent(data) {
        console.log("api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/content/";



            console.log("postSaveContent api url", url)

            const options = {
                method: "POST",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveContent api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },


    async postSaveBulkContent(data) {
        console.log("api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/content/";



            console.log("postSaveContent api url", url)

            const options = {
                method: "POST",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveContent api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },


    async postSaveSingleContent(data) {
        console.log("api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += `/save-prov-content/?pub_id=${data.pub_id}&inst_type_id=${data.institute_type_id}&inst_board_id=${data.institute_board_id}&cls_std=${data.classroom_std}&course=${data.course}&sub_id=${data.subject_id}&cont_f_des=${data.content_f_desc}&cont_len=${data.content_length}&cont_loc=${data.content_location}&cont_size=${data.content_size}&image_url=${data.img_url}&content_ty_id=${data.content_type_id}&chap_id=${data.chapter_id}&chap_name=${data.chapter_name}&top_id=${data.topic_id}&top_name=${data.topic_name}&cont_name=${data.content_name}`
            url += '/save-prov-content/'



            console.log("postSaveContent api url", url)

            const options = {
                method: "POST",
                url,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveContent api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },


    async postSavePriceSetup(data) {
        console.log("api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += `/save-prov-content/?pub_id=${data.pub_id}&inst_type_id=${data.institute_type_id}&inst_board_id=${data.institute_board_id}&cls_std=${data.classroom_std}&course=${data.course}&sub_id=${data.subject_id}&cont_f_des=${data.content_f_desc}&cont_len=${data.content_length}&cont_loc=${data.content_location}&cont_size=${data.content_size}&image_url=${data.img_url}&content_ty_id=${data.content_type_id}&chap_id=${data.chapter_id}&chap_name=${data.chapter_name}&top_id=${data.topic_id}&top_name=${data.topic_name}&cont_name=${data.content_name}`
            url += '/content-prov-cost/'

            console.log("postSavePricingSetup api url", url)

            const options = {
                method: "POST",
                url,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveContent api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postUpdatePriceSetup(data) {
        console.log("update price api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += `/save-prov-content/?pub_id=${data.pub_id}&inst_type_id=${data.institute_type_id}&inst_board_id=${data.institute_board_id}&cls_std=${data.classroom_std}&course=${data.course}&sub_id=${data.subject_id}&cont_f_des=${data.content_f_desc}&cont_len=${data.content_length}&cont_loc=${data.content_location}&cont_size=${data.content_size}&image_url=${data.img_url}&content_ty_id=${data.content_type_id}&chap_id=${data.chapter_id}&chap_name=${data.chapter_name}&top_id=${data.topic_id}&top_name=${data.topic_name}&cont_name=${data.content_name}`
            url += '/content-prov-cost/'

            console.log("postSavePricingSetup api url", url)

            const options = {
                method: "PUT",
                url,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveContent api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },
    
    async updateSingleContent(id, data) {
        console.log("update content api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += `/save-prov-content/?pub_id=${data.pub_id}&inst_type_id=${data.institute_type_id}&inst_board_id=${data.institute_board_id}&cls_std=${data.classroom_std}&course=${data.course}&sub_id=${data.subject_id}&cont_f_des=${data.content_f_desc}&cont_len=${data.content_length}&cont_loc=${data.content_location}&cont_size=${data.content_size}&image_url=${data.img_url}&content_ty_id=${data.content_type_id}&chap_id=${data.chapter_id}&chap_name=${data.chapter_name}&top_id=${data.topic_id}&top_name=${data.topic_name}&cont_name=${data.content_name}`
            url += `/content/${id}`



            console.log("updateContent api url", url)

            const options = {
                method: "PUT",
                url,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("updateContent api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },


    async delPricing(userId, costId) {
        console.log("delete pricing api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += `/save-prov-content/?pub_id=${data.pub_id}&inst_type_id=${data.institute_type_id}&inst_board_id=${data.institute_board_id}&cls_std=${data.classroom_std}&course=${data.course}&sub_id=${data.subject_id}&cont_f_des=${data.content_f_desc}&cont_len=${data.content_length}&cont_loc=${data.content_location}&cont_size=${data.content_size}&image_url=${data.img_url}&content_ty_id=${data.content_type_id}&chap_id=${data.chapter_id}&chap_name=${data.chapter_name}&top_id=${data.topic_id}&top_name=${data.topic_name}&cont_name=${data.content_name}`
            url += `/content-prov-cost/?content_prov=${userId}&cost_id=${costId}`
            console.log("delete pricing cost api url", url)

            const options = {
                method: "DELETE",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },
            };

            let response = await axios(options);
            console.log("delete pricing cost api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postSaveSingleEbook(data, id) {

        console.log("data at save single book", data)

        console.log("ebook api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += `/save-pub-book/?pub_id=${data.publisher_id}&name=${data.ebook_name_text}&inst_type_id=${data.institute_type_id}&inst_board_id=${data.institute_board_id}&cls_std=${data.classroom_std}&course=${data.course}&sub_id=${data.subject_id}&loc_url=${data.loc_url}&img_url=${data.img_url}&no_chap=${data.no_chap}&no_les=${data.no_les}&no_pgs=${data.no_pgs}`
            // url += '/save-pub-book/'
            // id ? '/update-pdf-jpg/' :
            // url += id ? '/update-ebook-zip/' :'/save-ebook-zip/'
            url += id ? '/update-pdf/' : '/pdf-jpg/'

            console.log("ebook api url", url)

            const options = {
                method: id ? "PUT" : "POST",
                data: data,
                url,
                // headers: {
                //     "Content-Type": "application/json",
                //     Accept: "application/json",
                //     // 'Authorization': 'Bearer ' + UserSession.authtoken,
                // },

            };

            // let response = await axios(options);
            let response = (id) ? await axios.put(url, data) : await axios.post(url, data)


            console.log("postSaveSingleEbook ebook api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },


    async postSaveSingleEbook2(data, id) {

        console.log("data at save single book", JSON.stringify(data))

        console.log("ebook api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += `/save-pub-book/?pub_id=${data.publisher_id}&name=${data.ebook_name_text}&inst_type_id=${data.institute_type_id}&inst_board_id=${data.institute_board_id}&cls_std=${data.classroom_std}&course=${data.course}&sub_id=${data.subject_id}&loc_url=${data.loc_url}&img_url=${data.img_url}&no_chap=${data.no_chap}&no_les=${data.no_les}&no_pgs=${data.no_pgs}`
            // url += '/save-pub-book/'
            // id ? '/update-pdf-jpg/' :
            // url += id ? '/update-ebook-zip/' :'/save-ebook-zip/'
            url += id ? `/ebook/${id}` : '/ebook/'

            console.log("ebook api url", url)

            const options = {
                method: id ? "PUT" : "POST",
                data: data,
                url,
                // headers: {
                //     "Content-Type": "application/json",
                //     Accept: "application/json",
                //     // 'Authorization': 'Bearer ' + UserSession.authtoken,
                // },

            };

            let response = await axios(options);
            // let response = (id) ? await axios.put(url, data) : await axios.post(url, data)


            console.log("postSaveSingleEbook ebook api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },


    async DeleteSingleEbook(bookId) {

        console.log("Delete single book", bookId)

        console.log("ebook api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += `/save-pub-book/?pub_id=${data.publisher_id}&name=${data.ebook_name_text}&inst_type_id=${data.institute_type_id}&inst_board_id=${data.institute_board_id}&cls_std=${data.classroom_std}&course=${data.course}&sub_id=${data.subject_id}&loc_url=${data.loc_url}&img_url=${data.img_url}&no_chap=${data.no_chap}&no_les=${data.no_les}&no_pgs=${data.no_pgs}`
            // url += '/save-pub-book/'
            url += `/ebook/${bookId}`

            console.log("ebook api url", url)

            const options = {
                method: "DELETE",
                url,

            };



            // let response = await axios(options);
            let response = await axios(options)



            console.log("Delete ebook api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },


    async GetPublisherEbooks() {

        console.log("ebook api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/pub-book/?pub_id=1";



            console.log("ebook api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveContent ebook api response", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async GetPublisherContent(instId, boardId, stdId, subId, chapId, provId) {

        console.log("content api is getting called", provId)
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };


            let url = Constants.Application.PARTNER_END_URL;
            // url += "/content-pub/?content_prov_id=" + provId;
            url += `/content-pub-inst/?content_prov_id=${provId}&institute_type_id=${instId}&institute_board_id=${boardId}&subject_id=${subId}&chapter_id=${chapId}&classroom_std=${stdId}`



            console.log("content api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get Content api response", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async getPricingSetup(userId) {

        console.log("pricing setup api is getting called", userId)
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };


            let url = Constants.Application.PARTNER_END_URL;
            // url += "/content-pub/?content_prov_id=" + provId;
            url += `/content-prov-cost/?content_prov=${userId}`
            console.log("pricing setup api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios.get(url);

            console.log("get pricing setup api response", response.data)


            if (response && response.data) {

                return response.data;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async searchContent(q) {

        console.log(`search content api is getting called for ${q}`)
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += `/search-content/?search=${q}`;



            console.log("content api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get Content api response", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async GetEbookContent(book_id) {

        console.log("ebook api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += `/cont-prov-cont/?book_id=${book_id}`;



            console.log("ebook api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveContent ebook api response", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async GetEbookContentTeachView(book_id) {

        console.log("ebook api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += `/teach-book/?book_id=${book_id}`;



            console.log("ebook api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("getEbookContent ebook api response", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async GetPublisherListCont() {

        console.log("ebook api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            const ProvId = Cookie.get('kgtopg.guruculum.user.id')

            let url = Constants.Application.PARTNER_END_URL;
            url += `/get-all-pub/?cont_prov_id=${ProvId}`;



            console.log("publisher list api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("publishers list api response", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async postEbookMappedContent(data) {
        console.log("api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/save-pub-cont-sub/";



            console.log("postSaveContent api url", url)

            const options = {
                method: "POST",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveContent api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },




    async deleteBoookMappedContent(id) {
        console.log("api is getting called at delete")
        try {


            let url = Constants.Application.PARTNER_END_URL;
            url += `/content-sub/${id}`;



            console.log("delete content api url", url)

            const options = {
                method: "DELETE",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("Delete content api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },



    async updateBoookMappedContent(body, id) {
        console.log("api is getting called at delete")
        try {


            let url = Constants.Application.PARTNER_END_URL;
            // url += `/content-sub/${id}`;
            url += `/update-single-sub/`;



            console.log("delete content api url", url)

            const options = {
                method: "PUT",
                data: body,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("Delete content api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async getContentProviders() {

        console.log("Content providers api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += `/content-prov/`;



            console.log("content api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get content api response", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async DelContentProvider(provDet) {

        console.log("DeletProv providers api is getting called", provDet)
        try {


            const ProvId = Cookie.get('kgtopg.guruculum.user.id')

            const body = {
                cont_prov_id: provDet.id,
                pub_id: ProvId
            }

            let url = Constants.Application.PARTNER_END_URL;
            url += `/delete-cont-prov/`;



            console.log("content api url", url)

            const options = {
                method: "DELETE",
                data: body,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get content api response", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async savePubContProv(data) {
        console.log("savePubContProv api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/contprov-pub/";

            console.log("savePubContProv api url", url)

            const options = {
                method: "POST",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("savePubContProv api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },


    async getMyContentProviders(bookId) {

        console.log(" getMyContentProviders api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += `cont-prov-cont/?book_id=${bookId}`;



            console.log("content api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get content api response", response)


            if (response) {
                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async getEbookMappedContent(book_id) {

        console.log("getting data of ebook mapped details")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/get-cont-sub/?book_id=${book_id}`;



            console.log("content api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get  mapped content response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postSaveApproveStatus(body) {

        console.log("posting approval status with body " + JSON.stringify(body))
        try {

            let url = Constants.Application.PLATFORM_END_URL;
            url += `/approve-reject/?publisher_id=${body.publisher_id}&sub_id=${body.sub_id}&classroom_std=${body.classroom_std}&institute_id=${body.institute_id}&partner_status=${body.partner_status}&publisher_name=${body.publisher_name}`;

            console.log("post approval status api url", url, body)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("post approval status response ", response)

            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postInvite(body) {

        console.log("posting invite with body " + JSON.stringify(body))
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/send-school-invite/`;

            console.log("posting invite status api url", url, body)

            const options = {
                method: "POST",
                url,
                data: body,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("posting invite status response ", response)

            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async deletePublisher(pubId) {


        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/publisher/${pubId}`;



            console.log("delete publisher url", url)

            const options = {
                method: "DELETE",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("delete publisher response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async deleteContProv(provId) {


        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/content-prov/${provId}`;

            console.log("delete content provider url", url)

            const options = {
                method: "DELETE",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("delete content provider response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async deleteContent(contId) {


        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/content/${contId}`;



            console.log("delete content url", url)

            const options = {
                method: "DELETE",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("delete content response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async getPubAdmins(pubId) {

        console.log("getting data of publisher admin details")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/get-pub-details/?user_id=${pubId}`;



            console.log("content api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get  mapped content response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async getContProvAdmins(provId) {

        console.log("getting data of content provider admins")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/get-prov-details/?user_id=${provId}`;

            console.log("content api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get  mapped content response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async postSaveFeedback(data) {
        console.log("api is getting called")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += '/feed-back/'

            console.log("postSaveFeedback api url", url)

            const options = {
                method: "POST",
                url,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },
            };


            let response = await axios(options);

            console.log("postSaveContent api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async updatePubStatus(data) {
        console.log(TAG, "updatePubStatus api is getting called")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += '/update-publisher-status/'

            console.log(TAG, "updatePubStatus api url", url)

            const options = {
                method: "PUT",
                url,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },
            };


            let response = await axios(options);

            console.log(TAG, "updatePubStatus api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

}