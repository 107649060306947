/**
 * quiz planner
 * drawer
 */

import React, { Component, useEffect, useState, useRef } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import PropTypes from "prop-types";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import FolderIcon from "@material-ui/icons/Folder";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import LessonPlanAPI from "../../../http/lessonplanquiz";
import Constants from "../../../resource/Constants";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import SendIcon2 from "@mui/icons-material/Send";

import Button2 from "@mui/material/Button";
import Menu2 from "@mui/material/Menu";
import MenuItem2 from "@mui/material/MenuItem";
import KeyboardArrowDownIcon2 from "@mui/icons-material/KeyboardArrowDown";
import AddIcon2 from "@mui/icons-material/Add";
import AddCircleOutlineIcon2 from "@mui/icons-material/AddCircleOutline";
import Checkbox2 from "@mui/material/Checkbox";
import ReportProblemIcon2 from "@mui/icons-material/ReportProblem";
import Cookie from "js-cookie";
import { useDispatch, useSelector } from "react-redux";

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import JoditEditor from "jodit-react";
import { red } from "@mui/material/colors";

const options = [
  "None",
  "Atria",
  "Callisto",
  "Dione",
  "Ganymede",
  "Hangouts Call",
  "Luna",
  "Oberon",
  "Phobos",
  "Pyxis",
  "Sedna",
  "Titania",
  "Triton",
  "Umbriel",
];

const useStyles3 = makeStyles({
  list: {
    width: 250,
    minWidth: 250,
  },
  fullList: {
    width: "auto",
  },
  fullWidth: {
    width: "100%",
    minWidth: "100%",
  },
  quarterWidth: {
    width: "95%",
  },
});

const useStyles2 = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const useStyles4 = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);
  const [dbApple, setDbApple] = useState(true);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">Phone Ringtone</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: "80%",
    maxHeight: 435,
  },
}));

function ConfirmationDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("Dione");

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  return (
    <div className={classes.root}>
      <List component="div" role="list">
        <ListItem button divider disabled role="listitem">
          <ListItemText primary="Interruptions" />
        </ListItem>
        <ListItem
          button
          divider
          aria-haspopup="true"
          aria-controls="ringtone-menu"
          aria-label="phone ringtone"
          onClick={handleClickListItem}
          role="listitem"
        >
          <ListItemText primary="Phone ringtone" secondary={value} />
        </ListItem>
        <ListItem button divider disabled role="listitem">
          <ListItemText
            primary="Default notification ringtone"
            secondary="Tethys"
          />
        </ListItem>
        <ConfirmationDialogRaw
          classes={{
            paper: classes.paper,
          }}
          id="ringtone-menu"
          keepMounted
          open={open}
          onClose={handleClose}
          value={value}
        />
      </List>
    </div>
  );
}

const CircleIcon = (props) => {
  return (
    <div
      style={{
        display: "flex",
        width: 36,
        height: 36,
        backgroundColor: "#EEE6FF",
        borderRadius: 50,
        color: "black",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.text}
    </div>
  );
};

function AlignItemsList(props) {
  const classes = useStyles4();
  const quiz = props.quiz;

  console.log("quiz in alignitemslist ", JSON.stringify(quiz));

  const onDeleteQuiz = (id) => {
    props.onDeleteQuiz(id);
  };

  useEffect(() => {
    console.log("QuizPlannerDrawer.js useEffect: called.");
  }, []);

  return (
    <List className={classes.root}>
      {quiz && quiz.length ? (
        quiz.map((qstn, index) => {
          // const qst = JSON.parse(qstStr)
          const qst = qstn.question;
          const ops = qstn.options;
          console.log("qst in alignitemslist ", qst);

          let qType = "Multiple Choice";
          console.log(`qtype from qst ${qst.quiz_question_type_id}`);
          console.log(
            `qtype static print ${Constants.Application.QUIZ.ID_QTYPE_TRUE_FALSE}`
          );
          switch (qst.quiz_question_type_id) {
            case Constants.Application.QUIZ.ID_QTYPE_MULTIPLE_CHOICE:
              qType = "Multiple Choice";
              break;
            case Constants.Application.QUIZ.ID_QTYPE_MULTIPLE_SELECT:
              qType = "Multiple Select";
              break;
            case Constants.Application.QUIZ.ID_QTYPE_TRUE_FALSE:
              qType = "True Or False";
              console.log("qtype ", qType);
              break;
            case Constants.Application.QUIZ.ID_QTYPE_TEXT_TYPE:
              qType = "Short Answer";
              break;
            default:
              qType = "None";
          }

          console.log("qtype in alignitemslist ", qType);

          return (
            <div key={index}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {/* <Avatar alt={qType} src="/static/images/avatar/1.jpg" /> */}
                  <CircleIcon text={index + 1} />
                </ListItemAvatar>
                <ListItemText
                  primary={qType}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {qst.question_text}
                      </Typography>
                      {` — Time ${qst.question_time}`}
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        console.log("click question");
                        props.onOpenQuiz(
                          qst.quiz_id,
                          qst.question_id,
                          qst,
                          qst.quiz_question_type_id,
                          ops
                        );
                      }}
                    >
                      <EditIcon
                        style={{
                          color: "gray",
                        }}
                      />
                    </IconButton>

                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        onDeleteQuiz(qst.quiz_id);
                      }}
                    >
                      <DeleteIcon
                        style={{
                          color: "red",
                        }}
                      />
                    </IconButton>
                  </div>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
            </div>
          );
        })
      ) : (
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            {/* <Avatar alt="No Quiz" src="/static/images/avatar/1.jpg" /> */}
            <ReportProblemIcon2 size={48} fontSize="large" color="warning" />
          </ListItemAvatar>
          <ListItemText
            primary="No Questions Created Yet!"
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  No questions created
                </Typography>
                {" — Start creating questions below"}
              </React.Fragment>
            }
          />
        </ListItem>
      )}

      {/* dummy */}
      {/* 
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Summer BBQ"
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                to Scott, Alex, Jennifer
                </Typography>
                            {" — Wish I could come, but I'm out of town this…"}
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Oui Oui"
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                Sandra Adams
                </Typography>
                            {' — Do you have Paris recommendations? Have you ever…'}
                        </React.Fragment>
                    }
                />
            </ListItem>
         */}
    </List>
  );
}

/* 
function getUnpubLessons() {

    // get your unpublished quiz
    const url = '<%= Constants.API_URL %>/quiz/get-unpub-quiz/<%= sLsn.getStaff_lesson_id() %>';

    $.ajax({
        url: url,
        data: 'sid=' + Math.random(),
        async: true,
        method: 'GET',
        success: function (response) {

            // success
            console.log('get unpublished lessons response ' + JSON.stringify(response));

            $('#qlist').html('loading..');
            let qHTML = '';
            let qId = 0;
            let type = 201;
            let typeStr = '';
            let qNum = 1;
            let lbl;
            let bglight = 'bg-light-success'
            if (response && response.length > 0) {

                //					qHTML += '<div class="list-group">';
                //					qHTML += '<div class="list list-hover">';
                qHTML += '<div class="card-body pt-0">';
                response.map(qz => {
                    type = qz.quiz_question_type_id;
                    switch (type) {
                        case parseInt('<%= Constants.ID_QTYPE_MULTIPLE_CHOICE %>'):
                            typeStr = 'Multiple choice';
                            lbl = 'label-danger';
                            bglight = 'bg-light-info';
                            break;
                        case parseInt('<%= Constants.ID_QTYPE_MULTIPLE_SELECT %>'):
                            typeStr = 'Multiple select';
                            lbl = 'label-success';
                            bglight = 'bg-light-warning';
                            break;
                        case parseInt('<%= Constants.ID_QTYPE_TRUE_FALSE %>'):
                            typeStr = 'True Or False';
                            lbl = 'label-info';
                            bglight = 'bg-light-danger';
                            break;
                        case parseInt('<%= Constants.ID_QTYPE_PICTURE_CHOICE %>'):
                            typeStr = 'Picture Choice';
                            lbl = 'label-warning';
                            bglight = 'bg-light-success';
                            break;
                        case parseInt('<%= Constants.ID_QTYPE_TEXT_TYPE %>'):
                            typeStr = 'Short Answer';
                            lbl = 'label-danger';
                            bglight = 'bg-light-info';
                            break;
                        case parseInt('<%= Constants.ID_QTYPE_MATCH_FLW %>'):
                            typeStr = 'Match The Following';
                            lbl = 'label-danger';
                            bglight = 'bg-light-info';
                            break;
                    }

                    if (((qNum % 2) == 0)) {

                    } else if (((qNum % 3) == 0)) {

                    } else if (((qNum % 1) == 0)) {

                    } else {

                    }

                    qHTML += '<div  onmouseover="onMouseHoverd(this)" onmouseout="onMouseOutd(this)" class="d-flex justify-content-between text-left align-items-center mb-9 ' + bglight + ' rounded p-5">' +

                        '<div class="symbol symbol-40 symbol-light mr-4">' +
                        '<span class="symbol-label bg-hover-white">' +
                        '<span class="label ' + lbl + '">' + qNum + '</span>' +
                        '</span>' +
                        '</div>' +

                        '<div class="d-flex flex-column flex-grow-1 mr-2">' +
                        '<div class="d-flex justify-content-start">' +
                        '<span class="text-muted font-weight-bold mr-5">' + (typeStr.toUpperCase()) + '</span>' +

                        '<span><i class="fa fa-clock-o" style="color: #808080bf;"></i>&nbsp;<small style="font-size:1em;">' + qz.question_time + '</small></span>' +
                        '</div>' +
                        '<a href="#" class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">' + qz.question_text + '</a>' +

                        '</div>' +
                        '<div class="qst-close-btn" style="display: none">' +
                        //								      '<div class="dot" style="font-size: 1.5em; font-weight: bold; background: #CCCCCC; width: 30px; height: 30px; border-radius: 50%;">' + 
                        '<span onclick="onDelQuizClicked(\'' + qz.quiz_id + '\', this.parentNode.parentNode)" style="cursor: pointer">' +
                        '<i class="fa fa-times-circle fa-2x text-danger"></i>' +
                        '</span>' +
                        //								      '</div>' + 
                        '</div>' +
                        //'<span class="font-weight-bolder text-warning py-1 font-size-lg">&nbsp;</span>'+

                        '</div>'

                    ++qNum;
                });
                qHTML += '</div>';


                $('#qlist').html(qHTML);
            } else {
                let none = '<div class="p-4">' +
                    '<div class="alert alert-success mb-5 p-5" role="alert" id="noresults">' +
                    '<h4 class="alert-heading">No quiz created yet!</h4>' +
                    '<p>No quiz has been planned recently. Please use the button above to prepare your questions</p>' +
                    '<div class="border-bottom border-white opacity-20 mb-5"></div>' +
                    '<p class="mb-0">You can close this window after setting up the quiz questions.</p>' +
                    '</div>' +
                    '</div>';
                $('#qlist').html(none);
            }
            
        },
        error: function (error) {
            // error page
            console.log('get unpublished lessons error ' + JSON.stringify(error));
        }
    });

}


    function submitOpt() {	
        //debugger
        isinputCheck = false;
        quizQuestion = true;
        let dateStr;
        let today = new Date();
        dateStr = today.getYear() + '-' + today.getMonth() + '-' + today.getDate();
        console.log('datestr ' + dateStr);
        console.log('submitting multiple option quiz');
        if(Validate()) {
        	
            let timeroptMin = document.getElementById("timeroptm");
            let timeroptSec = document.getElementById("timeropts");
            let timerOpt = timeroptMin.value +":"+ timeroptSec.value;
            //let timerOpt = document.getElementById('timeropt');
            console.log('timer opt ' + timerOpt);
            console.log('timer opt value ' + timerOpt);
            let formData = {
                    "classroom_id": '<%= sLsn.getClassroom_id() %>',
                    "staff_id": '<%= sLsn.getStaff_id() %>',
                    "staff_lesson_id": '<%= sLsn.getStaff_lesson_id() %>',
                    "subject_id": '<%= sLsn.getSubject_id() %>',
                    "chapter_id": '<%= sLsn.getChapter_id() %>',
                    "quiz_title": "abcd",
                    "scheduled_date": "2020-07-02",
                    "quiz_ended": 0,
                    "question_type": '<%= Constants.ID_QTYPE_MULTIPLE_CHOICE %>',
                    "question_text":$("#qstOpt").text(),
                    "question_time":'00:'+timerOpt, //'00:'+$(event.target.closest('div')).find('input').val(), // timerOpt.value,
                    "question_url": '',
                    "question_topic_text": "",
                    "question_pts": 1,
                    "options": [
                        {
                            "option_index": 0,
                            "option_text": $("#opt1").val(),
                            "option_url": '',
                            "option_correct": 1
                        },
                        {
                            "option_index": 1,
                            "option_text": $("#opt2").val(),
                            "option_url": '',
                            "option_correct": 0
                        },
                        {
                            "option_index": 2,
                            "option_text": $("#opt3").val(),
                            "option_url": '',
                            "option_correct": 0
                        },
                        {
                            "option_index": 3,
                            "option_text": $("#opt4").val(),
                            "option_url": '',
                            "option_correct": 0
                        }
                    ]
                }

            console.log('multiple choice form data ' + JSON.stringify(formData));

            $.ajax({
                type: 'post',
                url: '<%= Constants.API_URL %>/quiz/add-quiz',
                data: formData,
                success: function(data, textStatus, jqXHR) {
                    // The form was successfully submitted
//	                console.log('submitted successfully!');
                    swal("Your question has been submitted..");
                    console.log(data.insertId);
                    quesnId = data.insertId;
                    $('#quiz-div').hide();
                    $('#quiz-end').show();
                    //replace qbox
                    
                    // call unpublished
                    getUnpubLessons();
                    $drawerQst.toggleClass('quiz-drawer-open');
                    
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    // The submit action failed
                    alert('Failed to add quiz to database, try again.');
                }
            });
        }
        $('.customTextarea').text('');
    }
	
	

function submitChk() {
        console.log('submitting multiple select question')
        isinputCheck = true;
        quizQuestion = true;
        if(Validate()) {

            let timerMin = document.getElementById('timerchkm');
            let timerSec = document.getElementById('timerchks');
//			let timerChk = document.getElementById('timerchk');
//			console.log('timer check value ' + timerChk.value);

            let timerChk = timerMin.value + ":" + timerSec.value;
            let formData = {
                    "classroom_id": '<%= sLsn.getClassroom_id() %>',
                    "staff_id": '<%= sLsn.getStaff_id() %>',
                    "staff_lesson_id": '<%= sLsn.getStaff_lesson_id() %>',
                    "subject_id": '<%= sLsn.getSubject_id() %>',
                    "chapter_id": '<%= sLsn.getChapter_id() %>',
                    "quiz_title": "abcd",
                    "scheduled_date": "2020-12-20",
                    "quiz_ended": 0,
                    "question_type": <%= Constants.ID_QTYPE_MULTIPLE_SELECT %>,
                    "question_text": $("#qstChk").text(),
                    //"question_time": '00:'+$(event.target.closest('div')).find('input').val(),//$("#timerchk").val(),
                    "question_time": '00:'+timerChk,
                    "question_url": '',
                    "question_topic_text": "",
                    "question_pts": 1,
                    "options": [
                        {
                            "option_index": 0,
                            "option_text": $("#chktxt1").val(),
                            "option_url": '',
                            "option_correct": $("#chk1").is(":checked")?1:0
                        },
                        {
                            "option_index": 1,
                            "option_text": $("#chktxt2").val(),
                            "option_url": '',
                            "option_correct": $("#chk2").is(":checked")?1:0
                        },
                        {
                            "option_index": 2,
                            "option_text": $("#chktxt3").val(),
                            "option_url": '',
                            "option_correct": $("#chk3").is(":checked")?1:0
                        },
                        {
                            "option_index": 3,
                            "option_text": $("#chktxt4").val(),
                            "option_url": '',
                            "option_correct": $("#chk4").is(":checked")?1:0
                        }
                    ]
                }
    	
            $.ajax({ 
                type: 'post',
                url: '<%= Constants.API_URL %>/quiz/add-quiz',
                data: formData,
                success: function(data, textStatus, jqXHR) {
                    // The form was successfully submitted
//	                console.log('submitted successfully!');
                    swal("Your question has been submitted..");
                    console.log('submitted successfully!');
                    console.log(data.insertId);
                    console.log(data);
                    quesnId = data.insertId;
                    $('#quiz-div').hide();
                    $('#quiz-end').show();
                    

                    // call unpublished
                    getUnpubLessons();
                    $drawerQst.toggleClass('quiz-drawer-open');
                    
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    // The submit action failed
                    alert('Failed to add quiz to database, try again.');
                }
            });
        }
        $('.customTextarea').text('');
    }
	
    function submitBool() {
        isinputCheck = false;
        quizQuestion = true;
              
console.log('submitting boolean question');
if (Validate()) {

    if (tfOp > -1) {
        let timerboolMin = document.getElementById("timerboolm");
        let timerboolSec = document.getElementById("timerbools");
        let timerBool = timerboolMin.value + ':' + timerboolSec.value;
        //let timerBool = document.getElementById('timerbool');
        console.log('boolean question time ' + timerBool);
        let formData = {
            "classroom_id": '<%= sLsn.getClassroom_id() %>',
            "staff_id": '<%= sLsn.getStaff_id() %>',
            "staff_lesson_id": '<%= sLsn.getStaff_lesson_id() %>',
            "subject_id": '<%= sLsn.getSubject_id() %>',
            "chapter_id": '<%= sLsn.getChapter_id() %>',
            "quiz_title": "abcd",
            "scheduled_date": "2020-12-20",
            "quiz_ended": 0,
            "question_type": <%= Constants.ID_QTYPE_TRUE_FALSE %>,
            "question_text": $("#qstBool").text(),
            "question_time": '00:' + timerBool, //+$(event.target.closest('div')).find('input').val(),//$("#timerbool").val(),
            "question_url": $("#picurl6").val(),
            "question_topic_text": "",
            "question_pts": 1,
            "options": [
                {
                    "option_index": 0,
                    "option_text": (tfOp == 1) ? "True" : "False",//$("#toggle").is(":checked")?"True":"False",
                    "option_url": '',
                    "option_correct": (tfOp == 1) ? 1 : 0,//$("#toggle").is(":checked")?1:0
                }
                

            ]
        }

        console.log('true false form data ' + JSON.stringify(formData));

        $.ajax({
            type: 'POST',
            url: '<%= Constants.API_URL %>/quiz/add-quiz',
            data: formData,
            success: function (data, textStatus, jqXHR) {
                // The form was successfully submitted
                swal("Your question has been submitted..");
                console.log('submitted successfully!');
                console.log(data.insertId);
                console.log(data);
                quesnId = data.insertId;
                $('#quiz-div').hide();
                $('#quiz-end').show();

                // call unpublished
                getUnpubLessons();
                $drawerQst.toggleClass('quiz-drawer-open');

                // reset option
                tfOp = -1;

            },
            error: function (jqXHR, textStatus, errorThrown) {
                // The submit action failed
                swal('Failed to add quiz to database, try again.');
            }
        });
    } else {
        swal('Please select either True (or) False')
    }
}
$('.customTextarea').text('');
              
          }

function submitPOpt() {
    isinputCheck = false;
    quizQuestion = true;
    let dateStr;
    let today = new Date();

    dateStr = today.getYear() + '-' + today.getMonth() + '-' + today.getDate();
    console.log('datestr ' + dateStr);
    console.log('submitting picture option quiz');
    if (Validate()) {
        let timerpoptMin = document.getElementById('timerpoptm');
        let timerpoptSec = document.getElementById('timerpopts');
        let timerPopt = timerpoptMin.value + ":" + timerpoptSec.value;
        let formData = {
            "classroom_id": '<%= sLsn.getClassroom_id() %>',
            "staff_id": '<%= sLsn.getStaff_id() %>',
            "staff_lesson_id": '<%= sLsn.getStaff_lesson_id() %>',
            "subject_id": '<%= sLsn.getSubject_id() %>',
            "chapter_id": '<%= sLsn.getChapter_id() %>',
            "quiz_title": "abcd",
            "scheduled_date": "2020-07-02",
            "quiz_ended": 0,
            "question_type": '<%= Constants.ID_QTYPE_PICTURE_CHOICE %>',
            "question_text": $("#qstPOpt").text(),
            "question_time": '00:' + timerPopt, //+$(event.target.closest('div')).find('input').val(), //$("#timerpopt").val(),
            "question_url": $("#picurl1").val(),
            "question_topic_text": "",
            "question_pts": 1,
            "options": [
                {
                    "option_index": 0,
                    "option_text": $("#popt1").val(),
                    "option_url": $("#picurl2").val(),
                    "option_correct": 1
                },
                {
                    "option_index": 1,
                    "option_text": $("#popt2").val(),
                    "option_url": $("#picurl3").val(),
                    "option_correct": 0
                },
                {
                    "option_index": 2,
                    "option_text": $("#popt3").val(),
                    "option_url": $("#picurl4").val(),
                    "option_correct": 0
                },
                {
                    "option_index": 3,
                    "option_text": $("#popt4").val(),
                    "option_url": $("#picurl5").val(),
                    "option_correct": 0
                },
            ]
        }

        $.ajax({
            type: 'post',
            url: '<%= Constants.API_URL %>/quiz/add-quiz',
            data: formData,
            success: function (data, textStatus, jqXHR) {
                // The form was successfully submitted
                //	                console.log('submitted successfully!');
                Swal.fire("Your question has been submitted..");
                console.log(data.insertId);
                quesnId = data.insertId;
                $('#quiz-div').hide();
                $('#quiz-end').show();
                //replace qbox

                // call unpublished
                getUnpubLessons();
                $drawerQst.toggleClass('quiz-drawer-open');

            },
            error: function (jqXHR, textStatus, errorThrown) {
                // The submit action failed
                Swal('Failed to save quiz, try again.');
            }
        });
    }
    $('.customTextarea').text('');
}

function submitText() {
    isinputCheck = false;
    quizQuestion = true;
    
    console.log('submitting text question');
    let timerTextM = document.getElementById('timertextm');
    let timerTextS = document.getElementById('timertexts');
    let timerText = timerTextM.value + ":" + timerTextS.value;
    if (Validate()) {

        let formData = {
            "classroom_id": '<%= sLsn.getClassroom_id() %>',
            "staff_id": '<%= sLsn.getStaff_id() %>',
            "staff_lesson_id": '<%= sLsn.getStaff_lesson_id() %>',
            "subject_id": '<%= sLsn.getSubject_id() %>',
            "chapter_id": '<%= sLsn.getChapter_id() %>',
            "quiz_title": "abcd",
            "scheduled_date": "2020-12-20",
            "quiz_ended": 0,
            "question_type": <%= Constants.ID_QTYPE_TEXT_TYPE %>,
            "question_text": $("#qstTxt").text(),
            "question_time": '00:' + timerText,//$(event.target.closest('div')).find('input').val(), //$("#timertxt").val(),
            "question_url": '',
            "question_topic_text": "",
            "question_pts": 1,
            "options": [
                {
                    "option_index": 0,
                    "option_text": $("#ansTxt").val(),
                    "option_url": "",
                    "option_correct": 1,//$("#toggle").is(":checked")?1:0
                }
                
            ]
        }

        console.log('form data to be sent ' + JSON.stringify(formData));

        $.ajax({
            type: 'POST',
            url: '<%= Constants.API_URL %>/quiz/add-quiz',
            data: formData,
            success: function (data, textStatus, jqXHR) {
                // The form was successfully submitted
                swal("Your question has been submitted..");
                console.log('submitted successfully!');
                console.log(data.insertId);
                console.log(data);
                quesnId = data.insertId;
                $('#quiz-div').hide();
                $('#quiz-end').show();

                // call unpublished
                getUnpubLessons();
                $drawerQst.toggleClass('quiz-drawer-open');

                // reset option
                tfOp = -1;

            },
            error: function (jqXHR, textStatus, errorThrown) {
                // The submit action failed
                swal('Failed to add quiz to database, try again.');
            }
        });

    }
    $('.customTextarea').text('');
}
*/

const RadioBox = (props) => {
  const classes = useStyles3();
  const {
    lsnPlanId,
    subId,
    subSubId,
    quiz,
    hasTimer,
    planSetup,
    type,
    chapId,
    topId,
    subTopId,
  } = props;

  const [qst, setQst] = useState(quiz ? quiz.question_text : "");
  const [crct, setCrct] = useState("");
  const [op1, setOp1] = useState("");
  const [op2, setOp2] = useState("");
  const [op3, setOp3] = useState("");
  const [op4, setOp4] = useState("");
  const [changed, setChanged] = useState(false);
  const [qstChanged, setQstChanged] = useState(false);

  const [crctChanged, setCrctChanged] = useState(false);
  const [op1Changed, setOp1Changed] = useState(false);

  const [op2Changed, setOp2Changed] = useState(false);

  const [op3Changed, setOp3Changed] = useState(false);

  const [op4Changed, setOp4Changed] = useState(false);
  const [timeMins, setTimeMins] = useState(0);
  const [timeSecs, setTimeSecs] = useState(15);

  const isEdit = props.isEdit ? props.isEdit : false;

  const qBankId = Cookie.get("kgtopg.guruculum.qbank.id");
  const currMap = useSelector((state) => state.currMap.currMap);
  const currLsn = useSelector((state) => state.currLesson.currLesson);

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const onContentChanged = (newConent) => {
    setContent(newConent);
  };

  let btnDisabled = false;

  let count = quiz && quiz.length ? quiz.length : 0;
  console.log("QuizPlannerDrawer.js RadioBox quiz " + JSON.stringify(quiz));

  console.log(
    "QuizPlannerDrawer.js RadioBox props qst " + JSON.stringify(props.qst)
  );
  console.log(
    "QuizPlannerDrawer.js RadioBox props ops " + JSON.stringify(props.ops)
  );
  console.log("QuizPlannerDrawer.js RadioBox isEdit " + isEdit);

  // verify if coming from props or here
  let qst2 = "";
  let crct2 = ""; // the op value which is correct
  let op1b = "";
  let op2b = "";
  let op3b = "";
  let op4b = "";

  if (props.qst && props.ops && isEdit) {
    qst2 = props.qst.question_text;
    op1b = props.ops[0] ? props.ops[0].question_option_text : "";
    op2b = props.ops[1] ? props.ops[1].question_option_text : "";
    op3b = props.ops[2] ? props.ops[2].question_option_text : "";
    op4b = props.ops[3] ? props.ops[3].question_option_text : "";

    if (props.ops[0] && props.ops[0].question_option_correct == 1) crct2 = op1b;
    else if (props.ops[1] && props.ops[1].question_option_correct == 1)
      crct2 = op2b;
    else if (props.ops[2] && props.ops[2].question_option_correct == 1)
      crct2 = op3b;
    else if (props.ops[3] && props.ops[3].question_option_correct == 1)
      crct2 = op4b;

    if (changed) {
      // override from props to state
      if (qstChanged) qst2 = qst;

      if (crctChanged) {
        crct2 = crct;
      }

      if (op1Changed) {
        op1b = op1;
      }

      if (op2Changed) {
        op2b = op2;
      }

      if (op3Changed) {
        op3b = op3;
      }

      if (op4Changed) {
        op4b = op4;
      }
    }
  } else {
    // he is typing
    qst2 = qst;
    op1b = op1;
    op2b = op2;
    op3b = op3;
    op4b = op4;

    crct2 = crct;
  }

  if (
    !qst2 ||
    !crct2 || // should select at least one
    // !op1b ||
    !op2b ||
    !op3b ||
    !op4b
    // || (!timeMins && !timeSecs)
  )
    btnDisabled = true;

  // reset qst if changed
  if (changed && !isEdit) qst2 = qst;

  const onSubmitOpt = async () => {
    const qst2 = props.qst;
    let qst3 = qst;
    let crctc = crct;
    let op1c = crct;
    let op2c = op2;
    let op3c = op3;
    let op4c = op4;

    let ogQstId = 0;
    let ogQuizId = 0;
    if (qst2) {
      ogQuizId = qst2.quiz_id;
      ogQstId = qst2.quiz_question_id;

      if (!qstChanged) qst3 = qst2.question_text; // old loaded question
      // new typed
      else qst3 = qst;

      if (!crctChanged) {
        crctc = crct2;
      }

      if (!op1Changed) {
        op1c = op1b;
      }

      if (!op2Changed) {
        op2c = op2b;
      }

      if (!op3Changed) {
        op3c = op3b;
      }

      if (!op4Changed) {
        op4c = op4b;
      }
    } else {
      qst3 = qst;
    }

    // get all values and call the API
    let dateStr;
    let today = new Date();
    dateStr = today.getYear() + "-" + today.getMonth() + "-" + today.getDate();
    console.log("datestr " + dateStr);
    console.log("submitting multiple option quiz");

    let timerOpt = timeMins + ":" + timeSecs;
    //let timerOpt = document.getElementById('timeropt');
    console.log("timer opt " + timerOpt);
    console.log("timer opt value " + timerOpt);
    let formData = {
      classroom_id: 0,
      staff_id: 0,
      staff_lesson_id: lsnPlanId,
      subject_id: subId,
      subject_sub_id: subSubId,
      chapter_id: chapId,
      topic_id: topId,
      topic_sub_id: subTopId,
      test_type: 'exam',
      quiz_title: type, // we distinguish popquiz by their type under a plan
      scheduled_date: "2020-07-02",
      quiz_ended: 0,
      question_type: Constants.Application.QUIZ.ID_QTYPE_MULTIPLE_CHOICE,
      question_text: qst3,
      question_time: "00:" + timerOpt, //'00:'+$(event.target.closest('div')).find('input').val(), // timerOpt.value,
      question_url: "",
      question_topic_text: "",
      question_pts: 1,
      is_edit: isEdit,
      og_quiz_id: ogQuizId,
      og_question_id: ogQstId,
      options: [
        {
          option_index: 0,
          option_text: crctc,
          option_url: "",
          option_correct: 1,
        },
        {
          option_index: 1,
          option_text: op2c,
          option_url: "",
          option_correct: 0,
        },
        {
          option_index: 2,
          option_text: op3c,
          option_url: "",
          option_correct: 0,
        },
        {
          option_index: 3,
          option_text: op4c,
          option_url: "",
          option_correct: 0,
        },
      ],
    };

    let selIndices = [];
    selIndices.push(0);

    // save only if in create mode

    // save to qbank
    if (!isEdit) {
      let qFormData = {
        profile_id: qBankId,
        institute_type_id: currMap.institute_type_id,
        institute_board_id: currMap.institute_board_id,
        classroom_std: currMap.classroom_std,
        subject_id: currMap.subject_id,
        chapter_id: chapId,
        topic_id: planSetup.topic_id,
        topic_sub_id: subTopId,
        test_type: 'exam',
        question: qst,
        question_type_id: Constants.Application.QUIZ.ID_QTYPE_MULTIPLE_SELECT,
        question_text: qst,
        question_difficulty: 1,
        question_help_text: "",
        options: [crct, op2, op3, op4],
        selected_indices: selIndices,
        solution_text: "",
        solution_images: [],
        solution_videos: [],
        solution_audios: [],
        Question_option_urls: [],
        difficulty_id: 1,
        academicstandard_id: 1,
      };

      await LessonPlanAPI.saveQuizQBank(qFormData);
    }

    console.log("multiple choice form data " + JSON.stringify(formData));
    await LessonPlanAPI.saveQuiz(formData);

    if (!isEdit) {
    }
    // close create
    props.toggleCreate();
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "top",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {!isEdit ? <CircleIcon text={count + 1} /> : <></>}
          <div
            style={{
              marginLeft: 5,
            }}
          >
            <Typography variant="h5">Multiple Choice</Typography>

            <Typography variant="caption">
              Please enter the correct answer in the Second block
            </Typography>
          </div>
        </div>

        {/* TIMER */}
        {/* {hasTimer ? */}
        {!props.noTime ? (
          <div
            style={{
              marginTop: 0,
              marginBottom: 0,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignSelf: "center",
                marginRight: 10,
              }}
            >
              Set Timer
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Mins"
                variant="outlined"
                type="number"
                defaultValue={timeMins}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max: 2,
                    min: 0,
                  },
                }}
                style={{
                  width: 70,
                }}
                onChange={(event) => {
                  const val = event.target.value;
                  console.log(`changed op mins to ${val}`);
                  setTimeMins(val);
                }}
                size="small"
                placeholder="Mins"
              />
              <TextField
                id="outlined-basic"
                label="Secs"
                variant="outlined"
                type="number"
                defaultValue={timeSecs}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max: 60,
                    min: 0,
                  },
                }}
                style={{
                  width: 70,
                }}
                size="small"
                onChange={(event) => {
                  const val = event.target.value;
                  console.log(`changed op secs to ${val}`);
                  setTimeSecs(val);
                }}
                placeholder="Secs"
              />

              <div style={{ margin: 10 }}></div>
              <CloseIcon
                color="danger"
                onClick={props.toggleCreate}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div
        className={classes.fullWidth}
        style={{
          marginTop: 20,
        }}
      >
        <Typography variant="subtitle2" display="block" gutterBottom>
          Question
        </Typography>
        {/* question */}
        <TextField
          id="filled-basic"
          label="Enter Question"
          variant="outlined"
          placeholder="Type here"
          defaultValue={qst2}
          // value={qst2}
          onChange={(event) => {
            const val = event.target.value;
            console.log(`changed op question to ${val}`);
            setQst(val);
            setChanged(true);
            setQstChanged(true);
          }}
          className={classes.fullWidth}
        />

        {/* <JoditEditor
          ref={editor}
          value={qst2}
          tabIndex={1}
          onChange={onContentChanged}
        /> */}

        <div
          style={{
            margin: 10,
          }}
        ></div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle2" display="block" gutterBottom>
            Options
          </Typography>
          <div style={{ marginRight: 10 }}></div>
          <Typography variant="caption" display="block">
            Please enter the right answer in the first option. Remaining should
            be incorrect. It comes in random order for the student.
          </Typography>
        </div>

        {/* <div>
          <JoditEditor
            ref={editor}
            value={crct2}
            tabIndex={1}
            onChange={onContentChanged}
          />
        </div> */}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          {/* <Radio disabled={true} /> */}
          <TextField
            id="filled-basic"
            label="Right answer"
            variant="standard"
            placeholder="Right answer"
            value={crct2}
            defaultValue={crct2}
            onChange={(event) => {
              const val = event.target.value;
              console.log(`changed crct to ${val}`);
              setCrct(val);
              setChanged(true);
              setCrctChanged(true);
            }}
            className={classes.quarterWidth}
          />
        </div>

        <div
          style={{
            margin: 5,
          }}
        ></div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          {/* <Radio disabled={true} /> */}
          <TextField
            id="filled-basic"
            label="Wrong answer"
            variant="standard"
            placeholder="Wrong answer"
            value={op2b}
            defaultValue={op2b}
            onChange={(event) => {
              const val = event.target.value;
              console.log(`changed op3 ans to ${val}`);
              setOp2(val);
              setChanged(true);
              setOp2Changed(true);
            }}
            className={classes.quarterWidth}
          />
        </div>
        {/* <div>
          <JoditEditor
            ref={editor}
            value={op1b}
            tabIndex={1}
            onChange={(val) => {
              setOp1(val)
              onContentChanged(val);
              setOp1Changed(true);
              setChanged(true);
            }}
          />
        </div> */}

        <div
          style={{
            margin: 5,
          }}
        ></div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          {/* <Radio disabled={true} /> */}
          <TextField
            id="filled-basic"
            label="Wrong answer"
            variant="standard"
            placeholder="Wrong answer"
            value={op3b}
            defaultValue={op3b}
            onChange={(event) => {
              const val = event.target.value;
              console.log(`changed op3 ans to ${val}`);
              setOp3(val);
              setChanged(true);
              setOp3Changed(true);
            }}
            className={classes.quarterWidth}
          />
        </div>
        {/* <div>
          <JoditEditor
            ref={editor}
            value={op2b}
            tabIndex={1}
            onChange={onContentChanged}
          />
        </div> */}

        <div
          style={{
            margin: 5,
          }}
        ></div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          {/* <Radio disabled={true} /> */}
          <TextField
            id="filled-basic"
            label="Wrong answer"
            variant="standard"
            placeholder="Wrong answer"
            value={op4b}
            defaultValue={op4b}
            onChange={(event) => {
              const val = event.target.value;
              console.log(`changed op4 ans to ${val}`);
              setOp4(val);
              setChanged(true);
              setOp4Changed(true);
            }}
            className={classes.fullWidth}
          />
        </div>
        {/* <div>
          <JoditEditor
            ref={editor}
            value={op3b}
            tabIndex={1}
            onChange={onContentChanged}
          />
        </div> */}

        <div
          style={{
            display: "flex",
            alignSelf: "flex-end",
            justifyContent: "flex-end",
            marginTop: 20,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              alignSelf: "flex-end",
            }}
            onClick={onSubmitOpt}
            disabled={btnDisabled}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

const CheckBox3 = (props) => {
  const classes = useStyles3();

  const { lsnPlanId, subId, subSubId, quiz, planSetup, type, chapId, topId, subTopId } = props;
  const [qst, setQst] = useState(quiz ? quiz.question_text : "");
  const [qstChanged, setQstChanged] = useState(false);
  const [op1Chk, setOp1Chk] = useState(false);
  const [op1, setOp1] = useState("");
  const [op1Changed, setOp1Changed] = useState(false);
  const [op2Chk, setOp2Chk] = useState(false);
  const [op2, setOp2] = useState("");
  const [op2Changed, setOp2Changed] = useState(false);
  const [op3Chk, setOp3Chk] = useState(false);
  const [op3, setOp3] = useState("");
  const [op3Changed, setOp3Changed] = useState(false);
  const [op4Chk, setOp4Chk] = useState(false);
  const [op4, setOp4] = useState("");
  const [op4Changed, setOp4Changed] = useState(false);
  const [changed, setChanged] = useState(false);

  const [timeMins, setTimeMins] = useState(0);
  const [timeSecs, setTimeSecs] = useState(15);

  const qBankId = Cookie.get("kgtopg.guruculum.qbank.id");
  const currMap = useSelector((state) => state.currMap.currMap);
  const currLsn = useSelector((state) => state.currLesson.currLesson);

  const isEdit = props.isEdit ? props.isEdit : false;

  let btnDisabled = false;

  console.log(`QuizPlannerDrawer.js CheckBox check qst ${qst}`);
  console.log(`QuizPlannerDrawer.js CheckBox check op1 ${op1}`);
  console.log(`QuizPlannerDrawer.js CheckBox check op2 ${op2}`);
  console.log(`QuizPlannerDrawer.js CheckBox check op3 ${op3}`);
  console.log(`QuizPlannerDrawer.js CheckBox check op4 ${op4}`);
  console.log(`QuizPlannerDrawer.js CheckBox check timeMins ${timeMins}`);
  console.log(`QuizPlannerDrawer.js CheckBox check timeSecs ${timeSecs}`);

  // TEMPORARY
  // if (quiz)
  // btnDisabled = true

  let count = quiz && quiz.length ? quiz.length : 0;

  console.log(
    "QuizPlannerDrawer.js CheckBox props qst " + JSON.stringify(props.qst)
  );
  console.log(
    "QuizPlannerDrawer.js CheckBox props ops " + JSON.stringify(props.ops)
  );

  // verify if coming from props or here
  let qst2 = "";
  let op1b = "";
  let op2b = "";
  let op3b = "";
  let op4b = "";
  let op1Chkb = false;
  let op2Chkb = false;
  let op3Chkb = false;
  let op4Chkb = false;

  if (props.qst && props.ops && isEdit) {
    console.log("QuizPlannerDrawer.js CheckBox props check true ");
    console.log("QuizPlannerDrawer.js CheckBox props changed " + changed);
    qst2 = props.qst.question_text;
    op1b = props.ops[0] ? props.ops[0].question_option_text : "";
    op2b = props.ops[1] ? props.ops[1].question_option_text : "";
    op3b = props.ops[2] ? props.ops[2].question_option_text : "";
    op4b = props.ops[3] ? props.ops[3].question_option_text : "";

    op1Chkb = props.ops[0] && props.ops[0].question_option_correct == 1;
    op2Chkb = props.ops[1] && props.ops[1].question_option_correct == 1;
    op3Chkb = props.ops[2] && props.ops[2].question_option_correct == 1;
    op4Chkb = props.ops[3] && props.ops[3].question_option_correct == 1;

    if (changed) {
      // override from props to state
      if (qstChanged) qst2 = qst;

      if (op1Changed) {
        op1b = op1;
        op1Chkb = op1Chk;
      }

      if (op2Changed) {
        op2b = op2;
        op2Chkb = op2Chk;
      }

      if (op3Changed) {
        op3b = op3;
        op3Chkb = op3Chk;
      }

      if (op4Changed) {
        op4b = op4;
        op4Chkb = op4Chk;
      }
    }

    console.log("QuizPlannerDrawer.js CheckBox props op1b " + op1b);
    console.log("QuizPlannerDrawer.js CheckBox props op2b " + op2b);
    console.log("QuizPlannerDrawer.js CheckBox props op3b " + op3b);
    console.log("QuizPlannerDrawer.js CheckBox props op4b " + op4b);

    console.log("QuizPlannerDrawer.js CheckBox props op1bchk " + op1Chkb);
    console.log("QuizPlannerDrawer.js CheckBox props op2bchk " + op2Chkb);
    console.log("QuizPlannerDrawer.js CheckBox props op3bchk " + op3Chkb);
    console.log("QuizPlannerDrawer.js CheckBox props op4bchk " + op4Chkb);
  } else {
    // he is typing first time
    console.log("QuizPlannerDrawer.js CheckBox props check false ");
    qst2 = qst;
    op1b = op1;
    op2b = op2;
    op3b = op3;
    op4b = op4;

    op1Chkb = op1Chk;
    op2Chkb = op2Chk;
    op3Chkb = op3Chk;
    op4Chkb = op4Chk;
  }

  if (
    !qst2 ||
    (!op1b && !op2b && !op3b && !op4b) || // not even 1 selected
    (!op1Chkb && !op2Chkb && !op3Chkb && !op4Chkb) || // not even 1 selected
    (!timeMins && !timeSecs)
  )
    btnDisabled = true;

  if (changed && !isEdit) {
    qst2 = qst;
  }

  const onSubmitChk = async () => {
    const qst2 = props.qst;

    let ogQstId = 0;
    let ogQuizId = 0;
    let qst3 = qst;
    let op1c = op1;
    let op1Chkc = op1Chk;
    let op2c = op2;
    let op2Chkc = op2Chk;
    let op3c = op3;
    let op3Chkc = op3Chk;
    let op4c = op4;
    let op4Chkc = op4Chk;

    if (qst2) {
      // editing
      ogQuizId = qst2.quiz_id;
      ogQstId = qst2.quiz_question_id;

      if (!qstChanged) qst3 = qst2.question_text; // old loaded question
      // new typed
      else qst3 = qst;

      if (!op1Changed) {
        op1c = op1b;
        op1Chkc = op1Chkb;
      }

      if (!op2Changed) {
        op2c = op2b;
        op2Chkc = op2Chkb;
      }

      if (!op3Changed) {
        op3c = op3b;
        op3Chkc = op3Chkb;
      }

      if (!op4Changed) {
        op4c = op4b;
        op4Chkc = op4Chkb;
      }
    } else {
      qst3 = qst;
    }

    let timerChk = timeMins + ":" + timeSecs;
    let formData = {
      classroom_id: 0,
      staff_id: 0,
      qbank_id: qBankId,
      staff_lesson_id: lsnPlanId,
      subject_id: subId,
      subject_sub_id: subSubId,
      chapter_id: chapId,
      topic_id: topId,
      topic_sub_id: subTopId,
      test_type: 'exam',
      quiz_title: type,
      scheduled_date: "2020-12-20",
      quiz_ended: 0,
      question_type: Constants.Application.QUIZ.ID_QTYPE_MULTIPLE_SELECT,
      question_text: qst3,
      //"question_time": '00:'+$(event.target.closest('div')).find('input').val(),//$("#timerchk").val(),
      question_time: "00:" + timerChk,
      question_url: "",
      question_topic_text: "",
      question_pts: 1,
      is_edit: isEdit,
      og_quiz_id: ogQuizId,
      og_question_id: ogQstId,
      options: [
        {
          option_index: 0,
          option_text: op1c,
          option_url: "",
          option_correct: op1Chkc ? 1 : 0,
        },
        {
          option_index: 1,
          option_text: op2c,
          option_url: "",
          option_correct: op2Chkc ? 1 : 0,
        },
        {
          option_index: 2,
          option_text: op3c,
          option_url: "",
          option_correct: op3Chkc ? 1 : 0,
        },
        {
          option_index: 3,
          option_text: op4c,
          option_url: "",
          option_correct: op4Chkc ? 1 : 0,
        },
      ],
    };

    let selIndices = [];
    if (op1Chkc) selIndices.push(0);
    if (op2Chkc) selIndices.push(1);
    if (op3Chkc) selIndices.push(2);
    if (op4Chkc) selIndices.push(3);

    if (!isEdit) {
      let qFormData = {
        profile_id: qBankId,
        institute_type_id: currMap.institute_type_id,
        institute_board_id: currMap.institute_board_id,
        classroom_std: currMap.classroom_std,
        subject_id: currMap.subject_id,
        chapter_id: chapId,
        topic_id: planSetup.topic_id,
        topic_sub_id: subTopId,
        test_type: 'exam',
        question: qst,
        question_type_id: Constants.Application.QUIZ.ID_QTYPE_MULTIPLE_SELECT,
        question_text: qst,
        question_difficulty: 1,
        question_help_text: "",
        options: [op1, op2, op3, op4],
        selected_indices: selIndices,
        solution_text: "",
        solution_images: [],
        solution_videos: [],
        solution_audios: [],
        Question_option_urls: [],
        difficulty_id: 1,
        academicstandard_id: 1,
      };

      // save to qbank
      await LessonPlanAPI.saveQuizQBank(qFormData);
    }

    console.log("QuizPlannerDrawer.js onSubmitChk formData " + formData);
    if (quiz) {
      await LessonPlanAPI.saveQuiz(formData);
    }

    console.log("QuizPlannerDrawer.js onSubmitChk now toggling create");
    // close create
    props.toggleCreate();
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "top",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {!isEdit ? <CircleIcon text={count + 1} /> : <></>}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 5,
            }}
          >
            <Typography variant="h5">Multiple Select</Typography>

            <Typography variant="caption">
              Please select all the correct options only, and then set the time.
            </Typography>
          </div>
        </div>

        {!props.noTime ? (
          <div
            style={{
              marginTop: 0,
              marginBottom: 0,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignSelf: "center",
                marginRight: 5,
              }}
            >
              Set Timer
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Mins"
                variant="outlined"
                type="number"
                defaultValue={timeMins}
                onChange={(event) => {
                  const { name, value } = event.target;
                  console.log(`checkbox mins ${value}`);

                  setTimeMins(value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max: 2,
                    min: 0,
                  },
                }}
                style={{
                  width: 70,
                }}
                size="small"
                placeholder="Mins"
              />
              <TextField
                id="outlined-basic"
                label="Secs"
                variant="outlined"
                type="number"
                defaultValue={timeSecs}
                onChange={(event) => {
                  const { name, value } = event.target;
                  console.log(`checkbox secs ${value}`);

                  setTimeSecs(value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max: 60,
                    min: 0,
                  },
                }}
                style={{
                  width: 70,
                }}
                size="small"
                placeholder="Secs"
              />
              <div style={{ margin: 10 }}></div>
              <CloseIcon
                color="danger"
                onClick={props.toggleCreate}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div
        className={classes.fullWidth}
        style={{
          marginTop: 20,
        }}
      >
        {/* question */}
        <Typography variant="subtitle2" display="block" gutterBottom>
          Question
        </Typography>
        <TextField
          id="filled-basic"
          label="Enter Question"
          variant="outlined"
          placeholder="Type here"
          defaultValue={qst2}
          onChange={(event) => {
            const { name, value } = event.target;

            setQst(value);
            setChanged(true);
            setQstChanged(true);

            // so update state with props
            // if (!qst)
            //     setQst(qst2)
            // if (!op1Chk)
            //     setOp1Chk(op1Chkb)
            // if (!op2Chk)
            //     setOp2Chk(op2Chkb)
            // if (!op3Chk)
            //     setOp3Chk(op3Chkb)
            // if (!op4Chk)
            //     setOp4Chk(op4Chkb)

            // if (!op1)
            //     setOp1(op1b)

            // if (!op2)
            //     setOp2(op2b)
            // if (!op3)
            //     setOp3(op3b)

            // if (!op4)
            //     setOp4(op4b)
          }}
          className={classes.fullWidth}
        />

        <div
          style={{
            margin: 20,
          }}
        ></div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          <Typography variant="subtitle2" display="block">
            Options
          </Typography>
          <div style={{ marginRight: 10 }}></div>
          <Typography variant="caption" display="block">
            Select only the correct options
          </Typography>
        </div>

        {/* answers / choices */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginTop: 5,
          }}
        >
          <Checkbox
            checked={op1Chkb}
            // onChange={handleChange}
            onChange={(event) => {
              const { name, checked } = event.target;
              console.log(`checkbox op1 change state ${checked}`);

              setOp1Chk(checked);
              setChanged(true);
              setOp1Changed(true);
              // setQstChanged(false)
              // setOp2Changed(false)
              // setOp3Changed(false)
              // setOp4Changed(false)

              // so update state with props
              // if (!qst)
              //     setQst(qst2)
              // if (!op1Chk)
              //     setOp1Chk(op1Chkb)
              // if (!op2Chk)
              //     setOp2Chk(op2Chkb)
              // if (!op3Chk)
              //     setOp3Chk(op3Chkb)
              // if (!op4Chk)
              //     setOp4Chk(op4Chkb)

              // if (!op1)
              //     setOp1(op1b)

              // if (!op2)
              //     setOp2(op2b)
              // if (!op3)
              //     setOp3(op3b)

              // if (!op4)
              //     setOp4(op4b)
            }}
            inputProps={{ "aria-label": "primary checkbox" }}
            style={{
              width: "5%",
            }}
          />
          <TextField
            id="filled-basic"
            label="Option 1"
            variant="standard"
            placeholder="Option 1"
            value={op1b}
            defaultValue={op1b}
            onChange={(event) => {
              const { name, value } = event.target;
              console.log(`checkbox op1 ${value}`);

              setOp1(value);
              setChanged(true);
              setOp1Changed(true);
              // setQstChanged(false)
              // setOp2Changed(false)
              // setOp3Changed(false)
              // setOp4Changed(false)

              // so update state with props

              // if (!qst)
              //     setQst(qst2)
              // if (!op1Chk)
              //     setOp1Chk(op1Chkb)
              // if (!op2Chk)
              //     setOp2Chk(op2Chkb)
              // if (!op3Chk)
              //     setOp3Chk(op3Chkb)
              // if (!op4Chk)
              //     setOp4Chk(op4Chkb)

              // if (!op1)
              //     setOp1(op1b)

              // if (!op2)
              //     setOp2(op2b)
              // if (!op3)
              //     setOp3(op3b)

              // if (!op4)
              //     setOp4(op4b)
            }}
            style={{
              width: "95%",
            }}
          />

          {op1Chk ? (
            // <Chip
            //     avatar={<Avatar><CheckIcon /></Avatar>}
            //     label="Correct"
            //     clickable
            //     color="primary"
            //     // onDelete={handleDelete}
            //     deleteIcon={<DoneIcon />}
            //     variant="outlined"

            // />
            <></>
          ) : (
            // <Chip
            //     avatar={<Avatar><CloseIcon /></Avatar>}
            //     label="Wrong"
            //     clickable
            //     color="secondary"
            //     // onDelete={handleDelete}
            //     deleteIcon={<DoneIcon />}
            //     variant="outlined"

            // />
            <></>
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginTop: 5,
          }}
        >
          <Checkbox
            checked={op2Chkb}
            // onChange={handleChange}
            inputProps={{ "aria-label": "primary checkbox" }}
            style={{
              width: "5%",
            }}
            onChange={(event) => {
              const { name, checked } = event.target;
              console.log(`checkbox op2 change state ${checked}`);

              setOp2Chk(checked);
              setChanged(true);
              // setOp1Changed(false)
              // setQstChanged(false)
              setOp2Changed(true);
              // setOp3Changed(false)
              // setOp4Changed(false)

              // so update state with props

              // if (!qst)
              //     setQst(qst2)
              // if (!op1Chk)
              //     setOp1Chk(op1Chkb)
              // if (!op2Chk)
              //     setOp2Chk(op2Chkb)
              // if (!op3Chk)
              //     setOp3Chk(op3Chkb)
              // if (!op4Chk)
              //     setOp4Chk(op4Chkb)

              // if (!op1)
              //     setOp1(op1b)

              // if (!op2)
              //     setOp2(op2b)
              // if (!op3)
              //     setOp3(op3b)

              // if (!op4)
              //     setOp4(op4b)
            }}
          />
          <TextField
            id="filled-basic"
            label="Option 2"
            variant="standard"
            placeholder="Option 2"
            value={op2b}
            defaultValue={op2b}
            onChange={(event) => {
              const { name, value } = event.target;
              console.log(`checkbox op2 ${value}`);

              setOp2(value);
              setChanged(true);
              // setOp1Changed(false)
              // setQstChanged(false)
              setOp2Changed(true);
              // setOp3Changed(false)
              // setOp4Changed(false)

              // so update state with props

              // if (!qst)
              //     setQst(qst2)
              // if (!op1Chk)
              //     setOp1Chk(op1Chkb)
              // if (!op2Chk)
              //     setOp2Chk(op2Chkb)
              // if (!op3Chk)
              //     setOp3Chk(op3Chkb)
              // if (!op4Chk)
              //     setOp4Chk(op4Chkb)

              // if (!op1)
              //     setOp1(op1b)

              // if (!op2)
              //     setOp2(op2b)
              // if (!op3)
              //     setOp3(op3b)

              // if (!op4)
              //     setOp4(op4b)
            }}
            style={{
              width: "95%",
            }}
          />

          {op2Chk ? (
            // <Chip
            //     avatar={<Avatar><CheckIcon /></Avatar>}
            //     label="Correct"
            //     clickable
            //     color="primary"
            //     // onDelete={handleDelete}
            //     deleteIcon={<DoneIcon />}
            //     variant="outlined"
            //     style={{
            //         width: '20%'
            //     }}
            // />
            <></>
          ) : (
            // <Chip
            //     avatar={<Avatar><CloseIcon /></Avatar>}
            //     label="Wrong"
            //     clickable
            //     color="secondary"
            //     // onDelete={handleDelete}
            //     deleteIcon={<DoneIcon />}
            //     variant="outlined"
            //     style={{
            //         width: '20%'
            //     }}
            // />
            <></>
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginTop: 5,
          }}
        >
          <Checkbox
            checked={op3Chkb}
            // onChange={handleChange}
            inputProps={{ "aria-label": "primary checkbox" }}
            onChange={(event) => {
              const { name, checked } = event.target;
              console.log(`checkbox op3 change state ${checked}`);

              setOp3Chk(checked);
              setChanged(true);
              // setOp1Changed(false)
              // setQstChanged(false)
              // setOp2Changed(false)
              setOp3Changed(true);
              // setOp4Changed(false)

              // so update state with props

              // if (!qst)
              //     setQst(qst2)
              // if (!op1Chk)
              //     setOp1Chk(op1Chkb)
              // if (!op2Chk)
              //     setOp2Chk(op2Chkb)
              // if (!op3Chk)
              //     setOp3Chk(op3Chkb)
              // if (!op4Chk)
              //     setOp4Chk(op4Chkb)

              // if (!op1)
              //     setOp1(op1b)

              // if (!op2)
              //     setOp2(op2b)
              // if (!op3)
              //     setOp3(op3b)

              // if (!op4)
              //     setOp4(op4b)
            }}
            style={{
              width: "5%",
            }}
          />
          <TextField
            id="filled-basic"
            label="Option 3"
            variant="standard"
            placeholder="Option 3"
            value={op3b}
            defaultValue={op3b}
            onChange={(event) => {
              const { name, value } = event.target;
              console.log(`checkbox op3 ${value}`);

              setOp3(value);
              setChanged(true);
              // setOp1Changed(false)
              // setQstChanged(false)
              // setOp2Changed(false)
              setOp3Changed(true);
              // setOp4Changed(false)

              // so update state with props

              // if (!qst)
              //     setQst(qst2)
              // if (!op1Chk)
              //     setOp1Chk(op1Chkb)
              // if (!op2Chk)
              //     setOp2Chk(op2Chkb)
              // if (!op3Chk)
              //     setOp3Chk(op3Chkb)
              // if (!op4Chk)
              //     setOp4Chk(op4Chkb)

              // if (!op1)
              //     setOp1(op1b)

              // if (!op2)
              //     setOp2(op2b)
              // if (!op3)
              //     setOp3(op3b)

              // if (!op4)
              //     setOp4(op4b)
            }}
            style={{
              width: "95%",
            }}
          />

          {op3Chk ? (
            // <Chip
            //     avatar={<Avatar><CheckIcon /></Avatar>}
            //     label="Correct"
            //     clickable
            //     color="primary"
            //     // onDelete={handleDelete}
            //     deleteIcon={<DoneIcon />}
            //     variant="outlined"
            //     style={{
            //         width: '20%'
            //     }}
            // />
            <></>
          ) : (
            // <Chip
            //     avatar={<Avatar><CloseIcon /></Avatar>}
            //     label="Wrong"
            //     clickable
            //     color="secondary"
            //     // onDelete={handleDelete}
            //     deleteIcon={<DoneIcon />}
            //     variant="outlined"
            //     style={{
            //         width: '20%'
            //     }}
            // />

            <></>
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginTop: 5,
          }}
        >
          <Checkbox
            checked={op4Chkb}
            // onChange={handleChange}
            inputProps={{ "aria-label": "primary checkbox" }}
            onChange={(event) => {
              const { name, checked } = event.target;
              console.log(`checkbox op4 change state ${checked}`);

              setOp4Chk(checked);
              setChanged(true);
              // setOp1Changed(false)
              // setQstChanged(false)
              // setOp2Changed(false)
              // setOp3Changed(false)
              setOp4Changed(true);

              // so update state with props

              // if (!qst)
              //     setQst(qst2)
              // if (!op1Chk)
              //     setOp1Chk(op1Chkb)
              // if (!op2Chk)
              //     setOp2Chk(op2Chkb)
              // if (!op3Chk)
              //     setOp3Chk(op3Chkb)
              // if (!op4Chk)
              //     setOp4Chk(op4Chkb)

              // if (!op1)
              //     setOp1(op1b)

              // if (!op2)
              //     setOp2(op2b)
              // if (!op3)
              //     setOp3(op3b)

              // if (!op4)
              //     setOp4(op4b)
            }}
            style={{
              width: "5%",
            }}
          />
          <TextField
            id="filled-basic"
            label="Option 4"
            variant="standard"
            placeholder="Option 4"
            value={op4b}
            defaultValue={op4b}
            onChange={(event) => {
              const { name, value } = event.target;
              console.log(`checkbox op4 ${value}`);

              setOp4(value);
              setChanged(true);
              // setOp1Changed(false)
              // setQstChanged(false)
              // setOp2Changed(false)
              // setOp3Changed(false)
              setOp4Changed(true);

              // so update state with props

              // if (!qst)
              //     setQst(qst2)
              // if (!op1Chk)
              //     setOp1Chk(op1Chkb)
              // if (!op2Chk)
              //     setOp2Chk(op2Chkb)
              // if (!op3Chk)
              //     setOp3Chk(op3Chkb)
              // if (!op4Chk)
              //     setOp4Chk(op4Chkb)

              // if (!op1)
              //     setOp1(op1b)

              // if (!op2)
              //     setOp2(op2b)
              // if (!op3)
              //     setOp3(op3b)

              // if (!op4)
              //     setOp4(op4b)
            }}
            style={{
              width: "95%",
            }}
          />

          {op4Chk ? (
            // <Chip
            //     avatar={<Avatar><CheckIcon /></Avatar>}
            //     label="Correct"
            //     clickable
            //     color="primary"
            //     // onDelete={handleDelete}
            //     deleteIcon={<DoneIcon />}
            //     variant="outlined"
            //     style={{
            //         width: '20%'
            //     }}
            // />
            <></>
          ) : (
            // <Chip
            //     avatar={<Avatar><CloseIcon /></Avatar>}
            //     label="Wrong"
            //     clickable
            //     color="secondary"
            //     // onDelete={handleDelete}
            //     deleteIcon={<DoneIcon />}
            //     variant="outlined"
            //     style={{
            //         width: '20%'
            //     }}
            // />
            <></>
          )}
        </div>

        {/* save button */}

        <div
          style={{
            display: "flex",
            alignSelf: "flex-end",
            justifyContent: "flex-end",
            marginTop: 20,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={btnDisabled}
            onClick={onSubmitChk}
            style={{
              alignSelf: "flex-end",
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

const TrueFalseBox = (props) => {
  const classes = useStyles3();
  let isEdit2 = props.isEdit ? true : false;
  const { lsnPlanId, subId, subSubId, quiz, planSetup, type, chapId,
  topId, subTopId } = props;

  const [qst, setQst] = useState(quiz ? quiz.question_text : "");
  const [isTrue, setIsTrue] = useState(null);
  const [changed, setChanged] = useState(false); // default

  const [timeMins, setTimeMins] = useState(0);
  const [timeSecs, setTimeSecs] = useState(15);
  // const [isEdit, setIsEdit] = useState(isEdit2);
  const isEdit = isEdit2;

  const handleTrueChanged = (val) => {
    console.log("QuizPlannerDrawer.js handleTrueChanged: new val is " + val);
    setIsTrue(val);

    // edit mode should change too
    setChanged(true);
  };

  const onSetQst = (qst) => {
    setQst(qst);
  };

  const qBankId = Cookie.get("kgtopg.guruculum.qbank.id");
  const currMap = useSelector((state) => state.currMap.currMap);
  const currLsn = useSelector((state) => state.currLesson.currLesson);

  console.log(`true false value now is ${isTrue}`);

  let btnDisabled = false;

  let count = quiz && quiz.length ? quiz.length : 0;
  console.log("QuizPlannerDrawer.js TrueFalseBox quiz " + quiz);

  useEffect(() => {
    const { qst } = props;

    console.log(
      "QuizPlannerDrawer.js TrueFalseBox useEffect: called with qst props.. " +
        JSON.stringify(qst)
    );

    if (qst) setQst(qst.question_text);
  }, []);

  const handleSetQst = (qst) => {
    setQst(qst);
  };

  console.log("QuizPlannerDrawer.js TrueFalseBox rendering again..");

  console.log(
    "QuizPlannerDrawer.js TrueFalseBox props qst " + JSON.stringify(props.qst)
  );
  console.log(
    "QuizPlannerDrawer.js TrueFalseBox props ops " + JSON.stringify(props.ops)
  );

  let op1 = false;
  let op2 = false;
  let isTrue2 = null;
  // verify if coming from props or here
  let qst2 = "";
  if (props.qst && props.ops && isEdit) {
    console.log("true false from props");
    qst2 = props.qst.question_text;
    op1 = props.ops[0] && props.ops[0].question_option_correct == 1; // true
    op2 = props.ops[0] && props.ops[0].question_option_correct == 0;

    console.log("true false from props op1 " + op1);
    console.log("true false from props op2 " + op2);
    isTrue2 = op1 == true; // this is first load
  } else {
    console.log("true false from state");
    // he is typing
    qst2 = qst;
    isTrue2 = isTrue;
  }

  // verify if state changed
  if (changed && !isEdit) {
    isTrue2 = isTrue;
    qst2 = qst;
  }

  if (
    !qst2 ||
    isTrue2 == null

    // || (!timeMins
    // && !timeSecs)
  )
    btnDisabled = true;

  const onSubmitTF = async () => {
    const qst2 = props.qst;
    let qst3 = qst;

    let ogQstId = 0;
    let ogQuizId = 0;
    if (qst2) {
      ogQuizId = qst2.quiz_id;
      ogQstId = qst2.quiz_question_id;

      if (!changed) qst3 = qst2.question_text; // old loaded question
      // new typed
      else qst3 = qst;
    } else {
      qst3 = qst;
    }

    let timerBool = timeMins + ":" + timeSecs;
    //let timerBool = document.getElementById('timerbool');
    console.log("boolean question time " + timerBool);

    let formData = {
      classroom_id: 0,
      staff_id: 0,
      staff_lesson_id: lsnPlanId,
      subject_id: subId,
      subject_sub_id: subSubId,
      chapter_id: chapId,
      topic_id: topId,
      topic_sub_id: subTopId,
      test_type: 'exam',
      quiz_title: type,
      scheduled_date: "2020-12-20",
      quiz_ended: 0,
      question_type: Constants.Application.QUIZ.ID_QTYPE_TRUE_FALSE,
      question_text: qst3,
      question_time: "00:" + timerBool, //+$(event.target.closest('div')).find('input').val(),//$("#timerbool").val(),
      question_url: "",
      question_topic_text: "",
      question_pts: 1,
      is_edit: isEdit,
      og_quiz_id: ogQuizId,
      og_question_id: ogQstId,
      options: [
        {
          option_index: 0,
          option_text: isTrue == true ? "True" : "False", //$("#toggle").is(":checked")?"True":"False",
          option_url: "",
          option_correct: isTrue == true ? 1 : 0, //$("#toggle").is(":checked")?1:0
        },
      ],
    };

    let selIndices = [];
    if (isTrue == true) {
      selIndices.push(1);
    } else {
      selIndices.push(0);
    }

    if (!isEdit) {
      let qFormData = {
        profile_id: qBankId,
        institute_type_id: currMap.institute_type_id,
        institute_board_id: currMap.institute_board_id,
        classroom_std: currMap.classroom_std,
        subject_id: currMap.subject_id,
        chapter_id: chapId,
        topic_id: planSetup.topic_id,
        topic_sub_id: subTopId,
        test_type: 'exam',
        question: qst,
        question_type_id: Constants.Application.QUIZ.ID_QTYPE_TRUE_FALSE,
        question_text: qst,
        question_difficulty: 1,
        question_help_text: "",
        options: [isTrue == true ? "True" : "False"],
        selected_indices: selIndices,
        solution_text: "",
        solution_images: [],
        solution_videos: [],
        solution_audios: [],
        Question_option_urls: [],
        difficulty_id: 1,
        academicstandard_id: 1,
      };

      // save to qbank
      await LessonPlanAPI.saveQuizQBank(qFormData);
    }

    console.log("true false form data " + JSON.stringify(formData));

    // submit quiz..
    await LessonPlanAPI.saveQuiz(formData);

    // close create
    props.toggleCreate();
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "top",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {!isEdit ? <CircleIcon text={count + 1} /> : <></>}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 5,
            }}
          >
            <Typography variant="h5">True or False</Typography>

            <Typography variant="caption">
              Please select the following field, true or false.
            </Typography>
          </div>
        </div>

        {/* timer */}
        {!props.noTime ? (
          <div
            style={{
              marginTop: 0,
              marginBottom: 0,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                alignSelf: "center",
                marginRight: 5,
              }}
            >
              Set Timer
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Mins"
                variant="outlined"
                type="number"
                defaultValue={timeMins}
                onChange={(event) => {
                  const { name, value } = event.target;

                  setTimeMins(value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max: 2,
                    min: 0,
                  },
                }}
                style={{
                  width: 70,
                }}
                size="small"
                placeholder="Mins"
              />
              <TextField
                id="outlined-basic"
                label="Secs"
                variant="outlined"
                type="number"
                defaultValue={timeSecs}
                onChange={(event) => {
                  const { name, value } = event.target;

                  setTimeSecs(value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max: 60,
                    min: 0,
                  },
                }}
                style={{
                  width: 70,
                }}
                size="small"
                placeholder="Secs"
              />

              <div style={{ width: 10 }}></div>
              <CloseIcon
                color="danger"
                onClick={props.toggleCreate}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div
        className={classes.fullWidth}
        style={{
          marginTop: 20,
        }}
      >
        {/* question */}
        <Typography variant="subtitle2" display="block" gutterBottom>
          Question
        </Typography>
        <TextField
          id="filled-basic"
          label="Enter Question"
          variant="outlined"
          placeholder="Type here"
          // defaultValue={qst}
          defaultValue={qst2}
          onChange={(event) => {
            const { name, value } = event.target;

            setQst(value);
            setChanged(true);
          }}
          className={classes.fullWidth}
        />
        <div
          style={{
            margin: 20,
          }}
        ></div>

        {/* options */}
        <Typography variant="subtitle2" display="block">
          Options
        </Typography>
        <div style={{ margin: 10 }}></div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Chip
            avatar={
              <Avatar>
                <CheckIcon />
              </Avatar>
            }
            label="True"
            clickable
            color="primary"
            // onDelete={handleDelete}
            deleteIcon={<DoneIcon />}
            variant={isTrue2 ? "default" : "outlined"}
            onClick={() => {
              console.log(`true false was ${isTrue2}`);

              // not true or false
              if (!isTrue2) handleTrueChanged(true);
              else handleTrueChanged(null);
            }}
          />
          <div
            style={{
              marginLeft: 10,
            }}
          ></div>
          <Chip
            avatar={
              <Avatar>
                <CloseIcon />
              </Avatar>
            }
            label="False"
            clickable
            color="secondary"
            // onDelete={handleDelete}
            deleteIcon={<DoneIcon />}
            variant={isTrue2 == false ? "default" : "outlined"}
            onClick={() => {
              console.log(`true false was ${isTrue2}`);

              // already clicked true
              if (isTrue2 || isTrue2 == null) handleTrueChanged(false);
              else if (isTrue2 == false) handleTrueChanged(null);
            }}
          />
        </div>

        {/* save button */}

        <div
          style={{
            display: "flex",
            alignSelf: "flex-end",
            justifyContent: "flex-end",
            marginTop: 20,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={btnDisabled}
            onClick={onSubmitTF}
            style={{
              alignSelf: "flex-end",
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

const ShortAnswerBox = (props) => {
  const classes = useStyles3();

  const { lsnPlanId, subId, subSubId, quiz, planSetup, type, chapId, topId, subTopId } = props;
  const [qst, setQst] = useState(quiz ? quiz.question_text : "");
  const [ans, setAns] = useState("");

  const [timeMins, setTimeMins] = useState(0);
  const [timeSecs, setTimeSecs] = useState(15);

  const [changed, setChanged] = useState(false);

  const qBankId = Cookie.get("kgtopg.guruculum.qbank.id");
  const currMap = useSelector((state) => state.currMap.currMap);
  const currLsn = useSelector((state) => state.currLesson.currLesson);

  const isEdit = props.isEdit ? props.isEdit : false;
  const onSubmitText = async () => {
    const qst2 = props.qst;
    let qst3 = qst;

    let ogQstId = 0;
    let ogQuizId = 0;
    if (qst2) {
      ogQuizId = qst2.quiz_id;
      ogQstId = qst2.quiz_question_id;

      if (!changed) qst3 = qst2.question_text; // old loaded question
      // new typed
      else qst3 = qst;
    } else {
      qst3 = qst;
    }

    console.log("submitting text question");
    let timerTextM = timeMins;
    let timerTextS = timeSecs;
    let timerText = timerTextM + ":" + timerTextS;

    let formData = {
      classroom_id: 0,
      staff_id: 0,
      staff_lesson_id: lsnPlanId,
      subject_id: subId,
      subject_sub_id: subSubId,
      chapter_id: chapId,
      topic_id: topId,
      topic_sub_id: subTopId,
      test_type: 'exam',
      quiz_title: type,
      scheduled_date: "2020-12-20",
      quiz_ended: 0,
      question_type: Constants.Application.QUIZ.ID_QTYPE_TEXT_TYPE,
      question_text: qst3,
      question_time: "00:" + timerText, //$(event.target.closest('div')).find('input').val(), //$("#timertxt").val(),
      question_url: "",
      question_topic_text: "",
      question_pts: 1,
      is_edit: isEdit,
      og_quiz_id: ogQuizId,
      og_question_id: ogQstId,
      options: [
        {
          option_index: 0,
          option_text: ans,
          option_url: "",
          option_correct: 1, //$("#toggle").is(":checked")?1:0
        },
      ],
    };

    let selIndices = [0];

    // save to quiz bank
    if (!isEdit) {
      let qFormData = {
        profile_id: qBankId,
        institute_type_id: currMap.institute_type_id,
        institute_board_id: currMap.institute_board_id,
        classroom_std: currMap.classroom_std,
        subject_id: currMap.subject_id,
        chapter_id: chapId,
        topic_id: planSetup.topic_id,
        topic_sub_id: subTopId,
        test_type: 'exam',
        question: qst,
        question_type_id: Constants.Application.QUIZ.ID_QTYPE_TEXT_TYPE,
        question_text: qst,
        question_difficulty: 1,
        question_help_text: "",
        options: [ans],
        selected_indices: selIndices,
        solution_text: "",
        solution_images: [],
        solution_videos: [],
        solution_audios: [],
        Question_option_urls: [],
        difficulty_id: 1,
        academicstandard_id: 1,
      };

      // save to qbank
      await LessonPlanAPI.saveQuizQBank(qFormData);
    }

    console.log("short answer form data " + JSON.stringify(formData));

    // save quiz
    await LessonPlanAPI.saveQuiz(formData);

    // close create
    props.toggleCreate();
  };

  let btnDisabled = false;

  console.log(
    "QuizPlannerDrawer.js ShortAnswerBox props qst " + JSON.stringify(props.qst)
  );
  console.log(
    "QuizPlannerDrawer.js ShortAnswerBox props ops " + JSON.stringify(props.ops)
  );

  // verify if coming from props or here
  let qst2 = "";
  let ans2 = "";
  if (props.qst && props.ops && isEdit) {
    qst2 = props.qst.question_text;
    ans2 =
      props.ops[0] && props.ops[0].question_option_text
        ? props.ops[0].question_option_text
        : "";
  } else {
    // he is typing
    qst2 = qst;
    ans2 = ans;
  }

  if (
    !qst2 ||
    !ans2
    // || (!timeMins && !timeSecs)
  )
    btnDisabled = true;

  let count = quiz && quiz.length ? quiz.length : 0;

  if (changed && !isEdit) {
    qst2 = qst;
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "top",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {!isEdit ? <CircleIcon text={count + 1} /> : <></>}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 5,
            }}
          >
            <Typography variant="h5">Short Answer</Typography>

            <Typography variant="caption">
              Please enter the correct answer.
            </Typography>
          </div>
        </div>

        {/* timer */}
        {!props.noTime ? (
          <div
            style={{
              marginTop: 0,
              marginBottom: 0,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                alignSelf: "center",
                marginRight: 5,
              }}
            >
              Set Timer
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Mins"
                variant="outlined"
                type="number"
                defaultValue={timeMins}
                onChange={(event) => {
                  const { name, value } = event.target;
                  setTimeMins(value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max: 2,
                    min: 0,
                  },
                }}
                style={{
                  width: 70,
                }}
                size="small"
                placeholder="Mins"
              />
              <TextField
                id="outlined-basic"
                label="Secs"
                variant="outlined"
                type="number"
                defaultValue={timeSecs}
                onChange={(event) => {
                  const { name, value } = event.target;
                  setTimeSecs(value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max: 60,
                    min: 0,
                  },
                }}
                style={{
                  width: 70,
                }}
                size="small"
                placeholder="Secs"
              />

              <div style={{ margin: 10 }}></div>
              <CloseIcon
                color="danger"
                onClick={props.toggleCreate}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div
        className={classes.fullWidth}
        style={{
          marginTop: 20,
        }}
      >
        {/* question */}
        <TextField
          id="filled-basic"
          label="Enter question"
          variant="outlined"
          placeholder="Type here"
          defaultValue={qst2}
          onChange={(event) => {
            const { name, value } = event.target;
            setQst(value);
            setChanged(true);
          }}
          className={classes.fullWidth}
        />
        <div
          style={{
            marginTop: 10,
          }}
        ></div>

        {/* options */}
        <TextField
          id="filled-basic"
          label="Enter correct answer"
          variant="outlined"
          placeholder="Type here"
          defaultValue={ans2}
          onChange={(event) => {
            const { name, value } = event.target;
            setAns(value);
          }}
          className={classes.fullWidth}
        />

        {/* save button */}

        <div
          style={{
            display: "flex",
            alignSelf: "flex-end",
            justifyContent: "flex-end",
            marginTop: 20,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={btnDisabled}
            onClick={onSubmitText}
            onDoubleClick={btnDisabled}
            style={{
              alignSelf: "flex-end",
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
/* 
const ret = (
    <>

        <div class="quiz-drawer-push">
            <div class="quiz-drawer quiz-drawer-right">
                <div
                    class="offcanvas-header d-flex align-items-center justify-content-between p-5">
                    <h3 class="font-weight-bold m-0">
                        Quiz Planner <small class="text-muted font-size-sm ml-2">Start
                  a quiz by creating questions</small>
                    </h3>
                    <a href="#"
                        class="close-btn2 btn btn-xs btn-icon btn-light btn-hover-primary">
                        <i class="ki ki-close icon-xs text-muted"></i>
                    </a>
                </div>
                <div class="w-100">
                    <button class="qst-btn btn btn-info w-50 mb-1 addQuizBtn" onclick="clickonCreateQzBtn()">
                        Create Question <i class="fa fa-plus-square-o" aria-hidden="true"></i>
                    </button>
                    <h5
                        style="margin-right: 10%; display: flex; justify-content: flex-start; margin-left: 5%; margin-top: 4%; margin-bottom: 2%;"
                        id="questionsCount" class="mt-3">
                        <span class="questionsCountSpan"> </span>&nbsp;Questions
          </h5>

                </div>
                <div class="modal-body" style="height: 100%; padding-top: 0;">

                    <div class="mb-4" id="qlist" style="height: 75%; overflow-y: auto">
                        <ul class="nav nav-list">
                            <li>No quiz created yet..</li>
                        </ul>
                    </div>

                </div>

            </div>

        </div>


        <div class="quiz-drawer-push">
            <div class="quiz-drawer quiz-drawer-qst overflow-auto">
                <div
                    class="offcanvas-header d-flex align-items-center justify-content-between p-5">
                    <h3 class="font-weight-bold m-0">
                        Quiz Planner <small class="text-muted font-size-sm ml-2">Create
                  a question by choosing a type</small>
                    </h3>
                    <a href="#"
                        class="close-btn btn btn-xs btn-icon btn-light btn-hover-primary quiz-close-btn">
                        <i class="ki ki-close icon-xs text-muted"></i>
                    </a>
                </div>
                <div class="modal-body ">
                    <div class="quizTopSectopn ">
                        <div class="dropdown quizDropdown quizDropdownSection"
                            style="text-align: left;">
                            <button
                                class="btn btn-outline-secondary quizDropdownBtn qsttype w-50 "
                                type="button" id="dropdownMenuButton" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                Select Question type <i class="fa fa-angle-down float-right"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" href="#" onclick="addMultiple()">Multiple
                          Choice</a> <a class="dropdown-item" href="#" onclick="addCheck()">Multiple
                          Select</a> <a class="dropdown-item" href="#" onclick="addTF()">True
                          / False</a> <a class="dropdown-item" href="#"
                                    onclick="addPicChoice()">Picture Choice</a> <a
                                        class="dropdown-item" href="#" onclick="shortAnswer()">Short
                          Answer</a>

                            </div>
                        </div>

                    </div>
                    <div id="qbox"></div>
                </div>

            </div>

        </div>

    </>
)
 */
const QuizBox = (props) => {
  const classes = useStyles3();
  const [qType, setQType] = useState("Choose type");
  const [create, setCreate] = useState(false);
  const [quizId, setQuizId] = useState(0);
  const [qstId, setQstId] = useState(0);
  const [qTypeId, setQTypeId] = useState(0);
  const [quiz, setQuiz] = useState(props.quiz);
  const [isEdit, setIsEdit] = useState(props.isEdit);
  const [qst, setQst] = useState(null);
  const [ops, setOps] = useState(null);

  const { planSetup, type, chapId, topId, subTopId } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  console.log("QuizPlannerDrawer.js QuizBox quiz " + props.quiz);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsEdit(false); // make edit false by force
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let quizName = "Quiz";
  if (props.name) quizName = props.name;

  let title = `${quizName} PopQuiz`;

  if (type == "homework") {
    title = quizName;
  } else if (type === "classwork") {
    title = `Questions & Answers`;
  }

  const toggleCreate = () => {
    console.log("QuizPlannerDrawer QuizBox toggleCreate() called..");
    setCreate(!create);

    // if (create) {
    // show new
    setQuiz(null);
    setQuizId(0);
    setQstId(0);
    setQType("");
    setQTypeId(0);
    setIsEdit(false); // reset
    // }
    props.onUpdateList();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setQType(value);
  };

  const handleChange2 = (type) => {
    setQType(type);
    handleClose();
  };

  const handleChangeQTypeId = (qTypeId2) => {
    console.log(
      "QuizPlannerDrawer.js QuizBox handleChangeQTypeId qTypeId " + qTypeId2
    );
    const qTypeId3 = parseInt(qTypeId2);
    switch (qTypeId3) {
      case Constants.Application.QUIZ.ID_QTYPE_MULTIPLE_CHOICE:
        console.log(
          "QuizPlannerDrawer.js QuizBox handleChangeQTypeId qType MULTIPLECHOICE"
        );
        setQType("MULTIPLECHOICE");
        break;
      case Constants.Application.QUIZ.ID_QTYPE_MULTIPLE_SELECT:
        console.log(
          "QuizPlannerDrawer.js QuizBox handleChangeQTypeId qType MULTIPLESELECT"
        );
        setQType("MULTIPLESELECT");
        break;
      case Constants.Application.QUIZ.ID_QTYPE_TRUE_FALSE:
        console.log(
          "QuizPlannerDrawer.js QuizBox handleChangeQTypeId qType TRUEFALSE"
        );
        setQType("TRUEFALSE");
        break;
      case Constants.Application.QUIZ.ID_QTYPE_TEXT_TYPE:
        console.log(
          "QuizPlannerDrawer.js QuizBox handleChangeQTypeId qType SHORTANSWER"
        );
        setQType("SHORTANSWER");
        break;
    }
  };

  const onSubmitOpt = () => {
    // show success message from callback

    console.log(`submitted multiple choice successfully..`);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  console.log("QuizPlannerDrawer.js QuizBox qType is " + qType);

  const quizQuestions = (
    <div
      style={{
        padding: 20,
        // width: 500
        width: "100%",
      }}
    >
      {/* create question button */}
      {/* <Button variant="contained" color="primary"
                onClick={toggleCreate}>
                Add Question
            </Button>

            <div style={{ margin: 10 }}></div> */}

      {/* {(create) && */}

      <>
        <div
          style={{
            marginTop: 20,
          }}
        >
          {qType == "MULTIPLESELECT" && (
            <CheckBox3
              planSetup={planSetup}
              type={type}
              lsnPlanId={props.lsnPlanId}
              chapId={chapId}
              topId={topId}
              subTopId={subTopId}
              subId={props.subId}
              subSubId={props.subSubId}
              quiz={props.quiz}
              toggleCreate={toggleCreate}
              qst={qst}
              ops={ops}
              noTime={props.noTime}
              isEdit={isEdit}
            />
          )}

          {qType == "MULTIPLECHOICE" && (
            <RadioBox
              planSetup={planSetup}
              type={type}
              lsnPlanId={props.lsnPlanId}
              chapId={chapId}
              topId={topId}
              subTopId={subTopId}
              subId={props.subId}
              subSubId={props.subSubId}
              quiz={props.quiz}
              toggleCreate={toggleCreate}
              qst={qst}
              ops={ops}
              noTime={props.noTime}
              isEdit={isEdit}
            />
          )}

          {qType == "TRUEFALSE" && (
            <TrueFalseBox
              planSetup={planSetup}
              type={type}
              lsnPlanId={props.lsnPlanId}
              chapId={chapId}
              topId={topId}
              subTopId={subTopId}
              subId={props.subId}
              subSubId={props.subSubId}
              quiz={props.quiz}
              toggleCreate={toggleCreate}
              qst={qst}
              ops={ops}
              // isEdit={isEdit}
              noTime={props.noTime}
              isEdit={isEdit}
            />
          )}

          {qType == "SHORTANSWER" && (
            <ShortAnswerBox
              planSetup={planSetup}
              type={type}
              lsnPlanId={props.lsnPlanId}
              chapId={chapId}
              topId={topId}
              subTopId={subTopId}
              subId={props.subId}
              subSubId={props.subSubId}
              quiz={props.quiz}
              toggleCreate={toggleCreate}
              qst={qst}
              ops={ops}
              noTime={props.noTime}
              isEdit={isEdit}
            />
          )}
        </div>
        {/* <Typography
                        variant='body2'>
                        Create
                        a question by choosing a type
                    </Typography>
                    <br /> */}

        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 20,
          }}
        >
          <Button2
            id="basic-button"
            aria-controls={menuOpen ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? "true" : undefined}
            onClick={handleClick}
            variant="outlined"
            endIcon={<AddCircleOutlineIcon2 />}
            style={
              {
                // backgroundColor: Constants.Application.COLOR_PRIMARY
              }
            }
            sx={{
              color: "purple",
              backgroundColor: "white",
              borderColor: "purple",
              "&:hover": {
                borderColor: "purple",
                backgroundColor: "purple",
                color: "white",
              },
            }}
          >
            Add Question
          </Button2>

          <Menu2
            id="basic-menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {/* <MenuItem2 onClick={handleClose}>Profile</MenuItem2>
                            <MenuItem2 onClick={handleClose}>My account</MenuItem2>
                            <MenuItem2 onClick={handleClose}>Logout</MenuItem2>  */}

            {type == "classwork" || type == "homework" ? (
              <>
                <MenuItem2
                  onClick={() => handleChange2("SHORTANSWER")}
                  value={`SHORTANSWER`}
                >
                  SHORT ANSWER
                </MenuItem2>
              </>
            ) : (
              <></>
            )}

            {type == "assm" ? (
              <>
                <MenuItem2
                  onClick={() => handleChange2("TRUEFALSE")}
                  value={`TRUEFALSE`}
                >
                  TRUE OR FALSE
                </MenuItem2>
                <MenuItem2
                  onClick={() => handleChange2("MULTIPLECHOICE")}
                  value={`MULTIPLECHOICE`}
                >
                  MULTIPLE CHOICE
                </MenuItem2>
              </>
            ) : (
              <></>
            )}
            {/* <MenuItem2 onClick={() => handleChange2('MULTIPLESELECT')} value={`MULTIPLESELECT`}>MULTIPLE SELECT</MenuItem2> */}
          </Menu2>

          {/* 
                        <FormControl variant="filled" className={classes.formControl}
                            style={{
                                width: '100%'
                            }}>
                            <InputLabel id="demo-simple-select-filled-label">Question Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={qType}
                                onChange={handleChange}
                            >
                                
                                <MenuItem value={`MULTIPLECHOICE`}>MULTIPLE CHOICE</MenuItem>
                                <MenuItem value={`MULTIPLESELECT`}>MULTIPLE SELECT</MenuItem>
                                <MenuItem value={`TRUEFALSE`}>TRUE OR FALSE</MenuItem>
                                <MenuItem value={`SHORTANSWER`}>SHORT ANSWER</MenuItem>
                            </Select>
                        </FormControl> */}
        </div>
      </>
      {/* } */}
    </div>
  );

  const quizContent = (
    <AlignItemsList
      quiz={props.quiz}
      onDeleteQuiz={(id) => props.onDeleteQuiz(id)}
      onOpenQuiz={(quizId, qstId, qst, qTypeId, ops) => {
        console.log("QuizPlannerDrawer.js QuizBox onOpenQuiz called");
        console.log("QuizPlannerDrawer.js QuizBox onOpenQuiz quizId " + quizId);
        console.log("QuizPlannerDrawer.js QuizBox onOpenQuiz qstId " + qstId);
        console.log(
          "QuizPlannerDrawer.js QuizBox onOpenQuiz qTypeId " + qTypeId
        );
        console.log(
          "QuizPlannerDrawer.js QuizBox onOpenQuiz qst " + JSON.stringify(qst)
        );
        setQuizId(quizId);
        setQstId(qstId);
        handleChangeQTypeId(qTypeId);
        // setQuiz(quiz);
        setQst(qst);
        setOps(ops);
        setIsEdit(true);

        // toggleCreate() // reset
      }}
    />
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: 10,
          marginTop: 10,
        }}
      >
        <Typography variant="h3">{title}</Typography>

        <Typography variant="caption">
          Create multiple questions for quiz in class
        </Typography>
      </div>
      {/* 
            <br /><br /> */}

      {
        // !create &&
        quizContent
      }

      {quizQuestions}
    </>
  );
};

// #### THIS WILL WORK PERFECT AFTER RESPONSE IS FIXED
const QuizPlannerDrawer = (props) => {
  const { name, planSetup, type, chapId } = props;
  const [quiz, setQuiz] = useState(null);
  let noTime = false;
  if (props.noTime) noTime = props.noTime;

  const planId = planSetup.lessonPlanSetup.lesson_plan_setup_id;
  const subTopId = planSetup.lessonPlanSetup.sub_topic_id;
  const topId = planSetup.lessonPlanSetup.topic_id;

  console.log("QuizPlannerDrawer planSetup prop " + JSON.stringify(planSetup));

  const onUpdateList = () => {
    getUnpubQuiz(planId, type);
  };

  const onDeleteQuiz = async (id) => {
    await LessonPlanAPI.delQuiz(id);
    // update
    getUnpubQuiz(planId, type);
  };

  const getUnpubQuiz = async () => {
    const resp = await LessonPlanAPI.getUnpubQuizType(planId, type);

    console.log(
      `QuizPlannerDrawer.js getUnpubQuiz: unpub quiz response ${JSON.stringify(
        resp
      )}`
    );
    setQuiz(resp.data);
  };

  useEffect(() => {
    getUnpubQuiz(planId, type);
    return <></>;
  }, []);

  return (
    <QuizBox
      name={name}
      planSetup={planSetup}
      type={type}
      lsnPlanId={planId}
      subId={props.subId}
      chapId={chapId}
      topId={topId}
      subTopId={subTopId}
      subSubId={props.subSubId}
      quiz={quiz}
      onDeleteQuiz={(id) => onDeleteQuiz(id)}
      onUpdateList={onUpdateList}
      // isEdit={props.isEdit}
      noTime={noTime}
    />
  );
};

export default QuizPlannerDrawer;
