import React, {
  useEffect,
  useState,
  useRef,
  useCount,
  useCallback,
} from "react";
import { Box, Container } from "@material-ui/core";
import JoditEditor from "jodit-react";

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import IconButton2 from "@mui/material/IconButton";
import DoneIcon2 from "@mui/icons-material/Done";
import Drawer2 from "@mui/material/Drawer";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Card2 from "@mui/material/Card";
import CardActions2 from "@mui/material/CardActions";
import CardContent2 from "@mui/material/CardContent";
import Typography2 from "@mui/material/Typography";
import CardActionArea2 from "@mui/material/CardActionArea";
import Alert2 from "@mui/material/Alert";
import ListItem2 from "@mui/material/ListItem";
import ListItemButton2 from "@mui/material/ListItemButton";
import ListItemIcon2 from "@mui/material/ListItemIcon";
import ListItemText2 from "@mui/material/ListItemText";
import Checkbox2 from "@mui/material/Checkbox";
import ExpandLess2 from "@mui/icons-material/ExpandLess";
import Collapse2 from "@mui/material/Collapse";
import ExpandMore2 from "@mui/icons-material/ExpandMore";
import List2 from "@mui/material/List";
import Button2 from "@mui/material/Button";
import Divider2 from "@mui/material/Divider";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import QuizPlannerDrawer from "./QuizPlannerDrawer";
import MediaPlannerDrawer from "./MediaPlannerDrawer";
import ClearIcon from "@mui/icons-material/Clear";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip2 from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

import Select2 from "@mui/material/Select";
import MenuItem2 from "@mui/material/MenuItem";
import InputLabel2 from "@mui/material/InputLabel";
import NativeSelect2 from "@mui/material/NativeSelect";
import FormControl2 from "@mui/material/FormControl";

import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Constants from "../../../resource/Constants";

import AddCommentIcon2 from "@mui/icons-material/AddComment";
import AccessTimeIcon2 from "@mui/icons-material/AccessTime";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/dist/ProfilePicture.css";
import Modal2 from "@mui/material/Modal";
import Box2 from "@mui/material/Box";

import Cropper from "react-easy-crop";
import getCroppedImg from "../../../resource/cropImage";
import LessonPlanSetupAPI from "../../../http/lessonplansetup";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";

import "react-image-crop/dist/ReactCrop.css";
import Snackbar2 from "@mui/material/Snackbar";
import { red } from "@material-ui/core/colors";
import GPTGenerator from "./GPTGenerator";

const BANNER_WIDTH = 800;
const BANNER_HEIGHT = 400;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: BANNER_WIDTH,
  height: BANNER_HEIGHT,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const config = {
  apiKey: Constants.Application.FIREBASE.API_KEY,
  authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
  //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
  storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET,
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}

const inputStyles = (theme) => ({
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "white !important",
  },
});

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  //centerCrop(
  return makeAspectCrop(
    {
      unit: "%",
      width: 90,
    },
    aspect,
    mediaWidth,
    mediaHeight
  );
  //,
  //mediaWidth,
  //mediaHeight,
  //)
}

const useStyles2 = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiSelect-outlined": {
      borderColor: "whitesmoke",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "whitesmoke",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&:hover .MuiOutlinedInput-root .MuiSelect-outlined": {
      borderColor: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .MuiOutlinedInput-input": {
      color: "whitesmoke",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiInputLabel-outlined": {
      color: "whitesmoke",
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "white",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "white",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
      // backgroundColor: 'white'
    },
  },
});

const CIRCLEWTEXTANDCOLOR = (props) => {
  const { text, color } = props;

  return (
    <div
      style={{
        borderRadius: 50,
        background: color,
        width: 25,
        height: 25,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "bold",
      }}
    >
      {text}
    </div>
  );
};

function PickImageDialog3(props) {
  const { planSetup, type } = props;
  const [open, setOpen] = React.useState(false);
  const [picSrc, setPicSrc] = useState(
    "https://www.nicepng.com/png/detail/129-1298055_file-upload-comments-icons-for-file-upload.png"
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setPicSource = (src) => {
    setPicSrc(src);

    // move up as we need to update data to master object
    props.onUpdatePicSource(src);
  };

  let type3 = null;
  let newPicSrc = picSrc;

  if (type == "classwork")
    newPicSrc = planSetup.application.classwork.answerSheetUrl;
  else if (type == "homework")
    newPicSrc = planSetup.application.homework.answerSheetUrl;

  // replace images/ with encodedURL
  newPicSrc = newPicSrc.replace("images/", "images%2F");

  if (newPicSrc) {
    if (
      newPicSrc.includes("jpg") ||
      newPicSrc.includes("jpeg") ||
      newPicSrc.includes("png")
    )
      type3 = "image";
    else if (newPicSrc.includes("pdf") || newPicSrc.includes("doc"))
      type3 = "doc";
  }

  // base on type
  if (type3) {
    switch (type3) {
      case "image":
        newPicSrc = newPicSrc;
        break;
      case "doc":
        newPicSrc =
          "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png";
    }
  }

  console.log("CurriculumSetup.js PickImageDialog3 setPicSource type " + type3);
  console.log(
    "CurriculumSetup.js PickImageDialog3 setPicSource newPicSrc " + newPicSrc
  );

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}> */}
      <div
        style={{
          border: "1px #DCDCDC solid",
          borderRadius: 10,
          width: 100,
          height: 140,
          background: `url('')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        onClick={handleClickOpen}
      >
        <img src={newPicSrc} width={100} height={140} />
      </div>
      {/* </Button> */}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose the document by clicking upload (PDF / image)
          </DialogContentText>

          <UploadBox2
            setPicSource={(src) => setPicSource(src)}
            handleClose={handleClose}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button onClick={handleClose}>UPLOAD</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const STEPASSET2 = (props) => {
  const { asset } = props;
  return (
    <div
      sx={{
        backgroundColor: "#DCDCDC",
      }}
      style={{
        background: "#DCDCDC",
        verticalAlign: "middle",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        padding: 2,
        borderRadius: 5,
        margin: 0,
      }}
      onClick={() => props.onClicked()}
    >
      {asset}
    </div>
  );
};

// function CropBox({ src }) {
//     const [crop, setCrop] = useState<Crop>()
//     return (
//         <ReactCrop crop={crop} onChange={c => setCrop(c)}>
//             <img src={src} />
//         </ReactCrop>
//     )
// }

const UploadBox2 = (props) => {
  const profilePictureRef = React.createRef();
  const [open, setOpen] = React.useState(false);
  const [downloadUrls, setDownloadUrls] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [ImageDownloadUrls, setImageDownloadUrls] = useState("");

  const myEmptyIterable = {
    [Symbol.iterator]() {
      return [][Symbol.iterator]();
    },
  };

  let cropIter = Array.from(myEmptyIterable);
  let complCrop = Array.from(myEmptyIterable);

  const [imgSrc2, setImgSrc2] = useState("");
  // const previewCanvasRef = useRef < HTMLCanvasElement > (null)
  const previewCanvasRef = useRef(null);
  // const imgRef = useRef < HTMLImageElement > (null)
  const imgRef = useRef(null);

  // const [crop, setCrop] = useState(myEmptyIterable)
  // const [completedCrop, setCompletedCrop] = useState < PixelCrop > (myEmptyIterable)
  // const [aspect, setAspect] = useState < number | undefined > (16 / 9)
  const [name, setName] = useState("");
  const [type, setType] = useState("");

  const [progress, setProgress] = useState(0);
  const { planSetup } = props;

  const handleUpload = () => {
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();

    //add here the upload logic...
  };

  // Create new plugin instance
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleUploadStart = () => {
    setIsUploading(true);
    setUploadProgress(0);
  };

  const handleProgress = (progress) => {
    setProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
  };

  const handleUploadSingleSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL();

    console.log("download url", downloadURL);

    if (downloadURL) {
      setProgress(0);
    }
    // setFileNames(filename)
    setImageDownloadUrls(downloadURL);
    props.setPicSource(downloadURL);
    setImgSrc2(downloadURL);
    // close origin dialog
    props.handleClose();

    // setUploadProgress(100)
    // setIsUploading(false)
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let imgSrc =
    "https://www.nicepng.com/png/detail/129-1298055_file-upload-comments-icons-for-file-upload.png";

  if (ImageDownloadUrls) {
    imgSrc = ImageDownloadUrls;
  }

  const setPicSource = (src) => {
    setImageDownloadUrls(src);
  };

  let type4 = null;
  let newPicSrc = imgSrc;
  if (newPicSrc) {
    if (
      newPicSrc.includes("jpg") ||
      newPicSrc.includes("jpeg") ||
      newPicSrc.includes("png")
    )
      type4 = "image";
    else if (newPicSrc.includes("pdf") || newPicSrc.includes("doc"))
      type4 = "doc";
  }

  // base on type
  if (type4) {
    switch (type4) {
      case "image":
        newPicSrc = imgSrc;
        break;
      case "doc":
        newPicSrc =
          "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png";
    }
  }

  console.log("CurriculumSetup.js PickImageDialog2 setPicSource type " + type4);
  console.log(
    "CurriculumSetup.js PickImageDialog2 setPicSource newPicSrc " + newPicSrc
  );

  return (
    <>
      <div
        style={
          {
            // height: 200
          }
        }
      >
        {progress ? (
          <CircularProgress />
        ) : (
          <>
            {/* <img
                            src={newPicSrc}
                            style={{
                                // width: '100%',
                                height: 400
                            }} /> */}

            {/* show the cropping tool */}

            {/*                         
                        {(imgSrc2) && (
                            <ReactCrop
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={aspect}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imgSrc2}
                                    style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        )}
                        <div>
                            {(completedCrop) && (
                                <canvas
                                    ref={previewCanvasRef}
                                    style={{
                                        border: '1px solid black',
                                        objectFit: 'contain',
                                        width: completedCrop.width,
                                        height: completedCrop.height,
                                    }}
                                />
                            )}
                        </div> */}
          </>
        )}

        <div
          style={{
            alignSelf: "right",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              // marginLeft: 15,
              // marginRight: 15,
            }}
          >
            <label
              style={{
                fontFamily: "Poppins, Helvetica, sans-serif",
                backgroundColor: "lightsteelblue",
                color: "black",
                paddingLeft: 5,
                paddingRight: 5,
                borderRadius: 4,
                cursor: "pointer",
                // width: '100%',
                textAlign: "center",
                alignSelf: "right",
                float: "right",
              }}
            >
              {/* <Typography style={{ width: 250, padding: 5 }}> */}
              CHOOSE
              {/* </Typography> */}
              <FileUploader
                hidden
                // accept="image/*"
                storageRef={firebase.storage().ref("images")}
                onUploadStart={handleUploadStart}
                onUploadError={handleUploadError}
                onUploadSuccess={handleUploadSingleSuccess}
                onProgress={handleProgress}
              />
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

function PickImageDialog2(props) {
  const { planSetup, type } = props;
  const [open, setOpen] = React.useState(false);
  const [picSrc, setPicSrc] = useState(
    "https://www.nicepng.com/png/detail/129-1298055_file-upload-comments-icons-for-file-upload.png"
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setPicSource = (src) => {
    setPicSrc(src);
  };

  const onUpdatePicSource = (src) => {
    setPicSrc(src);

    // move up as we need to update data to master object
    props.onUpdatePicSource(src);
  };

  let type3 = null;
  let newPicSrc = picSrc;

  if (type == "classwork")
    newPicSrc = planSetup.application.classwork.questionPaperUrl;
  else if (type == "homework")
    newPicSrc = planSetup.application.homework.questionPaperUrl;
  else if (type == "workbook")
    newPicSrc = planSetup.application.classwork.workBookURL;

  if (newPicSrc) {
    if (
      newPicSrc.includes("jpg") ||
      newPicSrc.includes("jpeg") ||
      newPicSrc.includes("png")
    )
      type3 = "image";
    else if (newPicSrc.includes("pdf") || newPicSrc.includes("doc"))
      type3 = "doc";
  }

  // base on type
  if (type3) {
    switch (type3) {
      case "image":
        // newPicSrc = picSrc;
        break;
      case "doc":
        newPicSrc =
          "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png";
    }
  }

  console.log("ApplicationList.js PickImageDialog2 setPicSource type " + type);
  console.log(
    "ApplicationList.js PickImageDialog2 setPicSource newPicSrc " + newPicSrc
  );

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}> */}
      <div
        style={{
          border: "1px #DCDCDC solid",
          borderRadius: 10,
          width: 100,
          height: 140,
          background: `url('')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        onClick={handleClickOpen}
      >
        <img src={newPicSrc} width={100} height={140} />
      </div>
      {/* </Button> */}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose the document by clicking upload (PDF / image)
          </DialogContentText>

          <UploadBox2
            planSetup={planSetup}
            handleClose={handleClose}
            setPicSource={(src) => onUpdatePicSource(src)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button onClick={handleClose}>UPLOAD</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

function PickImageDialog4(props) {
  const { planSetup, index, type } = props;
  const [open, setOpen] = React.useState(false);
  const [picSrc, setPicSrc] = useState(
    "https://www.nicepng.com/png/detail/129-1298055_file-upload-comments-icons-for-file-upload.png"
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setPicSource = (src) => {
    setPicSrc(src);

    // move up as we need to update data to master object
    props.onUpdatePicSource(src);
  };

  let type3 = null;
  let newPicSrc = picSrc;

  if (type == "classwork")
    newPicSrc = planSetup.application.classwork.questionTips[index].assetUrl;
  else if (type == "homework")
    newPicSrc = planSetup.application.homework.questionTips[index].assetUrl;

  if (newPicSrc) {
    if (
      newPicSrc.includes("jpg") ||
      newPicSrc.includes("jpeg") ||
      newPicSrc.includes("png")
    )
      type3 = "image";
    else if (newPicSrc.includes("pdf") || newPicSrc.includes("doc"))
      type3 = "doc";
  }

  // base on type
  if (type3) {
    switch (type3) {
      case "image":
        // newPicSrc = picSrc;
        break;
      case "doc":
        newPicSrc =
          "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png";
    }
  }

  console.log("CurriculumSetup.js PickImageDialog2 setPicSource type " + type);
  console.log(
    "CurriculumSetup.js PickImageDialog2 setPicSource newPicSrc " + newPicSrc
  );

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}> */}
      <div
        style={{
          border: "1px #DCDCDC solid",
          borderRadius: 10,
          width: 40,
          height: 55,
          background: `url('')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        onClick={handleClickOpen}
      >
        <img src={newPicSrc} width={40} height={55} />
      </div>
      {/* </Button> */}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose the document by clicking upload (PDF / image)
          </DialogContentText>

          <UploadBox2
            setPicSource={(src) => setPicSource(src)}
            handleClose={handleClose}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button onClick={handleClose}>UPLOAD</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const WIDTHBOX = (props) => {
  const { value } = props;
  return (
    <div
      style={{
        width: value,
      }}
    ></div>
  );
};

const MARGINBOX = (props) => {
  const { value } = props;
  return (
    <div
      style={{
        margin: value,
      }}
    ></div>
  );
};

const ADDASSETWIDGET = (props) => {
  const { noTip, noImage, noVideo, noDoc, planSetup } = props;

  {
    /* ICONS */
  }
  return (
    <div
      style={{
        // width: 250,
        marginTop: 10,
        display: "flex",
        flexDirection: "row",
        // textAlign: 'right'
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      {!noTip && (
        <>
          <Chip2 label="Add Teacher Tip" />
          <div style={{ marginRight: 10 }}></div>
        </>
      )}

      {!noImage && (
        <>
          <STEPASSET
            onClicked={() => props.onAddHomeworkImage()}
            planSetup={planSetup}
            asset={
              <Tooltip title="Add Image" placement="top">
                <AddPhotoAlternateIcon />
              </Tooltip>
            }
          />

          <div style={{ marginRight: 10 }}></div>
        </>
      )}

      {!noVideo && (
        <>
          <Tooltip title="Add Video" placement="top">
            <STEPASSET asset={<VideoCallIcon />} />
          </Tooltip>
          <div style={{ marginRight: 10 }}></div>
        </>
      )}

      {!noDoc && (
        <>
          <Tooltip title="Add Document" placement="top">
            <STEPASSET asset={<NoteAddIcon />} />
          </Tooltip>
          <div style={{ marginRight: 10 }}></div>
        </>
      )}
      {/* <Tooltip title="Delete item" placement="top">
              <DeleteForeverIcon color="red" />
            </Tooltip> */}
    </div>
  );
};

function StepField4(props) {
  const { num } = props;
  const [showIt, setShowIt] = useState(false);

  const toggleShowIt = () => {
    let newShowIt = !showIt;
    setShowIt(newShowIt);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography2 variant="h6">Step {num}</Typography2>

        <ClearIcon
          color="warning"
          style={{
            cursor: "pointer",
          }}
        />
      </div>

      {/* {showIt ? */}
      <Box
        sx={{
          // width: 500,
          maxWidth: "100%",
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <TextField
          fullWidth
          label="Enter the text"
          placeholder="Enter the text here"
          id="fullWidth"
          variant="outlined"
          multiline
          rows={1}
          maxRows={5}
        />

        {/* <ImageIcon /> */}
      </Box>
      {/* :
              <></>
            } */}

      {/* IMAGES */}

      <div
        style={{
          margin: 20,
        }}
      ></div>

      {/* <StandardImageList /> */}

      <div
        style={{
          margin: 10,
        }}
      ></div>
    </div>
  );
}

const HEADING = (props) => {
  const { value } = props;

  return (
    <>
      <Typography variant="h4">{value}</Typography>
    </>
  );
};

const HEADING2 = (props) => {
  const { value, subValue } = props;

  return (
    <>
      <div
        className="d-flex flex-row justify-content-start align-items-center"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">{value}</Typography>

        <div
          style={{
            marginRight: 15,
          }}
        ></div>

        <Typography variant="body2">{subValue}</Typography>
      </div>
    </>
  );
};

const SUBHEADING = (props) => {
  const { value } = props;

  return (
    <>
      <Typography variant="subtitle1">{value}</Typography>
    </>
  );
};

function BriefField(props) {
  const { name, planSetup } = props;
  const [content, setContent] = useState("");

  const editor = useRef(null);

  const onContentChanged = (newContent) => {
    setContent(newContent);

    handleChange2(newContent);
  };

  const handleChange2 = (value) => {
    console.log("ApplicationList.js BriefField handleChange2 name " + name);
    console.log("ApplicationList.js BriefField handleChange2 value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.application.summary = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("ApplicationList.js BriefField handleChange name " + name);
    console.log("ApplicationList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.application.summary = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  let val = planSetup.application.summary;

  return (
    <div sx={{ minWidth: "100%" }}>
      <>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SUBHEADING value={`Teacher Summary for ${name}`} />


            <GPTGenerator
              handleUpdateGPTText={(text) => handleChange2(text)}
              prompt={`one sentence summary for ${name}`}
              planSetup={planSetup}
            />
          </div>

          <Box
            sx={{
              // width: 500,
              maxWidth: "100%",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              label="Enter summary"
              value={val}
              placeholder="Enter the text here"
              id="fullWidth"
              variant="outlined"
              onChange={handleChange}
              inputProps={{
                maxLength: 500,
              }}
            />

            {/*                         
                        <JoditEditor
                            ref={editor}
                            value={val}
                            tabIndex={1}
                            onChange={onContentChanged}
                        /> */}
          </Box>
        </div>
      </>
    </div>
  );
}

function HeadingField(props) {
  const { name, zIndex } = props;

  return (
    <Card2 sx={{ minWidth: "100%" }} style={{}}>
      <CardContent2>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SUBHEADING value={`Description for ${name}`} />

            {/* <ClearIcon color="warning"
                style={{
                  cursor: 'pointer'
                }} /> */}
          </div>

          <Box
            sx={{
              // width: 500,
              maxWidth: "100%",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              label="Enter description"
              placeholder="Enter the text here"
              id="fullWidth"
              multiline
              rows={4}
              variant="outlined"
            />

            {/* <ImageIcon /> */}
          </Box>
        </div>
      </CardContent2>
    </Card2>
  );
}

function BANNERMODAL(props) {
  const { planData, planSetup, type, item } = props;
  const [open, setOpen] = React.useState(props.open);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = React.useState(false);

  const onCropCompleted = (file) => {
    // upload to firebase and set as banner
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose2 = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {

    setOpen2(false);

    if (reason === "clickaway") {
      return;
    }

  };

  console.log("ApplicationList.js BANNERMODAL open from state " + open);
  console.log("ApplicationList.js BANNERMODAL type " + type);
  console.log("ApplicationList.js BANNERMODAL item " + item);

  return (
    <div>
      {/* <Button2 onClick={handleOpen}>Open modal</Button2> */}
      <Modal2
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box2 sx={modalStyle}>
          {/* <Typography2 id="modal-modal-title" variant="h6" component="h2">
              Text in a modal
            </Typography2>
            <Typography2 id="modal-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography2> */}

          <BANNERBOX2
            type2={type}
            item2={item}
            planSetup={planSetup}
            planData={planData}
            onCropCompleted={(file) => onCropCompleted(file)}
            onCompletedUpload={(firebaseUrl) => {
              console.log(
                "ApplicationList.js BANNERMODAL onCompletedUpload: firebaseUrl " +
                firebaseUrl
              );
              console.log(
                "ApplicationList.js BANNERMODAL onCompletedUpload: type " + type
              );
              console.log(
                "ApplicationList.js BANNERMODAL onCompletedUpload: item " + item
              );
              let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
              let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

              if (type === "classwork") {
                if (item === "workbook") {
                  newPlanSetup.application.classwork.workBookURL = firebaseUrl;
                } else if (item === "questionpaper") {
                  newPlanSetup.application.classwork.questionPaperUrl =
                    firebaseUrl;
                } else if (item === "answersheet") {
                  newPlanSetup.application.classwork.answerSheetUrl =
                    firebaseUrl;
                }
              } else if (type === "homework") {
                if (item === "questionpaper") {
                  newPlanSetup.application.homework.questionPaperUrl =
                    firebaseUrl;
                } else if (item === "answersheet") {
                  newPlanSetup.application.homework.answerSheetUrl =
                    firebaseUrl;
                }
              }

              // newPlanSetup.application.classwork.workBookURL = firebaseUrl;
              // close modal
              handleClose();
              // update state
              setOpen2(true);

              // update main object
              props.handleUpdatePlanSetup(newPlanSetup);

              props.onCompletedUpload();
            }}
            handleUpdatePlanSetup={(newPlanSetup) =>
              props.handleUpdatePlanSetup(newPlanSetup)
            }
          />
        </Box2>
      </Modal2>

      <Snackbar2
        open={open2}
        autoHideDuration={6000}
        onClose={handleClose2}
        message="Image processing complete"
      // action={action}
      />
    </div>
  );
}

const BANNERBOX2 = (props) => {
  const { planData, planSetup } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [percent, setPercent] = useState(0);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const { type2, item2 } = props;

  let image = "";
  if (type2 === "classwork") {
    if (item2 === "workbook") {
      image = planData.application.classwork.workBookURL;
    } else if (item2 === "questionpaper") {
      image = planData.application.classwork.questionPaperUrl;
    } else if (item2 === "answersheet") {
      image = planData.application.classwork.answerSheetUrl;
    }
  } else if (type2 === "homework") {
    if (item2 === "questionpaper") {
      image = planData.application.homework.questionPaperUrl;
    } else if (item2 === "answersheet") {
      image = planData.application.homework.answerSheetUrl;
    }
  }

  console.log("ApplicationList.js BANNERBOX2 type2 " + type2);
  console.log("ApplicationList.js BANNERBOX2 item2 " + item2);
  console.log("ApplicationList.js BANNERBOX2 image " + image);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(
      "CurriculumSetup.js BANNERBOX2 onCropComplete: crop completed " +
      croppedArea,
      croppedAreaPixels
    );

    setCroppedAreaPixels(croppedAreaPixels);

    // wait for state to be set
    // setTimeout(function() {
    showCroppedImage(croppedAreaPixels);
    // }, 2000)
  }, []);

  async function srcToFile(src, fileName, mimeType) {
    return await fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        console.log("ApplicationList.js BANNERBOX2 srcToFile buf " + buf);

        const imageFile = new File([buf], fileName, { type: mimeType });
        handleUpload(imageFile);
      });
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleUpload = (imageFile) => {
    if (!imageFile) {
      alert("Please upload an image first!");
    }

    const storageRef = firebase.storage().ref(`/files/${imageFile.name}`); //.child(imageFile.name);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    // const uploadTask = uploadBytesResumable(storageRef, file);
    const uploadTask = storageRef.put(imageFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => {
        console.log("ApplicationList.js BANNERBOX2 handleUpload: error " + err);
      },
      () => {
        // download url
        storageRef
          .getDownloadURL() //uploadTask.snapshot.ref).then((url) => {
          .then(async (firebaseUrl) => {
            console.log(
              "ApplicationList.js BANNERBOX2 handleUpload: firebaseUrl " +
              firebaseUrl
            );
            //                        console.log('ApplicationList.js BANNERBOX2 handleUpload: planSetup application classwork ' + JSON.stringify(planSetup.application.classwork.workBookURL));

            // update the plan image with the URL
            //                        const resp3 = await LessonPlanSetupAPI.postUpdatePlanSetup(planData.lessonPlanSetup);

            // setOpen(true)
            // trigger cropping modal

            // update the plan
            props.onCompletedUpload(firebaseUrl);
            // props.handleUpdatePlanSetup(newPlanSetup)
          })
          .catch((e) => {
            console.log(
              "ApplicationList.js BANNERBOX2 handleUpload: exception " + e
            );
          });
      }
    );
  };

  const showCroppedImage = useCallback(
    async (croppedAreaPixels2) => {
      try {
        // this is the file
        const imgUrl = image;
        const croppedImage = await getCroppedImg(
          imgUrl,
          croppedAreaPixels2,
          rotation
        );
        console.log(
          "ApplicationList.js showCroppedImage: donee croppedImage: ",
          { croppedImage }
        );
        setCroppedImage(croppedImage);

        let name = makeid(12);
        let type = "";
        let ext = "";

        if (imgUrl.includes(".jpg") || imgUrl.includes(".jpeg")) {
          type = "image/jpeg";
          ext = "jpg";
        } else if (imgUrl.includes("png")) {
          type = "image/png";
          ext = "png";
        }

        if (type) {
          name = `${name}.${ext}`;
        }

        setName(name);
        setType(type);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, rotation]
  );

  const onOkClicked = () => {
    srcToFile(croppedImage, name, type);
  };

  return (
    <>
      <Cropper
        image={image}
        crop={crop}
        zoom={zoom}
        aspect={16 / 9}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />
      <div
        style={{
          position: "absolute",
          bottom: 10,
          right: 10,
          zIndex: 150,
        }}
      >
        <Button2
          mode="contained"
          onClick={() => onOkClicked(croppedImage, name, type)}
        >
          UPLOAD
        </Button2>
      </div>
    </>
  );
};

const WorkbookItem2 = (props) => {
  const { num, desc, planSetup } = props;
  const [imgSrc, setImgSrc] = useState("");

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [percent, setPercent] = useState(0);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const onContentChanged = (newContent) => {
    setContent(newContent);

    console.log("ApplicationList.js Workbook Text handleChange name " + name);
    console.log(
      "ApplicationList.js Workbook Text handleChange value " + newContent
    );
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.application.classwork.description = newContent;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const onUpdatePicSource = (downloadURL) => {
    // make a copy
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    let newPicSrc = downloadURL;
    let type = "image";

    if (
      newPicSrc.includes("jpg") ||
      newPicSrc.includes("jpeg") ||
      newPicSrc.includes("png")
    ) {
      type = "image";
      newPlanSetup.application.classwork.hasImage = true;
      newPlanSetup.application.classwork.hasWorkBook = true;
      newPlanSetup.application.classwork.hasDoc = false;
      newPlanSetup.application.classwork.imageURL = downloadURL;
    } else if (newPicSrc.includes("pdf") || newPicSrc.includes("doc")) {
      type = "doc";
      newPlanSetup.application.classwork.hasImage = false;
      newPlanSetup.application.classwork.hasWorkBook = true;
      newPlanSetup.application.classwork.hasDoc = true;
      newPlanSetup.application.classwork.documentURL = downloadURL;
    }

    // common
    newPlanSetup.application.classwork.workBookURL = downloadURL;

    // update to the master object
    props.handleUpdatePlanSetup(newPlanSetup);

    // open crop modal if it's an image
    if (type == "image") setModalOpen(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("ApplicationList.js Workbook Text handleChange name " + name);
    console.log("ApplicationList.js Workbook Text handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.application.classwork.description = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(
      "CurriculumSetup.js BANNERBOX2 onCropComplete: crop completed " +
      croppedArea,
      croppedAreaPixels
    );

    setCroppedAreaPixels(croppedAreaPixels);

    // wait for state to be set
    // setTimeout(function() {
    showCroppedImage(croppedAreaPixels);
    // }, 2000)
  }, []);

  async function srcToFile(src, fileName, mimeType) {
    return await fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        console.log("CurriculumSetup.js BANNERBOX srcToFile buf " + buf);

        const imageFile = new File([buf], fileName, { type: mimeType });
        handleUpload(imageFile);
      });
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleUpload = (imageFile) => {
    if (!imageFile) {
      alert("Please upload an image first!");
    }

    const storageRef = firebase.storage().ref(`/files/${imageFile.name}`); //.child(imageFile.name);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    // const uploadTask = uploadBytesResumable(storageRef, file);
    const uploadTask = storageRef.put(imageFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => {
        console.log("ApplicationList.js BANNERBOX2 handleUpload: error " + err);
      },
      () => {
        // download url
        storageRef
          .getDownloadURL() //uploadTask.snapshot.ref).then((url) => {
          .then(async (firebaseUrl) => {
            console.log(
              "ApplicationList.js BANNERBOX2 handleUpload: firebaseUrl " +
              firebaseUrl
            );

            // update the plan image with the URL

            //                        props.planSetup.lessonPlanSetup.cover_image_url = firebaseUrl;
            //                        const resp3 = await LessonPlanSetupAPI.postUpdatePlanSetup(props.planSetup.lessonPlanSetup);

            // setOpen(true)
            // trigger cropping modal

            // update the plan
            props.onCompletedUpload(firebaseUrl);
          })
          .catch((e) => {
            console.log(
              "ApplicationList.js BANNERBOX2 handleUpload: exception " + e
            );
          });
      }
    );
  };

  const onOkClicked = () => {
    srcToFile(croppedImage, name, type);
  };

  const showCroppedImage = useCallback(
    async (croppedAreaPixels2) => {
      try {
        // this is the file
        const imgUrl = props.planSetup.lessonPlanSetup.cover_image_url;
        const croppedImage = await getCroppedImg(
          imgUrl,
          croppedAreaPixels2,
          rotation
        );
        console.log(
          "CurriculumSetup.js showCroppedImage: donee croppedImage: ",
          { croppedImage }
        );
        setCroppedImage(croppedImage);

        let name = makeid(12);
        let type = "";
        let ext = "";

        if (imgUrl.includes(".jpg") || imgUrl.includes(".jpeg")) {
          type = "image/jpeg";
          ext = "jpg";
        } else if (imgUrl.includes("png")) {
          type = "image/png";
          ext = "png";
        }

        if (type) {
          name = `${name}.${ext}`;
        }

        setName(name);
        setType(type);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, rotation]
  );

  let val = planSetup.application.classwork.description;

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%'
            }}>
            <SUBHEADING value={desc} />
            <GPTGenerator
              handleUpdateGPTText={(text) => onContentChanged(text)}
              prompt={`workbook summary for classwork`}
              planSetup={planSetup}
            />
          </div>
          {/* 
                    <ClearIcon color="warning"
                        style={{
                            cursor: 'pointer'
                        }} /> */}
        </div>

        <Box
          sx={{
            // width: 500,
            maxWidth: "100%",
          }}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {/* <TextField fullWidth label="Enter the text"
                        value={val}
                        placeholder="Enter the text here" id="fullWidth"
                        variant="outlined"
                        rows={6}
                        multiline
                        sx={{
                            width: '65%'
                        }}
                        style={{
                            width: '65%',
                        
                        }}
                        planSetup={planSetup}
                        onChange={handleChange}
                    /> */}

          <JoditEditor
            ref={editor}
            value={val}
            tabIndex={1}
            onChange={onContentChanged}
          />
        </Box>

        <div
          sx={{
            width: "34%",
            display: "flex",
            justifyContent: "flex-end",
          }}
          style={{
            width: "34%",
            border: "0px #DCDCDC solid",
            padding: 10,
            // height: 200
          }}
        >
          {/* <AddPhotoAlternateIcon
                  size={128}
                  fontSize="large"
                /> */}

          <PickImageDialog2
            onUpdatePicSource={(src) => onUpdatePicSource(src)}
            type="workbook"
            planSetup={planSetup}
          />
          <Typography2 variant="body2">Click to upload</Typography2>

          <div style={{ marginBottom: 10 }}></div>

          {modalOpen ? (
            <>
              <BANNERMODAL
                planData={planSetup}
                planSetup={planSetup}
                open={modalOpen}
                onModalClose={() => setModalOpen(false)}
                onCompletedUpload={(src) => {
                  // onCompletedUpload(src) // pass this to the modal banner
                  console.log("WorkbookItem2 onCompletedUpload: called..");

                  // plansetup was already updated inside bannermodal
                  // this is main banner image
                  // let sPlanSetup = JSON.parse(JSON.stringify(planSetup));

                  setModalOpen(false);

                  // props.handleUpdatePlanSetup(sPlanSetup);
                }}
                handleUpdatePlanSetup={(newPlanSetup) =>
                  props.handleUpdatePlanSetup(newPlanSetup)
                }
                type="classwork"
                item="workbook"
              />
            </>
          ) : (
            <></>
          )}
        </div>
        <MARGINBOX value={10} />

        {/* add assets widget */}
        {/* <div
                    style={{
                        float: 'right',
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                    }}>
                    <ADDASSETWIDGET />
                </div> */}
      </div>
    </>
  );
};

function NativeSelectDemo(props) {
  const { planSetup, index } = props;
  const [type, setType] = React.useState("Select");

  const handleChange = (event) => {
    console.log(
      "ApplicationList.js NativeSelectDemo handleChange: name " +
      event.target.name
    );
    console.log(
      "ApplicationList.js NativeSelectDemo handleChange: value " +
      event.target.value
    );
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    setType(event.target.value);

    newPlanSetup.application.classwork.questionTips[index].type =
      event.target.value;
    props.handleUpdatePlanSetup(newPlanSetup);
  };

  let newType = type;
  newType = planSetup.application.classwork.questionTips[index].type;

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl2 fullWidth variant="standard">
        <InputLabel2 variant="standard" htmlFor="uncontrolled-native">
          Type
        </InputLabel2>
        <Select2
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          // variant="outlined"
          // defaultValue={''}
          label="Type"
          value={newType}
          // inputProps={{
          //     name: 'type',
          //     id: 'uncontrolled-native',
          // }}

          // sx={{
          //     border: '0px #DCDCDC solid'
          // }}
          // style={{
          //     border: '0px #DCDCDC solid'
          // }}
          onChange={handleChange}
        >
          {/* <option value="">Select</option>
                    <option value={'Instruct'}>Instruct</option>
                    <option value={'Ask'}>Ask</option> */}
          <MenuItem2 value="Select">
            <em>Select</em>
          </MenuItem2>
          <MenuItem2 value={"Instruct"}>Instruct</MenuItem2>
          <MenuItem2 value={"Ask"}>Ask</MenuItem2>
          {/* 
                    <option value={30}>Thirty</option>
                     */}
        </Select2>
      </FormControl2>
    </Box>
  );
}

function NativeSelectDemo2(props) {
  const { planSetup, index } = props;
  const [type, setType] = React.useState("Select");

  const handleChange = (event) => {
    console.log(
      "ApplicationList.js NativeSelectDemo2 handleChange: name " +
      event.target.name
    );
    console.log(
      "ApplicationList.js NativeSelectDemo2 handleChange: value " +
      event.target.value
    );
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    setType(event.target.value);

    newPlanSetup.application.homework.questionTips[index].type =
      event.target.value;
    props.handleUpdatePlanSetup(newPlanSetup);
  };

  let newType = type;
  newType = planSetup.application.homework.questionTips[index].type;
  //newType.charAt(0).toUpperCase();

  console.log("ApplicationList.js NativeSelectDemo2 newType " + newType);

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl2 fullWidth variant="standard">
        <InputLabel2 variant="standard" htmlFor="uncontrolled-native">
          Type
        </InputLabel2>
        <Select2
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          // variant="outlined"
          // defaultValue={''}
          label="Type"
          value={newType}
          // inputProps={{
          //     name: 'type',
          //     id: 'uncontrolled-native',
          // }}

          // sx={{
          //     border: '0px #DCDCDC solid'
          // }}
          // style={{
          //     border: '0px #DCDCDC solid'
          // }}
          onChange={handleChange}
        >
          {/* <option value="">Select</option>
                    <option value={'Instruct'}>Instruct</option>
                    <option value={'Ask'}>Ask</option> */}
          <MenuItem2 value="Select">
            <em>Select</em>
          </MenuItem2>
          <MenuItem2 value={"Instruct"}>Instruct</MenuItem2>
          <MenuItem2 value={"Ask"}>Ask</MenuItem2>
          {/* 
                    <option value={30}>Thirty</option>
                     */}
        </Select2>
      </FormControl2>
    </Box>
  );
}

const QSTTIP = (props) => {
  const { num, tip, index, planSetup } = props;
  const [age, setAge] = React.useState("");

  const handleChange2 = (event) => {
    setAge(event.target.value);
  };

  const handleChange = (event) => {
    console.log(
      "ApplicationList.js QSTTIP handleChange: name " + event.target.name
    );
    console.log(
      "ApplicationList.js QSTTIP handleChange: value " + event.target.value
    );
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    // update
    newPlanSetup.application.classwork.questionTips[index].description =
      event.target.value;
    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const onUpdatePicSource = (downloadURL) => {
    // make a copy
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    let newPicSrc = downloadURL;
    let type = "image";

    newPlanSetup.application.classwork.questionTips[index].assetUrl = newPicSrc;

    // update to the master object
    props.handleUpdatePlanSetup(newPlanSetup);
  };

  let text = planSetup.application.classwork.questionTips[index].description;

  return (
    <>
      <div>
        {/* <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                        <SUBHEADING
                            value={desc}
                        />
                        <WIDTHBOX value={10} />

                    </div>

                    <ClearIcon color="warning"
                        style={{
                            cursor: 'pointer'
                        }} />
                </div> */}

        {/* tip */}
        {/* 
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                    <span>Question #</span>
                    <WIDTHBOX value={10} />
                    <TextField
                        variant="outlined"
                        label=""
                        placeholder=""
                        size="small"
                        type="number"
                        sx={{
                            width: 50
                        }}
                        style={{
                            width: 50
                        }}
                    />
                    <WIDTHBOX value={10} />
                    <span>Tip</span>
                </div> */}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              // width: 500,
              width: "100%",
              maxWidth: "100%",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <CIRCLEWTEXTANDCOLOR text={num} color="orange" />
            <WIDTHBOX value={10} />
            <div
              sx={{
                width: "15%",
              }}
              style={{
                width: "15%",
              }}
            >
              <NativeSelectDemo
                index={index}
                planSetup={planSetup}
                handleUpdatePlanSetup={(newPlanSetup) =>
                  props.handleUpdatePlanSetup(newPlanSetup)
                }
              />
            </div>

            <WIDTHBOX value={10} />

            <TextField
              value={text}
              index={index}
              fullWidth
              label="Enter the text"
              placeholder="Enter the text here"
              id="fullWidth"
              variant="outlined"
              sx={{
                width: "65%",
              }}
              style={{
                width: "65%",
              }}
              onChange={handleChange}
            />

            <div
              sx={{
                width: "5%",
                display: "flex",
                justifyContent: "flex-end",
              }}
              style={{
                width: "5%",
                border: "0px #DCDCDC solid",
                padding: 10,
                // height: 200
              }}
            >
              {/* <AddPhotoAlternateIcon
                  size={128}
                  fontSize="large"
                /> */}

              <PickImageDialog4
                planSetup={planSetup}
                index={index}
                type="classwork"
                onUpdatePicSource={(src) => onUpdatePicSource(src)}
              />
            </div>
          </Box>

          {/* remove icon */}
          <div>
            <div style={{}}>
              <ClearIcon
                color="red"
                style={{
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.onDelClassworkQTip(index);
                }}
              />
            </div>
          </div>
        </div>

        <MARGINBOX value={10} />
      </div>
    </>
  );
};

const QSTTIPS = (props) => {
  const { tips, planSetup } = props;

  return tips.map((tip, idx) => {
    const num = idx + 1; // to display
    return (
      <QSTTIP
        key={idx}
        tip={tip}
        idx={idx}
        index={idx}
        num={num}
        onDelClassworkQTip={(index) => props.onDelClassworkQTip(index)}
        planSetup={planSetup}
        handleUpdatePlanSetup={(newPlanSetup) =>
          props.handleUpdatePlanSetup(newPlanSetup)
        }
      />
    );
  });
};

const HOMEWORKQSTTIP = (props) => {
  const { num, tip, index, planSetup } = props;
  const [age, setAge] = React.useState("");

  const handleChange2 = (event) => {
    setAge(event.target.value);
  };

  const handleChange = (event) => {
    console.log(
      "ApplicationList.js HOMEWORKQSTTIP handleChange: name " +
      event.target.name
    );
    console.log(
      "ApplicationList.js HOMEWORKQSTTIP handleChange: value " +
      event.target.value
    );
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    // update
    newPlanSetup.application.homework.questionTips[index].description =
      event.target.value;
    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const handleChange3 = (text) => {
    // make a copy
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    // update
    newPlanSetup.application.homework.questionTips[index].description = text;
    props.handleUpdatePlanSetup(newPlanSetup);
  }

  const onUpdatePicSource = (downloadURL) => {
    // make a copy
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    let newPicSrc = downloadURL;
    let type = "image";

    newPlanSetup.application.homework.questionTips[index].assetUrl = newPicSrc;

    // update to the master object
    props.handleUpdatePlanSetup(newPlanSetup);
  };

  let text = planSetup.application.homework.questionTips[index].description;
  let type2 = planSetup.application.homework.questionTips[index].type;

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              // width: 500,
              width: "100%",
              maxWidth: "100%",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <CIRCLEWTEXTANDCOLOR text={num} color="orange" />
            <WIDTHBOX value={10} />
            <div
              sx={{
                width: "15%",
              }}
              style={{
                width: "15%",
              }}
            >
              <NativeSelectDemo2
                index={index}
                planSetup={planSetup}
                handleUpdatePlanSetup={(newPlanSetup) =>
                  props.handleUpdatePlanSetup(newPlanSetup)
                }
              />
            </div>

            <WIDTHBOX value={10} />

            <TextField
              value={text}
              index={index}
              fullWidth
              label="Enter the text"
              placeholder="Enter the text here"
              id="fullWidth"
              variant="outlined"
              sx={{
                width: "65%",
              }}
              style={{
                width: "65%",
              }}
              onChange={handleChange}
            />

            <div
              sx={{
                width: "5%",
                display: "flex",
                justifyContent: "flex-end",
              }}
              style={{
                width: "5%",
                border: "0px #DCDCDC solid",
                padding: 10,
                // height: 200
              }}
            >
              <PickImageDialog4
                planSetup={planSetup}
                index={index}
                type="homework"
                onUpdatePicSource={(src) => onUpdatePicSource(src)}
              />
            </div>
          </Box>

          {/* GPT */}

          <GPTGenerator
            handleUpdateGPTText={(text) => handleChange3(text)}
            prompt={`a single question tip for ${type2}`}
            planSetup={planSetup}
          />

          {/* remove icon */}
          <div>
            <div style={{}}>
              <ClearIcon
                color="red"
                style={{
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.onDelHomeworkQTip(index);
                }}
              />
            </div>
          </div>
        </div>

        <MARGINBOX value={10} />
      </div>
    </>
  );
};

const HOMEWORKQSTTIPSLISTINNER = (props) => {
  const { tips, planSetup } = props;

  return tips.map((tip, idx) => {
    const num = idx + 1; // to display
    return (
      <HOMEWORKQSTTIP
        key={idx}
        tip={tip}
        idx={idx}
        index={idx}
        num={num}
        onDelHomeworkQTip={(index) => props.onDelHomeworkQTip(index)}
        planSetup={planSetup}
        handleUpdatePlanSetup={(newPlanSetup) =>
          props.handleUpdatePlanSetup(newPlanSetup)
        }
      />
    );
  });
};

const HOMEWORKQSTTIPSLIST = (props) => {
  const { planSetup, num } = props;
  const { application } = planSetup;
  const { homework } = application;
  const { questionTips } = homework;

  console.log(
    "ApplicationList.js HOMEWORKQSTTIPS: planSetup application homework " +
    JSON.stringify(planSetup.application.homework)
  );
  return (
    <>
      {questionTips && questionTips.length ? (
        <HOMEWORKQSTTIPSLISTINNER
          tips={questionTips}
          planSetup={planSetup}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
          onDelHomeworkQTip={(index) => props.onDelHomeworkQTip(index)}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const CLASSWORKQSTTIPS = (props) => {
  const { planSetup, num } = props;
  const { application } = planSetup;
  const { classwork } = application;
  const { questionTips } = classwork;

  // digital questions
  let isDigital = classwork.hasAssm;

  console.log(
    "ApplicationList.js CLASSWORKQSTTIPS: planSetup application classwork " +
    JSON.stringify(planSetup.application.classwork)
  );
  return (
    <>
      {questionTips && questionTips.length ? (
        <QSTTIPS
          tips={questionTips}
          planSetup={planSetup}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
          onDelClassworkQTip={(index) => props.onDelClassworkQTip(index)}
        />
      ) : (
        <></>
      )}

      <div
        style={{
          marginTop: 10,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography2 variant="button">ADD:</Typography2>

        <div style={{ marginRight: 10 }}></div>

        <a
          style={{
            color: Constants.COLOR_PRIMARY,
          }}
          href="javascript:void(0)"
          onClick={() => props.onAddClassworkQstTip()}
        >
          <STEPASSET
            onClick={() => {
              props.onAddClassworkQstTip();
            }}
            asset={
              <Tooltip title="Add Question Tip" placement="top">
                <AddCommentIcon2 color="secondary" />
              </Tooltip>
            }
          />
        </a>
        {/* 
                <Button2
                    variant="outlined"
                    color="primary"
                    endIcon={<AddCircleOutlineIcon />}
                    style={{
                        // backgroundColor: Constants.Application.COLOR_PRIMARY
                    }}
                    sx={{ color: 'purple', backgroundColor: 'white', borderColor: 'purple' }}
                    onClick={() => { props.onAddClassworkQstTip() }} // add a new resource block
                >
                    Add Tip
                </Button2>
                 */}
      </div>
    </>
  );
};

const HOMEWORKQSTTIPS = (props) => {
  const { planSetup, num } = props;
  const { application } = planSetup;
  const { homework } = application;
  const { questionTips } = homework;

  console.log(
    "ApplicationList.js HOMEWORKQSTTIPS: planSetup application homework " +
    JSON.stringify(planSetup.application.homework)
  );
  return (
    <>
      {questionTips && questionTips.length ? (
        <HOMEWORKQSTTIPSLIST
          tips={questionTips}
          planSetup={planSetup}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
          onDelHomeworkQTip={(index) => props.onDelHomeworkQTip(index)}
        />
      ) : (
        <></>
      )}

      <div
        style={{
          marginTop: 10,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography2 variant="button">ADD:</Typography2>

        <div style={{ marginRight: 10 }}></div>

        <a
          style={{
            color: Constants.COLOR_PRIMARY,
          }}
          href="javascript:void(0)"
          onClick={() => props.onAddHomeworkQstTip()}
        >
          <STEPASSET
            onClick={() => {
              props.onAddHomeworkQstTip();
            }}
            asset={
              <Tooltip title="Add Question Tip" placement="top">
                <AddCommentIcon2 color="secondary" />
              </Tooltip>
            }
          />
        </a>

        {/* 
                <Button2
                    variant="contained"
                    endIcon={<AddCircleOutlineIcon />}
                    style={{
                        backgroundColor: Constants.Application.COLOR_PRIMARY
                    }}
                    onClick={() => { props.onAddHomeworkQstTip() }} // add a new resource block
                >
                    Add Tip
                </Button2>
                 */}
      </div>
    </>
  );
};

const CLASSWORKBOX = (props) => {
  const { num, name, planSetup } = props;

  const { application } = planSetup;
  return (
    <div sx={{ minWidth: "100%" }}>
      <>
        {application.classwork.hasWorkBook ? (
          <>
            <CLASSWORKNAMEBOX num={num} name={name} />
            {/* <CLASSWORKQBOX num={num} name="Workbook" /> */}
            <WorkbookItem2
              desc={`Upload ${name}`}
              planSetup={planSetup}
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
            />

            <MARGINBOX value={10} />

            <CLASSWORKNAMEBOX num={num} name="Teacher Differentiation Tips" />

            <CLASSWORKQBOX
              num={num}
              name="Those needing more help"
              type={"help_description"}
              planSetup={planSetup}
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
            />
            <CLASSWORKQBOX
              num={num}
              name="Extra Challenge"
              type={"extra_challenge"}
              planSetup={planSetup}
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
            />
          </>
        ) : (
          <></>
        )}

        <MARGINBOX value={7} />

        {/* enter/upload question and answers */}
        <CLASSWORKBOX3
          name={name}
          desc=""
          planSetup={planSetup}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
          topicId={props.topicId}
          chapId={props.chapId}
          unitId={props.unitId}
        />

        <MARGINBOX value={7} />
        {/* attach question tips */}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography2 variant="h6">Question Tips</Typography2>
          <div style={{ mx: 2, marginLeft: 5, marginRight: 5 }}></div>
          <Typography variant="caption">
            Select type of the tip and then describe in the text box. You can
            even attach an image to make the teacher understand better, which is
            optional.
          </Typography>
        </div>

        {/* classwork question tips */}
        <CLASSWORKQSTTIPS
          planSetup={planSetup}
          onAddClassworkQstTip={() => props.onAddClassworkQstTip()}
          onDelClassworkQTip={(index) => props.onDelClassworkQTip(index)}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />

        {/* 
          <Typography2
            variant="h6">
            {name} Practice
          </Typography2> */}

        <div
          style={{
            marginTop: 10,
            textAlign: "left",
          }}
        >
          {/* <Button variant="contained" size="small">
              Add Step
            </Button> */}
        </div>
      </>
    </div>
  );
};

const TimeBox = (props) => {
  const { planSetup, type } = props;
  const classes = useStyles2();

  let type2 = 1; // value

  if (type == "analysis") {
    type2 = planSetup.analysis.mins;
  } else if (type == "application") {
    type2 = planSetup.application.mins;
  } else if (type == "morePractice") {
    type2 = planSetup.application.morePractice.mins;
  }

  const handleChange = (event) => {
    const val = event.target.value;
    props.handleMinsChange(type, val);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      style={{
        marginLeft: 10,
      }}
    >
      <AccessTimeIcon2 sx={{ mr: 1 }} />
      <TextField
        value={type2}
        id="outlined-basic"
        label="Mins"
        variant="outlined"
        type="number"
        min={1}
        max={10}
        size="small"
        style={{
          width: 80,
        }}
        onChange={handleChange}
      />
    </Box>
  );
};

const CLASSWORKBOX2 = (props) => {
  const { num, name, planSetup } = props;
  return (
    <div sx={{ minWidth: "100%" }}>
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <CLASSWORKNAMEBOX
            num={num}
            name="More Practice"
            planSetup={planSetup}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}>

            <TimeBox
              type="morePractice"
              planSetup={planSetup}
              handleMinsChange={(type, mins) => {
                //                            props.handleMinsChange(type, mins)
                if (type == "morePractice") {
                  let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
                  newPlanSetup.application.morePractice.mins = mins;

                  props.handleUpdatePlanSetup(newPlanSetup);
                }
              }}
            />
          </div>

        </div>

        <CLASSWORKQBOXP
          num={num}
          name="More Practice"
          planSetup={planSetup}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />

        <div
          style={{
            margin: 20,
          }}
        ></div>

        {/* <CLASSWORKSTEPLIST /> */}

        {/* <CLASSWORKSTEPLIST */}
        <STEPLIST
          planSetup={planSetup}
          onAddPracStepClicked={() => props.onAddPracStepClicked()}
          onDelPracStepClicked={(stepIdx) =>
            props.onDelPracStepClicked(stepIdx)
          }
          onDeletePracStepImage={(stepIdx, idx) =>
            props.onDeletePracStepImage(stepIdx, idx)
          }
          onAddPracStepImage={(stepIdx, url) =>
            props.onAddPracStepImage(stepIdx, url)
          }
          onDeletePracStepVid={(stepIdx, idx) =>
            props.onDeletePracStepVid(stepIdx, idx)
          }
          onAddPracStepVid={(stepIdx, url) =>
            props.onAddPracStepVid(stepIdx, url)
          }
          onDeletePracStepDoc={(stepIdx, idx) =>
            props.onDeletePracStepDoc(stepIdx, idx)
          }
          onAddPracStepDoc={(stepIdx, url) =>
            props.onAddPracStepDoc(stepIdx, url)
          }
          onAddPracStepTip={(stepIdx, title, desc) =>
            props.onAddPracStepTip(stepIdx, title, desc)
          }
          onDeletePracStepTip={(stepIdx, idx) =>
            props.onDeletePracStepTip(stepIdx, idx)
          }
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />

        <div
          style={{
            marginTop: 10,
            textAlign: "left",
          }}
        >
          {/* <Button variant="contained" size="small">
              Add Step
            </Button> */}
        </div>
      </>
    </div>
  );
};

const CLASSWORKBOX3 = (props) => {
  const { num, name, planSetup } = props;
  const mapId = useSelector((state) => state.currMapId.currMapId);
  const query = new URLSearchParams(window.location.search);
  const instId = query.get("inst_id");
  const board_id = query.get("board_id");
  const clsStd = query.get("class_std");
  const subId = query.get("sub_id");
  let planId = 0;

  const { application } = planSetup;
  console.log(
    "ApplicationList.js CLASSWORKBOX3: application ",
    JSON.stringify(application)
  );

  return (
    <>
      {/* <CLASSWORKNAMEBOX num={num} name="Homework" /> */}
      {application.classwork.hasQandA ? (
        <WorkbookItem3
          desc={`Upload Textual Solutions`}
          planSetup={planSetup}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />
      ) : (
        <></>
      )}

      {/* or */}

      {application.classwork.hasAssm ? (
        <QuizPlannerDrawer
          planSetup={planSetup}
          type="classwork"
          name="Classwork"
          lsnPlanId={planId}
          subId={subId}
          subSubId={subId}
          topicId={props.topicId}
          chapId={props.chapId}
          unitId={props.unitId}
          noTime={false}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const itemData = [
  {
    img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    url: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    title: "Coffee",
  },
  {
    img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
    url: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
    title: "Hats",
  },

  {
    img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
    url: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
    title: "Honey",
  },
  {
    img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
    url: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
    title: "Basketball",
  },
  {
    img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
    url: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
    title: "Fern",
  },
];

const HOMEWORKIMAGELIST2 = (props) => {
  const { images, planSetup } = props;

  console.log(
    "ApplicationList.js HOMEWORKIMAGELIST images " + JSON.stringify(images)
  );

  return (
    <>
      <div>
        <HEADING value="Add Homework Question Images" />
        <MARGINBOX value={10} />
        {images && images.length ? (
          <ImageList sx={{ width: "100%", height: "auto" }} cols={3}>
            {/* {itemData.map((item) => ( */}
            {images.map((item, idx) => {
              return (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <ImageListItem key={item.img}>
                    <img
                      //src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                      src={`${item.url}`}
                      // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      srcSet={`${item.url}`}
                      alt={""}
                      loading="lazy"
                    />
                  </ImageListItem>
                  <div
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                    }}
                  >
                    <DeleteForeverIcon
                      color="red"
                      style={{
                        color: "red",
                      }}
                      onClick={() => {
                        props.onDelHomeworkImage(idx);
                      }}
                    />
                  </div>
                </div>
              );
            })}
            {/* ))} */}
          </ImageList>
        ) : (
          <></>
        )}
      </div>
      <MARGINBOX value={20} />

      <div
        style={{
          width: "100%",
        }}
      >
        <ADDASSETWIDGET
          noTip={true}
          noVideo={true}
          noDoc={true}
          planSetup={planSetup}
          onAddHomeworkImage={() => props.onAddHomeworkImage()}
        />
      </div>
    </>
  );
};

const HOMEWORKBOX2 = (props) => {
  const { num, name, planSetup } = props;
  const mapId = useSelector((state) => state.currMapId.currMapId);
  const query = new URLSearchParams(window.location.search);
  const instId = query.get("inst_id");
  const board_id = query.get("board_id");
  const clsStd = query.get("class_std");
  const subId = query.get("sub_id");
  let planId = 0;

  const { application } = planSetup;

  return (
    <div sx={{ minWidth: "100%" }}>
      <>
        {/* <CLASSWORKNAMEBOX num={num} name="Homework" /> */}

        {application.homework.hasQandA ? (
          <WorkbookItem4
            desc={`Upload Question and Answer`}
            planSetup={planSetup}
            handleUpdatePlanSetup={(newPlanSetup) =>
              props.handleUpdatePlanSetup(newPlanSetup)
            }
          />
        ) : (
          <></>
        )}

        {/* or */}
        {application.homework.hasAssm ? (
          <QuizPlannerDrawer
            name="Homework"
            planSetup={planSetup}
            type="homework"
            lsnPlanId={planId}
            subId={subId}
            subSubId={subId}
            topicId={props.topicId}
            chapId={props.chapId}
            unitId={props.unitId}
            noTime={true}
          />
        ) : (
          <></>
        )}

        <MARGINBOX value={10} />

        {application.homework.hasImages ? (
          <>
            <Typography2 variant="h6">Images</Typography2>
            <HOMEWORKIMAGELIST
              images={planSetup.application.homework.images}
              planSetup={planSetup}
              onDelHomeworkImage={(idx) => props.onDelHomeworkImage(idx)}
              onAddHomeworkImage={() => props.onAddHomeworkImage()}
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
            />
          </>
        ) : (
          <></>
        )}

        <MARGINBOX value={10} />

        {application.homework.hasQuestionTips ? (
          <>
            {/* attach question tips */}
            <Typography2 variant="h6">Add Question Tips</Typography2>

            {/* homework question tips */}
            <HOMEWORKQSTTIPS
              planSetup={planSetup}
              onAddHomeworkQstTip={() => props.onAddHomeworkQstTip()}
              onDelHomeworkQTip={(index) => props.onDelHomeworkQTip(index)}
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
            />
          </>
        ) : (
          <></>
        )}

        {/* 
                <div
                    style={{
                        marginTop: 10,

                    }}>
                    <Button2
                        variant="contained"
                        endIcon={<AddCircleOutlineIcon />}
                        style={{
                            backgroundColor: Constants.Application.COLOR_PRIMARY
                        }}
                        onClick={() => { }} // add a new resource block
                    >
                        Add Tip
                    </Button2>
                </div> */}
      </>
    </div>
  );
};

const CLASSWORKNAMEBOX = (props) => {
  const { num, name } = props;
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography2 variant="h6">{name}</Typography2>
        </div>
      </div>

      <div
        style={{
          margin: 0,
        }}
      ></div>
      {/* {showIt ? */}
      <Box
        sx={{
          // width: 500,
          maxWidth: "100%",
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      ></Box>

      {/* IMAGES */}

      <div
        style={{
          margin: 20,
        }}
      ></div>
    </>
  );
};

const CLASSWORKQBOX = (props) => {
  const { num, name, type, planSetup } = props;

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const onContentChanged = (newContent) => {
    setContent(newContent);

    handleChange2(newContent);
  };

  let val = "";
  if (type === "help_description") {
    val = planSetup.application.classwork.teacherDiffTips.help_description;
  } else {
    val = planSetup.application.classwork.teacherDiffTips.extra_challenge;
  }

  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("ApplicationList.js CLASSWORKQBOX handleChange name " + name);
    console.log("ApplicationList.js CLASSWORKQBOX handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    newPlanSetup.application.classwork.hasDiffTips = true;
    if (type === "help_description") {
      newPlanSetup.application.classwork.teacherDiffTips.help_description =
        value;
    } else {
      newPlanSetup.application.classwork.teacherDiffTips.extra_challenge =
        value;
    }

    props.handleUpdatePlanSetup(newPlanSetup);
  };
  const handleChange2 = (value) => {
    console.log("ApplicationList.js CLASSWORKQBOX handleChange name " + name);
    console.log("ApplicationList.js CLASSWORKQBOX handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    newPlanSetup.application.classwork.hasDiffTips = true;
    if (type === "help_description") {
      newPlanSetup.application.classwork.teacherDiffTips.help_description =
        value;
    } else {
      newPlanSetup.application.classwork.teacherDiffTips.extra_challenge =
        value;
    }

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  console.log(
    "ApplicationList.js CLASSWORKQBOX: classwork " +
    JSON.stringify(planSetup.application.classwork)
  );

  let tipText = "";
  if (type == "help_description")
    tipText = planSetup.application.classwork.teacherDiffTips.help_description;
  else
    tipText = planSetup.application.classwork.teacherDiffTips.extra_challenge;

  console.log("ApplicationList.js CLASSWORKBOX: type " + type);
  console.log("ApplicationList.js CLASSWORKBOX: tipText " + tipText);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%'
          }}>
          <Typography2
            variant="subtitle1"
            sx={{
              color: "#000",
            }}
          >
            Enter {name} description
          </Typography2>



          <GPTGenerator
            handleUpdateGPTText={(text) => onContentChanged(text)}
            prompt={`${name} in teacher differentiation tips`}
            planSetup={planSetup}
          />
        </div>
      </div>

      <Box
        sx={{
          // width: 500,
          maxWidth: "100%",
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {/* <TextField fullWidth label="Enter the text"
                    // value={val}
                    defaultValue={tipText}
                    placeholder="Enter the text here" id="fullWidth"
                    variant="outlined" multiline rows={1} maxRows={5}
                    onChange={handleChange}
                /> */}

        <JoditEditor
          ref={editor}
          value={tipText}
          tabIndex={1}
          onChange={onContentChanged}
        />
      </Box>

      {/* IMAGES */}

      <div
        style={{
          margin: 20,
        }}
      ></div>
    </>
  );
};

const CLASSWORKQBOXP = (props) => {
  const { num, name, type, planSetup } = props;

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const onContentChanged = (newContent) => {
    setContent(newContent);

    handleChange2(newContent);
  };

  let val = planSetup.application.morePractice.description;

  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("ApplicationList.js CLASSWORKQBOXP handleChange name " + name);
    console.log(
      "ApplicationList.js CLASSWORKQBOXP handleChange value " + value
    );
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    newPlanSetup.application.morePractice.description = value;
    props.handleUpdatePlanSetup(newPlanSetup);
  };
  const handleChange2 = (value) => {
    console.log("ApplicationList.js CLASSWORKQBOXP handleChange name " + name);
    console.log(
      "ApplicationList.js CLASSWORKQBOXP handleChange value " + value
    );
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    newPlanSetup.application.morePractice.description = value;
    props.handleUpdatePlanSetup(newPlanSetup);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography2
          variant="subtitle1"
          sx={{
            color: "#000",
          }}
        >
          Enter {name} description
        </Typography2>


        <GPTGenerator
          handleUpdateGPTText={(text) => handleChange2(text)}
          prompt={`${name} description`}
          planSetup={planSetup}
        />

      </div>

      <Box
        sx={{
          // width: 500,
          maxWidth: "100%",
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {/* <TextField fullWidth label="Enter the text"
                    value={val}
                    placeholder="Enter the text here" id="fullWidth"
                    variant="outlined" multiline rows={1} maxRows={5}
                    onChange={handleChange}
                /> */}

        <JoditEditor
          ref={editor}
          value={val}
          tabIndex={1}
          onChange={onContentChanged}
        />
      </Box>

      {/* IMAGES */}

      <div
        style={{
          margin: 20,
        }}
      ></div>
    </>
  );
};

/* CLASSWORK Question And Answer */
const WorkbookItem3 = (props) => {
  const { num, desc, planSetup } = props;
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);

  const onUpdatePicSource = (downloadURL) => {
    // make a copy
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    let newPicSrc = downloadURL;
    let type = "image";

    if (
      newPicSrc.includes("jpg") ||
      newPicSrc.includes("jpeg") ||
      newPicSrc.includes("png")
    ) {
      type = "image";
      newPlanSetup.application.classwork.hasQandA = true;
      newPlanSetup.application.classwork.questionPaperUrl = downloadURL;
    } else if (newPicSrc.includes("pdf") || newPicSrc.includes("doc")) {
      type = "doc";
      newPlanSetup.application.classwork.hasQandA = true;
      newPlanSetup.application.classwork.questionPaperUrl = downloadURL;
    }

    // update to the master object
    props.handleUpdatePlanSetup(newPlanSetup);

    // open question paper cropping option if 9image
    if (type === 'image')
      setModal1Open(true);
  };

  const onUpdatePicSource2 = (downloadURL) => {
    // make a copy
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    let newPicSrc = downloadURL;
    let type = "image";

    if (
      newPicSrc.includes("jpg") ||
      newPicSrc.includes("jpeg") ||
      newPicSrc.includes("png")
    ) {
      type = "image";
      newPlanSetup.application.classwork.hasQandA = true;
      newPlanSetup.application.classwork.answerSheetUrl = downloadURL;
    } else if (newPicSrc.includes("pdf") || newPicSrc.includes("doc")) {
      type = "doc";
      newPlanSetup.application.classwork.hasQandA = true;
      newPlanSetup.application.classwork.answerSheetUrl = downloadURL;
    }

    // update to the master object
    props.handleUpdatePlanSetup(newPlanSetup);

    // open answer sheet cropping  option
    if (type === 'image')
      setModal2Open(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("ApplicationList.js WorkbookItem3 handleChange name " + name);
    console.log("ApplicationList.js WorkbookItem3 handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.application.classwork.qandAText = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  let val = planSetup.application.classwork.qandAText;

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography2
            variant="body1"
            sx={{
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            {desc}
          </Typography2>

          {/* 
                    <SUBHEADING
                        value={desc}
                    /> */}

          {/* 
                    <ClearIcon color="warning"
                        style={{
                            cursor: 'pointer'
                        }} /> */}
        </div>

        <Box
          sx={{
            // width: 500,
            maxWidth: "100%",
          }}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TextField
            fullWidth
            label="Enter the text"
            placeholder="Enter the text here"
            id="fullWidth"
            variant="outlined"
            rows={6}
            multiline
            sx={{
              width: "55%",
            }}
            style={{
              width: "55%",
            }}
            value={val}
            onChange={handleChange}
          />

          <div
            sx={{
              width: "44%",
              display: "flex",
              justifyContent: "flex-end",
            }}
            style={{
              width: "44%",
              border: "0px #DCDCDC solid",
              padding: 10,
              // height: 200
            }}
          >
            {/* <AddPhotoAlternateIcon
                  size={128}
                  fontSize="large"
                /> */}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div>
                <Typography variant="caption">Upload Question Paper</Typography>
                <PickImageDialog2
                  type="classwork"
                  onUpdatePicSource={(src) => onUpdatePicSource(src)}
                  planSetup={planSetup}
                  handleUpdatePlanSetup={(newPlanSetup) =>
                    props.handleUpdatePlanSetup(newPlanSetup)
                  }
                />

                {modal1Open ? (
                  <>
                    <BANNERMODAL
                      planData={planSetup}
                      planSetup={planSetup}
                      open={modal1Open}
                      onModalClose={() => setModal1Open(false)}
                      onCompletedUpload={(src) => {
                        // onCompletedUpload(src) // pass this to the modal banner
                        console.log(
                          "WorkbookItem3 modal1 onCompletedUpload: called.."
                        );
                        // this is main banner image
                        // let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
                        setModal1Open(false);

                        // props.handleUpdatePlanSetup(sPlanSetup);
                      }}
                      handleUpdatePlanSetup={(newPlanSetup) =>
                        props.handleUpdatePlanSetup(newPlanSetup)
                      }
                      type="classwork"
                      item="questionpaper"
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div style={{ margin: 10 }}></div>
              <div>
                <Typography variant="caption">Upload Answer Sheet</Typography>
                <PickImageDialog3
                  type="classwork"
                  onUpdatePicSource={(src) => onUpdatePicSource2(src)}
                  planSetup={planSetup}
                  handleUpdatePlanSetup={(newPlanSetup) =>
                    props.handleUpdatePlanSetup(newPlanSetup)
                  }
                />

                {modal2Open ? (
                  <>
                    <BANNERMODAL
                      planData={planSetup}
                      planSetup={planSetup}
                      open={modal2Open}
                      onModalClose={() => setModal2Open(false)}
                      onCompletedUpload={(src) => {
                        // onCompletedUpload(src) // pass this to the modal banner
                        console.log(
                          "WorkbookItem3 modal2 onCompletedUpload: called.."
                        );

                        // this is main banner image
                        // let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
                        setModal2Open(false);

                        // props.handleUpdatePlanSetup(sPlanSetup);
                      }}
                      handleUpdatePlanSetup={(newPlanSetup) =>
                        props.handleUpdatePlanSetup(newPlanSetup)
                      }
                      type="classwork"
                      item="answersheet"
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

/* HOMEWORK Question And Answer */
const WorkbookItem4 = (props) => {
  const { num, desc, planSetup } = props;
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);

  const onUpdatePicSource = (downloadURL) => {
    // make a copy
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    let newPicSrc = downloadURL;
    let type = "image";

    if (
      newPicSrc.includes("jpg") ||
      newPicSrc.includes("jpeg") ||
      newPicSrc.includes("png")
    ) {
      type = "image";
      newPlanSetup.application.homework.hasQandA = true;
      newPlanSetup.application.homework.questionPaperUrl = downloadURL;
    } else if (newPicSrc.includes("pdf") || newPicSrc.includes("doc")) {
      type = "doc";
      newPlanSetup.application.homework.hasQandA = true;
      newPlanSetup.application.homework.questionPaperUrl = downloadURL;
    }

    // update to the master object
    props.handleUpdatePlanSetup(newPlanSetup);

    // question paper modal open
    // open crop modal
    if (type == "image") setModal1Open(true);
  };

  const onUpdatePicSource2 = (downloadURL) => {
    // make a copy
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    let newPicSrc = downloadURL;
    let type = "image";

    if (
      newPicSrc.includes("jpg") ||
      newPicSrc.includes("jpeg") ||
      newPicSrc.includes("png")
    ) {
      type = "image";
      newPlanSetup.application.homework.hasQandA = true;
      newPlanSetup.application.homework.answerSheetUrl = downloadURL;
    } else if (newPicSrc.includes("pdf") || newPicSrc.includes("doc")) {
      type = "doc";
      newPlanSetup.application.homework.hasQandA = true;
      newPlanSetup.application.homework.answerSheetUrl = downloadURL;
    }

    // update to the master object
    props.handleUpdatePlanSetup(newPlanSetup);

    // answer sheet modal open
    // open crop modal
    if (type == "image") setModal2Open(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("ApplicationList.js WorkbookItem4 handleChange name " + name);
    console.log("ApplicationList.js WorkbookItem4 handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.application.homework.qandAText = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  let val = planSetup.application.homework.qandAText;

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography2 variant="h6">{desc}</Typography2>

          {/* 
                    <SUBHEADING
                        value={desc}
                    /> */}

          <ClearIcon
            color="warning"
            style={{
              cursor: "pointer",
            }}
          />
        </div>

        <Box
          sx={{
            // width: 500,
            maxWidth: "100%",
          }}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TextField
            fullWidth
            label="Enter the text"
            placeholder="Enter the text here"
            id="fullWidth"
            variant="outlined"
            rows={6}
            multiline
            sx={{
              width: "55%",
            }}
            style={{
              width: "55%",
            }}
            value={val}
            onChange={handleChange}
          />

          <div
            sx={{
              width: "44%",
              display: "flex",
              justifyContent: "flex-end",
            }}
            style={{
              width: "44%",
              border: "0px #DCDCDC solid",
              padding: 10,
              // height: 200
            }}
          >
            {/* <AddPhotoAlternateIcon
                  size={128}
                  fontSize="large"
                /> */}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div>
                <Typography variant="caption">Upload Question Paper</Typography>
                <PickImageDialog2
                  onUpdatePicSource={(src) => onUpdatePicSource(src)}
                  planSetup={planSetup}
                  handleUpdatePlanSetup={(newPlanSetup) =>
                    props.handleUpdatePlanSetup(newPlanSetup)
                  }
                  type="homework"
                />

                {modal1Open ? (
                  <>
                    <BANNERMODAL
                      planData={planSetup}
                      planSetup={planSetup}
                      open={modal1Open}
                      onModalClose={() => setModal1Open(false)}
                      onCompletedUpload={(src) => {
                        // onCompletedUpload(src) // pass this to the modal banner

                        // this is main banner image
                        // let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
                        setModal1Open(false);

                        // props.handleUpdatePlanSetup(sPlanSetup);
                      }}
                      handleUpdatePlanSetup={(newPlanSetup) =>
                        props.handleUpdatePlanSetup(newPlanSetup)
                      }
                      type="homework"
                      item="questionpaper"
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div style={{ margin: 10 }}></div>
              <div>
                <Typography variant="caption">Upload Answer Sheet</Typography>
                <PickImageDialog3
                  onUpdatePicSource={(src) => onUpdatePicSource2(src)}
                  planSetup={planSetup}
                  handleUpdatePlanSetup={(newPlanSetup) =>
                    props.handleUpdatePlanSetup(newPlanSetup)
                  }
                  type="homework"
                />

                {modal2Open ? (
                  <>
                    <BANNERMODAL
                      planData={planSetup}
                      planSetup={planSetup}
                      open={modal2Open}
                      onModalClose={() => setModal2Open(false)}
                      onCompletedUpload={(src) => {
                        // onCompletedUpload(src) // pass this to the modal banner

                        // this is main banner image
                        let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
                        setModal2Open(false);

                        props.handleUpdatePlanSetup(sPlanSetup);
                      }}
                      handleUpdatePlanSetup={(newPlanSetup) =>
                        props.handleUpdatePlanSetup(newPlanSetup)
                      }
                      type="homework"
                      item="answersheet"
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

const UploadBox = (props) => {
  const { planSetup, idx } = props;
  const profilePictureRef = React.createRef();
  const [percent, setPercent] = useState(0);

  async function srcToFile(src, fileName, mimeType) {
    return await fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        console.log("AimList.js UploadBox srcToFile buf " + buf);

        const imageFile = new File([buf], fileName, { type: mimeType });
        handleUpload1(imageFile);
      });
  }

  const handleUpload1 = (imageFile) => {
    //add here the upload logic...
    if (!imageFile) {
      alert("AimList.js UploadBox handleUpload1 Image is invalid!");
      return;
    }

    const storageRef = firebase.storage().ref(`/files/${imageFile.name}`); //.child(imageFile.name);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    // const uploadTask = uploadBytesResumable(storageRef, file);
    const uploadTask = storageRef.put(imageFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => {
        console.log("CurriculumSetup.js BANNERBOX handleUpload: error " + err);
      },
      () => {
        // download url
        storageRef
          .getDownloadURL() //uploadTask.snapshot.ref).then((url) => {
          .then(async (firebaseUrl) => {
            console.log(
              "CurriculumSetup.js BANNERBOX handleUpload: firebaseUrl " +
              firebaseUrl
            );
            // update plansetup current resource image
            let sPlanSetup = JSON.parse(JSON.stringify(planSetup));

            let newImg = {
              url: firebaseUrl,
            };

            sPlanSetup.application.morePractice.steps[idx].images.push(newImg);
            props.handleUpdatePlanSetup(sPlanSetup);

            props.onClose();
          })
          .catch((e) => {
            console.log(
              "CurriculumSetup.js BANNERBOX handleUpload: exception " + e
            );
          });
      }
    );
  };

  const handleUpload = async () => {
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const imageFile = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();

    if (
      Object.keys(imageData).length === 0 &&
      imageData.constructor === Object
    ) {
      // do not proceed
      return;
    }

    console.log("AimList.js UploadBox handleUpload PP " + PP);
    console.log("AimList.js UploadBox handleUpload imageData " + imageData);
    console.log("AimList.js UploadBox handleUpload file " + imageFile);
    console.log(
      "AimList.js UploadBox handleUpload imageAsDataURL " + imageAsDataURL
    );

    let type = imageAsDataURL.substring(
      imageAsDataURL.indexOf(":") + 1,
      imageAsDataURL.indexOf(";")
    ); // => image/png
    console.log("AimList.js UploadBox handleUpload callback: type " + type);

    let fileName = makeid(10);

    if (type == "image/jpeg") fileName = fileName + ".jpg";
    else if (type == "image/png") fileName = fileName + ".png";
    else if (type == "image/gif") fileName = fileName + ".gif";
    else fileName = fileName + ".jpg";

    console.log(
      "AimList.js UploadBox handleUpload callback: fileName " + fileName
    );

    // now upload to firebase and set the image url ready
    // convert to file
    // const imageBlob = dataURItoBlob(newDataUri);

    // console.log('CurriculumSetup.js BANNERBOX imageChanged callback: imageBlob ' + JSON.stringify(imageBlob));
    // const imageFile = dataURLtoFile(newDataUri, fileName);
    // fetch(newDataUri)
    //   .then(res => res.blob())
    //   .then(blob => {
    //     const imageFile = new File([blob], fileName)

    //   })

    // const imageFile = new File([imageBlob], imageBlob.name, {
    //   type: imageBlob.type,
    // });

    const imageFile2 = await srcToFile(imageAsDataURL, fileName, type);
  };

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  return (
    <>
      <div
        style={
          {
            // height: 200
          }
        }
      >
        <ProfilePicture ref={profilePictureRef} useHelper={true} debug={true} />

        <div
          style={{
            alignSelf: "right",
          }}
        >
          <Button2 onClick={handleUpload}>Upload</Button2>
        </div>
      </div>
    </>
  );
};

/* homework image upload */
const UploadBox5 = (props) => {
  const { planSetup, idx } = props;
  const profilePictureRef = React.createRef();
  const [percent, setPercent] = useState(0);

  async function srcToFile(src, fileName, mimeType) {
    return await fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        console.log("ApplicationList.js UploadBox srcToFile buf " + buf);

        const imageFile = new File([buf], fileName, { type: mimeType });
        handleUpload1(imageFile);
      });
  }

  const handleUpload1 = (imageFile) => {
    //add here the upload logic...
    if (!imageFile) {
      alert("ApplicationList.js UploadBox handleUpload1 Image is invalid!");
      return;
    }

    const storageRef = firebase.storage().ref(`/files/${imageFile.name}`); //.child(imageFile.name);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    // const uploadTask = uploadBytesResumable(storageRef, file);
    const uploadTask = storageRef.put(imageFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => {
        console.log("ApplicationList.js BANNERBOX handleUpload: error " + err);
      },
      () => {
        // download url
        storageRef
          .getDownloadURL() //uploadTask.snapshot.ref).then((url) => {
          .then(async (firebaseUrl) => {
            console.log(
              "ApplicationList.js BANNERBOX handleUpload: firebaseUrl " +
              firebaseUrl
            );
            // update plansetup current resource image
            let sPlanSetup = JSON.parse(JSON.stringify(planSetup));

            let newImg = {
              url: firebaseUrl,
            };

            sPlanSetup.application.homework.images.push(newImg);
            props.handleUpdatePlanSetup(sPlanSetup);

            props.onClose();
          })
          .catch((e) => {
            console.log(
              "ApplicationList.js BANNERBOX handleUpload: exception " + e
            );
          });
      }
    );
  };

  const handleUpload = async () => {
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const imageFile = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();

    if (
      Object.keys(imageData).length === 0 &&
      imageData.constructor === Object
    ) {
      // do not proceed
      return;
    }

    console.log("ApplicationList.js UploadBox5 handleUpload PP " + PP);
    console.log(
      "ApplicationList.js UploadBox5 handleUpload imageData " + imageData
    );
    console.log("ApplicationList.js UploadBox5 handleUpload file " + imageFile);
    console.log(
      "ApplicationList.js UploadBox5 handleUpload imageAsDataURL " +
      imageAsDataURL
    );

    let type = imageAsDataURL.substring(
      imageAsDataURL.indexOf(":") + 1,
      imageAsDataURL.indexOf(";")
    ); // => image/png
    console.log(
      "ApplicationList.js UploadBox5 handleUpload callback: type " + type
    );

    let fileName = makeid(10);

    if (type == "image/jpeg") fileName = fileName + ".jpg";
    else if (type == "image/png") fileName = fileName + ".png";
    else if (type == "image/gif") fileName = fileName + ".gif";
    else fileName = fileName + ".jpg";

    console.log(
      "ApplicationList.js UploadBox handleUpload callback: fileName " + fileName
    );

    // now upload to firebase and set the image url ready
    // convert to file
    // const imageBlob = dataURItoBlob(newDataUri);

    // console.log('CurriculumSetup.js BANNERBOX imageChanged callback: imageBlob ' + JSON.stringify(imageBlob));
    // const imageFile = dataURLtoFile(newDataUri, fileName);
    // fetch(newDataUri)
    //   .then(res => res.blob())
    //   .then(blob => {
    //     const imageFile = new File([blob], fileName)

    //   })

    // const imageFile = new File([imageBlob], imageBlob.name, {
    //   type: imageBlob.type,
    // });

    const imageFile2 = await srcToFile(imageAsDataURL, fileName, type);
  };

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  return (
    <>
      <div
        style={
          {
            // height: 200
          }
        }
      >
        <ProfilePicture ref={profilePictureRef} useHelper={true} debug={true} />

        <div
          style={{
            alignSelf: "right",
          }}
        >
          <Button2 onClick={handleUpload}>Upload</Button2>
        </div>
      </div>
    </>
  );
};

function PickImageDialog(props) {
  const { planSetup, idx, image } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    // props.onAddPracStepImage(idx, 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d')
  };

  const handleClose = () => {
    setOpen(false);
  };

  const imgUrl = image
    ? image
    : "https://t3.ftcdn.net/jpg/04/34/72/82/360_F_434728286_OWQQvAFoXZLdGHlObozsolNeuSxhpr84.jpg";

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}> */}

      <a
        style={{
          color: Constants.COLOR_PRIMARY,
        }}
        href="javascript:void(0)"
        onClick={() => {
          handleClickOpen();
        }}
      >
        <STEPASSET
          onClick={() => {
            props.onAddPracStepImage(
              idx,
              "https://images.unsplash.com/photo-1551782450-a2132b4ba21d"
            );
          }}
          asset={
            <Tooltip title="Add Image" placement="top">
              <AddPhotoAlternateIcon color="secondary" />
            </Tooltip>
          }
        />
      </a>

      {/* </Button> */}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload picture</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose the picture, and crop it to your desire
          </DialogContentText>
          <UploadBox
            idx={idx}
            planSetup={planSetup}
            handleUpdatePlanSetup={(newPlanSetup) =>
              props.handleUpdatePlanSetup(newPlanSetup)
            }
            onClose={handleClose}
            onAddPracStepImage={(idx, url) =>
              props.onAddPracStepImage(idx, url)
            }
          />
        </DialogContent>
        {/* <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose}>UPLOAD</Button>
                </DialogActions> */}
      </Dialog>
    </div>
  );
}

/* homework images */
function PickImageDialog5(props) {
  const { planSetup, idx, image } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    // props.onAddPracStepImage(idx, 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d')
  };

  const handleClose = () => {
    setOpen(false);
  };

  const imgUrl = image
    ? image
    : "https://t3.ftcdn.net/jpg/04/34/72/82/360_F_434728286_OWQQvAFoXZLdGHlObozsolNeuSxhpr84.jpg";

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}> */}

      <a
        style={{
          color: Constants.COLOR_PRIMARY,
        }}
        href="javascript:void(0)"
        onClick={() => {
          handleClickOpen();
        }}
      >
        <STEPASSET
          onClick={() => {
            props.onAddHomeworkImage(
              idx,
              "https://images.unsplash.com/photo-1551782450-a2132b4ba21d"
            );
          }}
          asset={<AddPhotoAlternateIcon color="secondary" />}
        />
      </a>

      {/* </Button> */}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload picture</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose the picture, and crop it to your desire
          </DialogContentText>
          <UploadBox5
            idx={idx}
            planSetup={planSetup}
            handleUpdatePlanSetup={(newPlanSetup) =>
              props.handleUpdatePlanSetup(newPlanSetup)
            }
            onClose={handleClose}
            onAddHomeworkImage={(idx, url) =>
              props.onAddHomeworkImage(idx, url)
            }
          />
        </DialogContent>
        {/* <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose}>UPLOAD</Button>
                </DialogActions> */}
      </Dialog>
    </div>
  );
}

const STEPASSET = (props) => {
  const { asset } = props;
  return (
    <div
      sx={{
        backgroundColor: "#DCDCDC",
      }}
      style={{
        background: "#DCDCDC",
        verticalAlign: "middle",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        padding: 2,
        borderRadius: 5,
        margin: 0,
        cursor: "pointer",
      }}
    >
      {asset}
    </div>
  );
};

const StepImageList = (props) => {
  const { images, stepIdx, planSetup } = props;

  console.log(
    "CurriculumSetup.js StepImageList images " + JSON.stringify(images)
  );

  return (
    <>
      {images && images.length ? (
        <ImageList sx={{ width: "100%", height: "auto" }} cols={6}>
          {/* {itemData.map((item) => ( */}
          {images.map((item, idx) => {
            return (
              <div
                style={{
                  position: "relative",
                }}
              >
                <ImageListItem key={item.img}>
                  <img
                    //src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    src={`${item.url}`}
                    // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    srcSet={`${item.url}`}
                    alt={""}
                    loading="lazy"
                  />
                </ImageListItem>
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}
                >
                  <DeleteForeverIcon
                    color="red"
                    style={{
                      color: "red",
                    }}
                    onClick={() => props.onDeletePracStepImage(stepIdx, idx)}
                  />
                </div>
              </div>
            );
          })}
          {/* ))} */}
        </ImageList>
      ) : (
        <></>
      )}
    </>
  );
};

const StepTipList = (props) => {
  const { step, tips, planSetup, stepIdx } = props;

  // update the aim resource by index
  const handleChange = (e, idx2) => {
    const { name, value } = e.target;
    console.log("ApplicationList.js StepTipList handleChange idx " + idx2);

    console.log("ApplicationList.js StepTipList handleChange name " + name);
    console.log("ApplicationList.js StepTipList handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    let tip = newPlanSetup.application.morePractice.steps[stepIdx].tips[idx2];
    tip.title = value; // it's all connected by pointers, so value has changed
    tip.description = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const handleChange2 = (text, idx2) => {
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    let tip = newPlanSetup.application.morePractice.steps[stepIdx].tips[idx2];
    tip.title = text; // it's all connected by pointers, so value has changed
    tip.description = text;


    props.handleUpdatePlanSetup(newPlanSetup);
  }

  return tips && tips.length ? (
    tips.map((item, idx) => {
      let title = item.title;

      return (
        <>
          <div
            style={{
              margin: 10,
            }}
          ></div>
          {/* TEACHER TIP */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SUBHEADING value="Teacher Tip" />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}>
              <GPTGenerator
                handleUpdateGPTText={(text) => handleChange2(text, idx)}
                prompt={`Teacher tip for more practice`}
                planSetup={planSetup}
              />

              <ClearIcon
                color="warning"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.onDeletePracStepTip(stepIdx, idx);
                }}
              />
            </div>
          </div>
          <Box
            sx={{
              // width: 500,
              maxWidth: "100%",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              label="Enter the text"
              value={title}
              placeholder="Enter the text here"
              id="fullWidth"
              variant="outlined"
              multiline
              rows={1}
              maxRows={5}
              onChange={(e) => handleChange(e, idx)}
            />

            {/* <ImageIcon /> */}
          </Box>
        </>
      );
    })
  ) : (
    <></>
  );
};

const StepVideoList = (props) => {
  const { videos, stepIdx, planSetup } = props;

  console.log(
    "CurriculumSetup.js StepImageList videos " + JSON.stringify(videos)
  );

  return (
    <>
      {videos && videos.length ? (
        <ImageList sx={{ width: "100%", height: "auto" }} cols={6}>
          {/* {itemData.map((item) => ( */}
          {videos.map((item, idx) => {
            return (
              <div
                style={{
                  position: "relative",
                }}
              >
                <ImageListItem>
                  <img
                    //src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    // src={`${item.url}`}
                    src="https://www.pngkit.com/png/detail/43-438895_play-button-overlay-png-white-video-play-button.png"
                    // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    // srcSet={`${item.url}`}
                    srcSet="https://www.pngkit.com/png/detail/43-438895_play-button-overlay-png-white-video-play-button.png"
                    alt={""}
                    loading="lazy"
                  />
                </ImageListItem>
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}
                >
                  <DeleteForeverIcon
                    color="red"
                    style={{
                      color: "red",
                    }}
                    onClick={() => props.onDeletePracStepVid(stepIdx, idx)}
                  />
                </div>
              </div>
            );
          })}
          {/* ))} */}
        </ImageList>
      ) : (
        <></>
      )}
    </>
  );
};

const StepDocList = (props) => {
  const { docs, stepIdx, planSetup } = props;

  console.log("ApplicationList.js StepDocList docs " + JSON.stringify(docs));

  const PDF_ICON =
    "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png";
  const PPT_ICON =
    "https://www.pcworld.com/wp-content/uploads/2021/09/pcw-ppt-primary-100787681-orig-1.jpg?quality=50&strip=all&w=1024";
  return (
    <>
      {docs && docs.length ? (
        <ImageList sx={{ height: "auto" }} cols={6}>
          {/* {itemData.map((item) => ( */}
          {docs.map((item, idx) => {
            let icon = PDF_ICON;
            if (item.url.includes("ppt")) icon = PPT_ICON;
            return (
              <div
                style={{
                  position: "relative",
                }}
              >
                <ImageListItem>
                  <img
                    //src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    // src={`${item.url}`}
                    src={icon}
                    // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    // srcSet={`${item.url}`}
                    srcSet={icon}
                    alt={""}
                    sx={{
                      height: "100px",
                    }}
                    style={{
                      height: "100px",
                      width: "100px",
                      alignSelf: "center",
                    }}
                    loading="lazy"
                  />
                </ImageListItem>
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}
                >
                  <DeleteForeverIcon
                    color="red"
                    style={{
                      color: "red",
                    }}
                    onClick={() => props.onDeletePracStepDoc(stepIdx, idx)}
                  />
                </div>
              </div>
            );
          })}
          {/* ))} */}
        </ImageList>
      ) : (
        <></>
      )}
    </>
  );
};

/* to upload all kinds of media */
function PickImageDialog6(props) {
  let type2 = props.type;
  const [open, setOpen] = React.useState(false);
  const [picSrc, setPicSrc] = useState(
    "https://www.nicepng.com/png/detail/129-1298055_file-upload-comments-icons-for-file-upload.png"
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setPicSource = (src) => {
    setPicSrc(src);
  };

  const onUpdatePicSource = (src) => {
    setPicSrc(src);

    // move up as we need to update data to master object
    props.onUpdatePicSource(src);
  };

  let type3 = type2;
  let newPicSrc = picSrc;
  if (newPicSrc) {
    if (
      newPicSrc.includes("mp4") ||
      newPicSrc.includes("ogg") ||
      newPicSrc.includes("mov")
    )
      type3 = "video";
  }

  // base on type
  if (type3) {
    switch (type3) {
      case "video":
        newPicSrc =
          "https://www.pngkit.com/png/detail/43-438895_play-button-overlay-png-white-video-play-button.png";
        break;
    }
  }

  console.log("CurriculumSetup.js PickImageDialog2 setPicSource type " + type3);
  console.log(
    "CurriculumSetup.js PickImageDialog2 setPicSource newPicSrc " + newPicSrc
  );

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}> */}
      {type3 == "video" ? (
        <STEPASSET
          asset={
            <Tooltip title="Add Video" placement="top">
              <VideoCallIcon color="secondary" onClick={handleClickOpen} />
            </Tooltip>
          }
        />
      ) : (
        <STEPASSET
          asset={
            <Tooltip title="Add Document" placement="top">
              <NoteAddIcon color="secondary" />
            </Tooltip>
          }
          onClick={handleClickOpen}
        />
      )}
      {/* <STEPASSET asset={<VideoCallIcon color="secondary"
                onClick={handleClickOpen}
            />
             */}
      {/*
            <div
                style={{
                    border: '1px #DCDCDC solid',
                    borderRadius: 10,
                    width: 100,
                    height: 140,
                    background: `url('')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
                onClick={handleClickOpen}
            >
                <img
                    src={newPicSrc}
                    width={100}
                    height={140}
                />
            </div>
            */}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose the video (mp4) by clicking upload
          </DialogContentText>

          <UploadBox6 setPicSource={(src) => onUpdatePicSource(src)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button onClick={handleClose}>UPLOAD</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const UploadBox6 = (props) => {
  const profilePictureRef = React.createRef();
  const [open, setOpen] = React.useState(false);
  const [downloadUrls, setDownloadUrls] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [ImageDownloadUrls, setImageDownloadUrls] = useState("");
  const [progress, setProgress] = useState(0);

  const { planSetup } = props;

  const handleUpload = () => {
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();

    //add here the upload logic...
  };

  // Create new plugin instance
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleUploadStart = () => {
    setIsUploading(true);
    setUploadProgress(0);
  };

  const handleProgress = (progress) => {
    setProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
  };

  const handleUploadSingleSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL();

    console.log("download url", downloadURL);

    if (downloadURL) {
      setProgress(0);
    }
    // setFileNames(filename)
    setImageDownloadUrls(downloadURL);
    props.setPicSource(downloadURL);
    // setUploadProgress(100)
    // setIsUploading(false)
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let imgSrc =
    "https://www.nicepng.com/png/detail/129-1298055_file-upload-comments-icons-for-file-upload.png";

  if (ImageDownloadUrls) {
    imgSrc = ImageDownloadUrls;
  }

  const setPicSource = (src) => {
    setImageDownloadUrls(src);
  };

  let type = null;
  let newPicSrc = imgSrc;
  if (newPicSrc) {
    if (
      newPicSrc.includes("mp4") ||
      newPicSrc.includes("ogg") ||
      newPicSrc.includes("mov")
    )
      type = "video";
  }

  // base on type
  if (type) {
    switch (type) {
      case "video":
        newPicSrc =
          "https://www.pngkit.com/png/detail/43-438895_play-button-overlay-png-white-video-play-button.png"; // or show video player here
        break;
    }
  }

  console.log("CurriculumSetup.js PickImageDialog2 setPicSource type " + type);
  console.log(
    "CurriculumSetup.js PickImageDialog2 setPicSource newPicSrc " + newPicSrc
  );

  return (
    <>
      <div
        style={
          {
            // height: 200
          }
        }
      >
        {progress ? (
          <CircularProgress />
        ) : (
          <img
            src={newPicSrc}
            style={{
              // width: '100%',
              height: 200,
            }}
          />
        )}

        <div
          style={{
            alignSelf: "right",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              // marginLeft: 15,
              // marginRight: 15,
            }}
          >
            <label
              style={{
                fontFamily: "Poppins, Helvetica, sans-serif",
                backgroundColor: "lightsteelblue",
                color: "black",
                paddingLeft: 5,
                paddingRight: 5,
                borderRadius: 4,
                cursor: "pointer",
                // width: '100%',
                textAlign: "center",
                alignSelf: "right",
                float: "right",
              }}
            >
              {/* <Typography style={{ width: 250, padding: 5 }}> */}
              CHOOSE
              {/* </Typography> */}
              <FileUploader
                hidden
                // accept="image/*"
                storageRef={firebase.storage().ref("images")}
                onUploadStart={handleUploadStart}
                onUploadError={handleUploadError}
                onUploadSuccess={handleUploadSingleSuccess}
                onProgress={handleProgress}
              />
            </label>

            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                {progress ? <CircularProgress /> : <></>}
              </div> */}
            {/* 
            {ImageDownloadUrls ?
              <div style={{ marginLeft: 5, marginTop: 10 }} onClick={handleClickOpen}>
                <ImageOutlinedIcon style={{ fontsize: 80 }} />
              </div>
              :
              <></>
            } */}
          </div>
        </div>
      </div>
    </>
  );
};

/* to upload all kinds of media */
function PickImageDialog7(props) {
  let type2 = props.type;
  const [open, setOpen] = React.useState(false);
  const [picSrc, setPicSrc] = useState(
    "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setPicSource = (src) => {
    setPicSrc(src);
  };

  const onUpdateDocPicSource = (src) => {
    setPicSrc(src);

    // move up as we need to update data to master object
    props.onUpdateDocPicSource(src);
  };

  let type = type2;
  let newPicSrc = picSrc;
  if (newPicSrc) {
    if (newPicSrc.includes("pdf") || newPicSrc.includes("doc")) type = "doc";
    else if (newPicSrc.includes("ppt")) type = "ppt";
  }

  // base on type
  if (type) {
    switch (type) {
      case "doc":
        newPicSrc =
          "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png";
        break;
      case "ppt":
        newPicSrc =
          "https://www.pcworld.com/wp-content/uploads/2021/09/pcw-ppt-primary-100787681-orig-1.jpg?quality=50&strip=all&w=1024";
        break;
    }
  }

  console.log("ApplicationList.js PickImageDialog7 setPicSource type " + type);
  console.log(
    "ApplicationList.js PickImageDialog7 setPicSource newPicSrc " + newPicSrc
  );

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}> */}
      {type2 == "video" ? (
        <STEPASSET
          asset={
            <Tooltip title="Add Video" placement="top">
              <VideoCallIcon color="secondary" onClick={handleClickOpen} />
            </Tooltip>
          }
        />
      ) : (
        <STEPASSET
          asset={
            <Tooltip title="Add Document" placement="top">
              <NoteAddIcon color="secondary" onClick={handleClickOpen} />
            </Tooltip>
          }
        />
      )}
      {/* <STEPASSET asset={<VideoCallIcon color="secondary"
                onClick={handleClickOpen}
            />
             */}
      {/*
            <div
                style={{
                    border: '1px #DCDCDC solid',
                    borderRadius: 10,
                    width: 100,
                    height: 140,
                    background: `url('')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
                onClick={handleClickOpen}
            >
                <img
                    src={newPicSrc}
                    width={100}
                    height={140}
                />
            </div>
            */}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose the document (PDF) by clicking upload
          </DialogContentText>

          <UploadBox7 setDocPicSource={(src) => onUpdateDocPicSource(src)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button onClick={handleClose}>UPLOAD</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const UploadBox7 = (props) => {
  const profilePictureRef = React.createRef();
  const [open, setOpen] = React.useState(false);
  const [downloadUrls, setDownloadUrls] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [ImageDownloadUrls, setImageDownloadUrls] = useState("");
  const [progress, setProgress] = useState(0);

  const { planSetup } = props;

  const handleUpload = () => {
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();

    //add here the upload logic...
  };

  // Create new plugin instance
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleUploadStart = () => {
    setIsUploading(true);
    setUploadProgress(0);
  };

  const handleProgress = (progress) => {
    setProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
  };

  const handleUploadSingleSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL();

    console.log("download url", downloadURL);

    if (downloadURL) {
      setProgress(0);
    }
    // setFileNames(filename)
    setImageDownloadUrls(downloadURL);
    props.setDocPicSource(downloadURL);
    // setUploadProgress(100)
    // setIsUploading(false)
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let imgSrc =
    "https://www.nicepng.com/png/detail/129-1298055_file-upload-comments-icons-for-file-upload.png";

  if (ImageDownloadUrls) {
    imgSrc = ImageDownloadUrls;
  }

  const setPicSource = (src) => {
    setImageDownloadUrls(src);
  };

  let type = null;
  let newPicSrc = imgSrc;
  if (newPicSrc) {
    if (newPicSrc.includes("pdf") || newPicSrc.includes("doc")) type = "doc";
    else if (newPicSrc.includes("ppt")) {
      console.log("ApplicationList.js UploadBox7: newPicSrc has PPT");
      type = "ppt";
    }
  }

  // base on type
  if (type) {
    switch (type) {
      case "doc":
        newPicSrc =
          "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png";
        break;
      case "ppt":
        newPicSrc =
          "https://www.pcworld.com/wp-content/uploads/2021/09/pcw-ppt-primary-100787681-orig-1.jpg?quality=50&strip=all&w=1024";
        break;
    }
  }

  console.log("ApplicationList.js UploadBox7 setPicSource type " + type);
  console.log(
    "ApplicationList.js UploadBox7 setPicSource newPicSrc " + newPicSrc
  );

  return (
    <>
      <div
        style={
          {
            // height: 200
          }
        }
      >
        {progress ? (
          <CircularProgress />
        ) : (
          <img
            src={newPicSrc}
            style={{
              // width: '100%',
              height: 400,
            }}
          />
        )}

        <div
          style={{
            alignSelf: "right",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              // marginLeft: 15,
              // marginRight: 15,
            }}
          >
            <label
              style={{
                fontFamily: "Poppins, Helvetica, sans-serif",
                backgroundColor: "lightsteelblue",
                color: "black",
                paddingLeft: 5,
                paddingRight: 5,
                borderRadius: 4,
                cursor: "pointer",
                // width: '100%',
                textAlign: "center",
                alignSelf: "right",
                float: "right",
              }}
            >
              {/* <Typography style={{ width: 250, padding: 5 }}> */}
              CHOOSE
              {/* </Typography> */}
              <FileUploader
                hidden
                // accept="image/*"
                storageRef={firebase.storage().ref("images")}
                onUploadStart={handleUploadStart}
                onUploadError={handleUploadError}
                onUploadSuccess={handleUploadSingleSuccess}
                onProgress={handleProgress}
              />
            </label>

            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                {progress ? <CircularProgress /> : <></>}
              </div> */}
            {/* 
            {ImageDownloadUrls ?
              <div style={{ marginLeft: 5, marginTop: 10 }} onClick={handleClickOpen}>
                <ImageOutlinedIcon style={{ fontsize: 80 }} />
              </div>
              :
              <></>
            } */}
          </div>
        </div>
      </div>
    </>
  );
};

function StepField2(props) {
  const { num, idx, planSetup } = props;
  const [showIt, setShowIt] = useState(false);

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const onContentChanged = (newContent) => {
    setContent(newContent);

    handleChange2(newContent);
  };

  const toggleShowIt = () => {
    let newShowIt = !showIt;
    setShowIt(newShowIt);
  };

  const currentStep = planSetup.application.morePractice.steps[idx];

  if (!currentStep) {
    return <></>;
  }

  // update the aim resource by index
  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("CurriculumSetup.js StepField2 handleChange name " + name);
    console.log("CurriculumSetup.js StepField2 handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    let step = newPlanSetup.application.morePractice.steps[idx];
    step.title = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };
  const handleChange2 = (value) => {
    console.log("CurriculumSetup.js StepField2 handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    let step = newPlanSetup.application.morePractice.steps[idx];
    step.title = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const onUpdatePicSource = (src) => {
    // save as video or pdf based on extension
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    let type = "";
    if (src) {
      if (src.includes("mp4") || src.includes("ogg") || src.includes("mov"))
        type = "video";
    }

    if (type == "video") {
      // add new video

      let vid = {
        url: src,
      };

      newPlanSetup.application.morePractice.steps[idx].videos.push(vid);
    }

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const onUpdateDocPicSource = (src) => {
    // save as video or pdf based on extension
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    let type = "";
    if (src) {
      if (src.includes("pdf") || src.includes("doc")) type = "doc";
      else if (src.includes("ppt")) {
        type = "ppt";
      }
    }

    if (type == "doc" || type == "ppt") {
      // add new doc

      let doc = {
        url: src,
      };

      newPlanSetup.application.morePractice.steps[idx].docs.push(doc);
    }

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const step = planSetup.application.morePractice.steps[idx];

  return (
    <div sx={{ minWidth: "100%" }}>
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography2 variant="h6">Step {num}</Typography2>

          <ClearIcon
            color="warning"
            style={{
              cursor: "pointer",
            }}
            onClick={() => props.onDelPracStepClicked(idx)}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <SUBHEADING value="Enter title" />
          <GPTGenerator
            handleUpdateGPTText={(text) => handleChange2(text)}
            prompt={`a single more practice step`}
            planSetup={planSetup}
          />

        </div>


        {/* {showIt ? */}
        <Box
          sx={{
            // width: 500,
            maxWidth: "100%",
          }}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {/* <TextField fullWidth label="Title"
                        defaultValue={step.title}
                        placeholder="Type here" id="fullWidth"
                        variant="outlined" multiline rows={1} maxRows={5}
                        onChange={handleChange}
                    /> */}
          <JoditEditor
            ref={editor}
            value={step.title}
            tabIndex={1}
            onChange={onContentChanged}
          />

          {/* <ImageIcon /> */}
        </Box>
        {/* :
              <></>
            } */}

        <StepTipList
          tips={currentStep.tips}
          idx={idx}
          stepIdx={idx}
          planSetup={planSetup}
          onDeletePracStepTip={(stepIdx, idx) =>
            props.onDeletePracStepTip(stepIdx, idx)
          }
          step={step}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />

        {/* IMAGES */}

        <div
          style={{
            margin: 20,
          }}
        ></div>

        <StepImageList
          images={currentStep.images}
          stepIdx={idx}
          planSetup={planSetup}
          onDeletePracStepImage={(stepIdx, idx) =>
            props.onDeletePracStepImage(stepIdx, idx)
          }
          step={step}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />

        {/* VIDEOS */}

        <div
          style={{
            margin: 20,
          }}
        ></div>

        <StepVideoList
          videos={currentStep.videos}
          stepIdx={idx}
          planSetup={planSetup}
          onDeletePracStepVid={(stepIdx, idx) =>
            props.onDeletePracStepVid(stepIdx, idx)
          }
          step={step}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />

        {/* DOCS */}

        <div
          style={{
            margin: 20,
          }}
        ></div>

        <StepDocList
          docs={currentStep.docs}
          stepIdx={idx}
          planSetup={planSetup}
          onDeletePracStepDoc={(stepIdx, idx) =>
            props.onDeletePracStepDoc(stepIdx, idx)
          }
          step={step}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />

        {/* ICONS */}
        <div
          style={{
            marginTop: 10,
            display: "flex",
            flexDirection: "row",
            // textAlign: 'right'
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Typography2 variant="button">ADD:</Typography2>

          <div style={{ marginRight: 10 }}></div>

          <a
            style={{
              color: Constants.COLOR_PRIMARY,
            }}
            href="javascript:void(0)"
            onClick={() =>
              props.onAddPracStepTip(idx, "New Title", "New Description")
            }
          >
            <STEPASSET
              onClick={() =>
                props.onAddPracStepTip(idx, "New Title", "New Description")
              }
              asset={
                <Tooltip title="Add Teacher Tip" placement="top">
                  <AddCommentIcon2 color="secondary" />
                </Tooltip>
              }
            />
          </a>

          <div style={{ marginRight: 10 }}></div>

          <PickImageDialog
            idx={idx}
            planSetup={planSetup}
            // image={resource.url}
            handleUpdatePlanSetup={(newPlanSetup) =>
              props.handleUpdatePlanSetup(newPlanSetup)
            }
            onClick={() => {
              props.onAddPracStepImage(
                idx,
                "https://images.unsplash.com/photo-1551782450-a2132b4ba21d"
              );
            }}
            asset={<AddPhotoAlternateIcon color="secondary" />}
            onAddPracStepImage={(idx, url) =>
              props.onAddPracStepImage(idx, url)
            }
          />

          <div style={{ marginRight: 10 }}></div>

          <PickImageDialog6
            idx={idx}
            planSetup={planSetup}
            // image={resource.url}
            handleUpdatePlanSetup={(newPlanSetup) =>
              props.handleUpdatePlanSetup(newPlanSetup)
            }
            // onClick={() => {
            //     props.onAddStepImage(idx, 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d')
            // }}
            onUpdatePicSource={(src) => onUpdatePicSource(src)}
            onAddStepVid={(idx, url) => props.onAddStepVid(idx, url)}
            type="video"
          />

          {/* <STEPASSET asset={<VideoCallIcon color="secondary" />} /> */}

          <div style={{ marginRight: 10 }}></div>
          <Tooltip title="Add Document" placement="top">
            <PickImageDialog7
              idx={idx}
              planSetup={planSetup}
              // image={resource.url}
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
              // onClick={() => {
              //     props.onAddStepImage(idx, 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d')
              // }}
              onUpdateDocPicSource={(src) => onUpdateDocPicSource(src)}
              onAddStepVid={(idx, url) => props.onAddStepVid(idx, url)}
              type="document"
            />
          </Tooltip>
          {/* <STEPASSET asset={<NoteAddIcon color="secondary" />} /> */}
          <div style={{ marginRight: 10 }}></div>
          {/* <Tooltip title="Delete item" placement="top">
              <DeleteForeverIcon color="red" />
            </Tooltip> */}
        </div>
      </>
    </div>
  );
}

const STEPLIST = (props) => {
  const { planSetup } = props;

  console.log(
    "CurriculumSetup.js STEPLIST planSetup " + JSON.stringify(planSetup)
  );

  return (
    <>
      {planSetup &&
        planSetup.application &&
        planSetup.application.morePractice ? ( // && planSetup.application.morePractice.hasSteps) ?
        <>
          <div
            style={{
              margin: 20,
            }}
          ></div>

          <HEADING value="Steps we will take" />
          <div
            style={{
              margin: 10,
            }}
          ></div>

          {/* iterate steps */}
          {planSetup.application.morePractice.steps.map((step, idx) => {
            return (
              <>
                <StepField2
                  num={idx + 1}
                  idx={idx}
                  planSetup={planSetup}
                  onAddPracStepClicked={() => props.onAddPracStepClicked()}
                  onDelPracStepClicked={(stepIdx) =>
                    props.onDelPracStepClicked(stepIdx)
                  }
                  onDeletePracStepVid={(stepIdx, idx) =>
                    props.onDeletePracStepVid(stepIdx, idx)
                  }
                  onAddPracStepVid={(stepIdx, url) =>
                    props.onAddPracStepVid(stepIdx, url)
                  }
                  onDeletePracStepDoc={(stepIdx, idx) =>
                    props.onDeletePracStepDoc(stepIdx, idx)
                  }
                  onAddPracStepDoc={(stepIdx, url) =>
                    props.onAddPracStepDoc(stepIdx, url)
                  }
                  onDeletePracStepImage={(stepIdx, idx) =>
                    props.onDeletePracStepImage(stepIdx, idx)
                  }
                  onAddPracStepImage={(stepIdx, url) =>
                    props.onAddPracStepImage(stepIdx, url)
                  }
                  onAddPracStepTip={(stepIdx, title, desc) =>
                    props.onAddPracStepTip(stepIdx, title, desc)
                  }
                  onDeletePracStepTip={(stepIdx, idx) =>
                    props.onDeletePracStepTip(stepIdx, idx)
                  }
                  handleUpdatePlanSetup={(newPlanSetup) =>
                    props.handleUpdatePlanSetup(newPlanSetup)
                  }
                />

                <div
                  style={{
                    margin: 20,
                  }}
                ></div>
              </>
            );
          })}
        </>
      ) : (
        <></>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            marginTop: 10,
            textAlign: "right",
          }}
        >
          <Button2
            variant="outlined"
            endIcon={<AddCircleOutlineIcon />}
            style={
              {
                // backgroundColor: Constants.Application.COLOR_PRIMARY
              }
            }
            onClick={() => props.onAddPracStepClicked()}
            sx={{
              color: "purple",
              backgroundColor: "white",
              borderColor: "purple",
              "&:hover": {
                borderColor: "purple",
                backgroundColor: "purple",
                color: "white",
              },
            }}
          >
            Add Step
          </Button2>
        </div>
      </div>
    </>
  );
};

const HomeworkImages = (props) => {
  const { images, planSetup } = props;

  console.log(
    "CurriculumSetup.js StepImageList images " + JSON.stringify(images)
  );

  return (
    <>
      {images && images.length ? (
        <ImageList sx={{ width: "100%", height: "auto" }} cols={3}>
          {/* {itemData.map((item) => ( */}
          {images.map((item, idx) => {
            return (
              <div
                style={{
                  position: "relative",
                }}
              >
                <ImageListItem key={item.img}>
                  <img
                    //src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    src={`${item.url}`}
                    // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    srcSet={`${item.url}`}
                    alt={""}
                    loading="lazy"
                  />
                </ImageListItem>
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}
                >
                  <DeleteForeverIcon
                    color="red"
                    style={{
                      color: "red",
                    }}
                    onClick={() => props.onDelHomeworkImage(idx)}
                  />
                </div>
              </div>
            );
          })}
          {/* ))} */}
        </ImageList>
      ) : (
        <>
          <Alert2 severity="info">No images added yet!</Alert2>
        </>
      )}
    </>
  );
};

function HOMEWORKIMAGELIST(props) {
  const { num, idx, planSetup } = props;
  const [showIt, setShowIt] = useState(false);

  const { images } = planSetup.application.homework;

  return (
    <div sx={{ minWidth: "100%" }}>
      <>
        {/* IMAGES */}

        <div
          style={{
            margin: 20,
          }}
        ></div>

        <HomeworkImages
          images={images}
          planSetup={planSetup}
          onDelHomeworkImage={(idx) => props.onDelHomeworkImage(idx)}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />

        {/* ICONS */}
        <div
          style={{
            marginTop: 10,
            display: "flex",
            flexDirection: "row",
            // textAlign: 'right'
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Tooltip title="Add Image" placement="top">
            <PickImageDialog5
              idx={idx}
              planSetup={planSetup}
              // image={resource.url}
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
              onClick={() => {
                props.onAddHomeworkImage(
                  idx,
                  "https://images.unsplash.com/photo-1551782450-a2132b4ba21d"
                );
              }}
              asset={<AddPhotoAlternateIcon color="secondary" />}
              onAddHomeworkImage={(idx, url) =>
                props.onAddHomeworkImage(idx, url)
              }
            />
          </Tooltip>
        </div>
      </>
    </div>
  );
}

const APPLICATIONLIST = (props) => {
  const { planSetup } = props;
  const { hasClasswork, hasMorePractice, hasHomework } = planSetup.application;

  const mapId = useSelector((state) => state.currMapId.currMapId);
  const query = new URLSearchParams(window.location.search);
  const instId = query.get("inst_id");
  const board_id = query.get("board_id");
  const clsStd = query.get("class_std");
  const subId = query.get("sub_id");
  let planId = 0;

  return (
    <Card2 sx={{ minWidth: "100%" }}>
      <CardContent2>
        <div
          style={{
            margin: 10,
          }}
        ></div>

        <BriefField
          name="Evaluate"
          planSetup={planSetup}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />
        <div
          style={{
            margin: 20,
          }}
        ></div>

        {hasClasswork ? (
          <>
            <HEADING2
              value={`CLASSWORK`}
              subValue={`Exercises from the textbook or additional notes as determined by the teacher.`}
            />

            <div
              style={{
                margin: 10,
              }}
            ></div>

            {/* classwork full section */}
            <CLASSWORKBOX
              num={1}
              name="Workbook"
              planSetup={planSetup}
              onAddClassworkQstTip={() => props.onAddClassworkQstTip()}
              onDelClassworkQTip={(index) => props.onDelClassworkQTip(index)}
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
              onAddPracStepClicked={() => props.onAddPracStepClicked()}
              onDelPracStepClicked={(stepIdx) =>
                props.onDelPracStepClicked(stepIdx)
              }
              onDelPracStepImage={(stepIdx, idx) =>
                props.onDelPracStepImage(stepIdx, idx)
              }
              onAddPracStepImage={(stepIdx, url) =>
                props.onAddPracStepImage(stepIdx, url)
              }
              onAddPracStepTip={(stepIdx, title, desc) =>
                props.onAddPracStepTip(stepIdx, title, desc)
              }
              onDeletePracStepTip={(stepIdx, idx) =>
                props.onDeletePracStepTip(stepIdx, idx)
              }
              topicId={props.topicId}
              chapId={props.chapId}
              unitId={props.unitId}
            />

            <div
              style={{
                margin: 20,
              }}
            ></div>
          </>
        ) : (
          <></>
        )}

        {hasMorePractice ? (
          <>
            {/* more practice full section */}
            <CLASSWORKBOX2
              num={2}
              name="More Practice"
              planSetup={planSetup}
              onAddPracStepClicked={() => props.onAddPracStepClicked()}
              onDelPracStepClicked={(stepIdx) =>
                props.onDelPracStepClicked(stepIdx)
              }
              onDeletePracStepImage={(stepIdx, idx) =>
                props.onDeletePracStepImage(stepIdx, idx)
              }
              onAddPracStepImage={(stepIdx, url) =>
                props.onAddPracStepImage(stepIdx, url)
              }
              onDeletePracStepVid={(stepIdx, idx) =>
                props.onDeletePracStepVid(stepIdx, idx)
              }
              onAddPracStepVid={(stepIdx, url) =>
                props.onAddPracStepVid(stepIdx, url)
              }
              onDeletePracStepDoc={(stepIdx, idx) =>
                props.onDeletePracStepDoc(stepIdx, idx)
              }
              onAddPracStepDoc={(stepIdx, url) =>
                props.onAddPracStepDoc(stepIdx, url)
              }
              onAddPracStepTip={(stepIdx, title, desc) =>
                props.onAddPracStepTip(stepIdx, title, desc)
              }
              onDeletePracStepTip={(stepIdx, idx) =>
                props.onDeletePracStepTip(stepIdx, idx)
              }
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
              handleMinsChange={(mins) => props.handleMinsChange(mins)}
              topicId={props.topicId}
              chapId={props.chapId}
              unitId={props.unitId}
            />

            <div
              style={{
                margin: 20,
              }}
            ></div>
          </>
        ) : (
          <></>
        )}

        {hasHomework ? (
          <>
            <HEADING value={`HOMEWORK`} />
            <div
              style={{
                margin: 10,
              }}
            ></div>

            <HOMEWORKBOX2
              planSetup={planSetup}
              num={1}
              name="Homework"
              onDelHomeworkImage={(idx) => props.onDelHomeworkImage(idx)}
              onAddHomeworkImage={() => props.onAddHomeworkImage()}
              onAddHomeworkQstTip={() => props.onAddHomeworkQstTip()}
              onDelHomeworkQTip={(index) => props.onDelHomeworkQTip(index)}
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
              topicId={props.topicId}
              chapId={props.chapId}
              unitId={props.unitId}
            />
          </>
        ) : (
          <></>
        )}
        {/* 
        <div
          style={{
            marginTop: 10,
            textAlign: 'left'
          }}>
          <Button variant="contained" endIcon={<AddCircleOutlineIcon />}>
            Add Part 4
          </Button>
        </div> */}
      </CardContent2>
    </Card2>
  );
};

export default APPLICATIONLIST;
