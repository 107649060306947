import axios from "axios";
import Constants from "../../resource/Constants";
import {
  CONTENTDETAILS_LIST_FAIL,
  CONTENTDETAILS_LIST_REQUEST,
  CONTENTDETAILS_LIST_SUCCESS,
  CURRICULUMMAODETAILS_LIST_SUCCESS,
  CURRICULUMMAPDETAILS_LIST_FAIL,
  CURRICULUMMAPDETAILS_LIST_REQUEST,
  CURRICULUMMAPDETAILS_LIST_SUCCESS,
  EBOOKDETAILS_LIST_FAIL,
  EBOOKDETAILS_LIST_REQUEST,
  EBOOKDETAILS_LIST_SUCCESS,
} from "../constants/GetAllFIlesConstants";
import Cookie from "js-cookie";
const TAG = 'GetAllFilesAction.js';

const listContentGet = () => async (dispatch) => {
  const ProvId = Cookie.get("kgtopg.guruculum.user.id");

  try {
    dispatch({ type: CONTENTDETAILS_LIST_REQUEST });

    const { data } = await axios.get(
      Constants.Application.PARTNER_END_URL +
        `/content-by-prov/?prov_id=${ProvId}`
    );

    console.log("listContentGet ======================", data);
    dispatch({ type: CONTENTDETAILS_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CONTENTDETAILS_LIST_FAIL, payload: error.message });
  }
};

const listEbooksGet = () => async (dispatch) => {
  const ProvId = Cookie.get("kgtopg.guruculum.user.id");

  try {
    dispatch({ type: EBOOKDETAILS_LIST_REQUEST });

    const { data } = await axios.get(
      Constants.Application.PARTNER_END_URL +
        `/book-by-pub/?publisher_id=${ProvId}`
    );
    console.log("listEbooksGet ======================", data);
    dispatch({ type: EBOOKDETAILS_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: EBOOKDETAILS_LIST_FAIL, payload: error.message });
  }
};

const listCurriculumAllGet = () => async (dispatch) => {
  // because this is set after this file is loaded
  const ProvId = Cookie.get("kgtopg.guruculum.user.id");

  try {
    dispatch({ type: CURRICULUMMAPDETAILS_LIST_REQUEST });

    const url =
      Constants.Application.PARTNER_END_URL +
      `/map-by-pub/?publisher_id=${ProvId}`;
    // const url =
    //   Constants.Application.PARTNER_END_URL +
    //   `/map-activity/?pub_id=${ProvId}&board_id=0&subject_id=0&classroom_std=0`;
    console.log(TAG, "listcurriculumallget url ", url);
    const { data } = await axios.get(url);
    const data2 = data.data;
    console.log(TAG, "listCurriculumAllGet ======================", data2);
    dispatch({ type: CURRICULUMMAPDETAILS_LIST_SUCCESS, payload: data2 });
  } catch (error) {
    dispatch({ type: CURRICULUMMAPDETAILS_LIST_FAIL, payload: error.message });
  }
};

export { listContentGet, listEbooksGet, listCurriculumAllGet };
