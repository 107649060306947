import { CONTTYPE_LIST_REQUEST,  CONTTYPE_LIST_SUCCESS,  CONTTYPE_LIST_FAIL } from "../constants/contentTypeConstants";


function ContTypeReducer(state = { contTypeDetails: [] }, action) {
    switch (action.type) {
        case CONTTYPE_LIST_REQUEST:
            return { loadingCont: true }
        case CONTTYPE_LIST_SUCCESS:
            return { loadingCont: false, contTypeDetails: action.payload };
        case CONTTYPE_LIST_FAIL:
            return { loadingCont: false, error: action.payload }
        default:
            return state;
    }

}

export { ContTypeReducer }