/**
 * actions for curriculum map redux
 */
export const CURRICULUMMAP_LIST_REQUEST ='CURRICULUMMAP_LIST_REQUEST'
export const CURRICULUMMAP_LIST_SUCCESS ='CURRICULUMMAP_LIST_SUCCESS'
export const CURRICULUMMAP_LIST_FAIL ='CURRICULUMMAP_LIST_FAIL'

export const CURRICULUMMAP_ADD_REQUEST = 'CURRICULUMMAP_ADD_REQUEST';
export const CURRICULUMMAP_ADD_SUCCESS = 'CURRICULUMMAP_ADD_SUCCESS';
export const CURRICULUMMAP_ADD_FAILURE = 'CURRICULUMMAP_ADD_FAILURE';

export const CURRICULUMMAP_CURR_REQUEST = 'CURRICULUMMAP_CURR_REQUEST';
export const CURRICULUMMAP_CURR_SUCCESS = 'CURRICULUMMAP_CURR_SUCCESS';
export const CURRICULUMMAP_CURR_FAILURE = 'CURRICULUMMAP_CURR_FAILURE';

export const FILTERED_LIST_REQUEST ='FILTERED_LIST_REQUEST'
export const FILTERED_LIST_SUCCESS ='FILTERED_LIST_SUCCESS'
export const FILTERED_LIST_FAIL ='FILTERED_LIST_FAIL'

export const CURRICULUMMAPFILTER_LIST_REQUEST ='CURRICULUMMAPFILTER_LIST_REQUEST'
export const CURRICULUMMAPFILTER_LIST_SUCCESS ='CURRICULUMMAPFILTER_LIST_SUCCESS'
export const CURRICULUMMAPFILTER_LIST_FAIL ='CURRICULUMMAPFILTER_LIST_FAIL'
