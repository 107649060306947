import React, { useEffect, useState, useRef } from "react";
import { Box, Container } from "@material-ui/core";
import JoditEditor from "jodit-react";

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import IconButton2 from "@mui/material/IconButton";
import DoneIcon2 from "@mui/icons-material/Done";
import Drawer2 from "@mui/material/Drawer";

import Card2 from "@mui/material/Card";
import CardActions2 from "@mui/material/CardActions";
import CardContent2 from "@mui/material/CardContent";
import Typography2 from "@mui/material/Typography";
import CardActionArea2 from "@mui/material/CardActionArea";
import Alert2 from "@mui/material/Alert";
import ListItem2 from "@mui/material/ListItem";
import ListItemButton2 from "@mui/material/ListItemButton";
import ListItemIcon2 from "@mui/material/ListItemIcon";
import ListItemText2 from "@mui/material/ListItemText";
import Checkbox2 from "@mui/material/Checkbox";
import ExpandLess2 from "@mui/icons-material/ExpandLess";
import Collapse2 from "@mui/material/Collapse";
import ExpandMore2 from "@mui/icons-material/ExpandMore";
import List2 from "@mui/material/List";
import Button2 from "@mui/material/Button";
import Divider2 from "@mui/material/Divider";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import QuizPlannerDrawer from "./QuizPlannerDrawer";
import MediaPlannerDrawer from "./MediaPlannerDrawer";
import ClearIcon from "@mui/icons-material/Clear";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip2 from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

import Select2 from "@mui/material/Select";
import MenuItem2 from "@mui/material/MenuItem";
import InputLabel2 from "@mui/material/InputLabel";
import NativeSelect2 from "@mui/material/NativeSelect";
import FormControl2 from "@mui/material/FormControl";

import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Constants from "../../../resource/Constants";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/dist/ProfilePicture.css";

import GPTAPI from '../../../http/gpt';
import GPTGenerator from "./GPTGenerator";

const TAG = 'AimList.js';
const config = {
  apiKey: Constants.Application.FIREBASE.API_KEY,
  authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
  //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
  storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET,
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}

const UploadBox = (props) => {
  const { planSetup, idx } = props;
  const profilePictureRef = React.createRef();
  const [percent, setPercent] = useState(0);

  async function srcToFile(src, fileName, mimeType) {
    return await fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        console.log("AimList.js UploadBox srcToFile buf " + buf);

        const imageFile = new File([buf], fileName, { type: mimeType });
        handleUpload1(imageFile);
      });
  }

  const handleUpload1 = (imageFile) => {
    //add here the upload logic...
    if (!imageFile) {
      alert("AimList.js UploadBox handleUpload1 Image is invalid!");
      return;
    }

    const storageRef = firebase.storage().ref(`/files/${imageFile.name}`); //.child(imageFile.name);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    // const uploadTask = uploadBytesResumable(storageRef, file);
    const uploadTask = storageRef.put(imageFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => {
        console.log("CurriculumSetup.js BANNERBOX handleUpload: error " + err);
      },
      () => {
        // download url
        storageRef
          .getDownloadURL() //uploadTask.snapshot.ref).then((url) => {
          .then(async (firebaseUrl) => {
            console.log(
              "CurriculumSetup.js BANNERBOX handleUpload: firebaseUrl " +
              firebaseUrl
            );
            // update plansetup current resource image
            let sPlanSetup = JSON.parse(JSON.stringify(planSetup));

            sPlanSetup.aim.resourceList[idx].url = firebaseUrl;
            sPlanSetup.aim.resourceList[idx].asset_url = firebaseUrl;
            props.handleUpdatePlanSetup(sPlanSetup);

            props.onClose();
          })
          .catch((e) => {
            console.log(
              "CurriculumSetup.js BANNERBOX handleUpload: exception " + e
            );
          });
      }
    );
  };

  const handleUpload = async () => {
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const imageFile = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();

    console.log("AimList.js UploadBox handleUpload PP " + PP);
    console.log("AimList.js UploadBox handleUpload imageData " + imageData);
    console.log("AimList.js UploadBox handleUpload file " + imageFile);
    console.log(
      "AimList.js UploadBox handleUpload imageAsDataURL " + imageAsDataURL
    );

    let type = imageAsDataURL.substring(
      imageAsDataURL.indexOf(":") + 1,
      imageAsDataURL.indexOf(";")
    ); // => image/png
    console.log("AimList.js UploadBox handleUpload callback: type " + type);

    let fileName = makeid(10);

    if (type == "image/jpeg") fileName = fileName + ".jpg";
    else if (type == "image/png") fileName = fileName + ".png";
    else if (type == "image/gif") fileName = fileName + ".gif";
    else fileName = fileName + ".jpg";

    console.log(
      "AimList.js UploadBox handleUpload callback: fileName " + fileName
    );

    // now upload to firebase and set the image url ready
    // convert to file
    // const imageBlob = dataURItoBlob(newDataUri);

    // console.log('CurriculumSetup.js BANNERBOX imageChanged callback: imageBlob ' + JSON.stringify(imageBlob));
    // const imageFile = dataURLtoFile(newDataUri, fileName);
    // fetch(newDataUri)
    //   .then(res => res.blob())
    //   .then(blob => {
    //     const imageFile = new File([blob], fileName)

    //   })

    // const imageFile = new File([imageBlob], imageBlob.name, {
    //   type: imageBlob.type,
    // });

    const imageFile2 = await srcToFile(imageAsDataURL, fileName, type);
  };

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  return (
    <>
      <div
        style={
          {
            // height: 200
          }
        }
      >
        <ProfilePicture ref={profilePictureRef} useHelper={true} debug={true} />

        <div
          style={{
            alignSelf: "right",
          }}
        >
          <Button2 onClick={handleUpload}>Upload</Button2>
        </div>
      </div>
    </>
  );
};

function PickImageDialog(props) {
  const { planSetup, idx, image } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const imgUrl = image
    ? image
    : "https://t3.ftcdn.net/jpg/04/34/72/82/360_F_434728286_OWQQvAFoXZLdGHlObozsolNeuSxhpr84.jpg";

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}> */}
      <div
        style={{
          border: "1px #DCDCDC solid",
          borderRadius: 10,
          width: 80,
          height: 80,
          background: 'url("' + imgUrl + '")',
          backgroundSize: "contain",
          backgroundPosition: "center",
        }}
        onClick={handleClickOpen}
      >
        {/* <img 
            src="https://media.gettyimages.com/photos/green-cornfield-ready-for-harvest-late-afternoon-light-sunset-picture-id168351414?s=612x612" 
            width={100}
            height={100}
            /> */}
      </div>
      {/* </Button> */}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload picture</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose the picture, and crop it to your desire
          </DialogContentText>
          <UploadBox
            idx={idx}
            planSetup={planSetup}
            handleUpdatePlanSetup={(newPlanSetup) =>
              props.handleUpdatePlanSetup(newPlanSetup)
            }
            onClose={handleClose}
          />
        </DialogContent>
        {/* <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose}>UPLOAD</Button>
                </DialogActions> */}
      </Dialog>
    </div>
  );
}

const ResourceItem = (props) => {
  const { num, idx, planSetup } = props;

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const onContentChanged = (newContent) => {
    setContent(newContent);

    handleChange2(newContent);
  };

  // update the aim resource by index
  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("CurriculumSetup.js ResourceItem handleChange name " + name);
    console.log("CurriculumSetup.js ResourceItem handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.aim.resourceList[idx].title = value;

    newPlanSetup.aim.resourceList[idx].description = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };
  const handleChange2 = (value) => {
    console.log("CurriculumSetup.js ResourceItem handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.aim.resourceList[idx].title = value;

    newPlanSetup.aim.resourceList[idx].description = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const resource = planSetup.aim.resourceList[idx];

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 5
          }}
        >
          <SUBHEADING value={`Resource ${num}`} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}>
            <GPTGenerator
              handleUpdateGPTText={(text) => handleChange2(text)}
              prompt='a single resource we will need for outcome'
              planSetup={planSetup}
            />

            <ClearIcon
              color="warning"
              style={{
                cursor: "pointer",
              }}
              onClick={() => props.onDeleteResourceItem(idx)}
            />
          </div>

        </div>

        <Box
          sx={{
            // width: 500,
            maxWidth: "90%",
          }}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {/* <TextField fullWidth label="Enter the text"
                        placeholder="Enter the text here" id="fullWidth"
                        variant="outlined"
                        value={resource.description}
                        rows={2}
                        multiline
                        sx={{
                            width: '85%'
                        }}
                        style={{
                            width: '85%'
                        }}
                        onChange={handleChange}
                    /> */}

          <JoditEditor
            ref={editor}
            value={resource.description}
            tabIndex={1}
            onChange={onContentChanged}
            height="150px"
            style={{
              height: '150px !important'
            }}
          />

          <div
            sx={{
              width: "14%",
              display: "flex",
              justifyContent: "flex-end",
            }}
            style={{
              width: "19%",
              border: "0px #DCDCDC solid",
              padding: 10,
              // height: 200
            }}
          >
            {/* <AddPhotoAlternateIcon
                  size={128}
                  fontSize="large"
                /> */}

            <PickImageDialog
              idx={idx}
              planSetup={planSetup}
              image={resource.url}
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
            />
          </div>
        </Box>
      </div>
    </>
  );
};

function ResourceField(props) {
  const { planSetup } = props;

  return (
    <div sx={{ minWidth: "100%" }}>
      <>
        <>
          {planSetup &&
            planSetup.aim &&
            planSetup.aim.hasResource &&
            planSetup.aim.resourceList &&
            planSetup.aim.resourceList.length ? (
            <>
              {planSetup.aim.resourceList.map((res, idx2) => {
                return (
                  <>
                    <ResourceItem
                      num={idx2 + 1}
                      idx={idx2}
                      key={idx2}
                      planSetup={planSetup}
                      onDeleteResourceItem={() =>
                        props.onDeleteResourceItem(idx2)
                      }
                      handleUpdatePlanSetup={(newPlanSetup) =>
                        props.handleUpdatePlanSetup(newPlanSetup)
                      }
                    />
                    <div
                      style={{
                        margin: 10,
                      }}
                    ></div>
                  </>
                );
              })}
              {/* <ResourceItem num={2} /> */}
            </>
          ) : (
            <>
              <Alert2 variant="outlined" severity="warning">
                No resources added! Add now..
              </Alert2>
            </>
          )}

          {/* add resource button */}
          <div
            style={{
              marginTop: 10,
              textAlign: "left",
            }}
          >
            <Button2
              variant="outlined"
              endIcon={<AddCircleOutlineIcon />}
              style={
                {
                  // backgroundColor: Constants.Application.COLOR_PRIMARY
                }
              }
              onClick={() => props.onAddResourceClicked()} // add a new resource block
              sx={{
                color: "purple",
                backgroundColor: "white",
                borderColor: "purple",
                "&:hover": {
                  borderColor: "purple",
                  backgroundColor: "purple",
                  color: "white",
                },
              }}
            // color='#5664d2'
            >
              Add Resource
            </Button2>
          </div>
        </>
      </>
    </div>
  );
}

const KEYWORDLIST = (props) => {
  const { planSetup } = props;

  return (
    <div sx={{ minWidth: "100%" }}>
      <>
        {planSetup &&
          planSetup.aim &&
          planSetup.aim.hasKeyword &&
          planSetup.aim.keywordList &&
          planSetup.aim.keywordList.length ? (
          <>
            {planSetup.aim.keywordList.map((res, idx2) => {
              return (
                <>
                  <KeywordItem
                    keyw={res}
                    num={idx2 + 1}
                    idx={idx2}
                    key={idx2}
                    onDeleteAimKeywItem={() => props.onDeleteAimKeywItem(idx2)}
                    planSetup={planSetup}
                    handleUpdatePlanSetup={(newPlanSetup) =>
                      props.handleUpdatePlanSetup(newPlanSetup)
                    }
                  />

                  <div
                    style={{
                      margin: 20,
                    }}
                  ></div>
                </>
              );
            })}
            {/* <ResourceItem num={2} /> */}
          </>
        ) : (
          <>
            <Alert2 variant="filled" severity="warning">
              No keywords added! Add now..
            </Alert2>
          </>
        )}
        {/* 
          <KeywordItem /> */}

        <div
          style={{
            marginLeft: 5,
            marginTop: 20,
            textAlign: "left",
          }}
        >
          <Button2
            variant="outlined"
            endIcon={<AddCircleOutlineIcon />}
            style={
              {
                // backgroundColor: Constants.Application.COLOR_PRIMARY
              }
            }
            // color='#5664d2'
            onClick={() => props.onAddAimKeywClicked()}
            sx={{
              color: "purple",
              backgroundColor: "white",
              borderColor: "purple",
              "&:hover": {
                borderColor: "purple",
                backgroundColor: "purple",
                color: "white",
              },
            }}
          >
            Add Keyword
          </Button2>
        </div>
      </>
    </div>
  );
};

function KeywordItem(props) {
  const { idx, planSetup, keyw } = props;

  const handleChangeTitle = (event) => {
    const { name, value } = event.target;
    console.log(
      "CurriculumSetup.js KeywordItem handleChangeTitle name " + name
    );
    console.log(
      "CurriculumSetup.js KeywordItem handleChangeTitle value " + value
    );
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    let keyword = newPlanSetup.aim.keywordList[idx];
    keyword.title = value;
    keyword.name = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const handleChangeTitle2 = (value) => {
    console.log(TAG, 'handleChangeTitle2 value ', value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    let keyword = newPlanSetup.aim.keywordList[idx];
    keyword.title = value;
    keyword.name = value;

  }

  const handleChangeDesc = (event) => {
    const { name, value } = event.target;
    console.log("CurriculumSetup.js KeywordItem handleChangeDesc name " + name);
    console.log(
      "CurriculumSetup.js KeywordItem handleChangeDesc value " + value
    );
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    let keyword = newPlanSetup.aim.keywordList[idx];

    keyword.description = value;
    props.handleUpdatePlanSetup(newPlanSetup); 

  };

  const handleChangeBoth = (title2, desc2) => {
    console.log(TAG, 'handleChangeDesc2 title2 ', title2);
    console.log(TAG, 'handleChangeDesc2 desc2 ', desc2);

    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    let keyword = newPlanSetup.aim.keywordList[idx];

    keyword.title = title2;
    keyword.name = title2;

    keyword.description = desc2;
    props.handleUpdatePlanSetup(newPlanSetup); 
  }

  console.log(TAG, 'KeywordItem keyw ', keyw);

  let nameVal = keyw.name;
  let descVal = keyw.description;

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%'
          }}>
          <SUBHEADING value="Add Keyword" />

          <div>
            <GPTGenerator
              handleUpdateGPTText={(gptText) => {
                let keywArr = gptText.split(':');
                console.log(TAG, 'GPTGenerator handleUpdateGPTText: keywArr ', keywArr);
                let title2 = keywArr[0].replace(/^\n+/, '');
                let name2 = keywArr[0].replace(/^\n+/, '');
                let description2 = keywArr[1];

                console.log(TAG, 'handleUpdateGPTText title2 ', title2);
                console.log(TAG, 'handleUpdateGPTText description2 ', description2);

                // change both and update
                handleChangeBoth(title2, description2);
              }}
              prompt="a single new unique keyword and it's description separated by colon, that we will need for outcome"
              planSetup={planSetup}
            />
            <ClearIcon
              color="warning"
              style={{
                cursor: "pointer",
              }}
              onClick={() => props.onDeleteAimKeywItem(idx)}
            />
          </div>
        </div>
      </div>

      <Box
        sx={{
          // width: 500,
          maxWidth: "100%",
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "99%",
            marginBottom: 10,
          }}
        >
          <TextField
            value={nameVal}
            fullWidth
            label="Enter the keyword title"
            placeholder="Enter the keyword title"
            variant="outlined"
            onChange={handleChangeTitle}
          />
        </Box>

        <Box
          sx={{
            width: "99%",
          }}
        >
          <TextField
            value={descVal}
            fullWidth
            label="Enter the keyword description"
            placeholder="Enter the keyword description"
            id="outlined-multiline-static"
            multiline
            rows={4}
            maxRows={4}
            variant="outlined"
            onChange={handleChangeDesc}
          />
        </Box>
      </Box>
    </div>
  );
}

const HEADING = (props) => {
  const { value } = props;

  return (
    <>
      <Typography variant="h4">{value}</Typography>
    </>
  );
};

const SUBHEADING = (props) => {
  const { value } = props;

  return (
    <>
      <Typography variant="subtitle1">{value}</Typography>
    </>
  );
};

function BriefField(props) {

  // get criteria from localstorage
  const CRIT = Constants.Application.LESSONPLAN_CRITERIA;
  let criter = localStorage.getItem(CRIT, '');
  if (!criter)
    criter = '';

  const { name, planSetup } = props;
  const [outcomeText, setOutcomeText] = useState(localStorage.getItem(CRIT));
  const [loading, setLoading] = React.useState(false);

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const onContentChanged = (newContent) => {
    setContent(newContent);

    handleChange2(newContent);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("AimList.js BriefField handleChange name " + name);
    console.log("AimList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.aim.summary = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };
  const handleChange2 = (value) => {
    console.log("AimList.js BriefField handleChange name " + name);
    console.log("AimList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.aim.summary = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const handleChange3 = (event) => {
    const { name, value } = event.target;
    console.log("AimList.js BriefField handleChange3 name " + name);
    console.log("AimList.js BriefField handleChange3 value " + value);

    // set value in cookie
    setOutcomeText(value)
    localStorage.setItem(CRIT, value);
  };

  const handleGPTGenerate = async () => {
    let sSummary = '';

    const body = {
      board: 'CBSE',
      subject: 'Science',
      grade: 10,
      chapter: 'Ligh - Reflection and refraction',
      topic: 'Reflection of light',
      sub_topic: 'Laws of reflection',
      pedagogy_text: 'one sentence summary of aim',
      process: true
    }

    console.log(TAG, 'handleGPTGenerate body ', JSON.stringify(body));
    setLoading(true);

    const resp = await GPTAPI.generate(body);

    if (resp && resp.data) {
      sSummary = resp.data.choices[0].text;
    }

    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.aim.summary = sSummary;
    props.handleUpdatePlanSetup(newPlanSetup);

    setLoading(false);
  }

  const { aim } = planSetup;

  return (
    <div sx={{ minWidth: "100%" }}>
      <>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 5
            }}
          >
            <SUBHEADING value={`Teacher Summary for ${name}`} />

            {/* GPT Button */}
            {/* <Button2
              variant="outlined"
              color="primary"
              size="small"
              disabled={loading}
              onClick={handleGPTGenerate}>
              {(loading) ? 'Generating' : 'GPT'}
            </Button2> */}

            {/*
            <TextField
              fullWidth
              label='Learning outcome criteria'
              placeholder="Enter the text here"
              id="fullWidth3"
              variant="outlined"
              onChange={handleChange3}
              defaultValue={outcomeText}
              inputProps={{
                maxLength: 200,
              }}
              style={{
                width: '60%'
              }}
            />
*/}
            <GPTGenerator
              handleUpdateGPTText={(text) => handleChange2(text)}
              prompt='one sentence summary of outcome'
              planSetup={planSetup}
            />
          </div>

          <Box
            sx={{
              // width: 500,
              maxWidth: "100%",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              label={(loading) ? 'Generating..' : ".."}
              placeholder="Enter the text here"
              id="fullWidth"
              variant="outlined"
              onChange={handleChange}
              value={aim.summary}
              inputProps={{
                maxLength: 500,
              }}
            />
            {/* 
                        <JoditEditor
                            ref={editor}
                            value={aim.summary}
                            tabIndex={1}
                            onChange={onContentChanged}
                        /> */}
          </Box>
        </div>
      </>
    </div>
  );
}

const HEADING2 = (props) => {
  const { value, subValue } = props;

  return (
    <>
      <div
        className="d-flex flex-row justify-content-start align-items-center"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">{value}</Typography>

        <div
          style={{
            marginRight: 15,
          }}
        ></div>

        <Typography variant="body2">{subValue}</Typography>
      </div>
    </>
  );
};

/* learning outcome */
function BriefField3(props) {
  const { name, planSetup } = props;

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const onContentChanged = (newContent) => {
    setContent(newContent);

    handleChange2(newContent);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("ActionList.js BriefField handleChange name " + name);
    console.log("ActionList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.aim.learning_outcome = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const handleChange2 = (value) => {
    console.log("ActionList.js BriefField handleChange name " + name);
    console.log("ActionList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.aim.learning_outcome = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const val = planSetup.aim.learning_outcome;

  return (
    <div sx={{ minWidth: "100%" }}>
      <>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SUBHEADING value={`Learning outcome`} />
          </div>

          <Box
            sx={{
              // width: 500,
              maxWidth: "90%",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/* <TextField
                            defaultValue={val}
                            fullWidth label="Enter summary"
                            placeholder="Enter the text here" id="fullWidth"
                            variant="standard"
                            onChange={handleChange}
                            inputProps={{
                                maxLength: 500,
                            }}
                        />  */}

            <JoditEditor
              ref={editor}
              value={val}
              tabIndex={1}
              onChange={onContentChanged}
            />
          </Box>
        </div>
      </>
    </div>
  );
}

function HeadingField(props) {
  const { name, zIndex, planSetup } = props;
  const [loading, setLoading] = React.useState(false);

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const onContentChanged = (newContent) => {
    setContent(newContent);

    handleChange2(newContent);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("AimList.js HeadingField handleChange name " + name);
    console.log("AimList.js HeadingField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.aim.description = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };
  const handleChange2 = (value) => {
    console.log("AimList.js HeadingField handleChange name " + name);
    console.log("AimList.js HeadingField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.aim.description = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const handleGPTGenerate = async () => {
    let gptText = '';

    const body = {
      board: 'CBSE',
      subject: 'Science',
      grade: 10,
      chapter: 'Ligh - Reflection and refraction',
      topic: 'Reflection of light',
      sub_topic: 'Laws of reflection',
      pedagogy_text: 'description for learning outcome',
      process: true
    }

    console.log(TAG, 'handleGPTGenerate body ', JSON.stringify(body));
    setLoading(true);

    const resp = await GPTAPI.generate(body);

    if (resp && resp.data) {
      gptText = resp.data.choices[0].text;
    }

    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.aim.description = gptText;
    props.handleUpdatePlanSetup(newPlanSetup);

    setLoading(false);
  }

  const { aim } = planSetup;

  return (
    <div sx={{ minWidth: "100%" }} style={{}}>
      <>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 5
            }}
          >
            <SUBHEADING value={`Description for ${name}`} />


            {/* GPT Button */}
            {/* <Button2
              variant="outlined"
              color="primary"
              size="small"
              disabled={loading}
              onClick={handleGPTGenerate}>
              {(loading) ? 'Generating' : 'GPT'}
            </Button2> */}

            <GPTGenerator
              handleUpdateGPTText={(text) => handleChange2(text)}
              prompt='description for learning outcome'
              planSetup={planSetup}
            />
          </div>

          <Box
            sx={{
              // width: 500,
              maxWidth: "100%",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/* <TextField fullWidth label="Enter description"
                            placeholder="Enter the text here" id="fullWidth"
                            multiline
                            rows={4}
                            variant="outlined"
                            onChange={handleChange}
                            value={aim.description}
                        /> */}

            <JoditEditor
              ref={editor}
              value={aim.description}
              tabIndex={1}
              onChange={onContentChanged}
            />
          </Box>
        </div>
      </>
    </div>
  );
}

const AIMLIST = (props) => {
  const { planSetup } = props;

  console.log(
    "CurriculumSetup.js AIMLIST planSetup " + JSON.stringify(planSetup)
  );

  return (
    <>
      <Card2 sx={{ minWidth: "100%" }}>
        <CardContent2>
          <div
            style={{
              marginBottom: 10,
            }}
          ></div>

          <BriefField
            name="Outcome"
            planSetup={planSetup}
            handleUpdatePlanSetup={(newPlanSetup) =>
              props.handleUpdatePlanSetup(newPlanSetup)
            }
          />

          <div
            style={{
              margin: 20,
            }}
          ></div>

          {/* description */}

          <HEADING2
            value="Learning Outcomes"
            subValue="Learning Outcome is the learning goal that the learners will achieve by the end of today’s lesson."
          />
          <HeadingField
            name="Learning Outcome"
            planSetup={planSetup}
            handleUpdatePlanSetup={(newPlanSetup) =>
              props.handleUpdatePlanSetup(newPlanSetup)
            }
          />

          <div
            style={{
              marginBottom: 5,
            }}
          ></div>

          {/* learning outcomes */}
          {/* 
          <BriefField3
            name="Learning Outcome"
            planSetup={planSetup}
            handleUpdatePlanSetup={(newPlanSetup) =>
              props.handleUpdatePlanSetup(newPlanSetup)
            }
          /> */}

          {planSetup && planSetup.aim && planSetup.aim.hasResource ? (
            <>
              <div
                style={{
                  margin: 20,
                }}
              ></div>

              <HEADING value="Resources we will need" />
              <div
                style={{
                  margin: 10,
                }}
              ></div>

              <ResourceField
                planSetup={planSetup}
                onAddResourceClicked={() => props.onAddResourceClicked()}
                onDeleteResourceItem={(idx) => props.onDeleteResourceItem(idx)}
                handleUpdatePlanSetup={(newPlanSetup) =>
                  props.handleUpdatePlanSetup(newPlanSetup)
                }
              />
            </>
          ) : (
            <></>
          )}

          {planSetup && planSetup.aim && planSetup.aim.hasKeyword ? (
            <>
              <div
                style={{
                  margin: 20,
                }}
              ></div>

              <HEADING value="Keywords we will use" />
              <div
                style={{
                  margin: 10,
                }}
              ></div>

              <KEYWORDLIST
                planSetup={planSetup}
                onAddAimKeywClicked={() => props.onAddAimKeywClicked()}
                onDeleteAimKeywItem={(idx) => props.onDeleteAimKeywItem(idx)}
                handleUpdatePlanSetup={(newPlanSetup) =>
                  props.handleUpdatePlanSetup(newPlanSetup)
                }
              />
            </>
          ) : (
            <></>
          )}
        </CardContent2>
      </Card2>
    </>
  );
};

export default AIMLIST;
