import axios from "axios";
import Constants from "../../resource/Constants";
import { SUBSUBDETAILS_LIST_FAIL, SUBSUBDETAILS_LIST_REQUEST, SUBSUBDETAILS_LIST_SUCCESS } from "../constants/subSubjectConstants";




const listSubSubGet = (subId) => async (dispatch) => {
    console.log("subjects+++++++++++++++++++++++++++++++++++++++++++++++++", subId)
    try {
        dispatch({ type: SUBSUBDETAILS_LIST_REQUEST })

        const ssUrl = Constants.Application.PLATFORM_END_URL + `/subject-sub/?subject_id=${subId}`
        console.log('subsubjects url ' + ssUrl)
        const { data } = await axios.get(ssUrl)
        console.log("data in list subsubjects  get", data)
        dispatch({ type: SUBSUBDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: SUBSUBDETAILS_LIST_FAIL, payload: error.message })

    }
}


export { listSubSubGet }