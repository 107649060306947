/**
 * setup action creators for current map unit lesson
 */

export const LESSON_CURR_REQUEST = 'LESSON_CURR_REQUEST';
export const LESSON_CURR_SUCCESS = 'LESSON_CURR_SUCCESS';
export const LESSON_CURR_FAILURE = 'LESSON_CURR_FAILURE';

// action creators
export const getCurrLesson = () => ({ // already returns
    type: LESSON_CURR_REQUEST
})

export const getCurrLessonSuccess = (data) => ({
    type: LESSON_CURR_SUCCESS,
    payload: data
})

export const getCurrLessonFailure = () => ({
    type: LESSON_CURR_FAILURE
})

// async thunk action to update
export function updateCurrLesson(data) {

    console.log('redux currLessonActions.js updateCurrLesson: called')
    return async (dispatch) => {
        dispatch(getCurrLesson());

        try {
            // update data
            dispatch(getCurrLessonSuccess(data))

            // cleanup
        } catch (error) {
            dispatch(getCurrLessonFailure())
        }

    }
}
