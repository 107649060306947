/**
 * setup action creators for current map unit lesson plan
 */

export const LESSON_PLAN_CURR_REQUEST = 'LESSON_PLAN_CURR_REQUEST';
export const LESSON_PLAN_CURR_SUCCESS = 'LESSON_PLAN_CURR_SUCCESS';
export const LESSON_PLAN_CURR_FAILURE = 'LESSON_PLAN_CURR_FAILURE';

// action creators
export const getCurrLessonPlan = () => ({ // already returns
    type: LESSON_PLAN_CURR_REQUEST
})

export const getCurrLessonPlanSuccess = (data) => ({
    type: LESSON_PLAN_CURR_SUCCESS,
    payload: data
})

export const getCurrLessonPlanFailure = () => ({
    type: LESSON_PLAN_CURR_FAILURE
})

// async thunk action to update
export function updateCurrLessonPlan(data) {
    return async (dispatch) => {
        dispatch(getCurrLessonPlan());

        try {
            // update data
            dispatch(getCurrLessonPlanSuccess(data))

            // cleanup
        } catch (error) {
            dispatch(getCurrLessonPlanFailure())
        }

    }
}
