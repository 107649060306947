import {
    PROFILEDETAILSPUB_LIST_REQUEST,
    PROFILEDETAILSPUB_LIST_SUCCESS,
    PROFILEDETAILSPUB_LIST_FAIL,
    PROFILEDETAILSCONT_LIST_REQUEST,
    PROFILEDETAILSCONT_LIST_SUCCESS,
    PROFILEDETAILSCONT_LIST_FAIL,
} from "../constants/ProfileConstant";

function profileDetailsPubReducer(state ={PubProDetails:[]},action) {

    switch (action.type) {
        case PROFILEDETAILSPUB_LIST_REQUEST:
            return { loadingPubPro: true }
        case PROFILEDETAILSPUB_LIST_SUCCESS:
            return {loadingPubPro:false,PubProDetails:action.payload};
        case PROFILEDETAILSPUB_LIST_FAIL:
            return {loadingPubPro:false,errorPubPro:action.payload}
        default:
            return state;
    }

}


function profileDetailsContReducer(state ={ContProDetails:[]},action) {

    switch (action.type) {
        case PROFILEDETAILSCONT_LIST_REQUEST:
            return { loadingContPro: true }
        case PROFILEDETAILSCONT_LIST_SUCCESS:
            return {loadingContPro:false,ContProDetails:action.payload};
        case PROFILEDETAILSCONT_LIST_FAIL:
            return {loadingContPro:false,errorContPro:action.payload}
        default:
            return state;
    }

}

export { profileDetailsPubReducer ,profileDetailsContReducer}