import React, { useEffect, useState, useRef } from "react";
import Button2 from "@mui/material/Button";
import RefreshIcon from '@mui/icons-material/Refresh';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import GPTAPI from '../../../http/gpt';
import Constants from "../../../resource/Constants";
import { Tooltip } from "@material-ui/core";

const TAG = 'GPTGenerator.js';

const GPTGenerator = (props) => {
    const { prompt, planSetup, noOutcome } = props;
    const { namesDict, clsStd } = planSetup;
    const { chapter_name, topic_name, topic_sub_name, subject_sub_name, subject_name, board_name } = namesDict;

    const [loading, setLoading] = React.useState(false);

    const CRIT = Constants.Application.LESSONPLAN_CRITERIA;
    let criter = localStorage.getItem(CRIT, '');
    if (!criter)
        criter = '';
    const [criter2, setCriter2] = useState(criter);

    console.log(TAG, 'planSetup ', planSetup);

    const updateCriter = () => {
        let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
        const { aim, action, analysis, application, assessment, lessonPlanSetup } = sPlanSetup;

        // update criteria, now from the lesson plan description
        const { plan_description } = lessonPlanSetup;

        if (plan_description) {
            // setCriter2(plan_description);
        }
    }

    useEffect(() => {
        updateCriter();

        return {

        }
    }, [])

    const handleGPTGenerate = async () => {
        let gptText = '';
        criter = criter2;
        props.handleUpdateGPTText('Generating.. please wait..'); // clear first

        // attach the learning outcome..
        let newPrompt = ``;
        if(!noOutcome)
            newPrompt = `without mentioning grade, standard and subject again, give me only '${prompt}' part of a lesson plan for the learning outcome '${criter}', in brief`
        else
            // negate outcome
            newPrompt = `without mentioning grade, standard and subject again, give me only '${prompt}' part of a lesson plan, in brief`


        const body = {
            board: namesDict.board_name,
            subject: namesDict.subject_sub_name,
            grade: clsStd,
            chapter: chapter_name,
            topic: topic_name,
            sub_topic: topic_sub_name,
            pedagogy_text: newPrompt,
            process: true
        }

        console.log(TAG, 'handleGPTGenerate body ', JSON.stringify(body));
        setLoading(true);

        const resp = await GPTAPI.generate(body);

        if (resp && resp.data && resp.data.choices) {
            if (resp.data.choices.length && resp.data.choices[0].text)
                gptText = resp.data.choices[0].text;
        }

        props.handleUpdateGPTText(gptText);
        setLoading(false);
    }

    return (
        <Tooltip title="Re-generate">
            <Button2
                variant="outlined"
                color="primary"
                size="small"
                disabled={loading}
                onClick={handleGPTGenerate}>
                {(loading) ? 'Generating' : <AutorenewIcon />}
            </Button2>
        </Tooltip>
    )

}

export default GPTGenerator;
