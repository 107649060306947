import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LOGO from '../../assets/PustakLogo.png';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import Copyright from './slots/Copyright'
import guruculum from "../../assets/logoguruc.png";

function Copyright2() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Pustak.co
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const logo = guruculum

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function ThankyouSignUp() {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>

                <img src={logo} alt="Logo" height={160} />


                <form className={classes.form} noValidate>
                    <Grid container spacing={2}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: 20
                        }}>

                    </Grid>



                    <Grid container justify="flex-end"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: 20,
                        }}>
                            <Typography
                                variant="h2"
                                style={{
                                    marginRight: 20
                                }}>
                                Thank you!
                            </Typography>

                            <ThumbUpAltIcon style={{ color: 'green' }} />

                        </div>

                        <Typography
                            variant="body1">
                            Thank you for choosing us. One of our experts will get in touch with you.
                        </Typography>
                        <Typography
                            variant="body2"
                            style={{
                                marginTop: 20
                            }}>
                            We appreciate your cooperation.
                        </Typography>

                        <Grid item
                            style={{
                                marginTop: 20
                            }}>
                            <Link href="#" variant="body2">
                                Go back home
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
}