import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';

// class ColorPicker extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { 
//       background: '#ecf1f5',
//      }
//   }

//   handleChangeComplete = (color) => {
//     this.setState({ background: color.hex});
//     console.log("color picked",color)
//   };

//   render(){
//     return (
//       <div className="App ">
//         <ChromePicker color={ this.state.background }onChange={ this.handleChangeComplete }/>
//       </div>
//     );}
// }

function ColorPicker(props){
    const [bgColor,setbgColor] = useState('#ecf1f5')


    const handleChangeComplete = (color) => {
        setbgColor(color.hex)
        props.colorPickCode(color.hex)
        console.log("color Picked color",color.hex)
    }
    useEffect(()=>{
        props.colorPickCode(bgColor)
        return(()=>{
            //
        })

    },[])
    return(
        <div className="App">
            <ChromePicker color={bgColor} onChange={(color) => handleChangeComplete(color)}/>

        </div>
    )
}

export default ColorPicker;