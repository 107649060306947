/***
 * Content list table ScreenI
 */

/**Import start here  */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";

//table impots
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Box from "@material-ui/core/Box";
import Menu from '@material-ui/core/Menu';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { blue } from '@material-ui/core/colors';

import DeleteIcon from "@material-ui/icons/Delete";
import { Divider, Paper } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import Drawer from "@material-ui/core/Drawer";
import UploadContent from "./slots/UploadContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import AudiotrackOutlinedIcon from "@material-ui/icons/AudiotrackOutlined";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

//Api's
import API from "../../http/http";
import PARTNERAPI from "../../http/httppartner";
import PUBLISHERAPI from "../../http/publisher";

// redux imports
import { connect, useDispatch, useSelector } from "react-redux";
import { listBoardGet } from "../../redux/actions/boardDetailsAction";
import { listInstGet } from "../../redux/actions/InstituteDetailsAction";
import { listSubGet } from "../../redux/actions/subjectsAction";
import { listStdGet } from "../../redux/actions/standardAction";
import {
  fetchContent,
  searchContent,
} from "../../redux/actions/contentActions";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { More, Title } from "@material-ui/icons";
import { listChpGet } from "../../redux/actions/chapterAction";
import Cookie from "js-cookie";
import Copyright from "../user/slots/Copyright";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Tour from 'reactour'
import Slide from '@material-ui/core/Slide';
import ReactPlayer from "react-player";
import DocViewer from "react-doc-viewer";
import { listContentGet } from "../../redux/actions/GetAllFilesAction";


const steps = [
  {
    selector: '[data-tut="first-step"]',
    content: 'The various filters help search the right content',
  },
  {
    selector: '[data-tut="second-step"]',
    content: 'The latest content displays recently uploaded items',
  },
  {
    selector: '[data-tut="third-step"]',
    content: 'The previous content displays older uploaded items',
  },
  {
    selector: '[data-tut="fourth-step"]',
    content: 'The button enables you to create defined content or open content',
  },
  // ...
];


const emails = ['username@gmail.com', 'user02@gmail.com'];



/**Imports end here */



function FileViewer(props) {
  const { url } = props
  const docs = [
    { uri: url },
  ];

  return <DocViewer documents={docs} />;
}


//  copy right Function 
function Copyright2() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        KgtoPg
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}



//Circular progress function
function CircularIndeterminate() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <CircularProgress /> */}
      <CircularProgress color="secondary" />
    </div>
  );
}



//creating table data starts here
function createData(Title, Board, Subject, Chapter, Topic, Type) {
  return { Title, Board, Subject, Chapter, Topic, Type };
}





/**table rows dummy data*/
const rows = [
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Spherical Mirrors",
    "ICSE",
    "Physics",
    "Mirrors",
    "Reflection",
    "Video"
  ),
  createData(
    " Chemical Reactions and Equations",
    "CBSE",
    "Chemistry",
    "Chemical Reactions",
    "Reactions",
    "Pdf"
  ),
  createData(
    "Acids, Bases and Salts",
    "CBSE",
    "Chemistry",
    "Acids, Bases and Salts",
    "Acids",
    "Image"
  ),
  createData(
    "Metals and Non-metals",
    "CBSE",
    "Chemistry",
    "Metals and Non-metals",
    "Metals",
    "Video"
  ),
  createData(
    "Resource and Development",
    "CBSE",
    "Geography",
    "Resouces and Development",
    "Resources",
    "Video"
  ),
  createData(
    "Footprints without Feet",
    "SSC",
    "English",
    "Footprints without Feet",
    "Chapter1",
    "Video"
  ),
  createData(
    " Polynomials",
    "ICSE",
    "Maths",
    " Polynomials",
    "quadratic polynomials",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Minerals and Energy Resources",
    "CBSE",
    "Geography",
    "Minerals and Energy Resources",
    "Minerals",
    "Video"
  ),
  createData(
    "Pairs of Linear Equations in Two Variables",
    "CBSE",
    "Maths",
    "Pairs of Linear Equations in Two Variables",
    "Linear Equations",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Footprints without Feet",
    "ICSE",
    "English",
    "Footprints without Feet",
    "Chapter1",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Agriculture",
    "CBSE",
    "Geography",
    "Agriculture",
    "Agriculture",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Metals and Non-metals",
    "ICSE",
    "Chemistry",
    "Metals and Non-metals",
    "Metals",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Refraction Of Light",
    "CBSE",
    "Physics",
    "Refraction of Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Pairs of Linear Equations in Two Variables",
    "ICSE",
    "Maths",
    "Pairs of Linear Equations in Two Variables",
    "Linear Equations",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Resource and Development",
    "ICSE",
    "Geography",
    "Resouces and Development",
    "Resources",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Pairs of Linear Equations in Two Variables",
    "ICSE",
    "Maths",
    "Pairs of Linear Equations in Two Variables",
    "Linear Equations",
    "Video"
  ),
  createData(
    "Water Resources",
    "CBSE",
    "Geography",
    "Water Resources",
    "Water",
    "Image"
  ),
  createData(
    "Resource and Development",
    "SSC",
    "Geography",
    "Resouces and Development",
    "Resources",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Forest and Wildlife Resources",
    "CBSE",
    "Geography",
    "Forest and Wildlife Resources",
    "Forest and Wildlife",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Metals and Non-metals",
    "SSC",
    "Chemistry",
    "Metals and Non-metals",
    "Metals",
    "Video"
  ),
  createData(
    "Footprints without Feet",
    "SSC",
    "English",
    "Footprints without Feet",
    "Chapter1",
    "Video"
  ),
];

// board details dummy data
const boardDetailsMock = [
  {
    institute_board_id: 10001,
    institute_board_name: "CBSE",
    institute_board_f_desc: "cbse board",
    institute_board_status: 1,
    created_date: "2020-05-27",
  },
  {
    institute_board_id: 10002,
    institute_board_name: "Andhra Pradesh State Board",
    institute_board_f_desc: "AP state board",
    institute_board_status: 1,
    created_date: "2020-05-27",
  },
  {
    institute_board_id: 10003,
    institute_board_name: "ICSE",
    institute_board_f_desc: "ICSE board",
    institute_board_status: 1,
    created_date: "2020-05-27",
  },
  {
    institute_board_id: 10004,
    institute_board_name: "Bihar State Board",
    institute_board_f_desc: "Bihar state board",
    institute_board_status: 1,
    created_date: "2020-05-27",
  },
  {
    institute_board_id: 10005,
    institute_board_name: "Cambridge International",
    institute_board_f_desc: "Cambridge International board",
    institute_board_status: 1,
    created_date: "2020-05-27",
  },
  {
    institute_board_id: 10006,
    institute_board_name: "Chhattisgarh State Board",
    institute_board_f_desc: "Chhattisgarh state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10007,
    institute_board_name: "Gujarat State Board",
    institute_board_f_desc: "Gujarat state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10008,
    institute_board_name: "International Baccalaureate Board",
    institute_board_f_desc: "International Baccalaureate board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10009,
    institute_board_name: "Jharkhand State Board",
    institute_board_f_desc: "Jharkhand state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10010,
    institute_board_name: "J&K State Board",
    institute_board_f_desc: "J&K state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10011,
    institute_board_name: "Karnataka State Board",
    institute_board_f_desc: "Karnataka state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10012,
    institute_board_name: "Kerala State Board",
    institute_board_f_desc: "Kerala state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10013,
    institute_board_name: "Madhya Pradesh State Board",
    institute_board_f_desc: "Madhya Pradesh state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10014,
    institute_board_name: "Maharashtra State Board",
    institute_board_f_desc: "Maharashtra state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10015,
    institute_board_name: "Punjab State Board",
    institute_board_f_desc: "Punjab state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10016,
    institute_board_name: "Rajasthan State Board",
    institute_board_f_desc: "Rajasthan state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10017,
    institute_board_name: "Tamil Nadu State Board",
    institute_board_f_desc: "Tamil Nadu state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10018,
    institute_board_name: "Telangana State Board",
    institute_board_f_desc: "Telangana state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10019,
    institute_board_name: "Uttarakhand State Board",
    institute_board_f_desc: "Uttarakhan state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10020,
    institute_board_name: "Uttar Pradesh State Board",
    institute_board_f_desc: "Uttar Pradesh state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10021,
    institute_board_name: "West Bengal State Board",
    institute_board_f_desc: "West Bengal state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
];

// institute dummy data
const instituteMockData = [
  { inst_id: 1, inst_type: "School" },
  { inst_id: 2, inst_type: "College" },
  { inst_id: 3, inst_type: "University" },
  { inst_id: 4, inst_type: "Institute" },
];

//standards dummy data
const standardsMockData = [
  { stand_id: 1, stand_name: 1 },
  { stand_id: 2, stand_name: 2 },
  { stand_id: 3, stand_name: 3 },
  { stand_id: 4, stand_name: 4 },
  { stand_id: 5, stand_name: 5 },
  { stand_id: 6, stand_name: 6 },
  { stand_id: 7, stand_name: 7 },
  { stand_id: 8, stand_name: 8 },
  { stand_id: 8, stand_name: 9 },
  { stand_id: 9, stand_name: 9 },
  { stand_id: 10, stand_name: 10 },
  { stand_id: 11, stand_name: 11 },
  { stand_id: 12, stand_name: 12 },
];

//subjects dummy data
const subjectsMockData = [
  { subj_id: 1, subj_name: "English" },
  { subj_id: 2, subj_name: "Maths" },
  { subj_id: 3, subj_name: "Social" },
  { subj_id: 4, subj_name: "Physics" },
  { subj_id: 5, subj_name: "Chemistry" },
  { subj_id: 6, subj_name: "Biology" },
];

//chapters dummy data
const chaptersMockData = [
  { chap_id: 1, chap_name: "A Letter to God." },
  { chap_id: 2, chap_name: "Polynomials" },
  { chap_id: 3, chap_name: "Federalism" },
  { chap_id: 4, chap_name: " Light-Reflection and Refraction. " },
  { chap_id: 5, chap_name: " Acids, Bases and Salts" },
  { chap_id: 6, chap_name: "Life Processes" },
];

//topics dummy data
const TopicsMockData = [
  { topic_id: 1, topic_name: "How Do Organisms Reproduce" },
  { topic_id: 2, topic_name: "Our Environment" },
  { topic_id: 3, topic_name: " Sources Of Energy." },
  { topic_id: 4, topic_name: " Coordinate Geometry. " },
  { topic_id: 5, topic_name: " Acids, Bases and Salts" },
  { topic_id: 6, topic_name: "Metals and Non Metals." },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const useStyles5 = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles5();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Delete Content</DialogTitle>
      <DialogContent dividers>
        <Typography
          variant="body1">
          Do you want to delete the content?
        </Typography>

      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => { props.onDeleteMap(); handleClose() }} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

function SimpleDialogDemo(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  console.log(`props id ${props.id}`)

  return (
    <div>
      {/* <Typography variant="subtitle1">Selected: {selectedValue}</Typography>
          <br />
          <Button variant="outlined" color="primary" onClick={handleClickOpen}>
              Open simple dialog
          </Button>
           */}
      {/* <IconButton aria-label="delete" color="secondary" size="small"
        // onClick={() => props.onDeleteCont(props.id)}
        onClick={handleClickOpen}>
        <DeleteOutlineOutlinedIcon fontSize="inherit" />
      </IconButton> */}
      <MenuItem
        onClick={() => props.onDeleteCont(props.id)}
        onClick={handleClickOpen}>Delete</MenuItem>
      <SimpleDialog selectedValue={selectedValue} onDeleteMap={() => props.onDeleteMap()} row={props.row} id={props.id} open={open} onClose={handleClose} />
    </div>
  );
}


// table ascending and descending
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

//table sorting
function stableSort(array, comparator) {
  const stabilizedThis =
    array && array.length > 0 ? array.map((el, index) => [el, index]) : [];
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

//table sorting
function stableSort1(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// table headings array
const headCells = [
  { id: "Title", numeric: false, disablePadding: true, label: "Title" },
  // { id: 'Board', numeric: false, disablePadding: true, label: 'Board' },
  // { id: 'Subject', numeric: false, disablePadding: true, label: 'Subject' },
  // { id: "Chapter", numeric: false, disablePadding: true, label: "Chapter" },
  // { id: "Topic", numeric: false, disablePadding: true, label: "Tospic" },
  { id: "Type", numeric: false, disablePadding: true, label: "Type" },
  { id: 'Actions', numeric: false, disablePadding: true, label: 'Actions' }
  // { id: "image", numeric: false, disablePadding: false, label: "image(if)" }
];

function FetchContent() {
  const dipatch = useDispatch()
  return (
    <div>

    </div>
  )


}


//table heading component starts here
function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontFamily: "Poppins, Helvetica, sans-serif",
              color: "#B5B5C3",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

//table heading component ends here

/***top toolbar of the table */

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, instType, board, subject } = props;
  const [snackOpen, setSnackOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false)
  const [actvRow, setActvRow] = useState(null)

  const handleSnackClose = () => {
    setSnackOpen(false);
  };
  const handleSnackOpen = () => {
    setSnackOpen(true);
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const dispatch = useDispatch();

  const toggleDrawer = (anchor, open, row) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });

    // update edit
    setActvRow(row)
  };

  return (
    <>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            id="tableTitle"
            component="div"
            /* style={{
              fontSize: 16,
              fontWeight: "500",
              fontFamily: "Poppins, Helvetica, sans-serif",
            }} */
            variant="h2"
          >
            My Content
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="New Content">
            <></>
          </Tooltip>
        )}

        {["right"].map((anchor) => (
          <React.Fragment>
            <div data-tut="fourth-step"
              style={{
                width: "20%",
              }}>
              <Button
                onClick={toggleDrawer(anchor, true, false)}
                variant="contained"
                color="primary"
                className={classes.primary}
                style={{
                  width: "100%",
                  backgroundColor: "#3699FF",
                  textTransform: "none",
                }}
              >
                Add Content
              </Button>
            </div>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false, false)}
            >
              <UploadContent
                subject={subject}
                onSearchContent={(subject2) => {
                  props.onSearchContent(subject2);
                }}
                key="uploadContent"
                onCloseClicked={(anchor2) => {
                  //   toggleDrawer(anchor2, false)

                  setState({ ...state, [anchor]: false });

                  // show snackbar
                  handleSnackOpen();
                  // get list of content back
                }}
                onReload={props.onReload}
                anchor={anchor}
              />
            </Drawer>
          </React.Fragment>
        ))}
      </Toolbar>

      {/* snackbar after content upload */}
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert onClose={handleSnackClose} severity="success">
          Content uploaded successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

//Styling for table
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    height: "100%",
    minWidth: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(3),
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  margin: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: "15%",
  },
  primary: {
    width: "25%",
    height: 40,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControl1: {
    margin: theme.spacing(1),
    minWidth: 250,
    minHeight: 30,
  },
}));



const useStyles1 = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
}));

// content table starts here
const ContentTable = ({
  dispatch,
  content,
  contentLoading,
  boardDetails,
  instDetails,
  subDetails,
}) => {
  const classes = useStyles();
  const classes1 = useStyles1();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [contentData, setContentData] = useState(null);
  const [instType, setInstType] = useState("");
  const [board, setBoard] = useState("");
  const [subject, setSubject] = useState("");
  const [standardType, setStandardType] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [chap, setChap] = useState("");
  const [age, setAge] = React.useState("");
  const [searchResOpen, setSearchResOpen] = useState(false);
  const [filtersResOpen, setFiltersResOpen] = useState(false)
  const [provId, setProvId] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [actvRow, setActvRow] = React.useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [openSrc, setOpenSrc] = React.useState(false);
  const [openLatest, setOpenLatest] = React.useState(false)
  const [stopVideo, setStopVideo] = React.useState(false)

  const [openLatestImg, setOpenLatestImg] = React.useState(false)
  const [openLatestPdf, setOpenLatestPdf] = React.useState(false)
  const [openLatestAudio, setOpenLatestAudio] = React.useState(false)

  const [openPrev, setOpenPrev] = React.useState(false)
  const [openLatestPrvImg, setOpenLatestPrvImg] = React.useState(false)
  const [openLatestPrvPdf, setOpenLatestPrvPdf] = React.useState(false)
  const [openLatestPrvAudio, setOpenLatestPrvAudio] = React.useState(false)
  const [isQuery, setIsQuery] = useState(false)



  const [openSrcImg, setOpenSrcImg] = React.useState(false)
  const [openSrcImgUrl, setOpenSrcImgUrl] = React.useState("")

  const [openPdfSrc, setOpenPdfSrc] = React.useState(false)
  const [openSrcPdfUrl, setOpenSrcPdfUrl] = React.useState("")

  const [openAudioSrc, setOpenAudioSrc] = React.useState(false)
  const [openSrcAudioUrl, setOpenSrcAudioUrl] = React.useState("")


  const handleClickOpenSearch = () => {
    setOpenSrc(true);
  };

  const handleCloseSearch = () => {
    setOpenSrc(false);
  };


  const handleOpenSearchImg = (url) => {
    setOpenSrcImg(true);
    setOpenSrcImgUrl(url)
  };

  const handleCloseSearchImg = () => {
    setOpenSrcImg(false);
  };


  const handleOpenSearchPdf = (url) => {
    setOpenPdfSrc(true);
    setOpenSrcPdfUrl(url)
  };

  const handleCloseSearchPdf = () => {
    setOpenSrcImg(false);
  };



  const handleOpenSearchAudio = (url) => {
    setOpenAudioSrc(true);
    setOpenSrcAudioUrl(url)
  };

  const handleCloseSearchAudio = () => {
    setOpenSrcImg(false);
  };


  const handleClickOpenLatest = () => {
    setOpenLatest(true);
  };

  const handleCloseLatest = () => {
    setOpenLatest(false);
    setStopVideo(false)
  };

  const handleOpenLatestImg = () => {
    setOpenLatestImg(true);
  };

  const handleCloseLatestImg = () => {
    setOpenLatestImg(false);
    setStopVideo(false)
  };

  const handleOpenLatestPdf = () => {
    setOpenLatestPdf(true);
  };

  const handleCloseLatestPdf = () => {
    setOpenLatestPdf(false);
    setStopVideo(false)
  };

  const handleOpenLatestAudio = () => {
    setOpenLatestAudio(true);
  };

  const handleCloseLatestAudio = () => {
    setOpenLatestAudio(false);
    setStopVideo(false)
  };


  const handleClickOpenPrev = () => {
    setOpenPrev(true);
  };

  const handleCloseLatestPrev = () => {
    setOpenPrev(false);
  };


  const handleOpenPrevImg = () => {
    setOpenLatestPrvImg(true);
  };

  const handleCloseLatestPrevImg = () => {
    setOpenLatestPrvImg(false);
  };

  const handleOpenPrevPdf = () => {
    setOpenLatestPrvPdf(true);
  };

  const handleCloseLatestPrevPdf = () => {
    setOpenLatestPrvPdf(false);
  };

  const handleOpenPrevAudio = () => {
    setOpenLatestPrvAudio(true);
  };

  const handleCloseLatestPrevAudio = () => {
    setOpenLatestPrvAudio(false);
  };


  const [state, setState] = React.useState({
    right: false,
  });


  const handleTourOpen = () => {
    setIsTourOpen(true)
  }

  const handleSnackClose = () => {
    setSnackOpen(false);
  };
  const handleSnackOpen = () => {
    setSnackOpen(true);
  };

  const handleElClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setActvRow(row)
  };

  const handleElClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });

    // close menu
    handleElClose()
  };

  const isSelected = (Title) => selected.indexOf(Title) !== -1;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  //update institute state
  const handleInstTypeChange = async (event) => {
    const { name, value } = event.target;
    // console.log(`setting insttype to ${value}`)
    setInstType(value);
    setIsQuery(true)
    // dispatch(listContentGet())
    dispatch(fetchContent(value, board, standardType, subject, chap, provId)); // main table data

    await new Promise((resolve, reject) => {
      dispatch(listBoardGet(value));
      resolve();
    });

    const firstBoardId =
      boardDetails && boardDetails.data && boardDetails.data.length
        ? boardDetails.data[0].institute_board_id
        : 0;

    // we got boards
    await new Promise((resolve, reject) => {
      dispatch(listSubGet(firstBoardId));
      resolve();
    });

    // now we got subjects
    dispatch(listStdGet(value));
    setFiltersResOpen(true)
  };

  //update board state
  const handleBoardChange = (event) => {
    const { name, value } = event.target;
    setBoard(value);
    setIsQuery(true)

    dispatch(listSubGet(value));
    // dispatch(listContentGet())
    dispatch(fetchContent(instType, value, standardType, subject, chap, provId)); // main table data
    setFiltersResOpen(true)
  };

  //update sub state
  const handleSubChange = (event) => {
    const { name, value } = event.target;
    setSubject(value);
    setIsQuery(true)
    dispatch(listChpGet(value, board));
    // dispatch(listContentGet())
    dispatch(fetchContent(instType, board, standardType, value, chap, provId)); // main table data
    setFiltersResOpen(true)
  };

  const handleStdChange = (event) => {
    console.log("called handleStdChange");
    console.log(`new standard ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setStandardType(value);
    // dispatch(listContentGet())
    dispatch(fetchContent(instType, board, value, subject, chap, provId)); // main table data

  };

  const handleChpChange = (event, chaptername) => {
    console.log("called handleChpChange");
    console.log(`new chapter ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    console.log("chapter name", chaptername, value);
    setChap(value);

    // getPublContent()
    // dispatch(listContentGet())
    dispatch(fetchContent(instType, board, standardType, subject, value, provId)); // main table data
    setFiltersResOpen(true)
  }

  const onReload = () => {
    dispatch(listContentGet())

    // defined search
    if (standardType && subject && chap)
      dispatch(fetchContent(instType, board, standardType, subject, chap, provId)); // main table data
  }

  //update on change in search
  const handleSearchChange = async (event) => {
    const { name, value } = event.target;
    setSearchText(value);
    setSearchResOpen(true);

    if (value.length == 0) {
      console.log('calling all content')
      // get all first
      await new Promise((resolve, reject) => {

        dispatch(listContentGet())

      })

      // if combination is used
      dispatch(fetchContent());
    } else {

      // now call redux thunk api to filter content by name
      dispatch(searchContent(value));

    }
  };

  //table sorting
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // select all rows function
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.Title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  //slecting each row in table
  const handleClick = (event, Title) => {
    const selectedIndex = selected.indexOf(Title);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, Title);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // changing table row
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // changing number of rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelContent = async () => {
    // show dialog

    console.log(`now trying to delete content ${JSON.stringify(actvRow)}`)
    await PUBLISHERAPI.deleteContent(actvRow.id)

    // reload content
    await new Promise((resolve, reject) => {
      dispatch(fetchContent(instType, board, standardType, subject, chap, provId)); // main table data
      resolve()
    })

    dispatch(listContentGet())
  }

  // get publisher content from db
  const getPublContent = async () => {
    const response = await PUBLISHERAPI.GetPublisherContent();
    let responseData = response.data.data;
    setContentData(responseData);
    console.log("response at getpublcontent", responseData);
  };

  const loadData = async () => {

    await new Promise((resolve, reject) => {
      dispatch(listContentGet())
      resolve()
    })
    // get boards from api and set to store

    await new Promise((resolve, reject) => {
      dispatch(listInstGet());
    });

    // get subjects on load with stored board or selected board information
    dispatch(listStdGet());

  }

  useEffect(() => {
    const provId = Cookie.get("kgtopg.guruculum.user.id");
    setProvId(provId);
    // getPublContent()
    loadData();
    return () => {
      //
    };
  }, []);

  const chpDetailsGet = useSelector((state) => state.chpDetails);
  const { loadingChap, chapDetails, errorChp } = chpDetailsGet;

  const stdDetailsGet = useSelector((state) => state.stdDetails);
  const { loadingStd, stdDetails, errorStd } = stdDetailsGet;


  const ContentGet = useSelector((state) => state.allContentGet)
  const { loadingAllContent, allContentGetData, errorAllContent } = ContentGet

  {
    loadingAllContent && allContentGetData ?
      console.log("data is loading....")
      :
      console.log("allContentGetData at contentList", allContentGetData)
  }
  console.log("chapterDeatils ===========", chapDetails);

  console.log("content data at content list table", content);

  return (
    <div className={classes.root}>
      <Helmet>
        <title> Content | Pustak Publish</title>
      </Helmet>

      <div>
        <div style={{
          position: 'absolute',
          top: 0,
          right: 0
        }}>
          <LiveHelpIcon
            onClick={handleTourOpen} />
        </div>


        {/* table top header */}
        <div>
          <EnhancedTableToolbar
            numSelected={selected.length}
            instType={instType}
            board={board}
            subject={subject}
            onSearchContent={(subject) => {
              dispatch(fetchContent());
            }}
            onReload={onReload}
          />
        </div>


        {/* table main body  */}
        <div>
          <TableContainer>
            <div className={classes.root} data-tut="first-step">
              <Paper elevation={2}>
                <div style={{ padding: 10 }}>
                  <div style={{ display: "flex", marginTop: 10, marginBottom: 10, width: "100%" }}>
                    <div style={{ width: "95%", marginLeft: 10 }}>
                      <div style={{ width: "100%" }}>
                        <FormControl fullWidth style={{ marginTop: 0, width: "100%" }}>
                          <TextField
                            id="outlined-helperText"
                            label="Content search"

                            defaultValue={searchText}
                            onChange={handleSearchChange}
                            variant="outlined"
                            size="normal"

                            style={{
                              width: "100%",
                              fontSize: 12,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          />
                        </FormControl>
                      </div>

                      <span
                        style={{
                          fontSize: 12,
                          color: "#464E5F",
                          marginLeft: 5,
                        }}
                      >
                        Search by{" "}
                        <span
                          style={{
                            color: "#464E5F",
                            fontWeight: "600",

                            fontSize: 12,
                          }}
                        >
                          name
                        </span>
                      </span>
                    </div>
                  </div>


                  <div style={{ display: "flex", justifyContent: 'flex-start', alignItems: "center", overflow: "auto", overflowX: "initial", scrollbarWidth: "thin" }}>
                    {/* institute */}
                    <div style={{ display: "flex" }}>
                      <FormControl variant="outlined" className={classes.formControl1}
                      >
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          style={{ fontSize: 13, color: "#464E5F", marginTop: 0 }}
                        >
                          Institute Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          defaultValue={instType}
                          value={instType}
                          onChange={handleInstTypeChange}
                          label="Institute Type"
                          style={{ fontSize: 13, color: "#464E5F" }}
                        >
                          {instDetails && instDetails.data ? (
                            instDetails.data.map((type, index) => {
                              // console.log(`single inst type ${JSON.stringify(type)}`)
                              return (
                                <MenuItem
                                  key={index}
                                  value={type.institute_type_id}
                                  style={{
                                    fontSize: 13,
                                    color: "#464E5F",
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                  }}
                                >
                                  {type.institute_type_name}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <>
                              <MenuItem
                                style={{
                                  fontSize: 13,
                                  color: "#464E5F",
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                              >
                                No Institutes
                              </MenuItem>
                            </>
                          )}
                        </Select>
                        <span
                          style={{
                            fontSize: 12,
                            color: "#464E5F",
                            marginLeft: 5,
                          }}
                        >
                          Please select{" "}
                          <span
                            style={{
                              color: "#464E5F",
                              fontWeight: "600",

                              fontSize: 12,
                            }}
                          >
                            Institute
                          </span>
                        </span>
                      </FormControl>
                    </div>

                    {/* board */}
                    <div style={{ display: "flex" }}>
                      <FormControl variant="outlined" className={classes.formControl1}>
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          style={{ fontSize: 13, color: "#464E5F", marginTop: 0 }}
                        >
                          Board
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          defaultValue={board}
                          onChange={handleBoardChange}
                          label="Board"
                          style={{ fontSize: 13, color: "#464E5F" }}
                        >
                          {boardDetails && boardDetails.data
                            ? boardDetails.data.map((brd, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={brd.institute_board_id}
                                  style={{
                                    fontSize: 13,
                                    color: "#464E5F",
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                  }}
                                >
                                  {brd.institute_board_name}
                                </MenuItem>
                              );
                            })
                            : console.log("not getting the data")}
                        </Select>
                        <span
                          style={{
                            fontSize: 12,
                            color: "#464E5F",
                            marginLeft: 5,
                          }}
                        >
                          Please select{" "}
                          <span
                            style={{
                              color: "#464E5F",
                              fontWeight: "600",

                              fontSize: 12,
                            }}
                          >
                            Board
                          </span>
                        </span>
                      </FormControl>
                    </div>
                    {/* 
                    {stdDetailsGet ? (
                      loadingStd && stdDetailsGet ? (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl1}
                          >
                            <InputLabel
                              id="demo-simple-select-outlined-label"
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              Standard/Stream
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={standardType}
                              onChange={handleStdChange}
                              label="Standard/Stream"
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              <MenuItem
                                style={{
                                  fontSize: 13,
                                  color: "#464E5F",
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                              >
                                Please Select Institute Type
                              </MenuItem>
                            </Select>

                            <span
                              style={{
                                fontSize: 12,
                                color: "#464E5F",
                                marginLeft: 5,
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              Please select{" "}
                              <span
                                style={{
                                  color: "#464E5F",
                                  fontWeight: "600",
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                  fontSize: 12,
                                }}
                              >
                                Standard/Stream
                              </span>
                            </span>
                          </FormControl>
                        </div>
                      ) : (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl1}
                          >
                            <InputLabel
                              id="demo-simple-select-outlined-label"
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              Standard/Stream
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={standardType}
                              onChange={handleStdChange}
                              label="Standard/Stream"
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              {loadingStd && stdDetailsGet ? (
                                console.log("loading trueeee")
                              ) : stdDetails.length != 0 ? (
                                stdDetails.data.map((list, index) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      value={list.id}
                                      style={{
                                        fontSize: 13,
                                        color: "#464E5F",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                      }}
                                    >
                                      {list.standard}
                                    </MenuItem>
                                  );
                                })
                              ) : (
                                <MenuItem
                                  style={{
                                    fontSize: 13,
                                    color: "#464E5F",
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                  }}
                                >
                                  Please select Institute Type
                                </MenuItem>
                              )}
                            </Select>

                            <span
                              style={{
                                fontSize: 12,
                                color: "#464E5F",
                                marginLeft: 5,
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              Please select{" "}
                              <span
                                style={{
                                  color: "#464E5F",
                                  fontWeight: "600",
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                  fontSize: 12,
                                }}
                              >
                                Standard/Stream
                              </span>
                            </span>
                          </FormControl>
                        </div>
                      )
                    ) : (
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <FormControl variant="outlined" className={classes.formControl1}>
                          <InputLabel
                            id="demo-simple-select-outlined-label"
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            Standard/Stream
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={standardType}
                            onChange={handleStdChange}
                            label="Standard/Stream"
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            <MenuItem
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              No Standards
                            </MenuItem>
                          </Select>

                          <span
                            style={{
                              fontSize: 12,
                              color: "#464E5F",
                              marginLeft: 5,
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            Please select{" "}
                            <span
                              style={{
                                color: "#464E5F",
                                fontWeight: "600",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                                fontSize: 12,
                              }}
                            >
                              Standard/Stream
                            </span>
                          </span>
                        </FormControl>
                      </div>
                    )} */}

                    <div style={{ display: "flex" }}>
                      <FormControl variant="outlined" className={classes.formControl1}>
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          style={{ fontSize: 13, color: "#464E5F", marginTop: 0 }}
                        >
                          Subject
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          defaultValue={subject}
                          onChange={handleSubChange}
                          label="Subject"
                          style={{ fontSize: 13, color: "#464E5F" }}
                        >
                          {subDetails && subDetails.data && subDetails.data.subjects && subDetails.data.subjects.length > 0
                            ? subDetails.data.subjects.map((sb, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={sb.subject_id}
                                  style={{ fontSize: 13, color: "#464E5F" }}
                                >
                                  {sb.subject_name}
                                </MenuItem>
                              );
                            })
                            : console.log("not getting the data")}
                        </Select>
                        <span
                          style={{
                            fontSize: 12,
                            color: "#464E5F",
                            marginLeft: 5,
                          }}
                        >
                          Please select{" "}
                          <span
                            style={{
                              color: "#464E5F",
                              fontWeight: "600",

                              fontSize: 12,
                            }}
                          >
                            Subject
                          </span>
                        </span>
                      </FormControl>
                    </div>
                    {/* 
                    {chpDetailsGet ? (
                      loadingChap && chpDetailsGet ? (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl1}
                          >
                            <InputLabel
                              id="demo-simple-select-outlined-label"
                              style={{ fontSize: 13, color: "#464E5F" }}
                            >
                              Chapter
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={chap}
                              onChange={handleChpChange}
                              label="Chapter"
                              style={{ fontSize: 13, color: "#464E5F" }}
                            >
                              <MenuItem
                                style={{
                                  fontSize: 13,
                                  color: "#464E5F",
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                              >
                                Please Select Institute Type
                              </MenuItem>
                            </Select>
                            <span
                              style={{
                                fontSize: 12,
                                color: "#464E5F",
                                marginLeft: 5,
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              Please select{" "}
                              <span
                                style={{
                                  color: "#464E5F",
                                  fontWeight: "600",
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                  fontSize: 12,
                                }}
                              >
                                Chapter
                              </span>
                            </span>
                          </FormControl>
                        </div>
                      ) : (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl1}
                          >
                            <InputLabel
                              id="demo-simple-select-outlined-label"
                              style={{ fontSize: 13, color: "#464E5F" }}
                            >
                              Chapter
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={chap}
                              onChange={handleChpChange}
                              label="Chapter"
                              style={{ fontSize: 13, color: "#464E5F" }}
                            >
                              {loadingChap && chpDetailsGet ? (
                                console.log("loading trueeee")
                              ) : chapDetails.length != 0 ? (
                                chapDetails.data.map((list, index) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      value={list.chapter_id}
                                      style={{
                                        fontSize: 13,
                                        color: "#464E5F",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                      }}
                                    >
                                      {list.chapter_title}
                                    </MenuItem>
                                  );
                                })
                              ) : (
                                <MenuItem
                                  style={{
                                    fontSize: 13,
                                    color: "#464E5F",
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                  }}
                                >
                                  Please Select Institute Type
                                </MenuItem>
                              )}
                            </Select>
                            <span
                              style={{
                                fontSize: 12,
                                color: "#464E5F",
                                marginLeft: 5,
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              Please select{" "}
                              <span
                                style={{
                                  color: "#464E5F",
                                  fontWeight: "600",
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                  fontSize: 12,
                                }}
                              >
                                Chapter
                              </span>
                            </span>
                          </FormControl>
                        </div>
                      )
                    ) : (
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl1}
                        >
                          <InputLabel
                            id="demo-simple-select-outlined-label"
                            style={{ fontSize: 13, color: "#464E5F" }}
                          >
                            Chapter
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={chap}
                            onChange={handleChpChange}
                            label="Chapter"
                            style={{ fontSize: 13, color: "#464E5F" }}
                          >
                            <MenuItem
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              No Chapters
                            </MenuItem>
                          </Select>
                          <span
                            style={{
                              fontSize: 12,
                              color: "#464E5F",
                              marginLeft: 5,
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            Please select{" "}
                            <span
                              style={{
                                color: "#464E5F",
                                fontWeight: "600",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                                fontSize: 12,
                              }}
                            >
                              Chapter
                            </span>
                          </span>
                        </FormControl>
                      </div>
                    )} */}
                  </div>
                </div>
              </Paper>
            </div>

            <Divider light style={{ width: "100%", marginBottom: 10 }} />

            {/* search Table */}
            <Paper elevation={2} >
              {!contentLoading && searchResOpen ? (
                content && content.length && searchResOpen ? (
                  <div style={{ marginTop: 15 }}>
                    <div style={{
                      padding: 20
                    }}>
                      <h3 style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>
                        Search Results
                      </h3>
                    </div>

                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      size={dense ? "small" : "medium"}
                      aria-label="enhanced table"
                    >
                      <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                      />

                      <TableBody>
                        {stableSort(content, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.Title);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            let coverImg = row.image_cover_url
                            if (!coverImg)
                              coverImg = 'https://s29843.pcdn.co/blog/wp-content/uploads/sites/2/2021/02/video-thumbnails-social.png'

                            return content ? (
                              <TableRow
                                hover

                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.Title}
                                selected={isItemSelected}
                                align="right"
                              >

                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  align="center"
                                >
                                  <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                  }}>
                                    <div
                                      style={{
                                        margin: 20
                                      }}>
                                      <img onClick={() => { }} src={coverImg} width={48}
                                        style={{
                                          borderRadius: 5,
                                          marginRight: 10
                                        }} />
                                    </div>
                                    {row.content_name}
                                  </div>
                                </TableCell>
                                {/* 
                                <TableCell align="center">
                                  {row.chapter_name}
                                </TableCell>
                                <TableCell align="center">{row.topic_name}</TableCell> */}

                                {(row.content_location.includes('.mp4')
                                  || row.content_location.includes('vimeo')
                                  // || row.content_type_name_text == "video/mp4"

                                ) ? (
                                  <TableCell align="center">

                                    <div onClick={handleClickOpenPrev}
                                      style={{
                                        cursor: 'pointer'
                                      }}>
                                      <VideocamOutlinedIcon />
                                    </div>

                                    <div>
                                      <Dialog
                                        open={openPrev}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={handleCloseLatestPrev}
                                        aria-labelledby="alert-dialog-slide-title"
                                        aria-describedby="alert-dialog-slide-description"
                                      >
                                        <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                        <DialogContent>

                                          <ReactPlayer url={row.content_location} width={"550px"} playing={stopVideo} controls={true} pip={false} stopOnUnmount={false} />
                                        </DialogContent>
                                        <DialogActions>
                                          <Button onClick={handleCloseLatestPrev} color="primary">
                                            Close
                                          </Button>
                                        </DialogActions>
                                      </Dialog>
                                    </div>

                                  </TableCell>
                                ) : row.content_type_name_text ==
                                  "application/pdf" ? (
                                  <TableCell align="center">


                                    <div onClick={handleOpenPrevPdf}>
                                      <PictureAsPdfOutlinedIcon />
                                    </div>

                                    <div>
                                      <Dialog
                                        open={openLatestPrvPdf}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={handleCloseLatestPrevPdf}
                                        aria-labelledby="alert-dialog-slide-title"
                                        aria-describedby="alert-dialog-slide-description"
                                      >
                                        <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                        <DialogContent>
                                          <FileViewer url={row.content_location} />
                                          {/* <ReactPlayer url={row.content_location} width={"550px"} controls={true} stopOnUnmount={false} /> */}
                                        </DialogContent>
                                        <DialogActions>

                                          <Button onClick={handleCloseLatestPrevPdf} color="primary">
                                            Close
                                          </Button>
                                        </DialogActions>
                                      </Dialog>
                                    </div>

                                  </TableCell>
                                ) : (row.content_type_name_text == "image/jpg"
                                  || row.content_location.includes('.jpg')
                                  || row.content_location.includes('.jpeg')
                                  || row.content_location.includes('.png')
                                  || row.content_location.includes('.gif')
                                ) ? (
                                  <TableCell align="center">

                                    <div onClick={handleOpenPrevImg}>
                                      <ImageOutlinedIcon />
                                    </div>

                                    <div>
                                      <Dialog
                                        open={openLatestPrvImg}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={handleCloseLatestPrevImg}
                                        aria-labelledby="alert-dialog-slide-title"
                                        aria-describedby="alert-dialog-slide-description"
                                      >
                                        <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                        <DialogContent>
                                          {/* <FileViewer url={row.content_location} /> */}
                                          {/* <ReactPlayer url={row.content_location} width={"550px"} controls={true} stopOnUnmount={false} /> */}
                                          <img src={row.content_location} width="100%" />
                                        </DialogContent>
                                        <DialogActions>

                                          <Button onClick={handleCloseLatestPrevImg} color="primary">
                                            Close
                                          </Button>
                                        </DialogActions>
                                      </Dialog>
                                    </div>
                                  </TableCell>
                                ) : (row.content_type_name_text == "audio/mp3"
                                  || row.content_location.includes('.mp3')
                                ) ? (
                                  <TableCell align="center">

                                    <div onClick={handleOpenPrevAudio}>
                                      <AudiotrackOutlinedIcon />
                                    </div>
                                    <div>
                                      <Dialog
                                        open={openLatestPrvAudio}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={handleCloseLatestPrevAudio}
                                        aria-labelledby="alert-dialog-slide-title"
                                        aria-describedby="alert-dialog-slide-description"
                                      >
                                        <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                        <DialogContent>
                                          <ReactPlayer url={row.content_location} width={"550px"} controls={true} stopOnUnmount={false} />
                                        </DialogContent>
                                        <DialogActions>

                                          <Button onClick={handleCloseLatestPrevAudio} color="primary">
                                            Close
                                          </Button>
                                        </DialogActions>
                                      </Dialog>
                                    </div>

                                  </TableCell>
                                ) : (
                                  <TableCell align="center"> Unknown </TableCell>
                                )}

                                <TableCell align="center">
                                  <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleElClick(e, row)}>
                                    <MoreVertIcon />
                                  </Button>

                                  <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleElClose}
                                  >
                                    <MenuItem onClick={toggleDrawer('right', true)}>Edit</MenuItem>
                                    {/* <MenuItem onClick={() => handleDelContent(row)}>Delete</MenuItem> */}

                                    <SimpleDialogDemo onDeleteMap={() => handleDelContent()} id={row.id} row={row} />
                                    {/* <MenuItem onClick={handleElClose}>Logout</MenuItem> */}
                                  </Menu>
                                </TableCell>

                              </TableRow>
                            ) : (
                              <></>
                            );
                          })}

                        {emptyRows > 0 && (
                          <TableRow
                            style={{ height: (dense ? 33 : 53) * emptyRows }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>














                    {/*
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      size={dense ? "small" : "medium"}
                      aria-label="enhanced table"
                    >
                      <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                      />

                      <TableBody>
                        {stableSort(content, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.Title);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return content ? (
                              <TableRow
                                hover
                                
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.Title}
                                selected={isItemSelected}
                                align="right"
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{ "aria-labelledby": labelId }}
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  align="center"
                                >
                                  {row.content_name}
                                </TableCell>
                                
                                <TableCell align="center">
                                  {row.chapter_name}
                                </TableCell>
                                <TableCell align="center">{row.topic_name}</TableCell>

                                {row.content_type_name_text == "video/mp4" ? (
                                  <TableCell align="center">
                                    <div onClick={handleClickOpenSearch}>
                                      <VideocamOutlinedIcon />
                                    </div>
                                    <div>

                                      <Dialog
                                        open={openSrc}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={handleCloseSearch}
                                        aria-labelledby="alert-dialog-slide-title"
                                        aria-describedby="alert-dialog-slide-description"
                                      >
                                        <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                        <DialogContent>
                                          <ReactPlayer url={row.content_location} width={"550px"} controls={true} />
                                        </DialogContent>
                                        <DialogActions>
                                          <Button onClick={handleCloseSearch} color="primary">
                                            close
                                          </Button>
                                        </DialogActions>
                                      </Dialog>
                                    </div>

                                  </TableCell>
                                ) : row.content_type_name_text ==
                                  "application/pdf" ? (
                                  <TableCell align="center">
                                    <div onClick={handleOpenSearchPdf}>
                                      <PictureAsPdfOutlinedIcon />
                                    </div>


                                    <div>
                                      <Dialog
                                        open={openPdfSrc}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={handleCloseSearchPdf}
                                        aria-labelledby="alert-dialog-slide-title"
                                        aria-describedby="alert-dialog-slide-description"
                                      >
                                        <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                        <DialogContent>
                                          <FileViewer url={row.content_location} />
                                          
                                        </DialogContent>
                                        <DialogActions>
                                          <Button onClick={handleCloseSearchPdf} color="primary">
                                            close
                                          </Button>
                                        </DialogActions>
                                      </Dialog>
                                    </div>

                                  </TableCell>
                                ) : row.content_type_name_text == "image/jpg" ? (
                                  <TableCell align="center">
                                    <div onClick={handleOpenSearchImg}>
                                      <ImageOutlinedIcon />
                                    </div>

                                    <div>
                                      <Dialog
                                        open={openSrcImg}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={handleCloseSearchImg}
                                        aria-labelledby="alert-dialog-slide-title"
                                        aria-describedby="alert-dialog-slide-description"
                                      >
                                        <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                        <DialogContent>
                                          <FileViewer url={row.content_location} />
                                          
                                        </DialogContent>
                                        <DialogActions>
                                          <Button onClick={handleCloseSearchImg} color="primary">
                                            close
                                          </Button>
                                        </DialogActions>
                                      </Dialog>
                                    </div>


                                  </TableCell>
                                ) : row.content_type_name_text == "audio/mp3" ? (
                                  <TableCell align="center">

                                    <div onClick={handleOpenSearchAudio}>
                                      <AudiotrackOutlinedIcon />
                                    </div>



                                    <div>
                                      <Dialog
                                        open={openAudioSrc}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={handleCloseSearchAudio}
                                        aria-labelledby="alert-dialog-slide-title"
                                        aria-describedby="alert-dialog-slide-description"
                                      >
                                        <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                        <DialogContent>
                                          <ReactPlayer url={row.content_location} width={"550px"} controls={true} />
                                        </DialogContent>
                                        <DialogActions>
                                          <Button onClick={handleCloseSearchAudio} color="primary">
                                            close
                                          </Button>
                                        </DialogActions>
                                      </Dialog>
                                    </div>

                                  </TableCell>
                                ) : (
                                  <TableCell align="center">None </TableCell>
                                )}
                              </TableRow>
                            ) : (
                              <></>
                            );
                          })}

                        {emptyRows > 0 && (
                          <TableRow
                            style={{ height: (dense ? 33 : 53) * emptyRows }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                        */}






                  </div>
                ) : (
                  <div
                    style={{
                      marginTop: 40,
                    }}
                  >
                    {searchResOpen && (
                      <Alert severity="error">
                        <AlertTitle>No Content Found!</AlertTitle>
                        No Content has been uploaded for the selection —{" "}
                        <strong>Upload Content by clicking on button above!</strong>
                      </Alert>
                    )}
                  </div>
                )
              ) : (
                <div
                  style={{
                    marginTop: 40,
                    minWidth: "100%",
                    width: "100%",
                    alignSelf: "center",
                  }}
                >
                  {searchResOpen && <CircularIndeterminate />}
                </div>
              )}

            </Paper>

            {/* Latest Table */}
            <div data-tut="second-step">
              <Paper elevation={2}>
                {!contentLoading && !searchResOpen && filtersResOpen ? (
                  content &&
                    content.latest &&
                    content.latest.length > 0 &&
                    !searchResOpen ? (
                    <div
                      style={{
                        marginTop: 40,
                        padding: 10
                      }}
                    >
                      <div style={{ margin: 10 }}>
                        <h3 style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>
                          Latest Content
                        </h3>
                      </div>



                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                        aria-label="enhanced table"
                      >
                        <EnhancedTableHead
                          classes={classes}
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={rows.length}
                        />

                        <TableBody>
                          {stableSort(content.latest, getComparator(order, orderBy))
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                              const isItemSelected = isSelected(row.Title);
                              const labelId = `enhanced-table-checkbox-${index}`;
                              console.log('latest row ', JSON.stringify(row))
                              let coverImg = row.image_cover_url
                              if (!coverImg)
                                coverImg = 'https://s29843.pcdn.co/blog/wp-content/uploads/sites/2/2021/02/video-thumbnails-social.png'

                              return content.latest ? (
                                <TableRow
                                  hover

                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.Title}
                                  selected={isItemSelected}
                                  align="right"
                                >

                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                    align="center"
                                  >
                                    <div style={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      alignItems: 'center'
                                    }}>
                                      <div
                                        style={{
                                          margin: 20
                                        }}>
                                        <img onClick={() => { }} src={coverImg} width={48}
                                          style={{
                                            borderRadius: 5,
                                            marginRight: 10
                                          }} />
                                      </div>
                                      {row.content_name}
                                    </div>
                                  </TableCell>
{/* 
                                  <TableCell align="center">
                                    {row.chapter_name}
                                  </TableCell>
                                  <TableCell align="center">{row.topic_name}</TableCell> */}

                                  {(
                                    row.content_location.includes('.mp4')
                                    || row.content_location.includes('vimeo')
                                    // || row.content_type_name_text == "video/mp4"
                                  ) ? (
                                    <TableCell align="center">

                                      <div onClick={handleClickOpenPrev}
                                        style={{
                                          cursor: 'pointer'
                                        }}>
                                        <VideocamOutlinedIcon />
                                      </div>

                                      <div>
                                        <Dialog
                                          open={openPrev}
                                          TransitionComponent={Transition}
                                          keepMounted
                                          onClose={handleCloseLatestPrev}
                                          aria-labelledby="alert-dialog-slide-title"
                                          aria-describedby="alert-dialog-slide-description"
                                        >
                                          <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                          <DialogContent>

                                            <ReactPlayer url={row.content_location} width={"550px"} playing={stopVideo} controls={true} pip={false} stopOnUnmount={false} />
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={handleCloseLatestPrev} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>

                                    </TableCell>
                                  ) : row.content_type_name_text ==
                                    "application/pdf" ? (
                                    <TableCell align="center">


                                      <div onClick={handleOpenPrevPdf}>
                                        <PictureAsPdfOutlinedIcon />
                                      </div>

                                      <div>
                                        <Dialog
                                          open={openLatestPrvPdf}
                                          TransitionComponent={Transition}
                                          keepMounted
                                          onClose={handleCloseLatestPrevPdf}
                                          aria-labelledby="alert-dialog-slide-title"
                                          aria-describedby="alert-dialog-slide-description"
                                        >
                                          <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                          <DialogContent>
                                            <FileViewer url={row.content_location} />
                                            {/* <ReactPlayer url={row.content_location} width={"550px"} controls={true} stopOnUnmount={false} /> */}
                                          </DialogContent>
                                          <DialogActions>

                                            <Button onClick={handleCloseLatestPrevPdf} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>

                                    </TableCell>
                                  ) : (row.content_type_name_text == "image/jpg"
                                    || row.content_location.includes('.jpg')
                                    || row.content_location.includes('.jpeg')
                                    || row.content_location.includes('.png')
                                    || row.content_location.includes('.gif')
                                  ) ? (
                                    <TableCell align="center">

                                      <div onClick={handleOpenPrevImg}>
                                        <ImageOutlinedIcon />
                                      </div>

                                      <div>
                                        <Dialog
                                          open={openLatestPrvImg}
                                          TransitionComponent={Transition}
                                          keepMounted
                                          onClose={handleCloseLatestPrevImg}
                                          aria-labelledby="alert-dialog-slide-title"
                                          aria-describedby="alert-dialog-slide-description"
                                        >
                                          <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                          <DialogContent>
                                            {/* <FileViewer url={row.content_location} /> */}
                                            {/* <ReactPlayer url={row.content_location} width={"550px"} controls={true} stopOnUnmount={false} /> */}
                                            <img src={row.content_location} width="100%" />
                                          </DialogContent>
                                          <DialogActions>

                                            <Button onClick={handleCloseLatestPrevImg} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                    </TableCell>
                                  ) : (row.content_type_name_text == "audio/mp3"
                                    || row.content_location.includes('.mp3')
                                  ) ? (
                                    <TableCell align="center">

                                      <div onClick={handleOpenPrevAudio}>
                                        <AudiotrackOutlinedIcon />
                                      </div>
                                      <div>
                                        <Dialog
                                          open={openLatestPrvAudio}
                                          TransitionComponent={Transition}
                                          keepMounted
                                          onClose={handleCloseLatestPrevAudio}
                                          aria-labelledby="alert-dialog-slide-title"
                                          aria-describedby="alert-dialog-slide-description"
                                        >
                                          <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                          <DialogContent>
                                            <ReactPlayer url={row.content_location} width={"550px"} controls={true} stopOnUnmount={false} />
                                          </DialogContent>
                                          <DialogActions>

                                            <Button onClick={handleCloseLatestPrevAudio} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>

                                    </TableCell>
                                  ) : (
                                    <TableCell align="center"> Unknown </TableCell>
                                  )}

                                  <TableCell align="center">
                                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleElClick(e, row)}>
                                      <MoreVertIcon />
                                    </Button>

                                    <Menu
                                      id="simple-menu"
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={Boolean(anchorEl)}
                                      onClose={handleElClose}
                                    >
                                      <MenuItem onClick={toggleDrawer('right', true)}>Edit</MenuItem>
                                      {/* <MenuItem onClick={() => handleDelContent(row)}>Delete</MenuItem> */}

                                      <SimpleDialogDemo onDeleteMap={() => handleDelContent()} id={row.id} row={row} />
                                      {/* <MenuItem onClick={handleElClose}>Logout</MenuItem> */}
                                    </Menu>
                                  </TableCell>

                                </TableRow>
                              ) : (
                                <></>
                              );
                            })}

                          {emptyRows > 0 && (
                            <TableRow
                              style={{ height: (dense ? 33 : 53) * emptyRows }}
                            >
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>































                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: 40,
                        padding: 10
                      }}
                    >
                      <div style={{ marginBottom: 15, margin: 10 }}>
                        <h3 style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>
                          Latest Content
                        </h3>
                      </div>
                      {!searchResOpen && filtersResOpen && (
                        <Alert severity="error">
                          <AlertTitle>No Content Found!</AlertTitle>
                          No Content has been uploaded for the selection —{" "}
                          <strong>Upload Content by clicking on button above!</strong>
                        </Alert>
                      )}
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      marginTop: 40,
                      minWidth: "100%",
                      width: "100%",
                      alignSelf: "center",
                    }}
                  >
                    {!searchResOpen && filtersResOpen && <CircularIndeterminate />}
                  </div>
                )}
              </Paper>
            </div>




            {/* previous Table */}
            <div data-tut="third-step">
              <Paper elevation={2}>
                {!contentLoading && !searchResOpen && filtersResOpen ? (
                  content && content.old && content.old.length > 0 ? (
                    <div style={{ marginTop: 15 }}>
                      <div style={{ margin: 10, padding: 10 }}>
                        <h3 style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>
                          Previous Content
                        </h3>
                      </div>



                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                        aria-label="enhanced table"
                      >
                        <EnhancedTableHead
                          classes={classes}
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={rows.length}
                        />

                        <TableBody>
                          {stableSort(content.old, getComparator(order, orderBy))
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                              const isItemSelected = isSelected(row.Title);
                              const labelId = `enhanced-table-checkbox-${index}`;
                              let coverImg = row.image_cover_url
                              if (!coverImg)
                                coverImg = 'https://s29843.pcdn.co/blog/wp-content/uploads/sites/2/2021/02/video-thumbnails-social.png'

                              return content.old ? (
                                <TableRow
                                  hover

                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.Title}
                                  selected={isItemSelected}
                                  align="right"
                                >

                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                    align="center"
                                  >
                                    <div style={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      alignItems: 'center'
                                    }}>
                                      <div
                                        style={{
                                          margin: 20
                                        }}>
                                        <img onClick={() => { }} src={coverImg} width={48}
                                          style={{
                                            borderRadius: 5,
                                            marginRight: 10
                                          }} />
                                      </div>
                                      {row.content_name}
                                    </div>
                                  </TableCell>
{/* 
                                  <TableCell align="center">
                                    {row.chapter_name}
                                  </TableCell>
                                  <TableCell align="center">{row.topic_name}</TableCell> */}

                                  {(row.content_location.includes('.mp4')
                                    || row.content_location.includes('vimeo')
                                    // || row.content_type_name_text == "video/mp4"

                                  ) ? (
                                    <TableCell align="center">

                                      <div onClick={handleClickOpenPrev}
                                        style={{
                                          cursor: 'pointer'
                                        }}>
                                        <VideocamOutlinedIcon />
                                      </div>

                                      <div>
                                        <Dialog
                                          open={openPrev}
                                          TransitionComponent={Transition}
                                          keepMounted
                                          onClose={handleCloseLatestPrev}
                                          aria-labelledby="alert-dialog-slide-title"
                                          aria-describedby="alert-dialog-slide-description"
                                        >
                                          <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                          <DialogContent>

                                            <ReactPlayer url={row.content_location} width={"550px"} playing={stopVideo} controls={true} pip={false} stopOnUnmount={false} />
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={handleCloseLatestPrev} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>

                                    </TableCell>
                                  ) : row.content_type_name_text ==
                                    "application/pdf" ? (
                                    <TableCell align="center">


                                      <div onClick={handleOpenPrevPdf}>
                                        <PictureAsPdfOutlinedIcon />
                                      </div>

                                      <div>
                                        <Dialog
                                          open={openLatestPrvPdf}
                                          TransitionComponent={Transition}
                                          keepMounted
                                          onClose={handleCloseLatestPrevPdf}
                                          aria-labelledby="alert-dialog-slide-title"
                                          aria-describedby="alert-dialog-slide-description"
                                        >
                                          <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                          <DialogContent>
                                            <FileViewer url={row.content_location} />
                                            {/* <ReactPlayer url={row.content_location} width={"550px"} controls={true} stopOnUnmount={false} /> */}
                                          </DialogContent>
                                          <DialogActions>

                                            <Button onClick={handleCloseLatestPrevPdf} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>

                                    </TableCell>
                                  ) : (row.content_type_name_text == "image/jpg"
                                    || row.content_location.includes('.jpg')
                                    || row.content_location.includes('.jpeg')
                                    || row.content_location.includes('.png')
                                    || row.content_location.includes('.gif')
                                  ) ? (
                                    <TableCell align="center">

                                      <div onClick={handleOpenPrevImg}>
                                        <ImageOutlinedIcon />
                                      </div>

                                      <div>
                                        <Dialog
                                          open={openLatestPrvImg}
                                          TransitionComponent={Transition}
                                          keepMounted
                                          onClose={handleCloseLatestPrevImg}
                                          aria-labelledby="alert-dialog-slide-title"
                                          aria-describedby="alert-dialog-slide-description"
                                        >
                                          <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                          <DialogContent>
                                            {/* <FileViewer url={row.content_location} /> */}
                                            {/* <ReactPlayer url={row.content_location} width={"550px"} controls={true} stopOnUnmount={false} /> */}
                                            <img src={row.content_location} width="100%" />
                                          </DialogContent>
                                          <DialogActions>

                                            <Button onClick={handleCloseLatestPrevImg} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                    </TableCell>
                                  ) : (row.content_type_name_text == "audio/mp3"
                                    || row.content_location.includes('.mp3')
                                  ) ? (
                                    <TableCell align="center">

                                      <div onClick={handleOpenPrevAudio}>
                                        <AudiotrackOutlinedIcon />
                                      </div>
                                      <div>
                                        <Dialog
                                          open={openLatestPrvAudio}
                                          TransitionComponent={Transition}
                                          keepMounted
                                          onClose={handleCloseLatestPrevAudio}
                                          aria-labelledby="alert-dialog-slide-title"
                                          aria-describedby="alert-dialog-slide-description"
                                        >
                                          <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                          <DialogContent>
                                            <ReactPlayer url={row.content_location} width={"550px"} controls={true} stopOnUnmount={false} />
                                          </DialogContent>
                                          <DialogActions>

                                            <Button onClick={handleCloseLatestPrevAudio} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>

                                    </TableCell>
                                  ) : (
                                    <TableCell align="center"> Unknown </TableCell>
                                  )}

                                  <TableCell align="center">
                                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleElClick(e, row)}>
                                      <MoreVertIcon />
                                    </Button>

                                    <Menu
                                      id="simple-menu"
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={Boolean(anchorEl)}
                                      onClose={handleElClose}
                                    >
                                      <MenuItem onClick={toggleDrawer('right', true)}>Edit</MenuItem>
                                      {/* <MenuItem onClick={() => handleDelContent(row)}>Delete</MenuItem> */}

                                      <SimpleDialogDemo onDeleteMap={() => handleDelContent()} id={row.id} row={row} />
                                      {/* <MenuItem onClick={handleElClose}>Logout</MenuItem> */}
                                    </Menu>
                                  </TableCell>

                                </TableRow>
                              ) : (
                                <></>
                              );
                            })}

                          {emptyRows > 0 && (
                            <TableRow
                              style={{ height: (dense ? 33 : 53) * emptyRows }}
                            >
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>



                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: 40,
                        padding: 10,
                      }}
                    >
                      <div style={{ marginBottom: 15 }}>
                        <h3 style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>
                          Previous Content
                        </h3>
                      </div>
                      {!searchResOpen && filtersResOpen && (
                        <Alert severity="error">
                          <AlertTitle>No Content Found!</AlertTitle>
                          No Content has been uploaded for the selection —{" "}
                          <strong>Upload Content by clicking on button above!</strong>
                        </Alert>
                      )}
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      marginTop: 40,
                      minWidth: "100%",
                      width: "100%",
                      alignSelf: "center",
                    }}
                  >
                    {!searchResOpen && filtersResOpen && <CircularIndeterminate />}
                  </div>
                )}
              </Paper>
            </div>




            {/* Default Results*/}
            <div data-tut="third-step">
              <Paper elevation={2}>
                {!contentLoading && !searchResOpen && !filtersResOpen ? (
                  !isQuery && allContentGetData && allContentGetData.data && allContentGetData.data.length > 0 ? (
                    <div style={{ marginTop: 15 }}>
                      <div style={{ margin: 10, padding: 10 }}>
                        <h3 style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>
                          All Content
                        </h3>
                      </div>
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                        aria-label="enhanced table"
                      >
                        <EnhancedTableHead
                          classes={classes}
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={rows.length}
                        />

                        <TableBody>
                          {stableSort1(allContentGetData.data, getComparator(order, orderBy))
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                              const isItemSelected = isSelected(row.Title);
                              const labelId = `enhanced-table-checkbox-${index}`;
                              let coverImg = row.image_cover_url
                              if (!coverImg)
                                coverImg = 'https://s29843.pcdn.co/blog/wp-content/uploads/sites/2/2021/02/video-thumbnails-social.png'

                              console.log('type ', row.content_type_name_text)
                              return content ? (
                                <TableRow
                                  hover

                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.Title}
                                  selected={isItemSelected}
                                  align="right"
                                >

                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                    align="center"
                                  >
                                    <div style={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      alignItems: 'center'
                                    }}>
                                      <div
                                        style={{
                                          margin: 20
                                        }}>
                                        <img onClick={() => { }} src={coverImg} width={48}
                                          style={{
                                            borderRadius: 5,
                                            marginRight: 10
                                          }} />
                                      </div>
                                      {row.content_name}
                                    </div>
                                  </TableCell>
                                  {/* 
                                  <TableCell align="center">
                                    {row.chapter_name}
                                  </TableCell>
                                  <TableCell align="center">{row.topic_name}</TableCell> */}

                                  {(row.content_location.includes('.mp4')
                                    || row.content_location.includes('vimeo')
                                    // || row.content_type_name_text == "video/mp4"

                                  ) ? (
                                    <TableCell align="center">

                                      <div onClick={handleClickOpenPrev}
                                        style={{
                                          cursor: 'pointer'
                                        }}>
                                        <VideocamOutlinedIcon />
                                      </div>

                                      <div>
                                        <Dialog
                                          open={openPrev}
                                          TransitionComponent={Transition}
                                          keepMounted
                                          onClose={handleCloseLatestPrev}
                                          aria-labelledby="alert-dialog-slide-title"
                                          aria-describedby="alert-dialog-slide-description"
                                        >
                                          <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                          <DialogContent>

                                            <ReactPlayer url={row.content_location} width={"550px"} playing={stopVideo} controls={true} pip={false} stopOnUnmount={false} />
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={handleCloseLatestPrev} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>

                                    </TableCell>
                                  ) : row.content_type_name_text ==
                                    "application/pdf" ? (
                                    <TableCell align="center">


                                      <div onClick={handleOpenPrevPdf}>
                                        <PictureAsPdfOutlinedIcon />
                                      </div>

                                      <div>
                                        <Dialog
                                          open={openLatestPrvPdf}
                                          TransitionComponent={Transition}
                                          keepMounted
                                          onClose={handleCloseLatestPrevPdf}
                                          aria-labelledby="alert-dialog-slide-title"
                                          aria-describedby="alert-dialog-slide-description"
                                        >
                                          <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                          <DialogContent>
                                            <FileViewer url={row.content_location} />
                                            {/* <ReactPlayer url={row.content_location} width={"550px"} controls={true} stopOnUnmount={false} /> */}
                                          </DialogContent>
                                          <DialogActions>

                                            <Button onClick={handleCloseLatestPrevPdf} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>

                                    </TableCell>
                                  ) : (row.content_type_name_text == "image/jpg"
                                    || row.content_location.includes('.jpg')
                                    || row.content_location.includes('.jpeg')
                                    || row.content_location.includes('.png')
                                    || row.content_location.includes('.gif')
                                  ) ? (
                                    <TableCell align="center">

                                      <div onClick={handleOpenPrevImg}>
                                        <ImageOutlinedIcon />
                                      </div>

                                      <div>
                                        <Dialog
                                          open={openLatestPrvImg}
                                          TransitionComponent={Transition}
                                          keepMounted
                                          onClose={handleCloseLatestPrevImg}
                                          aria-labelledby="alert-dialog-slide-title"
                                          aria-describedby="alert-dialog-slide-description"
                                        >
                                          <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                          <DialogContent>
                                            {/* <FileViewer url={row.content_location} /> */}
                                            {/* <ReactPlayer url={row.content_location} width={"550px"} controls={true} stopOnUnmount={false} /> */}
                                            <img src={row.content_location} width="100%" />
                                          </DialogContent>
                                          <DialogActions>

                                            <Button onClick={handleCloseLatestPrevImg} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                    </TableCell>
                                  ) : (row.content_type_name_text == "audio/mp3"
                                    || row.content_location.includes('.mp3')
                                  ) ? (
                                    <TableCell align="center">

                                      <div onClick={handleOpenPrevAudio}>
                                        <AudiotrackOutlinedIcon />
                                      </div>
                                      <div>
                                        <Dialog
                                          open={openLatestPrvAudio}
                                          TransitionComponent={Transition}
                                          keepMounted
                                          onClose={handleCloseLatestPrevAudio}
                                          aria-labelledby="alert-dialog-slide-title"
                                          aria-describedby="alert-dialog-slide-description"
                                        >
                                          <DialogTitle id="alert-dialog-slide-title">{"Content Preview"}</DialogTitle>
                                          <DialogContent>
                                            <ReactPlayer url={row.content_location} width={"550px"} controls={true} stopOnUnmount={false} />
                                          </DialogContent>
                                          <DialogActions>

                                            <Button onClick={handleCloseLatestPrevAudio} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>

                                    </TableCell>
                                  ) : (
                                    <TableCell align="center">Unknown </TableCell>
                                  )}

                                  <TableCell align="center">
                                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleElClick(e, row)}>
                                      <MoreVertIcon />
                                    </Button>

                                    <Menu
                                      id="simple-menu"
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={Boolean(anchorEl)}
                                      onClose={handleElClose}
                                    >
                                      <MenuItem onClick={toggleDrawer('right', true)}>Edit</MenuItem>
                                      {/* <MenuItem onClick={() => handleDelContent(row)}>Delete</MenuItem> */}

                                      <SimpleDialogDemo onDeleteMap={() => handleDelContent()} id={row.id} row={row} />
                                      {/* <MenuItem onClick={handleElClose}>Logout</MenuItem> */}
                                    </Menu>
                                  </TableCell>

                                </TableRow>
                              ) : (
                                <></>
                              );
                            })}

                          {emptyRows > 0 && (
                            <TableRow
                              style={{ height: (dense ? 33 : 53) * emptyRows }}
                            >
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: 40,
                        padding: 10,
                      }}
                    >
                      <div style={{ marginBottom: 15 }}>
                        <h3 style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>
                          All Content
                        </h3>
                      </div>
                      {!searchResOpen && !filtersResOpen && (
                        <Alert severity="error">
                          <AlertTitle>No Content Found!</AlertTitle>
                          No Content has been uploaded for the selection —{" "}
                          <strong>Upload Content by clicking on button above!</strong>
                        </Alert>
                      )}
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      marginTop: 40,
                      minWidth: "100%",
                      width: "100%",
                      alignSelf: "center",
                    }}
                  >
                    {!searchResOpen && !filtersResOpen && <CircularIndeterminate />}
                  </div>
                )}
              </Paper>
            </div>




          </TableContainer>
        </div>

        {/* edit content drawer */}
        <Drawer
          anchor={'right'}
          open={state['right']}
          onClose={toggleDrawer('right', false, false)}
        >
          <UploadContent
            subject={null}
            onSearchContent={(subject2) => {

            }}
            key="uploadContent"
            onCloseClicked={(anchor2) => {
              //   toggleDrawer(anchor2, false)

              setState({ ...state, ['right']: false });

              // show snackbar
              handleSnackOpen();
              // get list of content back
            }}
            onReload={onReload}
            anchor={'right'}
            actvRow={actvRow}
          />
        </Drawer>

        <Snackbar
          open={snackOpen}
          autoHideDuration={6000}
          onClose={handleSnackClose}
        >
          <Alert onClose={handleSnackClose} severity="success">
            Content updated successfully!
          </Alert>
        </Snackbar>
      </div>

      <div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        // style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}
        />

        <Box pb={4}>
          <Copyright />
        </Box>
      </div>

      {/* </Paper> */}
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}


      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
    </div>

  );
};

// map redux stte to react component props
const mapStateToProps = (state) => ({
  content: state.content.content,
  contentLoading: state.content.loading,
  boardDetails: state.boardDetails.boardDetails,
  instDetails: state.instDetails.instDetails,
  subDetails: state.subDetails.subDetails,
  stdDetails: state.stdDetails.stdDetails,
  chpDetails: state.chpDetails.chpDetails,
});

// Connect Redux to React Component
export default connect(mapStateToProps)(ContentTable);
