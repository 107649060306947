import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  useDimensions,
  useCallback,
} from "react";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Fab from "@mui/material/Fab";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import NavigationIcon from "@mui/icons-material/Navigation";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import { withRouter, useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Chip from "@material-ui/core/Chip";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import DateRangeIcon from "@material-ui/icons/DateRange";
// import HTML5Backend from "react-dnd-html5-backend";
// import { DragDropContext } from "react-dnd";
import BigCalendar from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import { DateRangePickerCalendar, START_DATE } from "react-nice-dates";
import HomeIcon from "@material-ui/icons/Home";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import TextField2 from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import EditIcon2 from "@mui/icons-material/Edit";
import AppBar2 from "@mui/material/AppBar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import PersonIcon2 from "@mui/icons-material/Person";
import GroupIcon2 from "@mui/icons-material/Group";
import GroupsIcon2 from "@mui/icons-material/Groups";

import Grid2 from "@mui/material/Grid";
import FormLabel2 from "@mui/material/FormLabel";

import Radio2 from "@mui/material/Radio";
import RadioGroup2 from "@mui/material/RadioGroup";
import { ProgressBar, Step } from "react-step-progress-bar";

import Toolbar2 from "@mui/material/Toolbar";
import MenuIcon2 from "@mui/icons-material/Menu";

import Accordion2 from "@mui/material/Accordion";
import AccordionDetails2 from "@mui/material/AccordionDetails";
import AccordionSummary2 from "@mui/material/AccordionSummary";
import ExpandMoreIcon2 from "@mui/icons-material/ExpandMore";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import ImageIcon from "@mui/icons-material/Image";
import FaceIcon2 from "@mui/icons-material/Face";

import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import DoneIcon2 from "@mui/icons-material/Done";
import DoneAllIcon2 from "@mui/icons-material/DoneAll";
import QueueIcon2 from "@mui/icons-material/Queue";

import getCroppedImg from "../../resource/cropImage";

import Switch from "@mui/material/Switch";
import PLATFORMAPI from "../../http/platform";

// import Alert3 from '@mui/material/Alert';

// iconv
// import Iconv from 'iconv';
// import Buffer from 'buffer';
// import assert from 'assert';
// var Buffer = require('buffer').Buffer;
// var Iconv  = require('iconv').Iconv;
// var assert = require('assert');

import { updateCurrLessonPlan } from "../../redux/actions/currPlanActions";
import "react-nice-dates/build/style.css";

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Box, Container } from "@material-ui/core";

import Box3 from "@mui/material/Box";
// import UnitListResults from "./slots/UnitListResults";
import UnitListToolbar from "./slots/UnitListToolbar";
import customers from "../../__mocks__/customers";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { Edit } from "react-feather";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import MapAPI from "../../http/curriculummap";
import MapUnitAPI from "../../http/curriculummapunit";
import { useDispatch, useSelector } from "react-redux";
import { listUnitMapGet, postNewUnit } from "../../redux/actions/unitMapAction";
import { UnitMapReducer } from "../../redux/reducers/unitMapReducer";
import { updateCurrMap } from "../../redux/actions/currMapActions";
import { updateCurrUnit } from "../../redux/actions/currUnitActions";
import { updateCurrLesson } from "../../redux/actions/currLessonActions";
import { updateCurrMapId } from "../../redux/actions/currMapIdActions";

import { getUnitLessons } from "../../redux/actions/unitLessonsActions";
import {
  getLessonPlans,
  postNewLessonPlan,
} from "../../redux/actions/lessonPlansActions";
import Constants from "../../resource/Constants";
import ImportContactsOutlinedIcon from "@material-ui/icons/ImportContactsOutlined";
import LeftCard from "./slots/LeftCard";
import { Alert, AlertTitle } from "@material-ui/lab";
import Slider from "@material-ui/core/Slider";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ColorLensOutlinedIcon from "@material-ui/icons/ColorLensOutlined";
import ColorPicker from "./slots/ColorPicker";

import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import QuizPlannerDrawer from "./slots/QuizPlannerDrawer";
import MediaPlannerDrawer from "./slots/MediaPlannerDrawer";
import { listChpGet } from "../../redux/actions/chapterAction";
import { listTopGet } from "../../redux/actions/topicAction";
import { padEnd, truncate } from "lodash";

import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import { SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core";

import Box2 from "@mui/material/Box";

import List2 from "@mui/material/List";
import ListSubheader2 from "@mui/material/ListSubheader";
import ListItem2 from "@mui/material/ListItem";
import ListItemButton2 from "@mui/material/ListItemButton";
import ListItemIcon2 from "@mui/material/ListItemIcon";
import ListItemText2 from "@mui/material/ListItemText";
import { FixedSizeList2 } from "react-window";

import Card2 from "@mui/material/Card";
import CardActions2 from "@mui/material/CardActions";
import CardContent2 from "@mui/material/CardContent";
import Typography2 from "@mui/material/Typography";

import Input2 from "@mui/material/Input";
import InputLabel2 from "@mui/material/InputLabel";
import Menu2 from "@mui/material/Menu";
import MenuItem2 from "@mui/material/MenuItem";
import Button2 from "@mui/material/Button";
import InputAdornment2 from "@mui/material/InputAdornment";
import FormHelperText2 from "@mui/material/FormHelperText";
import FormControl2 from "@mui/material/FormControl";
import Select2 from "@mui/material/Select";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Divider2 from "@mui/material/Divider";
import InboxIcon2 from "@mui/icons-material/Inbox";
import MailIcon2 from "@mui/icons-material/Mail";

import DraftsIcon2 from "@mui/icons-material/Drafts";
import SendIcon2 from "@mui/icons-material/Send";
import ExpandLess2 from "@mui/icons-material/ExpandLess";
import Collapse2 from "@mui/material/Collapse";
import ExpandMore2 from "@mui/icons-material/ExpandMore";
import StarBorder2 from "@mui/icons-material/StarBorder";

import DeleteIcon2 from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Stack2 from "@mui/material/Stack";
import DraggableList from "react-draggable-lists";

import FormControlLabel2 from "@mui/material/FormControlLabel";
import Checkbox2 from "@mui/material/Checkbox";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import Tooltip from "@mui/material/Tooltip";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import Breadcrumbs2 from "@mui/material/Breadcrumbs";
import Link2 from "@mui/material/Link";
import DeleteForeverIcon2 from "@mui/icons-material/DeleteForever";
import Chip2 from "@mui/material/Chip";
import Drawer2 from "@mui/material/Drawer";

import Dialog2 from "@mui/material/Dialog";
import DialogActions2 from "@mui/material/DialogActions";
import DialogContent2 from "@mui/material/DialogContent";
import DialogContentText2 from "@mui/material/DialogContentText";
import DialogTitle2 from "@mui/material/DialogTitle";
import AccessTimeIcon2 from "@mui/icons-material/AccessTime";

import CardMedia2 from "@mui/material/CardMedia";
import CardActionArea2 from "@mui/material/CardActionArea";

import Avatar2 from "@mui/material/Avatar";
import FolderIcon2 from "@mui/icons-material/Folder";
import ListItemAvatar2 from "@mui/material/ListItemAvatar";
import Alert2 from "@mui/material/Alert";

import ReactImagePickerEditor, {
  ImagePickerConf,
} from "react-image-picker-editor";
import "react-image-picker-editor/dist/index.css";

// import CardActions2 from '@mui/material/CardActions';

// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/dist/ProfilePicture.css";
import { SignalCellularConnectedNoInternet2BarRounded } from "@mui/icons-material";

import queryString from "query-string";

import Snackbar2 from "@mui/material/Snackbar";
import MuiAlert3 from "@mui/material/Alert";
import Modal2 from "@mui/material/Modal";
import IconButton2 from '@mui/material/IconButton';
import CloseIcon2 from '@mui/icons-material/Close';

import TemporaryDrawer2 from "./slots/LessonPlanDrawer";
import ActivityLeftBar from "./slots/ActivityLeftBar";
import APPLICATIONLIST from "./slots/ApplicationList";
import ACTIONLIST from "./slots/ActionList";
import AIMLIST from "./slots/AimList";
import ANALYSISLIST from "./slots/AnalysisList";
import ASSESSMENTLIST from "./slots/AssessmentList";

import LessonPlanSetupAPI from "../../http/lessonplansetup";
import UserSession from "../../resource/UserSession";
import Cookie from "js-cookie";
import CircularProgress2 from "@mui/material/CircularProgress";
import Cropper from "react-easy-crop";
import { listSubTopGet, listChapSubTopGet } from "../../redux/actions/SubtopicAction";

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';

import GPTMainGenerator from "./slots/GPTMainGenerator";

let isONeutral2 = null;

const Alert3 = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert3 elevation={6} ref={ref} variant="filled" {...props} />;
});

const TAG = "CurriculumSetup.js";
const userId3 = parseInt(Cookie.get('kgtopg.guruculum.user.id'));

const BANNER_WIDTH = 500;
const BANNER_HEIGHT = 180;

const BANNER_WIDTH2 = 400;
const BANNER_HEIGHT2 = 200;

const CROP_WIDTH = 426;
const CROP_HEIGHT = 240;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: BANNER_WIDTH,
  height: BANNER_HEIGHT,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const inputStyles = (theme) => ({
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "white !important",
  },
});

const style3 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const useStyles2 = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiSelect-outlined": {
      borderColor: "whitesmoke",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "whitesmoke",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&:hover .MuiOutlinedInput-root .MuiSelect-outlined": {
      borderColor: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .MuiOutlinedInput-input": {
      color: "whitesmoke",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiInputLabel-outlined": {
      color: "whitesmoke",
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "white",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "white",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
      // backgroundColor: 'white'
    },
  },
});

const fbconfig = {
  apiKey: Constants.Application.FIREBASE.API_KEY,
  authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
  //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
  storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET,
};

// firebase.initializeApp(config);

// if (!firebase.apps.length) {
//   firebase.initializeApp(fbconfig);
// } else {
//   firebase.app(); // if already initialized, use that one
// }

const config2: ImagePickerConf = {
  borderRadius: "8px",
  language: "en",
  // width: '330px',
  width: "100%",
  height: "150px",
  objectFit: "contain",
  compressInitial: null,
};

const config3bOrg: ImagePickerConf = {
  borderRadius: "8px",
  language: "en",
  // width: '330px',
  width: BANNER_WIDTH + "px",
  height: BANNER_HEIGHT + " px",
  objectFit: "contain",
  compressInitial: null,
  hideDeleteBtn: true,
  hideDownloadBtn: true,
  hideEditBtn: true,
  hideAddBtn: true,
};

const config3b: ImagePickerConf = {
  borderRadius: "8px",
  language: "en",
  // width: '330px',
  width: 800,
  height: 200,
  objectFit: "cover",
  compressInitial: null,
  hideDeleteBtn: true,
  hideDownloadBtn: true,
  hideEditBtn: true,
  hideAddBtn: true,
};

// const initialImage: string = '/assets/images/8ptAya.webp';
const initialImage1 =
  "https://media.gettyimages.com/photos/green-cornfield-ready-for-harvest-late-afternoon-light-sunset-picture-id168351414?s=612x612";
const initialImage =
  "https://static.wikia.nocookie.net/onepiecefanon/images/2/24/NoImageAvailable.jpg/revision/latest?cb=20180606064203";
const initialImage3 = "";

const iconv = require("iconv-lite");
const drawerWidth2 = 440;

function Alert4(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const localizer = momentLocalizer(moment);

const myEventsList = [
  {
    id: 0,
    title: "Unit 1: Foundations of number",
    allDay: true,
    start: new Date(2021, 3, 0),
    end: new Date(2021, 3, 1),
  },
  {
    id: 1,
    title: "Unit 2: Additions and Subtractions",
    start: new Date(2021, 3, 7),
    end: new Date(2021, 3, 10),
  },

  {
    id: 2,
    title: "Unit 3: DTS STARTS",
    start: new Date(2021, 4, 13, 0, 0, 0),
    end: new Date(2021, 4, 20, 0, 0, 0),
  },

  {
    id: 3,
    title: "Unit 4: DTS ENDS",
    start: new Date(2020, 10, 6, 0, 0, 0),
    end: new Date(2020, 10, 13, 0, 0, 0),
  },

  {
    id: 4,
    title: "Unit 5: Some Event",
    start: new Date(2021, 3, 9, 0, 0, 0),
    end: new Date(2021, 3, 9, 0, 0, 0),
  },
  {
    id: 5,
    title: "Unit 6: Conference",
    start: new Date(2021, 3, 11),
    end: new Date(2021, 3, 13),
    desc: "Big conference for important people",
  },
  {
    id: 6,
    title: "Unit 7: Meeting",
    start: new Date(2021, 3, 12, 10, 30, 0, 0),
    end: new Date(2021, 3, 12, 12, 30, 0, 0),
    desc: "Pre-meeting meeting, to prepare for the meeting",
  },
  {
    id: 7,
    title: "Unit 8: Lunch",
    start: new Date(2021, 3, 12, 12, 0, 0, 0),
    end: new Date(2021, 3, 12, 13, 0, 0, 0),
    desc: "Power lunch",
  },
  {
    id: 8,
    title: "Unit 9: Meeting",
    start: new Date(2021, 3, 12, 14, 0, 0, 0),
    end: new Date(2021, 3, 12, 15, 0, 0, 0),
  },
  {
    id: 9,
    title: "Unit 10: Happy Hour",
    start: new Date(2021, 3, 12, 17, 0, 0, 0),
    end: new Date(2021, 3, 12, 17, 30, 0, 0),
    desc: "Most important meal of the day",
  },
  {
    id: 10,
    title: "Unit 11: Dinner",
    start: new Date(2021, 3, 12, 20, 0, 0, 0),
    end: new Date(2021, 3, 12, 21, 0, 0, 0),
  },
  {
    id: 11,
    title: "Unit 12: Birthday Party",
    start: new Date(2021, 3, 13, 7, 0, 0),
    end: new Date(2021, 3, 13, 10, 30, 0),
  },
  {
    id: 12,
    title: "Unit 13: Late Night Event",
    start: new Date(2021, 3, 17, 19, 30, 0),
    end: new Date(2021, 3, 18, 2, 0, 0),
  },
  {
    id: 13,
    title: "Unit 14: Multi-day Event",
    start: new Date(2021, 3, 20, 19, 30, 0),
    end: new Date(2021, 3, 22, 2, 0, 0),
  },
  {
    id: 14,
    title: "Unit 15: Today",
    start: new Date(new Date().setHours(new Date().getHours() - 3)),
    end: new Date(new Date().setHours(new Date().getHours() + 3)),
  },
];

const drawerWidth = 250;

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbar1: {
    paddingRight: 24,
    height: 40,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbarIcon2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbarIcon1: {
    top: "45vh",
    bottom: 0,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    // marginLeft:70,
    backgroundColor: "#1976d2",
    color: "#ffffff",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: "blue",
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    fontSize: 14,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: "#ffffff",
    /*
    scrollbarColor: "green",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    */
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    display: "flex",
    justifyContent: "center",
  },
  container: {
    marginTop: 20,
    paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(4),
    justifyContent: "center",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  large: {
    width: "100%",
    height: 50,
    borderRadius: 0,
  },
}));

const useStyles3 = makeStyles({
  list: {
    width: drawerWidth2,
  },
  fullList: {
    width: "auto",
  },
});

const config = {
  apiKey: Constants.Application.FIREBASE.API_KEY,
  authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
  //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
  storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET,
};

const itemData = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Breakfast",
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Burger",
  },
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: "Camera",
  },
  /*
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
  },
  
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
  },
  {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
  },
  {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
  },
  {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
  },
  */
];

// firebase.initializeApp(config);

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}

const StepImageList = (props) => {
  const { images, stepIdx, planSetup } = props;

  console.log(
    "CurriculumSetup.js StepImageList images " + JSON.stringify(images)
  );

  return (
    <>
      {images && images.length ? (
        <ImageList sx={{ width: "100%", height: "auto" }} cols={3}>
          {/* {itemData.map((item) => ( */}
          {images.map((item, idx) => {
            return (
              <div
                style={{
                  position: "relative",
                }}
              >
                <ImageListItem key={item.img}>
                  <img
                    //src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    src={`${item.url}`}
                    // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    srcSet={`${item.url}`}
                    alt={""}
                    loading="lazy"
                  />
                </ImageListItem>
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}
                >
                  <DeleteForeverIcon
                    color="red"
                    style={{
                      color: "red",
                    }}
                    onClick={() => props.onDeleteStepImage(stepIdx, idx)}
                  />
                </div>
              </div>
            );
          })}
          {/* ))} */}
        </ImageList>
      ) : (
        <></>
      )}
    </>
  );
};

const StepTipList = (props) => {
  const { tips, planSetup, stepIdx } = props;

  return tips && tips.length ? (
    tips.map((item, idx) => {
      return (
        <>
          <div
            style={{
              margin: 10,
            }}
          ></div>
          {/* TEACHER TIP */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SUBHEADING value="Teacher Tip" />

            <ClearIcon
              color="warning"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                props.onDeleteStepTip(stepIdx, idx);
              }}
            />
          </div>
          <Box
            sx={{
              // width: 500,
              maxWidth: "100%",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              label="Enter the text"
              placeholder="Enter the text here"
              id="fullWidth"
              variant="outlined"
              multiline
              rows={1}
              maxRows={5}
            />

            {/* <ImageIcon /> */}
          </Box>
        </>
      );
    })
  ) : (
    <></>
  );
};

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const MyCalendar = (props) => {
  return (
    <div>
      <Calendar
        localizer={localizer}
        events={myEventsList}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        eventPropGetter={(event, start, end, isSelected) => {
          console.log("event id " + event.id);
          const id = parseInt(event.id);
          let backgroundColor = "#FF9AA2";
          if ((id + 1) % 2 == 0) {
            backgroundColor = "#FFDAC1"; //'#FFB7B2'
          } else if ((id + 1) % 5 == 0) {
            backgroundColor = "#B5EAD7";
          }
          var style = {
            backgroundColor: backgroundColor,
            borderRadius: "0px",
            opacity: 0.8,
            color: "black",
            border: "0px",
            display: "block",
          };
          return {
            style: style,
          };
        }}
      />
    </div>
  );
};

function MyDateRangePickerCalendar() {
  const today = new Date();
  let last = today;
  last.setDate(today.getDate() + 12);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(last);
  const [focus, setFocus] = useState(START_DATE);
  const handleFocusChange = (newFocus) => {
    setFocus(newFocus || START_DATE);
  };
  return (
    <div>
      {/*       
      <p>Selected start date: {startDate ? format(startDate, 'dd MMM yyyy', { locale: enGB }) : 'none'}.</p>
      <p>Selected end date: {endDate ? format(endDate, 'dd MMM yyyy', { locale: enGB }) : 'none'}.</p>
      <p>Currently selecting: {focus}.</p> */}

      <DateRangePickerCalendar
        startDate={startDate}
        endDate={endDate}
        focus={focus}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        onFocusChange={handleFocusChange}
        locale={enGB}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // justifyContent: 'center',
    flexWrap: "wrap",
    "& > *": {
      // margin: theme.spacing(0.5),
    },
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  shape: {
    backgroundColor: theme.palette.primary.main,
    width: 20,
    height: 20,
  },
  shape2: {
    backgroundColor: theme.palette.secondary.dark,
    width: 20,
    height: 20,
  },
  shape3: {
    backgroundColor: theme.palette.primary.main,
    width: 5,
    height: 20,
  },
  shape4: {
    backgroundColor: theme.palette.secondary.dark,
    width: 5,
    height: 20,
  },
  shapeCircle: {
    borderRadius: "50%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const LessonPlan = () => {
  return <div>Lesson Plans</div>;
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        width: "100%",
      }}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

function INSTRUCTIONS() {
  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      <ListItem alignItems="flex-start">
        {/* <ListItemAvatar>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        </ListItemAvatar> */}
        <ListItemText
          primary="Step 1"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Enter the Learning Outcome
              </Typography>
              {""}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        {/* <ListItemAvatar>
          <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
        </ListItemAvatar> */}
        <ListItemText
          primary="Step 2"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Press the GPT button at the top to automatically build the Lesson Plan
              </Typography>
              {""}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        {/* <ListItemAvatar>
          <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
        </ListItemAvatar> */}
        <ListItemText
          primary="Step 3"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                If you are dissatisfied with the result of any particular section, you can choose to re-generate that particular section using it's own GPT button
              </Typography>
              {""}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </List>
  );
}

function ConfirmMultiActionAreaCard(props) {
  return (
    <Card2 sx={{ maxWidth: 345 }}>
      <CardActionArea2>
        {/* <CardMedia
          component="img"
          height="140"
          image="/static/images/cards/contemplative-reptile.jpg"
          alt="green iguana"
        /> */}
        <CardContent2>
          <Typography2 gutterBottom variant="h5" component="div">
            {props.head}
          </Typography2>
          <Typography2 variant="body2" color="text.secondary">
            {props.text}
          </Typography2>
        </CardContent2>
      </CardActionArea2>
      <CardActions2>
        <Button2 size="small" color="primary" onClick={props.onButtonClicked}>
          {props.text.toUpperCase()}
        </Button2>
      </CardActions2>
    </Card2>
  );
}

function ApprovePlanDialog(props) {
  const { currMap, planSetup, isOwnMap } = props;
  const [open, setOpen] = React.useState(false);
  const [showReason, setShowReason] = React.useState(false);

  let comms = planSetup.lessonPlanSetup.publisher_comments;
  const [comments, setComments] = React.useState(comms);

  const handleUpdateComments = (e) => {
    setComments(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApprove = async (bool) => {
    if (bool == 1) props.handleApprovePlan(''); // empty the comments
    else if (bool == -1) props.handleRejectPlan(comments);

    // close dialog
    handleClose();
  };

  useEffect(() => {
    console.log(
      TAG,
      "ApprovePlanDialog useEffect() currMap ",
      JSON.stringify(currMap)
    );

    console.log(
      TAG,
      "ApprovePlanDialog useEffect() planSetup ",
      JSON.stringify(planSetup)
    );
  });

  let status =
    planSetup && planSetup.lessonPlanSetup
      ? planSetup.lessonPlanSetup.is_approved
      : 0; // no status

  let comms2 =
    planSetup && planSetup.lessonPlanSetup
      ? planSetup.lessonPlanSetup.publisher_comments
      : "";

  console.log(TAG, 'ApprovePlanDialog status: ' + status);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {status == 1 ? <Chip2 label="APPROVED" color="success" /> : <></>}

        <div style={{ width: 5 }}></div>

        {status == -1 ? (
          <Tooltip title={comms2}>
            <Chip2 label="REWORK" color="warning" />
          </Tooltip>
        ) : (
          <></>
        )}

        {!isOwnMap ? (
          <>
            <div style={{ width: 50 }}></div>
            <Button2 color="success" variant="outlined" onClick={handleClickOpen}>
              Add Feedback
            </Button2>
          </>
        )
          :
          <></>
        }

        <div style={{ width: 5 }}></div>
        {/* <Button2 color="info" variant="outlined" onClick={handleClickOpen}>
          Reject Plan
        </Button2> */}
      </div>
      <div style={{ width: 5 }}></div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Add Feedback"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography2
              variant="h5">
              What action do you want to take?
            </Typography2>
            {showReason ? (
              <>
                <Box2
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Enter reason for rework"
                    id="fullWidth"
                    value={comments}
                    onChange={handleUpdateComments}
                  />
                </Box2>
              </>
            ) : (
              <></>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              // reset to approve
              setShowReason(false);
              handleClose();
            }}
          >
            Cancel
          </Button>

          {!showReason ? (
            <>
              <Button color="primary" onClick={(e) => handleApprove(1)}>Approve</Button>
              <Button color="secondary" onClick={(e) => setShowReason(true)}>Rework</Button>
            </>
          ) : (
            <Button color="secondary" onClick={(e) => handleApprove(-1)}>Rework</Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

function AddPlanFormDialog(props) {
  const { planSetup } = props;
  const { lessonPlanSetup } = planSetup;
  const topicId5 = lessonPlanSetup.topic_id;

  const [open, setOpen] = React.useState(props.openNewPlan);
  const [open2, setOpen2] = React.useState(props.openNewPlan);
  const [snackOpen, setSnackOpen] = useState(false);
  const [saveSnackOpen, setSaveSnackOpen] = useState(false);
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const { currMap, instId, boardId, clsStd, subId, lsn } = props;
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [topic, setTopic] = useState(0);
  const [subTopic, setSubTopic] = useState(0);
  const [chapter, setChapter] = useState(10);
  const [dur, setDur] = useState("");
  const [err, setErr] = useState(
    "Please verify the form details before submitting..!"
  );
  const [mediaType, setMediaType] = useState("self");
  const [videos, setVideos] = useState(null);

  const [topics, setTopics] = useState(null);
  const cover_image =
    // "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/fa/6926005ea411e490ff8d4c5d4ff426/chemistry_logo.png?auto=format%2Ccompress&dpr=1";
    "https://media.istockphoto.com/id/1248593714/vector/flat-vector-illustration-teamwork-on-finding-new-ideas-little-people-launch-a-mechanism.jpg?s=612x612&w=is&k=20&c=k5GIFPNQBK-nk_PZteruzfSFXkP0MTe9njdUJ08uoWk=";

  const handleMediaTypeChanged = (val) => {
    setMediaType(val);
  };

  // get params from URLSearchParams object
  const params = new URLSearchParams(window.location.search);
  const instId5 = params.get('inst_id');
  const boardId5 = params.get('board_id');
  const clsStd5 = params.get('class_std');
  const subId5 = params.get('sub_id');
  const chapId5 = params.get('chap_id');
  const mapId5 = params.get('map_id');
  const unitId5 = params.get('unit_id');
  const lsnId5 = params.get('lesson_id');

  console.log(TAG, 'instId5', instId5);
  console.log(TAG, 'boardId5', boardId5);
  console.log(TAG, 'chapId5', chapId5);

  const dispatch = useDispatch();
  const history = useHistory();

  // const currLesson = useSelector(state => state.currLesson.currLesson)
  // console.log("UnitList.js handleSave: currLesson ", JSON.stringify(lsn));

  const topDetailsGet = useSelector((state) => state.topDetails);
  const SubtopDetailsGet = useSelector((state) => state.subTopDetails);
  const { loadingTop, TopDetails, errorTop } = topDetailsGet;
  const { loadingSubtop, SubtopDetails, errorSubtop } = SubtopDetailsGet;

  console.log(TAG, `loadingTop `, loadingTop);
  console.log(TAG, `topicdetails ${JSON.stringify(TopDetails)}`);

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const handleSaveSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSaveSnackOpen(false);
  };

  const handleSuccessSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleChangeTopic = (event) => {
    let tpcId = parseInt(event.target.value);
    setTopic(tpcId);

    dispatch(listSubTopGet(tpcId));
  };

  const handleChangeSubTopic = (event) => {
    setSubTopic(event.target.value);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeDesc = (event) => {
    setDesc(event.target.value);
  };

  const handleSave = async () => {
    const lessonId = lsnId5;

    let dur2 = 0;
    if (dur) {
      console.log("UnitList.js handleSave: dur is " + dur);

      // dur is date
      // let min = dur.getMinutes();
      // let sec = dur.getSeconds();

      //dur2 = parseInt(dur.split(':')[0])
      dur2 = dur; //+':'+sec;
    }

    dur2 = 0;

    const body = {
      lesson_id: lessonId,
      duration_min: dur2,
      topic_id: topic, // new topic
      sub_topic_id: subTopic,
      name: name,
      plan_description: desc,
      multimedia_self: mediaType === "self" ? 1 : 0,
      cover_image_url: cover_image,
      created_user_id: UserSession.userId,
      modified_user_id: UserSession.userId,
    };

    console.log(
      "UnitList.js handleLessonPlanSetupSave body ",
      JSON.stringify(body)
    );

    if (!lessonId || !topic || !subTopic || !name) {
      // || !dur) {

      setSnackOpen(true);
      return;
    }

    // call save api
    const resp = await LessonPlanSetupAPI.postSavePlanSetup(body);
    console.log(
      "UnitList.js AddPlanFormDialog handleSave: response " +
      JSON.stringify(resp)
    );

    if (resp && resp.data && resp.data.msg && resp.data.msg === "saved") {
      // close dialogs
      handleClose();
      handleClose2();

      let planId2 = 0;
      const planSetup = resp.data.plan;
      planId2 = planSetup.lesson_plan_setup_id;

      // just open snackbar
      setSuccessSnackOpen(true);
      setTimeout(function () {
        // take him to next page
        // window.location.assign(`/publisher-ebook/curriculum-map/set-up/${planId}/?map_id=1&inst_id=${instId}&board_id=${boardId}&class_std=${clsStd}&sub_id=${subId}`)

        const URL = `/publisher-ebook/curriculum-map/set-up/${planId2}/?map_id=${mapId5}&inst_id=${instId5}&board_id=${boardId5}&class_std=${clsStd5}&sub_id=${subId5}&unit_id=${unitId5}&lesson_id=${lsnId5}&chap_id=${chapId5}`
        // history.push(
        //   URL
        // );

        window.location.assign(URL);
      }, 1000);
    } else if (
      resp &&
      resp.data &&
      resp.data.msg &&
      resp.data.msg === "exists"
    ) {
      setErr("A lesson plan already exists with same name!");
      setSnackOpen(true);
    } else {
      setErr("There was a problem saving lesson plan!");
      setSnackOpen(true);
    }
  };


  const _fetchTopics = async () => {
    console.log(TAG, '_fetchTopics chapId ', chapId5);
    const resp = await PLATFORMAPI.getChapTopics(chapId5);
    console.log(
      TAG,
      "NextPlanDialog _fetchTopics: data ",
      JSON.stringify(resp)
    );

    if (resp && resp.data) {
      setTopics(resp.data);
    }
  }

  useEffect(() => {
    if (!topics)
      _fetchTopics();
  })

  return (
    <div>

      <Dialog
        open={props.openNewPlan}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>Add a new plan </DialogTitle>
        <DialogContent>
          <DialogContentText>Add a new plan</DialogContentText>

          <>

            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Plan Name"
                  value={name}
                  placeholder="Enter here"
                  type="text"
                  fullWidth
                  variant="outlined"
                  style={{
                    width: "99%",
                  }}
                  size="large"
                  onChange={handleChangeName}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  autoFocus
                  margin="dense"
                  id="desc"
                  label="Plan Description"
                  placeholder="Enter here"
                  value={desc}
                  type="text"
                  fullWidth
                  variant="outlined"
                  style={{
                    width: "99%",
                  }}
                  size="large"
                  onChange={handleChangeDesc}
                />
              </div>

              <div
                style={{
                  margin: 20,
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "99%",
                  }}
                >
                  <FormControl2 fullWidth>
                    <InputLabel2 id="demo-simple-select-label">
                      Topic
                    </InputLabel2>
                    <Select2
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={topic}
                      // value={tpcId}
                      // onChange={handleTpcChange}
                      label="Topic"
                      size="small"
                      onChange={handleChangeTopic}
                    >
                      <MenuItem2 value={0}>Select Topic</MenuItem2>

                      {(topics && topics.length) ? (
                        topics.map((list, index) => {
                          let selected = false;

                          if (topic == list.topic_id) selected = true;

                          return (
                            <MenuItem2
                              selected={selected}
                              key={index}
                              value={list.topic_id}
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              {list.topic_title}
                            </MenuItem2>
                          );
                        })
                      ) : (
                        <MenuItem2
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          No topics found!
                        </MenuItem2>
                      )}
                    </Select2>
                  </FormControl2>
                </div>

              </div>





              <div
                style={{
                  margin: 20,
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "99%",
                  }}
                >
                  <FormControl2 fullWidth>
                    <InputLabel2 id="demo-simple-select-label">
                      Sub Topic
                    </InputLabel2>
                    <Select2
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={subTopic}
                      // value={tpcId}
                      // onChange={handleTpcChange}
                      label="Sub Topic"
                      size="small"
                      onChange={handleChangeSubTopic}
                    >
                      <MenuItem2 value={0}>Select Sub Topic</MenuItem2>

                      {loadingSubtop && SubtopDetailsGet ? (
                        console.log("loading trueeee")
                      ) : SubtopDetails.data && SubtopDetails.data.sub_topics && SubtopDetails.data.sub_topics.length != 0 ? (
                        SubtopDetails.data.sub_topics.map((list, index) => {
                          let selected = false;

                          if (subTopic == list.topic_sub_id) selected = true;

                          return (
                            <MenuItem2
                              selected={selected}
                              key={index}
                              value={list.topic_sub_id}
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              {list.topic_sub_name}
                            </MenuItem2>
                          );
                        })
                      ) : (
                        <MenuItem2
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          Topic not selected
                        </MenuItem2>
                      )}
                    </Select2>
                  </FormControl2>
                </div>

                {/* time pick */}
                {/* <div
                  style={{
                    width: '30%'
                  }}>

                  <TextField
                    autoFocus
                    margin="dense"
                    id="time"
                    value={dur}
                    label="Plan Duration"
                    placeholder="Enter minutes"
                    type="number"
                    fullWidth
                    variant="outlined"
                    style={{
                      width: '99%'
                    }}
                    size="large"
                    onChange={handleChangeDur}
                  />

                </div> */}
              </div>
            </>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            handleClose();
            props.setOpenNewPlan(false); // close coming from props

          }}>Cancel</Button>
          {/* <Button onClick={() => { onNextClicked() }}>NEXT</Button> */}
          <Button onClick={() => {
            props.setOpenNewPlan(false);
            setSaveSnackOpen(true);
            handleSave(true);
          }}>SAVE</Button>
        </DialogActions>
      </Dialog>

      {/* message snack */}
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {err}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackOpen}
        autoHideDuration={6000}
        onClose={handleSuccessSnackClose}
      >
        <Alert
          onClose={handleSuccessSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Plan Setup saved successfully. Please wait until we redirect you to
          the creator page.
        </Alert>
      </Snackbar>

      {/* save snackbar */}
      <Snackbar
        open={saveSnackOpen}
        autoHideDuration={6000}
        onClose={handleSaveSnackClose}
      >
        <Alert
          onClose={handleSaveSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Saving next plan. Please wait until we redirect you to
          the creator page.
        </Alert>
      </Snackbar>
    </div>
  );
}

function NextPlanDialog(props) {
  const { currMap, planSetup, planId } = props;
  const [open, setOpen] = React.useState(props.openNextPlan);
  const [showReason, setShowReason] = React.useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [saveSnackOpen, setSaveSnackOpen] = useState(false);
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [open2, setOpen2] = React.useState(props.openNewPlan);

  let comms = planSetup.lessonPlanSetup.publisher_comments;
  const [comments, setComments] = React.useState(comms);
  const [err, setErr] = useState(
    "Please verify the form details before submitting..!"
  );

  const [topics, setTopics] = React.useState(null);

  // get params from URLSearchParams object
  const params = new URLSearchParams(window.location.search);
  const instId5 = params.get('inst_id');
  const boardId5 = params.get('board_id');
  const clsStd5 = params.get('class_std');
  const subId5 = params.get('sub_id');
  const chapId5 = params.get('chap_id');
  const mapId5 = params.get('map_id');
  const unitId5 = params.get('unit_id');
  const lsnId5 = params.get('lesson_id');

  console.log(TAG, 'instId5', instId5);
  console.log(TAG, 'boardId5', boardId5);
  console.log(TAG, 'chapId5', chapId5);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const handleSuccessSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackOpen(false);
  };

  const handleSaveSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSaveSnackOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleUpdateComments = (e) => {
    setComments(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.handleOpenNextPlan(false); // close next plan
  };

  const handleApprove = async (bool) => {
    if (bool == 1) props.handleApprovePlan(comments);
    else if (bool == -1) props.handleRejectPlan(comments);

    // close dialog
    handleClose();
  };

  console.log(TAG, 'NextPlanDialog open', props.openNextPlan);

  const handleSaveQuick = async () => {

    let dur2 = 0;
    if (dur2) {
      console.log("UnitList.js handleSave: dur is " + dur2);

      // dur is date
      // let min = dur.getMinutes();
      // let sec = dur.getSeconds();

      //dur2 = parseInt(dur.split(':')[0])
    }

    dur2 = 0;

    const body = {
      lesson_plan_setup_id: planId
    }

    console.log(
      "UnitList.js handleSaveQuick body ",
      JSON.stringify(body)
    );

    // call save api
    const resp = await LessonPlanSetupAPI.postSaveNextPlanSetup(body);
    console.log(
      "UnitList.js AddPlanFormDialog handleSaveQuick: response " +
      JSON.stringify(resp)
    );

    if (resp && resp.data && resp.data.msg && resp.data.msg === "saved") {
      // close dialogs
      handleClose();

      let planId2 = 0;
      planId2 = resp.data.lesson_plan_setup_id;

      // just open snackbar
      setSuccessSnackOpen(true);
      setTimeout(function () {
        // take him to next page
        // window.location.assign(`/publisher-ebook/curriculum-map/set-up/${planId}/?map_id=1&inst_id=${instId}&board_id=${boardId}&class_std=${clsStd}&sub_id=${subId}`)
        const URL = `/publisher-ebook/curriculum-map/set-up/${planId2}/?map_id=${mapId5}&inst_id=${instId5}&board_id=${boardId5}&class_std=${clsStd5}&sub_id=${subId5}&unit_id=${unitId5}&lesson_id=${lsnId5}&chap_id=${chapId5}`
        // history.push(
        //   URL
        // );

        window.location.assign(URL);
      }, 1000);
    } else if (
      resp &&
      resp.data &&
      resp.data.msg &&
      resp.data.msg === "exists"
    ) {
      setErr("A lesson plan already exists with same name!");
      setSnackOpen(true);
    } else {
      setErr("There was a problem saving lesson plan!");
      setSnackOpen(true);
    }
  };

  const _fetchTopics = async () => {
    console.log(TAG, '_fetchTopics chapId ', chapId5);
    const resp = await PLATFORMAPI.getChapTopics(chapId5);
    console.log(
      TAG,
      "NextPlanDialog _fetchTopics: data ",
      JSON.stringify(resp)
    );

    if (resp && resp.data) {
      setTopics(resp.data);
    }
  }

  useEffect(() => {
    console.log(
      TAG,
      "ApprovePlanDialog useEffect() currMap ",
      JSON.stringify(currMap)
    );

    console.log(
      TAG,
      "ApprovePlanDialog useEffect() planSetup ",
      JSON.stringify(planSetup)
    );


    // get topics
    // dispatch(listTopGet(chapId5));


    // _fetchTopics();
  });

  let status =
    planSetup && planSetup.lessonPlanSetup
      ? planSetup.lessonPlanSetup.is_approved
      : 0; // no status

  let comms2 =
    planSetup && planSetup.lessonPlanSetup
      ? planSetup.lessonPlanSetup.publisher_comments
      : "";

  return (

    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >

      {/* <Button2 color="success" variant="outlined" onClick={handleClickOpen}>
        Next Plan
      </Button2> */}

      <Dialog
        open={props.openNextPlan}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Next Plan"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have successfully saved the lesson for the above topic, Would
            like to continue with the same topic?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {

              props.setOpenNewPlan(true);
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button onClick={(e) => {
            handleClose();
            //props.setShowTopicChange(true);
            setSaveSnackOpen(true);
            // call api to clone the plan and reload..
            handleSaveQuick();
          }}>OK</Button>
        </DialogActions>
      </Dialog>


      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {err}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackOpen}
        autoHideDuration={6000}
        onClose={handleSuccessSnackClose}
      >
        <Alert
          onClose={handleSuccessSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Plan Setup saved successfully. Please wait until we redirect you to
          the creator page.
        </Alert>
      </Snackbar>

      {/* save snackbar */}
      <Snackbar
        open={saveSnackOpen}
        autoHideDuration={6000}
        onClose={handleSaveSnackClose}
      >
        <Alert
          onClose={handleSaveSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Saving next plan. Please wait until we redirect you to
          the creator page.
        </Alert>
      </Snackbar>
    </div>
  );
}

function DelPlanDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    props.handleDeletePlan();

    // close dialog
    handleClose();
  };

  return (
    <div>
      <Button2 color="warning" variant="outlined" onClick={handleClickOpen}>
        Delete
      </Button2>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete plan?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you really want to delete this plan? You cannot revert the
            action!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDelete} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function ConfirmBasicModal(props) {
  const [open, setOpen] = React.useState(props.confirmModalOpen);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { isSaving, planSetup, isOwnMap } = props;

  const stat = planSetup.lessonPlanSetup.is_approved;
  const isApproved = (stat == 1);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      {isSaving ? <><CircularProgress2 /></> :
        <Button2 color="primary" variant="contained" onClick={handleOpen}
          disabled={(isSaving) ? true : false}
        >
          <>SAVE</>
        </Button2>
      }
      <Modal2
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box2 sx={style2}>
          <Typography2 id="modal-modal-title" variant="h6" component="h2">
            Choose what you want to do
          </Typography2>

          <Typography2 id="modal-modal-description" sx={{ mt: 2 }}>
            If your lesson plan is not completed yet,
          </Typography2>
          <ConfirmMultiActionAreaCard
            text={"save it to draft"}
            head={"If your lesson plan is not completed yet,"}
            onButtonClicked={() => {

              props.setIsSaving(true);
              props.handleSavePlan();
              // close dialog
              handleClose();
              props.handleConfirmModalChange(false);
            }}
          />

          <Typography2
            id="modal-modal-description"
            sx={{ mt: 2 }}
          ></Typography2>
          <ConfirmMultiActionAreaCard
            text={"publish"}
            head={
              "If you have completed the lesson plan, request for approval to"
            }
            onButtonClicked={() => {
              props.setIsSaving(true);

              // clear the approved status if not approved
              if (!isApproved)
                props.handleNeutralPlan();
              props.handlePublishPlan();

              // close dialog
              handleClose();
              props.handleConfirmModalChange(false);

              // open the next plan dialog
              // props.handleOpenNextPlan(true);

            }}
          />
        </Box2>
      </Modal2>
    </div>
  );
}

function ConfirmAlertDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    handleClose();
    props.handlePublishPlan();
  };

  return (
    <div>
      <Button2 color="inherit" variant="outlined" onClick={handleClickOpen}>
        PUBLISH
      </Button2>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Publish Lesson Plan"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button2 color="inherit" onClick={handleAgree}>
            PUBLISH
          </Button2>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function CurriculumBreadcrumbs(props) {
  const { planData, namesDict } = props;
  const currMap = useSelector((state) => state.currMap.currMap);

  // get params from URLSearchParams object
  const params = new URLSearchParams(window.location.search);
  const instId5 = params.get('inst_id');
  const boardId5 = params.get('board_id');
  const clsStd5 = params.get('class_std');
  const subId5 = params.get('sub_id');
  const chapId5 = params.get('chap_id');
  const mapId5 = params.get('map_id');
  const unitId5 = params.get('unit_id');
  const lsnId5 = params.get('lesson_id');

  console.log(TAG, 'instId5', instId5);
  console.log(TAG, 'boardId5', boardId5);
  console.log(TAG, 'chapId5', chapId5);

  console.log(
    "CurriculumSetup.js CurriculumBreadcrumbs planData " +
    JSON.stringify(planData)
  );
  console.log(
    "CurriculumSetup.js CurriculumBreadcrumbs currMap " +
    JSON.stringify(currMap)
  );
  return (
    <div
      role="presentation"
      onClick={() => {
        /*handleClick*/
      }}
    >
      <Breadcrumbs2 aria-label="breadcrumb">
        <Link2
          underline="hover"
          color="inherit"
          href="/publisher-ebook/curriculum-map"
        >
          Home
        </Link2>
        <Link2
          underline="hover"
          color="inherit"
          href="javascript:void(0)"
          onClick={() => {
            // window.history.back();
            const URL = `/publisher-ebook/curriculum-map/set-units/${mapId5}/?inst_id=${instId5}&board_id=${boardId5}&class_std=${clsStd5}&sub_id=${subId5}`;
            window.locations.assign(URL);
          }}

        >
          {currMap ? currMap.map_title_text : "-"} Curriculum Plan
        </Link2>
        <Typography2 color="text.primary">
          {planData ? planData.lessonPlanSetup.name : "-"}
        </Typography2>
      </Breadcrumbs2>
    </div>
  );
}

const LEARNINGOUTCOMEBOX = (props) => {
  const { planSetup } = props;

  // get criteria from localstorage
  const CRIT = Constants.Application.LESSONPLAN_CRITERIA;
  let criter = localStorage.getItem(CRIT, '');
  if (!criter)
    criter = '';

  // const [outcomeText, setOutcomeText] = useState(localStorage.getItem(CRIT));
  const [outcomeText, setOutcomeText] = useState(planSetup.lessonPlanSetup.plan_description);

  const handleChange3 = (event) => {
    const { name, value } = event.target;
    console.log("AimList.js BriefField handleChange3 name " + name);
    console.log("AimList.js BriefField handleChange3 value " + value);

    // set value in cookie
    setOutcomeText(value)
    localStorage.setItem(CRIT, value);

    const sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    sPlanSetup.lessonPlanSetup.plan_description = value;

    props.handleUpdatePlanSetup(sPlanSetup);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}>
        <span
          style={{
            width: 200
          }}
        >Enter Learning outcomes</span>
        <div style={{ margin: 10 }}></div>
        <TextField
          fullWidth
          // label='Learning outcome criteria'
          placeholder="Enter the text here"
          id="fullWidth3"
          variant="standard"
          color="warning"
          onChange={handleChange3}
          defaultValue={outcomeText}
          inputProps={{
            maxLength: 500,
            // border: '1px white solid',
            color: 'white'
          }}
          InputProps={{
            style: {
              color: 'white', // Change text color
              borderBottom: '1px white solid', // Change border color
            },
          }}
          size="small"
          style={{
            width: 650,
            // background: 'white',
            // border: '1px white solid',
            color: 'white'
          }}
        />
      </div>
    </>
  )
}

function MapHeading(props) {
  // planData is from the backend
  // planSetup is the updated object from changes done
  const { planData, planSetup, isOwnMap, planId, namesDict } = props;
  const currMap = useSelector((state) => state.currMap.currMap);

  const [saveSnackOpen, setSaveSnackOpen] = React.useState(false);
  const [successSnackOpen, setSuccessSnackOpen] = React.useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const [sTopic, setSTopic] = React.useState(planSetup.lessonPlanSetup.topic_id);
  const [sTopics, setSTopics] = React.useState(null);
  const [showTopicChange, setShowTopicChange] = React.useState(false);
  const [openNextPlan, setOpenNextPlan] = React.useState(false);
  const [openNewPlan, setOpenNewPlan] = React.useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isONeutral, setIsONeutral] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // get params from URLSearchParams object
  const params = new URLSearchParams(window.location.search);
  const instId5 = params.get('inst_id');
  const boardId5 = params.get('board_id');
  const clsStd5 = params.get('class_std');
  const subId5 = params.get('sub_id');
  const chapId5 = params.get('chap_id');
  const mapId5 = params.get('map_id');
  const unitId5 = params.get('unit_id');
  const lsnId5 = params.get('lesson_id');

  console.log(TAG, 'instId5', instId5);
  console.log(TAG, 'boardId5', boardId5);
  console.log(TAG, 'chapId5', chapId5);

  const handleChange = (event: SelectChangeEvent) => {
    setSTopic(event.target.value);

    // UPDATE PLAN SETUP
  };

  const handleSaveSnackClick = () => {
    setSaveSnackOpen(true);
  };

  const handleSaveSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSaveSnackOpen(false);
  };

  const handleSuccessSnackClick = () => {
    setSuccessSnackOpen(true);
  };

  const handleSuccessSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackOpen(false);
  };

  // dummy => deactivate after
  const handlePublishPlan1 = async () => {
    // get the full plan object
    const { lessonPlanId, aim, action, analysis, application, assessment } =
      planSetup;
    const userId = Cookie.get("kgtopg.guruculum.user.id");

    console.log(
      "CurriculumSetup.js MapHeading handlePublishPlan planSetup ",
      JSON.stringify(planSetup)
    );
  };

  const handleDeletePlan = async () => {
    const { lessonPlanId, aim, action, analysis, application } = planSetup;
    const resp = await LessonPlanSetupAPI.deletePlanSetup(lessonPlanId);

    // go back to the previous page
    window.history.back();
  };

  const handleNextPlan = async () => {
    const { lessonPlanId, aim, action, analysis, application } = planSetup;
    // const resp = await LessonPlanSetupAPI.deletePlanSetup(lessonPlanId);

    // show topics dropdown

    // reload page
  };

  const handleOpenNextPlan = (bool) => {
    setOpenNextPlan(bool);
  };

  // make this active after JSON object is fully prepared
  const handlePublishPlan = async (isPublished) => {
    // get the full plan object
    const { lessonPlanId, aim, action, analysis, application, lessonPlanSetup } = planSetup;
    const { is_approved, name, lesson_plan_setup_id, topic_id, sub_topic_id,
      lesson_id, duration_min, plan_description, multimedia_self,
      cover_image_url } = lessonPlanSetup;
    const userId = Cookie.get("kgtopg.guruculum.user.id");

    const params = new URLSearchParams(window.location.search);
    const planStatus = (params.get("is_approved") != null) ? params.get("is_approved") : 0;
    console.log(TAG, "planStatus", planStatus);

    setIsSaving(true);

    const isNeutral = (is_approved === 0);

    console.log(TAG, 'isNeutral', isNeutral);

    console.log(
      TAG, "MapHeading handlePublishPlan planSetup ",
      JSON.stringify(planSetup)
    );

    console.log(TAG, 'analysis object', JSON.stringify(analysis));

    console.log("analysis parts length " + analysis.parts.length);

    // open progress
    handleSaveSnackClick();

    try {

      // update lesson with outcome text
      const lessonId = lesson_id;

      let dur2 = duration_min;
      if (dur2) {
        console.log("UnitList.js handleSave: dur is " + dur2);

        // dur is date
        // let min = dur.getMinutes();
        // let sec = dur.getSeconds();

        //dur2 = parseInt(dur.split(':')[0])
        dur2 = dur2; //+':'+sec;
      }

      // dur2 = 0;

      const body = {
        lesson_plan_setup_id: lesson_plan_setup_id,
        lesson_id: lessonId,
        duration_min: dur2,
        topic_id: topic_id,
        sub_topic_id: sub_topic_id,
        name: name,
        plan_description: plan_description,
        multimedia_self: multimedia_self,
        cover_image_url: cover_image_url,
        created_user_id: UserSession.userId,
        modified_user_id: UserSession.userId,
      };

      console.log(
        TAG, "handleLessonPlanSetupSave body ",
        JSON.stringify(body)
      );

      if (!lesson_plan_setup_id || !lessonId || !topic_id || !name) {
        // || !dur) {

        // setSnackOpen(true);
        window.alert('There was a problem saving the plan!');
        return;
      }

      // call save api
      const resp = await LessonPlanSetupAPI.putUpdatePlanSetup(body);
      // save aim resources
      if (aim && aim.isChecked) {
        let aimResources = aim.resourceList;
        let aimKeywList = aim.keywordList;

        // let us save the activity first (aim)
        let body = {
          lesson_plan_setup_id: lessonPlanId,
          teaching_min_num: aim.mins,
          name: Constants.Application.NAME_AIM,
          asset_url: "",
          plan_description: aim.description,
          summary: aim.summary,
          learning_outcome: aim.learning_outcome,
          type: Constants.Application.NAME_AIM,
          participant_count: aim.participantCount,
          created_user_id: userId,
          modified_user_id: userId,
        };

        console.log(
          "CurriculumSetup.js MapHeading handlePublishPlan aim body ",
          JSON.stringify(body)
        );

        const resp = await LessonPlanSetupAPI.postSavePlanActv(body);
        const actvId = resp.data.id;

        // save the summary

        if (aim.hasResource) {
          // you are free to save the resources
          let resList = aimResources;
          if (resList && resList.length) {
            //          resList.map(async (res, idx) => {
            for (let idx = 0; idx < resList.length; idx++) {
              let res = resList[idx];
              let bodyb = {
                lesson_plan_actv_id: actvId,
                parent_id: 0,
                type: "resource",
                asset_url: res.asset_url ? res.asset_url : "",
                name: "resource",
                description: res.description,
                mins: 0,
                created_user_id: userId,
                modified_user_id: userId,
              };

              await LessonPlanSetupAPI.postSavePlanRes(bodyb);
              // })
            }
          }

          /*
          // save learning-outcome
          let body130b = {
            lesson_plan_actv_id: actvId,
            parent_id: 0,
            type: "aim_learning_outcome",
            asset_url: "",
            name: "Learning Outcome",
            description: aim.learning_outcome,
            mins: 0,
            created_user_id: userId,
            modified_user_id: userId,
          };
  
          const resp130b = await LessonPlanSetupAPI.postSavePlanRes(body130b);
          console.log(
            "CurriculumSetup.js handlePublishPlan postSavePlanRes response " +
              JSON.stringify(resp130b)
          );
          const resId130b = resp130b.data.id;
          */
        } else {
          // clear the resources
        }

        if (aim.hasKeyword) {
          // you are free to save the keywords
          // you are free to save the resources
          let resList = aimKeywList;
          if (resList && resList.length) {
            // resList.map(async (res, idx) => {
            for (let idx = 0; idx < resList.length; idx++) {
              let res = resList[idx];
              let bodyc = {
                lesson_plan_actv_id: actvId,
                parent_id: 0,
                type: "keyword",
                asset_url: "",
                name: res.title,
                description: res.description,
                mins: 0,
                created_user_id: userId,
                modified_user_id: userId,
              };

              await LessonPlanSetupAPI.postSavePlanRes(bodyc);
              // })
            }
          }
        } else {
          // clear the keywords
        }
      } // end aim
      else {
        // delete aim -> call api
        // call delete api

        const body4 = {
          lesson_plan_setup_id: lessonPlanId,
          name: "aim",
        };

        await LessonPlanSetupAPI.deletePlanActv(body4);
      }

      // save action resources
      if (action && action.isChecked) {
        let actionSteps = action.steps;

        // let is save the action because it is checked
        let body2 = {
          lesson_plan_setup_id: lessonPlanId,
          teaching_min_num: action.mins,
          name: Constants.Application.NAME_ACTION,
          asset_url: "",
          plan_description: action.summary,
          summary: action.summary,
          engage: action.engage,
          explore: action.explore,
          type: Constants.Application.NAME_ACTION,
          participant_count: action.participantCount,
          created_user_id: userId,
          modified_user_id: userId,
        };

        const resp2 = await LessonPlanSetupAPI.postSavePlanActv(body2);
        console.log(
          "CurriculumSetup.js handlePublishPlan postSavePlanActv response " +
          JSON.stringify(resp2)
        );
        const actvId2 = resp2.data.id;

        // save engage
        let body299b = {
          lesson_plan_actv_id: actvId2,
          parent_id: 0,
          type: "action_engage",
          asset_url: "",
          name: "Engage",
          description: action.engage,
          mins: 0,
          created_user_id: userId,
          modified_user_id: userId,
        };

        const resp299b = await LessonPlanSetupAPI.postSavePlanRes(body299b);
        console.log(
          "CurriculumSetup.js handlePublishPlan postSavePlanRes response " +
          JSON.stringify(resp299b)
        );
        const resId299b = resp299b.data.id;

        // save explore
        let body230b = {
          lesson_plan_actv_id: actvId2,
          parent_id: 0,
          type: "action_explore",
          asset_url: "",
          name: "Explore",
          description: action.explore,
          mins: 0,
          created_user_id: userId,
          modified_user_id: userId,
        };

        const resp230b = await LessonPlanSetupAPI.postSavePlanRes(body230b);
        console.log(
          "CurriculumSetup.js handlePublishPlan postSavePlanRes response " +
          JSON.stringify(resp230b)
        );
        const resId230b = resp230b.data.id;

        // // save explore
        // let body230c = {
        //   lesson_plan_actv_id: actvId2,
        //   parent_id: 0,
        //   type: 'Explain',
        //   asset_url: '',
        //   name: 'action_explain',
        //   description: action.explain,
        //   mins: 0,
        //   created_user_id: userId,
        //   modified_user_id: userId
        // }

        // const resp230c = await LessonPlanSetupAPI.postSavePlanRes(body230c)
        // console.log('CurriculumSetup.js handlePublishPlan postSavePlanRes response ' + JSON.stringify(resp230c));
        // const resId230c = resp230c.data.id;

        if (action.hasSteps) {
          // you are free to save the resources
          let resList = actionSteps;
          if (resList && resList.length) {
            // resList.map(async (res, idx) => {
            for (let idx = 0; idx < resList.length; idx++) {
              let res = resList[idx];
              let body2b = {
                lesson_plan_actv_id: actvId2,
                parent_id: 0,
                type: "action_step",
                asset_url: "",
                name: res.title,
                description: res.title,
                mins: 0,
                created_user_id: userId,
                modified_user_id: userId,
              };

              const resp2b = await LessonPlanSetupAPI.postSavePlanRes(body2b);
              console.log(
                "CurriculumSetup.js handlePublishPlan postSavePlanRes response " +
                JSON.stringify(resp2)
              );
              const resId2 = resp2b.data.id;

              console.log(
                "CurriculumStep.js handlePublishPlan postSavePlanRes step " +
                JSON.stringify(res)
              );

              // now iterate the inner resources of step
              let { images, videos, docs, tips } = res;

              // if (res.hasImages) {
              // iterate the images
              if (images && images.length) {
                // double check performed..

                // images.map(async (im, idx) => {
                for (let idx = 0; idx < images.length; idx++) {
                  let im = images[idx];
                  let body2c = {
                    lesson_plan_actv_id: actvId2,
                    parent_id: resId2,
                    type: "action_step_image",
                    asset_url: im.url,
                    name: "",
                    description: "",
                    mins: 0,
                    created_user_id: userId,
                    modified_user_id: userId,
                  };

                  console.log(
                    "CurriculumSetup.js handlePublishPlan postSavePlanRes body3 " +
                    JSON.stringify(body2c)
                  );
                  const resp2c = await LessonPlanSetupAPI.postSavePlanRes(body2c);
                  console.log(
                    "CurriculumSetup.js handlePublishPlan postSavePlanRes inner response " +
                    JSON.stringify(resp2c)
                  );

                  // })
                }
              }

              // }

              // if (res.hasVideos) {
              // iterate the images
              if (videos && videos.length) {
                // double check performed..

                for (let idx = 0; idx < videos.length; idx++) {
                  let im = videos[idx];
                  // videos.map(async (im, idx) => {
                  let body2d = {
                    lesson_plan_actv_id: actvId2,
                    parent_id: resId2,
                    type: "action_step_video",
                    asset_url: im.url,
                    name: "",
                    description: "",
                    mins: 0,
                    created_user_id: userId,
                    modified_user_id: userId,
                  };
                  await LessonPlanSetupAPI.postSavePlanRes(body2d);

                  // })
                }
              }
              // }

              // if (res.hasDocs) {
              // iterate the images
              if (docs && docs.length) {
                // double check performed..

                for (let idx = 0; idx < docs.length; idx++) {
                  let im = docs[idx];
                  // docs.map(async (im, idx) => {
                  let body2e = {
                    lesson_plan_actv_id: actvId2,
                    parent_id: resId2,
                    type: "action_step_doc",
                    asset_url: im.url,
                    name: "",
                    description: "",
                    mins: 0,
                    created_user_id: userId,
                    modified_user_id: userId,
                  };
                  await LessonPlanSetupAPI.postSavePlanRes(body2e);

                  // })
                }
              }
              // }

              // if (res.hasTips) {
              // iterate the images
              if (tips && tips.length) {
                // double check performed..

                for (let idx = 0; idx < tips.length; idx++) {
                  let im = tips[idx];
                  // tips.map(async (im, idx) => {
                  let body2f = {
                    lesson_plan_actv_id: actvId2,
                    parent_id: resId2,
                    type: "action_step_tip",
                    asset_url: "",
                    name: im.title,
                    description: im.description,
                    mins: 0,
                    created_user_id: userId,
                    modified_user_id: userId,
                  };
                  await LessonPlanSetupAPI.postSavePlanRes(body2f);

                  // })
                }
              }
              // }

              // })
            }
          }
        }
      } // end action
      else {
        // delete action
        // call delete api

        const body4 = {
          lesson_plan_setup_id: lessonPlanId,
          name: "action",
        };

        await LessonPlanSetupAPI.deletePlanActv(body4);
      }

      // save analysis resources
      if (analysis && analysis.isChecked) {
        const { hasAssm } = analysis;
        let analysisParts = analysis.parts;



        // let is save the action because it is checked
        let body3 = {
          lesson_plan_setup_id: lessonPlanId,
          teaching_min_num: analysis.mins,
          name: Constants.Application.NAME_ANALYSIS,
          asset_url: "",
          plan_description: analysis.summary,
          summary: analysis.summary,
          hasAssm: analysis.hasAssm,
          type: Constants.Application.NAME_ANALYSIS,
          participant_count: analysis.participantCount,
          created_user_id: userId,
          modified_user_id: userId,
        };

        const resp3 = await LessonPlanSetupAPI.postSavePlanActv(body3);
        const actvId3 = resp3.data.id;

        // save assessment (pop-quiz)
        if (hasAssm) {
          let body5dk1 = {
            lesson_plan_actv_id: actvId3,
            parent_id: 0,
            type: "assessment_assm",
            asset_url: "",
            name: "assessment_assm",
            description: "Assessment assessment",
            mins: 0,
            created_user_id: userId,
            modified_user_id: userId,
          };

          // save assessment
          const resp5dk1 = await LessonPlanSetupAPI.postSavePlanRes(body5dk1);
          const resId5dk1 = resp5dk1.data.id;
        }


        // save elaborate
        let body231b = {
          lesson_plan_actv_id: actvId3,
          parent_id: 0,
          type: "analysis_elaborate",
          asset_url: "",
          name: "Elaborate",
          description: analysis.elaborate,
          mins: 0,
          created_user_id: userId,
          modified_user_id: userId,
        };

        const resp231b = await LessonPlanSetupAPI.postSavePlanRes(body231b);
        console.log(
          "CurriculumSetup.js handlePublishPlan postSavePlanRes response " +
          JSON.stringify(resp231b)
        );
        const resId231b = resp231b.data.id;

        // if (analysis.hasParts) {
        // you are free to save the resources
        let resList = analysisParts;
        if (resList && resList.length) {
          console.log(
            "CurriculumSetup.js handlePublish: analysis parts " + resList.length
          );
          // resList.map(async (res, idx) => {
          for (let idx = 0; idx < resList.length; idx++) {
            let res = resList[idx];
            let isSelected = res.isSelected;

            console.log(
              "CurriculumSetup.js handlePublish: analysis part " +
              JSON.stringify(res)
            );

            if (isSelected) {
              let body3b = {
                lesson_plan_actv_id: actvId3,
                parent_id: 0,
                type: "analysis_part",
                asset_url: "",
                name: res.heading,
                description: res.headingExp,
                mins: 0,
                created_user_id: userId,
                modified_user_id: userId,
              };

              console.log(
                "CurriculumSetup.js handlePublish: analysis part body3b " +
                JSON.stringify(body3b)
              );

              const resp3b = await LessonPlanSetupAPI.postSavePlanRes(body3b);
              const resId3 = resp3b.data.id;

              // save question
              let body3cb = {
                lesson_plan_actv_id: actvId3,
                parent_id: resId3,
                type: "analysis_part_qst",
                asset_url: "",
                name: "analysis_part_qst",
                description: res.question,
                mins: 0,
                created_user_id: userId,
                modified_user_id: userId,
              };

              console.log(
                "CurriculumSetup.js handlePublish: analysis part qst save body " +
                JSON.stringify(body3cb)
              );
              await LessonPlanSetupAPI.postSavePlanRes(body3cb);

              // now iterate the inner resources of step
              let { explanation } = res;

              // if (res.hasExp) {
              // iterate the images
              console.log(
                "CurriculumSetup.js handlePublish: analysis part exp length " +
                explanation.length
              );
              if (explanation && explanation.length) {
                // double check performed..

                for (let idx = 0; idx < explanation.length; idx++) {
                  let im = explanation[idx];
                  // explanation.map(async (im, idx) => {
                  let body3c = {
                    lesson_plan_actv_id: actvId3,
                    parent_id: resId3,
                    type: "analysis_part_exp",
                    asset_url: "",
                    name: "analysis_part_exp",
                    description: im.description,
                    mins: 0,
                    created_user_id: userId,
                    modified_user_id: userId,
                  };

                  console.log(
                    "CurriculumSetup.js handlePublish: analysis part exp save body " +
                    JSON.stringify(body3c)
                  );
                  await LessonPlanSetupAPI.postSavePlanRes(body3c);

                  // })
                }
              }
              // }

              // })
            }
          }
        }
        // }
      } // end analysis
      else {
        // delete analysis
        // call delete api

        const body4 = {
          lesson_plan_setup_id: lessonPlanId,
          name: "analysis",
        };

        await LessonPlanSetupAPI.deletePlanActv(body4);
      }
      console.log("CurriculumSetup.js handleSavePlan end analysis");

      // save application resources
      if (application && application.isChecked) {
        const {
          hasClasswork,
          hasMorePractice,
          hasHomework,
          classwork,
          morePractice,
          homework,
        } = application;

        const {
          description,
          hasImage,
          hasDocument,
          hasWorkBook,
          hasAssm,
          hasQandA,
          hasTips,
          hasDiffTips,
          teacherDiffTips,
          questionTips,
          imageURL,
          documentURL,
          workBookURL,
        } = classwork;

        // let is save the action because it is checked
        let body4 = {
          lesson_plan_setup_id: lessonPlanId,
          teaching_min_num: application.mins,
          name: Constants.Application.NAME_APPLICATION,
          asset_url: "",
          plan_description: application.summary,
          summary: application.summary,
          type: Constants.Application.NAME_APPLICATION,
          participant_count: application.participantCount,
          created_user_id: userId,
          modified_user_id: userId,
        };

        const resp4 = await LessonPlanSetupAPI.postSavePlanActv(body4);
        const actvId4 = resp4.data.id;

        if (hasClasswork) {
          let body4b = {
            lesson_plan_actv_id: actvId4,
            parent_id: 0,
            type: "application_classwork",
            asset_url: "",
            name: "",
            description: classwork.description,
            mins: 0,
            created_user_id: userId,
            modified_user_id: userId,
          };

          const resp4b = await LessonPlanSetupAPI.postSavePlanRes(body4b);
          const resId4 = resp4b.data.id;

          if (hasImage) {
            let body4c = {
              lesson_plan_actv_id: actvId4,
              parent_id: resId4,
              type: "application_classwork_image",
              asset_url: imageURL,
              name: "",
              description: "",
              mins: 0,
              created_user_id: userId,
              modified_user_id: userId,
            };

            await LessonPlanSetupAPI.postSavePlanRes(body4c);
          }

          if (hasDocument) {
            let body4d = {
              lesson_plan_actv_id: actvId4,
              parent_id: resId4,
              type: "application_classwork_document",
              asset_url: documentURL,
              name: "",
              description: "",
              mins: 0,
              created_user_id: userId,
              modified_user_id: userId,
            };

            await LessonPlanSetupAPI.postSavePlanRes(body4d);
          }

          if (hasWorkBook) {
            let body4e = {
              lesson_plan_actv_id: actvId4,
              parent_id: resId4,
              type: "application_classwork_workbook",
              asset_url: workBookURL,
              name: "",
              description: "",
              mins: 0,
              created_user_id: userId,
              modified_user_id: userId,
            };

            await LessonPlanSetupAPI.postSavePlanRes(body4e);
          }

          if (hasAssm) {
            let body4ek = {
              lesson_plan_actv_id: actvId4,
              parent_id: resId4,
              type: "classwork_assm",
              asset_url: "",
              name: "classwork_assm",
              description: "Classwork assessment",
              mins: 0,
              created_user_id: userId,
              modified_user_id: userId,
            };

            await LessonPlanSetupAPI.postSavePlanRes(body4ek);
          }

          if (hasQandA) {
            if (classwork.qandAText) {
              // save question paper url
              let body4p1 = {
                lesson_plan_actv_id: actvId4,
                parent_id: resId4,
                type: "classwork_question_paper_text",
                asset_url: "",
                name: classwork.qandAText,
                description: classwork.qandAText,
                mins: 0,
                created_user_id: userId,
                modified_user_id: userId,
              };
              await LessonPlanSetupAPI.postSavePlanRes(body4p1);
            }

            // save the uploaded files

            if (classwork.questionPaperUrl) {
              // save question paper url
              let body4p = {
                lesson_plan_actv_id: actvId4,
                parent_id: resId4,
                type: "classwork_question_paper_url",
                asset_url: classwork.questionPaperUrl,
                name: "",
                description: "",
                mins: 0,
                created_user_id: userId,
                modified_user_id: userId,
              };
              await LessonPlanSetupAPI.postSavePlanRes(body4p);
            }

            if (classwork.answerSheetUrl) {
              // save answer sheet url
              let body4q = {
                lesson_plan_actv_id: actvId4,
                parent_id: resId4,
                type: "classwork_answer_sheet_url",
                asset_url: classwork.answerSheetUrl,
                name: "",
                description: "",
                mins: 0,
                created_user_id: userId,
                modified_user_id: userId,
              };
              await LessonPlanSetupAPI.postSavePlanRes(body4q);
            }
          }

          // if (hasTips) {
          if (questionTips && questionTips.length) {
            console.log(
              "CurriculumSetup.js handlePublishPlan classwork question tips " +
              JSON.stringify(classwork.questionTips)
            );

            for (let idx4 = 0; idx4 < questionTips.length; idx4++) {
              let tip = questionTips[idx4];
              // questionTips.map(async (tip, idx4) => {

              console.log(
                "CurriculumSetup.js handlePublishPlan classwork question tip " +
                JSON.stringify(tip)
              );
              let body4f = {
                lesson_plan_actv_id: actvId4,
                parent_id: resId4,
                type: tip.type, //.toLowerCase(),
                asset_url: tip.assetUrl,
                name: tip.name,
                description: tip.description,
                mins: 0,
                created_user_id: userId,
                modified_user_id: userId,
              };

              console.log(
                "CurriculumSetup.js handlePublishPlan classwork question tips body " +
                JSON.stringify(body4f)
              );

              await LessonPlanSetupAPI.postSavePlanRes(body4f);
              // })
            }
          }

          // }

          if (hasDiffTips) {
            let body4g = {
              lesson_plan_actv_id: actvId4,
              parent_id: resId4,
              type: "application_classwork_diff_tip",
              asset_url: "",
              name: "help_description",
              mins: 0,
              description: teacherDiffTips.help_description,
              created_user_id: userId,
              modified_user_id: userId,
            };

            await LessonPlanSetupAPI.postSavePlanRes(body4g);

            let body4h = {
              lesson_plan_actv_id: actvId4,
              parent_id: resId4,
              type: "application_classwork_diff_tip",
              asset_url: "",
              name: "extra_challenge",
              description: teacherDiffTips.extra_challenge,
              mins: 0,
              created_user_id: userId,
              modified_user_id: userId,
            };

            await LessonPlanSetupAPI.postSavePlanRes(body4h);
          }
        }

        if (hasMorePractice) {
          const { hasSteps, steps } = morePractice;

          let body4i = {
            lesson_plan_actv_id: actvId4,
            parent_id: 0,
            type: "application_more_practice",
            asset_url: "",
            name: "more_practice",
            description: morePractice.description,
            mins: morePractice.mins,
            created_user_id: userId,
            modified_user_id: userId,
          };

          const resp4b = await LessonPlanSetupAPI.postSavePlanRes(body4i);
          const resId4b = resp4b.data.id;

          // if (hasSteps) {
          // you are free to save the resources
          let resList = steps;
          if (resList && resList.length) {
            // resList.map(async (res, idx) => {
            for (let idx = 0; idx < resList.length; idx++) {
              let res = resList[idx];
              let body4j = {
                lesson_plan_actv_id: actvId4,
                parent_id: resId4b,
                type: "more_practice_step",
                asset_url: "",
                name: res.title,
                description: res.title,
                mins: 0,
                created_user_id: userId,
                modified_user_id: userId,
              };

              const resp4j2 = await LessonPlanSetupAPI.postSavePlanRes(body4j);
              const resId4j2 = resp4j2.data.id;

              // now iterate the inner resources of step
              let { images, videos, docs, tips } = res;

              // if (res.hasImages) {
              // iterate the images
              if (images && images.length) {
                // double check performed..

                // images.map(async (im, idx) => {
                for (let idx = 0; idx < images.length; idx++) {
                  let im = images[idx];
                  let body4k = {
                    lesson_plan_actv_id: actvId4,
                    parent_id: resId4j2,
                    type: "more_practice_step_image",
                    asset_url: im.url,
                    name: "",
                    description: "",
                    mins: 0,
                    created_user_id: userId,
                    modified_user_id: userId,
                  };
                  await LessonPlanSetupAPI.postSavePlanRes(body4k);

                  // })
                }
              }

              // }

              // if (res.hasVideos) {
              // iterate the images
              if (videos && videos.length) {
                // double check performed..

                // videos.map(async (im, idx) => {
                for (let idx = 0; idx < videos.length; idx++) {
                  let im = videos[idx];
                  let body4l = {
                    lesson_plan_actv_id: actvId4,
                    parent_id: resId4j2,
                    type: "more_practice_step_video",
                    asset_url: im.url,
                    name: "",
                    description: "",
                    mins: 0,
                    created_user_id: userId,
                    modified_user_id: userId,
                  };
                  await LessonPlanSetupAPI.postSavePlanRes(body4l);

                  // })
                }
              }
              // }

              // if (res.hasDocs) {
              // iterate the images
              if (docs && docs.length) {
                // double check performed..

                // docs.map(async (im, idx) => {
                for (let idx = 0; idx < docs.length; idx++) {
                  let im = docs[idx];
                  let body4m = {
                    lesson_plan_actv_id: actvId4,
                    parent_id: resId4j2,
                    type: "more_practice_step_doc",
                    asset_url: im.url,
                    name: "",
                    description: "",
                    mins: 0,
                    created_user_id: userId,
                    modified_user_id: userId,
                  };
                  await LessonPlanSetupAPI.postSavePlanRes(body4m);

                  // })
                }
              }
              // }

              // if (res.hasTips) {
              // iterate the images
              if (tips && tips.length) {
                // double check performed..

                // tips.map(async (im, idx) => {
                for (let idx = 0; idx < tips.length; idx++) {
                  let im = tips[idx];
                  let body4n = {
                    lesson_plan_actv_id: actvId4,
                    parent_id: resId4j2,
                    type: "more_practice_step_tip",
                    asset_url: "",
                    name: im.title,
                    description: im.description,
                    mins: 0,
                    created_user_id: userId,
                    modified_user_id: userId,
                  };
                  await LessonPlanSetupAPI.postSavePlanRes(body4n);

                  // })
                }
              }
              // }

              // })
            }
          }
          // }
        }

        if (hasHomework) {
          let body4o = {
            lesson_plan_actv_id: actvId4,
            parent_id: 0,
            type: "application_homework",
            asset_url: "",
            name: "homework",
            description: "",
            mins: 0,
            created_user_id: userId,
            modified_user_id: userId,
          };

          const resp4c = await LessonPlanSetupAPI.postSavePlanRes(body4o);
          const resId4c = resp4c.data.id;

          if (homework.hasQandA) {
            if (homework.qandAText) {
              // save question paper url
              let body4p1 = {
                lesson_plan_actv_id: actvId4,
                parent_id: resId4c,
                type: "homework_question_paper_text",
                asset_url: "",
                name: "homework_question_paper_text",
                description: homework.qandAText,
                mins: 0,
                created_user_id: userId,
                modified_user_id: userId,
              };
              await LessonPlanSetupAPI.postSavePlanRes(body4p1);
            }

            // save the uploaded files

            if (homework.questionPaperUrl) {
              // save question paper url
              let body4p = {
                lesson_plan_actv_id: actvId4,
                parent_id: resId4c,
                type: "homework_question_paper_url",
                asset_url: homework.questionPaperUrl,
                name: "homework question paper url",
                description: "",
                mins: 0,
                created_user_id: userId,
                modified_user_id: userId,
              };
              await LessonPlanSetupAPI.postSavePlanRes(body4p);
            }

            if (homework.answerSheetUrl) {
              // save answer sheet url
              let body4q = {
                lesson_plan_actv_id: actvId4,
                parent_id: resId4c,
                type: "homework_answer_sheet_url",
                asset_url: homework.answerSheetUrl,
                name: "homework answer sheet url",
                description: "",
                mins: 0,
                created_user_id: userId,
                modified_user_id: userId,
              };
              await LessonPlanSetupAPI.postSavePlanRes(body4q);
            }
          }

          if (homework.hasAssm) {
            // save the assessments

            let body4p1k = {
              lesson_plan_actv_id: actvId4,
              parent_id: resId4c, // under homework
              type: "homework_assm",
              asset_url: "",
              name: "homework assm",
              description: "homework assessment",
              mins: 0,
              created_user_id: userId,
              modified_user_id: userId,
            };
            await LessonPlanSetupAPI.postSavePlanRes(body4p1k);
          }

          // if (homework.hasImages) {
          // save the images
          console.log(
            "CurriculumSetup.js handlePublishPlan homework images " +
            JSON.stringify(homework.images)
          );
          if (homework.images && homework.images.length) {
            // homework.images.map(async (im, idx4) => {
            for (let idx4 = 0; idx4 < homework.images.length; idx4++) {
              let im = homework.images[idx4];
              let body4r = {
                lesson_plan_actv_id: actvId4,
                parent_id: resId4c,
                type: "homework_image",
                asset_url: im.url,
                name: "homework image",
                description: "homework image",
                mins: 0,
                created_user_id: userId,
                modified_user_id: userId,
              };
              await LessonPlanSetupAPI.postSavePlanRes(body4r);
              // })
            }
          }
          // }

          // if (homework.hasQuestionTips) {
          // save the tips
          console.log(
            "CurriculumSetup.js handlePublishPlan homework question tips " +
            JSON.stringify(homework.questionTips)
          );
          if (homework.questionTips && homework.questionTips.length) {
            // homework.questionTips.map(async (tip, idx4) => {
            for (let idx4 = 0; idx4 < homework.questionTips.length; idx4++) {
              let tip = homework.questionTips[idx4];
              let body4s = {
                lesson_plan_actv_id: actvId4,
                parent_id: resId4c,
                type: tip.type, //.toLowerCase(),
                asset_url: tip.assetUrl,
                name: tip.name,
                description: tip.description,
                mins: 0,
                created_user_id: userId,
                modified_user_id: userId,
              };
              await LessonPlanSetupAPI.postSavePlanRes(body4s);
              // })
            }
          }
          // }
        }
      } // end application
      else {
        // delete application
        // call delete api

        const body4 = {
          lesson_plan_setup_id: lessonPlanId,
          name: "application",
        };

        await LessonPlanSetupAPI.deletePlanActv(body4);
      }

      console.log("CurriculumSetup.js handleSavePlan end application");

      // save assessment resources
      const assessment = planSetup.assessment;
      if (assessment && assessment.isChecked) {
        const {
          summary,
          hasApproachingText,
          hasMeetingText,
          hasExceedingText,
          hasAssm,
          approachingText,
          meetingText,
          exceedingText,
          participantCount,
        } = assessment;
        let teachMins = assessment.mins;

        let assmAssm = assessment.hasAssm;

        let body5 = {
          lesson_plan_setup_id: lessonPlanId,
          teaching_min_num: teachMins,
          name: Constants.Application.NAME_ASSESSMENT,
          asset_url: "",
          plan_description: summary,
          summary: summary,
          type: Constants.Application.NAME_ASSESSMENT,
          participant_count: assessment.participantCount,
          created_user_id: userId,
          modified_user_id: userId,
        };

        console.log(
          "CurriculumSetup.js handlePublishPlan assessment save body " +
          JSON.stringify(body5)
        );

        console.log(
          "CurriculumSetup.js handlePublishPlan assessment full " +
          JSON.stringify(assessment)
        );
        const resp5 = await LessonPlanSetupAPI.postSavePlanActv(body5);
        const actvId5 = resp5.data.id;

        if (hasApproachingText) {
          let body5b = {
            lesson_plan_actv_id: actvId5,
            parent_id: 0,
            type: "assessment_approaching_text",
            asset_url: "",
            name: "assessment_approaching_text",
            description: approachingText,
            mins: 0,
            created_user_id: userId,
            modified_user_id: userId,
          };

          const resp5b = await LessonPlanSetupAPI.postSavePlanRes(body5b);
          const resId5b = resp5b.data.id;
        }

        if (hasMeetingText) {
          let body5c = {
            lesson_plan_actv_id: actvId5,
            parent_id: 0,
            type: "assessment_meeting_text",
            asset_url: "",
            name: "assessment_meeting_text",
            description: meetingText,
            mins: 0,
            created_user_id: userId,
            modified_user_id: userId,
          };

          const resp5c = await LessonPlanSetupAPI.postSavePlanRes(body5c);
          const resId5c = resp5c.data.id;
        }

        if (hasExceedingText) {
          let body5d = {
            lesson_plan_actv_id: actvId5,
            parent_id: 0,
            type: "assessment_exceeding_text",
            asset_url: "",
            name: "assessment_exceeding_text",
            description: exceedingText,
            mins: 0,
            created_user_id: userId,
            modified_user_id: userId,
          };

          const resp5d = await LessonPlanSetupAPI.postSavePlanRes(body5d);
          const resId5d = resp5d.data.id;
        }

        if (assmAssm) {
          let body5dk = {
            lesson_plan_actv_id: actvId5,
            parent_id: 0,
            type: "assessment_assm",
            asset_url: "",
            name: "assessment_assm",
            description: "Assessment assessment",
            mins: 0,
            created_user_id: userId,
            modified_user_id: userId,
          };

          const resp5dk = await LessonPlanSetupAPI.postSavePlanRes(body5dk);
          const resId5dk = resp5dk.data.id;
        }
      } else {
        // remove assessment
        // call delete api

        const body4 = {
          lesson_plan_setup_id: lessonPlanId,
          name: "assessment",
        };

        await LessonPlanSetupAPI.deletePlanActv(body4);
      }


      // update plan with user-id if map creator is the same
      // if(userId3 !== lessonPlanSetup.)

      console.log("CurriculumSetup.js handleSavePlan end assessment");
      // show success
      handleSuccessSnackClick();

      // close dialog
      setConfirmModalOpen(false);

      isONeutral2 = (planStatus == 0);

      // open next plan dialog
      if (isOwnMap) {
        if (isPublished) { // click on publish
          // if(isNeutral) { // will be true after publish
          if (isONeutral2 != null && isONeutral2 === true) {
            // fresh plan, so let him keep adding new plan
            setOpenNextPlan(true);

          } else {
            // it is null here, so make it true
            isONeutral2 = true; // only first time -> prevent nextplan popup from reappearing..
          }
          // }
        } else {
          // he is saving to draft
        }

      }


    } catch (e) {
      console.error(TAG, 'handleSavePlan() exception ', e);
    }

    // close progress
    handleSaveSnackClose();

    // open save button
    setIsSaving(false);
  };

  const handleConfirmModalChange = (boolVal) => {
    setConfirmModalOpen(boolVal);
  };

  async function _getChapTopics() {

    const resp = await PLATFORMAPI.getChapTopics(chapId5);
    console.log(TAG, '_getChapTopics() response', resp);

    if (resp && resp.data) {
      setSTopics(resp.data);
    }
  }

  useEffect(() => {

    // _getChapTopics();
    return () => {

    }
  }, [])

  return (
    <Box2 sx={{ flexGrow: 1 }}>
      <AppBar2
        position="static"
        sx={{
          backgroundColor: Constants.Application.COLOR_PRIMARY,
          backgroundColor: "#4b1388",
          position: "fixed",
          top: 0,
          right: 0,
          zIndex: 99,
        }}
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          zIndex: 99,
        }}
      >
        <Toolbar2
          variant="dense"
          sx={{
            justifyContent: "space-between",
          }}
        >
          {/* <IconButton2
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon2 />
          </IconButton2> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ChevronLeftIcon
              style={{
                marginRight: 10,
                cursor: 'pointer'
              }}
              onClick={() => {
                // go back to the previous page
                window.history.back();
              }}
            />
            <Typography2 variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {currMap ? currMap.map_title_text : "-"}
            </Typography2>

            
            <div style={{ width: 20 }}></div>

            <LEARNINGOUTCOMEBOX
              planSetup={planSetup}
              handleUpdatePlanSetup={(newPlanSetup) => {
                props.handleUpdatePlanSetup(newPlanSetup);
              }}
            />




            {/* change topic */}
            {/*
            {showTopicChange ?
              <div
                style={{
                  display: 'none'
                }}>
                <div
                  style={{
                    width: 200,
                    marginLeft: 10,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FormControl variant="filled" sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="demo-simple-select-filled-label">
                      Choose Topic
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={sTopic}
                      label="Topic"
                      onChange={handleChange}
                      sx={{
                        width: 200,
                      }}
                      style={{
                        width: 200,
                        background: 'white'
                      }}
                    >
                      {(sTopics && sTopics.length) ?
                        sTopics.map((tpc5, idx) => {
                          return (
                            <MenuItem key={idx} value={tpc5.topic_id}>
                              {tpc5.topic_title}
                            </MenuItem>
                          );
                        })
                        :
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                      }

                    </Select>
                    
                  </FormControl>

                </div>

                <div style={{ marginLeft: 10 }}></div>
                <Button variant="contained"
                  onClick={() => {
                    setShowTopicChange(false);
                  }}
                >GO</Button>
              </div>

              :
              <></>
            }

          */}
          </div>
          

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/* <ConfirmAlertDialog
              handlePublishPlan={handlePublishPlan}
            /> */}

            {/* {!isOwnMap ? ( */}
            <ApprovePlanDialog
              handleApprovePlan={(comms) => props.handleApprovePlan(comms)}
              handleRejectPlan={(comms) => props.handleRejectPlan(comms)}
              currMap={currMap}
              planSetup={planSetup}
              isOwnMap={isOwnMap}
            />
            {/* ) : (
              <></>
            )} */}



            <div style={{ width: 10 }}></div>
            {/* MAIN GPT BUTTON */}
            <GPTMainGenerator
              planSetup={planSetup}
              namesDict={namesDict}
              onUpdatePlanSetup={(newPlanSetup) => {
                props.handleUpdatePlanSetup(newPlanSetup);

                // set all activities open
                props.onChangeAccordionHeaderStatusAll(true);
                /*
                props.onChangeAccordionHeaderStatus('action',true);
                props.onChangeAccordionHeaderStatus('analysis',true);
                props.onChangeAccordionHeaderStatus('application',true);
                props.onChangeAccordionHeaderStatus('assessment',true);
                */
              }}
              onChangeAccordionHeaderStatus={(name, checked) => {
                props.onChangeAccordionHeaderStatus(name, checked);
              }}
            />


            <div
              style={{
                width: 20,
              }}
            ></div>

            <ConfirmBasicModal
              handleSavePlan={() => handlePublishPlan(false)}
              handlePublishPlan={() => handlePublishPlan(true)}
              isSaving={isSaving}
              setIsSaving={(bool) => setIsSaving(bool)}
              handleDeletePlan={handleDeletePlan}
              open={confirmModalOpen}
              handleConfirmModalChange={(val) => handleConfirmModalChange(val)}
              openNextPlan={openNextPlan}
              handleNeutralPlan={() => props.handleNeutralPlan()}
              handleOpenNextPlan={(bool) => handleOpenNextPlan(bool)}
              planSetup={planSetup}
              isOwnMap={isOwnMap}
            />

            <div
              style={{
                width: 20,
              }}
            ></div>

            {/* next button */}
            <NextPlanDialog
              handleNextPlan={handleNextPlan}
              currMap={currMap}
              planSetup={planSetup}
              setShowTopicChange={(bool) => {
                setShowTopicChange(bool);
              }}

              openNextPlan={openNextPlan}
              openNewPlan={openNewPlan}
              handleOpenNextPlan={(bool) => handleOpenNextPlan(bool)}
              setOpenNewPlan={(bool) => setOpenNewPlan(bool)}
              planId={planId}
            />


            {/* new plan dialog */}
            <AddPlanFormDialog
              openNewPlan={openNewPlan}
              setOpenNewPlan={(bool) => setOpenNewPlan(bool)}
              planSetup={planSetup}
              planId={planId}
            />



            <div style={{ width: 10 }}></div>


            {/* DELETE BUTTON */}
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon style={{ color: 'white' }} />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    // maxHeight: 0,
                    // width: '20ch',
                  },
                }}
              >
                <MenuItem selected={false}>
                  <DelPlanDialog
                    handleDeletePlan={() => {
                      // close modal
                      setConfirmModalOpen(false);

                      // delete and navigate back
                      handleDeletePlan();
                    }}
                  />
                </MenuItem>

              </Menu>
            </div>
          </div>

          <Snackbar2
            open={saveSnackOpen}
            autoHideDuration={6000}
            onClose={handleSaveSnackClose}
          >
            <Alert3
              onClose={handleSaveSnackClose}
              severity="info"
              sx={{ width: "100%" }}
            >
              Saving plan..
            </Alert3>
          </Snackbar2>
          <Snackbar2
            open={successSnackOpen}
            autoHideDuration={6000}
            onClose={handleSuccessSnackClose}
          >
            <Alert3
              onClose={handleSuccessSnackClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Plan details saved successfully!
            </Alert3>
          </Snackbar2>
        </Toolbar2>
      </AppBar2>

      <div
        style={{
          margin: 10,
        }}
      ></div>

      <div>
        <CurriculumBreadcrumbs planData={planData}
          namesDict={namesDict}
        />
      </div>
    </Box2>
  );
}

const listItems1 = ["textfield", "resourcefield", "resourcefield"];

const listItems = [
  "Entertainment",
  "Private Time",
  "Rest",
  "Meal",
  "Exercise",
  "Work",
  "Home Projects",
  "Family",
];

function PickImageDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}> */}
      <div
        style={{
          border: "1px #DCDCDC solid",
          borderRadius: 10,
          width: 80,
          height: 80,
          background:
            'url("https://media.gettyimages.com/photos/green-cornfield-ready-for-harvest-late-afternoon-light-sunset-picture-id168351414?s=612x612")',
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        onClick={handleClickOpen}
      >
        {/* <img 
          src="https://media.gettyimages.com/photos/green-cornfield-ready-for-harvest-late-afternoon-light-sunset-picture-id168351414?s=612x612" 
          width={100}
          height={100}
          /> */}
      </div>
      {/* </Button> */}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose the picture, and crop it to your desire
          </DialogContentText>
          <UploadBox />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>UPLOAD</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const TITLEBOX = (props) => {
  const { planData } = props;
  return (
    <Typography variant="h3">
      {planData ? planData.lessonPlanSetup.name : "-"}
    </Typography>
  );
};

function BANNERMODAL(props) {
  const { planData, planSetup } = props;
  const [open, setOpen] = React.useState(props.open);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = React.useState(false);
  const handleClose2 = () => setOpen2(false);

  const action = (
    <React.Fragment>
      {/* <Button color="secondary" size="small" onClick={handleClose2}>
        OK
      </Button> */}
      <IconButton2
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose2}
      >
        <CloseIcon2 fontSize="small" />
      </IconButton2>
    </React.Fragment>
  );

  const onCropCompleted = (file) => {
    // upload to firebase and set as banner
  };

  const handleClick = () => {
    setOpen(true);
  };

  // const handleClose2 = (event: React.SyntheticEvent | Event, reason?: string) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }

  //   setOpen2(false);
  // };

  return (
    <div>
      {/* <Button2 onClick={handleOpen}>Open modal</Button2> */}
      <Modal2
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box2 sx={modalStyle}>
          {/* <Typography2 id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography2>
          <Typography2 id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography2> */}

          <BANNERBOX2
            planSetup={planSetup}
            planData={planData}
            onCropCompleted={(file) => onCropCompleted(file)}
            onCompletedUpload={(firebaseUrl) => {
              let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
              sPlanSetup.lessonPlanSetup.cover_image_url = firebaseUrl;

              // close modal
              handleClose();
              // update state
              props.handleUpdatePlanSetup(sPlanSetup);
              setOpen2(true);
            }}
            onUpdatePlanSetup={(newPlanSetup) =>
              props.handleUpdatePlanSetup(newPlanSetup)
            }
          />
        </Box2>
      </Modal2>

      <Snackbar
        open={open2}
        autoHideDuration={6000}
        onClose={handleClose2}
        message="Image processing complete"
        action={action}
      />
    </div>
  );
}

const BANNERBOX2 = (props) => {
  const { planData, planSetup } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [percent, setPercent] = useState(0);
  const [name, setName] = useState("");
  const [type, setType] = useState("");

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(
      "CurriculumSetup.js BANNERBOX2 onCropComplete: crop completed " +
      croppedArea,
      croppedAreaPixels
    );

    setCroppedAreaPixels(croppedAreaPixels);

    // wait for state to be set
    // setTimeout(function() {
    showCroppedImage(croppedAreaPixels);
    // }, 2000)
  }, []);

  async function srcToFile(src, fileName, mimeType) {
    return await fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        console.log("CurriculumSetup.js BANNERBOX srcToFile buf " + buf);

        const imageFile = new File([buf], fileName, { type: mimeType });
        handleUpload(imageFile);
      });
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleUpload = (imageFile) => {
    if (!imageFile) {
      alert("Please upload an image first!");
      return;
    }

    const storageRef = firebase.storage().ref(`/files/${imageFile.name}`); //.child(imageFile.name);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    // const uploadTask = uploadBytesResumable(storageRef, file);
    const uploadTask = storageRef.put(imageFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => {
        console.log("CurriculumSetup.js BANNERBOX2 handleUpload: error " + err);
      },
      () => {
        // download url
        storageRef
          .getDownloadURL() //uploadTask.snapshot.ref).then((url) => {
          .then(async (firebaseUrl) => {
            console.log(
              "CurriculumSetup.js BANNERBOX2 handleUpload: firebaseUrl " +
              firebaseUrl
            );

            // update the plan image with the URL
            let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
            newPlanSetup.lessonPlanSetup.cover_image_url = firebaseUrl;
            const resp3 = await LessonPlanSetupAPI.postUpdatePlanSetup(
              newPlanSetup.lessonPlanSetup
            );

            // setOpen(true)
            // trigger cropping modal

            // update the plan
            props.onCompletedUpload(firebaseUrl);
            props.onUpdatePlanSetup(planData);
          })
          .catch((e) => {
            console.log(
              "CurriculumSetup.js BANNERBOX2 handleUpload: exception " + e
            );
          });
      }
    );
  };

  const showCroppedImage = useCallback(
    async (croppedAreaPixels2) => {
      try {
        // this is the file
        const imgUrl = planData.lessonPlanSetup.cover_image_url;
        const croppedImage = await getCroppedImg(
          imgUrl,
          croppedAreaPixels2,
          rotation
        );
        console.log(
          "CurriculumSetup.js showCroppedImage: donee croppedImage: ",
          { croppedImage }
        );
        setCroppedImage(croppedImage);

        let name = makeid(12);
        let type = "";
        let ext = "";

        if (imgUrl.includes(".jpg") || imgUrl.includes(".jpeg")) {
          type = "image/jpeg";
          ext = "jpg";
        } else if (imgUrl.includes("png")) {
          type = "image/png";
          ext = "png";
        }

        if (type) {
          name = `${name}.${ext}`;
        }

        setName(name);
        setType(type);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, rotation]
  );

  const onOkClicked = () => {
    srcToFile(croppedImage, name, type);
  };

  return (
    <>
      <Cropper
        image={planData.lessonPlanSetup.cover_image_url}
        crop={crop}
        zoom={zoom}
        aspect={16 / 9}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />
      <div
        style={{
          position: "absolute",
          bottom: 10,
          right: 10,
          zIndex: 150,
        }}
      >
        <Button2
          mode="contained"
          onClick={() => onOkClicked(croppedImage, name, type)}
        >
          UPLOAD
        </Button2>
      </div>
    </>
  );
};

const BANNERBOX = (props) => {
  const { imageSrc, config3, targetRef, planData, planSetup } = props;
  const [open, setOpen] = React.useState(false);

  // State to store uploaded file
  const [file, setFile] = useState("");

  // progress
  const [percent, setPercent] = useState(0);
  const [progressSnackOpen, setProgressSnackOpen] = useState(false);

  let initialImage2 = initialImage;
  // if reloaded due to state update?
  if (
    planSetup &&
    planSetup.lessonPlanSetup &&
    planSetup.lessonPlanSetup.cover_image_url
  ) {
    initialImage2 = planSetup.lessonPlanSetup.cover_image_url;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleCloseProgressSnack = (event, reason) => {
    setProgressSnackOpen(false);
  };

  // Handle file upload event and update state
  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const handleUpload = (imageFile) => {
    if (!imageFile) {
      alert("Please upload an image first!");
    }

    const storageRef = firebase.storage().ref(`/files/${imageFile.name}`); //.child(imageFile.name);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    // const uploadTask = uploadBytesResumable(storageRef, file);
    const uploadTask = storageRef.put(imageFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => {
        console.log("CurriculumSetup.js BANNERBOX handleUpload: error " + err);
      },
      () => {
        // download url
        storageRef
          .getDownloadURL() //uploadTask.snapshot.ref).then((url) => {
          .then(async (firebaseUrl) => {
            console.log(
              "CurriculumSetup.js BANNERBOX handleUpload: firebaseUrl " +
              firebaseUrl
            );

            // update the plan image with the URL

            planData.lessonPlanSetup.cover_image_url = firebaseUrl;
            const resp3 = await LessonPlanSetupAPI.postUpdatePlanSetup(
              planData.lessonPlanSetup
            );

            setOpen(true);
            // trigger cropping modal
            props.onCompletedUpload(firebaseUrl);
          })
          .catch((e) => {
            console.log(
              "CurriculumSetup.js BANNERBOX handleUpload: exception " + e
            );
          });
      }
    );
  };

  async function srcToFile(src, fileName, mimeType) {
    return await fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        console.log("CurriculumSetup.js BANNERBOX srcToFile buf " + buf);

        const imageFile = new File([buf], fileName, { type: mimeType });
        handleUpload(imageFile);
      });
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  return (
    <div
      ref={targetRef}
      style={{
        width: "auto",
        height: 200,
      }}
    >
      <ReactImagePickerEditor
        config={config3}
        imageSrcProp={initialImage2}
        imageChanged={async (newDataUri: any) => {
          console.log(
            "CurriculumSetup.js BANNERBOX imageChanged callback: image " +
            newDataUri
          );
          props.setImageSrc(newDataUri);
          let type = newDataUri.substring(
            newDataUri.indexOf(":") + 1,
            newDataUri.indexOf(";")
          ); // => image/png
          console.log(
            "CurriculumSetup.js BANNERBOX imageChanged callback: type " + type
          );

          let fileName = makeid(10);

          if (type == "image/jpeg") fileName = fileName + ".jpg";
          else if (type == "image/png") fileName = fileName + ".png";
          else if (type == "image/gif") fileName = fileName + ".gif";
          else fileName = fileName + ".jpg";

          console.log(
            "CurriculumSetup.js BANNERBOX imageChanged callback: fileName " +
            fileName
          );

          // now upload to firebase and set the image url ready
          // convert to file
          // const imageBlob = dataURItoBlob(newDataUri);

          // console.log('CurriculumSetup.js BANNERBOX imageChanged callback: imageBlob ' + JSON.stringify(imageBlob));
          // const imageFile = dataURLtoFile(newDataUri, fileName);
          // fetch(newDataUri)
          //   .then(res => res.blob())
          //   .then(blob => {
          //     const imageFile = new File([blob], fileName)

          //   })

          // const imageFile = new File([imageBlob], imageBlob.name, {
          //   type: imageBlob.type,
          // });

          const imageFile = await srcToFile(newDataUri, fileName, type);
          // console.log('CurriculumSetup.js BANNERBOX imageFile ' + JSON.stringify(imageFile));
          // setFile(imageFile);

          // upload
          // handleUpload(imageFile);

          setProgressSnackOpen(true);
        }}
      />
      {/* 
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Cover image set successfully..
        </Alert>
      </Snackbar>
       */}
      <Snackbar
        open={progressSnackOpen}
        autoHideDuration={6000}
        onClose={handleCloseProgressSnack}
      >
        <Alert
          onClose={handleCloseProgressSnack}
          severity="warning"
          sx={{ width: "100%" }}
        >
          Image is getting ready for processing.. please wait!!
        </Alert>
      </Snackbar>
    </div>
  );
};

const MARGINBOX = (props) => {
  const { value } = props;
  return (
    <div
      style={{
        margin: value,
      }}
    ></div>
  );
};

const GroupSelectChips = (props) => {
  const { type, planSetup } = props;
  let type2 = 1; // first

  if (type == "analysis") {
    type2 = planSetup.analysis.participantCount;
  } else if (type == "application") {
    type2 = planSetup.application.participantCount;
  }

  // override problem
  if (!type2 || type2 < 1) {
    type2 = 1;
  }

  console.log("CurriculumSetup.js GroupSelectChips type2: " + type2);

  const handleChange = (newType2) => {
    // setAge(event.target.value);
    // let newType2 = event.target.value;

    props.handleGroupChange(type, newType2);
  };

  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };

  return (
    <>
      <Typography2
        variant="body"
        style={{
          color: "white",
          marginRight: 5,
        }}
      >
        Select Participants
      </Typography2>

      <Chip
        sx={{
          borderRadius: 5,
        }}
        style={{
          borderRadius: 5,
          marginRight: 5,
        }}
        label="One"
        onClick={() => handleChange(1)}
        icon={
          <PersonIcon2
            style={{
              color: type2 == 1 ? "black" : "white",
            }}
          />
        }
        onDelete={handleDelete}
        deleteIcon={
          type2 == 1 ? (
            <DoneIcon2
              style={{
                color: "black",
              }}
            />
          ) : (
            <></>
          )
        }
        variant={type2 == 1 ? "default" : "outlined"}
      />

      <Chip
        sx={{
          borderRadius: 5,
        }}
        style={{
          borderRadius: 5,
          marginRight: 5,
        }}
        label="Two"
        onClick={() => handleChange(2)}
        icon={
          <GroupIcon2
            style={{
              // background: 'white',
              color: type2 == 2 ? "black" : "white",
            }}
          />
        }
        onDelete={handleDelete}
        deleteIcon={
          type2 == 2 ? (
            <DoneIcon2
              style={{
                color: "black",
              }}
            />
          ) : (
            <></>
          )
        }
        variant={type2 == 2 ? "default" : "outlined"}
      />

      <Chip
        sx={{
          borderRadius: 5,
        }}
        style={{
          borderRadius: 5,
          marginRight: 5,
        }}
        label="Group"
        onClick={() => handleChange(3)}
        icon={
          <GroupsIcon2
            style={{
              color: type2 == 3 ? "black" : "white",
            }}
          />
        }
        onDelete={handleDelete}
        deleteIcon={
          type2 == 3 ? (
            <DoneIcon2
              style={{
                color: "black",
              }}
            />
          ) : (
            <></>
          )
        }
        variant={type2 == 3 ? "default" : "outlined"}
      />
    </>
  );
};

const MainPage = (props) => {
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [modalOpen, setModalOpen] = useState(false);
  const { planId } = props;

  const mapId = useSelector((state) => state.currMapId.currMapId);
  const query = new URLSearchParams(window.location.search);
  const instId = query.get("inst_id");
  const board_id = query.get("board_id");
  const clsStd = query.get("class_std");
  const subId = query.get("sub_id");

  const { checkedList, planSetup, config3, planData, namesDict } = props;

  let showAim = false;
  let showAct = false;
  let showAna = false;
  let showAppl = false;
  let showAssm = false;

  console.log("CurriculumSetup.js MainPage checkedList ", checkedList);
  // const size = useDimensions(targetRef);
  // console.log('CurriculumSetup.js MainPage size ', size);

  // see what is checked
  if (checkedList && checkedList.length) {
    checkedList.map((itm) => {
      if (itm.name === Constants.Application.NAME_AIM) {
        if (itm.checked) showAim = true;
      }
      if (itm.name === Constants.Application.NAME_ACTION) {
        if (itm.checked) showAct = true;
      }
      if (itm.name === Constants.Application.NAME_ANALYSIS) {
        if (itm.checked) showAna = true;
      }
      if (itm.name === Constants.Application.NAME_APPLICATION) {
        if (itm.checked) showAppl = true;
      }
      if (itm.name === Constants.Application.NAME_ASSESSMENT) {
        if (itm.checked) showAssm = true;
      }
    });
  }

  console.log("CurriculumSetup.js MainPage before render showAim " + showAim);
  console.log("CurriculumSetup.js MainPage before render showAct " + showAct);
  console.log("CurriculumSetup.js MainPage before render showAppl " + showAppl);
  console.log("CurriculumSetup.js MainPage before render showAna " + showAna);
  console.log("CurriculumSetup.js MainPage before render showAssm " + showAssm);

  let showNothing = true;
  if (showAim || showAct || showAna || showAppl || showAssm) {
    showNothing = false;
  }

  useLayoutEffect(() => {
    console.log("CurriculumSetup.js MainPage useLayoutEffect: called..");
    if (targetRef.current) {
      console.log(
        "CurriculumSetup.js MainPage useLayoutEffect: targetRef width " +
        targetRef.current.offsetWidth
      );
      console.log(
        "CurriculumSetup.js MainPage useLayoutEffect: targetRef width " +
        targetRef.current.offsetWidth
      );

      let newWidth = parseInt(targetRef.current.offsetWidth);
      let newHeight = parseInt((newWidth * BANNER_HEIGHT) / BANNER_WIDTH);

      let config3c: ImagePickerConf = {
        borderRadius: "8px",
        language: "en",
        // width: '330px',
        width: `${newWidth}px`,
        height: `${newHeight}px`,
        objectFit: "contain",
        compressInitial: null,
        hideDeleteBtn: true,
        hideDownloadBtn: true,
        hideEditBtn: true,
        hideAddBtn: true,
      };

      props.setConfig3(config3c);
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  }, []);

  const onRemoveActvClicked = (actv) => {
    const newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    console.log("CurriculumSetup.js MainPage onRemoveActvClicked: clicked");

    let newList = [];
    let name3 = "";
    let checked3 = false;

    // update the plansetup item
    switch (actv) {
      case "aim":
        newPlanSetup.aim.isChecked = false;
        // disable all aim
        newPlanSetup.aim.summary = "";
        newPlanSetup.aim.description = "";
        newPlanSetup.aim.hasResource = false;
        newPlanSetup.aim.hasKeyword = false;
        break;
      case "action":
        newPlanSetup.action.isChecked = false;
        newPlanSetup.action.summary = "";
        newPlanSetup.action.description = "";
        newPlanSetup.action.hasSteps = false;

        break;
      case "analysis":
        newPlanSetup.analysis.summary = "";
        newPlanSetup.analysis.description = "";
        newPlanSetup.analysis.isChecked = false;
        newPlanSetup.analysis.hasParts = false;
        break;
      case "application":
        newPlanSetup.application.summary = "";
        newPlanSetup.application.description = "";
        newPlanSetup.application.isChecked = false;
        newPlanSetup.application.hasClasswork = false;
        newPlanSetup.application.hasHomework = false;
        newPlanSetup.application.hasMorePractice = false;
        break;
      case "assessment":
        newPlanSetup.assessment.summary = "";
        newPlanSetup.assessment.description = "";
        newPlanSetup.assessment.isChecked = false;
        newPlanSetup.assessment.hasApproachingText = false;
        newPlanSetup.assessment.hasMeetingText = false;
        newPlanSetup.assessment.hasExceedingText = false;
        break;
    }

    checkedList.map((itm, idx) => {
      name3 = itm.name;
      checked3 = itm.checked; // keep old value intact

      if (name3 === actv) {
        checked3 = false; // update
      }

      newList.push({
        name: name3,
        checked: checked3,
      });
    });

    console.log(
      "CurriculumSetup.js MainPage onRemoveActvClicked: updated newPlanSetup " +
      JSON.stringify(newPlanSetup)
    );

    // now update state
    props.handleUpdateCheckedList(newList);
    props.handleUpdatePlanSetup(newPlanSetup);
  };

  useEffect(() => {
    console.log("CurriculumSetup.js MainPage useEffect: rendering again..");
  }, []);

  // verify and check what has to be shown
  return (
    <div
      style={{
        width: "59%",
        marginLeft: 10,
        minHeight: "100%",
        position: "relative",
      }}
      ref={targetRef}
    >
      {/* TITLE AND DESCRIPTION */}
      <TITLEBOX planData={planData} />
      <MARGINBOX value={10} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: 250,
        }}
      >
        <div
          style={{
            width: BANNER_WIDTH,
            height: 250,
          }}
        >
          {/* BANNER */}
          {/* {!modalOpen ? */}
          <BANNERBOX
            setImageSrc={(src) => props.setImageSrc(src)}
            config3={config3}
            targetRef={targetRef}
            planData={planData}
            planSetup={planSetup}
            onCompletedUpload={(src) => {
              // onCompletedUpload(src) // pass this to the modal banner
              setModalOpen(true);
            }}
          />
        </div>


        <div
          style={{
            width: '35%',
            marginLeft: 20,
          }}
        >
          <Typography2 variant="h6">Steps to follow</Typography2>
          <INSTRUCTIONS />
        </div>



      </div>

      {/* : */}
      {/* <></> */}
      {/* } */}

      <MARGINBOX value={100} />

      {modalOpen ? (
        <>
          <BANNERMODAL
            planData={planData}
            planSetup={planSetup}
            open={modalOpen}
            onModalClose={() => setModalOpen(false)}
            onCompletedUpload={(src) => {
              // onCompletedUpload(src) // pass this to the modal banner

              // this is main banner image
              let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
              setModalOpen(false);

              props.handleUpdatePlanSetup(sPlanSetup);
            }}
            handleUpdatePlanSetup={(newPlanSetup) =>
              props.handleUpdatePlanSetup(newPlanSetup)
            }
          />
        </>
      ) : (
        <></>
      )}

      {showAim ? (
        <>
          <div className="section aimsection" ref={props.aimSection}>
            <div
              style={{
                backgroundColor: Constants.Application.COLOR_INFO,
                padding: 10,
                position: "sticky",
                top: 0,
                zIndex: 5,
                paddingLeft: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography2 variant="h6" color={"white"}>
                  OUTCOME
                </Typography2>

                <DeleteForeverIcon2
                  sx={{
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    onRemoveActvClicked("aim");
                  }}
                />
              </div>
            </div>
            {/* AIM */}

            <AIMLIST
              planSetup={planSetup}
              namesDict={namesDict}
              onAddResourceClicked={() => props.onAddResourceClicked()}
              onDeleteResourceItem={(idx) => props.onDeleteResourceItem(idx)}
              onAddAimKeywClicked={() => props.onAddAimKeywClicked()}
              onDeleteAimKeywItem={(idx) => props.onDeleteAimKeywItem(idx)}
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
            />

            <div
              style={{
                margin: 20,
              }}
            ></div>
          </div>
        </>
      ) : (
        <></>
      )}

      {showAct ? (
        <>
          <div className="section actsection" ref={props.actSection}>
            <div
              style={{
                backgroundColor: Constants.Application.COLOR_INFO,
                padding: 10,
                position: "sticky",
                top: 0,
                zIndex: 6,
                paddingLeft: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <Typography2
                  variant="h6"
                  style={{
                    width: "50%",
                  }}
                  color={"white"}
                >
                  LEARNING PATH
                </Typography2>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <TimeBox
                    type="action"
                    planSetup={planSetup}
                    handleMinsChange={(type, mins) =>
                      props.handleMinsChange(type, mins)
                    }
                  />

                  <div style={{ margin: 15 }}></div>

                  <DeleteForeverIcon2
                    sx={{
                      cursor: "pointer",
                      color: "white",
                    }}
                    onClick={() => onRemoveActvClicked("action")}
                  />
                </div>
              </div>
            </div>
            <ACTIONLIST
              planSetup={planSetup}
              namesDict={namesDict}
              onAddActionStepClicked={() => props.onAddActionStepClicked()}
              onDelActionStepClicked={(stepIdx) =>
                props.onDelActionStepClicked(stepIdx)
              }
              onAddStepImage={(stepIdx, idx) =>
                props.onAddStepImage(stepIdx, idx)
              }
              onDeleteStepImage={(stepIdx, idx) =>
                props.onDeleteStepImage(stepIdx, idx)
              }
              onDeleteStepVid={(stepIdx, idx) =>
                props.onDeleteStepVid(stepIdx, idx)
              }
              onAddStepVid={(stepIdx, url) => props.onAddStepVid(stepIdx, url)}
              onDeleteStepDoc={(stepIdx, idx) =>
                props.onDeleteStepDoc(stepIdx, idx)
              }
              onAddStepDoc={(stepIdx, url) => props.onAddStepDoc(stepIdx, url)}
              onAddStepTip={(stepIdx, title, desc) =>
                props.onAddStepTip(stepIdx, title, desc)
              }
              onDeleteStepTip={(stepIdx, idx) =>
                props.onDeleteStepTip(stepIdx, idx)
              }
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
            />
          </div>
        </>
      ) : (
        <></>
      )}

      {/* ANALYSIS */}
      {showAna ? (
        <>
          <div className="section anasection" ref={props.anaSection}>
            <div
              style={{
                backgroundColor: Constants.Application.COLOR_INFO,
                padding: 10,
                position: "sticky",
                top: 0,
                zIndex: 6,
                paddingLeft: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <Typography2
                  variant="h6"
                  style={{
                    width: "30%",
                  }}
                  color={"white"}
                >
                  PARTICIPATE
                </Typography2>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "70%",
                  }}
                >
                  <GroupSelectChips
                    type="analysis"
                    planSetup={planSetup}
                    handleGroupChange={(type, count) =>
                      props.handleGroupChange(type, count)
                    }
                  />

                  {/* 
                  <GroupSelect
                    // sx={{
                    //   color: 'white',
                    //   border: '1px white solid'
                    // }}
                    type="analysis"
                    planSetup={planSetup}
                    handleGroupChange={(type, count) => props.handleGroupChange(type, count)}

                  /> */}

                  <div style={{ margin: 15 }}></div>

                  <TimeBox
                    type="analysis"
                    planSetup={planSetup}
                    handleMinsChange={(type, mins) =>
                      props.handleMinsChange(type, mins)
                    }
                  />

                  <div style={{ margin: 15 }}></div>

                  <DeleteForeverIcon2
                    sx={{
                      cursor: "pointer",
                      color: "white",
                    }}
                    onClick={() => onRemoveActvClicked("analysis")}
                  />
                </div>
              </div>
            </div>

            <ANALYSISLIST
              planSetup={planSetup}
              namesDict={namesDict}
              onAddPartClicked={() => props.onAddPartClicked()}
              onDeleteAnaPartClicked={(idx) => {
                props.onDeleteAnaPartClicked(idx);
              }}
              onAddAnaPartExp={(num, title, desc) =>
                props.onAddAnaPartExp(num, title, desc)
              }
              onAddAnaPartClicked={() => props.onAddAnaPartClicked()}
              onDelAnaPartExp={(partIdx, idx) =>
                props.onDelAnaPartExp(partIdx, idx)
              }
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
              // quiz required params
              subId={subId}
              subSubId={subId}
              chapId={props.chapId}
              unitId={props.unitId}
            />

            <div
              style={{
                margin: 20,
              }}
            ></div>
          </div>
        </>
      ) : (
        <></>
      )}

      {/* APPLICATION */}
      {showAppl ? (
        <>
          <div className="section anasection" ref={props.appSection}>
            <div
              style={{
                backgroundColor: Constants.Application.COLOR_INFO,
                padding: 10,
                position: "sticky",
                top: 0,
                zIndex: 7,
                paddingLeft: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <Typography2
                  variant="h6"
                  style={{
                    width: "30%",
                  }}
                  color={"white"}
                >
                  EVALUATE
                </Typography2>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "70%",
                  }}
                >
                  <GroupSelectChips
                    type="application"
                    planSetup={planSetup}
                    handleGroupChange={(type, count) =>
                      props.handleGroupChange(type, count)
                    }
                  />

                  {/* 
                  <GroupSelect
                    planSetup={planSetup}
                    type="application"
                    handleGroupChange={(type, count) => props.handleGroupChange(type, count)}

                  />
                   */}

                  <div style={{ margin: 15 }}></div>

                  <TimeBox
                    planSetup={planSetup}
                    type="application"
                    handleMinsChange={(type, mins) =>
                      props.handleMinsChange(type, mins)
                    }
                  />

                  <div style={{ margin: 15 }}></div>

                  <DeleteForeverIcon2
                    sx={{
                      cursor: "pointer",
                      color: "white",
                    }}
                    onClick={() => onRemoveActvClicked("application")}
                  />
                </div>
              </div>

              {/* parts */}
            </div>

            {/* <StepField /> */}

            <APPLICATIONLIST
              planSetup={planSetup}
              namesDict={namesDict}
              onAddClassworkQstTip={() => props.onAddClassworkQstTip()}
              onDelClassworkQTip={(index) => props.onDelClassworkQTip(index)}
              onAddPracStepClicked={() => props.onAddPracStepClicked()}
              onDelPracStepClicked={(stepIdx) =>
                props.onDelPracStepClicked(stepIdx)
              }
              onDeletePracStepImage={(stepIdx, idx) =>
                props.onDeletePracStepImage(stepIdx, idx)
              }
              onAddPracStepImage={(stepIdx, url) =>
                props.onAddPracStepImage(stepIdx, url)
              }
              onDeletePracStepVid={(stepIdx, idx) =>
                props.onDeletePracStepVid(stepIdx, idx)
              }
              onAddPracStepVid={(stepIdx, url) =>
                props.onAddPracStepVid(stepIdx, url)
              }
              onDeletePracStepDoc={(stepIdx, idx) =>
                props.onDeletePracStepDoc(stepIdx, idx)
              }
              onAddPracStepDoc={(stepIdx, url) =>
                props.onAddPracStepDoc(stepIdx, url)
              }
              onAddPracStepTip={(stepIdx, title, desc) =>
                props.onAddPracStepTip(stepIdx, title, desc)
              }
              onDeletePracStepTip={(stepIdx, idx) =>
                props.onDeletePracStepTip(stepIdx, idx)
              }
              onDelHomeworkImage={(idx) => props.onDelHomeworkImage(idx)}
              onAddHomeworkImage={() => props.onAddHomeworkImage()}
              onAddHomeworkQstTip={() => props.onAddHomeworkQstTip()}
              onDelHomeworkQTip={(index) => props.onDelHomeworkQTip(index)}
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
              topicId={props.topicId}
              chapId={props.chapId}
              unitId={props.unitId}
            />

            <div
              style={{
                margin: 20,
              }}
            ></div>
          </div>
        </>
      ) : (
        <></>
      )}

      {/* ASSESSMENT */}
      {showAssm ? (
        <>
          <div className="section assmsection" ref={props.assmSection}>
            <div
              style={{
                backgroundColor: Constants.Application.COLOR_INFO,
                padding: 10,
                position: "sticky",
                top: 0,
                zIndex: 7,
                paddingLeft: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography2
                  variant="h6"
                  style={{
                    width: "50%",
                  }}
                  color={"white"}
                >
                  REFLECTION
                </Typography2>

                <DeleteForeverIcon2
                  sx={{
                    cursor: "pointer",
                    color: "white",
                  }}
                  onClick={() => onRemoveActvClicked("assessment")}
                />
              </div>

              {/* parts */}
            </div>

            {/* <StepField /> */}

            <ASSESSMENTLIST
              planSetup={planSetup}
              namesDict={namesDict}
              planId={planId}
              subId={subId}
              subSubId={subId}
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
              chapId={props.chapId}
              unitId={props.unitId}
            />
          </div>
        </>
      ) : (
        <></>
      )}

      {/* display alert if nothing was selected */}
      {showNothing ? (
        <>
          <Alert variant="outlined" severity="warning">
            No resources have been added yet - start adding now!
          </Alert>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

function RelatedLesson(props) {
  const { lsn, idx } = props;


  // get params from URLSearchParams object
  const params = new URLSearchParams(window.location.search);
  const instId5 = params.get('inst_id');
  const boardId5 = params.get('board_id');
  const clsStd5 = params.get('class_std');
  const subId5 = params.get('sub_id');
  const chapId5 = params.get('chap_id');
  const mapId5 = params.get('map_id');
  const unitId5 = params.get('unit_id');
  const lsnId5 = params.get('lesson_id');

  console.log(TAG, 'instId5', instId5);
  console.log(TAG, 'boardId5', boardId5);
  console.log(TAG, 'chapId5', chapId5);

  console.log(TAG, 'Related Lesson', JSON.stringify(lsn));
  const { name, plan_description, topic_id, lesson_plan_setup_id, cover_image_url } = lsn;

  const history = useHistory();
  return (
    <>
      <ListItem2 alignItems="flex-start"
        onClick={() => {
          // take him to that page
          const URL = `/publisher-ebook/curriculum-map/set-up/${lsn.lesson_plan_setup_id}/?map_id=${mapId5}&inst_id=${instId5}&board_id=${boardId5}&class_std=${clsStd5}&sub_id=${subId5}&unit_id=${unitId5}&lesson_id=${lsn.lesson_plan_setup_id}&chap_id=${chapId5}`
          //      history.push(URL)
          window.location.assign(URL);
        }}
        style={{
          cursor: 'pointer',
        }}
      >
        <ListItemAvatar2>
          <Avatar>{(idx + 1)}</Avatar>
        </ListItemAvatar2>
        <ListItemText2
          primary={name}
          secondary={
            <React.Fragment>
              <Typography2
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {plan_description}
              </Typography2>
              &nbsp; - Lesson Plan #{(idx + 1)}
            </React.Fragment>
          }
        />
      </ListItem2>
      <Divider2 variant="inset" component="li" />
    </>
  )
}

function RelatedLessonsList(props) {
  const { relatedLessons } = props;

  if (!relatedLessons || !relatedLessons.length) {
    return (
      <>
        <Alert2 severity="secondary">No related lessons found — prepare some now!</Alert2>
      </>
    )
  }
  return (
    <List2 sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {relatedLessons.map((lsn, idx) => {
        return <RelatedLesson
          lsn={lsn}
          key={idx}
          idx={idx}
        />
      })}
      {/*
      <ListItem2 alignItems="flex-start">
        <ListItemAvatar2>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        </ListItemAvatar2>
        <ListItemText2
          primary="Brunch this weekend?"
          secondary={
            <React.Fragment>
              <Typography2
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Ali Connors
              </Typography2>
              {" — I'll be in your neighborhood doing errands this…"}
            </React.Fragment>
          }
        />
      </ListItem2>
      <Divider2 variant="inset" component="li" />
      <ListItem2 alignItems="flex-start">
        <ListItemAvatar2>
          <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
        </ListItemAvatar2>
        <ListItemText2
          primary="Summer BBQ"
          secondary={
            <React.Fragment>
              <Typography2
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                to Scott, Alex, Jennifer
              </Typography2>
              {" — Wish I could come, but I'm out of town this…"}
            </React.Fragment>
          }
        />
      </ListItem2>
      <Divider2 variant="inset" component="li" />
      <ListItem2 alignItems="flex-start">
        <ListItemAvatar2>
          <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
        </ListItemAvatar2>
        <ListItemText2
          primary="Oui Oui"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Sandra Adams
              </Typography>
              {' — Do you have Paris recommendations? Have you ever…'}
            </React.Fragment>
          }
        />
      </ListItem2>
      */}
    </List2>
  );
}

const RelatedLessons = (props) => {
  const { planSetup } = props;
  const { lessonPlanSetup } = planSetup;
  const [relatedLessons, setRelatedLessons] = useState([]);

  async function _getRelatedLessons() {
    const blockId = lessonPlanSetup.lesson_id;
    const resp = await LessonPlanSetupAPI.getPlans(blockId);

    console.log(TAG, "RelatedLessons response ", JSON.stringify(resp));

    const data = (resp.data && resp.data.data) ? resp.data.data : null;
    setRelatedLessons(data);

  }

  useEffect(() => {

    _getRelatedLessons()
    return () => {

    }
  }, [])

  return (
    <>
      <Typography2
        variant="h6">Related Lesson Plans</Typography2>
      <RelatedLessonsList
        relatedLessons={relatedLessons} />
    </>
  )
}

const TimeBox = (props) => {
  const { planSetup, type } = props;
  const classes = useStyles2();

  let type2 = 1; // value

  if (type == "analysis") {
    type2 = planSetup.analysis.mins;
  } else if (type == "application") {
    type2 = planSetup.application.mins;
  } else if (type == "action") {
    type2 = planSetup.action.mins;
  }

  const handleChange = (event) => {
    const val = event.target.value;
    props.handleMinsChange(type, val);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      style={{
        marginLeft: 10,
      }}
    >
      <AccessTimeIcon2 sx={{ color: "white", mr: 1 }} />
      <TextField
        className={classes.root}
        value={type2}
        id="outlined-basic"
        label="Mins"
        variant="outlined"
        type="number"
        min={1}
        max={10}
        size="small"
        color="white"
        style={{
          width: 80,
          color: "white",
          borderColor: "white",
        }}
        sx={{
          "& .MuiInputLabel-root": { color: "white" },
          border: "1px solid white",
          borderRadius: 1,
          borderColor: "white",
        }}
        InputProps={{
          classes: {
            notchedOutline: inputStyles.notchedOutline,
          },
        }}
        onChange={handleChange}
      />
    </Box>
  );
};

function GroupSelect(props) {
  const classes = useStyles2();
  const [age, setAge] = React.useState("");
  const { type, planSetup } = props;

  let type2 = 1; // first

  if (type == "analysis") {
    type2 = planSetup.analysis.participantCount;
  } else if (type == "application") {
    type2 = planSetup.application.participantCount;
  }

  // override problem
  if (!type2 || type2 < 1) {
    type2 = 1;
  }

  console.log("CurriculumSetup.js GroupSelect type2: " + type2);

  const handleChange = (event) => {
    setAge(event.target.value);
    let newType2 = event.target.value;

    props.handleGroupChange(type, newType2);
  };

  return (
    <>
      {/* <span>Select group</span> */}
      <Box2 sx={{ minWidth: 220 }}>
        <FormControl2 fullWidth className={classes.root}>
          <InputLabel2
            sx={{
              color: "white",
            }}
            id="demo-simple-select-label"
          >
            Participation
          </InputLabel2>
          <Select2
            // sx={{
            //   color: 'white',
            //   borderColor: 'white'
            // }}
            color="info"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={1}
            value={type2}
            label="Participation"
            onChange={handleChange}
            size="small"
          // style={{
          //   borderColor: 'white'
          // }}
          >
            <MenuItem2 value={1}>Single</MenuItem2>
            <MenuItem2 value={2}>Pair</MenuItem2>
            <MenuItem2 value={3}>Group</MenuItem2>
            {/* <MenuItem2 value={3}>10</MenuItem2> */}
          </Select2>
        </FormControl2>
      </Box2>
    </>
  );
}

const ASSESSMENTLIST2 = (props) => {
  const { planSetup } = props;
  return (
    <>
      <div
        style={{
          margin: 10,
        }}
      ></div>
      <BriefField
        name="Assessment"
        planSetup={planSetup}
        handleUpdatePlanSetup={(newPlanSetup) =>
          props.handleUpdatePlanSetup(newPlanSetup)
        }
      />
      <div
        style={{
          margin: 10,
        }}
      ></div>

      {/* <WorkbookItem desc="Check Worksheet" /> */}
      <div
        style={{
          margin: 10,
        }}
      ></div>

      <TEXTBOX3 num={1} desc="Enter Approaching Text" />

      <div
        style={{
          margin: 20,
        }}
      ></div>

      <TEXTBOX3 num={1} desc="Enter Meeting Text" />

      <div
        style={{
          margin: 20,
        }}
      ></div>

      <TEXTBOX3 num={1} desc="Enter Exceeding Text" />
    </>
  );
};

function BriefField(props) {
  const { name, planSetup } = props;

  const handleChange = (event) => {
    const { name2, value } = event.target;

    console.log("CurriculumSetup.js BriefField handleChange name is " + name);
    console.log("CurriculumSetup.js BriefField handleChange name2 " + name2);
    console.log("CurriculumSetup.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    switch (name.toLowerCase()) {
      case "aim":
        newPlanSetup.aim.summary = value;

        break;
      case "action":
        newPlanSetup.action.summary = value;

        break;
      case "analysis":
        newPlanSetup.analysis.summary = value;

        break;
      case "application":
        newPlanSetup.application.summary = value;

        break;
      case "assessment":
        newPlanSetup.assessment.summary = value;

        break;
    }

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  return (
    <Card2 sx={{ minWidth: "100%" }}>
      <CardContent2>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SUBHEADING value={`Teacher Summary for ${name}`} />
          </div>

          <Box
            sx={{
              // width: 500,
              maxWidth: "100%",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              label="Enter summary"
              placeholder="Enter the text here"
              id="fullWidth"
              variant="standard"
              onChange={handleChange}
            />
          </Box>
        </div>
      </CardContent2>
    </Card2>
  );
}

const TEXTBOX3 = (props) => {
  const { num, desc } = props;
  return (
    <>
      <Card2 sx={{ minWidth: "100%" }}>
        <CardContent2>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <SUBHEADING value={desc} />

              <ClearIcon
                color="warning"
                style={{
                  cursor: "pointer",
                }}
              />
            </div>

            <Box
              sx={{
                // width: 500,
                maxWidth: "100%",
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TextField
                fullWidth
                label="Enter the text"
                placeholder="Enter the text here"
                id="fullWidth"
                variant="outlined"
              />
            </Box>
          </div>
        </CardContent2>
      </Card2>
    </>
  );
};

const UploadBox = () => {
  const profilePictureRef = React.createRef();

  const handleUpload = () => {
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();

    //add here the upload logic...
  };

  return (
    <>
      <div
        style={
          {
            // height: 200
          }
        }
      >
        <ProfilePicture ref={profilePictureRef} useHelper={true} debug={true} />

        <div
          style={{
            alignSelf: "right",
          }}
        >
          <Button2 onClick={handleUpload}>Upload</Button2>
        </div>
      </div>
    </>
  );
};

const HEADING = (props) => {
  const { value } = props;

  return (
    <>
      <Typography variant="h4">{value}</Typography>
    </>
  );
};

const SUBHEADING = (props) => {
  const { value } = props;

  return (
    <>
      <Typography variant="subtitle1">{value}</Typography>
    </>
  );
};

function UnitLeftCard(props) {
  const { planSetup } = props;

  useEffect(() => {
    console.log("CurriculumSetup.js UnitLeftCard useEffect: rendering again..");
  }, []);

  return (
    <Card2
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "24%",
      }}
      style={{
        width: "24%",
        marginLeft: 10,
        position: "sticky",
        top: 0,
      }}
    >
      <CardContent2>
        <ActivityLeftBar
          checkedList={props.checkedList}
          onChangeAccordionHeaderStatus={(name, checked) =>
            props.onChangeAccordionHeaderStatus(name, checked)
          }
          planSetup={planSetup}
          handleToggleResourceCheck={(name, type, checked) =>
            props.handleToggleResourceCheck(name, type, checked)
          }
          onUpdateRightItemSubChecked={(type, name, sub, isChecked2) =>
            props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)
          }
          onUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
          scrollToAim={props.scrollToAim}
          scrollToAction={props.scrollToAction}
          scrollToAnalysis={props.scrollToAnalysis}
          scrollToApplication={props.scrollToApplication}
          scrollToAssessment={props.scrollToAssessment}
        />
      </CardContent2>
      {/* <CardActions2>
        <Button2 size="small">Learn More</Button2>
      </CardActions2> */}
    </Card2>
  );
}

/* MAIN COMPONENT OF PAGE */
const CurriculumSetup = (props) => {
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });

  const classes = useStyles1();
  const [unitData, setUnitData] = useState(null);
  const [lessonData, setlessonData] = useState(null);
  const currMap = useSelector((state) => state.currMap.currMap);
  const currUnit = useSelector((state) => state.currUnit.currUnit);

  const [currMapId, setCurrMapId] = useState(0);
  const unitLessons = useSelector((state) => state.unitLessons.unitLessons);
  const lessonPlans = useSelector((state) => state.lessonPlans.lessonPlans);
  const [config3, setConfig3] = useState(config3b);
  //  const mapId = (currMap != null) ? currMap.map_id : 0

  const dispatch = useDispatch();

  const unitMapDetailsGet = useSelector((state) => state.unitMap);
  const { loadingUnit, UnitMap, errorMap } = unitMapDetailsGet;
  const [firstTime, setFirstTime] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const [checkedList, setCheckedList] = useState([]);

  const [aimSummary, setAimSummary] = useState("");
  const [aimDesc, setAimDesc] = useState("");
  const [aimResList, setAimResList] = useState([]);
  const [aimKeywList, setAimKeywList] = useState([]);
  const [planSetup, setPlanSetup] = useState(null);
  const [planData, setPlanData] = useState(null); // dynamically built
  const [loadingPlan, setLoadingPlan] = useState(true);
  const [isOwnMap, setIsOwnMap] = useState(false);
  const [isONeutral, setIONeutral] = useState(false);

  /* names for gpt */
  const [namesDict, setNamesDict] = useState({});
  const [chapName5, setChapName5] = useState('');
  const [topName5, setTopName5] = useState('');
  const [subTopName5, setSubTopName5] = useState('');
  const [subName5, setSubName5] = useState('');
  const [subSubName5, setSubSubName5] = useState('');
  const [brdName5, setBrdName5] = useState('');

  const [offlineModeSnackOpen, setOfflineModeSnackOpen] = useState(false);
  const planId = props.match.params.id;

  const aimSection = useRef(null);
  const actSection = useRef(null);
  const anaSection = useRef(null);
  const appSection = useRef(null);
  const assmSection = useRef(null);

  let mapId = 0;
  let unitId = 0;
  let lsnId = 0;
  let topicId = 0;
  let chapId = 0;
  let instId = 0;
  let board_id = 0;
  let clsStd = 0;
  let subId = 0;

  console.log("unit details", UnitMap);
  console.log(`lessons data ${JSON.stringify(unitLessons)}`);

  const scrollToAim = () => {
    console.log("CurriculumSetup.js scrollToAim: scrolling to aim");
    window.scrollTo({
      top: aimSection.current ? aimSection.current.offsetTop : 0,
      behavior: "smooth",
    });

    // scroll the element
    if (aimSection.current) aimSection.current.scrollIntoView();
  };

  const scrollToAction = () => {
    console.log("CurriculumSetup.js scrollToAction: scrolling to action");
    window.scrollTo({
      top: actSection.current ? actSection.current.offsetTop : 0,
      behavior: "smooth",
    });

    // scroll the element
    if (actSection.current) actSection.current.scrollIntoView();
  };

  const scrollToAnalysis = () => {
    console.log("CurriculumSetup.js scrollToAnalysis: scrolling to analysis");
    window.scrollTo({
      top: anaSection.current ? anaSection.current.offsetTop : 0,
      behavior: "smooth",
    });

    // scroll the element
    if (anaSection.current) anaSection.current.scrollIntoView();
  };

  const scrollToApplication = () => {
    console.log(
      "CurriculumSetup.js scrollToApplication: scrolling to application"
    );
    window.scrollTo({
      top: appSection.current ? appSection.current.offsetTop : 0,
      behavior: "smooth",
    });

    // scroll the element
    if (appSection.current) appSection.current.scrollIntoView();
  };

  const scrollToAssessment = () => {
    console.log(
      "CurriculumSetup.js scrollToAssessment: scrolling to assessment"
    );
    window.scrollTo({
      top: assmSection.current ? assmSection.current.offsetTop : 0,
      behavior: "smooth",
    });

    // scroll the element
    if (assmSection.current) assmSection.current.scrollIntoView();
  };

  const handleCloseOfflineModeSnack = () => {
    setOfflineModeSnackOpen(false); // close it
  };

  const getPlan = async (id) => {
    const sPlanData = await LessonPlanSetupAPI.getPlanSetupDetails(id);
    const query = new URLSearchParams(window.location.search);
    mapId = query.get("map_id");
    unitId = query.get("unit_id");
    chapId = query.get("chap_id");
    instId = query.get("inst_id");
    board_id = query.get("board_id");
    clsStd = query.get("class_std");
    subId = query.get("sub_id");

    console.log(
      "CurriculumSetup.js CurriculumSetup getPlan response1 " +
      JSON.stringify(sPlanData)
    );

    // update criteria using plan decription
    const CRIT = Constants.Application.LESSONPLAN_CRITERIA;
    localStorage.setItem(CRIT, sPlanData.data.lessonPlanSetup.plan_description);

    // attach inside
    sPlanData.data.namesDict = sPlanData.names_dict;
    sPlanData.data.clsStd = clsStd;

    setPlanData(sPlanData.data);
    const namesDict = sPlanData.names_dict;
    const { chapter_name, topic_name, topic_sub_name, subject_sub_name, subject_name, board_name } = namesDict;

    setNamesDict(namesDict);
    setChapName5(chapter_name);
    setTopName5(topic_name);
    setSubTopName5(topic_sub_name);
    setSubSubName5(subject_sub_name);
    setSubName5(subject_name);
    setBrdName5(board_name);

    const sPlanSetup = JSON.parse(
      JSON.stringify(Constants.Application.CURRICULUM_PLAN_SETUP)
    );

    sPlanSetup.namesDict = sPlanData.names_dict;
    sPlanSetup.clsStd = clsStd;

    console.log(
      "CurriculumSetup.js CurriculumSetup getPlan response " +
      JSON.stringify(sPlanData.data)
    );

    // update the id
    sPlanSetup.lessonPlanId = id;

    if (sPlanData && sPlanData.data && sPlanData.data.lessonPlanSetup) {
      sPlanSetup.lessonPlanSetup.id =
        sPlanData.data.lessonPlanSetup.lesson_plan_setup_id;
      sPlanSetup.lessonPlanSetup.topic_id =
        sPlanData.data.lessonPlanSetup.topic_id;
    }

    // update names

    let offlineMode = false;

    if (!offlineMode) {
      if (sPlanData && sPlanData.data) {
        setPlanSetup(sPlanData.data);

        console.log(
          "CurriculumSetup.js CurriculumSetup getPlan offline mode " +
          offlineMode
        );
        console.log(
          "CurriculumSetup.js CurriculumSetup getPlan offline mode plansetup " +
          JSON.stringify(sPlanData.data)
        );
      }

      setOfflineModeSnackOpen(false);
    } else {
      // show snackbar telling we are in offline mode
      setOfflineModeSnackOpen(true);
      setPlanSetup(sPlanSetup); // update object with server data
    }

    // stop loading
    setLoadingPlan(false);

    // set checks
    initCheckedList(sPlanData.data);
    console.log(
      "CurriculumSetup.js CurriculumSetup getPlan " + JSON.stringify(sPlanData)
    );
  };

  const initCheckedList = (planSetup2) => {
    // setup initial checks for accordion headers

    let checks2 = [];
    let names2 = ["aim", "action", "analysis", "application", "assessment"];

    let max = names2.length;
    let idx = 0;

    // ONLY WORKS FIRST TIME LOAD
    while (max-- > 0) {
      let checked2 = false;
      // override from the first object
      if (idx == 0) {
        checked2 = planSetup2.aim.isChecked;
      } else if (idx == 1) {
        checked2 = planSetup2.action.isChecked;
      } else if (idx == 2) {
        checked2 = planSetup2.analysis.isChecked;
      } else if (idx == 3) {
        checked2 = planSetup2.application.isChecked;
      } else if (idx == 4) {
        checked2 = planSetup2.assessment.isChecked;
      }

      checks2.push({
        name: names2[idx],
        checked: checked2,
      });

      ++idx;
    }

    setCheckedList(checks2);
  };

  const userId = Cookie.get("kgtopg.guruculum.user.id");

  useEffect(() => {
    if (userId == 0) {
      window.scrollTo(0, 0);
      console.log("Settings.js Settings: useEffect unlisten completed");

      window.location.assign("/logout");
    }

    const query = new URLSearchParams(window.location.search);
    mapId = query.get("map_id");
    unitId = query.get("unit_id");
    chapId = query.get("chap_id");
    instId = query.get("inst_id");
    board_id = query.get("board_id");
    clsStd = query.get("class_std");
    subId = query.get("sub_id");

    console.log(
      "CurriculumSetup.js CurriculumSetup useEffect query params at unit list",
      instId,
      board_id,
      clsStd,
      subId
    );
    console.log("map id at unit List ", mapId);

    getPlan(planId);
    getCurrMap(mapId);

    // get lessons
    return () => {
      //
    };
  }, []);

  const getCurrMap = async (mapId) => {
    const currMap = await MapAPI.getMapDetails(mapId);
    let ownerId = currMap ? currMap.publisher_id : 0;

    // verify if he is creator
    if (ownerId != userId) {
      setIsOwnMap(false);
    } else {
      setIsOwnMap(true);
    }

    await new Promise((resolve, reject) => {
      dispatch(updateCurrMap(currMap));
      console.log(`got currmap ${JSON.stringify(currMap)}`);
      resolve();
    });

    await new Promise((resolve, reject) => {
      dispatch(updateCurrMapId(mapId));
      resolve();
    });

    // getUnitAndLessons();
  };

  // const getUnitAndLessons = async () => {
  //   await new Promise((resolve, reject) => {
  //     dispatch(listUnitMapGet(mapId));
  //     resolve()
  //   })

  //   // update current unit to 1
  //   if (UnitMap && UnitMap.length > 0) {
  //     console.log(`trying to get lessons`)
  //     const currUnit = UnitMap[0]
  //     await new Promise((resolve, reject) => {
  //       dispatch(updateCurrUnit(currUnit))
  //       resolve()
  //     })

  //     getUnitLessonsData()

  //   }

  //   console.log(`getunitandlessons listunitmapget()`)
  // };

  // const getUnitLessonsData = () => {
  //   const unitId = currUnit.id;
  //   console.log(`unitid lessons call ${unitId}`);

  //   const lessons = unitLessons;
  //   // const lessons = await MapUnitAPI.getUnitLessons(unitId)
  //   dispatch(getUnitLessons(unitId));

  //   if (
  //     lessons &&
  //     lessons.data &&
  //     lessons.data.data &&
  //     lessons.data.data.length
  //   ) {
  //     // set current lesson
  //     dispatch(updateCurrLesson(lessons.data.data[0]));
  //   }

  //   setlessonData(lessons);
  //   setFirstTime(true);
  // };

  // set first unit as default current
  if (
    !firstTime &&
    UnitMap &&
    UnitMap.data &&
    UnitMap.data.Units &&
    UnitMap.data.Units.length
  ) {
    dispatch(updateCurrUnit(UnitMap.data.Units[0]));
  }

  console.log(`firstTime ${firstTime}`);

  // also get first unit lessons
  if (!firstTime && currUnit) {
    console.log(`curr unit id ${currUnit.id}`);
    // getUnitLessonsData(currUnit.id);
  }

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const updateData = (data) => {
    setUnitData(data);
  };

  const updateLesDatatoState = (lesData) => {
    setlessonData(lesData);
  };

  /* gpt saves all and enable all */
  const onChangeAccordionHeaderStatusAll = (checked) => {

    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    let newList = [];
    let name3 = "";
    let checked3 = false; // everyone by default

    checkedList.map((itm, idx) => {
      name3 = itm.name;
      checked3 = itm.checked; // keep old value intact

      newList.push({
        name: name3,
        checked: checked,
      });
    });

    // now update state
    setCheckedList(newList);

    // update the plansetup global object
    // setPlanSetup(sPlanSetup);
  }

  /* click on the header to open the drawer */
  const onChangeAccordionHeaderStatus = (name, checked) => {
    // rebuild the checklist
    let newList = [];
    let name3 = "";
    let checked3 = false; // everyone by default

    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));

    console.log(
      "CurriculumSetup.js CurriculumSetup onChangeAccordionHeaderStatus: name " +
      name
    );
    console.log(
      "CurriculumSetup.js CurriculumSetup onChangeAccordionHeaderStatus: checked " +
      checked
    );

    checkedList.map((itm, idx) => {
      name3 = itm.name;
      checked3 = itm.checked; // keep old value intact

      if (name3 === name) {
        checked3 = checked; // update
      }

      newList.push({
        name: name3,
        checked: checked3,
      });

      // update the plansetup item
      /*
      switch (name3) {
        case "aim":
          sPlanSetup.aim.isChecked = checked3;
          break;
        case "action":
          sPlanSetup.action.isChecked = checked3;
          break;
        case "analysis":
          sPlanSetup.analysis.isChecked = checked3;
          break;
        case "application":
          sPlanSetup.application.isChecked = checked3;
          break;
        case "assessment":
          sPlanSetup.assessment.isChecked = checked3;
          break;
      }
      */
    });

    // now update state
    setCheckedList(newList);

    // update the plansetup global object
    // setPlanSetup(sPlanSetup);
  };

  /* main headings of right drawer */
  const handleToggleResourceCheck = (name, type, isTrue) => {
    console.log("CurriculumSetup.js handleToggleResourceCheck: name " + name);
    console.log("CurriculumSetup.js handleToggleResourceCheck: type " + type);
    console.log(
      "CurriculumSetup.js handleToggleResourceCheck: isTrue " + isTrue
    );

    // copy from the factory session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    // update previous items
    sPlanSetup.aim.hasResource = planSetup.aim.hasResource;
    sPlanSetup.aim.hasKeyword = planSetup.aim.hasKeyword;

    switch (type) {
      case "aim":
        switch (name) {
          case "Resource":
            console.log(
              "CurriculumSetup.js handleToggleResourceCheck: type aim, name Resource making true"
            );
            sPlanSetup.aim.hasResource = isTrue; // just assign what is there
            break;
          case "Keyword":
            console.log(
              "CurriculumSetup.js handleToggleResourceCheck: type aim, name Keyword making true"
            );
            sPlanSetup.aim.hasKeyword = isTrue;
            break;
        }
        break;
      case "action":
        switch (name) {
          case "Step":
            console.log(
              "CurriculumSetup.js handleToggleResourceCheck: type action, name Step making true"
            );
            sPlanSetup.action.hasSteps = isTrue;
            break;
        }
        break;
      case "analysis":
        /*
          switch (name) { // actually index in this case
            case 0:
              console.log('CurriculumSetup.js handleToggleResourceCheck: type action, name Step making true')
              sPlanSetup.analysis.parts[0].isSelected = isTrue;
              break;
            case 1:
              console.log('CurriculumSetup.js handleToggleResourceCheck: type action, name Step making true')
              sPlanSetup.analysis.parts[1].isSelected = isTrue;
              break;
            case 2:
              console.log('CurriculumSetup.js handleToggleResourceCheck: type action, name Step making true')
              sPlanSetup.analysis.parts[2].isSelected = isTrue;
              break;
          }
          */

        // name is index
        if (sPlanSetup.analysis.parts.length > name) {
          sPlanSetup.analysis.parts[name].isSelected = isTrue;
        }

        // reassign the parts by removing the name (index)
        let newParts = sPlanSetup.analysis.parts;

        // filter if false
        if (isTrue) {
          // add new part

          let anaPartCount = sPlanSetup.analysis.parts.length;

          const part = {
            heading: "Analysis " + (anaPartCount + 1), // next one
            headingExp: "Analysis " + (anaPartCount + 1),
            question: "",
            explanation: [
              {
                text: "",
              },
              {
                text: "",
              },
            ],
            isSelected: true,
          };

          //    step.tips.push(img);
          // newParts.push(part);
        } else {
          // newParts = newParts.filter((itm, idx) => parseInt(name) != idx)
        }

        sPlanSetup.analysis.parts = newParts;
        break;
      case "application":
        switch (name) {
          case "Classwork":
            sPlanSetup.application.hasClasswork = isTrue;
            // if false, set all subs as false
            if (!isTrue) {
              sPlanSetup.application.classwork.hasWorkBook = false;
              sPlanSetup.application.classwork.hasQandA = false;
              sPlanSetup.application.classwork.hasAssm = false;
            }
            break;
          case "More Practice":
            sPlanSetup.application.hasMorePractice = isTrue;

            break;
          case "Homework":
            sPlanSetup.application.hasHomework = isTrue;
            if (!isTrue) {
              sPlanSetup.application.homework.hasQandA = false;
              sPlanSetup.application.homework.hasAssm = false;
              sPlanSetup.application.homework.hasImages = false;
              sPlanSetup.application.homework.hasQuestionTips = false;
            }
            break;
        }
        break;
      case "assessment":
        switch (name) {
          case "Meeting":
            sPlanSetup.assessment.hasMeetingText = isTrue;
            break;
          case "Approaching":
            sPlanSetup.assessment.hasApproachingText = isTrue;
            break;
          case "Exceeding":
            sPlanSetup.assessment.hasExceedingText = isTrue;
            break;
          case "Assessment":
          case "PopQuiz":
            sPlanSetup.assessment.hasAssm = isTrue;
            break;
        }
        break;
    }

    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js handleToggleResourceCheck: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onAddAimRes = (resObj) => {
    let newAimRes = aimResList;

    newAimRes.push(resObj);
    setAimResList(newAimRes);
  };

  const onRemoveAimRes = (idx) => {
    let newAimRes = aimResList.filter((itm, idx2) => idx != idx2);
    setAimResList(newAimRes);
  };

  const onAddAimKeywList = (keywObj) => {
    let newKeywList = aimKeywList;

    newKeywList.push(keywObj);
    setAimKeywList(newKeywList);
  };

  const onRemoveAimKeyw = (idx) => {
    let newAimKeyw = aimKeywList.filter((itm, idx2) => idx != idx2);
    setAimKeywList(newAimKeyw);
  };

  const onAddResourceClicked = () => {
    // update the context => data handler

    console.log("CurriculumSetup.js onAddResourceClicked: name called");

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let newResList = sPlanSetup.aim.resourceList;

    if (!newResList) {
      newResList = []; // new list
    }

    // attach new object
    const newObj = {
      title: "",
      description: "",
    };

    newResList.push(newObj);
    sPlanSetup.aim.hasResource = true;
    sPlanSetup.aim.resourceList = newResList;

    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onAddResourceClicked: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onDeleteResourceItem = (idx) => {
    // update the context => data handler

    console.log("CurriculumSetup.js onDeleteResourceClicked: name called");

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let oldResList = sPlanSetup.aim.resourceList;

    // filter out the item existing in the index
    const newResList = oldResList.filter((item, idx2) => idx != idx2);
    sPlanSetup.aim.resourceList = newResList;

    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onDeleteResourceItem: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onAddAimKeywClicked = () => {
    // update the context => data handler

    console.log("CurriculumSetup.js onAddAimKeywClicked: name called");

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let newKeywList = sPlanSetup.aim.keywordList;

    if (!newKeywList) {
      newKeywList = []; // new list
    }

    // attach new object
    const newObj = {
      title: "",
      description: "",
    };

    newKeywList.push(newObj);
    sPlanSetup.aim.keywordList = newKeywList;

    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onAddAimKeywClicked: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onDeleteAimKeywItem = (idx) => {
    // update the context => data handler

    console.log("CurriculumSetup.js onAddAimKeywClicked: name called");

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let oldKeywList = sPlanSetup.aim.keywordList;

    // filter out the item existing in the index
    const newKeywList = oldKeywList.filter((item, idx2) => idx != idx2);
    sPlanSetup.aim.keywordList = newKeywList;

    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onAddAimKeywClicked: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  /* ACTION STEP FUNCTIONALITY */
  const onAddActionStepClicked = () => {
    // update the context => data handler

    console.log("CurriculumSetup.js onAddActionStepClicked: name called");

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let newSteps = sPlanSetup.action.steps;

    if (!newSteps) {
      newSteps = []; // new list
    }

    // attach new object
    const newObj = {
      title: "",
      images: [],
      videos: [],
      docs: [],
      tips: [],
    };

    newSteps.push(newObj);
    // update flag unconditionally
    sPlanSetup.action.hasSteps = true;
    sPlanSetup.action.steps = newSteps;

    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onAddActionStepClicked: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onDelActionStepClicked = (stepIdx) => {
    console.log("CurriculumSetup.js onDeleteActionStep: name called");
    console.log("CurriculumSetup.js onDeleteActionStep: stepIdx is " + stepIdx);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let step = sPlanSetup.action.steps[stepIdx];

    let newSteps = sPlanSetup.action.steps.filter((itm, idx) => idx != stepIdx);
    sPlanSetup.action.steps = newSteps;
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onDeleteActionStep: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onAddStepImage = (stepIdx, url) => {
    console.log("CurriculumSetup.js onAddStepImage: name called");
    console.log("CurriculumSetup.js onAddStepImage: stepIdx is " + stepIdx);
    console.log("CurriculumSetup.js onAddStepImage: img url is " + url);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let step = sPlanSetup.action.steps[stepIdx];

    const img = {
      url: url,
    };

    //    step.images.push(img);
    sPlanSetup.action.steps[stepIdx].images.push(img);
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onAddStepImage: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onDeleteStepImage = (stepIdx, idx) => {
    console.log("CurriculumSetup.js onDeleteStepImage: name called");
    console.log("CurriculumSetup.js onDeleteStepImage: stepIdx is " + stepIdx);
    console.log("CurriculumSetup.js onDeleteStepImage: img idx is " + idx);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let step = sPlanSetup.action.steps[stepIdx];
    let img = step.images[idx]; // we don't need this

    let newImages = step.images.filter((img, idx2) => idx2 != idx);

    step.images = newImages;
    sPlanSetup.action.steps[stepIdx].images = newImages;
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onDeleteStepImage: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onAddStepVid = (stepIdx, url) => {
    console.log("CurriculumSetup.js onAddStepVid: name called");
    console.log("CurriculumSetup.js onAddStepVid: stepIdx is " + stepIdx);
    console.log("CurriculumSetup.js onAddStepVid: vid url is " + url);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let step = sPlanSetup.action.steps[stepIdx];

    const vid = {
      url: url,
    };

    //    step.images.push(img);
    sPlanSetup.action.steps[stepIdx].videos.push(vid);
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onAddStepVideo: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onDeleteStepVid = (stepIdx, idx) => {
    console.log("CurriculumSetup.js onDeleteStepVid: name called");
    console.log("CurriculumSetup.js onDeleteStepVid: stepIdx is " + stepIdx);
    console.log("CurriculumSetup.js onDeleteStepVid: vid idx is " + idx);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let step = sPlanSetup.action.steps[stepIdx];
    let vid = step.videos[idx]; // we don't need this

    let newVideos = step.videos.filter((vid, idx2) => idx2 != idx);

    step.videos = newVideos;
    sPlanSetup.action.steps[stepIdx].videos = newVideos;
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onDeleteStepVideo: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onAddStepDoc = (stepIdx, url) => {
    console.log("CurriculumSetup.js onAddStepDoc: name called");
    console.log("CurriculumSetup.js onAddStepDoc: stepIdx is " + stepIdx);
    console.log("CurriculumSetup.js onAddStepDoc: doc url is " + url);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let step = sPlanSetup.action.steps[stepIdx];

    const doc = {
      url: url,
    };

    //    step.images.push(img);
    sPlanSetup.action.steps[stepIdx].docs.push(doc);
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onAddStepDoc: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onDeleteStepDoc = (stepIdx, idx) => {
    console.log("CurriculumSetup.js onDeleteStepDoc: name called");
    console.log("CurriculumSetup.js onDeleteStepDoc: stepIdx is " + stepIdx);
    console.log("CurriculumSetup.js onDeleteStepDoc: doc idx is " + idx);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let step = sPlanSetup.action.steps[stepIdx];
    let doc = step.docs[idx]; // we don't need this

    let newDocs = step.docs.filter((img, idx2) => idx2 != idx);

    step.docs = newDocs;
    sPlanSetup.action.steps[stepIdx].docs = newDocs;
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onDeleteStepDoc: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onAddStepTip = (stepIdx, title, desc) => {
    console.log("CurriculumSetup.js onAddStepTip: name called");
    console.log("CurriculumSetup.js onAddStepTip: stepIdx is " + stepIdx);
    console.log("CurriculumSetup.js onAddStepTip: title is " + title);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let step = sPlanSetup.action.steps[stepIdx];

    const tip = {
      title: title,
      description: desc,
    };

    //    step.tips.push(img);
    sPlanSetup.action.steps[stepIdx].tips.push(tip);
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onAddStepTip: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onDeleteStepTip = (stepIdx, idx) => {
    console.log("CurriculumSetup.js onDeleteStepTip: name called");
    console.log("CurriculumSetup.js onDeleteStepTip: stepIdx is " + stepIdx);
    console.log("CurriculumSetup.js onDeleteStepTip: tip idx is " + idx);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let step = sPlanSetup.action.steps[stepIdx];

    let newTips = step.tips.filter((tip, idx2) => idx2 != idx);

    step.tips = newTips;
    sPlanSetup.action.steps[stepIdx].tips = newTips;
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onDeleteStepTip: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  /* CLASSWORK MORE PRACTICE STEP FUNCTIONALITY */
  const onAddPracStepClicked = () => {
    // update the context => data handler

    console.log("CurriculumSetup.js onAddPracStepClicked: name called");

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let newSteps = sPlanSetup.application.morePractice.steps;

    if (!newSteps) {
      newSteps = []; // new list
    }

    // attach new object
    const newObj = {
      title: "",
      images: [],
      videos: [],
      docs: [],
      tips: [],
    };

    newSteps.push(newObj);
    sPlanSetup.application.morePractice.steps = newSteps;

    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onAddPracStepClicked: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onDelPracStepClicked = (stepIdx) => {
    console.log("CurriculumSetup.js onDeletePracStep: name called");
    console.log("CurriculumSetup.js onDeletePracStep: stepIdx is " + stepIdx);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let step = sPlanSetup.application.morePractice.steps[stepIdx];

    let newSteps = sPlanSetup.application.morePractice.steps.filter(
      (itm, idx) => idx != stepIdx
    );
    sPlanSetup.application.morePractice.steps = newSteps;
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onDeletePracStep: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onAddPracStepImage = (stepIdx, url) => {
    console.log("CurriculumSetup.js onAddPracStepImage: name called");
    console.log("CurriculumSetup.js onAddPracStepImage: stepIdx is " + stepIdx);
    console.log("CurriculumSetup.js onAddPracStepImage: img url is " + url);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let step = sPlanSetup.application.morePractice.steps[stepIdx];

    const img = {
      url: url,
    };

    //    step.images.push(img);
    sPlanSetup.application.morePractice.steps[stepIdx].images.push(img);
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onAddPracStepImage: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onDeletePracStepImage = (stepIdx, idx) => {
    console.log("CurriculumSetup.js onDeletePracStepImage: name called");
    console.log(
      "CurriculumSetup.js onDeletePracStepImage: stepIdx is " + stepIdx
    );
    console.log("CurriculumSetup.js onDeletePracStepImage: img idx is " + idx);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let step = sPlanSetup.application.morePractice.steps[stepIdx];
    let img = step.images[idx]; // we don't need this

    let newImages = step.images.filter((img, idx2) => idx2 != idx);

    step.images = newImages;
    sPlanSetup.application.morePractice.steps[stepIdx].images = newImages;
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onDeletePracStepImage: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onAddPracStepVid = (stepIdx, url) => {
    console.log("CurriculumSetup.js onAddPracStepVid: name called");
    console.log("CurriculumSetup.js onAddPracStepVid: stepIdx is " + stepIdx);
    console.log("CurriculumSetup.js onAddPracStepVid: vid url is " + url);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let step = sPlanSetup.application.morePractice.steps[stepIdx];

    const vid = {
      url: url,
    };

    //    step.images.push(img);
    sPlanSetup.application.morePractice.steps[stepIdx].videos.push(vid);
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onAddPracStepVideo: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onDeletePracStepVid = (stepIdx, idx) => {
    console.log("CurriculumSetup.js onDeletePracStepVid: name called");
    console.log(
      "CurriculumSetup.js onDeletePracStepVid: stepIdx is " + stepIdx
    );
    console.log("CurriculumSetup.js onDeletePracStepVid: vid idx is " + idx);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let step = sPlanSetup.application.morePractice.steps[stepIdx];
    let vid = step.videos[idx]; // we don't need this

    let newVideos = step.videos.filter((vid, idx2) => idx2 != idx);

    step.videos = newVideos;
    sPlanSetup.application.morePractice.steps[stepIdx].videos = newVideos;
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onDeletePracStepVideo: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onAddPracStepDoc = (stepIdx, url) => {
    console.log("CurriculumSetup.js onAddPracStepDoc: name called");
    console.log("CurriculumSetup.js onAddPracStepDoc: stepIdx is " + stepIdx);
    console.log("CurriculumSetup.js onAddPracStepDoc: doc url is " + url);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let step = sPlanSetup.application.morePractice.steps[stepIdx];

    const doc = {
      url: url,
    };

    //    step.images.push(img);
    sPlanSetup.application.morePractice.steps[stepIdx].docs.push(doc);
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onAddPracStepDoc: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onDeletePracStepDoc = (stepIdx, idx) => {
    console.log("CurriculumSetup.js onDeletePracStepDoc: name called");
    console.log(
      "CurriculumSetup.js onDeletePracStepDoc: stepIdx is " + stepIdx
    );
    console.log("CurriculumSetup.js onDeletePracStepDoc: doc idx is " + idx);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let step = sPlanSetup.application.morePractice.steps[stepIdx];
    let doc = step.docs[idx]; // we don't need this

    let newDocs = step.docs.filter((img, idx2) => idx2 != idx);

    step.docs = newDocs;
    sPlanSetup.application.morePractice.steps[stepIdx].docs = newDocs;
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onDeletePracStepDoc: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onAddPracStepTip = (stepIdx, title, desc) => {
    console.log("CurriculumSetup.js onAddPracStepTip: name called");
    console.log("CurriculumSetup.js onAddPracStepTip: stepIdx is " + stepIdx);
    console.log("CurriculumSetup.js onAddPracStepTip: title is " + title);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let step = sPlanSetup.application.morePractice.steps[stepIdx];

    const tip = {
      title: title,
      description: desc,
    };

    //    step.tips.push(img);
    sPlanSetup.application.morePractice.steps[stepIdx].tips.push(tip);
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onAddPracStepTip: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onDeletePracStepTip = (stepIdx, idx) => {
    console.log("CurriculumSetup.js onDeletePracStepTip: name called");
    console.log(
      "CurriculumSetup.js onDeletePracStepTip: stepIdx is " + stepIdx
    );
    console.log("CurriculumSetup.js onDeletePracStepTip: tip idx is " + idx);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let step = sPlanSetup.application.morePractice.steps[stepIdx];

    let newTips = step.tips.filter((tip, idx2) => idx2 != idx);

    step.tips = newTips;
    sPlanSetup.application.morePractice.steps[stepIdx].tips = newTips;
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onDeletePracStepTip: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  /* teacher tip classwork */
  const onAddClassworkQstTip = (props) => {
    // just add a new tip
    const tip = {
      type: "instruct",
      text: "",
      name: "",
      description: "",
      assetUrl: "",
    };

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));

    sPlanSetup.application.classwork.questionTips.push(tip);
    // update state
    setPlanSetup(sPlanSetup);
  };

  const onDelClassworkQTip = (index) => {
    console.log("CurriculumSetup.js onDelClassworkQTip: name called");
    console.log("CurriculumSetup.js onDelClassworkQTip: tipIdx is " + index);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let newTips = sPlanSetup.application.classwork.questionTips.filter(
      (itm, idx) => {
        console.log("CurriculumSetup.js onDelClassworkQTip: filter idx " + idx);
        console.log(
          "CurriculumSetup.js onDelClassworkQTip: filter index " + index
        );
        return idx != index;
      }
    );

    // now reassign
    sPlanSetup.application.classwork.questionTips = newTips;
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onDelClassworkQTip: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  /* homework image */
  const onAddHomeworkImage = (props) => {
    // just add a new tip
    const image = {
      img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
      url: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
      title: "Coffee",
    };

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));

    sPlanSetup.application.homework.images.push(image);
    // update state
    setPlanSetup(sPlanSetup);
  };

  const onDelHomeworkImage = (index) => {
    console.log("CurriculumSetup.js onDelHomeworkImage: name called");
    console.log("CurriculumSetup.js onDelHomeworkImage: imageIdx is " + index);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let newImages = sPlanSetup.application.homework.images.filter(
      (itm, idx) => {
        console.log("CurriculumSetup.js onDelHomeworkImage: filter idx " + idx);
        console.log(
          "CurriculumSetup.js onDelHomeworkImage: filter index " + index
        );
        return idx != index;
      }
    );

    // now reassign
    sPlanSetup.application.homework.images = newImages;
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onDelHomeworkImage: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  /* teacher tip homework */
  const onAddHomeworkQstTip = (props) => {
    // just add a new tip
    const tip = {
      type: "instruct",
      text: "",
      name: "",
      description: "",
      assetUrl: "",
    };

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));

    sPlanSetup.application.homework.questionTips.push(tip);
    // update state
    setPlanSetup(sPlanSetup);
  };

  const onDelHomeworkQTip = (index) => {
    console.log("CurriculumSetup.js onDelHomeworkQTip: name called");
    console.log("CurriculumSetup.js onDelHomeworkQTip: tipIdx is " + index);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let newTips = sPlanSetup.application.homework.questionTips.filter(
      (itm, idx) => {
        console.log("CurriculumSetup.js onDelHomeworkQTip: filter idx " + idx);
        console.log(
          "CurriculumSetup.js onDelHomeworkQTip: filter index " + index
        );
        return idx != index;
      }
    );

    // now reassign
    sPlanSetup.application.homework.questionTips = newTips;
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onDelHomeworkQTip: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onDeleteAnaPartClicked = (partIdx) => {
    console.log("CurriculumSetup.js onDeleteAnaPartClicked: name called");
    console.log(
      "CurriculumSetup.js onDeleteAnaPartClicked: partIdx is " + partIdx
    );

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let step = sPlanSetup.analysis.parts[partIdx];

    let newParts = sPlanSetup.analysis.parts.filter(
      (itm, idx) => idx != partIdx
    );
    sPlanSetup.analysis.parts = newParts;
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onDeleteAnaPartClicked: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  // analysis part explanation
  const onAddAnaPartExp = (partIdx, title, desc) => {
    console.log("CurriculumSetup.js onAddAnaPartExp: name called");
    console.log("CurriculumSetup.js onAddAnaPartExp: stepIdx is " + partIdx);
    console.log("CurriculumSetup.js onAddAnaPartExp: title is " + title);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let part = sPlanSetup.analysis.parts[partIdx];

    const exp = {
      title: title,
      description: desc,
    };

    //    step.tips.push(img);
    sPlanSetup.analysis.parts[partIdx].explanation.push(exp);
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onAddAnaPartExp: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  /* add a new part in buffer */
  const onAddAnaPartClicked = () => {
    console.log("CurriculumSetup.js onAddAnaPartClicked: name called");

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let anaPartCount = sPlanSetup.analysis.parts.length;

    const part = {
      heading: "Analysis " + (anaPartCount + 1), // next one
      headingExp: "Analysis " + (anaPartCount + 1),
      question: "",
      explanation: [
        {
          text: "",
        },
        {
          text: "",
        },
      ],
      isSelected: true,
    };

    //    step.tips.push(img);
    sPlanSetup.analysis.hasParts = true;
    sPlanSetup.analysis.parts.push(part);
    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onAddAnaPartClicked: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  // analysis part explanation
  const onDelAnaPartExp = (partIdx, expIdx) => {
    console.log("CurriculumSetup.js onDelAnaPartExp: name called");
    console.log("CurriculumSetup.js onDelAnaPartExp: partIdx is " + partIdx);
    console.log("CurriculumSetup.js onDelAnaPartExp: expIdx is " + expIdx);

    // copy from the current session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    let part = sPlanSetup.analysis.parts[partIdx];

    let newExp = part.explanation.filter((itm, idx) => idx != expIdx);
    sPlanSetup.analysis.parts[partIdx].explanation = newExp;

    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js onDelAnaPartExp: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  const onUpdateRightItemSubChecked = (type, name, sub, isChecked2) => {
    console.log(
      "CurriculumSetup.js CurriculumSetup onUpdateRightItemSubChecked type " +
      type
    );
    console.log(
      "CurriculumSetup.js CurriculumSetup onUpdateRightItemSubChecked name " +
      name
    );
    console.log(
      "CurriculumSetup.js CurriculumSetup onUpdateRightItemSubChecked sub " +
      sub
    );
    console.log(
      "CurriculumSetup.js CurriculumSetup onUpdateRightItemSubChecked isChecked2 " +
      isChecked2
    );

    // copy from the factory session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    // verify the name and type and change the value

    // update previous items
    sPlanSetup.aim.hasResource = planSetup.aim.hasResource;
    sPlanSetup.aim.hasKeyword = planSetup.aim.hasKeyword;

    switch (type) {
      case "aim":
        switch (name) {
          case "Resource":
            break;
          case "Keyword":
            break;
        }
        break;
      case "action":
        switch (name) {
          case "Step":
            break;
        }
        break;
      case "analysis":
        // find the step, and update
        switch (name) {
        }
        break;
      case "application":
        switch (name) {
          case "Classwork":
            switch (sub) {
              case "Workbook":
                sPlanSetup.application.classwork.hasWorkBook = isChecked2; // whichever was updated and sent as argument
                sPlanSetup.application.classwork.hasDiffTips = isChecked2;
                if (isChecked2) {
                  // disable others
                  sPlanSetup.application.classwork.hasQandA = !isChecked2;
                  sPlanSetup.application.classwork.hasAssm = !isChecked2;
                }

                break;
              case "Question & Answer":
                sPlanSetup.application.classwork.hasQandA = isChecked2;
                // flip assessment
                sPlanSetup.application.classwork.hasAssm = !isChecked2;
                // disable workbook
                sPlanSetup.application.classwork.hasWorkBook = !isChecked2;
                sPlanSetup.application.classwork.hasDiffTips = !isChecked2;
                break;
              case "Assessment":
                sPlanSetup.application.classwork.hasAssm = isChecked2;
                // flip qanda
                sPlanSetup.application.classwork.hasQandA = !isChecked2;
                // disable workbook
                sPlanSetup.application.classwork.hasWorkBook = !isChecked2;
                sPlanSetup.application.classwork.hasDiffTips = !isChecked2;
                break;
            }
            break;
          case "Homework":
            switch (sub) {
              case "Question & Answer":
                sPlanSetup.application.homework.hasQandA = isChecked2;

                // flip assessment
                sPlanSetup.application.homework.hasAssm = !isChecked2;
                break;
              case "Assessment":
                sPlanSetup.application.homework.hasAssm = isChecked2;
                // flip qanda
                sPlanSetup.application.homework.hasQandA = !isChecked2;
                break;
              case "Images":
                sPlanSetup.application.homework.hasImages = isChecked2;

                break;
              case "Question Tips":
                sPlanSetup.application.homework.hasQuestionTips = isChecked2;
                break;
            }
            break;
        }
        break;
      case "assessment":
        break;
    }

    // update plansetup to reflect back
    console.log(
      "CurriculumSetup.js CurriculumSetup onUpdateRightItemSubChecked: updating state for plansetup " +
      JSON.stringify(sPlanSetup)
    );
    setPlanSetup(sPlanSetup);
  };

  /* participant count */
  const handleGroupChange = (type, count) => {
    // copy from the factory session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    console.log("CurriculumSetup.js handleGroupChange type " + type);
    console.log("CurriculumSetup.js handleGroupChange count " + count);

    if (type == "analysis") {
      sPlanSetup.analysis.participantCount = count;
    } else if (type == "application") {
      sPlanSetup.application.participantCount = count;
    }

    setPlanSetup(sPlanSetup);
  };

  /* minutes */
  const handleMinsChange = (type, mins) => {
    // copy from the factory session
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));

    if (type == "analysis") {
      sPlanSetup.analysis.mins = mins;
    } else if (type == "application") {
      sPlanSetup.application.mins = mins;
    } else if (type == "action") {
      sPlanSetup.action.mins = mins;
    }

    setPlanSetup(sPlanSetup);
  };

  const handleApprovePlan = (comms) => {
    handleUpdatePlanApproval(1, comms);
  };

  const handleRejectPlan = (comms) => {
    handleUpdatePlanApproval(-1, comms);
  };

  const handleNeutralPlan = () => {
    let comms = planSetup.lessonPlanSetup.publisher_comments;
    handleUpdatePlanApproval(0, comms);
  }

  const handleUpdatePlanApproval = async (bool, comms) => {
    const { lessonPlanId, aim, action, analysis, application } = planSetup;

    const body = {
      lesson_plan_setup_id: lessonPlanId,
      is_approved: bool,
      publisher_comments: comms,
    };

    const resp = await LessonPlanSetupAPI.putUpdatePlanSetupApproved(body);
    console.log(TAG, "handleUpdatePlanApproval", JSON.stringify(resp));

    // go back to the previous page
    // window.history.back();

    // reset buttons or reload state

    resetState(bool);
  };

  const resetState = (bool) => {
    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
    sPlanSetup.lessonPlanSetup.is_approved = bool;

    setPlanSetup(sPlanSetup);
  };

  if (loadingPlan) {
    return (
      <Box2
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          height: "100vh",
        }}
      >
        <CircularProgress2 />
      </Box2>
    );
  }

  return (
    <div className={classes.root}>
      {/* <LeftCard open={false} /> */}

      <main
        className={classes.content}
        style={{
          width: "100%",
        }}
      >
        <Container
          className={classes.container}
          style={{
            maxWidth: "100%",
            width: "100%",
          }}
        >
          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              maxWidth: "100%",
            }}
          >
            <Helmet>
              <title>Curriculum Map Setup | Guruculum</title>
            </Helmet>
            <Box
              sx={{
                backgroundColor: "background.default",
                minHeight: "100%",
                // py: 3,
              }}
              style={{
                width: "100%",
                minWidth: "100%",
              }}
            >
              <Container
                style={{
                  maxWidth: "100%",
                }}
              >
                {/* <UnitListToolbar /> */}

                <Box
                  sx={{ pt: 0, mt: 0 }}
                  style={{
                    marginTop: 0,
                    paddingBottom: 20,
                    width: "100%",
                  }}
                >
                  <MapHeading
                    planData={planData}
                    planSetup={planSetup}
                    isOwnMap={isOwnMap}
                    handleApprovePlan={(comms) => handleApprovePlan(comms)}
                    handleRejectPlan={(comms) => handleRejectPlan(comms)}
                    handleNeutralPlan={(comms) => handleNeutralPlan(comms)}
                    planId={planId}
                    isONeutral={isONeutral}
                    setIsONeutral={(bool) => setIONeutral(bool)}
                    namesDict={namesDict}
                    handleUpdatePlanSetup={(sPlanSetup) =>
                      setPlanSetup(sPlanSetup)
                    }
                    onChangeAccordionHeaderStatus={(name, checked) => {
                      onChangeAccordionHeaderStatus(name, checked);
                    }}
                    onChangeAccordionHeaderStatusAll={(checked) => {
                      onChangeAccordionHeaderStatusAll(checked);
                    }}
                  />

                  <div
                    style={{
                      margin: 10,
                    }}
                  ></div>

                  <div style={{ display: "flex" }}>
                    {loadingUnit && unitMapDetailsGet && UnitMap ? (
                      <></>
                    ) : (
                      <UnitLeftCard
                        style={{ width: "29%" }}
                        setUnitData={(data) => {
                          updateData(data);
                        }}
                        UnitMap={UnitMap}
                        unitLessons={unitLessons}
                        getLesData={(lesData) => {
                          updateLesDatatoState(lesData);
                        }}
                        lessonData={lessonData}
                        params={props.match.params}
                        checkedList={checkedList}
                        onChangeAccordionHeaderStatus={(name, checked) =>
                          onChangeAccordionHeaderStatus(name, checked)
                        }
                        planSetup={planSetup}
                        handleToggleResourceCheck={(name, type, checked) =>
                          handleToggleResourceCheck(name, type, checked)
                        }
                        onUpdateRightItemSubChecked={(
                          type,
                          name,
                          sub,
                          isChecked2
                        ) =>
                          onUpdateRightItemSubChecked(
                            type,
                            name,
                            sub,
                            isChecked2
                          )
                        }
                        planData={planData}
                        handleUpdatePlanSetup={(sPlanSetup) =>
                          setPlanSetup(sPlanSetup)
                        }
                        aimSection={aimSection}
                        actSection={actSection}
                        anaSection={anaSection}
                        appSection={appSection}
                        assmSection={assmSection}
                        scrollToAim={scrollToAim}
                        scrollToAction={scrollToAction}
                        scrollToAnalysis={scrollToAnalysis}
                        scrollToApplication={scrollToApplication}
                        scrollToAssessment={scrollToAssessment}
                      />
                    )}

                    <MainPage
                      updatedUnitData={unitData}
                      UnitMap={UnitMap}
                      lessonData={lessonData}
                      checkedList={checkedList}
                      planSetup={planSetup}
                      namesDict={namesDict}
                      onAddResourceClicked={() => onAddResourceClicked()}
                      onDeleteResourceItem={(idx) => onDeleteResourceItem(idx)}
                      onAddAimKeywClicked={() => onAddAimKeywClicked()}
                      onDeleteAimKeywItem={(idx) => onDeleteAimKeywItem(idx)}
                      onAddActionStepClicked={() => onAddActionStepClicked()}
                      onDelActionStepClicked={(idx) =>
                        onDelActionStepClicked(idx)
                      }
                      onAddStepImage={(stepIdx, url) =>
                        onAddStepImage(stepIdx, url)
                      }
                      onDeleteStepImage={(stepIdx, idx) =>
                        onDeleteStepImage(stepIdx, idx)
                      }
                      onDeleteStepVid={(stepIdx, idx) =>
                        onDeleteStepVid(stepIdx, idx)
                      }
                      onAddStepVid={(stepIdx, url) =>
                        onAddStepVid(stepIdx, url)
                      }
                      onDeleteStepDoc={(stepIdx, idx) =>
                        onDeleteStepDoc(stepIdx, idx)
                      }
                      onAddStepDoc={(stepIdx, url) =>
                        onAddStepDoc(stepIdx, url)
                      }
                      onAddStepTip={(stepIdx, title, desc) =>
                        onAddStepTip(stepIdx, title, desc)
                      }
                      onDeleteStepTip={(stepIdx, idx) =>
                        onDeleteStepTip(stepIdx, idx)
                      }
                      onDeletePracStepVid={(stepIdx, idx) =>
                        onDeletePracStepVid(stepIdx, idx)
                      }
                      onAddPracStepVid={(stepIdx, url) =>
                        onAddPracStepVid(stepIdx, url)
                      }
                      onDeletePracStepDoc={(stepIdx, idx) =>
                        onDeletePracStepDoc(stepIdx, idx)
                      }
                      onAddPracStepDoc={(stepIdx, url) =>
                        onAddPracStepDoc(stepIdx, url)
                      }
                      onDeleteAnaPartClicked={(idx) => {
                        onDeleteAnaPartClicked(idx);
                      }}
                      onAddAnaPartExp={(idx, title, desc) =>
                        onAddAnaPartExp(idx, title, desc)
                      }
                      onDelAnaPartExp={(partIdx, idx) =>
                        onDelAnaPartExp(partIdx, idx)
                      }
                      onAddAnaPartClicked={() => onAddAnaPartClicked()}
                      setImageSrc={(src) => setImageSrc()}
                      config3={config3}
                      setConfig3={(config3) => setConfig3(config3)}
                      onAddClassworkQstTip={() => onAddClassworkQstTip()}
                      onDelClassworkQTip={(index) => onDelClassworkQTip(index)}
                      onAddPracStepClicked={() => onAddPracStepClicked()}
                      onDelPracStepClicked={(idx) => onDelPracStepClicked(idx)}
                      onAddPracStepImage={(stepIdx, url) =>
                        onAddPracStepImage(stepIdx, url)
                      }
                      onDeletePracStepImage={(stepIdx, idx) =>
                        onDeletePracStepImage(stepIdx, idx)
                      }
                      onAddPracStepTip={(stepIdx, title, desc) =>
                        onAddPracStepTip(stepIdx, title, desc)
                      }
                      onDeletePracStepTip={(stepIdx, idx) =>
                        onDeletePracStepTip(stepIdx, idx)
                      }
                      onDelHomeworkImage={(idx) => onDelHomeworkImage(idx)}
                      onAddHomeworkImage={() => onAddHomeworkImage()}
                      onAddHomeworkQstTip={() => onAddHomeworkQstTip()}
                      onDelHomeworkQTip={(index) => onDelHomeworkQTip(index)}
                      planData={planData}
                      handleUpdatePlanSetup={(sPlanSetup) =>
                        setPlanSetup(sPlanSetup)
                      }
                      handleGroupChange={(type, count) =>
                        handleGroupChange(type, count)
                      }
                      handleMinsChange={(type, mins) =>
                        handleMinsChange(type, mins)
                      }
                      handleUpdateCheckedList={(newList) =>
                        setCheckedList(newList)
                      }
                      topicId={topicId}
                      chapId={chapId}
                      unitId={unitId}
                      aimSection={aimSection}
                      actSection={actSection}
                      anaSection={anaSection}
                      appSection={appSection}
                      assmSection={assmSection}
                      scrollToAim={scrollToAim}
                      scrollToAction={scrollToAction}
                      scrollToAnalysis={scrollToAnalysis}
                      scrollToApplication={scrollToApplication}
                      scrollToAssessment={scrollToAssessment}
                      planId={planId}
                    />

                    {/* <AssetsTab /> */}


                    {/* related maps */}
                    <div
                      style={{
                        width: '19%',
                        marginLeft: 10
                      }}>
                      <RelatedLessons
                        planSetup={planSetup}
                        planData={planData}
                        handleUpdatePlanSetup={(sPlanSetup) =>
                          setPlanSetup(sPlanSetup)
                        }
                      />
                    </div>
                  </div>
                </Box>
              </Container>
            </Box>
          </Grid>
        </Container>
      </main>

      {/* progress snack */}
      <Snackbar2
        open={offlineModeSnackOpen}
        autoHideDuration={6000}
        onClose={handleCloseOfflineModeSnack}
      >
        <Alert2
          onClose={handleCloseOfflineModeSnack}
          severity="error"
          sx={{ width: "100%" }}
        >
          You are currently in offline mode..!
        </Alert2>
      </Snackbar2>

      {/* fab */}
      {/* <Box3 sx={{ '& > :not(style)': { m: 1 } }}
      style={{
        bottom: 0,
        position: 'fixed',
        right: 0
      }}
      >
        <Fab variant="extended"
        onClick={() => {
          
        }}
        >
          <NavigationIcon sx={{ mr: 1 }} />
          Save
        </Fab>
      </Box3> */}
      {/* end fab */}
    </div>
  );
};

export default CurriculumSetup;
