import axios from "axios";
import Constants from "../../resource/Constants";
import { STDDETAILS_LIST_FAIL, STDDETAILS_LIST_REQUEST, STDDETAILS_LIST_SUCCESS } from "../constants/standardConstants";




const listStdGet = (inst_id,Board_id) => async (dispatch) => {
    try {
        dispatch({ type: STDDETAILS_LIST_REQUEST })

        const { data } = await axios.get(Constants.Application.PLATFORM_END_URL+"/standard/")
        console.log("data in list Standards  get", data)
        dispatch({ type: STDDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: STDDETAILS_LIST_FAIL, payload: error.message })

    }
}


export { listStdGet }
