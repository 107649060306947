import React, { useEffect, useState } from "react";
import IconButton2 from '@mui/material/IconButton';
import DoneIcon2 from '@mui/icons-material/Done';
import Drawer2 from '@mui/material/Drawer';

import Card2 from '@mui/material/Card';
import CardActions2 from '@mui/material/CardActions';
import CardContent2 from '@mui/material/CardContent';
import Typography2 from '@mui/material/Typography';
import CardActionArea2 from '@mui/material/CardActionArea';
import Alert2 from '@mui/material/Alert';
import ListItem2 from '@mui/material/ListItem';
import ListItemButton2 from '@mui/material/ListItemButton';
import ListItemIcon2 from '@mui/material/ListItemIcon';
import ListItemText2 from '@mui/material/ListItemText';
import Checkbox2 from '@mui/material/Checkbox';
import ExpandLess2 from '@mui/icons-material/ExpandLess';
import Collapse2 from '@mui/material/Collapse';
import ExpandMore2 from '@mui/icons-material/ExpandMore';
import List2 from '@mui/material/List';
import Button2 from '@mui/material/Button';
import Divider2 from '@mui/material/Divider';

import Radio2 from '@mui/material/Radio';
import RadioGroup2 from '@mui/material/RadioGroup';
import FormControlLabel2 from '@mui/material/FormControlLabel';
import FormControl2 from '@mui/material/FormControl';
import FormLabel2 from '@mui/material/FormLabel';
import Constants from "../../../resource/Constants";

const RIGHTITEMCHECKBOX2 = (props) => {
    const { name, idx, type, checked, sub, isChecked, planSetup, isRadio } = props;
    const [open, setOpen] = React.useState(true);
    let name2 = name.toLowerCase();

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItem2
                // key={value}
                // secondaryAction={
                //     <IconButton2 edge="end" aria-label="comments">
                //         {/* <CommentIcon /> */}
                //     </IconButton2>
                // }
                disablePadding
                sx={{
                    // selected and (selected + hover) states
                    '&& .Mui-selected, && .Mui-selected:hover': {
                        bgcolor: 'purple',
                        '&, & .MuiListItemIcon-root': {
                            color: 'white',
                        },
                    },
                    // hover states
                    '& .MuiListItemButton-root:hover': {
                        bgcolor: '#0288D1',
                        '&, & .MuiListItemIcon-root': {
                            color: 'white',
                        },
                    },

                    mt: 1.5,
                }}
                style={{
                    padding: 0
                }}
            >
                <ListItemButton2
                    role={undefined} onClick2={() => { }} dense
                    selected={isChecked}
                    // disabled={(sub && sub.length) ? false : isChecked}
                    onClick={(event) => {
                        console.log('LessonPlanDrawer.js RIGHTITEMCHECKBOX2 ListItemButton2 onClick event ' + event.target)

                        let name2 = name;
                        // verify if analysis
                        if (type === 'analysis')
                            name2 = idx;
                        // just toggle the checked
                        if (type === 'application') {
                            // return;
                        }

                        props.handleToggleResourceCheck(name2, type, !isChecked)
                    }}

                >

                    <ListItemText2
                        disableTypography
                        primary={<Typography2 type="body1">{name.toUpperCase()}</Typography2>}
                        style={{
                            fontSize: 16
                        }}

                        sx={{
                            fontSize: 20
                        }}
                    />

                    {isChecked ?
                        <>
                            {/* <ListItemIcon2> */}
                            <DoneIcon2 />
                            {/* <Checkbox2
                                edge="end"
                                // checked={(checked == isChecked)}
                                checked={isChecked}
                                tabIndex={-1}
                                disableRipple
                            // inputProps={{ 'aria-labelledby': labelId }}


                            onChange={(event) => {
                                const checked2 = event.target.checked; // checked or not
                                console.log('CurriculumSetup.js RIGHTITEMCHECKBOX onChange() name ' + name + ' type ' + type + ' checked2 ' + checked2);
                                props.handleToggleResourceCheck(name, type, checked2) // which resource, what flag

                            }}



                            /> */}
                            {/* </ListItemIcon2> */}
                        </>
                        :
                        <></>
                    }
                    {/* 
                    {(sub && sub.length) ?

                        open ? <ExpandLess2 /> : <ExpandMore2 />
                        :
                        <></>
                    } */}
                </ListItemButton2>

            </ListItem2>
            {(sub && sub.length) ?

                <Collapse2 in={open} timeout="auto" unmountOnExit>
                    <List2 component="div" disablePadding
                        sx={{
                            // selected and (selected + hover) states
                            '&& .Mui-selected, && .Mui-selected:hover': {
                                bgcolor: 'purple',
                                '&, & .MuiListItemIcon-root': {
                                    color: 'white',
                                },
                            },
                            // hover states
                            '& .MuiListItemButton-root:hover': {
                                bgcolor: '#0288D1',
                                '&, & .MuiListItemIcon-root': {
                                    color: 'white',
                                },
                            },
                        }}
                    >
                        {sub.map(sub2 => {
                            // check which activity this is

                            let isChecked2 = false;

                            let isCheckedAssm = false;
                            let isCheckedQandA = false;
                            if (type === 'application') {
                                // checking sub instead of name first
                                if (sub2 === 'Workbook') {
                                    // verify if workbook is checked of not
                                    isChecked2 = planSetup.application.classwork.hasWorkBook;
                                } else if (sub2 === 'Document') {
                                    isChecked2 = planSetup.application.classwork.hasAssm;
                                } else if (sub2 === 'Question & Answer') {

                                    // applies for both qa and assm
                                    if (name2 === 'classwork') {
                                        //isChecked2 = planSetup.application.classwork.hasQandA;
                                        isCheckedQandA = planSetup.application.classwork.hasQandA;
                                        isCheckedAssm = planSetup.application.classwork.hasAssm;
                                    } else if (name2 === 'homework') {
                                        //isChecked2 = planSetup.application.homework.hasQandA;
                                        isCheckedQandA = planSetup.application.homework.hasQandA;
                                        isCheckedAssm = planSetup.application.homework.hasAssm;
                                    }
                                } else if (sub2 === 'Assessment') { // this is currently not used
                                    if (name2 === 'classwork') {
                                        isChecked2 = planSetup.application.classwork.hasAssm;

                                    }
                                    else if (name2 === 'homework') {
                                        isChecked2 = planSetup.application.homework.hasAssm;

                                    }
                                } else if (sub2 === 'Images') {
                                    if (name2 === 'homework')
                                        isChecked2 = planSetup.application.homework.hasImages;
                                } else if (sub2 === 'Question Tips') {
                                    if (name2 === 'homework')
                                        isChecked2 = planSetup.application.homework.hasQuestionTips;
                                }
                            }

                            // check with the sub name passed with plansetup
                            console.log('LessonPlanDrawer.js RIGHTITEMCHECKBOX2 sub ' + sub2 + ' name ' + name + ' type ' + type + ' isChecked: ' + isChecked2);
                            return (
                                <>
                                    <ListItemButton2
                                        sx={{ ml: 8, my: 0.5 }}
                                        style={{
                                            display: (sub2 == 'Question & Answer') ? 'none' : 'flex'
                                        }}
                                        disablePadding
                                        selected={isChecked2}
                                        // disabled={isChecked2} // IT WILL BE DISABLED IF IT GOT SELECTED
                                        disabled={!isChecked}
                                        onClick={(event) => {
                                            let name3 = sub2;
                                            console.log('LessonPlanDrawer.js RIGHTITEMCHECKBOX2 SUB ListItemButton2 onClick event ' + event.target);
                                            console.log('LessonPlanDrawer.js RIGHTITEMCHECKBOX2 SUB ListItemButton2 onClick event sub2 ' + sub2);
                                            console.log('LessonPlanDrawer.js RIGHTITEMCHECKBOX2 SUB ListItemButton2 onClick event name ' + name);
                                            console.log('LessonPlanDrawer.js RIGHTITEMCHECKBOX2 SUB ListItemButton2 onClick event name3 ' + name3);

                                            // verify if application
                                            if (type === 'application') {

                                            }

                                            // update the sub level
                                            // let sPlanSetup = JSON.parse(JSON.stringify(planSetup));

                                            if (sub2 != 'Question & Answer')
                                                props.onUpdateRightItemSubChecked(type, name, name3, !isChecked2);

                                        }}
                                    >
                                        {/* <ListItemIcon2>
                                        
                                        {(!isRadio) ?
                                            <Checkbox2
                                                edge="start"
                                                checked={isChecked2}
                                                tabIndex={-1}
                                                disableRipple
                                                // inputProps={{ 'aria-labelledby': labelId }}
                                                onChange={(event) => {
                                                    console.log('CurriculmSetup.js RIGHTITEMCHECKBOX sub ' + sub2 + ' name ' + name + ' type ' + type + ' event target ' + event.target.checked)
                                                    let isChecked2 = event.target.checked;
                                                    // update the sub level
                                                    props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)
                                                }}
                                            />
                                            :
                                            <>
                                                
                                                <Radio2
                                                    checked={isChecked2}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    onChange={(event) => {
                                                        console.log('CurriculmSetup.js RIGHTITEMCHECKBOX sub ' + sub2 + ' name ' + name + ' type ' + type + ' event target ' + event.target.checked)
                                                        let isChecked2 = event.target.checked;
                                                        // update the sub level
                                                        props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)
                                                    }} />
                                            </>
                                        }
                                    </ListItemIcon2> */}
                                        <ListItemText2 primary={sub2}
                                            style={{
                                                fontSize: 14
                                            }}
                                        />

                                        {isChecked2 &&
                                            <DoneIcon2 />
                                        }

                                    </ListItemButton2>
                                    {/* sub items for assessment */}
                                    {(sub2 === 'Question & Answer') ?

                                        <>
                                            <ListItemButton2
                                                sx={{ ml: 8, my: 0.5 }}
                                                disablePadding
                                                selected={isCheckedAssm}
                                                // disabled={isCheckedAssm} // IT WILL BE DISABLED IF IT GOT SELECTED
                                                disabled={!isChecked}
                                                onClick={(event) => {
                                                    console.log('LessonPlanDrawer.js RIGHTITEMCHECKBOX2 SUB ListItemButton2 onClick event ' + event.target);

                                                    let name3 = sub2;
                                                    // verify if application
                                                    if (type === 'application') {

                                                    }

                                                    // update the sub level
                                                    // props.onUpdateRightItemSubChecked(type, name, name3, !isCheckedAssm);

                                                    // update the sub level
                                                    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));

                                                    //props.onUpdateRightItemSubChecked(type, name, name3, !isChecked2);
                                                    if (name2 === 'classwork') {
                                                        sPlanSetup.application.classwork.hasAssm = !isChecked2; // flip
                                                        sPlanSetup.application.classwork.hasQandA = isChecked2; // flip
                                                        sPlanSetup.application.classwork.hasWorkBook = isChecked2;

                                                        // uncheck the diff tips because we checked question and answer
                                                        sPlanSetup.application.classwork.hasDiffTips = isChecked2;
                                                    } else if (name2 === 'homework') {
                                                        sPlanSetup.application.homework.hasAssm = !isChecked2; // flip
                                                        sPlanSetup.application.homework.hasQandA = isChecked2; // flip
                                                        sPlanSetup.application.homework.hasWorkBook = isChecked2;
                                                    }

                                                    props.onUpdatePlanSetup(sPlanSetup)
                                                }}
                                            >
                                                <ListItemText2 primary={`Enter Question & Answers`} />

                                                {isCheckedAssm &&
                                                    <DoneIcon2 />
                                                }

                                            </ListItemButton2>

                                            <ListItemButton2
                                                sx={{ ml: 8 }}
                                                disablePadding
                                                selected={isCheckedQandA}
                                                // disabled={isCheckedQandA} // IT WILL BE DISABLED IF IT GOT SELECTED
                                                disabled={!isChecked}
                                                onClick={(event) => {
                                                    console.log('LessonPlanDrawer.js RIGHTITEMCHECKBOX2 SUB ListItemButton2 onClick event ' + event.target);

                                                    let name3 = sub2;

                                                    // update the sub level
                                                    let sPlanSetup = JSON.parse(JSON.stringify(planSetup));

                                                    // verify if application
                                                    if (type === 'application') {

                                                    }

                                                    if (name2 === 'classwork') {
                                                        sPlanSetup.application.classwork.hasQandA = !isChecked2; // flip
                                                        sPlanSetup.application.classwork.hasAssm = isChecked2; // flip
                                                        sPlanSetup.application.classwork.hasWorkBook = isChecked2;

                                                        // uncheck the diff tips because we checked question and answer
                                                        sPlanSetup.application.classwork.hasDiffTips = isChecked2;
                                                    } else if (name2 === 'homework') {
                                                        sPlanSetup.application.homework.hasQandA = !isChecked2; // flip
                                                        sPlanSetup.application.homework.hasAssm = isChecked2;
                                                        sPlanSetup.application.homework.hasWorkBook = isChecked2;
                                                    }

                                                    props.onUpdatePlanSetup(sPlanSetup)

                                                    // update the sub level
                                                    // props.onUpdateRightItemSubChecked(type, name, name3, !isCheckedQandA);
                                                }}
                                            >
                                                <ListItemText2 primary={`Upload Question & Answers`} />

                                                {isCheckedQandA &&
                                                    <DoneIcon2 />
                                                }

                                            </ListItemButton2>
                                        </>
                                        :
                                        <></>
                                    }
                                </>
                            )
                        })}
                    </List2>
                </Collapse2>

                :
                <></>
            }
        </>
    )
}

function ASSESSMENTRIGHTITEMCARD(props) {
    const { name, type, color, planSetup } = props;

    console.log('LessonPlanDrawer.js ASSESSMENTRIGHTITEMCARD: planSetup ' + JSON.stringify(planSetup));
    const { hasApproachingText, hasMeetingText, hasExceedingText, hasAssm } = planSetup.assessment;

    return (
        <Card2 sx={{ maxWidth: 345 }} >
            <CardActionArea2>

                <CardContent2
                    sx={{
                        backgroundColor: Constants.Application.COLOR_PRIMARY_LIGHT
                    }}
                    style={{
                        backgroundColor: Constants.Application.COLOR_PRIMARY_LIGHT
                    }}
                >
                    <Typography2 gutterBottom variant="h6" component="div">
                        {name}
                    </Typography2>
                    <Typography2 variant="body2" color="text.secondary">
                        Assessments are concretely aligned with learning goals and instructional activities and provides opportunities for students to apply and extend the knowledge acquired during the lesson.
                    </Typography2>
                </CardContent2>
            </CardActionArea2>
            <CardActions2>
                {/*nothing*/}
            </CardActions2>


            <div>
                <RIGHTITEMCHECKBOX2
                    name="Approaching"
                    type="assessment"
                    isChecked={hasApproachingText}
                    planSetup={planSetup}
                    handleToggleResourceCheck={(name, type, checked) => {
                        props.handleToggleResourceCheck(name, type, checked)
                    }}
                    onUpdateRightItemSubChecked={(type, name, sub, isChecked2) => props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)}
                    onUpdatePlanSetup={(newPlanSetup) => props.onUpdatePlanSetup(newPlanSetup)}
                />
                <RIGHTITEMCHECKBOX2
                    name="Meeting"
                    type="assessment"
                    isChecked={hasMeetingText}
                    planSetup={planSetup}
                    handleToggleResourceCheck={(name, type, checked) => {
                        props.handleToggleResourceCheck(name, type, checked)
                    }}
                    onUpdateRightItemSubChecked={(type, name, sub, isChecked2) => props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)}
                    onUpdatePlanSetup={(newPlanSetup) => props.onUpdatePlanSetup(newPlanSetup)}
                />
                <RIGHTITEMCHECKBOX2
                    name="Exceeding"
                    type="assessment"
                    isChecked={hasExceedingText}
                    planSetup={planSetup}
                    handleToggleResourceCheck={(name, type, checked) => {
                        props.handleToggleResourceCheck(name, type, checked)
                    }}
                    onUpdateRightItemSubChecked={(type, name, sub, isChecked2) => props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)}
                    onUpdatePlanSetup={(newPlanSetup) => props.onUpdatePlanSetup(newPlanSetup)}
                />
                {/* 
                <RIGHTITEMCHECKBOX2
                    name="PopQuiz"
                    type="assessment"
                    isChecked={hasAssm}
                    planSetup={planSetup}
                    handleToggleResourceCheck={(name, type, checked) => {
                        props.handleToggleResourceCheck(name, type, checked)
                    }}
                    onUpdateRightItemSubChecked={(type, name, sub, isChecked2) => props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)}
                    onUpdatePlanSetup={(newPlanSetup) => props.onUpdatePlanSetup(newPlanSetup)}
                /> */}

                {/* 
          <RIGHTITEMCHECKBOX
            name="Test"
            type="assessment"
            handleToggleResourceCheck={(name, type, checked) => {
              props.handleToggleResourceCheck(name, type, checked)
            }}
          /> */}
                {/* <RIGHTITEMCHECKBOX
            name="Project" checked={false} /> */}
                {/* <RIGHTITEMCHECKBOX
            name="Oral Questions"
            type="assessment"
            handleToggleResourceCheck={(name, type, checked) => {
              props.handleToggleResourceCheck(name, type, checked)
            }}
          /> */}


{/* 
                <div
                    style={{
                        padding: 10,
                        position: 'fixed',
                        bottom: 10,
                        width: '97%'
                    }}>
                    <Alert2 variant="filled" severity="info">
                        The items you select above will appear in the plan!
                    </Alert2>
                </div> */}



            </div>
        </Card2>
    );
}

function ANALYSISRIGHTITEMCARD(props) {
    const { name, type, color, planSetup } = props;
    const { hasParts, parts } = planSetup.analysis;
    console.log('LessonPlanDrawer.js ANALYSISRIGHTITEMCARD hasParts ' + hasParts);
    console.log('LessonPlanDrawer.js ANALYSISRIGHTITEMCARD parts ' + parts);

    return (
        <Card2 sx={{ maxWidth: 345 }}>
            <CardActionArea2>
                {/* <CardMedia2
            component="img"
            height="140"
            image="/static/images/cards/contemplative-reptile.jpg"
            alt="green iguana"
          /> */}
                <CardContent2
                    sx={{
                        backgroundColor: Constants.Application.COLOR_PRIMARY_LIGHT
                    }}
                    style={{
                        backgroundColor: Constants.Application.COLOR_PRIMARY_LIGHT
                    }}>
                    <Typography2 gutterBottom variant="h6" component="div">
                        {name}
                    </Typography2>
                    <Typography2 variant="body2" color="text.secondary">
                        The Participate part addresses the lesson's effectiveness - to what extent did the students meet the objectives stated in your lesson plan, and how do you know
                    </Typography2>
                </CardContent2>
            </CardActionArea2>
            <CardActions2>
                {/* <Button2 variant="contained" size="medium" color="primary"
            onClick={props.onCloseDrawer}>
            ADD
          </Button2> */}
                {/* <Chip2 icon={<FaceIcon />} label={`Part`} color={`success`} /> */}
                {/* <Chip2 icon={<FaceIcon />} label={`Teacher tip`} color={`secondary`} /> */}
            </CardActions2>


            <div>
                <>
                    {(parts && parts.length) ?
                        parts.map((part, idx) => {
                            if(part.heading === 'assessment_assm')
                                return <></>
                                
                            return (
                                <RIGHTITEMCHECKBOX2
                                    // name={part.heading}
                                    name={part.heading}
                                    idx={idx}
                                    type="analysis"
                                    // isChecked={hasParts}
                                    isChecked={part.isSelected}
                                    planSetup={planSetup}
                                    handleToggleResourceCheck={(name, type, checked) => {
                                        props.handleToggleResourceCheck(name, type, checked)
                                    }}
                                    onUpdateRightItemSubChecked={(type, name, sub, isChecked2) => props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)}
                                    onUpdatePlanSetup={(newPlanSetup) => props.onUpdatePlanSetup(newPlanSetup)}
                                />
                            )

                        })

                        :
                        <>
                            <p style={{
                                paddingLeft: 10,
                                paddingBottom: 10
                            }}>No parts added!</p>
                        </>
                    }


                    <div
                        style={{
                            padding: 10,
                            position: 'fixed',
                            bottom: 10,
                            width: '350px'
                        }}>
                        <Alert2 variant="filled" severity="info">
                            The items you select above will appear in the plan!
                        </Alert2>
                    </div>
                </>
            </div>
        </Card2>
    );
}

function APPLICATIONRIGHTITEMCARD(props) {
    const { name, type, color, planSetup } = props;
    const { hasClasswork, hasMorePractice, hasHomework } = planSetup.application;

    return (
        <Card2 sx={{ maxWidth: 345 }}>
            <CardActionArea2>
                {/* <CardMedia2
            component="img"
            height="140"
            image="/static/images/cards/contemplative-reptile.jpg"
            alt="green iguana"
          /> */}
                <CardContent2
                    sx={{
                        backgroundColor: Constants.Application.COLOR_PRIMARY_LIGHT
                    }}
                    style={{
                        backgroundColor: Constants.Application.COLOR_PRIMARY_LIGHT
                    }}>
                    <Typography2 gutterBottom variant="h6" component="div">
                        {name}
                    </Typography2>
                    <Typography2 variant="body2" color="text.secondary">
                        Evaluate - provides activities that help learners apply their learning to new situations or contexts beyond the lesson and apply what they have learned.
                    </Typography2>
                </CardContent2>
            </CardActionArea2>
            <CardActions2>
                {/* <Button2 variant="contained" size="medium" color="primary"
            onClick={props.onCloseDrawer}>
            ADD
          </Button2> */}

                {/* 
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}>
            <Chip2 icon={<FaceIcon />} label={`Classwork Workbook`} color={`success`} />
            <div style={{ margin: 10 }}></div>
            <Chip2 icon={<FaceIcon />} label={`Classwork More Practice`} color={`secondary`} />
          </div>
           */}
            </CardActions2>


            <div>
                {/* classwork */}
                <RIGHTITEMCHECKBOX2
                    name="Classwork"
                    type="application"
                    isChecked={hasClasswork}
                    sub={['Workbook', 'Question & Answer']}
                    isRadio={true} // choose only one
                    planSetup={planSetup}
                    handleToggleResourceCheck={(name, type, checked) => {
                        props.handleToggleResourceCheck(name, type, checked)
                    }}
                    onUpdateRightItemSubChecked={(type, name, sub, isChecked2) => props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)}
                    onUpdatePlanSetup={(newPlanSetup) => props.onUpdatePlanSetup(newPlanSetup)}
                />

                {/* more practice */}
                <RIGHTITEMCHECKBOX2
                    name="More Practice"
                    type="application"
                    isChecked={hasMorePractice}
                    planSetup={planSetup}
                    handleToggleResourceCheck={(name, type, checked) => {
                        props.handleToggleResourceCheck(name, type, checked)
                    }}
                    onUpdateRightItemSubChecked={(type, name, sub, isChecked2) => props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)}
                    onUpdatePlanSetup={(newPlanSetup) => props.onUpdatePlanSetup(newPlanSetup)}
                />


                {/* homework */}
                <RIGHTITEMCHECKBOX2
                    name="Homework"
                    type="application"
                    isChecked={hasHomework}
                    planSetup={planSetup}
                    sub={['Question & Answer', 'Images', 'Question Tips']}
                    handleToggleResourceCheck={(name, type, checked) => {
                        props.handleToggleResourceCheck(name, type, checked)
                    }}
                    onUpdateRightItemSubChecked={(type, name, sub, isChecked2) => props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)}
                    onUpdatePlanSetup={(newPlanSetup) => props.onUpdatePlanSetup(newPlanSetup)}
                />

                <div
                    style={{
                        padding: 10,
                        position: 'fixed',
                        bottom: 10
                    }}>
                    <Alert2 variant="filled" severity="info">
                        The items you select above will appear in the plan!
                    </Alert2>
                </div>
            </div>
        </Card2>
    );
}

function ACTIONRIGHTITEMCARD(props) {
    const { name, type, color, planSetup } = props;
    const { hasSteps, hasTeacherTips, hasKeyword } = planSetup.action;

    return (
        <Card2 sx={{ maxWidth: 345 }}>
            <CardActionArea2>
                {/* <CardMedia2
            component="img"
            height="140"
            image="/static/images/cards/contemplative-reptile.jpg"
            alt="green iguana"
          /> */}
                <CardContent2
                    sx={{
                        backgroundColor: Constants.Application.COLOR_PRIMARY_LIGHT
                    }}
                    style={{
                        backgroundColor: Constants.Application.COLOR_PRIMARY_LIGHT
                    }}>
                    <Typography2 gutterBottom variant="h6" component="div">
                        {name}
                    </Typography2>
                    <Typography2 variant="body2" color="text.secondary">
                        Action plan provides instructional support and is a comprehensive way to monitor change and progress across a variety of objectives and goals.
                    </Typography2>
                </CardContent2>
            </CardActionArea2>
            <CardActions2>
                {/* <Button2 variant="contained" size="medium" color="primary"
            onClick={props.onCloseDrawer}>
            ADD
          </Button2> */}
                {/* <Chip2 icon={<FaceIcon />} label={`Step`} color={`info`} />
          <Chip2 icon={<FaceIcon />} label={`Teacher tip`} color={`secondary`} /> */}

                <div>
                    <RIGHTITEMCHECKBOX2
                        name="Step"
                        type="action"
                        isChecked={hasSteps}
                        planSetup={planSetup}
                        handleToggleResourceCheck={(name, type, checked) => {
                            props.handleToggleResourceCheck(name, type, checked)
                        }}
                        onUpdateRightItemSubChecked={(type, name, sub, isChecked2) => props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)}
                        onUpdatePlanSetup={(newPlanSetup) => props.onUpdatePlanSetup(newPlanSetup)}
                    />



                    {/* <RIGHTITEMCHECKBOX
              name="Keyword" /> */}
                    {/* <RIGHTITEMCHECKBOX
              name="Teacher tip"
              type="action"
              isChecked={hasTeacherTips}
              handleToggleResourceCheck={(name, type, checked) => {
                props.handleToggleResourceCheck(name, type, checked)
              }}
            /> */}



                    <div
                        style={{
                            padding: 10,
                            position: 'fixed',
                            bottom: 10
                        }}>
                        <Alert2 variant="filled" severity="info">
                            The items you select above will appear in the plan!
                        </Alert2>
                    </div>
                </div>
            </CardActions2>
        </Card2>
    );
}


function AIMRIGHTITEMCARD(props) {
    const { name, type, color, planSetup } = props;
    const { hasResource, hasKeyword } = planSetup.aim;
    console.log('LessonPlanDrawer.js AIMRIGHTITEMCARD: planSetup ' + JSON.stringify(planSetup));

    return (
        <Card2 sx={{ maxWidth: 345 }}>
            <CardActionArea2>
                {/* <CardMedia2
            component="img"
            height="140"
            image="/static/images/cards/contemplative-reptile.jpg"
            alt="green iguana"
          /> */}
                <CardContent2
                    sx={{
                        backgroundColor: Constants.Application.COLOR_PRIMARY_LIGHT
                    }}
                    style={{
                        backgroundColor: Constants.Application.COLOR_PRIMARY_LIGHT
                    }}>
                    <Typography2 gutterBottom variant="h6" component="div">
                        {name}
                    </Typography2>
                    <Typography2 variant="body2" color="text.secondary">
                        Outcome on Lesson plans describe what the teacher wants, learners to be able to do by the end of a lesson or what they will have done during it.
                    </Typography2>
                </CardContent2>
            </CardActionArea2>
            <CardActions2>
                {/* <Button2 variant="contained" size="medium" color="primary"
            onClick={props.onCloseDrawer}>
            ADD
          </Button2> */}

                {/* 
          <Chip2 icon={<FaceIcon />} label={`Resource`} color={`primary`} />
          <Chip2 icon={<FaceIcon />} label={`Keyword`} color={`warning`} /> */}

            </CardActions2>
            <div>
                <RIGHTITEMCHECKBOX2
                    name="Resource"
                    type="aim"
                    isChecked={hasResource}
                    planSetup={planSetup}
                    handleToggleResourceCheck={(name, type, checked) => {
                        props.handleToggleResourceCheck(name, type, checked)
                    }}
                    onUpdateRightItemSubChecked={(type, name, sub, isChecked2) => props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)}
                    onUpdatePlanSetup={(newPlanSetup) => props.onUpdatePlanSetup(newPlanSetup)}
                />
                <RIGHTITEMCHECKBOX2
                    name="Keyword"
                    type="aim"
                    isChecked={hasKeyword}
                    planSetup={planSetup}
                    handleToggleResourceCheck={(name, type, checked) => {
                        props.handleToggleResourceCheck(name, type, checked)
                    }}
                    onUpdateRightItemSubChecked={(type, name, sub, isChecked2) => props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)}
                    onUpdatePlanSetup={(newPlanSetup) => props.onUpdatePlanSetup(newPlanSetup)}
                />

                <div
                    style={{
                        padding: 10,
                        position: 'fixed',
                        bottom: 10
                    }}>
                    <Alert2 variant="filled" severity="info">
                        The items you select above will appear in the plan!
                    </Alert2>
                </div>
            </div>
        </Card2>
    );
}

function TemporaryDrawer2(props) {
    const { name, type, checked, planSetup } = props;
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    console.log('LessonPlanDrawer.js TemporaryDrawer2: first type ' + type + ' and checked ' + checked)

    const toggleDrawer = (anchor, open, type) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        props.onChangeAccordionHeaderStatus(type, open)
        console.log('LessonPlanDrawer.js TemporaryDrawer2: toggelDrawer() called with type ' + type + ' and open ' + open)

        // scroll to section
        props.scrollToSection();

        // show / hide main
        if (type === 'action' || type === 'assessment')
            return; // no action

        setState({ ...state, [anchor]: open }); // flip
    };

    /*
    const list = (anchor) => (
      <Box2
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List2>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem2 key={text} disablePadding>
              <ListItemButton2>
                <ListItemIcon2>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon2>
                <ListItemText2 primary={text} />
              </ListItemButton2>
            </ListItem2>
          ))}
        </List2>
        <Divider2 />
        <List2>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem2 key={text} disablePadding>
              <ListItemButton2>
                <ListItemIcon2>
                  {index % 2 === 0 ? <InboxIcon2 /> : <MailIcon2 />}
                </ListItemIcon2>
                <ListItemText2 primary={text} />
              </ListItemButton2>
            </ListItem2>
          ))}
        </List2>
      </Box2>
    );
  */

    const anchor2 = 'left'
    return (
        <div>
            {/* {['left', 'right', 'top', 'bottom'].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))} */}

            <React.Fragment key={anchor2}>
                {/* <Button2 onClick={toggleDrawer(anchor2, true)}>{anchor2}</Button2> */}
                {/* <Checkbox2 onClick={toggleDrawer(anchor2, true)} /> */}

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                }}>
                    <Button2
                        variant={(checked) ? 'contained' : 'outlined'}
                        sx={{
                            width: '100%',
                            // background: '#fa4515'
                        }}
                        onClick={toggleDrawer(anchor2, true, type)}
                        color="secondary"
                    // endIcon={<DoneIcon2 />}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}>
                            <span>{name}</span>
                            {checked &&
                                <DoneIcon2 />
                            }
                        </div>

                    </Button2>


                    {/*
            <Typography2
              variant="h5"
              onClick={toggleDrawer(anchor2, true, type)}
            >
              {name}
  
            </Typography2>
            */}

                    {/* button to open the drawer */}
                    {/* <Button2 variant="contained" endIcon={<QueueIcon2 />}>
              Add Resources
            </Button2> */}




                    {/*
            <IconButton2
              color="primary"
              aria-label="Add Resources"
              component="label"
            >
              
              {(checked) ?
                <QueueIcon2
                  color={Constants.Application.COLOR_PRIMARY}
                  style={{
                    color: Constants.Application.COLOR_PRIMARY
                  }}
                  onClick={toggleDrawer(anchor2, true, type)} />
                :
                <></>
              }
            </IconButton2>
            */}


                </div>

                <Drawer2
                    anchor={anchor2}
                    open={state[anchor2]}
                    onClose={toggleDrawer(anchor2, false)}
                >
                    <div
                        style={{
                            height: 'auto !important',
                            overflowY: 'scroll'
                        }}>
                        {/* {list(anchor2)} */}

                        {type === 'aim' ?
                            <>
                                <AIMRIGHTITEMCARD
                                    onClick={toggleDrawer(anchor2, true, 'aim')}
                                    name="Outcome " type="resource" color="primary"
                                    onCloseDrawer={toggleDrawer(anchor2, false, 'aim')}
                                    planSetup={planSetup}
                                    handleToggleResourceCheck={(name, type, checked) => props.handleToggleResourceCheck(name, type, checked)}
                                    onUpdateRightItemSubChecked={(type, name, sub, isChecked2) => props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)}
                                    onUpdatePlanSetup={(newPlanSetup) => props.onUpdatePlanSetup(newPlanSetup)}
                                />
                                {/* <Divider2 /> */}
                            </>
                            :
                            <></>
                        }

                        {/* Action */}
                        {type === 'action' ?
                            <>
                                <ACTIONRIGHTITEMCARD
                                    onClick={toggleDrawer(anchor2, true, 'action')}
                                    name="Learning Path " type="keyword" color="secondary"
                                    // onCloseDrawer={toggleDrawer(anchor2, false)}
                                    planSetup={planSetup}
                                    onCloseDrawer={toggleDrawer(anchor2, false, 'action')}
                                    handleToggleResourceCheck={(name, type, checked) => props.handleToggleResourceCheck(name, type, checked)}
                                    onUpdateRightItemSubChecked={(type, name, sub, isChecked2) => props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)}
                                    onUpdatePlanSetup={(newPlanSetup) => props.onUpdatePlanSetup(newPlanSetup)}
                                />
                                <Divider2 />
                            </>
                            :
                            <></>
                        }

                        {/* Analysis */}
                        {type === 'analysis' ?
                            <>
                                <ANALYSISRIGHTITEMCARD
                                    onClick={toggleDrawer(anchor2, true.valueOf, 'analysis')}
                                    name="Participate " type="step" color="info"
                                    onCloseDrawer={toggleDrawer(anchor2, false, 'analysis')}
                                    planSetup={planSetup}
                                    handleToggleResourceCheck={(name, type, checked) => props.handleToggleResourceCheck(name, type, checked)}
                                    onUpdateRightItemSubChecked={(type, name, sub, isChecked2) => props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)}
                                    onUpdatePlanSetup={(newPlanSetup) => props.onUpdatePlanSetup(newPlanSetup)}
                                />
                                <Divider2 />
                            </>
                            :
                            <></>
                        }

                        {/* Application */}
                        {type === 'application' ?
                            <>
                                <APPLICATIONRIGHTITEMCARD
                                    onClick={toggleDrawer(anchor2, true, 'application')}
                                    name="Evaluate "
                                    type="teacher-tip"
                                    color="success"
                                    onCloseDrawer={toggleDrawer(anchor2, false, 'application')}
                                    planSetup={planSetup}
                                    handleToggleResourceCheck={(name, type, checked) => props.handleToggleResourceCheck(name, type, checked)}
                                    onUpdateRightItemSubChecked={(type, name, sub, isChecked2) => props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)}
                                    onUpdatePlanSetup={(newPlanSetup) => props.onUpdatePlanSetup(newPlanSetup)}
                                />
                            </>
                            :
                            <></>
                        }

                        {/* Assessment */}
                        {type === 'assessment' ?
                            <>
                                <ASSESSMENTRIGHTITEMCARD
                                    onClick={toggleDrawer(anchor2, true, 'assessment')}
                                    name="Reflection"
                                    type="teacher-tip"
                                    color="success"
                                    onCloseDrawer={toggleDrawer(anchor2, false)}
                                    planSetup={planSetup}
                                    handleToggleResourceCheck={(name, type, checked) => props.handleToggleResourceCheck(name, type, checked)}
                                    onUpdateRightItemSubChecked={(type, name, sub, isChecked2) => props.onUpdateRightItemSubChecked(type, name, sub, isChecked2)}
                                    onUpdatePlanSetup={(newPlanSetup) => props.onUpdatePlanSetup(newPlanSetup)}
                                />
                            </>
                            :
                            <></>
                        }

                        {/*
              <RIGHTITEMCARD
                onClick={toggleDrawer(anchor2, true)}
                name="Analysis Part" type="analysis-part" color="success" onCloseDrawer={toggleDrawer(anchor2, false)} /> */}

                    </div>
                </Drawer2>
            </React.Fragment>
        </div>
    );
}

export default TemporaryDrawer2;