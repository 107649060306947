import axios from "axios";
import Constants from "../../resource/Constants";
import { TOPDETAILS_LIST_REQUEST, TOPDETAILS_LIST_SUCCESS, TOPDETAILS_LIST_FAIL } from "../constants/topicConstants";



const listTopGet = (chpId) => async (dispatch) => {
    try {
        dispatch({ type: TOPDETAILS_LIST_REQUEST })

        const { data } = await axios.get(Constants.Application.PLATFORM_END_URL + `/chapter-topic/?chapterid=${chpId}`)
        console.log("data in list subjects  get", data)
        dispatch({ type: TOPDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: TOPDETAILS_LIST_FAIL, payload: error.message })

    }
}

export { listTopGet }