import React, { useEffect, useState } from "react";
import { useLocation, useParams, useHistory, Link } from "react-router-dom";

import { emphasize, withStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Chip from "@material-ui/core/Chip";
import Chip2 from "@mui/material/Chip";
import VisibilityIcon2 from '@mui/icons-material/Visibility';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Modal2 from '@mui/material/Modal';

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import DateRangeIcon from "@material-ui/icons/DateRange";
// import HTML5Backend from "react-dnd-html5-backend";
// import { DragDropContext } from "react-dnd";
import BigCalendar from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import { DateRangePickerCalendar, START_DATE } from "react-nice-dates";
import HomeIcon from "@material-ui/icons/Home";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Link2 from "@mui/material/Link";
import TextField2 from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import EditIcon2 from "@mui/icons-material/Edit";

import List2 from "@mui/material/List";
import ListItem2 from "@mui/material/ListItem";
import Divider2 from "@mui/material/Divider";
import ListItemText2 from "@mui/material/ListItemText";
import ListItemIcon2 from "@mui/material/ListItemIcon";
import ListItemAvatar2 from "@mui/material/ListItemAvatar";
import Avatar2 from "@mui/material/Avatar";
import Typography2 from "@mui/material/Typography";

import CardActionArea2 from "@mui/material/CardActionArea";

import Breadcrumbs2 from "@mui/material/Breadcrumbs";
import Switch from "@mui/material/Switch";
import UNITAPI from "../../../http/curriculummapunit";
// import Alert3 from '@mui/material/Alert';

// iconv
// import Iconv from 'iconv';
// import Buffer from 'buffer';
// import assert from 'assert';
// var Buffer = require('buffer').Buffer;
// var Iconv  = require('iconv').Iconv;
// var assert = require('assert');
import store from "../../../store";

import { updateCurrLessonPlan } from "../../../redux/actions/currPlanActions";
import "react-nice-dates/build/style.css";

import {
    Avatar,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Typography,
    TextField,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Box, Container } from "@material-ui/core";
// import UnitListResults from "./UnitListResults";
import UnitListToolbar from "./UnitListToolbar";
import customers from "../../../__mocks__/customers";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { Edit } from "react-feather";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import MapAPI from "../../../http/curriculummap";
import MapUnitAPI from "../../../http/curriculummapunit";
import { useDispatch, useSelector } from "react-redux";
import { listUnitMapGet, postNewUnit } from "../../../redux/actions/unitMapAction";
import { UnitMapReducer } from "../../../redux/reducers/unitMapReducer";
import { updateCurrMap } from "../../../redux/actions/currMapActions";
import { updateCurrUnit } from "../../../redux/actions/currUnitActions";
import { updateCurrLesson } from "../../../redux/actions/currLessonActions";
import { updateCurrMapId } from "../../../redux/actions/currMapIdActions";

import { getUnitLessons } from "../../../redux/actions/unitLessonsActions";
import {
    getLessonPlans,
    postNewLessonPlan,
} from "../../../redux/actions/lessonPlansActions";
import Constants from "../../../resource/Constants";
import ImportContactsOutlinedIcon from "@material-ui/icons/ImportContactsOutlined";
import LeftCard from "./LeftCard";
import { Alert, AlertTitle } from "@material-ui/lab";
import Slider from "@material-ui/core/Slider";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ColorLensOutlinedIcon from "@material-ui/icons/ColorLensOutlined";
import ColorPicker from "./ColorPicker";

import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import QuizPlannerDrawer from "./QuizPlannerDrawer";
import MediaPlannerDrawer from "./MediaPlannerDrawer";
import {
    listChpGet,
    listChpGet2,
    listChpGet3,
} from "../../../redux/actions/chapterAction";
import { listTopGet } from "../../../redux/actions/topicAction";
import { truncate } from "lodash";

import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import { SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core";

import Box2 from "@mui/material/Box";
import ListItemButton2 from "@mui/material/ListItemButton";

import { FixedSizeList2 } from "react-window";

import Card2 from "@mui/material/Card";
import CardActions2 from "@mui/material/CardActions";
import CardContent2 from "@mui/material/CardContent";
import CardMedia2 from "@mui/material/CardMedia";
import Button2 from "@mui/material/Button";
import ButtonGroup2 from '@mui/material/ButtonGroup';

/* accordion elements from mui */
import Accordion2 from "@mui/material/Accordion";
import AccordionDetails2 from "@mui/material/AccordionDetails";
import AccordionSummary2 from "@mui/material/AccordionSummary";
import InboxIcon2 from "@mui/icons-material/Inbox";
import DraftsIcon2 from "@mui/icons-material/Drafts";

import ExpandMoreIcon2 from "@mui/icons-material/ExpandMore";

import { styled } from "@mui/material/styles";
import Grid2 from "@mui/material/Grid";
import Paper2 from "@mui/material/Paper";

import AddBoxIcon2 from "@mui/icons-material/AddBox";

import Dialog2 from "@mui/material/Dialog";
import DialogActions2 from "@mui/material/DialogActions";
import DialogContent2 from "@mui/material/DialogContent";
import DialogContentText2 from "@mui/material/DialogContentText";
import DialogTitle2 from "@mui/material/DialogTitle";

import InputLabel2 from "@mui/material/InputLabel";
import MenuItem2 from "@mui/material/MenuItem";
import FormControl2 from "@mui/material/FormControl";
import Select2 from "@mui/material/Select";

import Alert2 from "@mui/material/Alert";
import IconButton2 from "@mui/material/IconButton";
import CloseIcon2 from "@mui/icons-material/Close";

import Radio2 from "@mui/material/Radio";
import RadioGroup2 from "@mui/material/RadioGroup";
import FormControlLabel2 from "@mui/material/FormControlLabel";

import FormLabel2 from "@mui/material/FormLabel";

import LessonPlanSetupAPI from "../../../http/lessonplansetup";

// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import {
    filterMaps,
    listCurriculuFilterGet,
    listCurriculumGet,
    postNewCurriculum,
} from "../../../redux/actions/curriculumMapAction";
import { listSubSubGet } from "../../../redux/actions/subSubjectsAction";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Cookie from "js-cookie";
import UserSession from "../../../resource/UserSession";
// import MultiStep from 'react-multistep';
// import MasterForm from "./MasterForm";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import Drawer2 from "@mui/material/Drawer";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TAG = "AddLessonPlanGPT.js";
const userId3 = parseInt(Cookie.get('kgtopg.guruculum.user.id'));


function AddPlanGPTFormDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [successSnackOpen, setSuccessSnackOpen] = useState(false);
    const { currMap, instId, boardId, clsStd, subId, lsn, unitId5, lsnId5, chpId5 } = props;
    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    const [topic, setTopic] = useState(0);
    const [chapter, setChapter] = useState(10);
    const [dur, setDur] = useState("");
    const [err, setErr] = useState(
        "Please verify the form details before submitting..!"
    );
    const [mediaType, setMediaType] = useState("self");
    const [videos, setVideos] = useState(null);
    const [pdList, setPdList] = useState([]);

    const PEDAGOGY = (props) => {
        const { pedagogy } = props;
        const [title, setTitle] = React.useState('');
        const [desc, setDesc] = React.useState('');
        const [content, setContent] = React.useState('');
        const [isLoading, setIsLoading] = React.useState(false);

        const setDescription = (e) => {
            const { name, value } = e.target;
            console.log(TAG, 'setDescription() value ', value);

            setDesc(value);
        }

        const onGoClicked = async () => {

            if(desc && desc.length) {
                const body = {
                    board: 'CBSE',
                    subject: 'Science',
                    grade: 10,
                    chapter: 'Light - Reflection and refraction',
                    topic: 'Reflection of light',
                    sub_topic: 'Laws of reflection',
                    pedagogy_text: desc,//'Student activity with solutions',
                    process: true
                }
                const URL = 'http://192.168.29.251:8000/api/generate-pedagogy/';
                setIsLoading(true);

                const resp = await axios.post(URL, body);
                const resp2 = await resp.data;
                let sContent = '';

                console.log(TAG, 'onGoClicked resp2 ', JSON.stringify(resp2));

                if(resp2 && resp2.data) {

                    let data = resp2.data;
                    if(data && data.choices) {
                        sContent = data.choices[0].text;
                    }
                }


                console.log(TAG, 'onGoClicked sContent', sContent);
                setContent(sContent);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }

        return (
            <>
                <div
                style={{marginBottom: 20}}>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                width: "29%",
                            }}
                        >
                            <TextField
                                autoFocus
                                margin="dense"
                                id="desc"
                                label="Title"
                                placeholder="Enter here"

                                type="text"
                                fullWidth
                                variant="outlined"
                                style={{
                                    width: "99%",
                                }}
                                size="large"

                            />
                        </div>

                        <div
                            style={{
                                width: "69%",
                            }}
                        >
                            <TextField
                                autoFocus
                                margin="dense"
                                id="desc"
                                label="Description for the GPT Engine"
                                placeholder="Enter here"
                                onChange={setDescription}
                                type="text"
                                fullWidth
                                variant="outlined"
                                style={{
                                    width: "99%",
                                }}
                                size="large"

                            />
                        </div>

                        <div
                            style={{
                                width: '7%'
                            }}>
                            <Button2
                                variant="contained"
                                onClick={onGoClicked}
                                disabled={isLoading}>
                                Go
                            </Button2>
                        </div>

                    </div>

                    <div>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="desc"
                            label={(isLoading) ? 'Generating..' : 'Generated'}
                            multiline
                            rows={5}
                            value={content}
                            // defaultValue={content}
                            type="text"
                            fullWidth
                            variant="outlined"
                            style={{
                                width: "99%",
                            }}
                            size="large"
                            color={(isLoading) ? 'warning' : 'secondary'}
                            focused={(isLoading) ? true : false}

                        />
                    </div>
                </div>

            </>
        );
    }

    const PEDAGOGY_LIST = (props) => {

        return pdList.map((pedagogy, idx) => {
            return (
                <>
                    
                    <PEDAGOGY
                        pedagogy={pedagogy}
                    />

                </>
            )
        })
    }

    const cover_image =
        // "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/fa/6926005ea411e490ff8d4c5d4ff426/chemistry_logo.png?auto=format%2Ccompress&dpr=1";
        "https://media.istockphoto.com/id/1248593714/vector/flat-vector-illustration-teamwork-on-finding-new-ideas-little-people-launch-a-mechanism.jpg?s=612x612&w=is&k=20&c=k5GIFPNQBK-nk_PZteruzfSFXkP0MTe9njdUJ08uoWk=";

    const handleMediaTypeChanged = (val) => {
        setMediaType(val);
    };

    const dispatch = useDispatch();
    const history = useHistory();

    // const currLesson = useSelector(state => state.currLesson.currLesson)
    // console.log("UnitList.js handleSave: currLesson ", JSON.stringify(lsn));

    const topDetailsGet = useSelector((state) => state.topDetails);
    const { loadingTop, TopDetails, errorTop } = topDetailsGet;
    console.log(`topicdetails ${JSON.stringify(TopDetails)}`);

    const handleSnackClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackOpen(false);
    };

    const handleSuccessSnackClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
        const chpId = lsn.chapter_id;

        dispatch(listTopGet(chpId));
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const handleChangeChapter = (event) => {
        setChapter(event.target.value);
    };

    const handleChangeDur = (event) => {
        let val = event.target.value;
        console.log("UnitList.js AddPlanFormDialog handleChangeDur val ", val);
        setDur(val);
    };

    const handleChangeTopic = (event) => {
        setTopic(event.target.value);
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
    };

    const handleChangeDesc = (event) => {
        setDesc(event.target.value);
    };

    const handleSave = async () => {
        const lessonId = lsn.id;

        let dur2 = 0;
        if (dur) {
            console.log("UnitList.js handleSave: dur is " + dur);

            // dur is date
            // let min = dur.getMinutes();
            // let sec = dur.getSeconds();

            //dur2 = parseInt(dur.split(':')[0])
            dur2 = dur; //+':'+sec;
        }

        dur2 = 0;

        const body = {
            lesson_id: lessonId,
            duration_min: dur2,
            topic_id: topic,
            name: name,
            plan_description: desc,
            multimedia_self: mediaType === "self" ? 1 : 0,
            cover_image_url: cover_image,
            created_user_id: userId3,
            modified_user_id: userId3
        };

        console.log(
            "UnitList.js handleLessonPlanSetupSave body ",
            JSON.stringify(body)
        );

        if (!lessonId || !topic || !name) {
            // || !dur) {

            setSnackOpen(true);
            return;
        }

        // call save api
        const resp = await LessonPlanSetupAPI.postSavePlanSetup(body);
        console.log(
            "UnitList.js AddPlanFormDialog handleSave: response " +
            JSON.stringify(resp)
        );

        if (resp && resp.data && resp.data.msg && resp.data.msg === "saved") {
            // close dialogs
            handleClose();
            handleClose2();

            let planId = 0;
            const planSetup = resp.data.plan;
            planId = planSetup.lesson_plan_setup_id;

            // just open snackbar
            setSuccessSnackOpen(true);
            setTimeout(function () {
                // take him to next page
                // window.location.assign(`/publisher-ebook/curriculum-map/set-up/${planId}/?map_id=1&inst_id=${instId}&board_id=${boardId}&class_std=${clsStd}&sub_id=${subId}`)
                history.push(
                    `/publisher-ebook/curriculum-map/set-up/${planId}/?map_id=${currMap.id}&inst_id=${instId}&board_id=${boardId}&class_std=${clsStd}&sub_id=${subId}&unit_ud=${unitId5}&lesson_id=${lsnId5}&chap_id=${chpId5}`
                );
            }, 1000);
        } else if (
            resp &&
            resp.data &&
            resp.data.msg &&
            resp.data.msg === "exists"
        ) {
            setErr("A lesson plan already exists with same name!");
            setSnackOpen(true);
        } else {
            setErr("There was a problem saving lesson plan!");
            setSnackOpen(true);
        }
    };

    function ControlledRadioButtonsGroup(props) {
        const [value, setValue] = React.useState("guruculum");

        const handleChange = async (event) => {
            const val = event.target.value;
            setValue(val);

            // see if we opened guruculum option, then close the second modal
            // if (val !== 'self')
            // setOpen2(false)

            // props.handleMediaTypeChanged(event.target.value)
            setMediaType(val);

        };

        return (
            <FormControl2>
                <FormLabel2 id="demo-controlled-radio-buttons-group">
                    Type of media
                </FormLabel2>
                <RadioGroup2
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={mediaType}
                    onChange={handleChange}
                >
                    <FormControlLabel2
                        value="guruculum"
                        control={
                            <Radio2
                                onClick={() => {
                                    // onLoadContentByProv()
                                }}
                            />
                        }
                        label="Third Party Library"
                    />
                    <FormControlLabel2
                        value="self"
                        control={<Radio2 />}
                        label="Own Multimedia"
                    />
                </RadioGroup2>
            </FormControl2>
        );
    }

    const onAddPedagogy = (props) => {
        // copy object
        let sPdList = JSON.parse(JSON.stringify(pdList));

        let pdObj = {
            title: '',
            description: ''
        }

        sPdList.push(pdObj);
        setPdList(sPdList);
    }

    return (
        <div>

            <Card2
                sx={{ maxWidth: 345, textAlign: "center" }}
                onClick={() => {
                    // window.alert('yeah!!')
                    //navigation.navigate()
                    handleClickOpen();
                }}
            >
                <CardActionArea2>
                    <CardMedia2
                        component="img"
                        height="140"
                        image="https://cdn.pixabay.com/photo/2018/11/13/21/44/instagram-3814061_1280.png"
                        alt="green iguana"
                    />

                    <CardContent2>
                        <Typography2 gutterBottom variant="h5" component="div">
                            Add a new GPT plan
                        </Typography2>

                        <Typography2 variant="body2" color="text.secondary">
                            Add a new plan generated by GPT under 
                        </Typography2>
                    </CardContent2>
                </CardActionArea2>
            </Card2>
            {/* </Link2> */}

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogTitle>Add a new GPT plan </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add a new plan generated by GPT under
                        &nbsp;&nbsp;
                        <Chip2 label="CBSE" color="primary" /> 
                        &nbsp;&nbsp;
                        <Chip2 label="Mathematics" color="info" />
                        &nbsp;&nbsp;
                        <Chip2 label="Standard 10" color="secondary" />
                        &nbsp;&nbsp;
                        <Chip2 label="Geometry" color="success" />
                    </DialogContentText>

                    <>
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Plan Name"
                                    value={name}
                                    placeholder="Enter here"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    style={{
                                        width: "99%",
                                    }}
                                    size="large"
                                    onChange={handleChangeName}
                                />
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="desc"
                                    label="Plan Description"
                                    placeholder="Enter here"
                                    value={desc}
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    style={{
                                        width: "99%",
                                    }}
                                    size="large"
                                    onChange={handleChangeDesc}
                                />
                            </div>

                            <div
                                style={{
                                    margin: 20,
                                }}
                            ></div>

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        width: "49%",
                                        marginRight: 10
                                    }}
                                >
                                    <FormControl2 fullWidth>
                                        <InputLabel2 id="demo-simple-select-label">
                                            Topic
                                        </InputLabel2>
                                        <Select2
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={topic}
                                            // value={tpcId}
                                            // onChange={handleTpcChange}
                                            label="Topic"
                                            size="small"
                                            onChange={handleChangeTopic}
                                        >
                                            <MenuItem2 value={0}>Select Topic</MenuItem2>

                                            {loadingTop && topDetailsGet ? (
                                                console.log("loading trueeee")
                                            ) : TopDetails.data && TopDetails.data.length != 0 ? (
                                                TopDetails.data.map((list, index) => {
                                                    let selected = false;

                                                    if (topic == list.topic_id) selected = true;

                                                    return (
                                                        <MenuItem2
                                                            selected={selected}
                                                            key={index}
                                                            value={list.topic_id}
                                                            style={{
                                                                fontSize: 13,
                                                                color: "#464E5F",
                                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                            }}
                                                        >
                                                            {list.topic_title}
                                                        </MenuItem2>
                                                    );
                                                })
                                            ) : (
                                                <MenuItem2
                                                    style={{
                                                        fontSize: 13,
                                                        color: "#464E5F",
                                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                                    }}
                                                >
                                                    Chapter not selected
                                                </MenuItem2>
                                            )}
                                        </Select2>
                                    </FormControl2>
                                </div>

                                <div
                                    style={{
                                        width: "49%",
                                    }}
                                >
                                    <FormControl2 fullWidth>
                                        <InputLabel2 id="demo-simple-select-label">
                                            Sub Topic
                                        </InputLabel2>
                                        <Select2
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            // value={topic}
                                            // value={tpcId}
                                            // onChange={handleTpcChange}
                                            label="Topic"
                                            size="small"
                                            // onChange={handleChangeTopic}
                                        >
                                            <MenuItem2 value={0}>Select Sub Topic</MenuItem2>

                                            {/* {loadingTop && topDetailsGet ? (
                                                console.log("loading trueeee")
                                            ) : TopDetails.data && TopDetails.data.length != 0 ? (
                                                TopDetails.data.map((list, index) => {
                                                    let selected = false;

                                                    if (topic == list.topic_id) selected = true;

                                                    return (
                                                        <MenuItem2
                                                            selected={selected}
                                                            key={index}
                                                            value={list.topic_id}
                                                            style={{
                                                                fontSize: 13,
                                                                color: "#464E5F",
                                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                            }}
                                                        >
                                                            {list.topic_title}
                                                        </MenuItem2>
                                                    );
                                                })
                                            ) : (
                                                <MenuItem2
                                                    style={{
                                                        fontSize: 13,
                                                        color: "#464E5F",
                                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                                    }}
                                                >
                                                    Chapter not selected
                                                </MenuItem2>
                                            )} */}
                                        </Select2>
                                    </FormControl2>
                                </div>

                            </div>


                            <div style={{ margin: 20 }}></div>

                            {/* GPT specific fields */}
                            <h4 className="h4">Pedagogy Details</h4>

                            <PEDAGOGY_LIST />
                            <div style={{margin:10}}></div>

                            <Button
                            variant="outlined"
                            onClick={onAddPedagogy}
                            >Add</Button>
                        </>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    {/* <Button onClick={() => { onNextClicked() }}>NEXT</Button> */}
                    <Button onClick={() => handleSave(true)}>SAVE</Button>
                </DialogActions>
            </Dialog>

            {/* choose provider */}
            <Dialog
                open={open2}
                onClose={handleClose2}
                fullWidth={true}
                maxWidth={"sm"}
            >
                <DialogTitle>Choose the media type </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Choose what type of media is available to teacher
                    </DialogContentText>

                    <>
                        <ControlledRadioButtonsGroup
                            handleMediaTypeChanged={(type) => handleMediaTypeChanged(type)}
                            setVideos={(videos) => setVideos(videos)}
                        />
                    </>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            handleClose2();
                            setOpen(true);
                            setMediaType("self");
                        }}
                    >
                        Cancel
                    </Button>
                    <Button onClick={() => handleSave(true)}>SAVE</Button>
                </DialogActions>
            </Dialog>

            {/* <MediaDrawer /> */}

            {/* message snack */}
            <Snackbar
                open={snackOpen}
                autoHideDuration={6000}
                onClose={handleSnackClose}
            >
                <Alert
                    onClose={handleSnackClose}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {err}
                </Alert>
            </Snackbar>
            <Snackbar
                open={successSnackOpen}
                autoHideDuration={6000}
                onClose={handleSuccessSnackClose}
            >
                <Alert
                    onClose={handleSuccessSnackClose}
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    Plan Setup saved successfully. Please wait until we redirect you to
                    the creator page.
                </Alert>
            </Snackbar>
        </div>
    );
}

function LessonPlanAddGPTCard(props) {
    const { lsn } = props;
    const queryParams = new URLSearchParams(useLocation().search);
    const params = useParams();

    console.log(
        `UnitList.js LessonPlanAddCard queryParams ${JSON.stringify(queryParams)}`
    );
    console.log(`UnitList.js LessonPlanAddCard params ${JSON.stringify(params)}`);
    const currMap = useSelector((state) => state.currMap.currMap);
    const currUnit = useSelector((state) => state.currUnit.currUnit);
    const currLesson = useSelector((state) => state.currLesson.currLesson);

    let instId = 0;
    let boardId = 0;
    let clsStd = 0;
    let subId = 0;
    let unitId5 = 0;
    let lsnId5 = 0;
    let chpId5 = 0;

    console.log(
        "UnitList.js LessonPlanAddCard currMap " + JSON.stringify(currMap)
    );

    if (!currMap) {
        return <span>Loading..</span>;
    }

    instId = currMap.institute_type_id;
    boardId = currMap.institute_board_id;
    clsStd = currMap.classroom_std;
    subId = currMap.subject_id;
    unitId5 = currUnit.id;
    lsnId5 = currLesson.id;
    chpId5 = currLesson.chapter_id;

    return (
        <AddPlanGPTFormDialog
            currMap={currMap}
            instId={instId}
            boardId={boardId}
            clsStd={clsStd}
            subId={subId}
            lsn={lsn}
            unitId5={unitId5}
            lsnId5={lsnId5}
            chpId5={chpId5}
        />
    );
}

export default LessonPlanAddGPTCard;
