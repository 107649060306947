import { SINGLEEVENTPOST_LIST_REQUEST, SINGLEEVENTPOST_LIST_SUCCESS, SINGLEEVENTSPOST_LIST_FAIL, SINGLEVIDEOPOST_LIST_FAIL, SINGLEVIDEOPOST_LIST_REQUEST, SINGLEVIDEOPOST_LIST_SUCCESS, SUBSCRIBERSDETAILSPOST_LIST_FAIL, SUBSCRIBERSDETAILSPOST_LIST_REQUEST, SUBSCRIBERSDETAILSPOST_LIST_SUCCESS, TRAINTEACHVIDEOSPOST_LIST_FAIL, TRAINTEACHVIDEOSPOST_LIST_REQUEST, TRAINTEACHVIDEOSPOST_LIST_SUCCESS } from "../../constants/Trainer/AllEventsPost";

function singleEventPostReducer(state ={singleEvntPostData:[]},action) {

    switch (action.type) {
        case SINGLEEVENTPOST_LIST_REQUEST:
            return { loadingSinglePost: true }
        case SINGLEEVENTPOST_LIST_SUCCESS:
            return {loadingSinglePost:false,singleEvntPostData:action.payload};
        case SINGLEEVENTSPOST_LIST_FAIL:
            return {loadingSinglePost:false,errorSingleEventPost:action.payload}
        default:
            return state;
    }

}


function singleVideoPostReducer(state ={singleVideoPost:[]},action) {

    switch (action.type) {
        case SINGLEVIDEOPOST_LIST_REQUEST:
            return { loadingSingleVidPost: true }
        case SINGLEVIDEOPOST_LIST_SUCCESS:
            return {loadingSingleVidPost:false,singleVideoPost:action.payload};
        case SINGLEVIDEOPOST_LIST_FAIL:
            return {loadingSingleVidPost:false,errorSingleVidPost:action.payload}
        default:
            return state;
    }

}

function subscribersPostReducer(state ={subscrPost:[]},action) {

    switch (action.type) {
        case SUBSCRIBERSDETAILSPOST_LIST_REQUEST:
            return { loadingSubscrPost: true }
        case SUBSCRIBERSDETAILSPOST_LIST_SUCCESS:
            return {loadingSubscrPost:false,subscrPost:action.payload};
        case SUBSCRIBERSDETAILSPOST_LIST_FAIL:
            return {loadingSubscrPost:false,errorSubscrPost:action.payload}
        default:
            return state;
    }

}

function trainTeachVidPostReducer(state ={teachVidPost:[]},action) {

    switch (action.type) {
        case TRAINTEACHVIDEOSPOST_LIST_REQUEST:
            return { loadingteachVidPost: true }
        case TRAINTEACHVIDEOSPOST_LIST_SUCCESS:
            return {loadingteachVidPost:false,teachVidPost:action.payload};
        case TRAINTEACHVIDEOSPOST_LIST_FAIL:
            return {loadingteachVidPost:false,errorteachVidPost:action.payload}
        default:
            return state;
    }

}



export {
     singleEventPostReducer,
     singleVideoPostReducer,
     subscribersPostReducer,
     trainTeachVidPostReducer, 
    }