import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Route, Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from "react-router-dom";

import Cookie from 'js-cookie';
import AuthAPI from '../../http/auth';
import { useDispatch, useSelector } from 'react-redux';
import { signinAuth } from '../../redux/actions/UserAuthAction';
import Constants from '../../resource/Constants';
import { CircularProgress } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import LOGO from '../../assets/PustakLogo.png'
import Copyright from './slots/Copyright'
import pustakPublish from "../../assets/PustakPublish.png"



function Alert2(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright1() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));



export default function ChangePassw() {
    const classes = useStyles();
    const [passw, setPassw] = useState("");
    const history = useHistory();
    const [confPassw, setConfPassw] = useState("");
    const [token, setToken] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [openSnack, setOpenSnack] = useState(false)
    const [openSuccessSnack, setOpenSuccessSnack] = useState(false)

    const handleSnackClose = () => {
        setOpenSnack(false)
    }

    const handleSuccessSnackClose = () => {
        setOpenSuccessSnack(false)
    }

    // let CONTENTUPLOAD = Cookie.get('kgtopg.guruculum.user.CONTENTUPLOAD')
    // let QUALITYASSURANCE = Cookie.get('kgtopg.guruculum.user.QUALITYASSURANCE')
    // let SUPERADMIN = Cookie.get('kgtopg.guruculum.user.SUPERADMIN')
    // let CONTENTMAP = Cookie.get('kgtopg.guruculum.user.CONTENTMAP')
    // let CURRICULUMMAP = Cookie.get('kgtopg.guruculum.user.CURRICULUMMAP')
    // let EBOOKUPLOAD = Cookie.get('kgtopg.guruculum.user.EBOOKUPLOAD')
    // let TRAINER = Cookie.get('kgtopg.guruculum.user.TRAINER')



    const navigateToPublisher = (role, userData) => history.push({ pathname: '/publisher-ebook/dashboard', state: { role: role, roleData: userData } });//eg.history.push('/login');
    const navigateToPublisherEbooks = (role, userData) => history.push({ pathname: '/publisher-ebook/books', state: { role: role, roleData: userData } });//eg.history.push('/login');
    const navigateToPublisherCurriculum = (role, userData) => history.push({ pathname: '/publisher-ebook/curriculum-map', state: { role: role, roleData: userData } });//eg.history.push('/login');
    const navigateToPublisherTrainer = (role, userData) => history.push({ pathname: '/publisher-ebook/trainer', state: { role: role, roleData: userData } });//eg.history.push('/login');

    const navigateToContent = (role, userData) => history.push({ pathname: '/publisher-content/dashboard', state: { role: role, roleData: userData } });//eg.history.push('/login');
    const navigateToContUpload = (role, userData) => history.push({ pathname: '/publisher-content/content', state: { role: role, roleData: userData } });//eg.history.push('/login');






    const changePassword = async () => {

        setIsLoading(true)
        if(!passw || !confPassw) {
            setOpenSnack(true)
            return;
        }


        if(passw != confPassw) {
            setOpenSnack(true)
            return;
        }


        const body = {
            token: token,
            user_password: passw
        }

        try {
            const resp = await AuthAPI.userChangePswrd(body)
            console.log(`response after login  ${JSON.stringify(resp)}`);






            // if (resp && resp.data.token) {
            //     const response = await AuthAPI.doLogin(body)
            //     // console.log(`response after login after authentication  ${JSON.stringify(resp)}`);
            //     console.log("response after login authentication", response)

            //     const roles = Cookie.get('kgtopg.guruculum.user.Roles')
            //     console.log("roles at login screen", roles)
            //     const CONTENTUPLOAD = (roles && roles.includes("CONTENTUPLOAD"))
            //     const QUALITYASSURANCE = (roles && roles.includes("QUALITYASSURANCE"))
            //     const SUPERADMIN = (roles && roles.includes("SUPERADMIN"))
            //     const CONTENTMAP = (roles && roles.includes("CONTENTMAP"))
            //     const CURRICULUMMAP = (roles && roles.includes("CURRICULUMMAP"))
            //     const EBOOKUPLOAD = (roles && roles.includes("EBOOKUPLOAD"))
            //     const TRAINER = (roles && roles.includes("TRAINER"))



            //     console.log("role status CONTENT UPLOAD at login", CONTENTUPLOAD)
            //     console.log("role status QUALITYASSURANCE at login ", QUALITYASSURANCE)
            //     console.log("role status SUPERADMIN at login ", SUPERADMIN)
            //     console.log("role status CONTENTMAP at login ", CONTENTMAP)
            //     console.log("role status CURRICULUMMAP at login", CURRICULUMMAP)
            //     console.log("role status EBOOKUPLOAD at login ", EBOOKUPLOAD)
            //     console.log("role status TRAINER at login", TRAINER)


            //     if (response && response.data && response.data.role == "EBOOK_PROVIDER") {
            //         if (SUPERADMIN) {
            //             navigateToPublisher(response.data.role, response.data)
            //         } else if (EBOOKUPLOAD) {
            //             navigateToPublisherEbooks(response.data.role, response.data)
            //         } else if (CONTENTMAP) {
            //             navigateToPublisherCurriculum(response.data.role, response.data)
            //         } else if (CURRICULUMMAP) {
            //             navigateToPublisherCurriculum(response.data.role, response.data)
            //         } else if (TRAINER) {
            //             navigateToPublisherTrainer(response.data.role, response.data)
            //         }
            //     }

            //     if (response && response.data && response.data.role == "CONTENT_PROVIDER") {
            //         console.log("entering in content provider SUPERADMIN", SUPERADMIN)
            //         console.log("entering in content provider CONTENTUPLOAD", CONTENTUPLOAD)

            //         if (SUPERADMIN) {
            //             console.log("entering in content provider dashboard ")
            //             navigateToContent(response.data.role, response.data)

            //         } else if (CONTENTUPLOAD) {
            //             console.log("entering in content provider content upload ")
            //             navigateToContUpload(response.data.role, response.data)
            //         } else if (QUALITYASSURANCE) {
            //             console.log("entering in content provider QualityAssurance ")
            //             navigateToContUpload(response.data.role, response.data)
            //         }
            //     }
            // } else {

            // }
            setOpenSuccessSnack(true)
        } catch (e) {
            console.log(`exception authenticating user ${e.message}`)
            setOpenSnack(true)
        }

        setIsLoading(false)


    }





    const doAuth = async (resp) => {

        console.log("response at doAuth", resp)

        if (resp && resp.data) {



            const body = {
                user_email: resp.data.user_email,
                user_password: resp.data.user_password
            }
            // dispatch(signinAuth(body))


            const response = await AuthAPI.doAuth(body)
            console.log(`response after login Authentication ${JSON.stringify(response)}`);

            // navigateTo()

        }


    }

    const handlePassChanged = (event) => {
        const { name, value } = event.target;
        setPassw(value);
    }

    const handleConfPassChanged = (event) => {
        const { name, value } = event.target;
        setConfPassw(value);
    }

    // const logo = 'https://kgtopg.com/schooler/img/logo_small.png';
    const logo = LOGO


    const dispatch = useDispatch()


    const getUserAuthResp = useSelector(state => state.userSigninAuth)
    const { loadingAuth, userInfo, errorAuth } = getUserAuthResp


    {
        loadingAuth && userInfo ?
            <></>
            :
            console.log("authentication response", userInfo)

    }


    useEffect(() => {

        const userLoggedIn = Cookie.get('kgtopg.guruculum.user.login')
        const userRole = Cookie.get('kgtopg.guruculum.user.role')

        const query = new URLSearchParams(window.location.search);
        const tokenId = query.get("token");

        setToken(tokenId)

        // if (userLoggedIn) {
        //     if (userRole == Constants.Application.ROLE_CONT_PROV) {
        //         history.push('/publisher-content/dashboard');//eg.history.push('/login');
        //     } else if (userRole == Constants.Application.ROLE_EBOOK_PUB) {
        //         history.push('/publisher-ebook/dashboard');//eg.history.push('/login');

        //     }
        // }




        return () => {
            //
        }

    }, [])




    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                {/* <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                    
                </Avatar> */}
                <img src={pustakPublish} alt="Logo" height={70} />
                {/* <Typography component="h1" variant="h5">
                    Sign in
                </Typography> */}
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        defaultValue={passw}
                        onChange={handlePassChanged}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                // doLogin()
                            }
                        }}
                        margin="normal"
                        required
                        fullWidth
                        type="password"
                        id="password"
                        label="New Password"
                        name="New Password"
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        defaultValue={confPassw}
                        onChange={handleConfPassChanged}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                // doLogin()
                            }
                        }}
                        margin="normal"
                        required
                        fullWidth
                        name="Confirm Password"
                        label="Confirm Password"
                        type="password"
                        id="password"
                    />
                    {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    /> */}
                    <Button
                        disabled={isLoading}
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => changePassword()}
                    >
                        {(!isLoading) ?
                            <span>Change Password</span>
                            :
                            <CircularProgress />
                        }
                    </Button>
                    <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose}>
                        <Alert2 onClose={handleSnackClose} severity="warning">
                            Change Password failed!
                        </Alert2>
                    </Snackbar>

                    <Snackbar open={openSuccessSnack} autoHideDuration={6000} onClose={handleSuccessSnackClose}>
                        <Alert2 onClose={handleSnackClose} severity="success">
                            Password updated successfully..
                        </Alert2>
                    </Snackbar>
                    <Grid container>
                        <Grid item xs>
                            <Link to="/" variant="body2" style={{ textDecoration: "none", color: "blue" }}>
                                login
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/user/register" variant="body2" style={{ textDecoration: "none", color: "blue" }}>
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}