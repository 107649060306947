import axios from "axios";
import Constants from "../../resource/Constants";
import { CONTTYPE_LIST_REQUEST, CONTTYPE_LIST_SUCCESS, CONTTYPE_LIST_FAIL } from "../constants/contentTypeConstants";



const listContTypeGet = () => async (dispatch) => {
    try {
        dispatch({ type: CONTTYPE_LIST_REQUEST })

        const { data } = await axios.get(Constants.Application.PARTNER_END_URL+"/get-cont-type/")
        console.log("data in list subjects  get", data)
        dispatch({ type: CONTTYPE_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: CONTTYPE_LIST_FAIL, payload: error.message })

    }
}

export { listContTypeGet }