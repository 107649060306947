
export const SINGLEEVENTPUT_LIST_REQUEST = ' SINGLEEVENTPUT_LIST_REQUEST'
export const SINGLEEVENTPUT_LIST_SUCCESS = '  SINGLEEVENTPUT_LIST_SUCCESS'
export const SINGLEEVENTPUT_LIST_FAIL = ' SINGLEEVENTPUT_LIST_FAIL'


export const SINGLEVIDEOPUT_LIST_REQUEST = ' SINGLEVIDEOPUT_LIST_REQUEST'
export const SINGLEVIDEOPUT_LIST_SUCCESS = '  SINGLEVIDEOPUT_LIST_SUCCESS'
export const SINGLEVIDEOPUT_LIST_FAIL = ' SINGLEVIDEOPUT_LIST_FAIL'

export const SUBSCRIBERSDETAILAPUT_LIST_REQUEST = ' SUBSCRIBERSDETAILAPUT_LIST_REQUEST'
export const SUBSCRIBERSDETAILAPUT_LIST_SUCCESS = '  SUBSCRIBERSDETAILAPUT_LIST_SUCCESS'
export const SUBSCRIBERSDETAILAPUT_LIST_FAIL = ' SUBSCRIBERSDETAILAPUT_LIST_FAIL'

export const TRAINTEACHVIDEOSPUT_LIST_REQUEST = 'TRAINTEACHVIDEOSPUT_LIST_REQUEST'
export const TRAINTEACHVIDEOSPUT_LIST_SUCCESS = 'TRAINTEACHVIDEOSPUT_LIST_SUCCESS'
export const TRAINTEACHVIDEOSPUT_LIST_FAIL = 'TRAINTEACHVIDEOSPUT_LIST_FAIL'
