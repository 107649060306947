import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';

import StarBorderIcon from '@material-ui/icons/StarBorder';
// import itemData from './itemData';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';

const itemData = [
    {
        img: 'https://img.freepik.com/free-vector/gradient-christmas-tinsel-background_52683-76117.jpg',
        title: 'Image',
        author: 'author',
    },
    {
        img: 'https://images.indianexpress.com/2021/12/GettyImages-MerryChristmas-1200.jpg?w=414',
        title: 'Image',
        author: 'author',
    },
    {
        img: 'https://img.freepik.com/free-vector/gradient-christmas-tinsel-background_52683-76117.jpg',
        title: 'Image',
        author: 'author',
    },
    {
        img: 'https://images.indianexpress.com/2021/12/GettyImages-MerryChristmas-1200.jpg?w=414',
        title: 'Image',
        author: 'author',
    },
]

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        overflowX: 'scroll',
        scrollbarWidth: 'thin', /* For Firefox */
        scrollbarColor: '#888888 #f0f0f0', /* For Chrome, Edge, and Safari */
    },
    imageList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    scrollableContainer: {
        overflowX: 'scroll',
        scrollbarWidth: 'thin', /* For Firefox */
        scrollbarColor: '#888888 #f0f0f0', /* For Chrome, Edge, and Safari */
    },
}));

function SingleLineImageList() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <ImageList className={classes.imageList} cols={2.5}>
                {itemData.map((item) => (
                    <ImageListItem key={item.img}>
                        <img src={item.img} alt={item.title} />
                        <ImageListItemBar
                            title={item.title}
                            classes={{
                                root: classes.titleBar,
                                title: classes.title,
                            }}
                            actionIcon={
                                <IconButton aria-label={`star ${item.title}`}>
                                    <StarBorderIcon className={classes.title} />
                                </IconButton>
                            }
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </div>
    );
}

const images = [
    'https://img.freepik.com/free-vector/gradient-christmas-tinsel-background_52683-76117.jpg',
    'https://images.indianexpress.com/2021/12/GettyImages-MerryChristmas-1200.jpg?w=414',
    'https://imgk.timesnownews.com/story/Merry_Christmas_2021_wishes_and_quotes.jpg?tr=w-1200,h-900',
    // Add more image URLs as needed
];

const MyGallery = () => {
    return (
        <Grid container spacing={2}>
            {images.map((image, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Paper>
                        <img src={image} alt={`Image ${index + 1}`} />
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
};

const WIDTHBOX = () => {
    return (
        <div style={{
            width: 10
        }}></div>
    )
}

const MAIN_HEADING = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}>

            <img src="https://upload.wikimedia.org/wikipedia/commons/0/0e/Neve_a_Verona_17.01.2006_033.jpg"
                height={100}
                alt=''
            />

            <WIDTHBOX />

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                <Typography
                    variant='h6'>My first content</Typography>
                <WIDTHBOX />
                <EditIcon />
            </div>

        </div>
    )
}

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

const HEIGHTBOX = (props) => {
    let ht = (props.height !== undefined) ? props.height : 10;
    return (
        <div style={{ margin: ht }}></div>
    )
}

const KEYWORDSLIST = () => {
    const handleClick = () => {
        console.info('You clicked the Chip.');
    };

    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };

    return (
        <>
            <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'flex-start'
            }}>
                <Chip
                    label="There"
                    onClick={handleClick}
                    onDelete={handleDelete}
                    deleteIcon={<DoneIcon />}
                    className='m3'
                />
                <Chip
                    label="is"
                    onClick={handleClick}
                    onDelete={handleDelete}
                    deleteIcon={<DoneIcon />}
                    className='m3'

                />

                <Chip label="one" className='m3' />

                <Chip label="tree" className='m3' />
                <Chip label="the" className='m3' />
                <Chip label="of" className='m3' />
                <Chip label="giant" className='m3' />
                <Chip label="star" className='m3' />

                <Chip label="top" className='m3' />
                <Chip label="giant" className='m3' />
                <Chip label="star" className='m3' />

            </div>
        </>
    )
}

const card = (
    <React.Fragment>
        <CardHeader

            action={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                    <EditIcon />
                    <IconButton aria-label="settings">
                        <CloseIcon color="red" style={{ color: 'red' }} />
                    </IconButton>
                </div>
            }
            title="1. There is one giant star on the opt of the tree"
            subheader="SENTENCE"
        />
        <CardContent>
            <Card variant="outlined">
                <CardContent>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}>
                        <Typography
                            sx={{ fontSize: 14, marginRight: 10 }} color="text.secondary" gutterBottom>
                            TRANSLATION
                        </Typography>

                        <TextField
                            style={{
                                width: '80%'
                            }}
                            id="standard-basic" label="Standard" variant="standard" />
                    </div>

                    <HEIGHTBOX />

                    <Divider />

                    <HEIGHTBOX />


                    <div

                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}>
                        <TextField
                            style={{
                                width: '50%'
                            }}
                            id="standard-basic" label="There is one giant star on the top" variant="standard" />

                        <span
                            style={{ margin: 10 }}>
                            (Or)
                        </span>

                        <Button variant="outlined"
                            style={{
                                // marginLeft: 10
                            }}>Upload your Image</Button>
                    </div>

                    <HEIGHTBOX />

                    <SingleLineImageList />

                    <HEIGHTBOX />

                    <Divider />

                    <HEIGHTBOX />

                    {/* Keywords */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}>
                        <div
                            style={{
                                width: '20%'
                            }}>
                            <Typography
                                variant='h6'>KEYWORDS</Typography>
                        </div>

                        <div
                            style={{
                                width: '78%'
                            }}>

                            <KEYWORDSLIST />
                        </div>
                    </div>
                </CardContent>
            </Card>

        </CardContent>
        <CardActions>
            <Button size="small">Learn More</Button>
        </CardActions>
    </React.Fragment>
);

const SENTENCE_BOX = () => {
    return (
        <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined"
                sx={{
                    borderRadius: 2.5
                }}>{card}</Card>
        </Box>
    );
}

const MainPage2 = (props) => {

    return (
        <div
            style={{
                width: "49%",
                marginLeft: 10,
                minHeight: "100%",
                position: "relative",
            }}
        >
            <MAIN_HEADING />
            <div style={{ margin: 20 }}></div>

            <SENTENCE_BOX />
        </div>
    )
}


export default MainPage2