/**
 * @project kgtopg - schooler - partner
 * curriculum map data services
 */
import API from "./http";
import axios from "axios";
import qs from "querystring";
import Constants from "../resource/Constants";

export default {
  async postSavePlanSetup(data) {
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
      };

      let url = Constants.Application.PARTNER_END_URL;
      // url += "/curriculum-map/";
      url += "/lesson-plan-setup/";

      console.log("postSavePlanSetup api url", url);

      const options = {
        method: "POST",
        data: data,
        url,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': 'Bearer ' + UserSession.authtoken,
        },
      };

      let response = await axios(options);

      console.log("postSavePlanSetup api response", response);

      if (response) return response;
      else return false;
    } catch (ex) {
      return false;
    }
  },

  
  async postSaveNextPlanSetup(data) {
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
      };

      let url = Constants.Application.PARTNER_END_URL;
      // url += "/curriculum-map/";
      url += "/add-next-lesson-plan/";

      console.log("postSaveNextPlanSetup api url", url);

      const options = {
        method: "POST",
        data: data,
        url,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': 'Bearer ' + UserSession.authtoken,
        },
      };

      let response = await axios(options);

      console.log("postSaveNextPlanSetup api response", response);

      if (response) return response;
      else return false;
    } catch (ex) {
      return false;
    }
  },
  async putUpdatePlanSetup(data) {
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
      };

      let url = Constants.Application.PARTNER_END_URL;
      url += `/lesson-plan-setup/`;

      console.log("putUpdatePlanSetup api url", url);

      const options = {
        method: "PUT",
        data: data,
        url,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': 'Bearer ' + UserSession.authtoken,
        },
      };

      let response = await axios(options);

      console.log("putUpdatePlanSetup api response", response);

      if (response) return response;
      else return false;
    } catch (ex) {
      return false;
    }
  },
  async putUpdatePlanSetupApproved(data) {
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
      };

      let url = Constants.Application.PARTNER_END_URL;
      url += `/update-lesson-plan-setup-isapproved/`;

      console.log("putUpdatePlanSetupApproved api url", url);

      const options = {
        method: "PUT",
        data: data,
        url,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': 'Bearer ' + UserSession.authtoken,
        },
      };

      let response = await axios(options);

      console.log("putUpdatePlanSetupApproved api response", response);

      if (response) return response;
      else return false;
    } catch (ex) {
      return false;
    }
  },
  async postUpdatePlanSetup(data) {
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
      };

      let url = Constants.Application.PARTNER_END_URL;
      url += `/lesson-plan-setup/`;

      console.log("postUpdatePlanSetup api url", url);

      const options = {
        method: "PUT",
        data: data,
        url,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': 'Bearer ' + UserSession.authtoken,
        },
      };

      let response = await axios(options);

      console.log("postUpdatePlanSetup api response", response);

      if (response) return response;
      else return false;
    } catch (ex) {
      return false;
    }
  },
  async deletePlanSetup(mapId) {
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
      };

      let url = Constants.Application.PARTNER_END_URL;
      url += `/lesson-plan-setup/?lesson_plan_setup_id=${mapId}`;

      console.log("deletePlanSetup api url", url);

      const options = {
        method: "DELETE",
        url,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': 'Bearer ' + UserSession.authtoken,
        },
      };

      let response = await axios(options);

      console.log("deletePlanSetup api response", response);

      if (response) return response;
      else return false;
    } catch (ex) {
      return false;
    }
  },
  async postSavePlanActv(data) {
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
      };

      let url = Constants.Application.PARTNER_END_URL;
      // url += "/curriculum-map/";
      url += "/lesson-plan-actv/";

      console.log("postSavePlanActv api url", url);

      const options = {
        method: "POST",
        data: data,
        url,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': 'Bearer ' + UserSession.authtoken,
        },
      };

      let response = await axios(options);

      console.log("postSavePlanActv api response", response);

      if (response) return response;
      else return false;
    } catch (ex) {
      return false;
    }
  },
  async deletePlanActv(data) {
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
      };

      let url = Constants.Application.PARTNER_END_URL;
      // url += "/curriculum-map/";
      url += "/lesson-plan-actv/";
      console.log("deletePlanActv api url", url);

      const options = {
        method: "DELETE",
        data: data,
        url,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': 'Bearer ' + UserSession.authtoken,
        },
      };

      let response = await axios(options);

      console.log("deletePlanActv api response", response);

      if (response) return response;
      else return false;
    } catch (ex) {
      return false;
    }
  },

  async postSavePlanRes(data) {
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
      };

      let url = Constants.Application.PARTNER_END_URL;
      // url += "/curriculum-map/";
      url += "/lesson-plan-resource/";

      console.log("lessonplansetup.js postSavePlanRes: api url ", url);

      const options = {
        method: "POST",
        data: data,
        url,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': 'Bearer ' + UserSession.authtoken,
        },
      };

      let response = await axios(options);

      console.log(
        "lessonplansetup.js postSavePlanRes: api response ",
        response
      );

      if (response) return response;
      else return false;
    } catch (ex) {
      return false;
    }
  },
  async getPlans(lessonId) {
    // console.log("date",stuId,jsDate)
    // we wait
    try {
      let url = Constants.Application.PARTNER_END_URL;
      url += `/lesson-plan-setup/?lesson_id=${lessonId}`;

      console.log(`getPlans by publisher url ${url}`);

      let response = await axios.get(url);
      // console.log(`upcoming response ${JSON.stringify(response)}`)
      // let response = await API.get(`/schooler/teacher-upcoming-classes-service?staffid=${staffId}`);

      // .then(response => {
      // console.log(`upcoming classes response ${JSON.stringify(response)}`)

      // let result = response.data.data
      // let message = response.data.message

      if (response) return response;
      else return false;
    } catch (ex) {
      // console.log(ex);
      // console.log(`exception ${ex.message}`)
      return false;
    }
  },
  async getPlanSetupDetails(planId) {
    // console.log("date",stuId,jsDate)
    // we wait
    try {
      let url = Constants.Application.PARTNER_END_URL;
      url += `/get-lesson-plan-setup/?lesson_plan_setup_id=${planId}`;

      console.log(`getPlanSetupDetails details view url ${url}`);

      let response = await axios.get(url);
      console.log(
        `getPlanSetupDetails details response ${JSON.stringify(response)}`
      );
      // let response = await API.get(`/schooler/teacher-upcoming-classes-service?staffid=${staffId}`);

      // .then(response => {
      // console.log(`upcoming classes response ${JSON.stringify(response)}`)

      // let result = response.data.data
      // let message = response.data.message

      if (response) return response.data;
      else return false;
    } catch (ex) {
      // console.log(ex);
      // console.log(`exception ${ex.message}`)
      return false;
    }
  },
};
