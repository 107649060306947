import { UPLOADCONT_LIST_REQUEST, UPLOADCONT_LIST_SUCCESS, UPLOADCONT_LIST_FAIL } from "../constants/uploadContentConstants";


function UploadContReducer(state = {UploadCont:[]},action){
    switch(action.type){
        case UPLOADCONT_LIST_REQUEST:
            return {loadingUpCont:true}
        case UPLOADCONT_LIST_SUCCESS:
            return {loadingUpCont:false,UploadCont:action.payload};
        case UPLOADCONT_LIST_FAIL:
            return {loadingUpCont:false,error:action.payload}
        default:
            return state;
    }
}

export {UploadContReducer}