/**
 * lesson plan quiz
 * imported from v1-schooler
 */

import Constants from '../resource/Constants';
import API from './http';
import axios from 'axios';

export default {

    async delQuiz(id) {
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            const data = {
                id: id
            }

            let url = Constants.Application.NODEJS_END_URL;
            url += '/quiz/del-quiz'

            console.log("postDeleteQuiz api url", url)

            const options = {
                method: "POST",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("postDeleteQuiz api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            console.log('delete quiz error ' + JSON.stringify(ex));
            return false;

        }

    },


    async getUnpubQuiz(lsnId) {
        try {

            let url = Constants.Application.NODEJS_END_URL;
            url += `/quiz/get-unpub-quiz-full/${lsnId}`;

            console.log(`unpublished quiz url ${url}`);

            let response = await axios.get(url);
            // console.log(`upcoming response ${JSON.stringify(response)}`)
            // let response = await API.get(`/schooler/teacher-upcoming-classes-service?staffid=${staffId}`);

            // .then(response => {
            // console.log(`upcoming classes response ${JSON.stringify(response)}`)

            // let result = response.data.data
            // let message = response.data.message

            if (response)
                return response
            else
                return false
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }
    },

    async getUnpubQuizType(lsnId, type) {
        try {

            let url = Constants.Application.NODEJS_END_URL;
            url += `/quiz/get-unpub-quiz-full-type/${lsnId}/${type}`;

            console.log(`lessonplanquiz.js getUnpubQuizType: unpublished quiz url ${url}`);

            let response = await axios.get(url);
            // console.log(`upcoming response ${JSON.stringify(response)}`)
            // let response = await API.get(`/schooler/teacher-upcoming-classes-service?staffid=${staffId}`);

            // .then(response => {
            // console.log(`upcoming classes response ${JSON.stringify(response)}`)

            // let result = response.data.data
            // let message = response.data.message

            if (response)
                return response
            else
                return false
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }
    },

    async saveQuiz(data) {
        //debugger
        let isinputCheck = false;
        let quizQuestion = true;
        let dateStr;
        let today = new Date();
        dateStr = today.getYear() + '-' + today.getMonth() + '-' + today.getDate();
        console.log('datestr ' + dateStr);
        console.log('submitting multiple option quiz');
        
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.NODEJS_END_URL;
            url += '/quiz/add-quiz'

            console.log("postSaveQuiz api url", url)
            console.log('postSaveQuiz api body', JSON.stringify(data))

            const options = {
                method: "POST",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("postSaveQuiz api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            console.log('delete quiz error ' + JSON.stringify(ex));
            return false;

        }

    },

    async saveQuizQBank(data) {
        //debugger
        let isinputCheck = false;
        let quizQuestion = true;
        let dateStr;
        let today = new Date();
        dateStr = today.getYear() + '-' + today.getMonth() + '-' + today.getDate();
        console.log('datestr ' + dateStr);
        console.log('submitting multiple option quiz');
        
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.QBANK_END_URL;
            url += '/save-question/'

            console.log("lessonplanquiz.js postSaveQuizQBank api url", url)
            console.log('lessonplanquiz.js postSaveQuizQBank api body', JSON.stringify(data))

            const options = {
                method: "POST",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("lessonplanquiz.js postSaveQuiz api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            console.log('lessonplanquiz.js postSaveQuiz delete quiz error ' + JSON.stringify(ex));
            return false;

        }

    },

    async getMedia(lsnId) {
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/lesson-plan-media/?lesson_plan_id=${lsnId}`;

            console.log(`get lessonplan media url ${url}`);

            let response = await axios.get(url);
            // console.log(`upcoming response ${JSON.stringify(response)}`)
            // let response = await API.get(`/schooler/teacher-upcoming-classes-service?staffid=${staffId}`);

            // .then(response => {
            // console.log(`upcoming classes response ${JSON.stringify(response)}`)

            // let result = response.data.data
            // let message = response.data.message

            if (response)
                return response
            else
                return false
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }
    },

    async saveMedia(data) {
        //debugger
        let isinputCheck = false;
        let quizQuestion = true;
        let dateStr;
        let today = new Date();
        dateStr = today.getYear() + '-' + today.getMonth() + '-' + today.getDate();
        
        console.log('submitting media');
        
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += '/lesson-plan-media/'

            console.log("saveMedia api url", url)
            console.log('saveMedia api body', JSON.stringify(data))

            const options = {
                method: "POST",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("saveMedia api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            console.log('saveMedia ' + JSON.stringify(ex));
            return false;

        }

    },

    async delMedia(id) {
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            const data = {
                lesson_plan_media_id: id
            }

            let url = Constants.Application.PARTNER_END_URL;
            url += '/lesson-plan-media/'

            console.log("postDeleteMedia api url", url)

            const options = {
                method: "DELETE",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            console.log('postDeleteMedia options ', options)

            let response = await axios(options);
            console.log("postDeleteQuiz api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            console.log('delete quiz error ' + JSON.stringify(ex));
            return false;

        }

    },


}