import { SINGLEEVENTDELETE_LIST_FAIL, SINGLEEVENTDELETE_LIST_REQUEST, SINGLEEVENTDELETE_LIST_SUCCESS, SINGLEVIDEODELETE_LIST_FAIL, SINGLEVIDEODELETE_LIST_REQUEST, SINGLEVIDEODELETE_LIST_SUCCESS, SUBSCRIBERSDETAILSDELETE_LIST_FAIL, SUBSCRIBERSDETAILSDELETE_LIST_REQUEST, SUBSCRIBERSDETAILSDELETE_LIST_SUCCESS, TRAINTEACHVIDEOSDELETE_LIST_FAIL, TRAINTEACHVIDEOSDELETE_LIST_REQUEST, TRAINTEACHVIDEOSDELETE_LIST_SUCCESS } from "../../constants/Trainer/AllEventsDelete";

function singleEventDeleteReducer(state = { singleEventDel: [] }, action) {

    switch (action.type) {
        case SINGLEEVENTDELETE_LIST_REQUEST:
            return { loadingSingleDel: true }
        case SINGLEEVENTDELETE_LIST_SUCCESS:
            return { loadingSingleDel: false, singleEventDel: action.payload };
        case SINGLEEVENTDELETE_LIST_FAIL:
            return { loadingSingleDel: false, errorSingleEventDel: action.payload }
        default:
            return state;
    }

}

function singleVideoDeleteReducer(state = { singleVideoDel: [] }, action) {

    switch (action.type) {
        case SINGLEVIDEODELETE_LIST_REQUEST:
            return { loadingSingleVidDel: true }
        case SINGLEVIDEODELETE_LIST_SUCCESS:
            return { loadingSingleVidDel: false, singleVideoDel: action.payload };
        case SINGLEVIDEODELETE_LIST_FAIL:
            return { loadingSingleVidDel: false, errorSingleVideoDel: action.payload }
        default:
            return state;
    }

}

function subscribersDeleteReducer(state = { subscrDel: [] }, action) {

    switch (action.type) {
        case SUBSCRIBERSDETAILSDELETE_LIST_REQUEST:
            return { loadingSubscrDel: true }
        case SUBSCRIBERSDETAILSDELETE_LIST_SUCCESS:
            return { loadingSubscrDel: false, subscrDel: action.payload };
        case SUBSCRIBERSDETAILSDELETE_LIST_FAIL:
            return { loadingSubscrDel: false, errorSubscrDel: action.payload }
        default:
            return state;
    }

}


function trainTeachVidDelReducer(state = { trainTeachDel: [] }, action) {

    switch (action.type) {
        case TRAINTEACHVIDEOSDELETE_LIST_REQUEST:
            return { loadingTrainTeachDel: true }
        case TRAINTEACHVIDEOSDELETE_LIST_SUCCESS:
            return { loadingTrainTeachDel: false, trainTeachDel: action.payload };
        case TRAINTEACHVIDEOSDELETE_LIST_FAIL:
            return { loadingTrainTeachDel: false, errorSubscrDel: action.payload }
        default:
            return state;
    }

}

export {
    singleEventDeleteReducer,
    singleVideoDeleteReducer,
    subscribersDeleteReducer,
    trainTeachVidDelReducer
}