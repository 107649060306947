import React, {
  useEffect,
  useState,
  useRef,
  useCount,
  useCallback,
} from "react";
import { Box, Container } from "@material-ui/core";
import JoditEditor from "jodit-react";

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import IconButton2 from "@mui/material/IconButton";
import DoneIcon2 from "@mui/icons-material/Done";
import Drawer2 from "@mui/material/Drawer";

import Card2 from "@mui/material/Card";
import CardActions2 from "@mui/material/CardActions";
import CardContent2 from "@mui/material/CardContent";
import Typography2 from "@mui/material/Typography";
import CardActionArea2 from "@mui/material/CardActionArea";
import Alert2 from "@mui/material/Alert";
import ListItem2 from "@mui/material/ListItem";
import ListItemButton2 from "@mui/material/ListItemButton";
import ListItemIcon2 from "@mui/material/ListItemIcon";
import ListItemText2 from "@mui/material/ListItemText";
import Checkbox2 from "@mui/material/Checkbox";
import ExpandLess2 from "@mui/icons-material/ExpandLess";
import Collapse2 from "@mui/material/Collapse";
import ExpandMore2 from "@mui/icons-material/ExpandMore";
import List2 from "@mui/material/List";
import Button2 from "@mui/material/Button";
import Divider2 from "@mui/material/Divider";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import QuizPlannerDrawer from "./QuizPlannerDrawer";
import MediaPlannerDrawer from "./MediaPlannerDrawer";
import ClearIcon from "@mui/icons-material/Clear";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip2 from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

import Select2 from "@mui/material/Select";
import MenuItem2 from "@mui/material/MenuItem";
import InputLabel2 from "@mui/material/InputLabel";
import NativeSelect2 from "@mui/material/NativeSelect";
import FormControl2 from "@mui/material/FormControl";

import AddCommentIcon2 from "@mui/icons-material/AddComment";

import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Constants from "../../../resource/Constants";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/dist/ProfilePicture.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import Cropper from "react-easy-crop";
import getCroppedImg from "../../../resource/cropImage";
import Modal2 from "@mui/material/Modal";
import Box2 from "@mui/material/Box";
import Snackbar2 from "@mui/material/Snackbar";
import PLAY_ICON from "../../../assets/playvideo.jpg";
import GPTGenerator from "./GPTGenerator";

const BANNER_WIDTH = 800;
const BANNER_HEIGHT = 400;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: BANNER_WIDTH,
  height: BANNER_HEIGHT,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const config = {
  apiKey: Constants.Application.FIREBASE.API_KEY,
  authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
  //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
  storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET,
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}

const STEPASSET = (props) => {
  const { asset } = props;
  return (
    <div
      sx={{
        backgroundColor: "#DCDCDC",
      }}
      style={{
        background: "#DCDCDC",
        verticalAlign: "middle",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        padding: 2,
        borderRadius: 5,
        margin: 0,
        cursor: "pointer",
      }}
    >
      {asset}
    </div>
  );
};

const StepImageList = (props) => {
  const { images, stepIdx, planSetup } = props;

  console.log(
    "CurriculumSetup.js StepImageList images " + JSON.stringify(images)
  );

  return (
    <>
      {images && images.length ? (
        <ImageList sx={{ width: "100%", height: "auto" }} cols={6}>
          {/* {itemData.map((item) => ( */}
          {images.map((item, idx) => {
            return (
              <div
                style={{
                  position: "relative",
                }}
              >
                <ImageListItem key={item.img}>
                  <img
                    //src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    src={`${item.url}`}
                    // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    srcSet={`${item.url}`}
                    alt={""}
                    loading="lazy"
                  />
                </ImageListItem>
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}
                >
                  <DeleteForeverIcon
                    color="red"
                    style={{
                      color: "red",
                    }}
                    onClick={() => props.onDeleteStepImage(stepIdx, idx)}
                  />
                </div>
              </div>
            );
          })}
          {/* ))} */}
        </ImageList>
      ) : (
        <></>
      )}
    </>
  );
};

const StepVideoList = (props) => {
  const { videos, stepIdx, planSetup } = props;

  console.log(
    "CurriculumSetup.js StepImageList videos " + JSON.stringify(videos)
  );

  return (
    <>
      {videos && videos.length ? (
        <ImageList sx={{ width: "100%", height: "auto" }} cols={6}>
          {/* {itemData.map((item) => ( */}
          {videos.map((item, idx) => {
            return (
              <div
                style={{
                  position: "relative",
                }}
              >
                <ImageListItem>
                  <img
                    //src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    // src={`${item.url}`}
                    src={PLAY_ICON}
                    // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    // srcSet={`${item.url}`}
                    srcSet={PLAY_ICON}
                    alt={""}
                    loading="lazy"
                    style={{
                      height: 96,
                      width: 96
                    }}
                  />
                </ImageListItem>
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}
                >
                  <DeleteForeverIcon
                    color="red"
                    style={{
                      color: "red",
                    }}
                    onClick={() => props.onDeleteStepVid(stepIdx, idx)}
                  />
                </div>
              </div>
            );
          })}
          {/* ))} */}
        </ImageList>
      ) : (
        <></>
      )}
    </>
  );
};

const StepDocList = (props) => {
  const { docs, stepIdx, planSetup } = props;

  console.log("ActionList.js StepImageList docs " + JSON.stringify(docs));

  const PDF_ICON =
    "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png";
  const PPT_ICON =
    "https://www.pcworld.com/wp-content/uploads/2021/09/pcw-ppt-primary-100787681-orig-1.jpg?quality=50&strip=all&w=1024";

  return (
    <>
      {docs && docs.length ? (
        <ImageList sx={{ height: "100px" }} cols={6}>
          {/* {itemData.map((item) => ( */}
          {docs.map((item, idx) => {
            let icon = PDF_ICON;
            if (item.url.includes("ppt")) icon = PPT_ICON;
            return (
              <div
                style={{
                  position: "relative",
                }}
              >
                <ImageListItem>
                  <img
                    //src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    // src={`${item.url}`}
                    src={icon}
                    // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    // srcSet={`${item.url}`}
                    srcSet={icon}
                    alt={""}
                    sx={{
                      height: "100px",
                    }}
                    style={{
                      height: "100px",
                      width: "100px",
                      alignSelf: "center",
                    }}
                    loading="lazy"
                    height={100}
                  />
                </ImageListItem>
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}
                >
                  <DeleteForeverIcon
                    color="red"
                    style={{
                      color: "red",
                    }}
                    onClick={() => props.onDeleteStepDoc(stepIdx, idx)}
                  />
                </div>
              </div>
            );
          })}
          {/* ))} */}
        </ImageList>
      ) : (
        <></>
      )}
    </>
  );
};

const StepTipList = (props) => {
  const { idx, step, tips, planSetup, stepIdx } = props;

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const onContentChanged = (e, newContent, idx2) => {
    setContent(newContent);

    console.log("ActionList.js onContentChanged(): newContent " + newContent);
    console.log("ActionList.js onContentChanged(): idx2 " + idx2);

    handleChange2(newContent, idx2);
  };

  // update the aim resource by index
  const handleChange = (e, idx2) => {
    const { name, value } = e.target;
    console.log("ActionList.js StepTipList handleChange idx " + idx2);

    console.log("ActionList.js StepTipList handleChange name " + name);
    console.log("ActionList.js StepTipList handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    let tip = newPlanSetup.action.steps[idx].tips[idx2];
    tip.title = value; // it's all connected by pointers, so value has changed

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const handleChange2 = (value, idx2) => {
    console.log("ActionList.js StepTipList handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    //        let tip = newPlanSetup.action.steps[idx].title = value; // it's all connected by pointers, so value has changed
    let tip = newPlanSetup.action.steps[idx].tips[idx2];
    tip.title = value; // it's all connected by pointers, so value has changed

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  return tips && tips.length ? (
    tips.map((item, idx) => {
      return (
        <>
          <div
            style={{
              margin: 10,
            }}
          ></div>
          {/* TEACHER TIP */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 10
            }}
          >
            <SUBHEADING value="Teacher Tip" />

            <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}>
              <GPTGenerator
                handleUpdateGPTText={(text) => handleChange2(text, idx)}
                prompt={`a single unique teacher tip for explain`}
                planSetup={planSetup}
              />
              <ClearIcon
                color="warning"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.onDeleteStepTip(stepIdx, idx);
                }}
              />
            </div>

          </div>
          <Box
            sx={{
              // width: 500,
              maxWidth: "100%",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/* <TextField fullWidth label="Enter the text"
                                value={item.title}
                                placeholder="Enter the text here" id="fullWidth"
                                variant="outlined" multiline rows={1} maxRows={5}
                                onChange={(e) => handleChange(e, idx)}
                            /> */}
            <JoditEditor
              ref={editor}
              value={item.title}
              tabIndex={1}
              onChange={(newContent2, e) =>
                onContentChanged(e, newContent2, idx)
              }
            />

            {/* <ImageIcon /> */}
          </Box>
        </>
      );
    })
  ) : (
    <></>
  );
};

/* images only */
const UploadBox = (props) => {
  const { planSetup, idx } = props;
  const profilePictureRef = React.createRef();
  const [percent, setPercent] = useState(0);

  async function srcToFile(src, fileName, mimeType) {
    return await fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        console.log("AimList.js UploadBox srcToFile buf " + buf);

        const imageFile = new File([buf], fileName, { type: mimeType });
        handleUpload1(imageFile);
      });
  }

  const handleUpload1 = (imageFile) => {
    //add here the upload logic...
    if (!imageFile) {
      alert("AimList.js UploadBox handleUpload1 Image is invalid!");
      return;
    }

    const storageRef = firebase.storage().ref(`/files/${imageFile.name}`); //.child(imageFile.name);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    // const uploadTask = uploadBytesResumable(storageRef, file);
    const uploadTask = storageRef.put(imageFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => {
        console.log("CurriculumSetup.js BANNERBOX handleUpload: error " + err);
      },
      () => {
        // download url
        storageRef
          .getDownloadURL() //uploadTask.snapshot.ref).then((url) => {
          .then(async (firebaseUrl) => {
            console.log(
              "CurriculumSetup.js BANNERBOX handleUpload: firebaseUrl " +
              firebaseUrl
            );
            // update plansetup current resource image
            let sPlanSetup = JSON.parse(JSON.stringify(planSetup));

            let newImg = {
              url: firebaseUrl,
            };

            sPlanSetup.action.steps[idx].images.push(newImg);
            props.handleUpdatePlanSetup(sPlanSetup);

            props.onClose();
          })
          .catch((e) => {
            console.log(
              "CurriculumSetup.js BANNERBOX handleUpload: exception " + e
            );
          });
      }
    );
  };

  const handleUpload = async () => {
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const imageFile = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();

    if (
      Object.keys(imageData).length === 0 &&
      imageData.constructor === Object
    ) {
      // do not proceed
      return;
    }

    console.log("ActionList.js UploadBox handleUpload PP " + PP);
    console.log(
      "ActionList.js UploadBox handleUpload imageData " +
      JSON.stringify(imageData)
    );
    console.log("ActionList.js UploadBox handleUpload file " + imageFile);
    console.log(
      "ActionList.js UploadBox handleUpload imageAsDataURL " + imageAsDataURL
    );

    let type = imageAsDataURL.substring(
      imageAsDataURL.indexOf(":") + 1,
      imageAsDataURL.indexOf(";")
    ); // => image/png
    console.log("ActionList.js UploadBox handleUpload callback: type " + type);

    let fileName = makeid(10);

    if (type == "image/jpeg") fileName = fileName + ".jpg";
    else if (type == "image/png") fileName = fileName + ".png";
    else if (type == "image/gif") fileName = fileName + ".gif";
    else fileName = fileName + ".jpg";

    console.log(
      "ActionList.js UploadBox handleUpload callback: fileName " + fileName
    );

    // now upload to firebase and set the image url ready
    // convert to file
    // const imageBlob = dataURItoBlob(newDataUri);

    // console.log('CurriculumSetup.js BANNERBOX imageChanged callback: imageBlob ' + JSON.stringify(imageBlob));
    // const imageFile = dataURLtoFile(newDataUri, fileName);
    // fetch(newDataUri)
    //   .then(res => res.blob())
    //   .then(blob => {
    //     const imageFile = new File([blob], fileName)

    //   })

    // const imageFile = new File([imageBlob], imageBlob.name, {
    //   type: imageBlob.type,
    // });

    const imageFile2 = await srcToFile(imageAsDataURL, fileName, type);
  };

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  return (
    <>
      <div
        style={
          {
            // height: 200
          }
        }
      >
        <ProfilePicture ref={profilePictureRef} useHelper={true} debug={true} />

        <div
          style={{
            alignSelf: "right",
          }}
        >
          <Button2 onClick={handleUpload}>Upload</Button2>
        </div>
      </div>
    </>
  );
};

/* images only */
function PickImageDialogO(props) {
  const { planSetup, idx, image } = props;
  const [open, setOpen] = React.useState(false);
  const [crop, setCrop] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
    // props.onAddStepImage(idx, 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d')
  };

  const handleClose = () => {
    setOpen(false);
  };

  const imgUrl = image
    ? image
    : "https://t3.ftcdn.net/jpg/04/34/72/82/360_F_434728286_OWQQvAFoXZLdGHlObozsolNeuSxhpr84.jpg";

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}> */}

      <a
        style={{
          color: Constants.COLOR_PRIMARY,
        }}
        href="javascript:void(0)"
        onClick={() => {
          handleClickOpen();
        }}
      >
        <STEPASSET
          onClick={() => {
            props.onAddStepImage(
              idx,
              "https://images.unsplash.com/photo-1551782450-a2132b4ba21d"
            );
          }}
          asset={
            <Tooltip title="Add Image" placement="top">
              <AddPhotoAlternateIcon color="secondary" />
            </Tooltip>
          }
        />
      </a>

      {/* </Button> */}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload picture</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose the picture, and crop it to your desire
          </DialogContentText>

          <UploadBox
            idx={idx}
            planSetup={planSetup}
            handleUpdatePlanSetup={(newPlanSetup) =>
              props.handleUpdatePlanSetup(newPlanSetup)
            }
            onClose={handleClose}
            onAddStepImage={(idx, url) => props.onAddStepImage(idx, url)}
          />
        </DialogContent>
        {/* <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose}>UPLOAD</Button>
                </DialogActions> */}
      </Dialog>
    </div>
  );
}

function BANNERMODAL(props) {
  const { planData, planSetup, type, item, image, stepIdx } = props;
  const [open, setOpen] = React.useState(props.open);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = React.useState(false);

  const onCropCompleted = (file) => {
    // upload to firebase and set as banner
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose2 = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  console.log("ActionList.js BANNERMODAL open from state " + open);
  console.log("ActionList.js BANNERMODAL type " + type);
  console.log("ActionList.js BANNERMODAL item " + item);
  console.log("ActionList.js BANNERMODAL image " + image);

  return (
    <div>
      {/* <Button2 onClick={handleOpen}>Open modal</Button2> */}
      <Modal2
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box2 sx={modalStyle}>
          {/* <Typography2 id="modal-modal-title" variant="h6" component="h2">
              Text in a modal
            </Typography2>
            <Typography2 id="modal-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography2> */}

          <BANNERBOX2
            type2={type}
            item2={item}
            stepIdx={stepIdx}
            planSetup={planSetup}
            planData={planData}
            image={image}
            onCropCompleted={(file) => onCropCompleted(file)}
            onCompletedUpload={(firebaseUrl) => {
              console.log(
                "ActionList.js BANNERMODAL onCompletedUpload: firebaseUrl " +
                firebaseUrl
              );
              console.log(
                "ActionList.js BANNERMODAL onCompletedUpload: type " + type
              );
              console.log(
                "ActionList.js BANNERMODAL onCompletedUpload: item " + item
              );
              let sPlanSetup = JSON.parse(JSON.stringify(planSetup));
              let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

              // newPlanSetup.application.classwork.workBookURL = firebaseUrl;
              // close modal
              handleClose();
              // update state
              setOpen2(true);

              // update main object
              //                            props.handleUpdatePlanSetup(newPlanSetup)

              props.onCompletedUpload(firebaseUrl);
            }}
            handleUpdatePlanSetup={(newPlanSetup) =>
              props.handleUpdatePlanSetup(newPlanSetup)
            }
          />
        </Box2>
      </Modal2>

      <Snackbar2
        open={open2}
        autoHideDuration={6000}
        onClose={handleClose2}
        message="Image processing complete"
      // action={action}
      />
    </div>
  );
}

const BANNERBOX2 = (props) => {
  const { planData, planSetup, stepIdx, image } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [percent, setPercent] = useState(0);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const { type2, item2 } = props;

  console.log("ActionList.js BANNERBOX2 type2 " + type2);
  console.log("ActionList.js BANNERBOX2 item2 " + item2);
  console.log("ActionList.js BANNERBOX2 image " + image);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(
      "ActionList.js BANNERBOX2 onCropComplete: crop completed " + croppedArea,
      croppedAreaPixels
    );

    setCroppedAreaPixels(croppedAreaPixels);

    // wait for state to be set
    // setTimeout(function() {
    showCroppedImage(croppedAreaPixels);
    // }, 2000)
  }, []);

  async function srcToFile(src, fileName, mimeType) {
    return await fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        console.log("ActionList.js BANNERBOX2 srcToFile buf " + buf);

        const imageFile = new File([buf], fileName, { type: mimeType });
        handleUpload(imageFile);
      });
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleUpload = (imageFile) => {
    if (!imageFile) {
      alert("Please upload an image first!");
    }

    const storageRef = firebase.storage().ref(`/files/${imageFile.name}`); //.child(imageFile.name);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    // const uploadTask = uploadBytesResumable(storageRef, file);
    const uploadTask = storageRef.put(imageFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => {
        console.log("ActionList.js BANNERBOX2 handleUpload: error " + err);
      },
      () => {
        // download url
        storageRef
          .getDownloadURL() //uploadTask.snapshot.ref).then((url) => {
          .then(async (firebaseUrl) => {
            console.log(
              "ActionList.js BANNERBOX2 handleUpload: firebaseUrl " +
              firebaseUrl
            );
            //                        console.log('ApplicationList.js BANNERBOX2 handleUpload: planSetup application classwork ' + JSON.stringify(planSetup.application.classwork.workBookURL));

            // update the plan image with the URL
            //                        const resp3 = await LessonPlanSetupAPI.postUpdatePlanSetup(planData.lessonPlanSetup);

            // setOpen(true)
            // trigger cropping modal

            // update the plan
            props.onCompletedUpload(firebaseUrl);
            // props.handleUpdatePlanSetup(newPlanSetup)
          })
          .catch((e) => {
            console.log(
              "ActionList.js BANNERBOX2 handleUpload: exception " + e
            );
          });
      }
    );
  };

  const showCroppedImage = useCallback(
    async (croppedAreaPixels2) => {
      try {
        // this is the file
        let idx2 = planSetup.action.steps[stepIdx].images.length - 1;
        const imgUrl = planSetup.action.steps[stepIdx].images[idx2].url;
        console.log("ActionList.js showCroppedImage: imgUrl: " + imgUrl);

        const croppedImage = await getCroppedImg(
          imgUrl,
          croppedAreaPixels2,
          rotation
        );
        console.log("ActionList.js showCroppedImage: donee croppedImage: ", {
          croppedImage,
        });
        setCroppedImage(croppedImage);

        let name = makeid(12);
        let type = "";
        let ext = "";

        if (imgUrl.includes(".jpg") || imgUrl.includes(".jpeg")) {
          type = "image/jpeg";
          ext = "jpg";
        } else if (imgUrl.includes("png")) {
          type = "image/png";
          ext = "png";
        }

        if (type) {
          name = `${name}.${ext}`;
        }

        setName(name);
        setType(type);
      } catch (e) {
        console.error(
          "ActionList.js showCroppedImage: EXCEPTION!!!! croppedImage: " + e
        );
      }
    },
    [croppedAreaPixels, rotation]
  );

  const onOkClicked = () => {
    srcToFile(croppedImage, name, type);
  };

  return (
    <>
      <Cropper
        image={image}
        crop={crop}
        zoom={zoom}
        aspect={16 / 9}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />
      <div
        style={{
          position: "absolute",
          bottom: 10,
          right: 10,
          zIndex: 150,
        }}
      >
        <Button2
          mode="contained"
          variant="contained"
          onClick={() => onOkClicked(croppedImage, name, type)}
        >
          UPLOAD
        </Button2>
      </div>
    </>
  );
};

const UploadBox9 = (props) => {
  const profilePictureRef = React.createRef();
  const [open, setOpen] = React.useState(false);
  const [downloadUrls, setDownloadUrls] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [ImageDownloadUrls, setImageDownloadUrls] = useState("");

  const myEmptyIterable = {
    [Symbol.iterator]() {
      return [][Symbol.iterator]();
    },
  };

  let cropIter = Array.from(myEmptyIterable);
  let complCrop = Array.from(myEmptyIterable);

  const [imgSrc2, setImgSrc2] = useState("");
  // const previewCanvasRef = useRef < HTMLCanvasElement > (null)
  const previewCanvasRef = useRef(null);
  // const imgRef = useRef < HTMLImageElement > (null)
  const imgRef = useRef(null);

  // const [crop, setCrop] = useState(myEmptyIterable)
  // const [completedCrop, setCompletedCrop] = useState < PixelCrop > (myEmptyIterable)
  // const [aspect, setAspect] = useState < number | undefined > (16 / 9)
  const [name, setName] = useState("");
  const [type, setType] = useState("");

  const [progress, setProgress] = useState(0);
  const { planSetup } = props;

  const handleUpload = () => {
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();

    //add here the upload logic...
  };

  // Create new plugin instance
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleUploadStart = () => {
    setIsUploading(true);
    setUploadProgress(0);
  };

  const handleProgress = (progress) => {
    setProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
  };

  const handleUploadSingleSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL();

    console.log("download url", downloadURL);

    if (downloadURL) {
      setProgress(0);
    }
    // setFileNames(filename)
    setImageDownloadUrls(downloadURL);
    props.setPicSource(downloadURL);
    setImgSrc2(downloadURL);
    // close origin dialog
    props.handleClose();

    // setUploadProgress(100)
    // setIsUploading(false)
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let imgSrc =
    "https://www.nicepng.com/png/detail/129-1298055_file-upload-comments-icons-for-file-upload.png";

  if (ImageDownloadUrls) {
    imgSrc = ImageDownloadUrls;
  }

  const setPicSource = (src) => {
    setImageDownloadUrls(src);
  };

  let type4 = null;
  let newPicSrc = imgSrc;
  if (newPicSrc) {
    if (
      newPicSrc.includes("jpg") ||
      newPicSrc.includes("jpeg") ||
      newPicSrc.includes("png")
    )
      type4 = "image";
    else if (newPicSrc.includes("pdf") || newPicSrc.includes("doc"))
      type4 = "doc";
  }

  // base on type
  if (type4) {
    switch (type4) {
      case "image":
        newPicSrc = imgSrc;
        break;
      case "doc":
        newPicSrc =
          "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png";
    }
  }

  console.log("ActionList.js PickImageDialog2 setPicSource type " + type4);
  console.log(
    "ActionList.js PickImageDialog2 setPicSource newPicSrc " + newPicSrc
  );

  return (
    <>
      <div
        style={
          {
            // height: 200
          }
        }
      >
        {progress ? <CircularProgress /> : <></>}

        <div
          style={{
            alignSelf: "right",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              // marginLeft: 15,
              // marginRight: 15,
            }}
          >
            <label
              style={{
                fontFamily: "Poppins, Helvetica, sans-serif",
                backgroundColor: "lightsteelblue",
                color: "black",
                paddingLeft: 5,
                paddingRight: 5,
                borderRadius: 4,
                cursor: "pointer",
                // width: '100%',
                textAlign: "center",
                alignSelf: "right",
                float: "right",
              }}
            >
              {/* <Typography style={{ width: 250, padding: 5 }}> */}
              CHOOSE
              {/* </Typography> */}
              <FileUploader
                hidden
                // accept="image/*"
                storageRef={firebase.storage().ref("images")}
                onUploadStart={handleUploadStart}
                onUploadError={handleUploadError}
                onUploadSuccess={handleUploadSingleSuccess}
                onProgress={handleProgress}
              />
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

function PickImageDialog9(props) {
  const { planSetup, type, idx } = props;
  const [open, setOpen] = React.useState(false);
  const [picSrc, setPicSrc] = useState(
    "https://www.nicepng.com/png/detail/129-1298055_file-upload-comments-icons-for-file-upload.png"
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setPicSource = (src) => {
    setPicSrc(src);
  };

  const onUpdatePicSource = (src) => {
    setPicSrc(src);

    // move up as we need to update data to master object
    props.onUpdatePicSource(src);
  };

  let type3 = null;
  let newPicSrc = picSrc;

  if (type == "classwork")
    newPicSrc = planSetup.application.classwork.questionPaperUrl;
  else if (type == "homework")
    newPicSrc = planSetup.application.homework.questionPaperUrl;
  else if (type == "workbook")
    newPicSrc = planSetup.application.classwork.workBookURL;

  if (newPicSrc) {
    if (
      newPicSrc.includes("jpg") ||
      newPicSrc.includes("jpeg") ||
      newPicSrc.includes("png")
    )
      type3 = "image";
    else if (newPicSrc.includes("pdf") || newPicSrc.includes("doc"))
      type3 = "doc";
  }

  // base on type
  if (type3) {
    switch (type3) {
      case "image":
        // newPicSrc = picSrc;
        break;
      case "doc":
        newPicSrc =
          "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png";
    }
  }

  console.log("ApplicationList.js PickImageDialog2 setPicSource type " + type);
  console.log(
    "ApplicationList.js PickImageDialog2 setPicSource newPicSrc " + newPicSrc
  );

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}> */}
      <div
        style={{
          // border: '1px #DCDCDC solid',
          // borderRadius: 10,
          // width: 100,
          // height: 140,
          background: `url('')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        onClick={handleClickOpen}
      >
        {/* <img
                    src={newPicSrc}
                    width={100}
                    height={140}
                /> */}

        <STEPASSET
          onClick={() => {
            props.onAddStepImage(
              idx,
              "https://images.unsplash.com/photo-1551782450-a2132b4ba21d"
            );
          }}
          asset={
            <Tooltip title="Add Image" placement="top">
              <AddPhotoAlternateIcon color="secondary" />
            </Tooltip>
          }
        />
      </div>
      {/* </Button> */}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose the document by clicking upload
          </DialogContentText>

          <UploadBox9
            planSetup={planSetup}
            handleClose={handleClose}
            setPicSource={(src) => onUpdatePicSource(src)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button onClick={handleClose}>UPLOAD</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

/* images only */
function PickImageDialog(props) {
  const { planSetup, idx, image } = props;
  const [open, setOpen] = React.useState(false);
  const [crop, setCrop] = useState(null);
  const [image2, setImage2] = useState(null);
  const [newIdx, setNewIdx] = useState(-1);
  const [modalOpen, setModalOpen] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [percent, setPercent] = useState(0);
  const [name, setName] = useState("");
  const [type, setType] = useState("");

  const onUpdatePicSource = (downloadURL) => {
    // make a copy
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));

    let newPicSrc = downloadURL;
    let type = "image";
    let newIdx2 = -1;

    if (
      newPicSrc.includes("jpg") ||
      newPicSrc.includes("jpeg") ||
      newPicSrc.includes("png")
    ) {
      type = "image";

      // common
      const img = {
        img: downloadURL,
        url: downloadURL,
      };

      newIdx2 = newPlanSetup.action.steps[idx].images.length;
      newPlanSetup.action.steps[idx].images.push(img); // one more added

      console.log(
        "ActionList.js PickImageDialog onUpdatePicSource: newIdx2 " + newIdx2
      );
      // update to the master object
      props.handleUpdatePlanSetup(newPlanSetup);

      // open crop modal if it's an image
      // if (type == 'image')
      setModalOpen(true);
      setImage2(downloadURL);
      setNewIdx(newIdx2);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("ApplicationList.js Workbook Text handleChange name " + name);
    console.log("ApplicationList.js Workbook Text handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.application.classwork.description = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(
      "CurriculumSetup.js BANNERBOX2 onCropComplete: crop completed " +
      croppedArea,
      croppedAreaPixels
    );

    setCroppedAreaPixels(croppedAreaPixels);

    // wait for state to be set
    // setTimeout(function() {
    showCroppedImage(croppedAreaPixels);
    // }, 2000)
  }, []);

  async function srcToFile(src, fileName, mimeType) {
    return await fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        console.log("CurriculumSetup.js BANNERBOX srcToFile buf " + buf);

        const imageFile = new File([buf], fileName, { type: mimeType });
        handleUpload(imageFile);
      });
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleUpload = (imageFile) => {
    if (!imageFile) {
      alert("Please upload an image first!");
    }

    const storageRef = firebase.storage().ref(`/files/${imageFile.name}`); //.child(imageFile.name);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    // const uploadTask = uploadBytesResumable(storageRef, file);
    const uploadTask = storageRef.put(imageFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => {
        console.log("ApplicationList.js BANNERBOX2 handleUpload: error " + err);
      },
      () => {
        // download url
        storageRef
          .getDownloadURL() //uploadTask.snapshot.ref).then((url) => {
          .then(async (firebaseUrl) => {
            console.log(
              "ApplicationList.js BANNERBOX2 handleUpload: firebaseUrl " +
              firebaseUrl
            );

            // update the plan image with the URL

            //                        props.planSetup.lessonPlanSetup.cover_image_url = firebaseUrl;
            //                        const resp3 = await LessonPlanSetupAPI.postUpdatePlanSetup(props.planSetup.lessonPlanSetup);

            // setOpen(true)
            // trigger cropping modal

            // update the plan
            props.onCompletedUpload(firebaseUrl);
          })
          .catch((e) => {
            console.log(
              "ApplicationList.js BANNERBOX2 handleUpload: exception " + e
            );
          });
      }
    );
  };

  const onOkClicked = () => {
    srcToFile(croppedImage, name, type);
  };

  const showCroppedImage = useCallback(
    async (croppedAreaPixels2) => {
      try {
        // this is the file
        const imgUrl = props.planSetup.lessonPlanSetup.cover_image_url;
        const croppedImage = await getCroppedImg(
          imgUrl,
          croppedAreaPixels2,
          rotation
        );
        console.log(
          "CurriculumSetup.js showCroppedImage: donee croppedImage: ",
          { croppedImage }
        );
        setCroppedImage(croppedImage);

        let name = makeid(12);
        let type = "";
        let ext = "";

        if (imgUrl.includes(".jpg") || imgUrl.includes(".jpeg")) {
          type = "image/jpeg";
          ext = "jpg";
        } else if (imgUrl.includes("png")) {
          type = "image/png";
          ext = "png";
        }

        if (type) {
          name = `${name}.${ext}`;
        }

        setName(name);
        setType(type);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, rotation]
  );

  const handleClickOpen = () => {
    setOpen(true);
    // props.onAddStepImage(idx, 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d')
  };

  const handleClose = () => {
    setOpen(false);
  };

  const imgUrl = image
    ? image
    : "https://t3.ftcdn.net/jpg/04/34/72/82/360_F_434728286_OWQQvAFoXZLdGHlObozsolNeuSxhpr84.jpg";

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}> */}

      <PickImageDialog9
        onUpdatePicSource={(src) => onUpdatePicSource(src)}
        type="action_step"
        planSetup={planSetup}
        idx={idx}
      />

      {modalOpen ? (
        <>
          <BANNERMODAL
            planData={planSetup}
            planSetup={planSetup}
            open={modalOpen}
            stepIdx={idx}
            image={image2}
            onModalClose={() => setModalOpen(false)}
            onCompletedUpload={(src) => {
              // onCompletedUpload(src) // pass this to the modal banner
              console.log(
                "ActionList PickImageDialog onCompletedUpload: called with src " +
                src
              );

              // plansetup was already updated inside bannermodal
              // this is main banner image
              let sPlanSetup = JSON.parse(JSON.stringify(planSetup));

              const newImg = {
                img: src,
                url: src,
              };

              console.log(
                "ActionList.js PickImageDialog onCompletedUpload: newIdx " +
                newIdx
              );
              console.log(
                "ActionList.js PickImageDialog onCompletedUpload: newImg " +
                newImg
              );

              // update the url with the cropped source url
              sPlanSetup.action.steps[idx].images[newIdx] = newImg;
              props.handleUpdatePlanSetup(sPlanSetup);

              setModalOpen(false);
            }}
            handleUpdatePlanSetup={(newPlanSetup) =>
              props.handleUpdatePlanSetup(newPlanSetup)
            }
            type="action"
            item="step_image"
          />
        </>
      ) : (
        <></>
      )}

      {/* 

            <a
                style={{
                    color: Constants.COLOR_PRIMARY
                }}
                href="javascript:void(0)"
                onClick={() => {
                    handleClickOpen()
                }}>

                <STEPASSET
                    onClick={() => {
                        props.onAddStepImage(idx, 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d')
                    }}
                    asset={
                        <Tooltip title="Add Image" placement="top">
                            <AddPhotoAlternateIcon color="secondary" />
                        </Tooltip>
                    } />

            </a> */}
      {/* </Button> */}
      {/* <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Upload picture</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Choose the picture, and crop it to your desire
                    </DialogContentText>

                    <UploadBox
                        idx={idx}
                        planSetup={planSetup}
                        handleUpdatePlanSetup={(newPlanSetup) => props.handleUpdatePlanSetup(newPlanSetup)}
                        onClose={handleClose}
                        onAddStepImage={(idx, url) => props.onAddStepImage(idx, url)}
                    />


                </DialogContent>
                
            </Dialog> */}
    </div>
  );
}

/* to upload all kinds of media */
function PickImageDialog2(props) {
  let type2 = props.type;
  const [open, setOpen] = React.useState(false);
  const [picSrc, setPicSrc] = useState(
    "https://www.nicepng.com/png/detail/129-1298055_file-upload-comments-icons-for-file-upload.png"
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setPicSource = (src) => {
    setPicSrc(src);
  };

  const onUpdatePicSource = (src) => {
    setPicSrc(src);

    // move up as we need to update data to master object
    props.onUpdatePicSource(src);
  };

  let type = null;
  let newPicSrc = picSrc;
  if (newPicSrc) {
    if (
      newPicSrc.includes("mp4") ||
      newPicSrc.includes("ogg") ||
      newPicSrc.includes("mov")
    )
      type = "video";
  }

  // base on type
  if (type) {
    switch (type) {
      case "video":
        newPicSrc =
          "https://www.pngkit.com/png/detail/43-438895_play-button-overlay-png-white-video-play-button.png";
        break;
    }
  }

  console.log("ActionList.js PickImageDialog2 setPicSource type " + type);
  console.log(
    "ActionList.js PickImageDialog2 setPicSource newPicSrc " + newPicSrc
  );

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}> */}
      {type2 == "video" ? (
        <STEPASSET
          asset={
            <Tooltip title="Add Video" placement="top">
              <VideoCallIcon color="secondary" onClick={handleClickOpen} />
            </Tooltip>
          }
        />
      ) : (
        <STEPASSET
          asset={
            <Tooltip title="Add Document" placement="top">
              <NoteAddIcon color="secondary" />
            </Tooltip>
          }
          onClick={handleClickOpen}
        />
      )}
      {/* <STEPASSET asset={<VideoCallIcon color="secondary"
                onClick={handleClickOpen}
            />
             */}
      {/*
            <div
                style={{
                    border: '1px #DCDCDC solid',
                    borderRadius: 10,
                    width: 100,
                    height: 140,
                    background: `url('')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
                onClick={handleClickOpen}
            >
                <img
                    src={newPicSrc}
                    width={100}
                    height={140}
                />
            </div>
            */}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose the document by clicking upload
          </DialogContentText>

          <UploadBox2 setPicSource={(src) => onUpdatePicSource(src)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button onClick={handleClose}>UPLOAD</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const UploadBox2 = (props) => {
  const profilePictureRef = React.createRef();
  const [open, setOpen] = React.useState(false);
  const [downloadUrls, setDownloadUrls] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [ImageDownloadUrls, setImageDownloadUrls] = useState("");
  const [progress, setProgress] = useState(0);

  const { planSetup } = props;

  const handleUpload = () => {
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();

    //add here the upload logic...
  };

  // Create new plugin instance
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleUploadStart = () => {
    setIsUploading(true);
    setUploadProgress(0);
  };

  const handleProgress = (progress) => {
    setProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
  };

  const handleUploadSingleSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL();

    console.log("download url", downloadURL);

    if (downloadURL) {
      setProgress(0);
    }
    // setFileNames(filename)
    setImageDownloadUrls(downloadURL);
    props.setPicSource(downloadURL);
    // setUploadProgress(100)
    // setIsUploading(false)
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let imgSrc =
    "https://www.nicepng.com/png/detail/129-1298055_file-upload-comments-icons-for-file-upload.png";

  if (ImageDownloadUrls) {
    imgSrc = ImageDownloadUrls;
  }

  const setPicSource = (src) => {
    setImageDownloadUrls(src);
  };

  let type = null;
  let newPicSrc = imgSrc;
  if (newPicSrc) {
    if (
      newPicSrc.includes("mp4") ||
      newPicSrc.includes("ogg") ||
      newPicSrc.includes("mov")
    )
      type = "video";
    else if (newPicSrc.includes("pdf") || newPicSrc.includes("doc"))
      type = "doc";
  }

  // base on type
  if (type) {
    switch (type) {
      case "video":
        newPicSrc =
          "https://www.pngkit.com/png/detail/43-438895_play-button-overlay-png-white-video-play-button.png"; // or show video player here
        break;
      case "doc":
        newPicSrc =
          "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png";
    }
  }

  console.log("ActionList.js PickImageDialog2 setPicSource type " + type);
  console.log(
    "ActionList.js PickImageDialog2 setPicSource newPicSrc " + newPicSrc
  );

  return (
    <>
      <div
        style={
          {
            // height: 200
          }
        }
      >
        {progress ? (
          <CircularProgress />
        ) : (
          <img
            src={newPicSrc}
            style={{
              // width: '100%',
              height: 200,
            }}
          />
        )}

        <div
          style={{
            alignSelf: "right",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              // marginLeft: 15,
              // marginRight: 15,
            }}
          >
            <label
              style={{
                fontFamily: "Poppins, Helvetica, sans-serif",
                backgroundColor: "lightsteelblue",
                color: "black",
                paddingLeft: 5,
                paddingRight: 5,
                borderRadius: 4,
                cursor: "pointer",
                // width: '100%',
                textAlign: "center",
                alignSelf: "right",
                float: "right",
              }}
            >
              {/* <Typography style={{ width: 250, padding: 5 }}> */}
              CHOOSE
              {/* </Typography> */}
              <FileUploader
                hidden
                // accept="image/*"
                storageRef={firebase.storage().ref("images")}
                onUploadStart={handleUploadStart}
                onUploadError={handleUploadError}
                onUploadSuccess={handleUploadSingleSuccess}
                onProgress={handleProgress}
              />
            </label>

            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                {progress ? <CircularProgress /> : <></>}
              </div> */}
            {/* 
            {ImageDownloadUrls ?
              <div style={{ marginLeft: 5, marginTop: 10 }} onClick={handleClickOpen}>
                <ImageOutlinedIcon style={{ fontsize: 80 }} />
              </div>
              :
              <></>
            } */}
          </div>
        </div>
      </div>
    </>
  );
};

/* to upload all kinds of media */
function PickImageDialog3(props) {
  let type2 = props.type;
  const [open, setOpen] = React.useState(false);
  const [picSrc, setPicSrc] = useState(
    "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setPicSource = (src) => {
    setPicSrc(src);
  };

  const onUpdateDocPicSource = (src) => {
    setPicSrc(src);

    // move up as we need to update data to master object
    props.onUpdateDocPicSource(src);
  };

  let type = null;
  let newPicSrc = picSrc;
  if (newPicSrc) {
    if (newPicSrc.includes("pdf") || newPicSrc.includes("doc")) type = "doc";
  }

  // base on type
  if (type) {
    switch (type) {
      case "doc":
        newPicSrc =
          "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png";
        break;
      case "ppt":
        newPicSrc =
          "https://www.pcworld.com/wp-content/uploads/2021/09/pcw-ppt-primary-100787681-orig-1.jpg?quality=50&strip=all&w=1024";
        break;
    }
  }

  console.log("ActionList.js PickImageDialog2 setPicSource type " + type);
  console.log(
    "ActionList.js PickImageDialog2 setPicSource newPicSrc " + newPicSrc
  );

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}> */}
      {type2 == "video" ? (
        <STEPASSET
          asset={
            <Tooltip title="Add Video" placement="top">
              <VideoCallIcon color="secondary" onClick={handleClickOpen} />
            </Tooltip>
          }
        />
      ) : (
        <STEPASSET
          asset={
            <Tooltip title="Add Document" placement="top">
              <NoteAddIcon color="secondary" onClick={handleClickOpen} />
            </Tooltip>
          }
        />
      )}
      {/* <STEPASSET asset={<VideoCallIcon color="secondary"
                onClick={handleClickOpen}
            />
             */}
      {/*
            <div
                style={{
                    border: '1px #DCDCDC solid',
                    borderRadius: 10,
                    width: 100,
                    height: 140,
                    background: `url('')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
                onClick={handleClickOpen}
            >
                <img
                    src={newPicSrc}
                    width={100}
                    height={140}
                />
            </div>
            */}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose the document by clicking upload
          </DialogContentText>

          <UploadBox3 setDocPicSource={(src) => onUpdateDocPicSource(src)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button onClick={handleClose}>UPLOAD</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const UploadBox3 = (props) => {
  const profilePictureRef = React.createRef();
  const [open, setOpen] = React.useState(false);
  const [downloadUrls, setDownloadUrls] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [ImageDownloadUrls, setImageDownloadUrls] = useState("");
  const [progress, setProgress] = useState(0);

  const { planSetup } = props;

  const handleUpload = () => {
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();

    //add here the upload logic...
  };

  // Create new plugin instance
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleUploadStart = () => {
    setIsUploading(true);
    setUploadProgress(0);
  };

  const handleProgress = (progress) => {
    setProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
  };

  const handleUploadSingleSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL();

    console.log("download url", downloadURL);

    if (downloadURL) {
      setProgress(0);
    }
    // setFileNames(filename)
    setImageDownloadUrls(downloadURL);
    props.setDocPicSource(downloadURL);
    // setUploadProgress(100)
    // setIsUploading(false)
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let imgSrc =
    "https://www.nicepng.com/png/detail/129-1298055_file-upload-comments-icons-for-file-upload.png";

  if (ImageDownloadUrls) {
    imgSrc = ImageDownloadUrls;
  }

  const setPicSource = (src) => {
    setImageDownloadUrls(src);
  };

  let type = null;
  let newPicSrc = imgSrc;
  if (newPicSrc) {
    if (
      newPicSrc.includes("jpg") ||
      newPicSrc.includes("jpeg") ||
      newPicSrc.includes("png")
    )
      type = "image";
    else if (newPicSrc.includes("pdf") || newPicSrc.includes("doc"))
      type = "doc";
    else if (newPicSrc.includes("ppt")) {
      type = "ppt";
    }
  }

  // base on type
  if (type) {
    switch (type) {
      case "image":
        newPicSrc = imgSrc;
        break;
      case "doc":
        newPicSrc =
          "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png";
        break;
      case "ppt":
        newPicSrc =
          "https://www.pcworld.com/wp-content/uploads/2021/09/pcw-ppt-primary-100787681-orig-1.jpg?quality=50&strip=all&w=1024";
    }
  }

  console.log("ActionList.js PickImageDialog2 setPicSource type " + type);
  console.log(
    "ActionList.js PickImageDialog2 setPicSource newPicSrc " + newPicSrc
  );

  return (
    <>
      <div
        style={
          {
            // height: 200
          }
        }
      >
        {progress ? (
          <CircularProgress />
        ) : (
          <img
            src={newPicSrc}
            style={{
              // width: '100%',
              height: 400,
            }}
          />
        )}

        <div
          style={{
            alignSelf: "right",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              // marginLeft: 15,
              // marginRight: 15,
            }}
          >
            <label
              style={{
                fontFamily: "Poppins, Helvetica, sans-serif",
                backgroundColor: "lightsteelblue",
                color: "black",
                paddingLeft: 5,
                paddingRight: 5,
                borderRadius: 4,
                cursor: "pointer",
                // width: '100%',
                textAlign: "center",
                alignSelf: "right",
                float: "right",
              }}
            >
              {/* <Typography style={{ width: 250, padding: 5 }}> */}
              CHOOSE
              {/* </Typography> */}
              <FileUploader
                hidden
                // accept="image/*"
                storageRef={firebase.storage().ref("images")}
                onUploadStart={handleUploadStart}
                onUploadError={handleUploadError}
                onUploadSuccess={handleUploadSingleSuccess}
                onProgress={handleProgress}
              />
            </label>

            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                {progress ? <CircularProgress /> : <></>}
              </div> */}
            {/* 
            {ImageDownloadUrls ?
              <div style={{ marginLeft: 5, marginTop: 10 }} onClick={handleClickOpen}>
                <ImageOutlinedIcon style={{ fontsize: 80 }} />
              </div>
              :
              <></>
            } */}
          </div>
        </div>
      </div>
    </>
  );
};

function StepField2(props) {
  const { num, idx, planSetup } = props;
  const [showIt, setShowIt] = useState(false);
  const editor = useRef(null);
  const [content, setContent] = useState("");

  const toggleShowIt = () => {
    let newShowIt = !showIt;
    setShowIt(newShowIt);
  };

  const currentStep = planSetup.action.steps[idx];

  if (!currentStep) {
    return <></>;
  }

  const onContentChanged = (newContent) => {
    console.log(
      "ActionList.js StepField2 onContentChanged: newContent " + newContent
    );
    setContent(newContent);

    onUpdateTitleText(newContent);
  };

  // update the aim resource by index
  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("CurriculumSetup.js StepField2 handleChange name " + name);
    console.log("CurriculumSetup.js StepField2 handleChange value " + value);

    onUpdateTitleText(value);
  };

  const onUpdateTitleText = (value) => {
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    let step = newPlanSetup.action.steps[idx];
    step.title = value;
    step.description = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const onUpdatePicSource = (src) => {
    // save as video or pdf based on extension
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    let type = "";
    if (src) {
      if (src.includes("mp4") || src.includes("ogg") || src.includes("mov"))
        type = "video";
    }

    if (type == "video") {
      // add new video

      let vid = {
        url: src,
      };

      newPlanSetup.action.steps[idx].videos.push(vid);
    }

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const onUpdateDocPicSource = (src) => {
    // save as video or pdf based on extension
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    let type = "";
    if (src) {
      if (src.includes("pdf") || src.includes("doc")) type = "doc";
      else if (src.includes("ppt")) {
        type = "ppt";
      }
    }

    if (type == "doc" || type == "ppt") {
      // add new doc

      let doc = {
        url: src,
      };

      newPlanSetup.action.steps[idx].docs.push(doc);
    }

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const step = planSetup.action.steps[idx];

  return (
    <div sx={{ minWidth: "100%" }}>
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography2 variant="h6">Step {num}</Typography2>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}>
            <GPTGenerator
              handleUpdateGPTText={(text) => onUpdateTitleText(text)}
              prompt={`a single unique step for explain`}
              planSetup={planSetup}
            />

            <ClearIcon
              color="warning"
              style={{
                cursor: "pointer",
              }}
              onClick={() => props.onDelActionStepClicked(idx)}
            />
          </div>

        </div>

        <SUBHEADING value="Enter title" />


        {/* {showIt ? */}
        <Box
          sx={{
            // width: 500,
            maxWidth: "100%",
          }}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <JoditEditor
            ref={editor}
            value={step.title}
            tabIndex={1}
            onChange={onContentChanged}
          />

          {/* <TextField fullWidth label="Title"
                        value={step.title}
                        placeholder="Type here" id="fullWidth"
                        variant="outlined" multiline rows={1} maxRows={5}
                        onChange={handleChange}
                    /> */}

          {/* <ImageIcon /> */}
        </Box>
        {/* :
              <></>
            } */}




        {/* ICONS */}
        <div
          style={{
            marginTop: 10,
            display: "flex",
            flexDirection: "row",
            // textAlign: 'right'
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <a
            style={{
              color: Constants.COLOR_PRIMARY,
            }}
            href="javascript:void(0)"
            onClick={() =>
              props.onAddStepTip(idx, "New Title", "New Description")
            }
          >
            <STEPASSET
              onClick={() =>
                props.onAddStepTip(idx, "New Title", "New Description")
              }
              asset={
                <Tooltip title="Add Teacher Tip" placement="top">
                  <AddCommentIcon2 color="secondary" />
                </Tooltip>
              }
            />
          </a>

          {/* 
                    <Chip2
                        icon={<AddCommentIcon2 style={{ color: 'purple' }} />}
                        label="Teacher Tip" variant="filled"
                        sx={{
                            color: 'purple',
                            fontWeight: 'bold'
                        }}
                        onClick={() => props.onAddStepTip(idx, 'New Title', 'New Description')}
                    /> */}

          <div style={{ marginRight: 10 }}></div>

          <PickImageDialog
            idx={idx}
            planSetup={planSetup}
            // image={resource.url}
            handleUpdatePlanSetup={(newPlanSetup) =>
              props.handleUpdatePlanSetup(newPlanSetup)
            }
            onClick={() => {
              props.onAddStepImage(
                idx,
                "https://images.unsplash.com/photo-1551782450-a2132b4ba21d"
              );
            }}
            asset={<AddPhotoAlternateIcon color="secondary" />}
            onAddStepImage={(idx, url) => props.onAddStepImage(idx, url)}
          />

          <div style={{ marginRight: 10 }}></div>

          <PickImageDialog2
            idx={idx}
            planSetup={planSetup}
            // image={resource.url}
            handleUpdatePlanSetup={(newPlanSetup) =>
              props.handleUpdatePlanSetup(newPlanSetup)
            }
            // onClick={() => {
            //     props.onAddStepImage(idx, 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d')
            // }}
            onUpdatePicSource={(src) => onUpdatePicSource(src)}
            onAddStepVid={(idx, url) => props.onAddStepVid(idx, url)}
            type="video"
          />

          {/* <STEPASSET asset={<VideoCallIcon color="secondary" />} /> */}

          <div style={{ marginRight: 10 }}></div>
          <Tooltip title="Add Document" placement="top">
            <PickImageDialog3
              idx={idx}
              planSetup={planSetup}
              // image={resource.url}
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
              // onClick={() => {
              //     props.onAddStepImage(idx, 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d')
              // }}
              onUpdateDocPicSource={(src) => onUpdateDocPicSource(src)}
              onAddStepVid={(idx, url) => props.onAddStepVid(idx, url)}
              type="document"
            />
            {/* <STEPASSET asset={<NoteAddIcon color="secondary" />} /> */}
          </Tooltip>
          <div style={{ marginRight: 10 }}></div>
          {/* <Tooltip title="Delete item" placement="top">
              <DeleteForeverIcon color="red" />
            </Tooltip> */}
        </div>

        <div
          style={{
            margin: 20,
          }}
        ></div>

        {/* IMAGES */}

        <StepImageList
          images={currentStep.images}
          stepIdx={idx}
          planSetup={planSetup}
          onDeleteStepImage={(stepIdx, idx) =>
            props.onDeleteStepImage(stepIdx, idx)
          }
          step={step}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />

        <div
          style={{
            margin: 20,
          }}
        ></div>

        {/* VIDEOS */}

        <div
          style={{
            margin: 20,
          }}
        ></div>

        <StepVideoList
          videos={currentStep.videos}
          stepIdx={idx}
          planSetup={planSetup}
          onDeleteStepVid={(stepIdx, idx) =>
            props.onDeleteStepVid(stepIdx, idx)
          }
          step={step}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />

        {/* DOCS */}

        <div
          style={{
            margin: 20,
          }}
        ></div>

        <StepDocList
          docs={currentStep.docs}
          stepIdx={idx}
          planSetup={planSetup}
          onDeleteStepDoc={(stepIdx, idx) =>
            props.onDeleteStepDoc(stepIdx, idx)
          }
          step={step}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />

        <div
          style={{
            margin: 20,
          }}
        ></div>

        {/* TIPS */}
        <StepTipList
          tips={currentStep.tips}
          idx={idx}
          stepIdx={idx}
          planSetup={planSetup}
          onDeleteStepTip={(stepIdx, idx) =>
            props.onDeleteStepTip(stepIdx, idx)
          }
          step={step}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />

      </>
    </div>
  );
}

const HEADING = (props) => {
  const { value } = props;

  return (
    <>
      <Typography variant="h4">{value}</Typography>
    </>
  );
};

const HEADING2 = (props) => {
  const { value, subValue } = props;

  return (
    <>
      <div
        className="d-flex flex-row justify-content-start align-items-center"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">{value}</Typography>

        <div
          style={{
            marginRight: 15,
          }}
        ></div>

        <Typography variant="body2">{subValue}</Typography>
      </div>
    </>
  );
};

const SUBHEADING = (props) => {
  const { value } = props;

  return (
    <>
      <Typography variant="subtitle1">{value}</Typography>
    </>
  );
};

const STEPLIST = (props) => {
  const { planSetup } = props;

  console.log(
    "CurriculumSetup.js STEPLIST planSetup " + JSON.stringify(planSetup)
  );

  return (
    <>
      {planSetup && planSetup.action && planSetup.action.hasSteps ? (
        <>
          <div
            style={{
              margin: 20,
            }}
          ></div>

          {/* 
                    <div
                        style={{
                            marginBottom: 5
                        }}></div>
                    <BriefField2
                        name="In the Explain phase, the teacher provides the necessary information to help learners understand the concept."
                        planSetup={planSetup}
                        handleUpdatePlanSetup={(newPlanSetup) => props.handleUpdatePlanSetup(newPlanSetup)}
                    /> */}

          {/* iterate steps */}
          {planSetup.action.steps.map((step, idx) => {
            return (
              <>
                <StepField2
                  num={idx + 1}
                  idx={idx}
                  planSetup={planSetup}
                  onDelActionStepClicked={(stepIdx) =>
                    props.onDelActionStepClicked(stepIdx)
                  }
                  onDeleteStepImage={(stepIdx, idx) =>
                    props.onDeleteStepImage(stepIdx, idx)
                  }
                  onAddStepImage={(stepIdx, url) =>
                    props.onAddStepImage(stepIdx, url)
                  }
                  onDeleteStepVid={(stepIdx, idx) =>
                    props.onDeleteStepVid(stepIdx, idx)
                  }
                  onAddStepVid={(stepIdx, url) =>
                    props.onAddStepVid(stepIdx, url)
                  }
                  onDeleteStepDoc={(stepIdx, idx) =>
                    props.onDeleteStepDoc(stepIdx, idx)
                  }
                  onAddStepDoc={(stepIdx, url) =>
                    props.onAddStepDoc(stepIdx, url)
                  }
                  onAddStepTip={(stepIdx, title, desc) =>
                    props.onAddStepTip(stepIdx, title, desc)
                  }
                  onDeleteStepTip={(stepIdx, idx) =>
                    props.onDeleteStepTip(stepIdx, idx)
                  }
                  handleUpdatePlanSetup={(newPlanSetup) =>
                    props.handleUpdatePlanSetup(newPlanSetup)
                  }
                />

                <div
                  style={{
                    margin: 20,
                  }}
                ></div>
              </>
            );
          })}
        </>
      ) : (
        <></>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            marginTop: 10,
            textAlign: "right",
          }}
        >
          <Button2
            variant="outlined"
            endIcon={<AddCircleOutlineIcon />}
            style={
              {
                // backgroundColor: Constants.Application.COLOR_PRIMARY
              }
            }
            onClick={() => props.onAddActionStepClicked()}
            color="primary"
            sx={{
              color: "purple",
              backgroundColor: "white",
              borderColor: "purple",
              "&:hover": {
                borderColor: "purple",
                backgroundColor: "purple",
                color: "white",
              },
            }}
          >
            Add Step
          </Button2>
        </div>
      </div>
    </>
  );
};

function BriefField(props) {
  const { name, planSetup } = props;

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const onContentChanged = (newContent) => {
    setContent(newContent);

    handleChange2(newContent);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("ActionList.js BriefField handleChange name " + name);
    console.log("ActionList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.action.summary = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const handleChange2 = (value) => {
    console.log("ActionList.js BriefField handleChange name " + name);
    console.log("ActionList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.action.summary = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const val = planSetup.action.summary;

  return (
    <div sx={{ minWidth: "100%" }}>
      <>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SUBHEADING value={`Teacher Summary for ${name}`} />

            <GPTGenerator
              handleUpdateGPTText={(text) => handleChange2(text)}
              prompt={`one sentence teacher summary for ${name}`}
              planSetup={planSetup}
            />
          </div>

          <Box
            sx={{
              // width: 500,
              maxWidth: "100%",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              // defaultValue={val}
              value={val}
              fullWidth
              label="Enter summary"
              placeholder="Enter the text here"
              id="fullWidth"
              variant="outlined"
              onChange={handleChange}
              inputProps={{
                maxLength: 500,
              }}
            />

            {/* 
                        <JoditEditor
                            ref={editor}
                            value={val}
                            tabIndex={1}
                            onChange={onContentChanged}
                        /> */}
          </Box>
        </div>
      </>
    </div>
  );
}

/* engage */
function BriefField2(props) {
  const { name, planSetup } = props;

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const onContentChanged = (newContent) => {
    setContent(newContent);

    handleChange2(newContent);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("ActionList.js BriefField handleChange name " + name);
    console.log("ActionList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.action.summary = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const handleChange2 = (value) => {
    console.log("ActionList.js BriefField handleChange name " + name);
    console.log("ActionList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.action.engage = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const val = planSetup.action.engage;

  return (
    <div sx={{ minWidth: "100%" }}>
      <>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SUBHEADING value={name} />

            <GPTGenerator
              handleUpdateGPTText={(text) => handleChange2(text)}
              prompt={`engage`}
              planSetup={planSetup}
            />

          </div>

          <Box
            sx={{
              // width: 500,
              maxWidth: "100%",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/* <TextField
                            defaultValue={val}
                            fullWidth label="Enter summary"
                            placeholder="Enter the text here" id="fullWidth"
                            variant="standard"
                            onChange={handleChange}
                            inputProps={{
                                maxLength: 500,
                            }}
                        />  */}

            <JoditEditor
              ref={editor}
              value={val}
              tabIndex={1}
              onChange={onContentChanged}
            />
          </Box>
        </div>
      </>
    </div>
  );
}

/* explore */
function BriefField3(props) {
  const { name, planSetup } = props;

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const onContentChanged = (newContent) => {
    setContent(newContent);

    handleChange2(newContent);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("ActionList.js BriefField handleChange name " + name);
    console.log("ActionList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.action.explore = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const handleChange2 = (value) => {
    console.log("ActionList.js BriefField handleChange name " + name);
    console.log("ActionList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.action.explore = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const val = planSetup.action.explore;

  return (
    <div sx={{ minWidth: "100%" }}>
      <>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SUBHEADING value={`Explore`} />

            <GPTGenerator
              handleUpdateGPTText={(text) => handleChange2(text)}
              prompt={`prerequisite with many sentences`}
              planSetup={planSetup}
            />

          </div>

          <Box
            sx={{
              // width: 500,
              maxWidth: "100%",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/* <TextField
                            defaultValue={val}
                            fullWidth label="Enter summary"
                            placeholder="Enter the text here" id="fullWidth"
                            variant="standard"
                            onChange={handleChange}
                            inputProps={{
                                maxLength: 500,
                            }}
                        />  */}

            <JoditEditor
              ref={editor}
              value={val}
              tabIndex={1}
              onChange={onContentChanged}
            />
          </Box>
        </div>
      </>
    </div>
  );
}

/* explain */
function BriefField4(props) {
  const { name, planSetup } = props;

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const onContentChanged = (newContent) => {
    setContent(newContent);

    handleChange2(newContent);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("ActionList.js BriefField handleChange name " + name);
    console.log("ActionList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.action.explain = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const handleChange2 = (value) => {
    console.log("ActionList.js BriefField handleChange name " + name);
    console.log("ActionList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.action.explain = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const val = planSetup.action.explain;

  return (
    <Card2 sx={{ minWidth: "100%" }}>
      <CardContent2>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SUBHEADING value={`Explain`} />
          </div>

          <Box
            sx={{
              // width: 500,
              maxWidth: "100%",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/* <TextField
                            defaultValue={val}
                            fullWidth label="Enter summary"
                            placeholder="Enter the text here" id="fullWidth"
                            variant="standard"
                            onChange={handleChange}
                            inputProps={{
                                maxLength: 500,
                            }}
                        />  */}

            <JoditEditor
              ref={editor}
              value={val}
              tabIndex={1}
              onChange={onContentChanged}
            />
          </Box>
        </div>
      </CardContent2>
    </Card2>
  );
}

function HeadingField(props) {
  const { name, zIndex } = props;

  return (
    <Card2 sx={{ minWidth: "100%" }} style={{}}>
      <CardContent2>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SUBHEADING value={`Description for ${name}`} />
          </div>

          <Box
            sx={{
              // width: 500,
              maxWidth: "100%",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              label="Enter description"
              placeholder="Enter the text here"
              id="fullWidth"
              multiline
              rows={4}
              variant="outlined"
            />
          </Box>
        </div>
      </CardContent2>
    </Card2>
  );
}

const ACTIONLIST = (props) => {
  const { planSetup } = props;

  return (
    <Card2 sx={{ minWidth: "100%" }}>
      <CardContent2>
        {/* steps */}
        <BriefField
          name="Learning Path"
          planSetup={planSetup}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />

        <div
          style={{
            margin: 20,
          }}
        ></div>

        <HEADING2
          value="Engage"
          subValue="Engage phase activates prior knowledge and generates interest in the topic."
        />

        <div
          style={{
            marginBottom: 5,
          }}
        ></div>
        <BriefField2
          name=""
          planSetup={planSetup}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />

        <div
          style={{
            margin: 20,
          }}
        ></div>

        <HEADING2
          value="Explore"
          subValue="In the Explore phase, the teacher encourages students to investigate and explore the concept in a hands-on and interactive way."
        />

        <div
          style={{
            marginBottom: 5,
          }}
        ></div>
        <BriefField3
          name=""
          planSetup={planSetup}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />

        <div
          style={{
            margin: 20,
          }}
        ></div>

        <HEADING2
          value="Explain"
          subValue="In the Explain phase, the teacher provides the necessary information to help learners understand the concept."
        />

        <div
          style={{
            margin: 10,
          }}
        ></div>

        <STEPLIST
          planSetup={planSetup}
          onAddActionStepClicked={() => props.onAddActionStepClicked()}
          onDelActionStepClicked={(stepIdx) =>
            props.onDelActionStepClicked(stepIdx)
          }
          onDeleteStepImage={(stepIdx, idx) =>
            props.onDeleteStepImage(stepIdx, idx)
          }
          onAddStepImage={(stepIdx, url) => props.onAddStepImage(stepIdx, url)}
          onDeleteStepVid={(stepIdx, idx) =>
            props.onDeleteStepVid(stepIdx, idx)
          }
          onAddStepVid={(stepIdx, url) => props.onAddStepVid(stepIdx, url)}
          onDeleteStepDoc={(stepIdx, idx) =>
            props.onDeleteStepDoc(stepIdx, idx)
          }
          onAddStepDoc={(stepIdx, url) => props.onAddStepDoc(stepIdx, url)}
          onAddStepTip={(stepIdx, title, desc) =>
            props.onAddStepTip(stepIdx, title, desc)
          }
          onDeleteStepTip={(stepIdx, idx) =>
            props.onDeleteStepTip(stepIdx, idx)
          }
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />

        <div
          style={{
            margin: 20,
          }}
        ></div>
      </CardContent2>
    </Card2>
  );
};

export default ACTIONLIST;
