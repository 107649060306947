import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AddAdmin from './AddAdmin';
import DeleteIcon from '@material-ui/icons/Delete';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import CommentIcon from '@material-ui/icons/Comment';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { listContRolesGet, listContRolesPut, listContSendMail, listPubRolesGet } from '../../../../redux/actions/AdminControlsAction';
import { listContTypeGet } from '../../../../redux/actions/contentTypeAction';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Cookie from 'js-cookie';
import PublisherAPI from '../../../../http/publisher';
import { Alert, AlertTitle } from '@material-ui/lab';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';

import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useHistory } from 'react-router';
import Input from '@material-ui/core/Input';

import Chip from '@material-ui/core/Chip';
import { updateAdminRoles } from '../../../../redux/actions/adminRolesAction';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

import Tour from 'reactour'

const steps = [
    
    {
        selector: '[data-tut="first-step"]',
        content: 'Enter the email of the admin you want to invite..',
    },
    {
        selector: '[data-tut="second-step"]',
        content: 'The list shows the admins in the application and actions to perform on them like role update or view their activity',
    },
    
    // ...
];

function Alert2(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


//dropdown styles
const useStyles3 = makeStyles((theme) => ({
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const useStyles4 = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));


//send invite field styles
const useStyles2 = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: "auto",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        width: 250,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));



let selectRef = null


const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles5 = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

function SimpleDialog(props) {
    const classes = useStyles5();
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Remove member</DialogTitle>
            <DialogContent dividers>
                <Typography
                    variant="body1">
                    Do you want to remove the member?
            </Typography>

            </DialogContent>

            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => props.onDeleteCont(props.id)} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

function SimpleDialogDemo(props) {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(emails[1]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    console.log(`props id ${props.id}`)

    return (
        <div>
            {/* <Typography variant="subtitle1">Selected: {selectedValue}</Typography>
            <br />
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Open simple dialog
            </Button>
             */}
            {/* <IconButton aria-label="delete" color="secondary" size="small"
                // onClick={() => props.onDeleteCont(props.id)}
                onClick={handleClickOpen}>
                <DeleteOutlineOutlinedIcon fontSize="inherit" />
            </IconButton> */}
            <Typography
                onClick={handleClickOpen}
                variant="body1">
                Delete
            </Typography>
            <SimpleDialog selectedValue={selectedValue} onDeleteCont={(id) => props.onDeleteCont(id)} id={props.id} open={open} onClose={handleClose} />
        </div>
    );
}


function SimpleDialog2(props) {
    const dispatch = useDispatch()
    const adminRoles = useSelector(state => state.adminRoles.adminRoles)
    const classes = useStyles();
    const { onClose, selectedValue, open } = props;
    const [chkIds, setChkIds] = useState(adminRoles)

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    const { contRolesGet, index, checkIds, roleIds, row, currUsrId } = props;
    console.log(`contRolesGet in dialog ${contRolesGet}`)

    // this function is not useful, not called when required...
    const loadChecked = () => {
        // if checked are empty, set with role ids
        console.log(`dialog row id is ${row.id}`)
        console.log(`dialog currUsrId is ${currUsrId}`)
        console.log(`dialog checkIds is ${adminRoles}`)

        let newCheckedIds = []
        if (row.id != currUsrId && currUsrId > 0) {
            // new guy refresh checkedids
            newCheckedIds = []

        } else { // same guy again
            // first time
            newCheckedIds = adminRoles

        }

        if (!newCheckedIds.length) {
            if (roleIds.length) {
                newCheckedIds = roleIds

                console.log(`called  setcheckedids force`)
                setChkIds(newCheckedIds)

                // update to parent through props
                // props.setCheckIds(newCheckedIds)
            }
        }

        // update current user
        props.setCurrUsrId(row.id)
    }

    useEffect(() => {

        // check boxes auto-checked
        loadChecked()
        return (
            <></>
        )
    }, [])

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Choose Roles</DialogTitle>
            <DialogContent dividers>
                <List className={classes.root}>
                    {contRolesGet && contRolesGet.map((value, idx) => {
                        const labelId = `checkbox-list-label-${value}`;
                        const isSel = roleIds.indexOf(value.id) > -1

                        return (
                            <MenuItem value={labelId}
                                selected={isSel}>
                                <ListItem key={value} role={undefined} dense button onClick={props.handleToggle(value, index)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={adminRoles.indexOf(value.id) !== -1}
                                            // checked={roleIds.indexOf(value.id) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <PersonOutlineOutlinedIcon />
                                        <ListItemText id={labelId} primary={value.role_name} />
                                    </div>                                                                {/* <ListItemSecondaryAction>
                                                                    <IconButton edge="end" aria-label="comments">
                                                                        <CommentIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction> */}
                                </ListItem>
                            </MenuItem>
                        );
                    })}
                </List>
            </DialogContent>


            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Cancel
                </Button>

                <Button variant="contained" color="primary" onClick={() => { handleClose(); props.UpdateRoles(row.id) }}>
                    Save
                </Button>
            </DialogActions>

        </Dialog>
    );
}

SimpleDialog2.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

function SimpleDialogDemo2(props) {
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(emails[1]);
    const [checkIds, setCheckIds] = React.useState(props.checkIds)
    const { contRolesGet, index, roleIds, row, currUsrId } = props;

    console.log(`checkIds inside simpledialogdemo ${props.checkIds}`)

    const loadChecked = () => {
        // if checked are empty, set with role ids
        console.log(`button click row id is ${row.id}`)
        console.log(`button click currUsrId is ${currUsrId}`)

        let newCheckedIds = []
        if (row.id != currUsrId && currUsrId > 0) {
            // new guy refresh checkedids
            newCheckedIds = []

        } else { // same guy again
            // first time
            newCheckedIds = props.checkIds

        }

        if (!newCheckedIds.length) {
            if (roleIds.length) {
                newCheckedIds = roleIds

                console.log(`button click called  setcheckedids force`)
                setCheckIds(newCheckedIds)

                // update to parent through props
                props.setCheckIds(newCheckedIds)

                // update to redux - prefilled
                dispatch(updateAdminRoles(newCheckedIds))
            }
        }

        // update current user
        props.setCurrUsrId(row.id)
    }

    const handleClickOpen = () => {
        setCheckIds(props.checkIds)

        // props.setCheckIds(props.checkIds)
        loadChecked()
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <div>
            {/* <Typography variant="subtitle1">Selected: {selectedValue}</Typography>
            <br /> */}
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Roles
            </Button>

            <SimpleDialog2 selectedValue={selectedValue} open={open} onClose={handleClose}
                checkIds={checkIds}
                roleIds={props.roleIds}
                index={props.index}
                contRolesGet={props.contRolesGet}
                UpdateRoles={(id) => props.UpdateRoles(id)}
                row={props.row}
                handleToggle={(value, index) => props.handleToggle(value, index)}
                setCurrUsrId={(id) => props.setCurrUsrId(id)}
                currUsrId={props.currUsrId}
                setCheckIds={(chkIds) => props.setCheckIds(chkIds)} />
        </div>
    );
}



//sending invite field component
function SendingMailBar(props) {
    const classes = useStyles();
    const classes2 = useStyles2();
    const dispatch = useDispatch()
    const [age, setAge] = React.useState('');
    const [checked, setChecked] = React.useState([0]);
    const [email, setEmail] = React.useState("")
    const [checkIds, setCheckIds] = React.useState([])
    const [openSnack, handleOpenSnack] = React.useState(false)

    const handleSnackClose = () => {
        handleOpenSnack(false)
    }

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        const newCheckedId = [...checkIds]
        if (currentIndex === -1) {
            newChecked.push(value);
            newCheckedId.push(value.id)
        } else {
            newChecked.splice(currentIndex, 1);
            newCheckedId.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        setCheckIds(newCheckedId)

    };

    const PostInvite = async () => {
        const userId = Cookie.get('kgtopg.guruculum.user.id')
        await new Promise((resolve, reject) => {
            dispatch(listContSendMail(userId, email, checkIds))
            resolve()
        })

        // show snackbar
        handleOpenSnack(true)

        // reload users
        props.onUpdateUsers()
    }


    useEffect(() => {
        dispatch(listContRolesGet())


        return (() => {
            //
        })

    }, [])

    const contRolesGetData = useSelector(state => state.contRolesGet)
    const { loadingContRoleGet, contRolesGet, errorContRoleGet } = contRolesGetData

    {
        loadingContRoleGet && contRolesGet ?
            console.log("data is loading")
            :
            console.log("contRolesGet ========", contRolesGet)

    }

    return (
        <div
            data-tut="first-step">

            <Paper component="form" className={classes2.root}>
                <IconButton className={classes2.iconButton} aria-label="menu">
                    <EmailOutlinedIcon />
                </IconButton>
                <InputBase
                    className={classes2.input}
                    placeholder="Enter Invitee email address"
                    inputProps={{ 'aria-label': 'Invitee email address' }}
                    onChange={(e) => { setEmail(e.target.value) }}

                />

                <Divider className={classes2.divider} orientation="vertical" />

                {/* <IconButton type="submit" className={classes2.iconButton} aria-label="search">
                    <SearchIcon />
                </IconButton> */}
                <FormControl variant="outlined" className={classes2.formControl} size="small">
                    <InputLabel >
                        <div style={{ display: "flex", justifyContent: "center", justifyItems: "center", alignItems: "center" }}>
                            <PersonOutlineOutlinedIcon />
                           Role
                        </div>
                    </InputLabel>
                    <Select
                        // labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={age}
                        onChange={handleChange}
                        label="Role"
                    >
                        {/* <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        */}

                        <List className={classes.root}>
                            {contRolesGet && contRolesGet.map((value, index) => {
                                const labelId = `checkbox-list-label-${value}`;

                                return (
                                    <MenuItem value={labelId}>
                                        <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checked.indexOf(value) !== -1}
                                                    tabIndex={-1}
                                                    // disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <PersonOutlineOutlinedIcon />
                                                <ListItemText id={labelId} primary={value.role_name} />
                                            </div>
                                            {/* <ListItemSecondaryAction>
                                                                    <IconButton edge="end" aria-label="comments">
                                                                        <CommentIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction> */}
                                        </ListItem>
                                    </MenuItem>
                                );
                            })}
                        </List>
                        {/* <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem> */}
                    </Select>
                </FormControl>

                <Divider className={classes2.divider} orientation="vertical" />
                <IconButton color="primary" className={classes2.iconButton} aria-label="directions" onClick={() => PostInvite()}>
                    <SendOutlinedIcon />
                </IconButton>
                <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert2 onClose={handleSnackClose} severity="success">
                        You have successfully invited the user!
                    </Alert2>
                </Snackbar>

            </Paper>
        </div >
    )

}

// table cells styles
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

const useStyles1 = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export default function AdminControls() {
    const classes = useStyles();
    const classes1 = useStyles1()
    const classes3 = useStyles3()
    const classes4 = useStyles4()
    const dispatch = useDispatch()
    const history = useHistory()

    const [age, setAge] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [checked, setChecked] = React.useState([]);
    const [checkIds, setCheckIds] = React.useState([])
    const [email, setEmail] = React.useState("")
    const [currIndex, setCurrIndex] = React.useState()
    const [contProvs, setContProvs] = React.useState(null)
    const [openSnack, handleOpenSnack] = React.useState(false)
    const [currUsrId, setCurrUsrId] = React.useState(0)
    const [opens, setOpens] = React.useState([])
    const [firstTime, setFirstTime] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [actvRow, setActvRow] = React.useState({ id: 0 });
    const [isTourOpen, setIsTourOpen] = useState(false);

    const handleElClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setActvRow(row)
    };

    const handleElClose = () => {
        setAnchorEl(null);
    };

    const handleSnackClose = () => {
        handleOpenSnack(false)
    }

    const handleTourOpen = () => {
        setIsTourOpen(true)
    }

    const [state, setState] = React.useState({
        right: false,
    });
    const [state1, setState1] = React.useState({
        right: false,
    });

    const updateCheckIds = (checkIds) => {
        console.log(`updateCheckIds() called.. ${JSON.stringify(checkIds)}`)

        // update redux admin roles ids
        dispatch(updateAdminRoles(checkIds)) // they come when dialog of roles is pre-filled or updated..
        setCheckIds(checkIds)
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const toggleDrawer1 = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState1({ ...state1, [anchor]: open });
    };

    const handleChange = (event) => {
        // setAge(event.target.value);

        console.log(`event ${event}`)
        console.log(`event target ${event.target}`)
        console.log(`event target value ${event.target.value}`)
    };

    const handleClose = () => {
        setOpen(false);

        // close all opens
        closeAllOpens()
    };

    const closeAllOpens = () => {
        let newOpens = []
        if (opens) {
            opens.map(opn => {
                newOpens.push(false)
            })

            console.log(`clearing all opens`)
            setOpens(newOpens)
        }
    }

    const handleOpen = (index) => {
        setOpen(true);
        setCurrIndex(index)
    };

    const handleToggle = (value, index) => () => {
        // console.log(`selectRef ${selectRef}`)
        // selectRef.open()

        // setOpen(true)
        // const currentIndex = checked.indexOf(value);
        console.log(`handleToggle value ${value}`)
        console.log(`handleToggle id ${value.id}`)
        console.log(`handleToggle checkIds ${JSON.stringify(checkIds)}`)

        // keep it open
        /* let newOpens = opens
        if (newOpens) {
            newOpens.map((opn, idx) => {
                if (index == idx) {
                    newOpens[idx] = true
                } else {
                    newOpens[idx] = opn
                }
            })

            setOpens(newOpens)
        } */

        // const currentIndex = checkIds.indexOf(value.id)
        const currentIndex = adminRoles.indexOf(value.id)
        console.log(`handleToggle currenIndex ${currentIndex}`)
        const newChecked = [...checked];
        const newCheckedIds = [...checkIds]
        if (currentIndex === -1) {
            newChecked.push(value);
            newCheckedIds.push(value.id)
        } else {
            newChecked.splice(currentIndex, 1);
            newCheckedIds.splice(currentIndex, 1)
        }

        setChecked(newChecked);
        setCheckIds(newCheckedIds)

        // update redux admin role ids
        dispatch(updateAdminRoles(newCheckedIds))
    };


    console.log("checked list", checked)
    console.log("checkedIds list", JSON.stringify(checkIds))



    const contRolesGetData = useSelector(state => state.contRolesGet)
    const { loadingContRoleGet, contRolesGet, errorContRoleGet } = contRolesGetData

    {
        loadingContRoleGet && contRolesGet ?
            console.log("data is loading")
            :
            console.log("contRolesGet", contRolesGet)

    }

    const onDeleteCont = async (provId) => {
        await PublisherAPI.deleteContProv(provId)

        // call all admin users again..
        getContProvAdmins()
    }


    const UpdateRoles = async (id) => {
        console.log("independent user id", id)
        await new Promise((resolve, reject) => {
            dispatch(listContRolesPut(id, checkIds))
            resolve()
        })

        // clear selections
        setCheckIds([])
        setChecked([])
        await new Promise((resolve, reject) => {
            dispatch(updateAdminRoles([])) // reset redux admin role selected ids
            resolve()
        })

        // reload
        await new Promise((resolve, reject) => {
            getContProvAdmins()
            resolve()
        })

        // show snack
        handleOpenSnack(true)
    }

    const getContProvAdmins = async () => {
        const userId = Cookie.get('kgtopg.guruculum.user.id')
        const resp = await PublisherAPI.getContProvAdmins(userId);

        console.log(`response from get content prov admins ${JSON.stringify(resp)}`)

        if (resp) {
            const admins = resp.data.data
            setContProvs(admins)

            // set up the opens array
            let opens = []
            admins.map(adm => {
                opens.push(false)
            })

            setOpens(opens)
        }

    }

    useEffect(() => {

        dispatch(listContRolesGet())
        // also calls redux for only admin role ids

        getContProvAdmins();
        return (() => {
            //
        })

    }, [])

    const contRolesPutData = useSelector(state => state.contRolesPut)
    const { loadingContRolePut, contRolesPut, errorContRolesPut } = contRolesPutData
    const adminRoles = useSelector(state => state.adminRoles.adminRoles)

    {
        loadingContRolePut && contRolesPut ?
            console.log("data is loading")
            :
            console.log("pubRolesGet", contRolesPut)

    }


    const openActivity = (id) => {
        history.push(`/publisher-content/admin-activity/?role_id=${id}`)

    }

    return (
        <div>
            <div style={{
                position: 'absolute',
                top: 0,
                right: 0
            }}>
                <LiveHelpIcon
                    onClick={handleTourOpen} />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: 20 }}>
                <div>
                    <Typography
                        variant="h4">
                        Admin Access Controls
                    </Typography>
                </div>
                <div>
                    <SendingMailBar onUpdateUsers={() => getContProvAdmins()} />
                </div>

                {/* 
                <div>


                    {['right'].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <Button variant="contained" color="primary" onClick={toggleDrawer(anchor, true)}>
                                create Admin
                        </Button>
                            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                                <AddAdmin />
                            </Drawer>
                        </React.Fragment>
                    ))} 
                </div>*/}
            </div>

            <div data-tut="second-step">
                {(contProvs && contProvs.length) ?
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="left">Name </StyledTableCell>
                                    {/* <StyledTableCell align="center">Roles</StyledTableCell> */}
                                    <StyledTableCell align="center">Activity</StyledTableCell>
                                    <StyledTableCell align="center">Approved</StyledTableCell>
                                    {/* <StyledTableCell align="center">Action</StyledTableCell> */}
                                    <StyledTableCell align="center">Edit</StyledTableCell>
                                    <StyledTableCell align="center">Action</StyledTableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {rows.map((row, index) => ( */}
                                {contProvs.map((row, index) => {
                                    let roleIds = []
                                    let roles = row.role_name
                                    if (roles && roles.length) {
                                        roles.map((role) => {
                                            roleIds.push(role.role_id)
                                        })
                                    }

                                    console.log(`role ids first ${JSON.stringify(roleIds)}`)

                                    /* 
                                                                    let newChecked = checked;
                                                                    if (!checked.length)
                                                                        newChecked = roleIds
                                    
                                                                    if (roleIds) {
                                                                        // add unexisting
                                                                        newChecked.map((id, index) => {
                                                                            // check if doesn't exist
                                                                            if (roleIds.indexOf(id) === -1) {
                                                                                // add because it isn't there and has been selected now..
                                                                                roleIds.push(id)
                                    
                                                                            }
                                                                        })
                                    
                                                                        // now remove non existent in checked
                                                                        roleIds.map((id) => {
                                                                            if (newChecked.indexOf(id) === -1) {
                                                                                // remove as it is non-existent
                                                                                roleIds.splice(roleIds.indexOf(id), 1)
                                                                            }
                                                                        })
                                                                    } else {
                                                                        roleIds = newChecked
                                                                    }
                                     */
                                    console.log(`roleIds after ${JSON.stringify(roleIds)}`)


                                    return (
                                        <StyledTableRow key={row.prov_name_text}>
                                            <StyledTableCell component="th" scope="row" align="left">
                                                <Typography
                                                    variant="h5">
                                                    {row.prov_name_text}
                                                </Typography>

                                                <Typography
                                                    variant="caption">
                                                    {row.prov_email_text}
                                                </Typography>
                                            </StyledTableCell>
                                            {/* <StyledTableCell align="center">{row.prov_email_text}</StyledTableCell> */}
                                            <StyledTableCell align="center">-</StyledTableCell>
                                            <StyledTableCell align="center">
                                                -
                                        </StyledTableCell>
                                            {/* <StyledTableCell align="center">
                                            <Button variant="outlined" onClick={() => { openActivity(row.id) }}>View Activity</Button>
                                        </StyledTableCell> */}
                                            <StyledTableCell align="center">


                                                <SimpleDialogDemo2
                                                    contRolesGet={contRolesGet}
                                                    checkIds={adminRoles}
                                                    roleIds={roleIds}
                                                    row={row}
                                                    handleToggle={(value, index) => handleToggle(value, index)}
                                                    index={index}
                                                    UpdateRoles={(id) => UpdateRoles(id)}
                                                    setCurrUsrId={(id) => setCurrUsrId(id)}
                                                    currUsrId={currUsrId}
                                                    setCheckIds={(chkIds) => updateCheckIds(chkIds)} />





                                                {/* 
                                            <FormControl className={classes3.formControl} key={index}>
                                                <InputLabel id="demo-controlled-open-select-label">Role</InputLabel>

                                                <Select
                                                    ref={el => { selectRef = el }}
                                                    labelId="demo-controlled-open-select-label"
                                                    id="demo-controlled-open-select"
                                                    // open={(opens.length > (index)) ? opens[index] : false}
                                                    onClose={() => {
                                                        console.log(`calling onClose()`)
                                                        console.log(`opens.length > (${index}) ${opens.length > (index)}`)
                                                        console.log(`opens[${index}] ${opens[index]}`)
                                                        closeAllOpens()
                                                        // handleClose()
                                                    }}
                                                    // onOpen={(index) => handleOpen(index)}
                                                    // value={age}
                                                    onChange={() => {
                                                        console.log(`called onChange()`)
                                                        // handleChange()
                                                    }}
                                                    onBlur={() => {
                                                        console.log(`calling onBlur()`)
                                                        // handleClose()
                                                        console.log(`opens.length > (${index}) ${opens.length > (index)}`)
                                                        console.log(`opens[${index}] ${opens[index]}`)
                                                        // closeAllOpens()
                                                        // handleClose()
                                                        let newOpens = opens
                                                        newOpens[index] = true
                                                        setOpens(newOpens)
                                                    }}
                                                    onClick={() => {
                                                        // console.log(`clicked select... ${index}`)
                                                        // // open the select
                                                        // let newOpens = opens
                                                        // newOpens[index] = true

                                                        // console.log(`opens.length > (index) ${opens.length > (index)}`)
                                                        // console.log(`opens[index] ${opens[index]}`)
                                                        // setOpens(newOpens)
                                                    }}
                                                    onOpen={() => {
                                                        let newOpens = opens
                                                        console.log(`opens.length > (${index}) ${opens.length > (index)}`)
                                                        console.log(`opens[${index}] ${opens[index]}`)
                                                        // console.log(`opens length ${opens.length}`)
                                                        // console.log(`opens index ${index} is ${opens[index]}`)
                                                        newOpens[index] = true
                                                        // setOpens(newOpens)
                                                        // closeAllOpens()

                                                        // if checked are empty, set with role ids
                                                        console.log(`row id is ${row.id}`)
                                                        console.log(`currUsrId is ${currUsrId}`)

                                                        let newCheckedIds = []
                                                        if (row.id != currUsrId && currUsrId > 0) {
                                                            // new guy refresh checkedids
                                                            newCheckedIds = []

                                                        } else { // same guy again
                                                            // first time
                                                            newCheckedIds = checkIds

                                                        }

                                                        if (!newCheckedIds.length) {
                                                            if (roleIds.length) {
                                                                newCheckedIds = roleIds

                                                                console.log(`called  setcheckedids force`)
                                                                setCheckIds(newCheckedIds)
                                                            }
                                                        }

                                                        // update current user
                                                        setCurrUsrId(row.id)

                                                    }}
                                                >


                                                    <List className={classes.root}>
                                                        {contRolesGet && contRolesGet.map((value, idx) => {
                                                            const labelId = `checkbox-list-label-${value}`;
                                                            const isSel = roleIds.indexOf(value.id) > -1

                                                            return (
                                                                <MenuItem value={labelId}
                                                                    selected={isSel}>
                                                                    <ListItem key={value} role={undefined} dense button onClick={handleToggle(value, index)}>
                                                                        <ListItemIcon>
                                                                            <Checkbox
                                                                                edge="start"
                                                                                checked={checkIds.indexOf(value.id) !== -1}
                                                                                // checked={roleIds.indexOf(value.id) !== -1}
                                                                                tabIndex={-1}
                                                                                disableRipple
                                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                                            />
                                                                        </ListItemIcon>
                                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                            <PersonOutlineOutlinedIcon />
                                                                            <ListItemText id={labelId} primary={value.role_name} />
                                                                        </div>                                                                
                                                                //          <ListItemSecondaryAction>
                                                                //     <IconButton edge="end" aria-label="comments">
                                                                //         <CommentIcon />
                                                                //     </IconButton>
                                                                // </ListItemSecondaryAction> 
                                                                    </ListItem>
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </List>
                                                    <MenuItem value="">

                                                        <Button variant="contained" color="primary" onClick={() => { UpdateRoles(row.id) }}>
                                                            Save
                                                         </Button>
                                                    </MenuItem>

                                                </Select>
                                            </FormControl>

                                             */}







                                                {/* {['right'].map((anchor) => (
                                        <React.Fragment key={anchor}>

                                            <IconButton aria-label="delete" color="primary" size="small" onClick={toggleDrawer1(anchor, true)}>
                                                <EditOutlinedIcon fontSize="inherit" />
                                            </IconButton>
                                            <Drawer anchor={anchor} open={state1[anchor]} onClose={toggleDrawer1(anchor, false)}>
                                                <AddAdmin />
                                            </Drawer>
                                        </React.Fragment>
                                    ))} */}


                                            </StyledTableCell>

                                            {/* 
                                        <StyledTableCell align="center">
                                           check box
                                        </StyledTableCell>
 */}


                                            <StyledTableCell align="center">
                                                {/* <IconButton aria-label="delete" color="secondary" size="small"
                                                onClick={() => onDeleteCont(row.id)}>
                                                <DeleteOutlineOutlinedIcon fontSize="inherit" />
                                            </IconButton> */}

                                                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleElClick(e, row)}>
                                                    <MoreVertIcon />
                                                </Button>
                                                <Menu
                                                    id="simple-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleElClose}
                                                >
                                                    <MenuItem onClick={() => { openActivity(actvRow.id) }}>Activity</MenuItem>
                                                    <MenuItem onClick={handleElClose}>
                                                        <SimpleDialogDemo onDeleteCont={(id) => { onDeleteCont(id) }} id={actvRow.id} />
                                                    </MenuItem>
                                                    {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                                                </Menu>


                                            </StyledTableCell>

                                        </StyledTableRow>
                                    )
                                }
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Alert severity="warning">
                        <AlertTitle>No Admins!</AlertTitle>
                You have not yet invited any admins — <strong>send an invite from above now!</strong>
                    </Alert>
                }
            </div>

            {/* update */}
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert2 onClose={handleSnackClose} severity="success">
                    You have successfully updated roles for the user!
                </Alert2>
            </Snackbar>

            <Tour
                steps={steps}
                isOpen={isTourOpen}
                onRequestClose={() => setIsTourOpen(false)}
            />
        </div>
    );
}