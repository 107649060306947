import axios from "axios";
import Constants from "../../resource/Constants";
import { SUBTOPDETAILS_LIST_REQUEST, SUBTOPDETAILS_LIST_SUCCESS, SUBTOPDETAILS_LIST_FAIL } from "../constants/SubtopicConstants";

const listChapSubTopGet = (chapId) => async (dispatch) => {
    try {
        dispatch({ type: SUBTOPDETAILS_LIST_REQUEST })

        const { data } = await axios.get(Constants.Application.PLATFORM_END_URL + `/chapter-topic-map/?chapterid=${chapId}&books_id=0`)
        console.log("data in list chap-sub-topics  get", data)
        dispatch({ type: SUBTOPDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: SUBTOPDETAILS_LIST_FAIL, payload: error.message })

    }
}

const listSubTopGet = (topId) => async (dispatch) => {
    try {
        dispatch({ type: SUBTOPDETAILS_LIST_REQUEST })

        const { data } = await axios.get(Constants.Application.PLATFORM_END_URL + `/get-topic-subtopics/?topic_id=${topId}`)
        console.log("data in list sub-topics  get", data)
        dispatch({ type: SUBTOPDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: SUBTOPDETAILS_LIST_FAIL, payload: error.message })

    }
}

export { listSubTopGet, listChapSubTopGet }
