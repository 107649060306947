import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


function createData(Title, Board, Subject, Chapter, Topic, Type) {
  return { Title, Board, Subject, Chapter, Topic, Type };
}

const rows = [
  createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  createData('Spherical Mirrors', 'ICSE', "Physics", "Mirrors", 'Reflection', "Video"),
  createData(' Chemical Reactions and Equations', 'CBSE', "Chemistry", "Chemical Reactions", 'Reactions', "Pdf"),
  createData('Acids, Bases and Salts', 'CBSE', "Chemistry", "Acids, Bases and Salts", 'Acids', "Video"),
  createData('Metals and Non-metals', 'CBSE', "Chemistry", "Metals and Non-metals", 'Metals', "Video"),
  createData('Resource and Development', 'CBSE', "Geography", "Resouces and Development", 'Resources', "Video"),
  createData('Footprints without Feet', 'SSC', "English", "Footprints without Feet", 'Chapter1', "Video"),
  createData(' Polynomials', 'ICSE', "Maths", " Polynomials", 'quadratic polynomials', "Video"),
  createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  createData('Minerals and Energy Resources', 'CBSE', "Geography", "Minerals and Energy Resources", 'Minerals', "Video"),
  createData('Pairs of Linear Equations in Two Variables', 'CBSE', "Maths", "Pairs of Linear Equations in Two Variables", 'Linear Equations', "Video"),
  createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  createData('Footprints without Feet', 'ICSE', "English", "Footprints without Feet", 'Chapter1', "Video"),
  createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  createData('Agriculture', 'CBSE', "Geography", "Agriculture", 'Agriculture', "Video"),
  createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  createData('Metals and Non-metals', 'ICSE', "Chemistry", "Metals and Non-metals", 'Metals', "Video"),
  createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  createData('Refraction Of Light', 'CBSE', "Physics", "Refraction of Light", 'Reflection', "Video"),
  createData('Pairs of Linear Equations in Two Variables', 'ICSE', "Maths", "Pairs of Linear Equations in Two Variables", 'Linear Equations', "Video"),
  createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  createData('Resource and Development', 'ICSE', "Geography", "Resouces and Development", 'Resources', "Video"),
  createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  createData('Pairs of Linear Equations in Two Variables', 'ICSE', "Maths", "Pairs of Linear Equations in Two Variables", 'Linear Equations', "Video"),
  createData('Water Resources', 'CBSE', "Geography", "Water Resources", 'Water', "Image"),
  createData('Resource and Development', 'SSC', "Geography", "Resouces and Development", 'Resources', "Video"),
  createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  createData('Forest and Wildlife Resources', 'CBSE', "Geography", "Forest and Wildlife Resources", 'Forest and Wildlife', "Video"),
  createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  createData('Light-reflection', 'CBSE', "Physics", "Light", 'Reflection', "Video"),
  createData('Metals and Non-metals', 'SSC', "Chemistry", "Metals and Non-metals", 'Metals', "Video"),
  createData('Footprints without Feet', 'SSC', "English", "Footprints without Feet", 'Chapter1', "Video"),

];


const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
  { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: 'Life Is Beautiful', year: 1997 },
  { title: 'The Usual Suspects', year: 1995 },
  { title: 'Léon: The Professional', year: 1994 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Saving Private Ryan', year: 1998 },
  { title: 'Once Upon a Time in the West', year: 1968 },
  { title: 'American History X', year: 1998 },
  { title: 'Interstellar', year: 2014 },
  { title: 'Casablanca', year: 1942 },
  { title: 'City Lights', year: 1931 },
  { title: 'Psycho', year: 1960 },
  { title: 'The Green Mile', year: 1999 },
  { title: 'The Intouchables', year: 2011 },
  { title: 'Modern Times', year: 1936 },
  { title: 'Raiders of the Lost Ark', year: 1981 },
  { title: 'Rear Window', year: 1954 },
  { title: 'The Pianist', year: 2002 },
  { title: 'The Departed', year: 2006 },
  { title: 'Terminator 2: Judgment Day', year: 1991 },
  { title: 'Back to the Future', year: 1985 },
  { title: 'Whiplash', year: 2014 },
  { title: 'Gladiator', year: 2000 },
  { title: 'Memento', year: 2000 },
  { title: 'The Prestige', year: 2006 },
  { title: 'The Lion King', year: 1994 },
  { title: 'Apocalypse Now', year: 1979 },
  { title: 'Alien', year: 1979 },
  { title: 'Sunset Boulevard', year: 1950 },
  { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964 },
  { title: 'The Great Dictator', year: 1940 },
  { title: 'Cinema Paradiso', year: 1988 },
  { title: 'The Lives of Others', year: 2006 },
  { title: 'Grave of the Fireflies', year: 1988 },
  { title: 'Paths of Glory', year: 1957 },
  { title: 'Django Unchained', year: 2012 },
  { title: 'The Shining', year: 1980 },
  { title: 'WALL·E', year: 2008 },
  { title: 'American Beauty', year: 1999 },
  { title: 'The Dark Knight Rises', year: 2012 },
  { title: 'Princess Mononoke', year: 1997 },
  { title: 'Aliens', year: 1986 },
  { title: 'Oldboy', year: 2003 },
  { title: 'Once Upon a Time in America', year: 1984 },
  { title: 'Witness for the Prosecution', year: 1957 },
  { title: 'Das Boot', year: 1981 },
  { title: 'Citizen Kane', year: 1941 },
  { title: 'North by Northwest', year: 1959 },
  { title: 'Vertigo', year: 1958 },
  { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
  { title: 'Reservoir Dogs', year: 1992 },
  { title: 'Braveheart', year: 1995 },
  { title: 'M', year: 1931 },
  { title: 'Requiem for a Dream', year: 2000 },
  { title: 'Amélie', year: 2001 },
  { title: 'A Clockwork Orange', year: 1971 },
  { title: 'Like Stars on Earth', year: 2007 },
  { title: 'Taxi Driver', year: 1976 },
  { title: 'Lawrence of Arabia', year: 1962 },
  { title: 'Double Indemnity', year: 1944 },
  { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
  { title: 'Amadeus', year: 1984 },
  { title: 'To Kill a Mockingbird', year: 1962 },
  { title: 'Toy Story 3', year: 2010 },
  { title: 'Logan', year: 2017 },
  { title: 'Full Metal Jacket', year: 1987 },
  { title: 'Dangal', year: 2016 },
  { title: 'The Sting', year: 1973 },
  { title: '2001: A Space Odyssey', year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: 'Toy Story', year: 1995 },
  { title: 'Bicycle Thieves', year: 1948 },
  { title: 'The Kid', year: 1921 },
  { title: 'Inglourious Basterds', year: 2009 },
  { title: 'Snatch', year: 2000 },
  { title: '3 Idiots', year: 2009 },
  { title: 'Monty Python and the Holy Grail', year: 1975 },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'Title', numeric: false, disablePadding: true, label: 'Title' },
  { id: 'Board', numeric: false, disablePadding: true, label: 'Board' },
  { id: 'Subject', numeric: false, disablePadding: true, label: 'Subject' },
  { id: 'Chapter', numeric: false, disablePadding: true, label: "Chapter" },
  { id: 'Topic', numeric: false, disablePadding: true, label: 'Topic' },
  { id: "Type", numeric: false, disablePadding: true, label: "Type" },
  // { id: "image", numeric: false, disablePadding: false, label: "image(if)" }

];

const handleDelete = () => {
  console.info('You clicked the delete icon.');
};

const handleClick = () => {
  console.info('You clicked the Chip.');
};



function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };


  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
            style={{fontFamily: "Poppins, Helvetica, sans-serif"}}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontFamily: "Poppins, Helvetica, sans-serif",
               color: "#B5B5C3" ,
              }}
          
          >

            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}

            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div"   style={{fontFamily: "Poppins, Helvetica, sans-serif"}}>
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h5" id="tableTitle" component="div"
          style={{
            fontSize: 16,
            fontWeight: "500",
            fontFamily: "Poppins, Helvetica, sans-serif",
          }}>

          Content list
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="New Content">
          {/* <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton> */}
          <Button variant="contained" color="primary" className={classes.primary} style={{ width: "20%", backgroundColor: "#3699FF", }}>
            <Link to="/addcontent"
              style={{
                textDecoration: "none",
                color: "#F3F6F9",
                fontFamily: "Poppins, Helvetica, sans-serif",
                textTransform: "none",
                fontSize: 14,

              }}
            >
              New content
          </Link>
          </Button>
        </Tooltip>
      )}
    </Toolbar>
  );
};


const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    minWidth: 500,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',

    },
  },
}))(InputBase);

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(3),
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  margin: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: "15%"

  },
  primary: {
    width: "25%",
    height: 40,
  }
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [age, setAge] = React.useState('');
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      {/* <Paper className={classes.paper}> */}
      <EnhancedTableToolbar numSelected={selected.length} />

      <TableContainer>
        <Divider light style={{ width: "100%" }} />

        {/* <FormControl className={classes.margin}>
          <NativeSelect
            id="demo-customized-select-native"
            value={age}
            onChange={handleChange}
            input={<BootstrapInput />}

          >
            <option aria-label="None" value="Ten" />
            <option value={10}>Ten</option>
            <option value={20}>Twenty</option>
            <option value={30}>Thirty</option>
          </NativeSelect>
          <Typography>
            <span style={{ fontSize: 13, fontWeight: "bold", color: " #B5B5C3 " }}>Filter</span> <span style={{ fontSize: 13, color: " #B5B5C3 " }}>by Title</span>
          </Typography>

        </FormControl>

        <FormControl className={classes.margin}>
          <NativeSelect
            id="demo-customized-select-native"
            value={age}
            onChange={handleChange}
            input={<BootstrapInput />}
          >
            <option aria-label="None" value="Ten" />
            <option value={10}>Ten</option>
            <option value={20}>Twenty</option>
            <option value={30}>Thirty</option>
          </NativeSelect>
          <Typography>
            <span style={{ fontSize: 13, fontWeight: "bold", color: " #B5B5C3 " }}>Filter</span> <span style={{ fontSize: 13, color: " #B5B5C3 " }}>by Chapter</span>
          </Typography>
        </FormControl> */}

        <FormControl className={classes.margin}>
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            options={top100Films.map((option) => option.title)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search"
                margin="normal"
                variant="outlined"
                InputProps={{ ...params.InputProps, type: 'search' }}
              />
            )}
            style={{ minWidth: 300 }}
          />
          <Typography>
            <span style={{ fontSize: 13, fontWeight: "bold", color: " #B5B5C3 ", }}>Search</span> <span style={{ fontSize: 13, color: " #B5B5C3 " }}>in all Fields</span>
          </Typography>
        </FormControl>


        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
          aria-label="enhanced table"
        >


          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}

          />

          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.name)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                    align="right"
                  >

                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                        style={{ fontSize: 10 }}
                      />
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none" align="left" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}>{row.Title}</TableCell>
                    <TableCell align="left" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}>
                      {row.Board}
                    </TableCell>
                    <TableCell align="left" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}>{row.Subject}</TableCell>
                    <TableCell align="left" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}>{row.Chapter}</TableCell>
                    <TableCell align="left" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}>{row.Topic}</TableCell>
                    <TableCell align="left" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}>{row.Type}</TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{ height: (dense ? 33 : 53) * emptyRows, fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>

        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}
      />
      {/* </Paper> */}
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
}