import { TOPDETAILS_LIST_REQUEST, TOPDETAILS_LIST_SUCCESS, TOPDETAILS_LIST_FAIL } from "../constants/topicConstants";


function TopDetailsReducer(state = { TopDetails: [] }, action) {
    switch (action.type) {
        case TOPDETAILS_LIST_REQUEST:
            return { loadingTop: true }
        case TOPDETAILS_LIST_SUCCESS:
            return { loadingTop: false, TopDetails: action.payload };
        case TOPDETAILS_LIST_FAIL:
            return { loadingTop: false, error: action.payload }
        default:
            return state;
    }

}

export { TopDetailsReducer }