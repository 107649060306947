import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Divider, Grid, Toolbar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { ArrowBack, Refresh } from '@material-ui/icons';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { useDispatch, useSelector } from 'react-redux';
import { listBoardGet } from '../../redux/actions/boardDetailsAction';
import { boardDetailsReducer } from '../../redux/reducers/boardDetailsReducer';
import { listInstGet } from '../../redux/actions/InstituteDetailsAction';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { listSubGet } from '../../redux/actions/subjectsAction';
import { listStdGet } from '../../redux/actions/standardAction';
import { listChpGet } from '../../redux/actions/chapterAction';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 350,
        minHeight: 30,

    },
    formControl1: {
        margin: theme.spacing(1),
        minWidth: 400,
        minHeight: 30,

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    toolbar: {
        marginRight: 24,
        justifyContent: "space-between"
    },
    button: {
        margin: theme.spacing(1),
        justifyContent: "flex-end",
        alignSelf: "center",
    },
    button1: {
        margin: theme.spacing(1),
        justifyContent: "flex-end",
        justifyItems: "flex-end",
        justifySelf: "flex-end",
        alignSelf: "flex-end",
        alignItems: "flex-end",
        alignContent: "flex-end",
    },
}));


const boardDetailsMock = [
    { institute_board_id: 10001, institute_board_name: "CBSE", institute_board_f_desc: "cbse board", institute_board_status: 1, created_date: "2020-05-27" },
    { institute_board_id: 10002, institute_board_name: "Andhra Pradesh State Board", institute_board_f_desc: "AP state board", institute_board_status: 1, created_date: "2020-05-27" },
    { institute_board_id: 10003, institute_board_name: "ICSE", institute_board_f_desc: "ICSE board", institute_board_status: 1, created_date: "2020-05-27" },
    { institute_board_id: 10004, institute_board_name: "Bihar State Board", institute_board_f_desc: "Bihar state board", institute_board_status: 1, created_date: "2020-05-27" },
    { institute_board_id: 10005, institute_board_name: "Cambridge International", institute_board_f_desc: "Cambridge International board", institute_board_status: 1, created_date: "2020-05-27" },
    { institute_board_id: 10006, institute_board_name: "Chhattisgarh State Board", institute_board_f_desc: "Chhattisgarh state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10007, institute_board_name: "Gujarat State Board", institute_board_f_desc: "Gujarat state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10008, institute_board_name: "International Baccalaureate Board", institute_board_f_desc: "International Baccalaureate board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10009, institute_board_name: "Jharkhand State Board", institute_board_f_desc: "Jharkhand state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10010, institute_board_name: "J&K State Board", institute_board_f_desc: "J&K state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10011, institute_board_name: "Karnataka State Board", institute_board_f_desc: "Karnataka state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10012, institute_board_name: "Kerala State Board", institute_board_f_desc: "Kerala state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10013, institute_board_name: "Madhya Pradesh State Board", institute_board_f_desc: "Madhya Pradesh state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10014, institute_board_name: "Maharashtra State Board", institute_board_f_desc: "Maharashtra state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10015, institute_board_name: "Punjab State Board", institute_board_f_desc: "Punjab state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10016, institute_board_name: "Rajasthan State Board", institute_board_f_desc: "Rajasthan state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10017, institute_board_name: "Tamil Nadu State Board", institute_board_f_desc: "Tamil Nadu state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10018, institute_board_name: "Telangana State Board", institute_board_f_desc: "Telangana state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10019, institute_board_name: "Uttarakhand State Board", institute_board_f_desc: "Uttarakhan state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10020, institute_board_name: "Uttar Pradesh State Board", institute_board_f_desc: "Uttar Pradesh state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10021, institute_board_name: "West Bengal State Board", institute_board_f_desc: "West Bengal state board", institute_board_status: 1, created_date: "2020-05-30" },

]

const instituteMockData = [
    { inst_id: 1, inst_type: "School" },
    { inst_id: 2, inst_type: "College" },
    { inst_id: 3, inst_type: "University" },
    { inst_id: 4, inst_type: "Institute" },
]

const standardsMockData = [
    { stand_id: 1, stand_name: 1 },
    { stand_id: 2, stand_name: 2 },
    { stand_id: 3, stand_name: 3 },
    { stand_id: 4, stand_name: 4 },
    { stand_id: 5, stand_name: 5 },
    { stand_id: 6, stand_name: 6 },
    { stand_id: 7, stand_name: 7 },
    { stand_id: 8, stand_name: 8 },
    { stand_id: 8, stand_name: 9 },
    { stand_id: 9, stand_name: 9 },
    { stand_id: 10, stand_name: 10 },
    { stand_id: 11, stand_name: 11 },
    { stand_id: 12, stand_name: 12 },
]




const subjectsMockData = [
    { subj_id: 1, subj_name: "English" },
    { subj_id: 2, subj_name: "Maths" },
    { subj_id: 3, subj_name: "Social" },
    { subj_id: 4, subj_name: "Physics" },
    { subj_id: 5, subj_name: "Chemistry" },
    { subj_id: 6, subj_name: "Biology" },

]

const chaptersMockData = [
    { chap_id: 1, chap_name: "A Letter to God." },
    { chap_id: 2, chap_name: "Polynomials" },
    { chap_id: 3, chap_name: "Federalism" },
    { chap_id: 4, chap_name: " Light-Reflection and Refraction. " },
    { chap_id: 5, chap_name: " Acids, Bases and Salts" },
    { chap_id: 6, chap_name: "Life Processes" },

]

const TopicsMockData = [
    { topic_id: 1, topic_name: "How Do Organisms Reproduce" },
    { topic_id: 2, topic_name: "Our Environment" },
    { topic_id: 3, topic_name: " Sources Of Energy." },
    { topic_id: 4, topic_name: " Coordinate Geometry. " },
    { topic_id: 5, topic_name: " Acids, Bases and Salts" },
    { topic_id: 6, topic_name: "Metals and Non Metals." },


]





export default function ContentMaking() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [age, setAge] = React.useState('');
    const [showInputField, SetShowInputField] = useState(false)
    const [selectBoardDetails, setSelectedBoardDetails] = useState("")
    const [boardDetailsState, setBoardDetailsState] = useState(null);
    const [instType, setInstType] = useState("School") // default value inside of function paranthesis
    const [boardType, setBoardType] = useState(" ")
    const [subjectType, setSubjectType] = useState(" ")
    const [standardType, setStandardType] = useState(" ")

    const dispatch = useDispatch();


    const handleChange = (event, newValue) => {
        console.log('handleChange called..')
        console.log(`new institute type value ${newValue}`)
        setInstType(newValue);
    };

    const handleInstChange = (event, newValue) => {
        console.log('handleInstChange called..')
        console.log(`new institute ${newValue}`)
    }


    const BootstrapInput = withStyles((theme) => ({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            height: 30,
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            fontSize: 16,
            width: 360,
            padding: '10px 26px 10px 12px',
            placeholder: "Title",
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#80bdff',

                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
        },
    }))(InputBase);




    const showInput = () => {
        SetShowInputField(true)

    }

    const boardDetailsGet = useSelector(state => state.boardDetails)
    const { loading, boardDetails, error } = boardDetailsGet


    const instDetailsGet = useSelector(state => state.instDetails)
    const { loadingInst, instDetails, errorInst } = instDetailsGet

    const subDetailsGet = useSelector(state => state.subDetails)
    const { loadingSub, subDetails, errorSub } = subDetailsGet

    const stdDetailsGet = useSelector(state => state.stdDetails)
    const { loadingStd, stdDetails, errorStd } = stdDetailsGet;

    const chpDetailsGet = useSelector(state => state.chpDetails)
    const { loadingChap, chapDetails, errorChp } = chpDetailsGet

    console.log("board Details", boardDetailsGet ? boardDetailsGet : "not yet fetched")
    console.log("instDetails ", instDetailsGet ? instDetailsGet : "not fetched inst details")
    console.log("subDetails", subDetailsGet ? subDetailsGet : "not fetched sub details")
    console.log("stdDetails", stdDetailsGet ? stdDetailsGet : "not fetched std details")
    console.log("chpterDetails", chpDetailsGet ? chpDetailsGet : "not fetched chp details")
    console.log("board details at state", boardDetailsState)



    useEffect(() => {
        dispatch(listBoardGet())
        dispatch(listInstGet())
        dispatch(listSubGet())
        dispatch(listStdGet())
        dispatch(listChpGet())


        return () => {
            // 
        }
    }, [])

    return (
        <div>


            <Toolbar className={classes.toolbar}>

                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ fontSize: 16, fontFamily: "Poppins, Helvetica, sans-serif", }}>
                    New Content
                </Typography>

                <div style={{ flexDirection: "row", }}>

                    <Link to="/publisher/mybooks" style={{ textDecoration: "none" }}>
                        <Button
                            variant="contained"
                            color="default"
                            size="small"
                            className={classes.button1}
                            startIcon={<ArrowBack style={{ fontFamily: "Poppins, Helvetica, sans-serif", color: " #80808F" }} />}
                            style={{ fontFamily: "Poppins, Helvetica, sans-serif", color: " #80808F", backgroundColor: "#F3F6F9", }}
                        >
                            Back
                     </Button>
                    </Link>

                    <Button
                        variant="contained"
                        color="default"
                        className={classes.button}
                        startIcon={<Refresh style={{ fontFamily: "Poppins, Helvetica, sans-serif", color: " #80808F" }} />}
                        size="small"
                        style={{ fontFamily: "Poppins, Helvetica, sans-serif", color: " #80808F", backgroundColor: "#F3F6F9", }}

                    >
                        Reset
                    </Button>


                    {/* <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        style={{ fontFamily: "Poppins, Helvetica, sans-serif", color: "#ffffff", backgroundColor: "#3699FF" }}
                    >
                        Save
                     </Button> */}


                </div>
            </Toolbar>
            <Divider style={{ marginBottom: 10 }} />


            {/* <AppBar position="static" color="default" style={{ marginTop: "20" }}> */}

            <Tabs
                value={value}
                // onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs example"
            >
                <Tab label="Basic info"  {...a11yProps(0)} style={{ fontSize: 12, }} />
                <Tab label="Add content" {...a11yProps(1)} style={{ fontSize: 12 }} />
            </Tabs>

            {/* </AppBar> */}



            <TabPanel value={value} index={0}>


                <div style={{ justifyContent: "center", alignContent: "center", alignItems: "center", display: "block" }}>
                    <div style={{ display: "flex", justifyContent: "center", margin: 20 }}>
                        <FormControl variant="outlined" className={classes.formControl} style={{ alignSelf: "center" }} >
                            <InputLabel id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}
                            >
                                Institute type
                             </InputLabel>

                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={instType}
                                onChange={handleInstChange}
                                label="Institute type"
                                size="small"
                                style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}

                            >



                                {loadingInst && instDetailsGet ?
                                    console.log("loading trueeee")
                                    :
                                    instDetails.length != 0 ?

                                        instDetails.data.map((list, index) => {
                                            return (
                                                <MenuItem key={index} value={list.institute_type_name} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_type_name}</MenuItem>

                                            )
                                        })
                                        :
                                        instituteMockData.map((list, index) => {
                                            return (
                                                <MenuItem key={index} value={list.inst_type} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.inst_type}</MenuItem>
                                            )
                                        })
                                }


                                {/* {instituteMockData ?
                                    instituteMockData.map((list, index) => {
                                        return (
                                            <MenuItem key={index} value="0" style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.inst_type}</MenuItem>
                                        )
                                    })
                                    :
                                    console.log("not getting the data")
                                } */}




                            </Select>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12
                                    }}>
                                    Institute type
                            </span>
                            </span>
                        </FormControl>



                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}
                            >
                                Board/Uni
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={age}
                                // onChange={handleChange}
                                label="Board/Uni"
                                style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}
                            >

                                {loading && boardDetailsGet ?
                                    boardDetailsMock.map((list, index) => {
                                        return (
                                            <MenuItem key={index} value="0" style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_board_name}</MenuItem>
                                        )
                                    })
                                    :
                                    boardDetails.length != 0 ?

                                        boardDetails.data.map((list, index) => {
                                            return (
                                                <MenuItem key={index} value={list.institute_type_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_board_name}</MenuItem>

                                            )
                                        })
                                        :
                                        boardDetailsMock.map((list, index) => {
                                            return (
                                                <MenuItem key={index} value="0" style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_board_name}</MenuItem>
                                            )
                                        })
                                }




                                {/* 

                     


                                {/* {boardDetailsMock ?
                                    boardDetailsMock.map((list, index) => {
                                        return (
                                            <MenuItem key={index} value="0" style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_board_name}</MenuItem>
                                        )
                                    })
                                    :
                                    console.log("not getting the data")
                                } */}

                            </Select>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12
                                    }}>
                                    Board/Uni
                            </span>
                            </span>
                        </FormControl>


                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Standard/Stream</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={age}
                                // onChange={handleChange}
                                label="Standard/Stream"
                                style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}
                            >



                                {loadingStd && stdDetailsGet ?
                                    boardDetailsMock.map((list, index) => {
                                        return (
                                            <MenuItem key={index} value="0" style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_board_name}</MenuItem>
                                        )
                                    })
                                    :
                                    stdDetails.length != 0 ?

                                        stdDetails.data.map((list, index) => {
                                            return (
                                                <MenuItem key={index} value={list.institute_type_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.standard}</MenuItem>

                                            )
                                        })
                                        :
                                        standardsMockData.map((list, index) => {
                                            return (
                                                <MenuItem key={index} value="0" style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.stand_name}</MenuItem>
                                            )
                                        })
                                }




                                {/* {standardsMockData ?
                                    standardsMockData.map((list, index) => {
                                        return (
                                            <MenuItem key={index} value="0" style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.stand_name}</MenuItem>
                                        )
                                    })
                                    :
                                    console.log("not getting the data")
                                } */}

                            </Select>

                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12
                                    }}>
                                    Standard/Stream
                            </span>
                            </span>
                        </FormControl>



                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}>Subject</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={age}
                                // onChange={handleChange}
                                label="Subject"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >

                                {loadingSub && subDetailsGet ?
                                   boardDetailsMock.map((list, index) => {
                                    return (
                                        <MenuItem key={index} value="0" style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_board_name}</MenuItem>
                                    )
                                })
                                    :
                                    stdDetails.length != 0 ?

                                        subDetails.data.subjects.map((list, index) => {
                                            return (
                                                <MenuItem key={index} value={list.institute_type_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.subject_name}</MenuItem>

                                            )
                                        })
                                        :
                                        subjectsMockData.map((list, index) => {
                                            return (
                                                <MenuItem key={index} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.subj_name}</MenuItem>
                                            )
                                        })
                                }

                                {/* 
                                {subjectsMockData ?
                                    subjectsMockData.map((list, index) => {
                                        return (
                                            <MenuItem key={index} value="0" style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.subj_name}</MenuItem>
                                        )
                                    })
                                    :
                                    console.log("not getting the data")
                                } */}

                            </Select>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12
                                    }}>
                                    Subject
                            </span>
                            </span>
                        </FormControl>


                        {/* <FormControl >
                            <InputLabel htmlFor="demo-customized-textbox" ></InputLabel>
                            <BootstrapInput id="demo-customized-textbox" />
                        </FormControl> */}


                    </div>

                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", margin: 10 }}>
                    <Button variant="contained" color="primary" style={{ fontFamily: "Poppins, Helvetica, sans-serif", textTransform: "none" }} onClick={() => { setValue(1) }}>
                        Next
                    </Button>
                </div>

            </TabPanel>


            <TabPanel value={value} index={1}>



                {/* <div style={{ display: "flex", justifyContent: "center" }}>
                    <FormControl className={classes.margin}>

                        <Typography>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please enter <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12
                                    }}>
                                    Title
                            </span>
                            </span>
                        </Typography>
                        <BootstrapInput id="demo-customized-textbox" />
                    </FormControl>
                </div> */}


                <div style={{ display: 'flex', justifyContent: "center", marginTop: 10 }}>

                    <FormControl variant="outlined" className={classes.formControl1}>
                        <span
                            style={{
                                fontSize: 12,
                                color: "#464E5F",
                                marginLeft: 5,
                                fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                        >
                            Please select <span
                                style={{
                                    color: "#464E5F",
                                    fontWeight: "600",
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                    fontSize: 12
                                }}>
                                Chapter
                            </span>
                        </span>
                        <InputLabel id="demo-simple-select-outlined-label"
                            style={{ fontSize: 13, color: "#464E5F", marginTop: 15 }}>Chapter</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={age}
                            // onChange={handleChange}
                            label="Age"
                            style={{ fontSize: 13, color: "#464E5F" }}
                        >

                            {/* {loadingChap && chpDetailsGet ?
                                console.log("loading trueeee")
                                :
                                chapDetails.length != 0 ?

                                    chapDetails.data.data.map((list, index) => {
                                        return (
                                            <MenuItem key={index} value={list.institute_type_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.topic_title}</MenuItem>

                                        )
                                    })
                                    :
                                    chaptersMockData.map((list, index) => {
                                        return (
                                            <MenuItem key={index} value="0" style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.chap_name}</MenuItem>
                                        )
                                    })
                            }
                            {/*  */}

                            {chaptersMockData ?
                                chaptersMockData.map((list, index) => {
                                    return (
                                        <MenuItem key={index} value="0" style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.chap_name}</MenuItem>
                                    )
                                })
                                :
                                console.log("not getting the data")
                            }

                        </Select>

                    </FormControl>
                </div>



                <div style={{ display: "flex", justifyContent: "center" }}>
                    <FormControl variant="outlined" className={classes.formControl1}>

                        <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", marginTop: 15 }}>Topic</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={age}
                            // onChange={handleChange}
                            label="Age"
                            style={{ fontSize: 13, color: "#464E5F" }}
                        >

                            {TopicsMockData ?
                                TopicsMockData.map((list, index) => {
                                    return (
                                        <MenuItem key={index} value="0" style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.topic_name}</MenuItem>
                                    )
                                })
                                :
                                console.log("not getting the data")
                            }

                        </Select>
                    </FormControl>
                    <span
                        style={{
                            fontSize: 12,
                            color: "#464E5F",
                            marginLeft: 5,
                            fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                    >
                        Please select <span
                            style={{
                                color: "#464E5F",
                                fontWeight: "600",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                                fontSize: 12
                            }}>
                            Topic
                            </span>
                    </span>
                </div>


                <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", alignContent: "center" }}>
                    <div>
                        <Button variant="outlined" style={{ width: 400, height: 50, textTransform: "none", fontFamily: "Poppins, Helvetica, sans-serif", }}>Upload Xls</Button>
                        <Typography>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Select <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12
                                    }}>
                                    Xls
                                </span>
                            </span>
                        </Typography>
                    </div>


                </div>


                {/* 
                <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", alignContent: "center" }}>
                    <div>
                        <Typography>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Select <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12
                                    }}>
                                    Doc
                                </span>
                            </span>
                        </Typography>
                        <Button variant="outlined" style={{ width: 200, height: 50, textTransform: "none", fontFamily: "Poppins, Helvetica, sans-serif", }}>Upload Document</Button>
                    </div>
                    <span style={{ display: "flex", marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                        :
                    </span>

                    <FormControl variant="outlined" className={classes.formControl1}>
                        <span
                            style={{
                                fontSize: 12,
                                color: "#464E5F",
                                marginLeft: 5,
                                fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                        >
                            Please select <span
                                style={{
                                    color: "#464E5F",
                                    fontWeight: "600",
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                    fontSize: 12
                                }}>
                                Doc Format
                         </span>
                        </span>
                        <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", marginTop: 15 }}>Upload Document</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={age}
                            onChange={handleChange}
                            label="Age"
                            style={{ fontSize: 13, color: "#464E5F" }}
                        >
                            <MenuItem value="0" style={{ fontSize: 13, color: "#464E5F" }}><em>None</em></MenuItem>
                            <MenuItem value={10} style={{ fontSize: 13, color: "#464E5F" }}>1080 mp4</MenuItem>
                            <MenuItem value={20} style={{ fontSize: 13, color: "#464E5F" }}>720 mp4</MenuItem>
                            <MenuItem value={30} style={{ fontSize: 13, color: "#464E5F" }}>Gif</MenuItem>
                        </Select>
                    </FormControl>
                </div>




                <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", alignContent: "center" }}>

                    <div>
                        <Typography>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Select <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12
                                    }}>
                                    Image
                                </span>
                            </span>
                        </Typography>
                        <Button variant="outlined" style={{ width: 200, height: 50, textTransform: "none", fontFamily: "Poppins, Helvetica, sans-serif", }}>Upload Image</Button>
                    </div>
                    <span style={{ display: "flex", marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                        :
                    </span>

                    <FormControl variant="outlined" className={classes.formControl1}>
                        <span
                            style={{
                                fontSize: 12,
                                color: "#464E5F",
                                marginLeft: 5,
                                fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                        >
                            Please select <span
                                style={{
                                    color: "#464E5F",
                                    fontWeight: "600",
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                    fontSize: 12
                                }}>
                                Image Format
                         </span>
                        </span>
                        <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", marginTop: 15 }}>Image Formats</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={age}
                            onChange={handleChange}
                            label="Age"
                            style={{ fontSize: 13, color: "#464E5F" }}
                        >
                            <MenuItem value="0" style={{ fontSize: 13, color: "#464E5F" }}><em>None</em></MenuItem>
                            <MenuItem value={10} style={{ fontSize: 13, color: "#464E5F" }}>1080 mp4</MenuItem>
                            <MenuItem value={20} style={{ fontSize: 13, color: "#464E5F" }}>720 mp4</MenuItem>
                            <MenuItem value={30} style={{ fontSize: 13, color: "#464E5F" }}>Gif</MenuItem>
                        </Select>
                    </FormControl>
                </div> */}



                <div style={{ display: "flex", justifyContent: "center", margin: 10 }}>
                    <Link to="/publisher/mybooks" style={{ textDecoration: "none" }}>
                        <Button variant="contained" color="primary" style={{ fontFamily: "Poppins, Helvetica, sans-serif", textTransform: "none" }}>
                            Submit
                        </Button>
                    </Link>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-start", margin: 10 }}>
                    <Button variant="contained" color="primary" style={{ fontFamily: "Poppins, Helvetica, sans-serif", textTransform: "none" }} onClick={() => { setValue(0) }}>
                        Previous
                    </Button>
                </div>



                <div style={{ justifyContent: "center", display: "flex", marginTop: 50 }}>
                    <span style={{ textAlign: "center", fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 14, alignSelf: "center" }}> To Add more Fields Go to <span style={{ fontWeight: "500" }}>Settings</span> Page</span>
                </div>



            </TabPanel>


            {/* <TabPanel value={value} index={1}>
                <div style={{ justifyContent: "flex-end" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        style={{ alignSelf: "flex-end", justifyContent: "flex-end", alignSelf: "flex-end" }}
                        onClick={() => showInput()}
                    >
                        Add Field
                </Button>
                </div>
                {showInputField ?
                    <FormControl >
                        <InputLabel htmlFor="demo-customized-textbox" ></InputLabel>
                        <BootstrapInput id="demo-customized-textbox" />
                    </FormControl>
                    :
                    <></>

                }
            </TabPanel> */}


        </div>
    );
}