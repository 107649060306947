import React, { useEffect, useState } from 'react';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import '../../../css/styles.css';

function VariantButtonGroup() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& > *': {
                    m: 1,
                },
            }}
        >
            <ButtonGroup size="medium" aria-label="medium button group">
                <Button variant="contained" className='p-16'>A1</Button>
                <Button variant="outlined" className='p-16'>A2</Button>
                <Button variant="outlined" className='p-16'>B1</Button>
                <Button variant="outlined" className='p-16'>B2</Button>
                <Button variant="outlined" className='p-16'>C1</Button>
                <Button variant="outlined" className='p-16'>C2</Button>
            </ButtonGroup>

        </Box>
    );
}

function SelectControls() {
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return (
        <div
            style={{

                width: '100%'
            }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%'
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    <Typography
                        variant='body1'
                        style={{
                            marginTop: 10
                        }}>
                        English
                    </Typography>

                    <Typography
                        variant='caption'
                        className='text-secondary'>
                        Target Language
                    </Typography>
                </div>
                {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small"> */}
                {/* <InputLabel id="demo-simple-select-helper-label">Target Language</InputLabel> */}
                {/* <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        // value={age}
                        value="English"
                        label="Target Language"
                    // onChange={handleChange}
                    >
                        <MenuItem value="English">
                            <em>English</em>
                        </MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select> */}

                {/* <FormHelperText>Target Language</FormHelperText> */}
                {/* </FormControl> */}
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select
                        // value={age}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        value="Italian"
                    >
                        <MenuItem value="Italian">
                            <em>Italian</em>
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                    <FormHelperText>Translation Language</FormHelperText>
                </FormControl>
            </div>


            {/* lesson type */}
            <div
                style={{
                    marginTop: 10
                }}>
                <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
                    <InputLabel id="demo-select-small-label">Lesson Type</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value="Sentences"
                        label="Lesson Type"
                        onChange={handleChange}
                    >
                        <MenuItem value="Sentences">
                            <em>Sentences</em>
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>
    );
}

const HEIGHTBOX = (props) => {
    let ht = (props.height !== undefined) ? props.height : 10;
    return (
        <div style={{ margin: ht }}></div>
    )
}

const SettingsLeftBar = () => {
    const [grammar, setGrammar] = useState('There is and there are');
    const [sentenceCount, setSentenceCount] = useState(5);

    useEffect(() => {

        return {

        }
    }, [])

    return (
        <div>
            {/* language settings and Lesson type */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                <SelectControls />

            </div>

            <HEIGHTBOX />

            {/* lesson leve */}
            <div>

                <Typography variant="body1" component="body1">
                    Lesson Level
                </Typography>
                <VariantButtonGroup />
            </div>

            <HEIGHTBOX />

            {/* Grammar */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                <Typography variant="body1" component="body1">
                    Grammar
                </Typography>


                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small-label">Grammar</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={grammar}
                        label="Grammar"
                    // onChange={handleChange}
                    >
                        <MenuItem value="There is and there are">
                            <em>There is and there are</em>
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                </FormControl>

            </div>

            <HEIGHTBOX />

            {/* topic */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                <Typography variant="body1" component="body1">
                    Topic
                </Typography>

                <TextField
                    id="outlined-multiline-static"
                    label="Enter text here"
                    multiline
                    rows={4}
                    defaultValue="Christmas in Italy"
                />
            </div>
            <HEIGHTBOX />

            {/* suggested topics */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                <Typography variant="body1" component="body1">
                    Suggested Topics
                </Typography>

                <Stack direction="row" spacing={1}
                    style={{
                        marginTop: 5
                    }}>
                    <Chip label="shopping" color="primary" />
                    <Chip label="food" color="success" />
                </Stack>
            </div>

            <HEIGHTBOX />

            {/* sentence count */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                <Typography variant="body1" component="body1">
                    Sentence Count
                </Typography>


                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small-label">Sentence Count</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={sentenceCount}
                        label="Sentence Count"
                    // onChange={handleChange}
                    >
                        <MenuItem value="5">
                            <em>5</em>
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                </FormControl>

            </div>

            <HEIGHTBOX />

            {/* activities template */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                <Typography variant="body1" component="body1">
                    Activities Template
                </Typography>


                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                        <img src="https://www.wordsworthelt.com/blog/wp-content/uploads/2015/04/Teaching-English2.jpg"
                            alt=''
                            width={64}
                            style={{
                                borderRadius: 10,
                                marginRight: 5
                            }}
                        />
                        <Typography
                            variant="body2"><b>Grammar</b></Typography>
                    </div>


                    <Button
                        variant='contained'
                        color='secondary'
                        size='small'
                    >Change</Button>
                </div>
            </div>



            <HEIGHTBOX height={20} />

            {/* Submit button */}
            <Button
                variant='contained'
                color='primary'
                style={{
                    width: '100%'
                }}
            >Generate Activities</Button>

        </div>
    )
}

export default SettingsLeftBar;
