
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import "../../css/sidebar.css"
import { Link } from 'react-router-dom'
import { ExitToApp, ExitToAppOutlined, SettingsOutlined } from '@material-ui/icons';



export const mainListItems = (
  <div>
    <Link to="/" style={{textDecoration:"none"}}>
      <ListItem button>
        <ListItemIcon>
          <div className="dashboard">
            <DashboardIcon />
          </div>
        </ListItemIcon>
        <ListItemText primary="Dashboard" className="dashboard-text" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
      </ListItem>
    </Link>
  </div>
);

export const secondaryListItems = (
  <div>
    {/* <ListSubheader inset style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12, color: "#000" }}>CONTENT</ListSubheader> */}
    <Link to="/settings" style={{ textDecoration: "none" }}>
      <ListItem button>
        <ListItemIcon>
          <div className="Finished">
            <SettingsOutlined />
          </div>
        </ListItemIcon>
        <div className="Finished-text">
          <ListItemText primary="Settings" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
        </div>
      </ListItem>
    </Link>
    <ListItem button>
      <ListItemIcon>
        <div className="Unfinished">
          <ExitToAppOutlined />
        </div>
      </ListItemIcon>
      <div className="Unfinished-text">
        <ListItemText primary="Logout" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
      </div>
    </ListItem>

  </div>
);