import React, { useEffect, useState, useRef } from "react";
import { Box, Container } from "@material-ui/core";
import JoditEditor from "jodit-react";

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import IconButton2 from "@mui/material/IconButton";
import DoneIcon2 from "@mui/icons-material/Done";
import Drawer2 from "@mui/material/Drawer";

import Card2 from "@mui/material/Card";
import CardActions2 from "@mui/material/CardActions";
import CardContent2 from "@mui/material/CardContent";
import Typography2 from "@mui/material/Typography";
import CardActionArea2 from "@mui/material/CardActionArea";
import Alert2 from "@mui/material/Alert";
import ListItem2 from "@mui/material/ListItem";
import ListItemButton2 from "@mui/material/ListItemButton";
import ListItemIcon2 from "@mui/material/ListItemIcon";
import ListItemText2 from "@mui/material/ListItemText";
import Checkbox2 from "@mui/material/Checkbox";
import ExpandLess2 from "@mui/icons-material/ExpandLess";
import Collapse2 from "@mui/material/Collapse";
import ExpandMore2 from "@mui/icons-material/ExpandMore";
import List2 from "@mui/material/List";
import Button2 from "@mui/material/Button";
import Divider2 from "@mui/material/Divider";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import QuizPlannerDrawer from "./QuizPlannerDrawer";
import MediaPlannerDrawer from "./MediaPlannerDrawer";
import ClearIcon from "@mui/icons-material/Clear";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip2 from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

import Select2 from "@mui/material/Select";
import MenuItem2 from "@mui/material/MenuItem";
import InputLabel2 from "@mui/material/InputLabel";
import NativeSelect2 from "@mui/material/NativeSelect";
import FormControl2 from "@mui/material/FormControl";

import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Constants from "../../../resource/Constants";

import AddCommentIcon2 from "@mui/icons-material/AddComment";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/dist/ProfilePicture.css";
import GPTGenerator from "./GPTGenerator";

const TAG = 'AnalysisList.js';

const config = {
  apiKey: Constants.Application.FIREBASE.API_KEY,
  authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
  //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
  storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET,
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}

function BriefField(props) {
  const { name, planSetup } = props;

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const onContentChanged = (newContent) => {
    setContent(newContent);
    handleChange2(newContent);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log("AnalysisList.js BriefField handleChange name " + name);
    console.log("AnalysisList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.analysis.summary = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const handleChange2 = (value) => {
    console.log("AnalysisList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.analysis.summary = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  let val = planSetup.analysis.summary;

  return (
    <div sx={{ minWidth: "100%" }}>
      <>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SUBHEADING value={`Teacher Summary for ${name}`} />

            <GPTGenerator
              handleUpdateGPTText={(text) => handleChange2(text)}
              prompt={`one sentence summary for participate`} 
              planSetup={planSetup}
              noOutcome={true}
            />
          </div>

          <Box
            sx={{
              // width: 500,
              maxWidth: "100%",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              label="Enter summary"
              // defaultValue={val}
              value={val}
              placeholder="Enter the text here"
              id="fullWidth"
              variant="outlined"
              onChange={handleChange}
              inputProps={{
                maxLength: 500,
              }}
            />
            {/* 
                        <JoditEditor
                            ref={editor}
                            value={val}
                            tabIndex={1}
                            onChange={onContentChanged}

                        /> */}
          </Box>
        </div>
      </>
    </div>
  );
}

function BriefField2(props) {
  const { name, planSetup } = props;

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const onContentChanged = (newContent) => {
    setContent(newContent);

    handleChange2(newContent);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("ActionList.js BriefField handleChange name " + name);
    console.log("ActionList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.analysis.elaborate = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const handleChange2 = (value) => {
    console.log("ActionList.js BriefField handleChange name " + name);
    console.log("ActionList.js BriefField handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.analysis.elaborate = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const val = planSetup.analysis.elaborate;

  return (
    <div sx={{ minWidth: "100%" }}>
      <>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SUBHEADING value={name} />

              
            <GPTGenerator
              handleUpdateGPTText={(text) => handleChange2(text)}
              prompt={`specifically "elaborate" in participate and excluding: "engage, explain, explore, evaluate", under the participate `} 
              planSetup={planSetup}
              noOutcome={true}
            />
          </div>

          <Box
            sx={{
              // width: 500,
              maxWidth: "100%",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/* <TextField
                            defaultValue={val}
                            fullWidth label="Enter summary"
                            placeholder="Enter the text here" id="fullWidth"
                            variant="standard"
                            onChange={handleChange}
                            inputProps={{
                                maxLength: 500,
                            }}
                        />  */}

            <JoditEditor
              ref={editor}
              value={val}
              tabIndex={1}
              onChange={onContentChanged}
            />
          </Box>
        </div>
      </>
    </div>
  );
}

const HEADING = (props) => {
  const { value } = props;

  return (
    <>
      <Typography variant="h4">{value}</Typography>
    </>
  );
};

const HEADING2 = (props) => {
  const { value, subValue } = props;

  return (
    <>
      <div
        className="d-flex flex-row justify-content-start align-items-center"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">{value}</Typography>

        <div
          style={{
            marginRight: 15,
          }}
        ></div>

        <Typography variant="body2">{subValue}</Typography>
      </div>
    </>
  );
};

const SUBHEADING = (props) => {
  const { value } = props;

  return (
    <>
      <Typography variant="subtitle1">{value}</Typography>
    </>
  );
};

const PARTITEMBOX = (props) => {
  const { num, num2, partIdx, expIdx, name, name2, planSetup } = props;

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const onContentChanged = (newContent) => {
    setContent(newContent);

    handleChange2(newContent);
  };

  const exp = planSetup.analysis.parts[partIdx].explanation[expIdx];

  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("AnalysisList.js PARTITEMBOX handleChange name " + name);
    console.log("AnalysisList.js PARTITEMBOX handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.analysis.parts[partIdx].explanation[expIdx].description =
      value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };
  const handleChange2 = (value) => {
    console.log("AnalysisList.js PARTITEMBOX handleChange name " + name);
    console.log("AnalysisList.js PARTITEMBOX handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    newPlanSetup.analysis.parts[partIdx].explanation[expIdx].description =
      value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  let qst = planSetup.analysis.parts[partIdx].question;
  let desc2 = planSetup.analysis.parts[partIdx].explanation[expIdx].description;

  console.log(TAG, 'PARTITEMBOX name2 ' + name2);
  return (
    <>
      <div
        style={{
          margin: 10,
        }}
      ></div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography2 variant="subtitle1">Add Explanation {num2}</Typography2>

        <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>

          <GPTGenerator
            handleUpdateGPTText={(text) => handleChange2(text)}
            prompt={(name2.toLowerCase() === 'activity') ?
            `many sentences of explanation for the activity: '${qst}' removing the period and spaces at the start`
            :`explanation of ${name2} for question: ${qst}`} 
            planSetup={planSetup}
            noOutcome={true}
          />
          <ClearIcon
            color="warning"
            style={{
              cursor: "pointer",
            }}
            onClick={() => props.onDelAnaPartExp(partIdx, expIdx)}
          />
          
        </div>
      </div>

      <Box
        sx={{
          // width: 500,
          maxWidth: "100%",
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {/* <TextField fullWidth label="Enter the text"
                    value={desc2}
                    placeholder="Enter the text here" id="fullWidth"
                    variant="outlined" multiline rows={1} maxRows={5}
                    onChange={handleChange}
                /> */}

        <JoditEditor
          ref={editor}
          value={desc2}
          tabIndex={1}
          onChange={onContentChanged}
        />
      </Box>

      <div
        style={{
          margin: 20,
        }}
      ></div>
    </>
  );
};

const PARTQBOX = (props) => {
  const { num, name, planSetup } = props;

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const onContentChanged = (newContent) => {
    setContent(newContent);

    handleChange2(newContent);
  };

  // update the aim resource by index
  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("AnalysisList.js PARTQBOX handleChange name " + name);
    console.log("AnalysisList.js PARTQBOX handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    let part = newPlanSetup.analysis.parts[num];
    part.question = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };
  const handleChange2 = (value) => {
    console.log("AnalysisList.js PARTQBOX handleChange name " + name);
    console.log("AnalysisList.js PARTQBOX handleChange value " + value);
    let newPlanSetup = JSON.parse(JSON.stringify(planSetup));
    let part = newPlanSetup.analysis.parts[num];
    part.question = value;

    props.handleUpdatePlanSetup(newPlanSetup);
  };

  const qst = planSetup.analysis.parts[num].question;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography2
          variant="subtitle1"
          sx={{
            color: "#000",
          }}
        >
          Add an activity for discussion
        </Typography2>

        <GPTGenerator
            handleUpdateGPTText={(text) => handleChange2(text)}
            prompt={`a single large sentence activity`} 
            planSetup={planSetup}
            noOutcome={true}
          />
        {/* 
          <ClearIcon color="warning"
            style={{
              cursor: 'pointer'
            }} /> */}
      </div>

      {/* {showIt ? */}
      <Box
        sx={{
          // width: 500,
          maxWidth: "100%",
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {/* <TextField fullWidth label="Enter the text"
                    value={qst}
                    placeholder="Enter the text here" id="fullWidth"
                    variant="outlined" multiline rows={1} maxRows={5}
                    handleUpdatePlanSetup={(newPlanSetup) => props.handleUpdatePlanSetup(newPlanSetup)}
                    onChange={handleChange}
                /> */}

        <JoditEditor
          ref={editor}
          value={qst}
          tabIndex={1}
          onChange={onContentChanged}
        />

        {/* <ImageIcon /> */}
      </Box>
      {/* :
              <></>
            } */}

      {/* IMAGES */}

      <div
        style={{
          margin: 20,
        }}
      ></div>
    </>
  );
};

const PARTNAMEBOX = (props) => {
  const { num, name } = props;
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <Typography2
              variant="body2">
              PART {num}
            </Typography2> */}

          <Typography2 variant="h6">{name} Discussion</Typography2>
        </div>

        {/* 
          <ClearIcon color="warning"
            style={{
              cursor: 'pointer'
            }} /> */}
      </div>

      {/* 
        <div style={{
          marginTop: 10
        }}>
          <span>OK, now let's generalize our discussion</span>
        </div> */}

      <div
        style={{
          margin: 0,
        }}
      ></div>
      {/* {showIt ? */}
      <Box
        sx={{
          // width: 500,
          maxWidth: "100%",
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {/* <TextField fullWidth label="Enter the text"
            placeholder="Enter the text here" id="fullWidth"
            variant="outlined" multiline rows={1} maxRows={5} /> */}

        {/* <ImageIcon /> */}
      </Box>
      {/* :
              <></>
            } */}

      {/* IMAGES */}

      <div
        style={{
          margin: 20,
        }}
      ></div>
    </>
  );
};

const STEPASSET = (props) => {
  const { asset } = props;
  return (
    <div
      sx={{
        backgroundColor: "#DCDCDC",
      }}
      style={{
        background: "#DCDCDC",
        verticalAlign: "middle",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        padding: 2,
        borderRadius: 5,
        margin: 0,
        cursor: "pointer",
      }}
    >
      {asset}
    </div>
  );
};

/* homework image upload */
const UploadBox5 = (props) => {
  const { planSetup, idx } = props;
  const profilePictureRef = React.createRef();
  const [percent, setPercent] = useState(0);

  async function srcToFile(src, fileName, mimeType) {
    return await fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        console.log("ApplicationList.js UploadBox srcToFile buf " + buf);

        const imageFile = new File([buf], fileName, { type: mimeType });
        handleUpload1(imageFile);
      });
  }

  const handleUpload1 = (imageFile) => {
    //add here the upload logic...
    if (!imageFile) {
      alert("ApplicationList.js UploadBox handleUpload1 Image is invalid!");
      return;
    }

    const storageRef = firebase.storage().ref(`/files/${imageFile.name}`); //.child(imageFile.name);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    // const uploadTask = uploadBytesResumable(storageRef, file);
    const uploadTask = storageRef.put(imageFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => {
        console.log("ApplicationList.js BANNERBOX handleUpload: error " + err);
      },
      () => {
        // download url
        storageRef
          .getDownloadURL() //uploadTask.snapshot.ref).then((url) => {
          .then(async (firebaseUrl) => {
            console.log(
              "ApplicationList.js BANNERBOX handleUpload: firebaseUrl " +
                firebaseUrl
            );
            // update plansetup current resource image
            let sPlanSetup = JSON.parse(JSON.stringify(planSetup));

            let newImg = {
              url: firebaseUrl,
            };

            sPlanSetup.application.homework.images.push(newImg);
            props.handleUpdatePlanSetup(sPlanSetup);

            props.onClose();
          })
          .catch((e) => {
            console.log(
              "ApplicationList.js BANNERBOX handleUpload: exception " + e
            );
          });
      }
    );
  };

  const handleUpload = async () => {
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const imageFile = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();

    if (
      Object.keys(imageData).length === 0 &&
      imageData.constructor === Object
    ) {
      // do not proceed
      return;
    }

    console.log("ApplicationList.js UploadBox5 handleUpload PP " + PP);
    console.log(
      "ApplicationList.js UploadBox5 handleUpload imageData " + imageData
    );
    console.log("ApplicationList.js UploadBox5 handleUpload file " + imageFile);
    console.log(
      "ApplicationList.js UploadBox5 handleUpload imageAsDataURL " +
        imageAsDataURL
    );

    let type = imageAsDataURL.substring(
      imageAsDataURL.indexOf(":") + 1,
      imageAsDataURL.indexOf(";")
    ); // => image/png
    console.log(
      "ApplicationList.js UploadBox5 handleUpload callback: type " + type
    );

    let fileName = makeid(10);

    if (type == "image/jpeg") fileName = fileName + ".jpg";
    else if (type == "image/png") fileName = fileName + ".png";
    else if (type == "image/gif") fileName = fileName + ".gif";
    else fileName = fileName + ".jpg";

    console.log(
      "ApplicationList.js UploadBox handleUpload callback: fileName " + fileName
    );

    // now upload to firebase and set the image url ready
    // convert to file
    // const imageBlob = dataURItoBlob(newDataUri);

    // console.log('CurriculumSetup.js BANNERBOX imageChanged callback: imageBlob ' + JSON.stringify(imageBlob));
    // const imageFile = dataURLtoFile(newDataUri, fileName);
    // fetch(newDataUri)
    //   .then(res => res.blob())
    //   .then(blob => {
    //     const imageFile = new File([blob], fileName)

    //   })

    // const imageFile = new File([imageBlob], imageBlob.name, {
    //   type: imageBlob.type,
    // });

    const imageFile2 = await srcToFile(imageAsDataURL, fileName, type);
  };

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  return (
    <>
      <div
        style={
          {
            // height: 200
          }
        }
      >
        <ProfilePicture ref={profilePictureRef} useHelper={true} debug={true} />

        <div
          style={{
            alignSelf: "right",
          }}
        >
          <Button2 onClick={handleUpload}>Upload</Button2>
        </div>
      </div>
    </>
  );
};

/* homework images */
function PickImageDialog5(props) {
  const { planSetup, idx, image } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    // props.onAddPracStepImage(idx, 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d')
  };

  const handleClose = () => {
    setOpen(false);
  };

  const imgUrl = image
    ? image
    : "https://t3.ftcdn.net/jpg/04/34/72/82/360_F_434728286_OWQQvAFoXZLdGHlObozsolNeuSxhpr84.jpg";

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}> */}

      <a
        style={{
          color: Constants.COLOR_PRIMARY,
        }}
        href="javascript:void(0)"
        onClick={() => {
          handleClickOpen();
        }}
      >
        <STEPASSET
          onClick={() => {
            props.onAddHomeworkImage(
              idx,
              "https://images.unsplash.com/photo-1551782450-a2132b4ba21d"
            );
          }}
          asset={<AddPhotoAlternateIcon color="secondary" />}
        />
      </a>

      {/* </Button> */}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload picture</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose the picture, and crop it to your desire
          </DialogContentText>
          <UploadBox5
            idx={idx}
            planSetup={planSetup}
            handleUpdatePlanSetup={(newPlanSetup) =>
              props.handleUpdatePlanSetup(newPlanSetup)
            }
            onClose={handleClose}
            onAddHomeworkImage={(idx, url) =>
              props.onAddHomeworkImage(idx, url)
            }
          />
        </DialogContent>
        {/* <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose}>UPLOAD</Button>
                </DialogActions> */}
      </Dialog>
    </div>
  );
}

const HomeworkImages = (props) => {
  const { images, planSetup } = props;

  console.log(
    "CurriculumSetup.js StepImageList images " + JSON.stringify(images)
  );

  return (
    <>
      {images && images.length ? (
        <ImageList sx={{ width: "100%", height: "auto" }} cols={3}>
          {/* {itemData.map((item) => ( */}
          {images.map((item, idx) => {
            return (
              <div
                style={{
                  position: "relative",
                }}
              >
                <ImageListItem key={item.img}>
                  <img
                    //src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    src={`${item.url}`}
                    // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    srcSet={`${item.url}`}
                    alt={""}
                    loading="lazy"
                  />
                </ImageListItem>
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}
                >
                  <DeleteForeverIcon
                    color="red"
                    style={{
                      color: "red",
                    }}
                    onClick={() => props.onDelHomeworkImage(idx)}
                  />
                </div>
              </div>
            );
          })}
          {/* ))} */}
        </ImageList>
      ) : (
        <>
          <Alert2 severity="info">No images added yet!</Alert2>
        </>
      )}
    </>
  );
};

/* explanation box */
const PARTLIST = (props) => {
  const { num, name, part, planSetup } = props;
  console.log("CurriculumSetup.js PARTLIST part " + JSON.stringify(part));

  const { images } = planSetup.application.homework.images;

  if (!part) {
    return <></>;
  }

  return (
    <div sx={{ minWidth: "100%" }}>
      <>
        <PARTNAMEBOX
          num={num}
          name={name}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />
        <PARTQBOX
          num={num}
          name={name}
          planSetup={planSetup}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />

        <div
          style={{
            margin: 20,
          }}
        ></div>
        {/* 
        <HomeworkImages
          images={images}
          planSetup={planSetup}
          onDelHomeworkImage={(idx) => props.onDelHomeworkImage(idx)}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        /> */}

        {/* ICONS */}
        {/* <div
          style={{
            margin: 10,

            display: "flex",
            flexDirection: "row",
            // textAlign: 'right'
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Tooltip title="Add Image" placement="top">
            <PickImageDialog5
              idx={num}
              planSetup={planSetup}
              // image={resource.url}
              handleUpdatePlanSetup={(newPlanSetup) =>
                props.handleUpdatePlanSetup(newPlanSetup)
              }
              onClick={() => {
                props.onAddHomeworkImage(
                  num,
                  "https://images.unsplash.com/photo-1551782450-a2132b4ba21d"
                );
              }}
              asset={<AddPhotoAlternateIcon color="secondary" />}
              onAddHomeworkImage={(num, url) =>
                props.onAddHomeworkImage(num, url)
              }
            />
          </Tooltip>
        </div> */}

        <Typography2 variant="h6">{name} Explanation</Typography2>

        {/* iterate the explanation */}
        {part.explanation && part.explanation.length ? (
          part.explanation.map((exp, idx3) => {
            return (
              <PARTITEMBOX
                num={num}
                name={exp.text}
                name2={name}
                num2={idx3 + 1}
                partIdx={num}
                expIdx={idx3}
                planSetup={planSetup}
                onDelAnaPartExp={(partIdx, idx) =>
                  props.onDelAnaPartExp(partIdx, idx)
                }
                handleUpdatePlanSetup={(newPlanSetup) =>
                  props.handleUpdatePlanSetup(newPlanSetup)
                }
              />
            );
          })
        ) : (
          <></>
        )}
        {/* <PARTITEMBOX num={num} name="" num2={2} /> */}

        <div
          style={{
            marginTop: 10,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography2 variant="button">ADD:</Typography2>

          <div style={{ marginRight: 10 }}></div>

          <a
            style={{
              color: Constants.COLOR_PRIMARY,
            }}
            href="javascript:void(0)"
            onClick={() => props.onAddAnaPartExp(num, "", "")}
          >
            <STEPASSET
              onClick={() => {
                props.onAddAnaPartExp(num, "", "");
              }}
              asset={
                <Tooltip title="Add Explanation" placement="top">
                  <AddCommentIcon2 color="secondary" />
                </Tooltip>
              }
            />
          </a>
          {/* 
                    <Button
                        variant="contained"
                        size="small"
                        // style={{
                        //   backgroundColor: Constants.Application.COLOR_LIGHT
                        // }}
                        onClick={() => props.onAddAnaPartExp(num, 'title', 'desc')} // which part
                    >
                        Add Explanation
                    </Button> */}
        </div>
      </>
    </div>
  );
};

const ANALYSISLIST = (props) => {
  // const { planSetup } = props;
  const { planSetup, planId, subId, subSubId, chapId, unitId } = props;

  // get no of parts
  let anaParts = planSetup.analysis.parts;

  console.log(
    "AnalysisList.js ANALYSISLIST anaParts " + JSON.stringify(anaParts)
  );

  let idx10 = 0;

  return (
    <Card2 sx={{ minWidth: "100%" }}>
      <CardContent2>
        <div
          style={{
            margin: 10,
          }}
        ></div>
        <BriefField
          name="Participate"
          planSetup={planSetup}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />
        <div
          style={{
            margin: 20,
          }}
        ></div>

        <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}>
          <HEADING2
            value="Elaborate"
            subValue="The teacher provides opportunities, including POPQUIZ , for students to apply or review the concept in different contexts or situations."
          />
          
        </div>
        
        <div
          style={{
            marginBottom: 5,
          }}
        ></div>
        <BriefField2
          name=""
          planSetup={planSetup}
          handleUpdatePlanSetup={(newPlanSetup) =>
            props.handleUpdatePlanSetup(newPlanSetup)
          }
        />
        <div
          style={{
            margin: 20,
          }}
        ></div>
        <div sx={{ minWidth: "100%" }}>
          <>
            <QuizPlannerDrawer
              chapId={chapId}
              planSetup={planSetup}
              type="assm"
              name="Class"
              lsnPlanId={planId}
              subId={subId}
              subSubId={subSubId}
              noTime={false}
            />
          </>
        </div>

        {anaParts && anaParts.length ? (
          anaParts.map((part, idx5) => {
            if(part.heading === 'assessment_assm')
              return <></>;
            
            return part.isSelected ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <HEADING value={`PART ${idx10++ + 1}`} />

                  <ClearIcon
                    color="warning"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      props.onDeleteAnaPartClicked(idx5);
                    }}
                  />
                </div>

                <div
                  style={{
                    margin: 10,
                  }}
                ></div>

                <PARTLIST
                  num={idx5}
                  name={part.heading}
                  planSetup={planSetup}
                  part={part}
                  onAddAnaPartExp={(num, title, desc) =>
                    props.onAddAnaPartExp(num, title, desc)
                  }
                  onDelAnaPartExp={(partIdx, idx) =>
                    props.onDelAnaPartExp(partIdx, idx)
                  }
                  handleUpdatePlanSetup={(newPlanSetup) =>
                    props.handleUpdatePlanSetup(newPlanSetup)
                  }
                />

                <div
                  style={{
                    margin: 20,
                  }}
                ></div>
              </>
            ) : (
              <></>
            );
          })
        ) : (
          <></>
        )}
        {/*         
        <HEADING
          value={`PART ${2}`} />
        <div
          style={{
            margin: 10
          }}></div>
        <PARTLIST num={2} name="Generalized" />
  
        
        <div
          style={{
            margin: 20
          }}></div>
  
  
  
  
  
  
        <HEADING
          value={`PART ${3}`} />
        <div
          style={{
            margin: 10
          }}></div>

        <PARTLIST num={3} name="Higher-Order Thinking" />
        
 */}
        <div
          style={{
            marginTop: 10,
            textAlign: "left",
          }}
        >
          {/* <Button2
                    variant="contained"
                    endIcon={<AddCircleOutlineIcon />}
                    style={{
                        backgroundColor: Constants.Application.COLOR_PRIMARY
                    }}
                    onClick={() => props.onAddAnaPartClicked()}

                >
                    Add Part
                </Button2> */}
        </div>
      </CardContent2>
    </Card2>
  );
};

export default ANALYSISLIST;
