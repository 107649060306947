import { CHPDETAILS_LIST_FAIL, CHPDETAILS_LIST_REQUEST, CHPDETAILS_LIST_SUCCESS } from "../constants/chapterConstants";


function chpDetailsReducer(state = { chapDetails: [] }, action) {
    switch (action.type) {
        case CHPDETAILS_LIST_REQUEST:
            return { loadingChap: true }
        case CHPDETAILS_LIST_SUCCESS:
            return { loadingChap: false, chapDetails: action.payload };
        case CHPDETAILS_LIST_FAIL:
            return { loadingChap: false, error: action.payload }
        default:
            return state;
    }

}

export { chpDetailsReducer }